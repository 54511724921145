import cloneDeep from 'lodash/cloneDeep';

import type {
  TrialExpenseSummaryGridResponse,
  TrialExpenseSummaryRow,
} from 'shared/lib/types';

export function processTrialExpenseSummaryRows(
  expenseSummaryGrid?: TrialExpenseSummaryGridResponse,
  monthPrefix = '',
): TrialExpenseSummaryRow[] | undefined {
  return expenseSummaryGrid?.rows
    ? cloneDeep(
        expenseSummaryGrid.rows.map((row) => {
          const newRow = { ...row };
          for (const month of expenseSummaryGrid.month_list) {
            newRow[`${monthPrefix}${month}`] = row.booked_expenses[month];
          }
          return newRow;
        }),
      )
    : undefined;
}
