import type { ReactNode } from 'react';
import { Children, useMemo, useRef, useState } from 'react';

import config from 'config';

import type { Handler } from './WizardContext';
import WizardContext from './WizardContext';

type WizardProps = {
  children?: ReactNode;
  devTitle?: string;
  startIndex?: number;
  stepNames?: string[];
  title?: string;
  onClose: (isLastStepSaveClick?: boolean) => void;
};

function Wizard(props: WizardProps) {
  const {
    devTitle,
    title,
    stepNames,
    children,
    startIndex = 0,
    onClose,
  } = props;

  const [activeStep, setActiveStep] = useState(startIndex);
  const [isLoading, setIsLoading] = useState(false);
  const hasNextStep = useRef(true);
  const hasPreviousStep = useRef(false);
  const nextStepHandler = useRef<Handler>(null);
  const stepCount = Children.toArray(children).length;

  hasNextStep.current = activeStep < stepCount - 1;
  hasPreviousStep.current = activeStep > 0;

  const goToNextStep = useRef(() => {
    if (hasNextStep.current) {
      setActiveStep((currentStep) => currentStep + 1);
    }
  });

  const goToPreviousStep = useRef(() => {
    if (hasPreviousStep.current) {
      nextStepHandler.current = null;
      setActiveStep((currentStep) => currentStep - 1);
    }
  });

  const goToStep = useRef((stepIndex: number) => {
    if (stepIndex < stepCount) {
      nextStepHandler.current = null;
      setActiveStep(stepIndex);
    }
  });

  // Callback to attach the step handler
  const handleStep = useRef((handler: Handler) => {
    nextStepHandler.current = handler;
  });

  const doNextStep = useRef(async () => {
    if (hasNextStep.current && nextStepHandler.current) {
      try {
        setIsLoading(true);
        await nextStepHandler.current();
        setIsLoading(false);
        nextStepHandler.current = null;
        goToNextStep.current();
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    } else {
      goToNextStep.current();
    }
  });

  const formattedDevTitle = devTitle ? `[${devTitle}] ` : '';
  const contextValue = useMemo(
    () => ({
      devTitle: config.VITE_APP_IS_DEV_ENVIRONMENT ? formattedDevTitle : '',
      title,
      stepNames,
      onClose,
      nextStep: doNextStep.current,
      previousStep: goToPreviousStep.current,
      handleStep: handleStep.current,
      isLoading,
      activeStep,
      stepCount,
      isFirstStep: !hasPreviousStep.current,
      isLastStep: !hasNextStep.current,
      goToStep: goToStep.current,
    }),
    [activeStep, stepCount, isLoading],
  );

  const activeStepContent = useMemo(() => {
    const reactChildren = Children.toArray(children);

    return reactChildren[activeStep];
  }, [activeStep, children]);

  const enhancedActiveStepContent = useMemo(
    () => activeStepContent,
    [activeStepContent],
  );

  // TODO: It would be nice to wrap this in <FullWidthDialog open>, but FAUST has an extra component inside its dialog, so this breaks the CSS
  return (
    <WizardContext.Provider value={contextValue}>
      {enhancedActiveStepContent}
    </WizardContext.Provider>
  );
}

export default Wizard;
