import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type { ContractContainerResponse, VendorType } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';

import ContractContainerDialog from './ContractContainerDialog';

type Props = {
  contractContainer?: ContractContainerResponse;
  vendorType: VendorType;
  onClose: () => void;
};

function ContractContainerWizard(props: Props) {
  const { vendorType, onClose, contractContainer } = props;
  return (
    <FullWidthDialog open>
      <Wizard
        devTitle="MEPHISTOPHELES"
        title={
          vendorType === 'CRO' ? 'CRO contract' : 'Other Clinical Contract'
        }
        onClose={onClose}
      >
        <ContractContainerDialog
          contractContainer={contractContainer}
          vendorType={vendorType}
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default ContractContainerWizard;
