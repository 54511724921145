import type { Ref } from 'react';
import { forwardRef } from 'react';

/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
import MuiButton from '@mui/material/Button';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button/Button';

function Button(props: ButtonProps, ref: Ref<HTMLButtonElement>) {
  const { testId, ...otherProps } = props;

  return <MuiButton {...otherProps} ref={ref} data-testid={testId} />;
}

export { buttonClasses, getButtonUtilityClass } from '@mui/material/Button';
/* eslint-enable @typescript-eslint/no-restricted-imports */

export type ButtonProps = MuiButtonProps & { testId: string };

export default forwardRef(Button);
