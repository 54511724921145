import groupBy from 'lodash/groupBy';

import { getNumberCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColGroupDef } from 'shared/components/ag-grid/types';

import type {
  AdministrativeOrProcedureCategoryResponse,
  PatientAssessmentResponse,
  PatientCohortResponse,
} from 'shared/lib/types';

const REGION_WIDTH = 90;
const SITE_NUMBER_WIDTH = 150;
const COUNT_WIDTH = 100;
const NAME_WIDTH = 250;
const VENDOR_WIDTH = 150;
const PI_WIDTH = 150;
const STATUS_WIDTH = 100;
const DATE_WIDTH = 100;

const getPatientActivityColumnDefs = (
  procedureCategories: AdministrativeOrProcedureCategoryResponse[] | undefined,
  patientCohorts: PatientCohortResponse[] | undefined,
): CondorColGroupDef[] => [
  {
    headerName: '',
    children: [
      {
        headerName: 'Region',
        field: 'region_name',
        width: REGION_WIDTH,
        rowGroup: true,
        hide: true,
      },
      {
        headerName: 'Site #',
        field: 'site_number',
        width: SITE_NUMBER_WIDTH,
        pinned: 'left',
      },
      {
        headerName: 'Site name',
        field: 'site_name',
        width: NAME_WIDTH,
        pinned: 'left',
      },
      { field: 'site_trace_id', hide: true },
      { headerName: 'CRO / Vendor', field: 'vendor_name', width: VENDOR_WIDTH },
      { headerName: 'PI name', field: 'pi_name', width: PI_WIDTH },
      { headerName: 'Status', field: 'site_status', width: STATUS_WIDTH },
    ],
  },
  {
    headerName: 'Site dates',
    children: [
      { headerName: 'Recruited', field: 'recruited_date', width: DATE_WIDTH },
      { headerName: 'Initiated', field: 'initiated_date', width: DATE_WIDTH },
      { headerName: 'Closed', field: 'closed_date', width: DATE_WIDTH },
    ],
  },
  {
    headerName: 'PATIENT NUMBERS',
    children: [
      {
        headerName: 'Active',
        field: 'patients_active',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
      {
        headerName: 'Screened',
        field: 'patients_screened',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
      {
        headerName: 'Screen-failed',
        field: 'patients_screen_failed',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
      {
        headerName: 'Enrolled',
        field: 'patients_enrolled',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
      {
        headerName: 'Completed',
        field: 'patients_dropped_completed',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
    ],
  },
  ...Object.entries(
    groupBy(
      [...(patientCohorts ?? [])].sort((cohortA, cohortB) =>
        cohortA.name.localeCompare(cohortB.name),
      ),
      (cohort) => cohort.pizza_name ?? cohort.name,
    ),
  ).map((cohortGrouping) => ({
    headerName: pizzaJoin(cohortGrouping).toUpperCase(),
    children: Object.entries(
      groupBy(
        cohortGrouping[1].flatMap((cohort) => cohort.patient_assessments),
        (visit) => visit.pizza_name ?? visit.name,
      ),
    )
      .map((visitGrouping) => {
        visitGrouping[1].sort(
          (visitA, visitB) => visitA.order_index - visitB.order_index,
        );
        return visitGrouping;
      })
      .sort(
        (visitGroupingA, visitGroupingB) =>
          getVisitSortIndex(visitGroupingA) - getVisitSortIndex(visitGroupingB),
      )
      .map((visitGrouping) => ({
        headerName: pizzaJoin(visitGrouping),
        field: `${cohortGrouping[0]}_${visitGrouping[0]}`,
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum' as const,
      })),
  })),
  {
    headerName: 'TOTAL',
    children: [
      {
        headerName: 'Expected Visits',
        field: 'total_expected_visits',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
      {
        headerName: 'Actual Visits',
        field: 'total_visits',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
      {
        headerName: 'Total Visits',
        field: 'final_visit_count',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
    ],
  },
  {
    headerName: 'PROCEDURES',
    children: [
      ...Object.entries(
        groupBy(
          procedureCategories,
          (category) => category.mapped_edc_name ?? category.name,
        ),
      )
        .map((categoryGrouping) => {
          categoryGrouping[1].sort((visitA, visitB) =>
            visitA.name.localeCompare(visitB.name),
          );
          return categoryGrouping;
        })
        .sort((categoryGroupingA, categoryGroupingB) =>
          categoryGroupingA[0].localeCompare(categoryGroupingB[0]),
        )
        .map((categoryGrouping) => ({
          headerName: pizzaJoin(categoryGrouping),
          field: `procedure_${categoryGrouping[0]}`,
          ...getNumberCellConfig({ useEmDash: true }),
          width: COUNT_WIDTH,
          aggFunc: 'sum' as const,
        })),
      {
        headerName: 'Total procedures',
        field: 'total_procedures',
        ...getNumberCellConfig({ useEmDash: true }),
        width: COUNT_WIDTH,
        aggFunc: 'sum',
      },
    ],
  },
];

export const pizzaJoin = (objects: [string, Array<{ name: string }>]) =>
  `${objects[1].map((object) => object.name).join(',')} (${objects[0]})`;

export const getVisitSortIndex = (
  visitGrouping: [string, PatientAssessmentResponse[]],
) => visitGrouping[1].slice(-1)[0].order_index; // * Since we already sorted the visits within the grouping, we get the max by looking at the final element of the array

export default getPatientActivityColumnDefs;
