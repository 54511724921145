import type { FeatureFlagResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const apiEndpointFactory =
  constructApi<FeatureFlagResponse>('featureflags').withTags('FEATURE_FLAG');
const api = apiEndpointFactory.inject(() => ({
  getFeatureFlagsByName: apiEndpointFactory.getBy('name'),
  getAllFeatureFlags: apiEndpointFactory.getAll(),
}));

export const { useGetFeatureFlagsByNameQuery, useGetAllFeatureFlagsQuery } =
  api;
