import { useState } from 'react';

import { useSelector } from 'react-redux';

import ColumnMapperStep from 'shared/lib/column-mapper/ColumnMapperStep';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import {
  UnmappedFileEntityType,
  type UnmappedFileResponse,
  type UploadedFile,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import { selectCompany } from 'shared/state/slices/companySlice';
import UploadUnmappedCsvStep from 'shared/wizards/upload-unmapped-csv-step/UploadUnmappedCsvStep';
import useOnCloseLogic from 'shared/wizards/hooks/useOnCloseLogic';

import {
  useCreateInvoiceSnapshotFromCsvMutation,
  useDeleteInvoiceSnapshotMutation,
} from 'shared/api/rtkq/invoicesnapshots';

import columns from './columns';
import InvoiceReviewStep from './steps/InvoiceReviewStep';
import InvoiceValidationStep from './steps/InvoiceValidationStep';

type InvoiceUploadWizardProps = {
  onClose: () => void;
  onComplete?: (message: string) => void;
};

function InvoiceUploadWizard(props: InvoiceUploadWizardProps) {
  const { onClose: onCloseProp, onComplete } = props;
  const company = useSelector(selectCompany);
  const [snapshotId, setSnapshotId] = useState<string>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const [deleteSnapshot, { isLoading: _deleteIsLoading }] =
    useDeleteInvoiceSnapshotMutation();
  const [setFfMappingId, handleCloseBtnClick] = useOnCloseLogic();

  function onClose(isLastStepSaveClick?: boolean) {
    handleCloseBtnClick(
      uploadedFiles,
      deleteSnapshot,
      setUploadedFiles,
      setSnapshotId,
      onCloseProp,
      isLastStepSaveClick,
      snapshotId,
    );
  }

  function saveUrl(files: UnmappedFileResponse[]) {
    setUploadedFiles(
      files.map((file) => ({
        csvBlobUrl: file.file,
        unmappedFileUrl: file.url,
        traceId: file.trace_id,
      })),
    );
  }

  const uploadHeader = 'Upload your Invoice listing file.';

  const mapperHeader = 'Assign these headers to your Invoice report';

  return (
    <FullWidthDialog open>
      <Wizard
        devTitle="Invoice"
        stepNames={['Map Columns', 'Validate', 'Review']}
        title="Invoice Upload"
        onClose={onClose}
      >
        <UploadUnmappedCsvStep
          company={company}
          entityClass={UnmappedFileEntityType.InvoiceSnapshot}
          fileTypeWithGrammaticalArticle="an Invoice report"
          header={uploadHeader}
          setSnapshotId={setSnapshotId}
          titleOverride="Invoice Listing"
          onSave={saveUrl}
        />
        <ColumnMapperStep
          columns={columns}
          extraCreateSnapshotArgs={{ company: company.trace_id }}
          setFfMappingId={setFfMappingId}
          setSnapshotId={setSnapshotId}
          stepTitleText={mapperHeader}
          uploadedFiles={uploadedFiles}
          useCreateSnapshotEndpoint={useCreateInvoiceSnapshotFromCsvMutation}
        />
        <InvoiceValidationStep snapshotId={snapshotId} />
        <InvoiceReviewStep
          snapshotId={snapshotId}
          onComplete={() =>
            onComplete
              ? onComplete('Invoice Report uploaded successfully')
              : onCloseProp()
          }
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default InvoiceUploadWizard;
