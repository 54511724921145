import { useMemo } from 'react';

import createScale from '@visx/scale/lib/createScale';
import type { ScaleInput } from '@visx/scale/lib/types/Scale';
import scaleCanBeZeroed from '@visx/scale/lib/utils/scaleCanBeZeroed';
import type { PositionScale } from '@visx/shape/lib/types';
import { extent as d3Extent } from '@visx/vendor/d3-array';
import isDiscreteScale from '@visx/xychart/lib/utils/isDiscreteScale';

import { filterUndefined } from 'utils';

import type { CommonGraphConfig } from '../types';
import combineBarStackData, { getStackValue } from './combineBarStackData';

type XScaleInput = ScaleInput<XScale>;
type XScale = PositionScale;
type YScale = PositionScale;
type Data<Datum> = {
  graphData: Datum[] | undefined;
  graphOptions: CommonGraphConfig & {
    orderOfData?: string[];
    margin?: { top: number; right: number; bottom: number; left: number };
  };
  height: number;
  width: number;
};

function useStackBar<Datum extends { date: string; data?: string }>(
  data: Data<Datum>,
) {
  const { height, width, graphOptions, graphData } = data;
  const xAccessor = useMemo(() => (item: Datum) => item.date, []);
  const yAccessor = useMemo(
    () => (item: Datum) => (item.data ? Number.parseInt(item.data, 10) : 0),
    [],
  );

  const combinedData = combineBarStackData({
    data: graphData ?? [],
    dataKeys: filterUndefined(graphOptions.orderOfData),
    xAccessor,
    yAccessor,
  });

  let { xScaleConfig, yScaleConfig } = graphOptions;
  if (xScaleConfig === undefined) {
    xScaleConfig = {
      type: 'band',
      paddingInner: 0.5,
      paddingOuter: 0.1,
    } as const;
  }
  if (yScaleConfig === undefined) {
    yScaleConfig = { type: 'linear' } as const;
  }

  const { margin } = graphOptions;
  const xRange = filterUndefined([
    margin?.left,
    Math.max(0, width - (margin?.right ?? 0)),
  ]);
  const yRange = filterUndefined([
    Math.max(0, height - (margin?.bottom ?? 0)),
    margin?.top,
  ]);
  const [xMin, xMax] = xRange;
  const [yMin, yMax] = yRange;

  const xValues = combinedData.map(getStackValue);
  const xDomain = isDiscreteScale(xScaleConfig) ? xValues : d3Extent(xValues);

  const yValues = combinedData.flatMap((item) => [
    item.negativeSum,
    item.positiveSum,
  ]);
  const yDomain = isDiscreteScale(yScaleConfig) ? yValues : d3Extent(yValues);

  const xScale = useMemo(() => {
    // d3Extent scale returns NaN domain for empty arrays
    if (xValues.length === 0) {
      return undefined;
    }

    return createScale({
      range: [xMin, xMax],
      domain: xDomain as [XScaleInput, XScaleInput],
      zero: xScaleConfig && scaleCanBeZeroed(xScaleConfig),
      ...(xScaleConfig as object),
    }) as XScale;
  }, [xValues.length, xScaleConfig, xMin, xMax, xDomain]);

  const yScale = useMemo(() => {
    if (yValues.length === 0) {
      return undefined;
    }

    return createScale({
      range: [yMin, yMax],
      domain: yDomain as [YScaleInput, YScaleInput],
      zero: yScaleConfig && scaleCanBeZeroed(yScaleConfig),
      ...(yScaleConfig as object),
    }) as YScale;
  }, [yValues.length, yScaleConfig, yMin, yMax, yDomain]);

  return {
    xScale,
    yScale,
    xRange,
    yRange,
  };
}

type YScaleInput = ScaleInput<YScale>;

export default useStackBar;
