import { StrictMode, useEffect } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import AppWithAuthentication, {
  AppWithoutAuthentication,
} from 'AppWithAuthentication';
import DataPreloader from 'DataPreloader';
import config from 'config';
import 'index.scss';
import store from 'shared/state/store';

if (config.VITE_APP_PAQARINA_SENTRY_DSN) {
  Sentry.init({
    dsn: config.VITE_APP_PAQARINA_SENTRY_DSN,
    environment: config.VITE_APP_NAME,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // allow redux state to be more than 3 by default (Sentry recommends 10)
    normalizeDepth: 10,

    // only trace things that we control, not everything
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\..+\.condorsoftware\.com/,
    ],
  });
}

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <DataPreloader />
        <Auth0Provider
          authorizationParams={{ redirect_uri: window.location.origin }}
          clientId={config.VITE_APP_AUTH0_CLIENT_ID}
          domain={config.VITE_APP_AUTH0_DOMAIN}
          useRefreshTokensFallback={config.IS_CYPRESS_ENVIRONMENT}
          cacheLocation={
            config.IS_CYPRESS_ENVIRONMENT ? 'localstorage' : undefined
          }
        >
          {config.VITE_APP_DISABLE_AUTH ? (
            <AppWithoutAuthentication />
          ) : (
            <AppWithAuthentication />
          )}
        </Auth0Provider>
      </Provider>
    </BrowserRouter>
  </StrictMode>,
);

if (config.IS_CYPRESS_ENVIRONMENT) {
  // eslint-disable-next-line no-console -- this is quite intentional to prove we are in a cypress environment
  console.log(
    '%c WE ARE RUNNING THE CONDOR APP WITHIN CYPRESS',
    'background: #CCC; color: blue; font-size: 24px; padding: 10px;',
  );
}
