import Add from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import withReadOnlyPermissionWrapper from 'shared/lib/read-only-permission/withReadOnlyPermissionWrapper';

type Props = { onHandleVersionSelection: () => void };

function ContractVersionDetailAddVersion(props: Props) {
  const { activeTab } = useParams();
  const { onHandleVersionSelection } = props;

  return (
    <Button
      startIcon={<Add />}
      testId="new_contract"
      variant="outlined"
      disableElevation
      onClick={onHandleVersionSelection}
    >
      {activeTab === 'amendment-in-progress'
        ? 'New Amendment-In-Progress'
        : 'New Current Contract'}
    </Button>
  );
}

export default withReadOnlyPermissionWrapper(
  withPeriodSpecificGenericWrapper(ContractVersionDetailAddVersion),
);
