import type { ChangeEvent, KeyboardEvent } from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { EM_DASH_PLACEHOLDER } from 'shared/components/ag-grid/helpers/shared';
import CondorTextField from 'shared/components/text-field/CondorTextField';

import { getFormattedNumber } from 'formatters';
import { DataType } from 'shared/lib/types';

type ParametersInputProps = {
  dataType: DataType;
  editable?: boolean;
  isOverall?: boolean;
  readOnly?: boolean;
  required?: boolean;
  value: number | string | undefined;
  onChange?: (newValue: string | undefined) => void;
};

function ParameterInput(props: ParametersInputProps) {
  const {
    editable = false,
    dataType,
    value,
    isOverall = false,
    required = false,
    onChange,
    readOnly = false,
  } = props;
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const valueIsStringOrNumber =
    typeof value === 'string' || typeof value === 'number';
  const normalizedValue =
    dataType === DataType.DECIMAL && valueIsStringOrNumber
      ? getFormattedNumber(value)
      : dataType === DataType.INTEGER && valueIsStringOrNumber
        ? getFormattedNumber(value, 0)
        : value;

  const onClickToEdit = () => {
    setShowEdit(true);
  };

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    const trimmedValue = newValue.trim();
    const finalValue = trimmedValue.length === 0 ? undefined : trimmedValue;
    onChange?.(finalValue);
  };

  const onKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setShowEdit(false);
    }
  };

  const onClickAway = () => {
    setShowEdit(false);
  };

  const finalReadOnly = readOnly || !editable || isOverall;

  return (
    <Box
      sx={{
        flexGrow: 1,
        pl: 1,
        py: 0.75,
        alignItems: 'center',
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {finalReadOnly ? (
        <Typography
          sx={{ fontWeight: isOverall ? 'bold' : undefined }}
          variant="caption"
        >
          {normalizedValue ?? EM_DASH_PLACEHOLDER}
        </Typography>
      ) : (
        !showEdit && (
          <>
            {value !== undefined && (
              <Typography variant="caption" onClick={onClickToEdit}>
                {normalizedValue}
              </Typography>
            )}
            {value === undefined && (
              <Typography
                color="text.disabled"
                variant="caption"
                onClick={onClickToEdit}
              >
                Click to edit
              </Typography>
            )}
          </>
        )
      )}
      {showEdit && (
        <CondorTextField
          defaultValue={value}
          required={required}
          size="small"
          type={dataType === DataType.DATE ? 'date' : 'number'}
          variant="standard"
          autoFocus
          onBlur={onClickAway}
          onChange={onChangeValue}
          onKeyUp={onKeyUp}
        />
      )}
    </Box>
  );
}

export default ParameterInput;
