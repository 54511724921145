import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';

import Alert from 'shared/ui/alert/Alert';

import EdcConfirmationStep from 'accruals/wizards/edc-upload-wizard/steps/EdcConfirmationStep';
import Wizard from 'shared/lib/wizard/Wizard';

function EdcVisitsSnapshotValidatorDemo() {
  const [searchParams] = useSearchParams();
  const snapshotId = searchParams.get('snapshotId');

  if (!snapshotId) {
    return (
      <Box>
        <Alert severity="error" title="Error">
          snapshotId is required, please specify it in url `snapshotId=edcs_...`
        </Alert>
      </Box>
    );
  }

  function onClose() {
    //
  }

  return (
    <Wizard
      startIndex={0}
      stepNames={['Validate']}
      title="EDC Upload Demo"
      onClose={onClose}
    >
      <EdcConfirmationStep snapshotId={snapshotId} />
    </Wizard>
  );
}

export default EdcVisitsSnapshotValidatorDemo;
