import AnimatedBar from './AnimatedBar';

type ForecastAnimatedLineProps = {
  isGreater: boolean;
  overContractColor: string;
  underContractColor: string;
  verticalOffset: number;
  x: number;
  y: number;
};

const FORECAST_LINE_WIDTH = 4;
const FORECAST_LINE_HEIGHT = 32;

function ForecastAnimatedLine(props: ForecastAnimatedLineProps) {
  const {
    isGreater,
    overContractColor,
    underContractColor,
    x,
    y,
    verticalOffset,
  } = props;

  return (
    <AnimatedBar
      fill={isGreater ? overContractColor : underContractColor}
      height={FORECAST_LINE_HEIGHT}
      rx={0}
      verticalOffset={verticalOffset}
      width={FORECAST_LINE_WIDTH}
      x={x - FORECAST_LINE_WIDTH / 2} // center the line
      y={y - FORECAST_LINE_HEIGHT / 2}
    />
  );
}

export default ForecastAnimatedLine;
