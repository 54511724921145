import { useCallback } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';

import type { UserDeactivatePopup } from 'shared/lib/types';

import { useUpdateUserAccessMutation } from 'shared/api/rtkq/users';

type UserPermissionColumns = string | undefined;

export default function useEditCondorUser(
  setShowPopup: (showPopup: UserDeactivatePopup) => void,
): [(params: CellValueChangedEvent) => void, boolean] {
  const [updateUserAccess, { isLoading: updateUserAccessLoading }] =
    useUpdateUserAccessMutation();

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      const { data } = event;
      const columnBeingUpdated: UserPermissionColumns = event.colDef.field;
      void (async () => {
        if (columnBeingUpdated === undefined) {
          return;
        }

        if (columnBeingUpdated === 'status' && data.status === 'Inactive') {
          setShowPopup({
            trace_id: data.trace_id,
            email: data.email,
            state: true,
          });
          return;
        }
        await updateUserAccess({
          trace_id: data.trace_id,
          [columnBeingUpdated]: data[columnBeingUpdated],
        });
      })();
    },
    [updateUserAccess, setShowPopup],
  );

  return [onCellValueChanged, updateUserAccessLoading];
}
