import { useMemo } from 'react';

import type {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { useSelector } from 'react-redux';

import type { TraceId } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import {
  useGetContentTypesQuery,
  useLazyGetAuditLogQuery,
} from 'shared/api/rtkq/auditlog';
import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

import {
  mapActionsToBackendValues,
  mapContentTypesToFilterValues,
  mapTrialsToFilterValues,
} from './utils';

const TRIAL_COLUMN = 'trial';
const ACTION_COLUMN = 'action';
const CONTENT_TYPE_COLUMN = 'content_type';

// Audit log is intentionally not period aware as it would be too much data to save
export default function useAuditLogServerSideDatasource(): IServerSideDatasource {
  const currentCompany = useSelector(selectCompany);

  const { currentData: trials } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );
  const { currentData: contentTypes } = useGetContentTypesQuery({
    filters: { company: currentCompany.trace_id },
  });
  const [getAuditLog] = useLazyGetAuditLogQuery();

  return useMemo(
    () => ({
      getRows: async (params: IServerSideGetRowsParams) => {
        let filters: Record<
          string,
          number[] | TraceId | TraceId[] | undefined
        > = {
          company: currentCompany.trace_id,
        };

        if (
          trials &&
          params.request.filterModel &&
          TRIAL_COLUMN in params.request.filterModel
        ) {
          const allTrialFilters = mapTrialsToFilterValues(trials);
          const trialFilters = params.request.filterModel[TRIAL_COLUMN];

          const finalTrialFilters = [];
          for (const filter of trialFilters.values) {
            const trialIndex = allTrialFilters.indexOf(filter);
            if (trialIndex >= 0) {
              finalTrialFilters.push(trials[trialIndex].trace_id);
            }
          }

          filters = { ...filters, trial: finalTrialFilters };
        }

        if (
          contentTypes &&
          params.request.filterModel &&
          CONTENT_TYPE_COLUMN in params.request.filterModel
        ) {
          const allContentTypeFilters =
            mapContentTypesToFilterValues(contentTypes);
          const contentTypeFilters =
            params.request.filterModel[CONTENT_TYPE_COLUMN];

          const finalContentTypeFilters = [];
          for (const filter of contentTypeFilters.values) {
            const contentTypeIndex = allContentTypeFilters.indexOf(filter);
            if (contentTypeIndex >= 0) {
              finalContentTypeFilters.push(contentTypes[contentTypeIndex].id);
            }
          }

          filters = { ...filters, content_type: finalContentTypeFilters };
        }

        if (
          params.request.filterModel &&
          ACTION_COLUMN in params.request.filterModel
        ) {
          const backedEndActions = mapActionsToBackendValues();
          const actionFilters = params.request.filterModel[ACTION_COLUMN];

          const finalActionFilters = [];
          for (const filter of actionFilters.values) {
            finalActionFilters.push(backedEndActions[filter]);
          }

          filters = { ...filters, action: finalActionFilters };
        }

        try {
          const auditLog = await getAuditLog({
            filters,
            page: Math.ceil((params.request.endRow ?? 100) / 100),
          }).unwrap();
          params.success({ rowData: auditLog.rows, rowCount: auditLog.count });
        } catch {
          params.fail();
        }
      },
    }),
    [contentTypes, currentCompany, trials, getAuditLog],
  );
}
