import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'shared/state/store';

type SidebarSection = { key: string; expanded: boolean };

type DynamicObject = { [key: string]: boolean | undefined };

type State = { collapsed: boolean; expandedStates: DynamicObject };

const SIDEBAR_COLLAPSED_KEY = 'sidebar_collapsed';
const SIDEBAR_EXPANDED_ITEM_KEY = 'sidebar_expanded_item';

const initialState: () => State = () => ({
  collapsed: JSON.parse(
    localStorage.getItem(SIDEBAR_COLLAPSED_KEY) ?? 'false',
  ) as boolean,
  expandedStates: JSON.parse(
    localStorage.getItem(SIDEBAR_EXPANDED_ITEM_KEY) ?? '{}',
  ) as DynamicObject,
});

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    updateCollapsed: (state, action: PayloadAction<boolean>) => {
      state.collapsed = action.payload;
      localStorage.setItem(SIDEBAR_COLLAPSED_KEY, action.payload.toString());
    },
    updateExpandedState: (state, action: PayloadAction<SidebarSection>) => {
      state.expandedStates[action.payload.key] = action.payload.expanded;
      localStorage.setItem(
        SIDEBAR_EXPANDED_ITEM_KEY,
        JSON.stringify(state.expandedStates),
      );
    },
  },
});

export const selectSidebarCollapsed = (state: RootState) =>
  state.sidebar.collapsed;
export const selectSidebarExpandedState = (state: RootState) =>
  state.sidebar.expandedStates;

export const { updateCollapsed, updateExpandedState } = sidebarSlice.actions;

export const sidebarReducer = sidebarSlice.reducer;
