import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import useAccrualsGridOptions from 'accruals/pages/analytics/hooks/useAccrualsGridOptions';
import useAccrualsRows from 'accruals/pages/analytics/hooks/useAccrualsRows';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { PeriodGridBlobType } from 'shared/lib/types';

import useAccrualsColumnDefs from '../hooks/useAccrualsColumnDefs';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function AccrualsGrid(props: Props) {
  const { sx, overlayNoRowsTemplate } = props;

  const columnDefs = useGridColDefs(useAccrualsColumnDefs);
  const gridOptions = useGridOptions(useAccrualsGridOptions);
  const rowData = useAccrualsRows();

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  AccrualsGrid,
  PeriodGridBlobType.ACCRUALS,
);
