import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { InvoiceRecordResponse } from 'shared/lib/types';

export function processInvoiceRecords(
  invoiceRecords: InvoiceRecordResponse[] | undefined,
) {
  return cloneDeep(invoiceRecords);
}

export default function useInvoiceListingRows(
  invoiceRecords: InvoiceRecordResponse[] | undefined,
  _isOpenPeriod: boolean,
): InvoiceRecordResponse[] | undefined {
  return useMemo(() => processInvoiceRecords(invoiceRecords), [invoiceRecords]);
}
