import type {
  GenericData,
  TraceId,
  VendorRepoActivitySnapshotResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { CONTRACT_RECON } from './contracts';

const PATH = 'vendorrepactivitysnapshots';
export const VENDOR_REP_ACTIVITY_SNAPSHOT = 'VENDOR_REP_ACTIVITY_SNAPSHOT';

const apiEndpointFactory = constructApi<
  VendorRepoActivitySnapshotResponse,
  GenericData
>(PATH).withTags(VENDOR_REP_ACTIVITY_SNAPSHOT, [CONTRACT_RECON]);
export const api = apiEndpointFactory.inject(() => ({
  deleteVendorRepActivitySnapshot: apiEndpointFactory.delete(),
  applyRegionToVendorRepActivityRecords: apiEndpointFactory.create<{
    snapshot: TraceId;
    region: TraceId;
    region_name: string;
  }>([], 'apply_region'),
  createReportedActivityFromCsv: apiEndpointFactory.create(
    [CONTRACT_RECON],
    'new_from_csv',
  ),
  getDirectFeesSnapshotsByContract: apiEndpointFactory.getWithFilters(),
}));

export const {
  useDeleteVendorRepActivitySnapshotMutation,
  useGetDirectFeesSnapshotsByContractQuery,
  useCreateReportedActivityFromCsvMutation,
} = api;
