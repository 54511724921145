import type { PointerEvent } from 'react';

type Props = {
  height: number;
  left: number;
  top: number;
  width: number;
  onPointerLeave?: (event: PointerEvent<SVGRectElement>) => void;
  onPointerMove?: (event: PointerEvent<SVGRectElement>) => void;
};

function EventEmitter(props: Props) {
  const { left, top, width, height, onPointerMove, onPointerLeave } = props;
  return (
    <rect
      fill="transparent"
      height={height}
      width={width}
      x={left}
      y={top}
      onPointerLeave={(event) => onPointerLeave?.(event)}
      onPointerMove={(event) => {
        onPointerMove?.(event);
      }}
    />
  );
}

export default EventEmitter;
