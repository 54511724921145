import type {
  BottomLineAdjustmentResponse,
  VendorReportedBottomLineAdjustmentResponse,
} from 'shared/lib/types';

import {
  CRO_EXPENSE_SUMMARY,
  OCC_EXPENSE_SUMMARY,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
} from './apiTags';
import constructApi from './constructApi';
import { CONTRACT_EXPENSES, CONTRACT_RECON } from './contracts';

const PATH = 'bottomlineadjustments';

const apiEndpointFactory = constructApi<BottomLineAdjustmentResponse>(
  PATH,
).withTags('BOTTOM_LINE_ADJUSTMENTS', [
  CRO_EXPENSE_SUMMARY,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  OCC_EXPENSE_SUMMARY,
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
]);
const api = apiEndpointFactory.inject(() => ({
  createBottomLineAdjustment: apiEndpointFactory.create([
    CRO_EXPENSE_SUMMARY,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    OCC_EXPENSE_SUMMARY,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
  ]),
  updateBottomLineAdjustment: apiEndpointFactory.update([
    CRO_EXPENSE_SUMMARY,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    OCC_EXPENSE_SUMMARY,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
  ]),
  getBottomLineAdjustmentsByContract: apiEndpointFactory.getBy('contract'),
  deleteBottomLineAdjustment: apiEndpointFactory.delete(),
  upsertVendorRepBlaAmount:
    apiEndpointFactory.update<VendorReportedBottomLineAdjustmentResponse>(
      [
        CRO_EXPENSE_SUMMARY,
        PERIOD_OCC_EXPENSES,
        PERIOD_OCC_RECON,
        OCC_EXPENSE_SUMMARY,
        CONTRACT_EXPENSES,
        CONTRACT_RECON,
      ],
      'upsert_vendor_rep_bla_amount',
    ),
}));

export const {
  useGetBottomLineAdjustmentsByContractQuery,
  useLazyGetBottomLineAdjustmentsByContractQuery,
  useDeleteBottomLineAdjustmentMutation,
  useUpdateBottomLineAdjustmentMutation,
  useCreateBottomLineAdjustmentMutation,
  useUpsertVendorRepBlaAmountMutation,
} = api;
