import { useEffect } from 'react';

import Add from '@mui/icons-material/Add';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import Button from 'shared/ui/button/Button';

import type {
  RegionGroupListItemType,
  RegionListItemType,
  TraceId,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';

import RegionGroupListItem from './RegionGroupListItem';

type Props = {
  contractVersionTraceId: TraceId;
  defaultRegionGroups: RegionGroupListItemType[];
  regionGroups: RegionGroupListItemType[];
  regions: RegionListItemType[];
  setRegionGroups: (regionGroups: RegionGroupListItemType[]) => void;
};

function RegionGroupList(props: Props) {
  const {
    contractVersionTraceId,
    regions,
    regionGroups,
    setRegionGroups,
    defaultRegionGroups,
  } = props;

  function handleRegionGroupChange(
    regionGroupListItem: RegionGroupListItemType,
  ) {
    const index = regionGroups.findIndex(
      (regionGroupToFind) =>
        regionGroupToFind.trace_id === regionGroupListItem.trace_id,
    );
    regionGroups.splice(index, 1, regionGroupListItem);
    setRegionGroups([...regionGroups]);
  }

  function handleAddRegionGroup() {
    let fakeId = regionGroups.length.toString(); // We need a unique identifier even though we haven't saved to the database yet.
    const existingTraceIds = new Set(
      regionGroups.map((regionGroup) => regionGroup.trace_id),
    );
    while (existingTraceIds.has(fakeId)) {
      fakeId = `${fakeId}${fakeId}`; // If we get a duplicate number due to repeated add/delete, double up the string to avoid collisions.
    }
    regionGroups.push({
      trace_id: fakeId,
      name: '',
      regions: [],
      contract_version: contractVersionTraceId,
      global: false,
      new: true,
      action: CRUDAction.CREATE,
    });

    setRegionGroups([...regionGroups]);
  }

  const nonDeletedRegionGroups = regionGroups.filter(
    (regionGroupToFind) => regionGroupToFind.action !== CRUDAction.DELETE,
  );

  const firstRegionGroupName = regionGroups[0]?.name;
  useEffect(() => {
    // Length check is 1 because we have a default global region group
    if (regionGroups.length === 1 && defaultRegionGroups.length > 0) {
      setRegionGroups([...defaultRegionGroups]);
    }
  }, [defaultRegionGroups, firstRegionGroupName, defaultRegionGroups]);

  return (
    <>
      <Typography sx={{ fontWeight: 'normal' }} variant="subtitle2">
        Region Groups
      </Typography>
      <Typography
        color="text.secondary"
        style={{ marginTop: '5px' }}
        variant="body1"
      >
        A default region group is already created and includes all added
        regions.
      </Typography>
      <List>
        {nonDeletedRegionGroups.map((regionGroupListItem, index) => (
          <RegionGroupListItem
            key={`${regionGroupListItem.trace_id ?? ''}_${index}`}
            disabled={regionGroupListItem.global}
            label="Region group name"
            regionGroup={regionGroupListItem}
            regionGroups={regionGroups}
            regions={regions}
            canDelete={
              !regionGroupListItem.global && nonDeletedRegionGroups.length > 1
            } // we require a region to always be added (including global)
            onRegionGroupChange={handleRegionGroupChange}
          />
        ))}
      </List>
      <Button
        startIcon={<Add />}
        testId="add_region_group"
        variant="text"
        disableElevation
        onClick={handleAddRegionGroup}
      >
        Add Region Group
      </Button>
    </>
  );
}

export default RegionGroupList;
