import { useMemo } from 'react';

import { getMoneyCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

export default function usePurchaseOrdersColumnDefs(
  _isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      { field: 'trace_id', hide: true, flex: 1 },
      { headerName: 'PO #', field: 'po_number', flex: 1 },
      { headerName: 'Vendor', field: 'vendor', aggFunc: 'totalLabel', flex: 1 },
      {
        headerName: 'PO amount',
        field: 'amount_amount',
        valueGetter: 'Number(node.data.po_amount)',
        aggFunc: 'sum',
        ...getMoneyCellConfig({ currencyFooterStyle: 'decimal' }),
        flex: 1,
      },
      { headerName: 'G/L account', field: 'gl_account', flex: 1 },
      { headerName: 'Description', field: 'description', flex: 1 },
      { headerName: 'PO date', field: 'date', flex: 1 },
      { headerName: 'Status', field: 'status', flex: 1 },
      { headerName: 'Trial ID', field: 'study_id', flex: 1 },
      { headerName: 'Currency', field: 'currency', flex: 1 },
    ],
    [],
  );
}
