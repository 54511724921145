import type { ProceduresEdcSnapshotResponse } from 'shared/lib/types';

import { ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG } from './administrativeorprocedurecategories';
import constructApi from './constructApi';

const PATH = 'proceduresedcsnapshots';

const apiEndpointFactory = constructApi<ProceduresEdcSnapshotResponse>(
  PATH,
).withTags('PROCEDURES_EDC_SNAPSHOTS', [
  ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG,
]);
const api = apiEndpointFactory.inject(() => ({
  createProceduresEdcSnapshotFromCsv: apiEndpointFactory.create(
    [ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG],
    'new_from_csv',
  ),
  updateEdcProceduresSnapshot: apiEndpointFactory.update(),
  getEdcProceduresSnapshotsByPeriod: apiEndpointFactory.getBy(
    'period',
    '-created_at',
  ),
  deleteEdcProceduresSnapshot: apiEndpointFactory.delete(),
  postSaveProceduresRecords: apiEndpointFactory.create([], 'post_save_records'),
}));

export const {
  useCreateProceduresEdcSnapshotFromCsvMutation,
  useDeleteEdcProceduresSnapshotMutation,
  usePostSaveProceduresRecordsMutation,
  useGetEdcProceduresSnapshotsByPeriodQuery,
} = api;
