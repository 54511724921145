import Box from '@mui/material/Box';

import AssumptionGroupSection from 'shared/lib/contract-assumptions/assumption-groups/AssumptionGroupSection';
import type {
  AssumptionGroupRequestDisplay,
  RegionListItemType,
} from 'shared/lib/types';
import { AssumptionGroupSectionName } from 'shared/lib/types';

function AssumptionGroupSectionsViewer(props: ContractAssumptions) {
  const { assumptionGroups, regions, versionName } = props;

  if (
    typeof assumptionGroups === 'undefined' ||
    typeof regions === 'undefined'
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
        alignItems: 'top',
        justifyContent: 'space-evenly',
        gap: 2,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <AssumptionGroupSection
          assumptionGroups={assumptionGroups}
          regionMap={{}}
          regions={regions}
          setCustomInheritedContract={(_value) => void 0}
          type={AssumptionGroupSectionName.TIMELINE}
          versionName={versionName}
          readOnly
        />
        <AssumptionGroupSection
          assumptionGroups={assumptionGroups}
          regionMap={{}}
          regions={regions}
          setCustomInheritedContract={(_value) => void 0}
          sx={{ mt: 4 }}
          type={AssumptionGroupSectionName.SITES}
          versionName={versionName}
          readOnly
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <AssumptionGroupSection
          assumptionGroups={assumptionGroups}
          regionMap={{}}
          regions={regions}
          setCustomInheritedContract={(_value) => void 0}
          type={AssumptionGroupSectionName.PATIENT_ENROLLMENT}
          versionName={versionName}
          readOnly
        />
        <AssumptionGroupSection
          assumptionGroups={assumptionGroups}
          regionMap={{}}
          regions={regions}
          setCustomInheritedContract={(_value) => void 0}
          sx={{ mt: 4 }}
          type={AssumptionGroupSectionName.PATIENT_MONTHS}
          versionName={versionName}
          readOnly
        />
      </Box>
    </Box>
  );
}

export type ContractAssumptions = {
  assumptionGroups?: AssumptionGroupRequestDisplay[];
  regions?: RegionListItemType[];
  versionName?: string;
};

export default AssumptionGroupSectionsViewer;
