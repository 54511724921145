import type { MouseEvent } from 'react';
import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { animated, useTransition } from '@react-spring/web';
import type { AxisScale } from '@visx/axis/lib/types';
import type { Bar, BarsProps } from '@visx/xychart/lib/types/series';
import {
  cleanColor,
  colorHasUrl,
} from '@visx/xychart/lib/utils/cleanColorString';
import getScaleBaseline from '@visx/xychart/lib/utils/getScaleBaseline';

function enterUpdate({ x, y, width, height, fill }: Bar) {
  return {
    x,
    y,
    width,
    height,
    fill: cleanColor(fill),
    opacity: 1,
  };
}

type BarTransitionConfig<Scale extends AxisScale> = {
  horizontal?: boolean;
  scale: Scale;
};

type CondorBar = Bar & {
  stroke?: string;
  strokeWidth?: number | string;
};

function useBarTransitionConfig<Scale extends AxisScale>(
  data: BarTransitionConfig<Scale>,
) {
  const { scale, horizontal } = data;
  const shouldAnimateX = !!horizontal;
  return useMemo(() => {
    const scaleBaseline = getScaleBaseline(scale);

    function fromLeave({ x, y, width, height, fill }: Bar) {
      return {
        x: shouldAnimateX ? scaleBaseline : x,
        y: shouldAnimateX ? y : scaleBaseline,
        width: shouldAnimateX ? 0 : width,
        height: shouldAnimateX ? height : 0,
        fill: cleanColor(fill),
        opacity: 0,
      };
    }

    return {
      unique: true,
      from: fromLeave,
      leave: fromLeave,
      enter: enterUpdate,
      update: enterUpdate,
      keys: (bar: Bar) => bar.key,
    };
  }, [scale, shouldAnimateX]);
}

function AnimatedBarsUnrounded<
  XScale extends AxisScale,
  YScale extends AxisScale,
>(data: CondorBarsProps<XScale, YScale>) {
  const {
    bars,
    xScale,
    yScale,
    horizontal,
    onMouseMoveOverBarStack,
    onMouseLeaveBarStack,
    ...rectProps
  } = data;
  const themeMode = useTheme().palette.mode;

  const animatedBars = useTransition(bars, {
    ...useBarTransitionConfig({
      horizontal,
      scale: horizontal ? xScale : yScale,
    }),
  });
  const isFocusable = Boolean(rectProps.onFocus ?? rectProps.onBlur);
  return (
    <>
      {animatedBars(
        (
          // @ts-expect-error x/y aren't in react-spring types (which are HTML CSS properties)
          { x, y, width, height, fill, opacity },
          item: CondorBar,
          { key },
        ) =>
          key === null ? null : (
            <animated.rect
              key={key}
              className="visx-bar"
              // use the item's fill directly if it's not animate-able
              fill={colorHasUrl(item.fill) ? item.fill : fill}
              height={height}
              opacity={opacity}
              rx={2}
              stroke={item.stroke}
              strokeWidth={item.strokeWidth}
              tabIndex={isFocusable ? 0 : undefined}
              width={width}
              x={x}
              y={y}
              style={{
                mixBlendMode: themeMode === 'light' ? 'multiply' : 'normal',
              }}
              {...rectProps}
              onMouseLeave={onMouseLeaveBarStack}
              onMouseMove={(event) => {
                const [index] = /\d+$/.exec(key) ?? [];
                onMouseMoveOverBarStack?.(
                  event,
                  index ? Number.parseInt(index, 10) : undefined,
                );
              }}
            />
          ),
      )}
    </>
  );
}

export type CondorBarsProps<
  XScale extends AxisScale,
  YScale extends AxisScale,
> = BarsProps<XScale, YScale> & {
  bars: CondorBar[];
  onMouseMoveOverBarStack?: (
    event: MouseEvent<SVGElement>,
    stackIndex?: number,
  ) => void;
  onMouseLeaveBarStack?: (event: MouseEvent<SVGElement>) => void;
};

/** Wrapper component which renders a Bars component depending on whether it needs rounded corners. */
export default function CondorAnimatedBars<
  XScale extends AxisScale,
  YScale extends AxisScale,
>(props: CondorBarsProps<XScale, YScale>) {
  return <AnimatedBarsUnrounded {...props} />;
}
