import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import getSharedExpenseSummaryColumnDefs from 'accruals/pages/clinical-expenses/shared/hooks/getSharedExpenseSummaryColumnDefs';
export default function useCroExpenseSummaryColumnDefs(
  isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      {
        headerName: 'Cost category',
        field: 'cost_category',
        flex: 1,
        cellDataType: 'text',
        valueFormatter: 'humanizeCostCategory',
        aggFunc: 'totalLabel',
      },
      ...getSharedExpenseSummaryColumnDefs(isOpenPeriod, true),
    ],
    [isOpenPeriod],
  );
}
