import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import {
  type GenericData,
  type GlAccountResponse,
  GlAccountTypeTranslation,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetGlAccountsByCompanyQuery } from 'shared/api/rtkq/glaccounts';

export function processGlAccounts(glAccounts: GlAccountResponse[] | undefined) {
  const glAccountSorter = (
    glA: { accountNumber: string },
    glB: { accountNumber: string },
  ) => {
    const accountNumberA = glA.accountNumber.toUpperCase();
    const accountNumberB = glB.accountNumber.toUpperCase();
    return accountNumberA.localeCompare(accountNumberB);
  };

  return cloneDeep(
    (glAccounts ?? [])
      .map((row) => ({
        accountNumber: row.account_number,
        accountType:
          GlAccountTypeTranslation[
            row.account_type as keyof typeof GlAccountTypeTranslation
          ],
        description: row.description,
        trial: 'All trials',
        traceId: row.trace_id,
      }))
      .sort(glAccountSorter),
  );
}

export default function useGlAccountRows(): GenericData[] {
  const currentCompany = useSelector(selectCompany);
  const { currentData: glAccounts } = useGetGlAccountsByCompanyQuery(
    currentCompany.trace_id,
  );

  return useMemo(() => processGlAccounts(glAccounts), [glAccounts]);
}
