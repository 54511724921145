import { useMemo } from 'react';

import FolderOpen from '@mui/icons-material/FolderOpen';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useDispatch, useSelector } from 'react-redux';

import { useChangeForecast } from 'forecasting/state/slices/forecastSlice';
import * as routes from 'routes';
import HiddenForReadOnlyPermission from 'shared/lib/read-only-permission/HiddenForReadOnlyPermission';
import useIsReadOnlyPermission from 'shared/lib/read-only-permission/useIsReadOnlyPermission';
import type {
  ForecastFolderResponse,
  ForecastResponse,
} from 'shared/lib/types';
import {
  openDeleteForecastFolderModal,
  openDeleteForecastModal,
  openDuplicateForecastModal,
  openRenameForecastFolderModal,
  openRenameForecastModal,
} from 'shared/state/slices/modalsSlice';
import { selectSidebarCollapsed } from 'shared/state/slices/sidebarSlice';
import { selectTrial } from 'shared/state/slices/trialSlice';
import SidebarContainer, {
  DIVIDER,
  ICON_SX,
} from 'shared/lib/sidebar/SidebarContainer';
import SidebarMenuItems from 'shared/lib/sidebar/SidebarMenuItems';
import type { SidebarSectionItem } from 'shared/lib/sidebar/SidebarSection';
import SidebarSkeletonContainer from 'shared/lib/sidebar/SidebarSkeletonContainer';
import SidebarTabs from 'shared/lib/sidebar/SidebarTabs';
import TrialNameAndBackButton from 'shared/lib/sidebar/TrialNameAndBackButton';

import { useGetForecastMenuItemsQuery } from 'shared/api/rtkq/forecasts';

import ForecastCreateButton from './ForecastCreateButton';

function ForecastingSidebar() {
  const trial = useSelector(selectTrial);
  const collapsed = useSelector(selectSidebarCollapsed);
  const isReadOnly = useIsReadOnlyPermission();

  const dispatch = useDispatch();
  const changeForecast = useChangeForecast();

  const { currentData, isLoading, isUninitialized, isFetching } =
    useGetForecastMenuItemsQuery(trial.trace_id);
  const { forecasts, folders: forecastFolders } = currentData ?? {
    forecasts: [],
    folders: [],
  };

  const navigationItems = useMemo(() => {
    if (forecasts.length === 0 && forecastFolders.length === 0) {
      return [];
    }

    const forecastMenu = (forecast: ForecastResponse) => [
      {
        label: 'Rename',
        action: () => dispatch(openRenameForecastModal(forecast)),
      },
      {
        label: 'Duplicate',
        action: () => dispatch(openDuplicateForecastModal(forecast)),
      },
      {
        label: 'Delete',
        action: () => dispatch(openDeleteForecastModal(forecast)),
      },
    ];

    const forecastFolderMenu = (folder: ForecastFolderResponse) => [
      {
        label: 'Rename',
        action: () => dispatch(openRenameForecastFolderModal(folder)),
      },
      {
        label: 'Delete',
        action: () => dispatch(openDeleteForecastFolderModal(folder)),
      },
    ];

    const menuItems: Array<SidebarSectionItem | typeof DIVIDER> = forecasts.map(
      (forecast: ForecastResponse) => ({
        title: forecast.name,
        link: routes.getSpecificForecast(forecast.trace_id),
        onClick: () => changeForecast(forecast),
        icon: forecast.locked ? (
          <LockIcon sx={ICON_SX} />
        ) : (
          <LockOpenIcon sx={ICON_SX} />
        ),
        menu: isReadOnly ? undefined : forecastMenu(forecast),
      }),
    );

    for (const folder of forecastFolders) {
      if (menuItems.length > 0) {
        menuItems.push(DIVIDER);
      }
      menuItems.push({
        title: folder.name,
        icon: <FolderOpen sx={ICON_SX} />,
        menu: isReadOnly ? undefined : forecastFolderMenu(folder),
        subNav: folder.forecasts?.map((forecast: ForecastResponse) => ({
          title: forecast.name,
          link: routes.getSpecificForecast(forecast.trace_id),
          icon: forecast.locked ? (
            <LockIcon sx={ICON_SX} />
          ) : (
            <LockOpenIcon sx={ICON_SX} />
          ),
          onClick: () => changeForecast(forecast),
          menu: isReadOnly ? undefined : forecastMenu(forecast),
        })),
      });
    }

    return menuItems;
  }, [forecasts, forecastFolders, dispatch, changeForecast, isReadOnly]);

  const finalLoading = isUninitialized || isFetching || isLoading;

  return (
    <SidebarContainer>
      <TrialNameAndBackButton collapsed={collapsed} />
      <SidebarTabs activeTab="forecasting" collapsed={collapsed} />
      <HiddenForReadOnlyPermission>
        <ForecastCreateButton collapsed={collapsed} />
      </HiddenForReadOnlyPermission>
      {finalLoading ? (
        <SidebarSkeletonContainer collapsed={collapsed} numSkeletons={4} />
      ) : (
        <SidebarMenuItems collapsed={collapsed} items={navigationItems} />
      )}
    </SidebarContainer>
  );
}

export default ForecastingSidebar;
