import ParentSize from '@visx/responsive/lib/components/ParentSize';

import RateVsEnrollmentGraph from 'forecasting/components/graphing/rate-vs-enrollment-graph/RateVsEnrollmentGraph';

import { data } from './data';

function RunRateVsEnrollment() {
  return (
    <ParentSize>
      {(parent) => (
        <RateVsEnrollmentGraph
          graphData={data}
          latestCloseDate="2025-01-01T00:00:00"
          width={parent.width}
        />
      )}
    </ParentSize>
  );
}

export default RunRateVsEnrollment;
