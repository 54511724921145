import type { ForwardedRef } from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

import Button from 'shared/ui/button/Button';

import DragAndDropWrapper from './DragAndDropWrapper';
import FileDisplay from './FileDisplay';
import UploadIcon from './icons/upload.svg?react';

type Props = {
  accept?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  hideAddedFiles?: boolean;
  hideSelectFile?: boolean;
  multiple?: boolean;
  submitButtonText?: string;
  sx?: SxProps;
  uploadButtonText?: string;
  onChangeFiles?: (files: File[]) => void;
  onSubmitClick?: (files: File[]) => void;
};

function FileUploader(props: Props, ref: ForwardedRef<FileUploaderRef>) {
  const {
    onChangeFiles,
    onSubmitClick,
    accept,
    multiple,
    disabled,
    uploadButtonText,
    submitButtonText,
    children,
    sx,
    hideSelectFile = false,
    hideAddedFiles = false,
  } = props;
  const [files, setFiles] = useState<File[]>([]);

  useImperativeHandle(ref, () => ({
    getFileInput() {
      return fileInput();
    },
  }));

  const handleSubmitClick = () => {
    onSubmitClick?.(files);
  };

  const uploadButtonTitle =
    files.length > 0
      ? `Change File${multiple ? 's' : ''}`
      : (uploadButtonText ?? 'Upload File');

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const newFiles = [...event.target.files];
      setFiles(newFiles);
      onChangeFiles?.(newFiles);
    }
  }

  function onDrop(newFiles: File[]) {
    setFiles(newFiles);
    onChangeFiles?.(newFiles);
  }

  function fileInput() {
    return (
      <input
        accept={accept ?? ''}
        data-testid="upInput"
        disabled={disabled}
        multiple={multiple}
        type="file"
        hidden
        onChange={handleFileUpload}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        gap: 3,
        ...sx,
      }}
    >
      <DragAndDropWrapper sx={{ width: '100%' }} onChangeFiles={onDrop}>
        {fileInput()}
        {!hideSelectFile && (
          <>
            <UploadIcon />
            <Typography
              sx={{ color: (theme) => theme.palette.grey[700] }}
              variant="h6"
            >
              Drag and drop file here or select a file from your computer.
            </Typography>
            <Button
              component="label"
              testId="upload_button_title"
              variant={files.length <= 0 ? 'outlined' : 'contained'}
            >
              {uploadButtonTitle}
              {fileInput()}
            </Button>
            {!hideAddedFiles && (
              <Box
                sx={{
                  textAlign: 'left',
                  width: 'fit-content',
                  margin: 'auto',
                  lineHeight: '25px',
                  fontSize: '12px',
                }}
              >
                <FileDisplay descriptorText="Selected File: " files={files} />
              </Box>
            )}
            {onSubmitClick && (
              <Button
                disabled={files.length === 0}
                testId="submit"
                variant="contained"
                onClick={handleSubmitClick}
              >
                {submitButtonText ?? 'Submit'}
              </Button>
            )}
          </>
        )}
      </DragAndDropWrapper>
      {children}
    </Box>
  );
}

export type FileUploaderRef = { getFileInput: () => React.ReactNode | null };
export default forwardRef(FileUploader);
