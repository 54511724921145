import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSidebarCollapsed,
  updateCollapsed,
} from 'shared/state/slices/sidebarSlice';

import SidebarToggleButton from './SidebarToggleButton';

type Props = {
  children: ReactNode;
};

function SidebarContainer(props: Props) {
  const { children } = props;

  const collapsed = useSelector(selectSidebarCollapsed);
  const dispatch = useDispatch();

  const onCollapse = () => {
    dispatch(updateCollapsed(!collapsed));
  };

  return (
    <Box
      component="aside"
      sx={{
        backgroundColor: 'background.paper',
        borderRight: (theme) => `2px solid ${theme.palette.divider}`,
        flex: 0,
        minWidth: MENU_WIDTH,
        transition: (theme) => theme.transitions.create('min-width'),
        overflowY: 'auto',
        overflowX: 'hidden',
        pb: 2,
        ...(collapsed && {
          minWidth: COLLAPSED_MENU_WIDTH,
          maxWidth: COLLAPSED_MENU_WIDTH,
          p: 0,
        }),
      }}
    >
      {children}
      <SidebarToggleButton collapsed={collapsed} onClick={onCollapse} />
    </Box>
  );
}

export const MENU_WIDTH = 256 as const;
export const COLLAPSED_MENU_WIDTH = 64 as const;
export const ICON_SX = { fontSize: 16 };
export const DIVIDER = 'DIVIDER' as const;

export default SidebarContainer;
