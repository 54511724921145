import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import IconButton from 'shared/ui/icon-button/IconButton';

import type { ContractFile } from 'shared/lib/types';

import type { ContractFileWithOgIndex } from './DocumentsInfoForm';
import { tagsList } from './DocumentsInfoForm';

type DocumentsListProps = {
  documents: ContractFileWithOgIndex[];
  errors?: ContractFile[];
  getSelectedTagOption: (
    tag: string,
  ) => { label: string; value: string } | undefined;
  handleRemoveDocument: (ogIndex: number) => void;
  handleTagChange: (tag: string, ogIndex: number) => void;
};
export default function DocumentsList(props: DocumentsListProps) {
  const {
    documents,
    errors,
    getSelectedTagOption,
    handleTagChange,
    handleRemoveDocument,
  } = props;
  return (
    <>
      {documents.map((doc, index) => (
        <Stack
          key={index}
          alignItems="center"
          flexDirection="row"
          my={1.25}
          sx={{
            ...(errors?.find((error) => error.file_name === doc.file_name) && {
              border: 1,
              borderColor: 'error.main',
              borderRadius: '4px',
            }),
          }}
        >
          <Box
            sx={{
              width: 350,
              backgroundColor: 'grey.100',
              padding: 1,
              display: 'inline-flex',
              borderRadius: 1,
              marginRight: 3,
            }}
          >
            <DescriptionOutlinedIcon sx={{ marginX: 1 }} />
            {doc.file ? (
              <a
                href={doc.file as unknown as string}
                rel="noreferrer"
                target="_blank"
              >
                <Typography
                  color="grey.700"
                  title={doc.file_name}
                  variant="body1"
                >
                  {doc.file_name}
                </Typography>
              </a>
            ) : (
              <Typography
                color="grey.700"
                title={doc.file_name}
                variant="body1"
              >
                {doc.file_name}
              </Typography>
            )}
          </Box>
          <FormControl fullWidth>
            <Autocomplete
              label="File category"
              options={tagsList}
              value={getSelectedTagOption(doc.tag)}
              onChange={(evt, value) => {
                evt.preventDefault();
                handleTagChange(value?.value ?? '', doc.ogIndex);
              }}
            />
          </FormControl>
          <IconButton
            size="small"
            sx={{ ml: 2 }}
            title={doc.traceId ? 'Delete' : 'Remove'}
            onClick={() => handleRemoveDocument(doc.ogIndex)}
          >
            {doc.traceId ? (
              <DeleteOutlineOutlinedIcon />
            ) : (
              <ClearOutlinedIcon />
            )}
          </IconButton>
        </Stack>
      ))}
    </>
  );
}
