import { useMemo, useState } from 'react';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import Dropdown from 'shared/ui/dropdown/Dropdown';

import EdcProceduresUploadWizard from 'accruals/wizards/csv-upload-wizards/edc-procedures/EdcProceduresUploadWizard';
import EdcUploadWizard from 'accruals/wizards/edc-upload-wizard/EdcUploadWizard';
import HiddenForReadOnlyPermission from 'shared/lib/read-only-permission/HiddenForReadOnlyPermission';
import type { LabelValueOption } from 'shared/lib/types';

enum EdcSnapshot {
  VISITS = 'EDC - visits',
  UNSCHEDULED_VISITS = 'EDC - unscheduled visits',
  SCREEN_FAIL = 'EDC - screen fail',
  PROCEDURES = 'EDC - procedures',
}

function UploadEdcSnapshots() {
  const [showUploadVisits, setShowUploadVisits] = useState<boolean>(false);
  const [showUploadUnscheduledVisits, setShowUploadUnscheduledVisits] =
    useState<boolean>(false);
  const [showUploadScreenFail, setShowUploadScreenFail] =
    useState<boolean>(false);
  const [showUploadProcedures, setShowUploadProcedures] =
    useState<boolean>(false);

  const dropDownOptions: Array<LabelValueOption<EdcSnapshot>> = useMemo(
    () =>
      Object.entries(EdcSnapshot).map((snapshot) => {
        const [value, label] = snapshot;
        return { value, label };
      }),
    [],
  );

  function onSelectOption(option: LabelValueOption<EdcSnapshot>) {
    switch (option.label) {
      case EdcSnapshot.VISITS:
        setShowUploadVisits(true);
        break;
      case EdcSnapshot.UNSCHEDULED_VISITS:
        setShowUploadUnscheduledVisits(true);
        break;
      case EdcSnapshot.SCREEN_FAIL:
        setShowUploadScreenFail(true);
        break;
      case EdcSnapshot.PROCEDURES:
        setShowUploadProcedures(true);
        break;
    }
  }

  return (
    <>
      <HiddenForReadOnlyPermission>
        <Dropdown
          options={dropDownOptions}
          title="Upload EDC"
          buttonProps={{
            testId: 'upload_edc',
            startIcon: <FileUploadOutlinedIcon />,
            endIcon: <KeyboardArrowDownOutlinedIcon />,
          }}
          onSelectOption={onSelectOption}
        />
      </HiddenForReadOnlyPermission>
      {showUploadVisits && (
        <EdcUploadWizard
          fileUploadType="EDCS"
          onClose={() => setShowUploadVisits(false)}
          onComplete={() => setShowUploadVisits(false)}
        />
      )}
      {showUploadUnscheduledVisits && (
        <EdcUploadWizard
          fileUploadType="UVS"
          onClose={() => setShowUploadUnscheduledVisits(false)}
          onComplete={() => setShowUploadUnscheduledVisits(false)}
        />
      )}
      {showUploadProcedures && (
        <EdcProceduresUploadWizard
          fileUploadType="PES"
          onClose={() => setShowUploadProcedures(false)}
          onComplete={() => setShowUploadProcedures(false)}
        />
      )}
      {showUploadScreenFail && (
        <EdcUploadWizard
          fileUploadType="SFS"
          onClose={() => setShowUploadScreenFail(false)}
          onComplete={() => setShowUploadScreenFail(false)}
        />
      )}
    </>
  );
}

export default UploadEdcSnapshots;
