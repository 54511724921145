import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetPurchaseOrderRecordsBySnapshotQuery } from 'shared/api/rtkq/purchaseorderrecords';

import columns from '../columns';

type Props = { snapshotId?: string; onComplete: () => void };

function PurchaseOrdersReviewStep(props: Props) {
  const { onComplete, snapshotId } = props;

  const { currentData: allRecords } = useGetPurchaseOrderRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  );

  return (
    <WizardStep
      header="Review your PO listing to make sure everything looks right."
      onNext={onComplete}
    >
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default PurchaseOrdersReviewStep;
