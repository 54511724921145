import AddIcon from '@mui/icons-material/Add';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import { PatientAssessmentTimelinePortionEnum } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import {
  closeCohortNameEditor,
  insertCohort,
  insertVisit,
  selectCohortNameEditor,
  selectCohorts,
  selectIsCohortsListInitialized,
  showCohortNameEditor,
} from 'shared/state/slices/visitSchedulesSlice';

import useSavePatientCohort from '../../hooks/useSavePatientCohort';
import useSavePatientVisit from '../../hooks/useSavePatientVisit';
import CohortInput from './CohortInput';

function AddNewCohort() {
  const dispatch = useDispatch();
  const trial = useSelector(selectTrial);
  const cohorts = useSelector(selectCohorts);
  const cohortNameEditor = useSelector(selectCohortNameEditor);
  const isCohortsListInitialized = useSelector(selectIsCohortsListInitialized);
  const [savePatientCohort] = useSavePatientCohort();
  const [savePatientVisit] = useSavePatientVisit();

  async function onAccept(value: string) {
    const { data: cohortData } = await savePatientCohort({
      name: value,
      patientAssessments: [],
      trialId: trial.trace_id,
      orderIndex: cohorts.length,
      isNew: true,
    });

    if (!cohortData) {
      return;
    }

    const { data: visitData } = await savePatientVisit({
      name: 'Visit 1',
      patientCohort: cohortData.traceId!,
      orderIndex: 0,
      mappedName: undefined,
      isScreen: false,
      isEnroll: false,
      isScreenfail: false,
      isDroppedCompleted: false,
      dayOfProtocol: null,
      isNew: true,
      timelinePortion: PatientAssessmentTimelinePortionEnum.OTHER,
    });

    if (!visitData) {
      return;
    }

    if (!isCohortsListInitialized) {
      // If there are no cohorts, just close editor.
      // Cohort will be added to the list by the effect in the Wizard.
      dispatch(closeCohortNameEditor());
      return;
    }

    dispatch(
      insertCohort({
        name: value,
        traceId: cohortData.traceId!,
        trialId: trial.trace_id,
      }),
    );
    dispatch(
      insertVisit({
        visit: {
          patientCohort: cohortData.traceId!,
          name: visitData.name,
          traceId: visitData.trace_id,
          orderIndex: visitData.order_index,
          isScreen: visitData.is_screen,
          isEnroll: visitData.is_enroll,
          isScreenfail: visitData.is_screenfail,
          isDroppedCompleted: visitData.is_dropped_completed,
          dayOfProtocol: visitData.day_of_protocol,
          timelinePortion: visitData.timeline_portion,
        },
      }),
    );
  }

  return (
    <>
      {cohortNameEditor === 'new' && (
        <ListItem
          sx={{ paddingRight: (theme) => theme.spacing(1) }}
          disableGutters
          divider
        >
          <CohortInput
            cohorts={cohorts}
            value=""
            onAccept={(value) => void onAccept(value)}
            onCancel={() => dispatch(closeCohortNameEditor())}
          />
        </ListItem>
      )}
      <ListItem disableGutters divider>
        <ListItemText
          primary={
            <Button
              disabled={cohortNameEditor === 'new'}
              size="small"
              startIcon={<AddIcon />}
              testId="add_cohort"
              sx={{
                boxShadow: 'none !important',
              }}
              disableRipple
              onClick={() => dispatch(showCohortNameEditor({ editor: 'new' }))}
            >
              Add cohort
            </Button>
          }
        />
      </ListItem>
    </>
  );
}

export default AddNewCohort;
