import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';

import Alert from 'shared/ui/alert/Alert';

import classes from 'shared/lib/column-mapper/ColumnMapperTable.module.scss';
import type { SnapshotRecordType } from 'shared/lib/types';

import type { HandleAcceptForFieldValueMapper } from './validation-handlers/FieldValueMapper';
import FieldValueMapper from './validation-handlers/FieldValueMapper';
import type { HandleAcceptForRollupRow } from './validation-handlers/RollupRowHandler';
import RollupRowHandler from './validation-handlers/RollupRowHandler';

type ValidationSidebarProps = {
  allSiteRecordOptions?: string[];
  handleAcceptForFieldValueMapper?: HandleAcceptForFieldValueMapper;
  handleAcceptForRollupRow?: HandleAcceptForRollupRow;
  rows?: SnapshotRecordType[];
  selectedErrorIndex?: number;
  setSelectedErrorIndex?: (index: number) => void;
  validationErrors: ValidationError[];
};

function ValidationSidebar(props: ValidationSidebarProps) {
  const {
    rows,
    validationErrors,
    selectedErrorIndex,
    setSelectedErrorIndex,
    allSiteRecordOptions,
    handleAcceptForFieldValueMapper,
    handleAcceptForRollupRow,
  } = props;
  const selectedError =
    selectedErrorIndex !== undefined
      ? validationErrors[selectedErrorIndex]
      : undefined;

  const onPrevIssueClick = () => {
    if (selectedErrorIndex !== undefined) {
      const newIssueIndex = selectedErrorIndex - 1;
      if (newIssueIndex > -1) {
        setSelectedErrorIndex?.(newIssueIndex);
      }
    }
  };

  const onNextIssueClick = () => {
    if (selectedErrorIndex !== undefined) {
      const currentIssueIndex = selectedErrorIndex;
      const newIssueIndex = currentIssueIndex + 1;
      if (newIssueIndex < validationErrors.length) {
        setSelectedErrorIndex?.(newIssueIndex);
      }
    }
  };

  const getErrorHandler = () => {
    if (selectedError === undefined || selectedErrorIndex === undefined) {
      return <div>Select an issue to view details</div>;
    }

    switch (selectedError.type) {
      case 'project':
      case 'subject':
      case 'site':
      case 'visit_name':
      case 'visdat':
      case 'study_id':
      case 'site_group':
      case 'savets':
      case 'cohort':
        return (
          <FieldValueMapper
            allSiteRecordOptions={allSiteRecordOptions}
            handleAccept={handleAcceptForFieldValueMapper}
            rows={rows}
            selectedErrorIndex={selectedErrorIndex}
            validationErrors={validationErrors}
          />
        );
      case 'rollup':
        return (
          <RollupRowHandler
            handleAccept={handleAcceptForRollupRow}
            selectedErrorIndex={selectedErrorIndex}
            validationErrors={validationErrors}
          />
        );
      default:
        return <div>Unknown error</div>;
    }
  };

  return (
    <aside className={classes.validationSidebar}>
      <div className={classes.validationSidebarHeader}>
        <Typography variant="subtitle1">
          <strong>Issues</strong>
        </Typography>
        <Typography
          className={classes.validationSidebarHeaderNavigate}
          variant="caption"
        >
          {!!(validationErrors.length && selectedErrorIndex !== undefined) && (
            <>
              <ChevronLeft onClick={onPrevIssueClick} />{' '}
              {selectedErrorIndex + 1} OF {validationErrors.length}
              <ChevronRight onClick={onNextIssueClick} />
            </>
          )}
        </Typography>
      </div>
      {selectedError ? (
        getErrorHandler()
      ) : (
        <div className={classes.validationSidebarContent}>
          <Alert severity="success" variant="filled">
            All issues resolved!
          </Alert>
        </div>
      )}
    </aside>
  );
}

export type ValidationError = {
  type:
    | 'cohort'
    | 'project'
    | 'rollup'
    | 'savets'
    | 'site_group'
    | 'site'
    | 'study_id'
    | 'subject'
    | 'visdat'
    | 'visit_name';
  row: number;
  fields: string[];
};
export default ValidationSidebar;
