import type { TimelineData } from 'forecasting/components/graphing/timeline-chart/types';

import type { LabelValueOption } from 'shared/lib/types';

export const donut: Array<LabelValueOption<string, number>> = [
  { label: 'NA', value: 2 },
  { label: 'EU', value: 1 },
  { label: 'AP', value: 4 },
];

export const timeline: TimelineData[] = [
  { start: '2022-01-01', end: '2022-02-28', parameter: 'Startup' },
  { start: '2022-03-01', end: '2022-05-15', parameter: 'Conduct' },
  { start: '2022-05-16', end: '2022-08-15', parameter: 'Enrollment' },
  { start: '2022-08-16', end: '2022-10-15', parameter: 'Treatment' },
  { start: '2022-10-16', end: '2022-12-15', parameter: 'Follow-up' },
  { start: '2022-12-16', end: '2023-06-01', parameter: 'Close' },
  { start: '2022-01-01', end: '2023-06-01', parameter: 'Overall' },
];
