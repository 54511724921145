// Validation handlers for validations where we need to map user inputted value of specific field(s) to a record in the database.
// e.g. mapping region name to region id, mapping site name/number to site id, etc.

import { useEffect, useState } from 'react';

import ArrowRight from '@mui/icons-material/ArrowRight';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Button from 'shared/ui/button/Button';
import Select from 'shared/ui/select/Select';

import {
  extractRangesFromArrayOfNumbers,
  humanize,
} from 'shared/helpers/helpers';
import classes from 'shared/lib/column-mapper/ColumnMapperTable.module.scss';
import type { SnapshotRecordType } from 'shared/lib/types';

import type { ValidationError } from '../ValidationSidebar';

type Props = {
  allSiteRecordOptions?: string[];
  handleAccept?: HandleAcceptForFieldValueMapper;
  rows?: SnapshotRecordType[];
  selectedErrorIndex?: number;
  validationErrors?: ValidationError[];
};

function FieldValueMapper(props: Props) {
  const {
    rows,
    validationErrors,
    selectedErrorIndex,
    allSiteRecordOptions,
    handleAccept,
  } = props;
  const [selectedFieldValue, setSelectedFieldValue] = useState<
    string | undefined
  >();
  const [applyToAllRows, setApplyToAllRows] = useState<boolean>(true);
  const [rowsWithSameValue, setRowsWithSameValue] = useState<number[]>([]);

  const selectedError =
    selectedErrorIndex !== undefined
      ? validationErrors?.[selectedErrorIndex]
      : undefined;

  useEffect(() => {
    if (selectedErrorIndex === undefined) {
      return;
    }

    const selectedValidationError = validationErrors?.[selectedErrorIndex];
    if (!selectedValidationError) {
      return;
    }

    const newRowsWithSameValue: number[] = [];
    rows?.forEach((row, rowIndex) => {
      // Check if all fields mentioned in the error have the same value as `row`
      // e.g. if `selectedValidationError.fields` is ['site_name', 'site_number'], then check if errorRow['site_name'] === row['site_number'] && errorRow['site_number'] === row['site_number']
      const hasSameValues = selectedValidationError.fields.every((field) => {
        const errorRowData = rows[selectedValidationError.row];
        return row[field] === errorRowData[field];
      });

      if (hasSameValues) {
        newRowsWithSameValue.push(rowIndex);
      }
    });
    setRowsWithSameValue(newRowsWithSameValue);
  }, [rows, selectedErrorIndex, validationErrors]);

  const handleFieldValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedFieldValue(event.target.value);
  };

  function getDateInput(label: string) {
    return (
      <CondorTextField
        InputLabelProps={{ shrink: true }}
        defaultValue="20223-01-01"
        label={label}
        type="date"
        onChange={handleFieldValueChange}
      />
    );
  }

  function getSelectOptions(type: ValidationError['type']) {
    return type === 'site' ? allSiteRecordOptions : [];
  }

  function getSelectInput(type: ValidationError['type']) {
    return (
      <Select
        className={classes.regionSelector}
        label="Select accepted format"
        value={selectedFieldValue ?? ''}
        onChange={handleFieldValueChange}
      >
        {getSelectOptions(type)}
      </Select>
    );
  }

  function getTextInput(label: string) {
    return (
      <CondorTextField
        InputLabelProps={{ shrink: true }}
        label={label}
        type="text"
        onChange={handleFieldValueChange}
      />
    );
  }

  function getInput(type: ValidationError['type']) {
    switch (type) {
      case 'site':
        return getSelectInput(type);
      case 'visdat':
      case 'savets':
        return getDateInput('Date');
      case 'project':
      case 'study_id':
      case 'subject':
      case 'site_group':
      case 'visit_name':
        return getTextInput('Text');
      default:
        break;
    }
  }

  const getOgFieldValues = () => {
    const ogFieldValues: Record<string, string> = {};
    if (selectedErrorIndex !== undefined) {
      const selectedValidationError = validationErrors?.[selectedErrorIndex];
      if (selectedValidationError) {
        const selectedRow = rows?.[selectedValidationError.row];
        for (const field of selectedValidationError.fields) {
          const value = selectedRow?.[field];
          if (value === null || value === undefined) {
            ogFieldValues[field] = '';
          } else {
            // TODO: Currently coercing boolean values for "Screen Fail Y/N" to string. Would we prefer to display the words "Yes" and "No" instead?
            ogFieldValues[field] = value.toString();
          }
        }
      }
    }
    return ogFieldValues;
  };

  const ogFieldValues = getOgFieldValues();

  const onClick = () => {
    if (selectedFieldValue) {
      void handleAccept?.(
        applyToAllRows,
        selectedFieldValue,
        rowsWithSameValue,
        ogFieldValues,
      );
    }
    // Select should be set to empty after every call
    setSelectedFieldValue('');
  };

  if (selectedError === undefined || selectedErrorIndex === undefined) {
    return null;
  }

  return (
    <>
      <div className={classes.validationSidebarContent}>
        <Typography variant="body1">
          We could not recognize the {humanize(selectedError.fields.join(', '))}{' '}
          for:
        </Typography>
        <br />
        <Stack direction="row" spacing="10px">
          <Typography variant="body1">
            Column: {humanize(selectedError.fields.join(', '))}
          </Typography>
          <Typography variant="body1">Row: {selectedErrorIndex + 1}</Typography>
        </Stack>
        <br />
        <Stack direction="row" spacing="20px">
          <CondorTextField
            InputLabelProps={{ shrink: true }}
            label="Original format"
            value={Object.values(ogFieldValues).join(', ')}
            disabled
          />
          <ArrowRight className={classes.arrowRight} />
          {getInput(selectedError.type)}
        </Stack>
        <br />
        <Typography variant="body1">
          The following cells have the same formatting. Apply changes to all?
        </Typography>
        <br />
        <Stack direction="row" spacing="10px">
          <Typography variant="body1">
            Column: {humanize(selectedError.fields.join(', '))}
          </Typography>
          <Typography variant="body1">
            Row: {extractRangesFromArrayOfNumbers(rowsWithSameValue)}
          </Typography>
        </Stack>
        <RadioGroup
          value={applyToAllRows ? 'applyToAll' : 'applyToOne'}
          onChange={(_, value) => {
            setApplyToAllRows(value === 'applyToAll');
          }}
        >
          <FormControlLabel
            control={<Radio />}
            label="Apply to all rows"
            value="applyToAll"
          />
          <FormControlLabel
            control={<Radio />}
            label="Apply to this row only"
            value="applyToOne"
          />
        </RadioGroup>
      </div>
      <div className={classes.validationSidebarFooter}>
        <Button
          className={classes.validationSidebarFooterButton}
          disabled={!selectedFieldValue}
          testId="accept"
          variant="contained"
          onClick={onClick}
        >
          Accept
        </Button>
      </div>
    </>
  );
}

export type HandleAcceptForFieldValueMapper = (
  allRows: boolean,
  selectedValue: string,
  rowsWithSameValue: number[],
  csvFieldValues: Record<string, string>,
) => Promise<void>;
export default FieldValueMapper;
