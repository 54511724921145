import type { SiteListingRecordResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'sitelistingrecords';

const apiEndpointFactory = constructApi<SiteListingRecordResponse>(
  PATH,
).withTags('SITE_LISTING_RECORDS');
const api = apiEndpointFactory.inject(() => ({
  createSiteListingRecord: apiEndpointFactory.create(),
  updateSiteListingRecord: apiEndpointFactory.update(),
  getSiteListingRecordsBySnapshot: apiEndpointFactory.getBy(
    'snapshot',
    'snapshot_row',
  ),
  deleteSiteListingRecord: apiEndpointFactory.delete(),
}));

export const { useGetSiteListingRecordsBySnapshotQuery } = api;
