import type { ReactElement, ReactNode } from 'react';

import Cancel from '@mui/icons-material/Cancel';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';

import IconButton from 'shared/ui/icon-button/IconButton';

type DialogTitleWithCloseProps = {
  children?: ReactNode;
  divider?: boolean;
  stepper?: ReactElement;
  sx?: SxProps<Theme>;
  onClose?: () => void;
};

function DialogTitleWithClose(props: DialogTitleWithCloseProps) {
  const { children, onClose, stepper, sx, divider = true } = props;

  return (
    <DialogTitle
      sx={{
        mb: divider ? 2 : 0,
        borderBottom: divider
          ? (theme) => `1px solid ${theme.palette.divider}`
          : undefined,
        ...sx,
      }}
    >
      <Typography color="text.primary" component="div" variant="subtitle1">
        {children}
      </Typography>
      {stepper}
      {onClose && (
        <IconButton size="small" onClick={onClose}>
          <Cancel />
        </IconButton>
      )}
    </DialogTitle>
  );
}

export default DialogTitleWithClose;
