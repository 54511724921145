import type { PurchaseOrderRecordResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'purchaseorderrecords';

export const PURCHASE_ORDER_RECORDS_TAG = 'PURCHASE_ORDER_RECORDS';

const apiEndpointFactory = constructApi<PurchaseOrderRecordResponse>(
  PATH,
).withTags(PURCHASE_ORDER_RECORDS_TAG);

export const api = apiEndpointFactory.inject(() => ({
  getPurchaseOrdersByCompany: apiEndpointFactory.getBy('company'),
  getPurchaseOrderRecordsBySnapshot: apiEndpointFactory.getBy(
    'snapshot',
    'snapshot_row',
  ),
}));

export const {
  useGetPurchaseOrderRecordsBySnapshotQuery,
  useLazyGetPurchaseOrdersByCompanyQuery,
  useGetPurchaseOrdersByCompanyQuery,
} = api;
