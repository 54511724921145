import { useMemo } from 'react';

import {
  getGenericCellConfig,
  getNativeCurrencyMoneyCellConfig,
  getPercentCellConfig,
  getTrialMoneyCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import { formatMonthDayYear } from 'shared/helpers/helpers';
import type {
  AccrualsGridRow,
  BsFluxRow,
  PeriodResponse,
} from 'shared/lib/types';

export default function useBsFluxColumnDefs(
  _isOpenPeriod: boolean,
  currentPeriod?: PeriodResponse | null,
  lastQuarterPeriod?: PeriodResponse | null,
): Array<
  | CondorColDef<AccrualsGridRow | BsFluxRow>
  | CondorColGroupDef<AccrualsGridRow | BsFluxRow>
> {
  return useMemo(() => {
    if (currentPeriod === null || currentPeriod === undefined) {
      return [];
    }

    const baseColumns = [
      { headerName: 'PO #s', field: 'po_numbers', ...getGenericCellConfig() },
      { headerName: 'G/L account', field: 'gl_account_number' },
      { headerName: 'Vendor', field: 'vendor_name', aggFunc: 'totalLabel' },
      { headerName: 'Vendor type', width: 50, field: 'vendor_type' },
      {
        headerName: 'Cost categories',
        width: 350,
        field: 'cost_categories',
        ...getGenericCellConfig(),
      },
      {
        headerName: 'Contract value (Native)',
        field: 'native_contract_value',
        ...getNativeCurrencyMoneyCellConfig(),
      },
      {
        headerName: 'Contract value (Trial)',
        field: 'default_contract_value',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
    ];

    const periodColumns = (prefix = ''): CondorColDef[] => [
      {
        headerName: 'Reconciled expense',
        field: `${prefix}reconciled_expense`,
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Accrued expense',
        field: `${prefix}accrued_expense`,
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Short-term prepaid',
        field: `${prefix}short_term_prepaid`,
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
    ];

    const fluxColumns: CondorColDef[] = [
      {
        headerName: 'LTD reconciled expense',
        field: 'difference_reconciled_expense',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: '% flux',
        valueGetter:
          'node.footer ? ((node.aggData?.period1_reconciled_expense - node.aggData?.period2_reconciled_expense) / node.aggData?.period2_reconciled_expense) * 100 : node.data?.flux_reconciled_expense',
        ...getPercentCellConfig({ useEmDash: false }),
      },
      {
        headerName: 'Accrued',
        field: 'difference_accrued_expense',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: '% flux',
        valueGetter:
          'node.footer ? ((node.aggData?.period1_accrued_expense - node.aggData?.period2_accrued_expense) / node.aggData?.period2_accrued_expense) * 100 : node.data?.flux_accrued_expense',
        ...getPercentCellConfig({ useEmDash: false }),
      },
      {
        headerName: 'Prepaid',
        field: 'difference_short_term_prepaid',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: '% flux',
        valueGetter:
          'node.footer ? ((node.aggData?.period1_short_term_prepaid - node.aggData?.period2_short_term_prepaid) / node.aggData?.period2_short_term_prepaid) * 100 : node.data?.flux_short_term_prepaid',
        ...getPercentCellConfig({ useEmDash: false }),
      },
    ];

    let columns = [
      { headerName: 'Contract', children: baseColumns },
      {
        headerName: `Current period (as of ${formatMonthDayYear(currentPeriod.end_date)})`,
        children: periodColumns(),
      },
    ] as CondorColGroupDef[];

    if (lastQuarterPeriod?.end_date) {
      columns = [
        ...columns,
        {
          headerName: `Prior period (as of ${formatMonthDayYear(lastQuarterPeriod.end_date)})`,
          children: periodColumns('period2_'),
        },
        {
          headerName: 'Flux - current period and prior period',
          children: fluxColumns,
        },
      ];
    } else {
      columns = [
        ...columns,
        {
          headerName: 'Prior period',
          children: [
            {
              valueGetter:
                'node?.rowIndex === 0 ? "No equivalent period in the prior quarter to compare to." : ""',
              width: 360,
              ...getGenericCellConfig({ useEmDash: false }),
            },
          ],
        },
        {
          headerName: 'Flux',
          children: [
            {
              valueGetter:
                'node?.rowIndex === 0 ? "No equivalent period in the prior quarter to compare to." : ""',
              width: 360,
              ...getGenericCellConfig({ useEmDash: false }),
            },
          ],
        },
      ];
    }

    return columns;
  }, [currentPeriod, lastQuarterPeriod]);
}
