import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import FileUploader from 'shared/components/file-uploader/FileUploader';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import type { SiteFileRequest, SiteRecordRequest } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useDeleteSiteFileMutation } from 'shared/api/rtkq/sitefiles';

import type { SiteFileWithOgIndex } from '../../../contract-version-wizard/DocumentsInfoForm';
import { tagsList } from '../../../contract-version-wizard/DocumentsInfoForm';
import DocumentsList from '../../../contract-version-wizard/DocumentsList';

type Props = {
  documentsList: SiteFileRequest[];
  siteRecord: Partial<SiteRecordRequest>;
  uploadErrors?: SiteFileRequest[];
  onChangeDocuments: (documents: SiteFileRequest[]) => void;
};

function SiteDocuments(props: Props) {
  const { documentsList, uploadErrors, onChangeDocuments, siteRecord } = props;
  const currentCompany = useSelector(selectCompany);
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const [deleteSiteFile] = useDeleteSiteFileMutation();

  const allDocuments: SiteFileWithOgIndex[] = documentsList.map(
    (doc, index) => ({ ...doc, ogIndex: index }),
  );

  // These are the real bytes, so filter them out so we don't try to display them
  const newDocuments = allDocuments
    .filter((document) => !document.trace_id)
    .map((document) => {
      const { file, ...rest } = document;
      return { ...rest };
    });
  const documents = allDocuments.filter((document) => document.trace_id);

  function getSelectedTagOption(tag: string) {
    return tagsList.find((option) => option.value === tag);
  }

  function handleTagChange(tag: string, ogIndex: number) {
    allDocuments[ogIndex].tag = tag;
    onChangeDocuments([...allDocuments]);
  }

  function removeNewDocument(index: number) {
    newDocuments.splice(index, 1);

    onChangeDocuments([...newDocuments, ...documents]);
  }

  async function removeExistingDocument(ogIndex: number) {
    const { trace_id } = allDocuments[ogIndex];
    if (!trace_id) {
      return;
    }

    await deleteSiteFile(trace_id).unwrap();
  }

  function onChangeFiles(files: File[]) {
    onChangeDocuments([
      ...documentsList,
      ...[...files].map((file) => ({
        tag: '',
        file,
        file_name: file.name,
        company: currentCompany.trace_id,
        period_version: currentPeriodVersion.trace_id,
        site: siteRecord.trace_id ?? '',
      })),
    ]);
  }

  return (
    <>
      <FileUploader
        accept=".pdf, .docx, .csv, .xlsx"
        sx={{ width: '100%' }}
        uploadButtonText="Select file"
        hideAddedFiles
        multiple
        onChangeFiles={onChangeFiles}
      />
      {(!!newDocuments.length || !!documents.length) && (
        <Box mt={3} py={2.5}>
          {newDocuments.length > 0 && (
            <>
              <Typography variant="body2">New files</Typography>
              <DocumentsList
                documents={newDocuments}
                errors={uploadErrors}
                getSelectedTagOption={getSelectedTagOption}
                handleRemoveDocument={removeNewDocument}
                handleTagChange={handleTagChange}
              />
            </>
          )}
          {documents.length > 0 && (
            <>
              <Typography variant="body2">Uploaded files</Typography>
              <DocumentsList
                documents={documents}
                getSelectedTagOption={getSelectedTagOption}
                handleTagChange={handleTagChange}
                handleRemoveDocument={(ogIndex: number) =>
                  void removeExistingDocument(ogIndex)
                }
              />
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default SiteDocuments;
