import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { InvoiceRecordResponse } from 'shared/lib/types';

export default function useInvoiceListingGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<InvoiceRecordResponse> {
  return useMemo(() => ({ getRowId: 'trace_id', grandTotalRow: 'bottom' }), []);
}
