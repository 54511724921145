import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import getSharedExpenseSummaryColumnDefs from 'accruals/pages/clinical-expenses/shared/hooks/getSharedExpenseSummaryColumnDefs';

export default function useOccExpenseSummaryColDefs(
  isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      // { field: 'contract_container_trace_id', hide: true }, // TODO(tyler): This is UUID, not slug, so don't expose until we fix. We only need this for linking to a contract's details page.
      {
        field: 'vendor_name',
        headerName: 'Vendor',
        flex: 1,
        aggFunc: 'totalLabel',
      },
      { field: 'po_numbers', headerName: 'PO #(s)', flex: 1 },
      ...getSharedExpenseSummaryColumnDefs(isOpenPeriod, false),
    ],
    [isOpenPeriod],
  );
}
