import type { Ref } from 'react';
import { forwardRef, useState } from 'react';

/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
import CheckCircle from '@mui/icons-material/CheckCircle';
import Error from '@mui/icons-material/Error';
import Info from '@mui/icons-material/Info';
import Warning from '@mui/icons-material/Warning';
import MuiAlert from '@mui/material/Alert';
import type { AlertProps as MuiAlertProps } from '@mui/material/Alert/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function Alert(props: AlertProps, ref: Ref<HTMLDivElement>) {
  const { title, dismissable, children, onClose, ...otherProps } = props;
  const [dismissed, setDismissed] = useState(false);

  return !dismissed ? (
    <MuiAlert
      {...otherProps}
      ref={ref}
      variant="standard"
      iconMapping={{
        info: <Info sx={{ fill: (theme) => theme.palette.grey[900] }} />,
        success: (
          <CheckCircle sx={{ fill: (theme) => theme.palette.success.dark }} />
        ),
        warning: (
          <Warning sx={{ fill: (theme) => theme.palette.warning.dark }} />
        ),
        error: <Error sx={{ fill: (theme) => theme.palette.error.dark }} />,
      }}
      onClose={onClose ?? (dismissable ? () => setDismissed(true) : undefined)}
    >
      {title ? <AlertTitle>{title}</AlertTitle> : undefined}
      {children}
    </MuiAlert>
  ) : undefined;
}

export type { AlertColor } from '@mui/material/Alert';
/* eslint-enable @typescript-eslint/no-restricted-imports */
export type AlertProps = MuiAlertProps & {
  title?: string;
  dismissable?: boolean;
};

export default forwardRef(Alert);
