import { type ReactElement, useState } from 'react';

import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import logo from 'shared/assets/images/logo.png';
import Alert from 'shared/ui/alert/Alert';
import Button from 'shared/ui/button/Button';

import ErrorContainer from 'shared/containers/error-container/ErrorContainer';

import { useReverifyUserMutation } from 'shared/api/rtkq/users';

type Props = { userId?: string };

export default function VerifyUser(props: Props): ReactElement {
  const { userId } = props;
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [reverifyUser] = useReverifyUserMutation();

  function resendVerificationEmail(auth0UserId: string | undefined) {
    void (async () => {
      await reverifyUser({ auth0UserId });
      setShowSuccessMsg(true);
    })();
  }

  return (
    <ErrorContainer>
      <Box component="img" height={80} src={logo} sx={{ mb: 2 }} width={80} />
      <Typography color="primary" sx={{ mb: 2 }} variant="h4">
        Welcome to Condor
      </Typography>
      <Typography align="center" data-testid="user_verify" variant="body1">
        Please verify your account with the link we sent to your email.
        <br />
        <br />
        Need help? Contact us at{' '}
        <a href="mailto:support@condorsoftware.com">
          support@condorsoftware.com
        </a>
        {/*
         */}.
      </Typography>
      <br />
      <Button
        testId="resend_verification_email"
        variant="contained"
        onClick={() => resendVerificationEmail(userId)}
      >
        Resend Email
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showSuccessMsg}
        onClose={() => setShowSuccessMsg(false)}
      >
        <Alert severity="success">Verification email sent successfully.</Alert>
      </Snackbar>
    </ErrorContainer>
  );
}
