import type { ChangeEvent } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';

import Cancel from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import Checkbox from 'shared/ui/checkbox/Checkbox';
import IconButton from 'shared/ui/icon-button/IconButton';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';
import { useUpdateUserAccessMutation } from 'shared/api/rtkq/users';

type Props = {
  handleClose: () => void;
  isOpen: boolean;
  name: string;
  title: string;
  userId: string;
  userTrials: string[];
};

type TrialCheckbox = {
  checked: boolean;
  trialTraceId: string;
  programTraceId: string;
};

function TrialAccessDialog(props: Props) {
  const { handleClose, isOpen, name, title, userId, userTrials } = props;
  const currentCompany = useSelector(selectCompany);
  const { data: trialsByCompany } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );
  const [updateUserAccess, { isLoading: updateUserAccessLoading }] =
    useUpdateUserAccessMutation();

  const allTrialPrograms = useMemo(
    () => trialsByCompany?.map((trial) => trial.program),
    [trialsByCompany],
  );
  const trialsByProgram = useMemo(
    () => groupBy(trialsByCompany ?? [], 'program.trace_id'),
    [trialsByCompany],
  );
  const programs = useMemo(
    () =>
      orderBy(uniqBy(allTrialPrograms, 'trace_id'), [
        (po) => po.name.toLowerCase(),
      ]),
    [allTrialPrograms],
  );

  const [trialCheckboxes, setTrialCheckboxes] = useState<TrialCheckbox[]>([]);

  useEffect(() => {
    if (isOpen) {
      const initialTrialChecked: TrialCheckbox[] =
        trialsByCompany?.map((trial) => ({
          trialTraceId: trial.trace_id,
          programTraceId: trial.program.trace_id,
          checked:
            userTrials.includes(trial.trace_id) ||
            userTrials.includes('All Trials'),
        })) ?? [];

      setTrialCheckboxes(initialTrialChecked);
    }
  }, [isOpen, trialsByCompany, userTrials]);

  const selectAllChecked = trialCheckboxes.every((trial) => trial.checked);
  const selectAllIndeterminate =
    !selectAllChecked && trialCheckboxes.some((trial) => trial.checked);

  const handleTrialCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    trialTraceId: string,
  ) => {
    setTrialCheckboxes((prev) =>
      prev.map((trial) =>
        trial.trialTraceId === trialTraceId
          ? { ...trial, checked: event.target.checked }
          : trial,
      ),
    );
  };

  const handleProgramCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    programTraceId: string,
  ) => {
    trialsByProgram[programTraceId].map((trial) =>
      handleTrialCheckboxOnChange(event, trial.trace_id),
    );
  };

  const handleSelectAllCheckboxOnChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    programs.map(({ trace_id }) =>
      handleProgramCheckboxOnChange(event, trace_id),
    );
  };

  const handleUpdateTrialAccess = () => {
    void (async () => {
      const trialsToUpdate = selectAllChecked
        ? []
        : trialCheckboxes
            .filter((trial) => trial.checked)
            .map((trial) => trial.trialTraceId);

      await updateUserAccess({ trace_id: userId, trials: trialsToUpdate });
      handleClose();
    })();
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pb: 0 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            color={(theme) => theme.palette.grey[800]}
            variant="body1"
          >
            {name.toUpperCase()}
          </Typography>
          <DialogContent sx={{ pl: 0, pt: 1 }}>{title}</DialogContent>
        </Box>
        <IconButton size="small" onClick={handleClose}>
          <Cancel />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ py: 3 }}>
        <Typography variant="body1">
          <strong>
            Select which trials the selected users should have access to.
          </strong>
        </Typography>
        <FormControlLabel
          label="Select all"
          sx={{ pl: 2, my: 1 }}
          control={
            <Checkbox
              checked={selectAllChecked}
              indeterminate={selectAllIndeterminate}
              onChange={handleSelectAllCheckboxOnChange}
            />
          }
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pl: 2,
            overflowY: 'scroll',
            maxHeight: '500px',
          }}
        >
          {programs.map(({ trace_id: programTraceId, name: programName }) => {
            const trialCheckboxesForProgram = trialCheckboxes.filter(
              (trial) => trial.programTraceId === programTraceId,
            );
            const programChecked = trialCheckboxesForProgram.every(
              (trial) => trial.checked,
            );
            const programIndeterminate =
              !programChecked &&
              trialCheckboxesForProgram.some((trial) => trial.checked);

            return (
              <Box key={programTraceId}>
                <Divider sx={{ py: 1, borderBottom: '1px lightgrey solid' }} />
                <FormControlLabel
                  label={programName}
                  sx={{ fontWeight: 'bold' }}
                  control={
                    <Checkbox
                      checked={programChecked}
                      indeterminate={programIndeterminate}
                      onChange={(event) =>
                        handleProgramCheckboxOnChange(event, programTraceId)
                      }
                    />
                  }
                />
                {trialsByProgram[programTraceId].map((trial) => (
                  <Box
                    key={trial.trace_id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}
                    >
                      <FormControlLabel
                        label={trial.study_id}
                        control={
                          <Checkbox
                            checked={
                              trialCheckboxes.find(
                                (trialCheckbox) =>
                                  trialCheckbox.trialTraceId === trial.trace_id,
                              )?.checked ?? false
                            }
                            onChange={(event) =>
                              handleTrialCheckboxOnChange(event, trial.trace_id)
                            }
                          />
                        }
                      />
                      <Typography sx={{ pl: 4 }}>{trial.indication}</Typography>
                    </Box>
                    <Typography
                      sx={{
                        borderRadius: 1,
                        backgroundColor: (theme) => theme.palette.grey[100],
                        p: 0.5,
                      }}
                    >
                      Phase {trial.phase}
                    </Typography>
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={trialCheckboxes.every((tcb) => !tcb.checked)}
          loading={updateUserAccessLoading}
          testId="test_update_trial_access"
          variant="contained"
          onClick={handleUpdateTrialAccess}
        >
          Update Trial Access
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default memo(TrialAccessDialog);
