import type SnapshotCell from './SnapshotCell';
import type { Validity } from './Validity';

class ValueTypeCondition implements Validity {
  // required or not column condition?
  isValid(cell: SnapshotCell): boolean {
    return typeof cell.getValue() !== 'undefined';
  }
}

export default ValueTypeCondition;
