import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Autocomplete from 'shared/ui/autocomplete/Autocomplete';

import { getDropdownOptionsFromArray } from 'shared/helpers/helpers';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetAdministrativeOrProcedureCategoriesByTrialQuery } from 'shared/api/rtkq/administrativeorprocedurecategories';

type Props = {
  isAllInOneFile: boolean;
  procedureType: string;
  setIsAllInOneFile: (isAllInOneFile: boolean) => void;
  setProcedureType: (procedureType: string) => void;
};

function EdcProceduresTypeSelector(props: Props) {
  const { isAllInOneFile, setIsAllInOneFile, procedureType, setProcedureType } =
    props;
  const trial = useSelector(selectTrial);
  const { currentData: administrativeOrProcedureCategories = [] } =
    useGetAdministrativeOrProcedureCategoriesByTrialQuery(trial.trace_id);
  const existingProcedureCategories =
    administrativeOrProcedureCategories.filter(
      (category) => category.category_type === 'INV',
    );
  const [dropdownOptions, setDropdownOptions] = useState(
    getDropdownOptionsFromArray(
      existingProcedureCategories.map((category) => category.name),
    ),
  );

  useEffect(() => {
    setDropdownOptions(
      getDropdownOptionsFromArray(
        existingProcedureCategories.map((category) => category.name),
      ),
    );
  }, [existingProcedureCategories, procedureType]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, my: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography className="required" variant="h6">
          What procedures does this file contain?
        </Typography>
        <Typography color="text.secondary" sx={{ mt: 1 }} variant="body1">
          You can upload one file with all your procedures, or separate files
          for each procedure type.
        </Typography>
      </Box>
      <RadioGroup
        sx={{ display: 'flex', flex: 1 }}
        value={isAllInOneFile.toString()}
        onChange={(event) => setIsAllInOneFile(event.target.value === 'true')}
      >
        <FormControlLabel
          control={<Radio />}
          label="All procedures"
          value="true"
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <FormControlLabel
            className={isAllInOneFile ? '' : 'required'}
            control={<Radio />}
            label="Single procedure"
            value="false"
          />
          <Autocomplete
            disabled={isAllInOneFile}
            label="Procedure type"
            options={dropdownOptions}
            placeholder="Select or add a procedure"
            sx={{ flex: 1, width: '100%' }}
            value={
              dropdownOptions.find(
                (option) => option.value === procedureType,
              ) ?? procedureType
            }
            autoSelect
            freeSolo
            selectOnFocus
            onChange={(_event, value) =>
              setProcedureType(
                typeof value === 'string' ? value : (value?.value ?? ''),
              )
            }
          />
        </Box>
      </RadioGroup>
    </Box>
  );
}

export default EdcProceduresTypeSelector;
