import type {
  ContractBudgetRecordRequest,
  ContractBudgetRecordResponse,
} from 'shared/lib/types';

import { PERIOD_OCC_EXPENSES, PERIOD_OCC_RECON } from './apiTags';
import constructApi from './constructApi';
import {
  CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
} from './contracts';
import { TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT } from './periods';

const PATH = 'contractbudgetrecords';

export const CONTRACT_BUDGET_RECORDS = 'CONTRACT_BUDGET_RECORDS';

const apiEndpointFactory = constructApi<
  ContractBudgetRecordResponse,
  ContractBudgetRecordRequest
>(PATH).withTags(CONTRACT_BUDGET_RECORDS, [
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
  TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
]);
const api = apiEndpointFactory.inject(() => ({
  updateContractBudgetRecord: apiEndpointFactory.update([
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
    TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
  ]),
  getContractBudgetRecordsBySnapshot: apiEndpointFactory.getBy(
    'snapshot',
    'snapshot_row',
  ),
}));

export const {
  useGetContractBudgetRecordsBySnapshotQuery,
  useUpdateContractBudgetRecordMutation,
} = api;
