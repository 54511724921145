const roller = document.createElement('div');
roller.className = 'lds-roller';
roller.innerHTML =
  '<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>';

window.removeRoller = () => {
  if (document.body.contains(roller)) {
    document.body.removeChild(roller);
  }
};

window.addRoller = () => {
  if (!document.body.contains(roller)) {
    document.body.appendChild(roller);
  }
};

window.addRoller();
