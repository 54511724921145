function getNumberFormatter(options?: Intl.NumberFormatOptions) {
  const defaultOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat('en-US', { ...defaultOptions, ...options });
}

const currencyFormatter = getNumberFormatter();
const numberFormatter = getNumberFormatter({
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const numberToCurrencyFormat = (num: number): string =>
  currencyFormatter.format(num);

export const numberToString = (number: number, emptyValue = 'N/A'): string => {
  if (Number.isNaN(number)) {
    return emptyValue;
  }

  return numberFormatter.format(number);
};
