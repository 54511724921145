import { useMemo } from 'react';

import type {
  CellClassParams,
  ICellRendererParams,
} from '@ag-grid-community/core';
import cloneDeep from 'lodash/cloneDeep';

import AgGridValidationCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-validation-cell-renderer/AgGridValidationCellRenderer';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import type { CondorColDef } from 'shared/components/ag-grid/types';

const COLUMN_DEFS: CondorColDef[] = [
  {
    checkboxSelection: true,
    headerCheckboxSelection: true,
    showDisabledCheckboxes: true,
    width: 32,
    cellClass: 'ag-checkbox-selection-column',
    headerClass: 'ag-checkbox-selection-column',
  },
  {
    headerName: 'Invoice #',
    field: 'invoiceNumber',
    cellRenderer: (params: ICellRendererParams) => (
      <>
        <AgGridValidationCellRenderer severity="success" />
        {params.value}
      </>
    ),
  },
  {
    headerName: 'Vendor',
    field: 'vendor',
    cellClassRules: {
      'ag-cell-complete-default': (params: CellClassParams) =>
        !!(params.rowIndex % 2),
      'ag-cell-complete-selected': (params: CellClassParams) =>
        !(params.rowIndex % 2),
    },
    cellRenderer: (params: ICellRendererParams) => (
      <>
        <AgGridValidationCellRenderer severity="success" />
        {params.value}
      </>
    ),
  },
  { headerName: 'PO #', field: 'poNumber' },
  {
    headerName: 'Invoice amount',
    field: 'invoiceAmount',
    cellRenderer: (params: ICellRendererParams) => (
      <>
        <AgGridValidationCellRenderer severity="success" />
        {params.value}
      </>
    ),
  },
  {
    headerName: 'G/L account',
    field: 'glAccount',
    cellClassRules: {
      'ag-cell-error-default': (params: CellClassParams) =>
        !!(params.rowIndex % 2),
      'ag-cell-error-selected': (params: CellClassParams) =>
        !(params.rowIndex % 2),
    },
    cellRenderer: (params: ICellRendererParams) => (
      <>
        <AgGridValidationCellRenderer severity="error" />
        {params.value}
      </>
    ),
  },
  { headerName: 'Description', field: 'description', minWidth: 100 },
  { headerName: 'Invoice date', field: 'invoiceDate', flex: 1 },
];

const ROW_DATA = [
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '5/17/2023',
    invoiceNumber: '98766.0',
    poNumber: '66735.0',
    vendor: 'DUMMY_VENDOR',
  },
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '5/17/2023',
    invoiceNumber: '98766.0',
    poNumber: '66735.0',
    vendor: 'DUMMY_VENDOR',
  },
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '5/17/2023',
    invoiceNumber: '98766.0',
    poNumber: '66735.0',
    vendor: 'DUMMY_VENDOR',
  },
];

function AgGridCellValidationDemo() {
  const rowData = useMemo(() => cloneDeep(ROW_DATA), []);

  return (
    <CondorAgGrid
      columnDefs={COLUMN_DEFS} // This is already a constant so does not need to be memoized
      gridOptions={useMemo(() => ({ suppressRowClickSelection: true }), [])}
      rowData={rowData}
      rowSelection="multiple"
      sx={useMemo(() => ({ height: 450 }), [])}
    />
  );
}

export default AgGridCellValidationDemo;
