import withPeriodSpecificRecordWrapper from 'shared/lib/periods/withPeriodSpecificRecordWrapper';
import RecordViewer from 'shared/lib/record-viewer/RecordViewer';
import { PeriodRecordBlobType } from 'shared/lib/types';

import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';
import useContractContainerRecord from './hooks/useContractContainerRecord';

function ContractContainerRecordContents() {
  const { contractContainer, contractVersion } =
    useContractAndPeriodWithVersions();
  const recordData = useContractContainerRecord(
    contractContainer,
    contractVersion,
  );
  return <RecordViewer record={recordData} />;
}

export default withPeriodSpecificRecordWrapper(
  ContractContainerRecordContents,
  PeriodRecordBlobType.CONTRACT_CONTAINER,
);
