import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import type { SnapshotRecordType } from 'shared/lib/types';
import { getStringFromSiteRecord } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import type { ValidationError } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/ValidationSidebar';
import WizardStep from 'shared/wizards/steps/WizardStep';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';

import { useGetSiteListingRecordsBySnapshotQuery } from 'shared/api/rtkq/sitelistingrecords';
import { useGetSitesByTrialQuery } from 'shared/api/rtkq/sites';

import columns from '../columns';

type SiteListingConfirmationStepProps = { snapshotId?: string };

function SiteListingConfirmationStep(props: SiteListingConfirmationStepProps) {
  const { snapshotId } = props;
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );

  const trial = useSelector(selectTrial);
  const { currentData: allSiteRecords } = useGetSitesByTrialQuery(
    trial.trace_id,
  );
  const { currentData: allRecords } = useGetSiteListingRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  );

  useEffect(() => {
    const newErrors: ValidationError[] = [];
    if (!allSiteRecords) {
      return;
    }

    allRecords?.forEach((record, index) => {
      const siteError: ValidationError = {
        type: 'site',
        row: index,
        fields: ['site_number'],
      };

      if (!record.site_number) {
        newErrors.push(siteError);
      }
    });
    setValidationErrors(newErrors);
  }, [allRecords, allSiteRecords]);

  return (
    <WizardStep
      description="Please fix them and upload a new file"
      disableNextButton={validationErrors.length > 0}
      header={
        validationErrors.length > 0
          ? `We have detected ${validationErrors.length} issue${
              validationErrors.length > 1 ? 's' : ''
            } that require your validation.`
          : 'No issues detected. Click on Save & Continue to proceed'
      }
    >
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
        validationErrors={validationErrors}
        allSiteRecordOptions={
          allSiteRecords?.map(getStringFromSiteRecord) ?? []
        }
      />
    </WizardStep>
  );
}

export default SiteListingConfirmationStep;
