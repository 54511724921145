import { useState } from 'react';

import type { SnapshotRecordType } from 'shared/lib/types';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import WizardStep from 'shared/wizards/steps/WizardStep';

import columns from '../columns';
import useValidationQueue from '../useValidationQueue';

type Props = { isAllInOneFile: boolean; snapshotId?: string };

function EdcProceduresConfirmationStep(props: Props) {
  const { snapshotId, isAllInOneFile } = props;
  const [selectedErrorIndex, setSelectedErrorIndex] = useState<number>(0);
  const { validationErrors, allRecords } = useValidationQueue(snapshotId);

  return (
    <WizardStep
      disableNextButton={validationErrors.length > 0}
      description={
        validationErrors.length > 0
          ? 'We will step you through each one. Accept or adjust the following'
          : ''
      }
      header={
        validationErrors.length > 0
          ? `We have detected ${validationErrors.length} issue${
              validationErrors.length > 1 ? 's' : ''
            } that require your validation.`
          : 'No issues detected. Click on Save & Continue to proceed'
      }
    >
      <SnapshotRecordsTable
        headers={columns(isAllInOneFile)}
        rows={allRecords as SnapshotRecordType[]}
        selectedErrorIndex={selectedErrorIndex}
        setSelectedErrorIndex={setSelectedErrorIndex}
        validationErrors={validationErrors}
        showValidationSidebar
      />
    </WizardStep>
  );
}

export default EdcProceduresConfirmationStep;
