import type { FlatFileMappingResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'ffmappings';

const apiEndpointFactory =
  constructApi<FlatFileMappingResponse>(PATH).withTags('FF_MAPPINGS');
const api = apiEndpointFactory.inject(() => ({
  createFlatFileMapping: apiEndpointFactory.create(),
  deleteFfMapping: apiEndpointFactory.delete(),
}));

export const { useCreateFlatFileMappingMutation, useDeleteFfMappingMutation } =
  api;
