import type { PeriodVersionResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'periodversions';

export const PERIOD_VERSION_TAG = 'PERIOD_VERSIONS';

const apiEndpointFactory =
  constructApi<PeriodVersionResponse>(PATH).withTags(PERIOD_VERSION_TAG);
const api = apiEndpointFactory.inject(() => ({
  getPeriodVersionsByPeriod: apiEndpointFactory.getBy('period', [
    '-version_number',
  ]),
  savePeriodDataForForecast: apiEndpointFactory.update(
    [],
    'save_period_data_for_forecast',
  ),
}));

export const {
  useGetPeriodVersionsByPeriodQuery,
  useSavePeriodDataForForecastMutation,
} = api;
