import Typography from '@mui/material/Typography';

import Modal from 'shared/components/modal/Modal';

import { useReinitializeForecast } from 'forecasting/state/slices/forecastSlice';
import type { ForecastResponse } from 'shared/lib/types';

import { useDeleteForecastMutation } from 'shared/api/rtkq/forecasts';

type Props = {
  forecast: ForecastResponse;
  handleCloseModal: () => void;
};

function DeleteForecastModal({ forecast, handleCloseModal }: Props) {
  const { name, trace_id } = forecast;
  const [deleteForecast, { isLoading: deleteForecastLoading }] =
    useDeleteForecastMutation();
  const reInitForecast = useReinitializeForecast();

  const handleDelete = () => {
    void (async () => {
      reInitForecast();
      await deleteForecast(trace_id);
      handleCloseModal();
    })();
  };

  return (
    <Modal
      handleClose={handleCloseModal}
      title={`Are you sure you want to delete "${name}"`}
      ButtonProps={{
        label: 'Delete',
        testId: 'ForecastModalDelete',
        loading: deleteForecastLoading,
        onClick: handleDelete,
      }}
      isOpen
    >
      <Typography>This action cannot be undone.</Typography>
    </Modal>
  );
}

export default DeleteForecastModal;
