import { useMemo } from 'react';

import { parse } from 'date-fns/parse';
import { useSelector } from 'react-redux';

import { getTrialMoneyCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import {
  formatShortMonthYear,
  getPeriodHeaderNames,
} from 'shared/helpers/helpers';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetPeriodsByTrialQuery } from 'shared/api/rtkq/periods';
import { useGetTrialAssumptionsByTrialQuery } from 'shared/api/rtkq/trialassumptions';

export default function useClosedPeriodValuesColumnDefs(
  _isOpenPeriod: boolean,
): CondorColDef[] {
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);

  const { currentData: periods } = useGetPeriodsByTrialQuery(trial.trace_id);
  const { currentData: trialAssumptions } = useGetTrialAssumptionsByTrialQuery(
    trial.trace_id,
  );

  return useMemo(() => {
    if (
      trialAssumptions === undefined ||
      trialAssumptions.length === 0 ||
      periods === undefined ||
      periods.length === 0
    ) {
      return [];
    }

    const periodColumns = getPeriodHeaderNames(
      parse(trialAssumptions[0].start_up_start_date, 'yyyy-MM-dd', new Date()),
      parse(period.end_date, 'yyyy-MM-dd', new Date()),
      false,
    );

    return [
      {
        headerName: 'PO #',
        valueFormatter:
          'node.data?.po_numbers ? node.data.po_numbers.join(", ") : ""',
        pinned: 'left',
      },
      {
        headerName: 'Vendor',
        field: 'vendor_name',
        pinned: 'left',
      },
      {
        headerName: 'Description',
        field: 'cost_category',
        valueFormatter: 'humanizeCostCategoryWithMixedVendors',
        pinned: 'left',
      },
      ...periodColumns.map((periodColumn) => {
        const per = periods.find(
          (per) => formatShortMonthYear(per.end_date) === periodColumn.label,
        );
        const isEditable = per?.is_closed;

        return {
          headerName: periodColumn.label,
          colId: periodColumn.label,
          field: `month_${periodColumn.endDate}`,
          cellStyle: isEditable
            ? undefined
            : { backgroundColor: 'rgba(0, 0, 0, 0.075)' },
          editable: isEditable,
          aggFunc: 'sum' as const,
          ...getTrialMoneyCellConfig({
            useEmDash: false,
            useEmDashInGroup: false,
            useEmDashInTotal: false,
          }),
        };
      }),
    ];
  }, [period, periods, trialAssumptions]);
}
