import { useSelector } from 'react-redux';

import {
  missingPeriodVersion,
  selectPeriodVersion,
} from 'accruals/state/slices/periodVersionSlice'; // eslint-disable-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import type { CommentLocationType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetCommentsWithFiltersQuery } from 'shared/api/rtkq/comments';

type CommentQuery = {
  location_type: CommentLocationType;
  location_slug?: string;
  // NOTE: Its ALWAYS period_version or company, never both
  period_version?: string;
  company?: string;
};

export default function useCommentsLoader(
  location: CommentLocationType,
  locationSlug: string,
) {
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const currentCompany = useSelector(selectCompany);

  const commentQuery: CommentQuery = {
    location_type: location,
    location_slug: locationSlug.trim().length ? locationSlug : undefined,
  };

  if (currentPeriodVersion !== missingPeriodVersion) {
    commentQuery.period_version = currentPeriodVersion.trace_id;
  } else {
    commentQuery.company = currentCompany.trace_id;
  }

  const { currentData: comments } =
    useGetCommentsWithFiltersQuery(commentQuery);

  return comments;
}
