import { useState } from 'react';

import type { SiteFileRequest, TraceId } from 'shared/lib/types';

import { useCreateSiteFileMutation } from 'shared/api/rtkq/sitefiles';

function useUploadSiteDocuments() {
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadFileRequest] = useCreateSiteFileMutation();

  async function uploadSiteDocument(
    siteTraceId: TraceId,
    doc: SiteFileRequest,
  ) {
    const data = new FormData();
    data.append('file', doc.file!);
    data.append('site', siteTraceId);
    data.append('company', doc.company);
    data.append('period_version', doc.period_version);

    if (doc.file_name) {
      data.append('file_name', doc.file_name);
    }

    if (doc.tag) {
      data.append('tag', doc.tag);
    }
    return uploadFileRequest(data).unwrap();
  }

  async function uploadSiteDocuments(
    siteTraceId: TraceId,
    documents: SiteFileRequest[],
  ) {
    setUploading(true);
    const uploadRequests = documents.map(async (doc) =>
      uploadSiteDocument(siteTraceId, doc),
    );

    const result = await Promise.allSettled(uploadRequests);
    setUploading(false);

    return result;
  }

  return { uploading, uploadSiteDocuments };
}

export default useUploadSiteDocuments;
