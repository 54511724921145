import type { ReactNode } from 'react';

import Box from '@mui/material/Box';

type Props = {
  children: ReactNode;
};

function PageContainer(props: Props) {
  const { children } = props;

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
        minHeight: 0,
        overflow: 'auto',
      }}
    >
      {children}
    </Box>
  );
}

export default PageContainer;
