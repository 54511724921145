import type { PeriodRecordBlobResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'periodrecordblobs';

const apiEndpointFactory =
  constructApi<PeriodRecordBlobResponse>(PATH).withTags('PERIOD_RECORD_BLOB');
const api = apiEndpointFactory.inject(() => ({
  upsertPeriodRecordBlob: apiEndpointFactory.create<FormData>([], 'upsert'),
  getPeriodRecordBlob: apiEndpointFactory.getWithFilters(),
}));

export const {
  useGetPeriodRecordBlobQuery,
  useLazyGetPeriodRecordBlobQuery,
  useUpsertPeriodRecordBlobMutation,
} = api;
