import type { SxProps } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

import type { TabProps } from './Tabs';
import Tabs from './Tabs';

type Props = {
  activeTab?: string;
  keyToUrl?: (tabKey: string) => string;
  sx?: SxProps;
  tabs: TabProps[];
  variant?: 'fullWidth' | 'small' | 'standard' | 'vertical';
};
function NavigationTabs(props: Props) {
  const { tabs, keyToUrl, activeTab: value, variant, sx } = props;
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Tabs
      tabs={tabs}
      value={value ?? location.pathname}
      // @ts-expect-error MUI bug https://github.com/mui/material-ui/issues/33785
      variant={variant ?? undefined}
      sx={{
        bgcolor: 'grey.50',
        borderBottom: '1px solid',
        borderColor: 'grey.300',
        ...sx,
      }}
      onChange={(_event, newValue) =>
        navigate(keyToUrl?.(newValue) ?? newValue)
      }
    />
  );
}

export default NavigationTabs;
