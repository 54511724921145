import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  getForecastingMoneyCellConfig,
  getOverUnderCellConfig,
  getPercentCellConfig,
  getTrialMoneyCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';

import { useGenerateForecastQuery } from 'shared/api/rtkq/forecasts';

import {
  actualMonthColDefs,
  forecastMonthColDefs,
} from './forecastMonthColDefs';

const useFinancialForecastSummaryColDefs = (
  showActuals: boolean,
): CondorColDef[] => {
  const forecast = useSelector(selectForecast);
  const { currentData: forecastGrids } = useGenerateForecastQuery(
    forecast.trace_id,
  );

  return useMemo(() => {
    const forecastedMonths = Object.keys(
      forecastGrids?.expenses[0] ?? {},
    ).filter((key) => key.startsWith('forecasted_month_'));

    return [
      {
        valueGetter:
          "node?.footer ? undefined : node?.data?.vendor_type === 'CRO' ? node.data.vendor : 'Other Clinical Contracts'",
        rowGroup: true,
        hide: true,
      },
      {
        field: 'po_numbers',
        headerName: 'PO #',
        pinned: true,
      },
      {
        field: 'vendor',
        headerName: 'Vendor',
        pinned: true,
      },
      {
        field: 'cost_category',
        headerName: 'Cost Category',
        pinned: true,
        valueFormatter: 'humanizeCostCategory',
      },
      {
        headerName: 'Summary',
        children: [
          {
            headerName: 'Contract value',
            field: 'default_contract_value',
            aggFunc: 'sum',
            ...getTrialMoneyCellConfig(),
          },
          {
            headerName: '% recognized',
            field: 'percent_recognized',
            aggFunc: 'percentRecognized',
            ...getPercentCellConfig({
              useEmDash: false,
              useEmDashInGroup: false,
              useEmDashInTotal: false,
            }),
          },
          {
            headerName: 'LTD Reconciled',
            field: 'reconciled_expense',
            aggFunc: 'sum',
            ...getTrialMoneyCellConfig(),
          },
          {
            headerName: 'Forecast Total',
            field: 'forecasted_total',
            aggFunc: 'sum',
            ...getTrialMoneyCellConfig(),
          },
          {
            headerName: 'Grand Total',
            field: 'grand_total',
            aggFunc: 'sum',
            ...getTrialMoneyCellConfig(),
          },
          {
            headerName: 'Over/Under',
            field: 'under_over',
            aggFunc: 'sum',
            ...getForecastingMoneyCellConfig(),
          },
          {
            headerName: 'Over/Under %',
            field: 'under_over_percent',
            aggFunc: 'underOver',
            width: 120,
            ...getOverUnderCellConfig(),
          },
        ],
      },
      {
        headerName: 'Actual',
        children: actualMonthColDefs(
          forecastGrids?.actual_months ?? [],
          true,
          !showActuals,
        ),
      },
      {
        headerName: 'Forecasted',
        children: forecastMonthColDefs(forecastedMonths, true),
      },
    ];
  }, [forecastGrids, showActuals]);
};

export default useFinancialForecastSummaryColDefs;
