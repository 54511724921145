import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import type { SnapshotRecordType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import type { ValidationError } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/ValidationSidebar';
import type { HandleAcceptForRollupRow } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/validation-handlers/RollupRowHandler';
import WizardStep from 'shared/wizards/steps/WizardStep';
import ValidateAndCheckErrorsTooltip from 'shared/wizards/csv-upload-wizards/validate-and-check-table/ValidateAndCheckErrorsTooltip';

import { useGetRegionsByTrialQuery } from 'shared/api/rtkq/regions';
import {
  useGetVendorRepActivityRecordsBySnapshotQuery,
  useUpdateVendorRepActivityRecordMutation,
} from 'shared/api/rtkq/vendorrepactivityrecords';

import columns from '../columns';

type Props = { snapshotId?: string };

function DirectFeeConfirmationStep(props: Props) {
  const { snapshotId } = props;
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );
  const [selectedErrorIndex, setSelectedErrorIndex] = useState<number>(0);
  const trial = useSelector(selectTrial);
  const { currentData: allRegions } = useGetRegionsByTrialQuery(trial.trace_id);
  const { currentData: allRecords } =
    useGetVendorRepActivityRecordsBySnapshotQuery(snapshotId ?? skipToken);
  const [updateVendorReport] = useUpdateVendorRepActivityRecordMutation();

  useEffect(() => {
    const newValidationErrors: ValidationError[] = [];
    if (!allRegions) {
      return;
    }

    allRecords?.forEach((record, index) => {
      if (
        record.inferred_rollup_row &&
        (record.confirmed_rollup_row === undefined ||
          record.confirmed_rollup_row === null)
      ) {
        newValidationErrors.push({
          type: 'rollup',
          row: index,
          fields: [], // empty fields will be treated as the whole row
        });
      }
    });
    setValidationErrors(newValidationErrors);
  }, [allRecords, allRegions]);

  const handleAcceptForRollupRow: HandleAcceptForRollupRow = async (
    applyToAll: boolean,
    selectedError: ValidationError,
    isRollupRow: boolean,
  ) => {
    const allRollupRows = validationErrors
      .filter((error) => error.type === 'rollup')
      .map((error) => error.row);

    const rowsToApply = applyToAll ? allRollupRows : [selectedError.row];

    await Promise.all(
      rowsToApply.map(async (row) => {
        const rowToApply = allRecords?.[row];
        if (rowToApply === undefined) {
          return;
        }

        await updateVendorReport({
          trace_id: rowToApply.trace_id,
          confirmed_rollup_row: isRollupRow,
        });
      }),
    );

    const newValidationErrors = validationErrors.filter(
      (error) => !rowsToApply.includes(error.row),
    );

    setValidationErrors(newValidationErrors);
  };

  return (
    <WizardStep
      disableNextButton={validationErrors.length > 0}
      description={
        validationErrors.length > 0
          ? 'We will step you through each one. Accept or adjust the following'
          : ''
      }
      header={
        validationErrors.length > 0
          ? `We have detected ${validationErrors.length} issue${
              validationErrors.length > 1 ? 's' : ''
            } that require your validation.`
          : 'No issues detected. Click on Save & Continue to proceed'
      }
    >
      {!!validationErrors.length && (
        <Box alignItems="center" display="flex" justifyContent="flex-end">
          <Typography align="right" color="error.main">
            <b>{validationErrors.length}</b> issues
          </Typography>
          <ValidateAndCheckErrorsTooltip validationErrors={validationErrors} />
        </Box>
      )}

      <SnapshotRecordsTable
        handleAcceptForRollupRows={handleAcceptForRollupRow}
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
        selectedErrorIndex={selectedErrorIndex}
        setSelectedErrorIndex={setSelectedErrorIndex}
        validationErrors={validationErrors}
      />
    </WizardStep>
  );
}

export default DirectFeeConfirmationStep;
