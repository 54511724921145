import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';

import Autocomplete, {
  autocompleteClasses,
} from 'shared/ui/autocomplete/Autocomplete';
import Button, { buttonClasses } from 'shared/ui/button/Button';

import type { DropdownOption } from 'shared/lib/types';

type Props = {
  className?: string;
  vendor?: Vendor;
  vendors: Vendor[];
  onAddVendor: () => void;
  onChange: (vendor: Vendor) => void;
};

function toDropdownOption(vendor: Vendor | undefined) {
  return { value: vendor, label: vendor?.name ?? '' };
}

function VendorField(props: Props) {
  const { vendor, vendors, onAddVendor, onChange } = props;

  function handleAutocompleteChange(
    _event: React.SyntheticEvent,
    value: DropdownOption<Vendor | undefined> | null,
  ) {
    if (value?.value && value.label !== ADD_NEW_NAME) {
      onChange(value.value);
    }
  }

  function renderOption(
    renderProps: React.HTMLAttributes<HTMLLIElement>,
    option: DropdownOption<Vendor | undefined>,
  ) {
    const { className, ...restProps } = renderProps;

    return option.label === ADD_NEW_NAME ? (
      <Box
        key={option.label}
        component="li"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          pl: (theme) => `${theme.spacing(1)} !important`,
        }}
        {...restProps}
        className={className}
      >
        <Button
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          testId="add_vendor"
          variant="text"
          sx={{
            [`&.${buttonClasses.root}`]: {
              justifyContent: 'start',
              py: (theme) => String(theme.spacing(2)),
              px: (theme) => String(theme.spacing(1)),
            },
          }}
          fullWidth
          onClick={onAddVendor}
        >
          Add vendor
        </Button>
      </Box>
    ) : (
      <Box className={className} component="li" {...restProps}>
        {option.label}
      </Box>
    );
  }

  return (
    <Autocomplete
      label="Vendor"
      listboxSx={{ pb: 0, [`& .${autocompleteClasses.option}`]: { pl: 5 } }}
      options={vendors.map(toDropdownOption)}
      placeholder="Select vendor"
      renderOption={renderOption}
      value={toDropdownOption(vendor)}
      isOptionEqualToValue={(
        option: DropdownOption<Vendor | undefined>,
        value: DropdownOption<Vendor | undefined>,
      ) => option.label === value.label}
      required
      onChange={handleAutocompleteChange}
    />
  );
}

export const ADD_NEW_NAME = 'addNew';
export type Vendor = { trace_id?: string; name: string; subsidiary?: string };
export default VendorField;
