import Typography from '@mui/material/Typography';

import FileUploader from 'shared/components/file-uploader/FileUploader';

function FileUploaderDemo() {
  return (
    <>
      <Typography sx={{ marginBottom: '16px' }} variant="h6">
        Single File Upload
      </Typography>
      <FileUploader
        submitButtonText="Submit"
        uploadButtonText="Upload CSV"
        onSubmitClick={(files: File[]) => alert(files[0].name)}
      />

      <Typography sx={{ marginTop: '48px', marginBottom: '16px' }} variant="h6">
        Multiple File Upload
      </Typography>
      <FileUploader
        submitButtonText="Submit"
        uploadButtonText="Upload CSV"
        multiple
        onSubmitClick={(files: File[]) => alert(files.map((file) => file.name))}
      />
    </>
  );
}

export default FileUploaderDemo;
