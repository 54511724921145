import { useEffect, useState } from 'react';

import Close from '@mui/icons-material/Close';
import Link from '@mui/icons-material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import type { ParseResult } from 'papaparse';
import Papa from 'papaparse';

import Button from 'shared/ui/button/Button';
import IconButton from 'shared/ui/icon-button/IconButton';

import { extractFileName, formatDate } from '../../helpers/helpers';

import styles from './CsvViewerModal.module.scss';

type Props = {
  description?: string;
  fileUrl: string;
  handleClose: () => void;
  isOpen: boolean;
  title: string;
  uploadedOn: Date | string;
};

type CsvRow = { [key: string]: number | string };

function CsvViewerModal(props: Props) {
  const { isOpen, handleClose, title, fileUrl, uploadedOn, description } =
    props;
  const [parsedFields, setParsedFields] = useState<string[]>([]);
  const [parsedRows, setParsedRows] = useState<CsvRow[]>([]);

  useEffect(() => {
    if (isOpen) {
      parseFile();
    }
  }, [isOpen]);

  const parseFile = () => {
    Papa.parse(fileUrl, {
      download: true,
      header: true,
      complete: (results: ParseResult<CsvRow>) => {
        setParsedFields(results.meta.fields ?? []);
        setParsedRows(
          results.data.map((row, index) => {
            row.id = index;
            return row;
          }),
        );
      },
    });
  };

  const copyLinkToKeyboard = () => {
    void navigator.clipboard.writeText(fileUrl);
  };

  return (
    <Dialog className={styles.dialog} maxWidth="md" open={isOpen} fullWidth>
      <DialogTitle>
        <Typography component="div" variant="h6">
          {title}
        </Typography>
        <IconButton
          data-testid="close-csv-dialog"
          variant="tertiary"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={styles.fileMetaData}>
          <b className={styles.fileMetaDataTitle}>File name:</b>
          <div className={styles.fileMetaDataValue}>
            {extractFileName(fileUrl)}
          </div>

          <b className={styles.fileMetaDataTitle}>File uploaded:</b>
          <div className={styles.fileMetaDataValue}>
            {formatDate(uploadedOn, 'Month dd, yyyy')}
          </div>

          {!!description && (
            <>
              <b className={styles.fileMetaDataTitle}>Description:</b>
              <div className={styles.fileMetaDataValue}>{description}</div>
            </>
          )}
        </div>

        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                <th />
                {parsedFields.map((row, index) => (
                  <th key={index}>{row}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {parsedRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{rowIndex}</td>
                  {parsedFields.map((field, fieldIndex) => (
                    <td key={fieldIndex}>{row[field]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<Link />}
          testId="get_link"
          variant="outlined"
          onClick={copyLinkToKeyboard}
        >
          Get Link
        </Button>
        <Button href={fileUrl} testId="download_file" variant="contained">
          Download File
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CsvViewerModal;
