import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import Roller from 'shared/ui/roller/Roller';

import {
  selectForecastInitialized,
  useInitializeForecast,
} from 'forecasting/state/slices/forecastSlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetForecastsByTrialQuery } from 'shared/api/rtkq/forecasts';

function withForecastRequired<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
) {
  return function ForecastRequiredWrapper(props: T): ReactNode {
    const trial = useSelector(selectTrial);
    const {
      currentData: forecasts,
      isUninitialized,
      isLoading,
      isFetching,
    } = useGetForecastsByTrialQuery(trial.trace_id);
    const initForecast = useInitializeForecast();
    const initialized = useSelector(selectForecastInitialized);
    const waitForApi = isUninitialized || isLoading || isFetching;

    // only call initialize forecast once
    useEffect(() => {
      if (waitForApi || initialized) {
        return;
      }

      initForecast(forecasts);
    }, [forecasts, waitForApi, initForecast, initialized]);

    return waitForApi ? <Roller /> : <LiveDataComponent {...props} />;
  };
}

export default withForecastRequired;
