import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { animated, to, useTransition } from '@react-spring/web';
import Group from '@visx/group/lib/Group';
import ordinal from '@visx/scale/lib/scales/ordinal';
import type { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie';
import Pie from '@visx/shape/lib/shapes/Pie';
import color from 'color';

import colors from 'colors.module.scss';
import { percentFormatter } from 'formatters';

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };

type PieProps = {
  animate?: boolean;
  height: number;
  margin?: typeof defaultMargin;
  percentage: number;
  width: number;
};

const REMAINING = 'Remaining';
const PERCENTAGE = 'Percentage';

const COLOR_SCALE = ordinal({
  domain: [PERCENTAGE, REMAINING],
  range: [colors.chart9, color(colors.n300).alpha(0.9).rgb().string()],
});

const LABEL_ACCESSOR = (datum: { label: string }) => datum.label;

function SmallDonut(props: PieProps) {
  const { percentage, width, height, margin = defaultMargin } = props;

  if (width < 10) {
    return null;
  }

  const data = [{ label: REMAINING, value: 100 - percentage }];
  if (percentage !== 0) {
    data.push({ label: PERCENTAGE, value: percentage });
  }

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 6;

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      <svg height={height} width={width}>
        <Group left={centerX + margin.left} top={centerY + margin.top}>
          <Pie
            cornerRadius={0}
            data={data}
            innerRadius={radius - donutThickness}
            outerRadius={radius}
            padAngle={0.1}
            pieSort={null}
            pieSortValues={null}
            pieValue={(datum: { value: number }) => datum.value}
          >
            {(pie) => (
              <AnimatedPie
                {...pie}
                getColor={(arc) => COLOR_SCALE(LABEL_ACCESSOR(arc.data))}
                getKey={(arc) => LABEL_ACCESSOR(arc.data)}
                animate
                onClickDatum={() => null}
              />
            )}
          </Pie>
        </Group>
      </svg>
      <Typography sx={{ ml: 1 }} variant="body1">
        {percentFormatter(percentage, 0)}
      </Typography>
    </Box>
  );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = <T,>({ endAngle }: PieArcDatum<T>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = <T,>({
  startAngle,
  endAngle,
}: PieArcDatum<T>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => (
    <g key={key}>
      <animated.path
        fill={getColor(arc)}
        d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
          path({
            ...arc,
            startAngle: -startAngle,
            endAngle: -endAngle,
          }),
        )}
        onClick={() => onClickDatum(arc)}
        onTouchStart={() => onClickDatum(arc)}
      />
    </g>
  ));
}

export default SmallDonut;
