import type { ChangeEvent } from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import Chip from 'shared/ui/chip/Chip';

import type {
  DropdownOption,
  ThirdPartyCompanyOptions,
  UserAddForm,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetThirdPartyCompaniesByCompanyQuery } from 'shared/api/rtkq/companies';
import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

type Props = {
  companyOptions: ThirdPartyCompanyOptions[];
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleGenericFieldOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleThirdPartyCompanyChange: (
    value: Array<DropdownOption<string>>,
    fieldName: string,
  ) => void;
  handleTrialAccessChange: (
    value: Array<DropdownOption<string>>,
    fieldName: string,
  ) => void;
  inputErrors: Record<string, boolean | string>;
  setCompanyOptions: (companyOptions: ThirdPartyCompanyOptions[]) => void;
  user: UserAddForm;
  onAddNewThirdPartyCompany: (companyName: string) => void;
};

function UserInviteForm(propsData: Props) {
  const {
    inputErrors,
    user,
    handleGenericFieldOnChange,
    handleEmailChange,
    handleThirdPartyCompanyChange,
    handleTrialAccessChange,
    companyOptions,
    setCompanyOptions,
    onAddNewThirdPartyCompany,
  } = propsData;

  const currentCompany = useSelector(selectCompany);
  const { currentData: trialsData } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );
  const { currentData: thirdPartyCompaniesData } =
    useGetThirdPartyCompaniesByCompanyQuery(currentCompany.trace_id);

  useEffect(() => {
    if (thirdPartyCompaniesData !== undefined) {
      setCompanyOptions(
        thirdPartyCompaniesData.map((thirdPartyCompany) => ({
          value: thirdPartyCompany.value,
          label: thirdPartyCompany.label,
        })),
      );
    }
  }, [setCompanyOptions, thirdPartyCompaniesData]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter': {
        event.preventDefault();
        // We need to allow even propagation to allow the Enter key to work when
        // selecting other options via keyboard. Thus removing the event.stopPropagation();
        const target = event.target as HTMLInputElement;
        if (target.value.length > 0) {
          onAddNewThirdPartyCompany(target.value);
        }
        break;
      }
      default:
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        gap: '24px',
        py: '10px',
      }}
    >
      <CondorTextField
        autoComplete="off"
        label="First Name"
        name="first_name"
        size="small"
        type="text"
        value={user.first_name}
        required
        onChange={handleGenericFieldOnChange}
      />
      <CondorTextField
        autoComplete="off"
        label="Last Name"
        name="last_name"
        size="small"
        type="text"
        value={user.last_name}
        required
        onChange={handleGenericFieldOnChange}
      />
      <CondorTextField
        errors={inputErrors.email}
        label="Email"
        size="small"
        type="email"
        value={user.email}
        variant="outlined"
        required
        onChange={handleEmailChange}
      />
      <CondorTextField
        autoComplete="off"
        label="Title"
        name="title"
        size="small"
        type="text"
        value={user.title}
        variant="outlined"
        onChange={handleGenericFieldOnChange}
      />
      <Autocomplete
        label="Company"
        limitTags={1}
        noOptionsText="Hit enter to add company"
        size="small"
        sx={{ flexGrow: 1 }}
        isOptionEqualToValue={(
          option: DropdownOption<string>,
          value: DropdownOption<string>,
        ) => option.value === value.value}
        options={companyOptions.map((companyOption) => ({
          value: companyOption.value,
          label: companyOption.label,
        }))}
        renderInput={(params) => {
          params.inputProps.onKeyDown = handleKeyDown;
          return (
            <CondorTextField
              {...params}
              label="Company"
              size="small"
              required
            />
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <Chip key={key} label={option.label} {...tagProps} />;
          })
        }
        value={user.third_party_companies.map((eachCompany) => ({
          label: eachCompany.label,
          value: eachCompany.value,
        }))}
        clearOnBlur
        filterSelectedOptions
        multiple
        required
        selectOnFocus
        onChange={(_, value) =>
          handleThirdPartyCompanyChange(value, 'third_party_companies')
        }
      />
      <Autocomplete
        getOptionLabel={(option) => option.label}
        label="Trial Access"
        limitTags={1}
        placeholder="All Trials"
        size="small"
        isOptionEqualToValue={(
          option: DropdownOption<string>,
          value: DropdownOption<string>,
        ) => option.label === value.label}
        options={
          trialsData?.map((eachTrial) => ({
            value: eachTrial.trace_id,
            label: `${eachTrial.indication} - ${eachTrial.study_id}`,
          })) ?? []
        }
        renderInput={(params) => {
          params.inputProps.onKeyDown = handleKeyDown;
          return (
            <CondorTextField
              {...params}
              InputLabelProps={{ shrink: true }}
              label="Trial Access"
              placeholder="All Trials"
              size="small"
            />
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <Chip key={key} label={option.label} {...tagProps} />;
          })
        }
        value={
          trialsData
            ?.filter((eachTrial) =>
              user.trial_access.includes(eachTrial.trace_id),
            )
            .map((eachTrial) => ({
              value: eachTrial.trace_id,
              label: `${eachTrial.indication} - ${eachTrial.study_id}`,
            })) ?? []
        }
        clearOnBlur
        filterSelectedOptions
        multiple
        selectOnFocus
        onChange={(_, value) => handleTrialAccessChange(value, 'trial_access')}
      />
    </Box>
  );
}

export default UserInviteForm;
