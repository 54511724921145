import type { RunRateVsEnrollmentDatum } from 'forecasting/components/graphing/rate-vs-enrollment-graph/types';
export const data: RunRateVsEnrollmentDatum[] = [
  {
    actualSpend: '1000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2021-08-01T00:00:00',
  },
  {
    actualSpend: '1100000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2021-09-01T00:00:00',
  },
  {
    actualSpend: '1200000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2021-10-01T00:00:00',
  },
  {
    actualSpend: '1300000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2021-11-01T00:00:00',
  },
  {
    actualSpend: '1400000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2021-12-01T00:00:00',
  },
  {
    actualSpend: '1500000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2022-01-01T00:00:00',
  },
  {
    actualSpend: '1600000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2022-02-01T00:00:00',
  },
  {
    actualSpend: '1700000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2022-03-01T00:00:00',
  },
  {
    actualSpend: '1800000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2022-04-01T00:00:00',
  },
  {
    actualSpend: '1900000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2022-05-01T00:00:00',
  },
  {
    actualSpend: '2000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2022-06-01T00:00:00',
  },
  {
    actualSpend: '2100000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '26',
    forecastedEnrollment: null,
    date: '2022-07-01T00:00:00',
  },
  {
    actualSpend: '4000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '30',
    forecastedEnrollment: null,
    date: '2022-08-01T00:00:00',
  },
  {
    actualSpend: '5000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '35',
    forecastedEnrollment: null,
    date: '2022-09-01T00:00:00',
  },
  {
    actualSpend: '5000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '35',
    forecastedEnrollment: null,
    date: '2022-10-01T00:00:00',
  },
  {
    actualSpend: '5500000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '40',
    forecastedEnrollment: null,
    date: '2022-11-01T00:00:00',
  },
  {
    actualSpend: '6000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '45',
    forecastedEnrollment: null,
    date: '2022-12-01T00:00:00',
  },
  {
    actualSpend: '6000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '50',
    forecastedEnrollment: null,
    date: '2023-01-01T00:00:00',
  },
  {
    actualSpend: '7000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: '55',
    forecastedEnrollment: '55',
    date: '2023-02-01T00:00:00',
  },
  {
    actualSpend: '8000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: null,
    forecastedEnrollment: '60',
    date: '2023-03-01T00:00:00',
  },
  {
    actualSpend: '9000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: null,
    forecastedEnrollment: '65',
    date: '2023-04-01T00:00:00',
  },
  {
    actualSpend: '10000000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: null,
    forecastedEnrollment: '68',
    date: '2023-05-01T00:00:00',
  },
  {
    actualSpend: '11200000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: null,
    forecastedEnrollment: '70',
    date: '2023-06-01T00:00:00',
  },
  {
    actualSpend: '11500000',
    forecastedSpend: null,
    actual: true,
    actualEnrollment: null,
    forecastedEnrollment: '75',
    date: '2023-07-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '15000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '85',
    date: '2023-08-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '16000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '93',
    date: '2023-09-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '17000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '92',
    date: '2023-10-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '18000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '95',
    date: '2023-11-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '20000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '100',
    date: '2023-12-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '20000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '100',
    date: '2024-01-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '22000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '100',
    date: '2024-02-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '23000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '110',
    date: '2024-03-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '24000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '115',
    date: '2024-04-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '24000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '120',
    date: '2024-05-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '25000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '125',
    date: '2024-06-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '26000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '130',
    date: '2024-07-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '27000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '135',
    date: '2024-08-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '28000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '140',
    date: '2024-09-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '29000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '145',
    date: '2024-10-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '30000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '150',
    date: '2024-11-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '32000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '155',
    date: '2024-12-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '33000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '160',
    date: '2025-01-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '34000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '165',
    date: '2025-02-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '35000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '170',
    date: '2025-02-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '36000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '175',
    date: '2025-03-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '37000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '180',
    date: '2025-04-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '37000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '190',
    date: '2025-05-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '39000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '192',
    date: '2025-06-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '39000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '195',
    date: '2025-07-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '45000000',
    actual: false,
    actualEnrollment: null,
    forecastedEnrollment: '200',
    date: '2025-08-01T00:00:00',
  },
];
