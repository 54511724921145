import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import type { PaletteColor, SxProps, Theme } from '@mui/material/styles';

import TabularDataCell from './TabularDataCell';

function TabularDataCellText(props: TabularDataCellTextProps) {
  const { color, loading = false, value, sx } = props;

  return (
    <TabularDataCell sx={sx}>
      {loading ? (
        <Skeleton variant="text" width="100%" />
      ) : (
        <Typography
          color={(theme) => color?.(theme).main}
          variant="body1"
          sx={{
            textAlign: 'center',
            width: '100%',
            fontWeight: color === undefined ? undefined : '700',
          }}
        >
          {value}
        </Typography>
      )}
    </TabularDataCell>
  );
}

export type TabularDataCellTextProps = {
  color?: (theme: Theme) => PaletteColor;
  loading?: boolean;
  value: number | string | null | undefined;
  sx?: SxProps;
};
export default TabularDataCellText;
