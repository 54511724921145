import { skipToken } from '@reduxjs/toolkit/query';

import withPeriodSpecificCustomWrapper from 'shared/lib/periods/withPeriodSpecificCustomWrapper';
import ContractFileBlobViewer from 'accruals/pages/clinical-expenses/shared/contract-record/ContractFileBlobViewer';
import { PeriodRecordBlobType } from 'shared/lib/types';

import { useGetContractFilesByContractContainerQuery } from 'shared/api/rtkq/contractfiles';

import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';
import ContractFilesList from './ContractFilesList';

function ContractContainerFileContents() {
  const { contractContainer } = useContractAndPeriodWithVersions();
  const { currentData: files } = useGetContractFilesByContractContainerQuery(
    contractContainer?.trace_id ?? skipToken,
  );

  return <ContractFilesList files={files} />;
}

export default withPeriodSpecificCustomWrapper(
  ContractContainerFileContents,
  ContractFileBlobViewer,
  PeriodRecordBlobType.CONTRACT_CONTAINER_FILES,
);
