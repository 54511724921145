import Typography from '@mui/material/Typography';

import type {
  FxRateStatusItem,
  UploadStatus,
  UploadStatusItem,
} from 'shared/lib/types';
import { FinancialCloseTaskType } from 'shared/lib/types';

import FxRateTaskStatus from './FxRateTaskStatus';
import UploadTaskStatus from './UploadTaskStatus';

function UploadTaskStatusGroup(props: UploadStatus) {
  const { title, items, taskType } = props;

  // FX rates are not "upload items" so we need to handle them differently
  // but we can safely assume that if not an fx rate, they are correctly UploadStatusItem[]
  return (
    <>
      {!!title && <Typography variant="body2">{title}</Typography>}
      {taskType === FinancialCloseTaskType.UPLOAD_FX_RATES
        ? (items as FxRateStatusItem[]).map((item) => (
            <FxRateTaskStatus
              key={item.currency_from + item.currency_to}
              contractVersionCount={item.contract_version_count}
              currency_from={item.currency_from}
              currency_to={item.currency_to}
              expectedFxRateCount={item.expected_fx_rate_count}
              fxRateCount={item.fx_rate_count}
              invoiceCount={item.invoice_count}
              labCount={item.lab_count}
              poCount={item.po_count}
              siteCount={item.site_count}
            />
          ))
        : (items as UploadStatusItem[]).map((item) => (
            <UploadTaskStatus
              key={item.title}
              files={item.files}
              labelVariant={title ? 'caption' : 'body2'}
              sx={{ mt: title ? 1 : 0 }}
              title={item.title}
              status={
                item.status === 'NOT_UPLOADED' ? 'notUploaded' : 'uploaded'
              }
            />
          ))}
    </>
  );
}

export default UploadTaskStatusGroup;
