import Checkbox from 'shared/ui/checkbox/Checkbox';

import styles from './CheckboxDemo.module.scss';

function CheckboxDemo() {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th />
          <th>Label: false</th>
          <th>Label: true</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Unchecked</td>
          <td>
            <Checkbox />
          </td>
          <td>
            <Checkbox label="Label" />
          </td>
        </tr>
        <tr>
          <td>Checked</td>
          <td>
            <Checkbox checked />
          </td>
          <td>
            <Checkbox label="Label" checked />
          </td>
        </tr>
        <tr>
          <td>Disabled</td>
          <td>
            <Checkbox disabled />
          </td>
          <td>
            <Checkbox label="Label" disabled />
          </td>
        </tr>
        <tr>
          <td>Checked Disabled</td>
          <td>
            <Checkbox checked disabled />
          </td>
          <td>
            <Checkbox label="Label" checked disabled />
          </td>
        </tr>
        <tr>
          <td>Indeterminate</td>
          <td>
            <Checkbox indeterminate />
          </td>
          <td>
            <Checkbox label="Label" indeterminate />
          </td>
        </tr>
        <tr>
          <td>Multiple lines</td>
          <td />
          <td>
            <Checkbox label="Multiple line checkbox text wrapped" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default CheckboxDemo;
