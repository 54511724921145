import type { ICellRendererParams } from '@ag-grid-community/core';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import Chip, { chipClasses } from 'shared/ui/chip/Chip';

import { humanize } from 'shared/helpers/helpers';

function AgGridContractStatusRenderer(
  params: ICellRendererParams<string | undefined>,
) {
  const { value } = params;
  return (
    <Chip
      avatar={<ArrowDropDown />}
      color={value === 'CURRENT' ? 'success' : 'default'}
      label={humanize(value?.toLowerCase() ?? '')}
      size="small"
      variant="filled"
      sx={{
        marginTop: '6px',
        flexDirection: 'row-reverse',
        [`&.${chipClasses.root}`]: { px: 0.5 },
        [`& .${chipClasses.label}`]: { pr: 0 },
        [`& .${chipClasses.avatar}`]: { m: 0 },
      }}
    />
  );
}

export default AgGridContractStatusRenderer;
