import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  getGenericCellConfig,
  getNumberCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { CurrencyCode } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetTrialAssumptionsByCompanyQuery } from 'shared/api/rtkq/trialassumptions';
import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

export function processFxRateColumnDefs(
  isOpenPeriod: boolean,
  inputCurrencies: CurrencyCode[],
): CondorColDef[] {
  return [
    {
      headerName: 'Period',
      field: 'end_date',
      pinned: 'left',
      cellStyle: { fontWeight: 'bold' },
      valueFormatter: 'humanizePeriodDate',
    },
    ...inputCurrencies.map((currency) => ({
      headerName: currency,
      field: currency,
      ...(isOpenPeriod
        ? {
            ...getNumberCellConfig(),
            editable: true,
          }
        : { ...getGenericCellConfig() }),
    })),
  ];
}

export default function useFxRateColumnDefs(
  isOpenPeriod: boolean,
  inputCurrencies: CurrencyCode[],
): CondorColDef[] {
  const currentCompany = useSelector(selectCompany);

  const { currentData: trialsByCompany } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );
  const { currentData: trialAssumptions } =
    useGetTrialAssumptionsByCompanyQuery(currentCompany.trace_id);

  return useMemo(
    () => processFxRateColumnDefs(isOpenPeriod, inputCurrencies),
    [isOpenPeriod, trialAssumptions, trialsByCompany, inputCurrencies],
  );
}
