import type { AssumptionGroupResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'assumptiongroup';

export const TAG = 'ASSUMPTION_GROUPS';

const apiEndpointFactory =
  constructApi<AssumptionGroupResponse>(PATH).withTags(TAG);

export const api = apiEndpointFactory.inject(() => ({
  getContractAssumptionGroupsByContractVersion:
    apiEndpointFactory.getBy('contract_version'),
  getContractAssumptionGroupsByContractVersions:
    apiEndpointFactory.getBy('contract_versions'),
}));

export const {
  useGetContractAssumptionGroupsByContractVersionQuery,
  useLazyGetContractAssumptionGroupsByContractVersionQuery,
  useGetContractAssumptionGroupsByContractVersionsQuery,
} = api;
