import { useMemo, useState } from 'react';

import type {
  CellValueChangedEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import cloneDeep from 'lodash/cloneDeep';

import {
  getDateCellConfig,
  getNumberCellConfig,
  getPercentCellConfig,
  getSelectCellConfig,
  getTextCellConfig,
} from 'shared/components/ag-grid-cells/config';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { LabelValueOption } from 'shared/lib/types';

const OPTIONS: LabelValueOption[] = [
  { value: 'site', label: 'Site' },
  { value: 'patient', label: 'Patient' },
  { value: 'month', label: 'Month' },
];

const COLUMN_DEFS: CondorColDef[] = [
  {
    headerName: 'Invoice #',
    field: 'invoiceNumber',
    editable: true,
    ...getTextCellConfig(),
  },
  {
    headerName: 'Invoice date',
    field: 'invoiceDate',
    editable: true,
    ...getDateCellConfig(),
  },
  {
    headerName: 'Percent Complete',
    field: 'percent',
    editable: true,
    ...getPercentCellConfig(),
  },
  {
    headerName: 'Group',
    field: 'group',
    editable: true,
    valueFormatter: (params: ValueFormatterParams) =>
      OPTIONS.find((option) => option.value === params.value)?.label ??
      'Select',
    ...getSelectCellConfig(),
    cellEditorParams: { options: OPTIONS },
  },
  {
    headerName: 'Invoice amount',
    field: 'invoiceAmount',
    editable: true,
    ...getNumberCellConfig(),
  },
  { headerName: 'G/L account', field: 'glAccount' },
  { headerName: 'Description', field: 'description', minWidth: 100 },
  { headerName: 'Vendor', field: 'vendor', flex: 1 },
];

const ROW_DATA = [
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '2023-05-17',
    invoiceNumber: '98766.0',
    group: 'site',
    vendor: 'DUMMY_VENDOR',
    percent: '50',
  },
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '2023-05-17',
    invoiceNumber: '98766.0',
    group: 'site',
    vendor: 'DUMMY_VENDOR',
    percent: 50,
  },
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '2023-05-17',
    invoiceNumber: '98766.0',
    group: 'site',
    vendor: 'DUMMY_VENDOR',
    percent: '98',
  },
];

function AgGridCellEditorsDemo() {
  const [changedEvents, setChangedEvents] = useState<CellValueChangedEvent[]>(
    [],
  );

  const gridOptions = useMemo(() => {
    function onCellValueChanged(params: CellValueChangedEvent) {
      setChangedEvents((state) => [...state, params]);
    }

    return { suppressRowClickSelection: true, onCellValueChanged };
  }, []);

  const rowData = useMemo(() => cloneDeep(ROW_DATA), []);

  return (
    <>
      <CondorAgGrid
        columnDefs={COLUMN_DEFS} // This is already a constant so does not need to be memoized
        gridOptions={gridOptions}
        rowData={rowData}
        sx={useMemo(() => ({ height: 450 }), [])}
      />
      <Typography my={3} variant="body2">
        Events: (onCellValueChanged)
      </Typography>
      <Grid container>
        {changedEvents.map((event, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: there's nothing else to use
          <Grid key={index} display="flex" gap={3} xs={12} item>
            <Typography color="text.secondary" variant="body1">
              # {index}
            </Typography>
            <Typography flex="1 1 0" variant="body1">
              Column: <b>{event.colDef.headerName}</b>
            </Typography>
            <Typography flex="1 1 0" variant="body1">
              Value: <b>{event.value}</b>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default AgGridCellEditorsDemo;
