import { useMemo } from 'react';

export default function useFinancialForecastSummaryGridOptions() {
  return useMemo(
    () =>
      ({
        groupDisplayType: 'groupRows',
        getRowStyle: 'highlightRow',
        groupTotalRow: 'bottom' as const,
        grandTotalRow: 'bottom',
        suppressAggFuncInHeader: true,
      }) as const,
    [],
  );
}
