import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/material/styles';

type Props = { sx?: SxProps; title: string };

function TabularDataHeaderCell(props: Props) {
  const { title, sx } = props;

  return (
    <Box sx={{ minWidth: 220, textAlign: 'center', px: 1.5, ...sx }}>
      <Typography color="text.secondary" variant="button">
        {title}
      </Typography>
    </Box>
  );
}

export default TabularDataHeaderCell;
