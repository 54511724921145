import MenuItem from '@mui/material/MenuItem';

import CondorTextField, {
  type CondorTextFieldProps,
} from 'shared/components/text-field/CondorTextField';

function Select(props: CondorTextFieldProps) {
  const { children, ...otherProps } = props;
  if (
    Array.isArray(children) &&
    children.length > 0 &&
    isStringArray(children)
  ) {
    return (
      <CondorTextField {...otherProps} select>
        {children.map((child) => (
          <MenuItem key={child} value={child}>
            {child}
          </MenuItem>
        ))}
      </CondorTextField>
    );
  }
  return <CondorTextField {...props} select />;
}

function isStringArray(array: unknown[]): array is string[] {
  return array.every((element) => typeof element === 'string');
}

export default Select;
