import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function UploadingSection() {
  return (
    <>
      <CircularProgress />
      <Typography sx={{ color: 'grey.700' }} variant="h6">
        Processing file...
      </Typography>
    </>
  );
}

export default UploadingSection;
