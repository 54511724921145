import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import {
  unitDetailFromBackendValue,
  unitTypeFromBackendValue,
} from 'shared/lib/driverUnitTypes';
import type { OccReconResponse } from 'shared/lib/types';

export function processOccReconData(reconData?: OccReconResponse) {
  return cloneDeep([
    ...(reconData?.rows.map((row) => ({
      ...row,
      ...(row.unitType && {
        unitType: unitTypeFromBackendValue(row.unitType),
      }),
      ...(row.unitDetail && {
        unitDetail: unitDetailFromBackendValue(row.unitDetail),
      }),
    })) ?? []),
    ...(reconData?.bla_list ?? []),
  ]);
}

// `unknown` matches ag-grid
export default function useOccReconRowData(
  reconData?: OccReconResponse,
): unknown[] | undefined {
  return useMemo(() => processOccReconData(reconData), [reconData]);
}
