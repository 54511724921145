import type { TrialSpendForecastDatum } from 'forecasting/components/graphing/trial-spend-forecast-graph/types';

export const data: TrialSpendForecastDatum[] = [
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '80000',
    OCC: '5000',
    INVESTIGATOR_FEES: '0',
    PASS_THROUGH: '0',
    date: '2021-08-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '370000',
    OCC: '150000',
    INVESTIGATOR_FEES: '0',
    PASS_THROUGH: '0',
    date: '2021-09-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '370000',
    OCC: '150000',
    INVESTIGATOR_FEES: '0',
    PASS_THROUGH: '0',
    date: '2021-10-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '370000',
    OCC: '1200000',
    INVESTIGATOR_FEES: '0',
    PASS_THROUGH: '0',
    date: '2021-11-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '680000',
    OCC: '120000',
    INVESTIGATOR_FEES: '0',
    PASS_THROUGH: '0',
    date: '2021-12-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '390000',
    OCC: '120000',
    INVESTIGATOR_FEES: '0',
    PASS_THROUGH: '0',
    date: '2022-01-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '390000',
    OCC: '220000',
    INVESTIGATOR_FEES: '0',
    PASS_THROUGH: '0',
    date: '2022-02-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '800000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-03-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '750000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-04-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '420000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-05-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '780000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-06-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '330000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-07-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '420000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-08-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '1',
    DIRECT_FEES: '390000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-09-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '8',
    DIRECT_FEES: '410000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-10-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '10',
    DIRECT_FEES: '500000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-11-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '12',
    DIRECT_FEES: '620000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2022-12-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '18',
    DIRECT_FEES: '700000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2023-01-01T00:00:00',
  },
  {
    forecastedEnrollment: null,
    actual: true,
    actualEnrollment: '22',
    DIRECT_FEES: '650000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2023-02-01T00:00:00',
  },
  {
    forecastedEnrollment: '24',
    actual: false,
    actualEnrollment: '24',
    DIRECT_FEES: '400000',
    OCC: '500000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2023-03-01T00:00:00',
  },
  {
    forecastedEnrollment: '26',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '530000',
    OCC: '400000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2023-04-01T00:00:00',
  },
  {
    forecastedEnrollment: '28',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '720000',
    OCC: '100000',
    INVESTIGATOR_FEES: '320000',
    PASS_THROUGH: '330000',
    date: '2023-05-01T00:00:00',
  },
  {
    forecastedEnrollment: '30',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '680000',
    OCC: '900000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2023-06-01T00:00:00',
  },
  {
    forecastedEnrollment: '32',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '440000',
    OCC: '100000',
    INVESTIGATOR_FEES: '420000',
    PASS_THROUGH: '30000',
    date: '2023-07-01T00:00:00',
  },
  {
    forecastedEnrollment: '34',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '530000',
    OCC: '100000',
    INVESTIGATOR_FEES: '620000',
    PASS_THROUGH: '30000',
    date: '2023-08-01T00:00:00',
  },
  {
    forecastedEnrollment: '36',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '550000',
    OCC: '100000',
    INVESTIGATOR_FEES: '220000',
    PASS_THROUGH: '30000',
    date: '2023-09-01T00:00:00',
  },
  {
    forecastedEnrollment: '38',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '620000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '830000',
    date: '2023-10-01T00:00:00',
  },
  {
    forecastedEnrollment: '40',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '780000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '660000',
    date: '2023-11-01T00:00:00',
  },
  {
    forecastedEnrollment: '42',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '750000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '300000',
    date: '2023-12-01T00:00:00',
  },
  {
    forecastedEnrollment: '44',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '800000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '110000',
    date: '2024-01-01T00:00:00',
  },
  {
    forecastedEnrollment: '46',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '900000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '70000',
    date: '2024-02-01T00:00:00',
  },
  {
    forecastedEnrollment: '48',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '850000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2024-03-01T00:00:00',
  },
  {
    forecastedEnrollment: '80',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '820000',
    OCC: '100000',
    INVESTIGATOR_FEES: '30000',
    PASS_THROUGH: '80000',
    date: '2024-04-01T00:00:00',
  },
  {
    forecastedEnrollment: '84',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '780000',
    OCC: '100000',
    INVESTIGATOR_FEES: '50000',
    PASS_THROUGH: '70000',
    date: '2024-05-01T00:00:00',
  },
  {
    forecastedEnrollment: '88',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '800000',
    OCC: '30000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2024-06-01T00:00:00',
  },
  {
    forecastedEnrollment: '92',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '780000',
    OCC: '550000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2024-07-01T00:00:00',
  },
  {
    forecastedEnrollment: '96',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '820000',
    OCC: '650000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2024-08-01T00:00:00',
  },
  {
    forecastedEnrollment: '100',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '780000',
    OCC: '500000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2024-09-01T00:00:00',
  },
  {
    forecastedEnrollment: '100',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '770000',
    OCC: '400000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2024-10-01T00:00:00',
  },
  {
    forecastedEnrollment: '110',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '650000',
    OCC: '700000',
    INVESTIGATOR_FEES: '50000',
    PASS_THROUGH: '10000',
    date: '2024-11-01T00:00:00',
  },
  {
    forecastedEnrollment: '120',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '750000',
    OCC: '700000',
    INVESTIGATOR_FEES: '40000',
    PASS_THROUGH: '50000',
    date: '2024-12-01T00:00:00',
  },
  {
    forecastedEnrollment: '130',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '800000',
    OCC: '600000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '50000',
    date: '2025-01-01T00:00:00',
  },
  {
    forecastedEnrollment: '140',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '830000',
    OCC: '100000',
    INVESTIGATOR_FEES: '450000',
    PASS_THROUGH: '30000',
    date: '2025-02-01T00:00:00',
  },
  {
    forecastedEnrollment: '150',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '880000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '30000',
    date: '2025-03-01T00:00:00',
  },
  {
    forecastedEnrollment: '160',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '600000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '210000',
    date: '2025-04-01T00:00:00',
  },
  {
    forecastedEnrollment: '170',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '650000',
    OCC: '100000',
    INVESTIGATOR_FEES: '450000',
    PASS_THROUGH: '30000',
    date: '2025-05-01T00:00:00',
  },
  {
    forecastedEnrollment: '180',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '800000',
    OCC: '100000',
    INVESTIGATOR_FEES: '600000',
    PASS_THROUGH: '30000',
    date: '2025-06-01T00:00:00',
  },
  {
    forecastedEnrollment: '190',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '820000',
    OCC: '100000',
    INVESTIGATOR_FEES: '20000',
    PASS_THROUGH: '500000',
    date: '2025-07-01T00:00:00',
  },
  {
    forecastedEnrollment: '200',
    actual: false,
    actualEnrollment: null,
    DIRECT_FEES: '850000',
    OCC: '100000',
    INVESTIGATOR_FEES: '80000',
    PASS_THROUGH: '110000',
    date: '2025-08-01T00:00:00',
  },
];
