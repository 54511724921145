import { useMemo } from 'react';

import { processCroExpenseSummaryRows } from 'accruals/pages/clinical-expenses/cro/hooks/useCroExpenseSummaryRows';
import type {
  CroExpenseSummaryRow,
  OccExpenseSummaryRow,
  SharedContractExpenseSummaryRow,
} from 'shared/lib/types';

export function processClinicalExpenseSummaryRows(
  croRows: CroExpenseSummaryRow[] | undefined,
  occRows: OccExpenseSummaryRow[] | undefined,
) {
  return [...(processCroExpenseSummaryRows(croRows) ?? []), ...(occRows ?? [])];
}

export default function useClinicalExpenseSummaryRows(
  croRows: CroExpenseSummaryRow[] | undefined,
  occRows: OccExpenseSummaryRow[] | undefined,
  isLoading: boolean,
) {
  return useMemo(
    () =>
      isLoading
        ? undefined
        : processClinicalExpenseSummaryRows(croRows, occRows),
    [croRows, occRows, isLoading],
  ) as SharedContractExpenseSummaryRow[] | undefined; // The shared type is the intersection of the two types
}
