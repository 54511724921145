import { memo } from 'react';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import TimeForecast from 'forecasting/components/graphing/time-forecast/TimeForecast';

import { timeline1, timeline2, timeline3, timeline4, timeline5 } from './data';

function TimeForecastDemo() {
  return (
    <Paper elevation={1} sx={{ p: 4, width: '80%' }}>
      <Typography variant="subtitle1">Time forecast</Typography>
      <Divider sx={{ mt: 1, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <TimeForecast
              data={timeline1}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Divider sx={{ pt: 3, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <TimeForecast
              data={timeline2}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Divider sx={{ pt: 3, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <TimeForecast
              data={timeline3}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Divider sx={{ pt: 3, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <TimeForecast
              data={timeline4}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Divider sx={{ pt: 3, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <TimeForecast
              data={timeline5}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
    </Paper>
  );
}

export default memo(TimeForecastDemo);
