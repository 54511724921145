import type { PeriodGraphBlobResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'periodgraphblobs';

const apiEndpointFactory =
  constructApi<PeriodGraphBlobResponse>(PATH).withTags('PERIOD_GRAPH_BLOB');
const api = apiEndpointFactory.inject(() => ({
  upsertPeriodGraphBlob: apiEndpointFactory.create<FormData>([], 'upsert'),
  getPeriodGraphBlob: apiEndpointFactory.getWithFilters(),
}));

// eslint-disable-next-line import/no-unused-modules -- to be used
export const {
  useGetPeriodGraphBlobQuery,
  useLazyGetPeriodGraphBlobQuery,
  useUpsertPeriodGraphBlobMutation,
} = api;
