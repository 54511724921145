import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import type { KeyValueRow } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

// isOpenPeriod is here so this works the same as all the other records.
// if adding something NON READ ONLY, please ensure you honor this flag
// else period close might not work correctly
export default function useTrialRecord(_isOpenPeriod: boolean): KeyValueRow[] {
  const trial = useSelector(selectTrial);

  return useMemo(
    () => [
      { key: 'Trial ID', value: trial.study_id },
      { key: 'Phase of trial', value: trial.phase },
      { key: 'Trial name', value: trial.name },
      { key: 'Indication', value: trial.indication },
      {
        key: 'Clinicaltrial.gov URL',
        value: trial.national_clinical_trial_url,
      },
    ],
    [trial],
  );
}
