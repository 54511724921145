import { useEffect, useState } from 'react';

import { getJSONFromFile } from 'shared/helpers/helpers';
import type { PeriodMenuItemResponse } from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

export default function usePeriodMenuItemBlob(
  periodMenuItem?: PeriodMenuItemResponse,
): apiJSON | undefined {
  const [blobData, setBlobData] = useState<apiJSON>();

  const blobPath = periodMenuItem?.blob;

  useEffect(() => {
    void (async () => {
      if (blobPath) {
        setBlobData(await getJSONFromFile<apiJSON>(blobPath));
      }
    })();
  }, [blobPath]);

  return blobData;
}
