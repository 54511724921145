import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import Typography from '@mui/material/Typography';

import Tabs from 'shared/ui/tabs/Tabs';

import styles from './TabsDemo.module.scss';

function TabsDemo() {
  const [value, setValue] = useState<string>('one');

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const tabs = [
    { key: 'one', devLabel: 'Dev 1', label: 'Item One' },
    { key: 'two', devLabel: 'Dev 2', label: 'Item Two' },
    { key: 'three', devLabel: 'Dev 3', label: 'Item Three' },
    { key: 'four', devLabel: 'Dev 4', label: 'Disabled', disabled: true },
  ];

  return (
    <>
      <div className={styles.type}>
        <Typography variant="h6">Standard</Typography>
        <Tabs
          tabs={tabs}
          value={value}
          variant="standard"
          onChange={handleChange}
        />
      </div>

      <div className={styles.type}>
        <Typography variant="h6">Small</Typography>
        <Tabs
          tabs={tabs}
          value={value}
          // @ts-expect-error MUI bug https://github.com/mui/material-ui/issues/33785
          variant="small"
          onChange={handleChange}
        />
      </div>

      <div className={styles.type}>
        <Typography variant="h6">Full-width</Typography>
        <Tabs
          tabs={tabs}
          value={value}
          variant="fullWidth"
          onChange={handleChange}
        />
      </div>

      <div className={styles.type}>
        <Typography variant="h6">Vertical</Typography>
        <Tabs
          orientation="vertical"
          tabs={tabs}
          value={value}
          onChange={handleChange}
        />
      </div>
    </>
  );
}

export default TabsDemo;
