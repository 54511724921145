import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { VersionHistoryRow } from 'shared/lib/types';

export default function useCroVersionHistoryGridOptions(
  _isOpenPeriod = true,
): CondorGridOptions<VersionHistoryRow> {
  return useMemo(
    () => ({ rowSelection: 'single', rowStyle: { cursor: 'pointer' } }),
    [],
  );
}
