export default [
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_VQxv37JeT-uw5cZMkSF9Yg/',
    trace_id: 'edcr_VQxv37JeT-uw5cZMkSF9Yg',
    created_at: '2023-05-16T19:13:26.373879Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 1,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-11-23',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 23 Nov 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_c1YzRrJNQkOrnYXh2h0G7w/',
    trace_id: 'edcr_c1YzRrJNQkOrnYXh2h0G7w',
    created_at: '2023-05-16T19:13:26.382253Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 2,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-11-30',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 30 Nov 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_XEy1CVj9Qgee0EQT0n518w/',
    trace_id: 'edcr_XEy1CVj9Qgee0EQT0n518w',
    created_at: '2023-05-16T19:13:26.388211Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 3,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-10-29',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 29 Oct 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_61oNsIxMSWyuimaVjeTfdg/',
    trace_id: 'edcr_61oNsIxMSWyuimaVjeTfdg',
    created_at: '2023-05-16T19:13:26.391089Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 4,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-11-03',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 03 Nov 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_6Q7BIscqTjmKGLtWIeCktA/',
    trace_id: 'edcr_6Q7BIscqTjmKGLtWIeCktA',
    created_at: '2023-05-16T19:13:26.393193Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 5,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-12-02',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 02 Dec 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_yNxa_JEkQbid-EkT8I2drw/',
    trace_id: 'edcr_yNxa_JEkQbid-EkT8I2drw',
    created_at: '2023-05-16T19:13:26.398535Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 6,
    project: null,
    study_id: null,
    subject: '1103-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1103',
    site_group: null,
    visdat: '2022-04-06',
    savets: null,
    cohort: 'Tier 1',
    visit_name: 'Unscheduled Visit 06 Apr 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_4MOvaJfVQ_e4PsBOEDWK7g/',
    trace_id: 'edcr_4MOvaJfVQ_e4PsBOEDWK7g',
    created_at: '2023-05-16T19:13:26.407932Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 7,
    project: null,
    study_id: null,
    subject: '2503-102',
    site_name: null,
    site_number: 'Viracta_VT3996-202_2503',
    site_group: null,
    visdat: '2022-11-28',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 28 Nov 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_1eHoQ4vfSmSYrsjk-RXqnQ/',
    trace_id: 'edcr_1eHoQ4vfSmSYrsjk-RXqnQ',
    created_at: '2023-05-16T19:13:26.416676Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 8,
    project: null,
    study_id: null,
    subject: '2503-102',
    site_name: null,
    site_number: 'Viracta_VT3996-202_2503',
    site_group: null,
    visdat: '2022-12-05',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 05 Dec 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_Qio3p8d_TOKz3fjkQi-noQ/',
    trace_id: 'edcr_Qio3p8d_TOKz3fjkQi-noQ',
    created_at: '2023-05-16T19:13:26.455563Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 9,
    project: null,
    study_id: null,
    subject: '3005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_3005',
    site_group: null,
    visdat: '2022-02-08',
    savets: null,
    cohort: 'Tier 1',
    visit_name: 'Unscheduled Visit 08 Feb 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_DUzJTar8QNy3RSmh--Ezgw/',
    trace_id: 'edcr_DUzJTar8QNy3RSmh--Ezgw',
    created_at: '2023-05-16T19:13:26.460002Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 10,
    project: null,
    study_id: null,
    subject: '5201-102',
    site_name: null,
    site_number: 'Viracta_VT3996-202_5201',
    site_group: null,
    visdat: '2022-05-27',
    savets: null,
    cohort: 'Tier 1',
    visit_name: 'Unscheduled Visit 27 May 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_bgim4R7dRuS75_raNCRxBA/',
    trace_id: 'edcr_bgim4R7dRuS75_raNCRxBA',
    created_at: '2023-05-16T19:13:26.478324Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 11,
    project: null,
    study_id: null,
    subject: '5201-102',
    site_name: null,
    site_number: 'Viracta_VT3996-202_5201',
    site_group: null,
    visdat: '2022-06-01',
    savets: null,
    cohort: 'Tier 1',
    visit_name: 'Unscheduled Visit 01 Jun 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_FX7u6qwZQOWaFICs8zsmzQ/',
    trace_id: 'edcr_FX7u6qwZQOWaFICs8zsmzQ',
    created_at: '2023-05-16T19:13:26.480572Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 12,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-11-23',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 23 Nov 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_LrpV3fFyQl2rmXfV8PXx1A/',
    trace_id: 'edcr_LrpV3fFyQl2rmXfV8PXx1A',
    created_at: '2023-05-16T19:13:26.486417Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 13,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-11-30',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 30 Nov 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_uHIRXt3ZR_iG4kTQVxvGFA/',
    trace_id: 'edcr_uHIRXt3ZR_iG4kTQVxvGFA',
    created_at: '2023-05-16T19:13:26.489356Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 14,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-10-29',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 29 Oct 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_eMvzrFtMTJi9DJpBAAyb8Q/',
    trace_id: 'edcr_eMvzrFtMTJi9DJpBAAyb8Q',
    created_at: '2023-05-16T19:13:26.491178Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 15,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-11-03',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 03 Nov 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_LksHVX5sQp2fENnl09A0nQ/',
    trace_id: 'edcr_LksHVX5sQp2fENnl09A0nQ',
    created_at: '2023-05-16T19:13:26.492660Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 16,
    project: null,
    study_id: null,
    subject: '1005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1005',
    site_group: null,
    visdat: '2021-12-02',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 02 Dec 2021',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_PkpyVp1ATNmp_Mt1J-WS2A/',
    trace_id: 'edcr_PkpyVp1ATNmp_Mt1J-WS2A',
    created_at: '2023-05-16T19:13:26.494217Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 17,
    project: null,
    study_id: null,
    subject: '1103-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_1103',
    site_group: null,
    visdat: '2022-04-06',
    savets: null,
    cohort: 'Tier 1',
    visit_name: 'Unscheduled Visit 06 Apr 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_YcToQCUIQ5eFcbYzFomtug/',
    trace_id: 'edcr_YcToQCUIQ5eFcbYzFomtug',
    created_at: '2023-05-16T19:13:26.495926Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 18,
    project: null,
    study_id: null,
    subject: '2503-102',
    site_name: null,
    site_number: 'Viracta_VT3996-202_2503',
    site_group: null,
    visdat: '2022-11-28',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 28 Nov 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_695b13I_RVuy1eLF7-CgwQ/',
    trace_id: 'edcr_695b13I_RVuy1eLF7-CgwQ',
    created_at: '2023-05-16T19:13:26.497809Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 19,
    project: null,
    study_id: null,
    subject: '2503-102',
    site_name: null,
    site_number: 'Viracta_VT3996-202_2503',
    site_group: null,
    visdat: '2022-12-05',
    savets: null,
    cohort: 'Tier 2',
    visit_name: 'Unscheduled Visit 05 Dec 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_4UmYzE0vR-q3mGUeZzQDsg/',
    trace_id: 'edcr_4UmYzE0vR-q3mGUeZzQDsg',
    created_at: '2023-05-16T19:13:26.500292Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 20,
    project: null,
    study_id: null,
    subject: '3005-101',
    site_name: null,
    site_number: 'Viracta_VT3996-202_3005',
    site_group: null,
    visdat: '2022-02-08',
    savets: null,
    cohort: 'Tier 1',
    visit_name: 'Unscheduled Visit 08 Feb 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_PwBh8RYwSkubbgTkuuQTNQ/',
    trace_id: 'edcr_PwBh8RYwSkubbgTkuuQTNQ',
    created_at: '2023-05-16T19:13:26.505462Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 21,
    project: null,
    study_id: null,
    subject: '5201-102',
    site_name: null,
    site_number: 'Viracta_VT3996-202_5201',
    site_group: null,
    visdat: '2022-05-27',
    savets: null,
    cohort: 'Tier 1',
    visit_name: 'Unscheduled Visit 27 May 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
  {
    url: 'http://127.0.0.1:8000/api/edcrecords/edcr_yQucKSJNSmmWaDejzNF8Nw/',
    trace_id: 'edcr_yQucKSJNSmmWaDejzNF8Nw',
    created_at: '2023-05-16T19:13:26.508203Z',
    snapshot: 'edcs_v2KSxDeoSgGGq2EFuMBNaw',
    snapshot_row: 22,
    project: null,
    study_id: null,
    subject: '5201-102',
    site_name: null,
    site_number: 'Viracta_VT3996-202_5201',
    site_group: null,
    visdat: '2022-06-01',
    savets: null,
    cohort: 'Tier 1',
    visit_name: 'Unscheduled Visit 01 Jun 2022',
    trial: 'ct_-8GYSISwQiSh2zgPhBOlJQ',
    patient_cohort_mapping: null,
    patient_assessment_mapping: null,
    site_record: null,
  },
];
