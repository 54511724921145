import Dialog from '@mui/material/Dialog';

import type { SiteRecordResponse } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';

import { useCreateSiteContractMutation } from 'shared/api/rtkq/sitecontracts';

import SiteInfoStep from './steps/SiteInfoStep';

type Props = { onClose: () => void; onSave?: () => void };

function AddSiteVersionWizard(props: Props) {
  const { onClose, onSave } = props;

  const [addSiteVersion, { isLoading }] = useCreateSiteContractMutation();

  async function saveSite(siteRecord: SiteRecordResponse | undefined) {
    await addSiteVersion({ site: siteRecord?.trace_id });
    onSave?.();
  }

  return (
    <Dialog open>
      <Wizard devTitle="NONE" title="New site version" onClose={onClose}>
        <SiteInfoStep
          disableNextButton={isLoading}
          onSave={(site) => void saveSite(site)}
        />
      </Wizard>
    </Dialog>
  );
}

export default AddSiteVersionWizard;
