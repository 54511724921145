import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';

import type { LabRequest } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import { selectTrial } from 'shared/state/slices/trialSlice';

import useAddLab from 'shared/api/hooks/useAddLab';

import LabInfoStep from './steps/LabInfoStep';

type Props = { onClose: () => void; onSave?: () => void };

function AddLabWizard(props: Props) {
  const trial = useSelector(selectTrial);
  const { onClose, onSave } = props;

  const [labRecord, setLabRecord] = useState<LabRequest>({});
  const [addLab, { isLoading }] = useAddLab();

  async function saveLab() {
    await addLab({ trial: trial.trace_id, ...labRecord });
    onSave?.();
  }
  return (
    <Dialog open>
      <Wizard devTitle="NONE" title="New lab" onClose={onClose}>
        <LabInfoStep
          disableNextButton={isLoading}
          labRecord={labRecord}
          setLabRecord={setLabRecord}
          onSave={() => void saveLab()}
        />
      </Wizard>
    </Dialog>
  );
}

export default AddLabWizard;
