import { useMemo } from 'react';

import { getGenericCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

export default function useContractsColumnDefs(): CondorColDef[] {
  return useMemo(
    () => [
      {
        headerName: 'Vendor',
        field: 'vendor_name',
        flex: 1,
        ...getGenericCellConfig({
          useEmDash: false,
          useEmDashInGroup: false,
          useEmDashInTotal: false,
        }),
      },
      {
        headerName: 'Contract type',
        field: 'vendor_type',
        width: 200,
        ...getGenericCellConfig({
          useEmDash: false,
          useEmDashInGroup: false,
          useEmDashInTotal: false,
        }),
      },
      {
        headerName: 'Current contract',
        field: 'version',
        flex: 1,
        valueFormatter: 'humanizeContractVersion',
        ...getGenericCellConfig({
          useEmDash: false,
          useEmDashInGroup: false,
          useEmDashInTotal: false,
        }),
      },
    ],
    [],
  );
}
