import { useState } from 'react';

import type {
  ContractBudgetRecordResponse,
  SnapshotRecordType,
} from 'shared/lib/types';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import type { ValidationError } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/ValidationSidebar';
import type { HandleAcceptForRollupRow } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/validation-handlers/RollupRowHandler';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useUpdateContractBudgetRecordMutation } from 'shared/api/rtkq/contractbudgetrecords';

import columns from '../columns';

type BudgetConfirmationStepProps = {
  allRecords: Array<ContractBudgetRecordResponse | undefined>;
  setValidationErrors: (validationErrors: ValidationError[]) => void;
  validationErrors: ValidationError[];
};

function BudgetConfirmationStep(props: BudgetConfirmationStepProps) {
  const { validationErrors, setValidationErrors, allRecords } = props;
  const [selectedErrorIndex, setSelectedErrorIndex] = useState<number>(0);
  const [updateContractBudgetRecord] = useUpdateContractBudgetRecordMutation();

  const handleAcceptForRollupRow: HandleAcceptForRollupRow = async (
    applyToAll: boolean,
    selectedError: ValidationError,
    isRollupRow: boolean,
  ) => {
    const allRollupRows = validationErrors
      .filter((error) => error.type === 'rollup')
      .map((error) => error.row);

    const rowsToApply = applyToAll ? allRollupRows : [selectedError.row];

    await Promise.all(
      rowsToApply.map(async (row) => {
        const rowToApply = allRecords[row];
        if (rowToApply === undefined) {
          return;
        }

        await updateContractBudgetRecord({
          trace_id: rowToApply.trace_id,
          confirmed_rollup_row: isRollupRow,
        });
      }),
    );

    const newValidationErrors = validationErrors.filter(
      (error) => !rowsToApply.includes(error.row),
    );

    setValidationErrors(newValidationErrors);
  };

  return (
    <WizardStep
      disableNextButton={validationErrors.length > 0}
      description={
        validationErrors.length > 0
          ? 'We will step you through each one. Accept or adjust the following'
          : ''
      }
      header={
        validationErrors.length > 0
          ? `We have detected ${validationErrors.length} issue${
              validationErrors.length > 1 ? 's' : ''
            } that require your validation.`
          : 'No issues detected. Click on Save & Continue to proceed'
      }
    >
      {allRecords.length > 0 && (
        <SnapshotRecordsTable
          handleAcceptForRollupRows={handleAcceptForRollupRow}
          headers={columns}
          rows={allRecords as SnapshotRecordType[]}
          selectedErrorIndex={selectedErrorIndex}
          setSelectedErrorIndex={setSelectedErrorIndex}
          validationErrors={validationErrors}
          showValidationSidebar
        />
      )}
    </WizardStep>
  );
}

export default BudgetConfirmationStep;
