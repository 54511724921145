import Typography from '@mui/material/Typography';

import Modal from 'shared/components/modal/Modal';

import { useReinitializeForecast } from 'forecasting/state/slices/forecastSlice';
import type { ForecastFolderResponse } from 'shared/lib/types';

import { useDeleteForecastFolderMutation } from 'shared/api/rtkq/forecastfolders';

type Props = {
  folder: ForecastFolderResponse;
  handleCloseModal: () => void;
};

function DeleteForecastFolderModal({ folder, handleCloseModal }: Props) {
  const { name, trace_id } = folder;
  const [deleteFolder, { isLoading: deleteFolderLoading }] =
    useDeleteForecastFolderMutation();
  const reInitForecast = useReinitializeForecast();

  const handleDelete = () => {
    void (async () => {
      reInitForecast();
      await deleteFolder(trace_id);
      handleCloseModal();
    })();
  };

  return (
    <Modal
      handleClose={handleCloseModal}
      title={`Are you sure you want to delete "${name}"`}
      ButtonProps={{
        label: 'Delete',
        testId: 'ForecastFolderModalDelete',
        loading: deleteFolderLoading,
        onClick: handleDelete,
      }}
      isOpen
    >
      <Typography>
        All contained forecasts will be deleted. This action cannot be undone.
      </Typography>
    </Modal>
  );
}

export default DeleteForecastFolderModal;
