import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import { getJSONFromFile } from 'shared/helpers/helpers';
import {
  type ContractContainerResponse,
  type ContractVersionResponse,
  PeriodRecordBlobType,
} from 'shared/lib/types';

import { useGetPeriodRecordBlobQuery } from 'shared/api/rtkq/periodrecordblobs';

type ReturnValue = {
  poNumber: string | undefined;
  vendorName: string | undefined;
};

export function processContractVersionBackButton(
  contractContainer: ContractContainerResponse | undefined,
  contractVersion: ContractVersionResponse | undefined,
): ReturnValue {
  return {
    vendorName: contractContainer?.vendor_name,
    poNumber: contractVersion?.po_number
      ? `(PO# ${contractVersion.po_number})`
      : '',
  };
}

export default function useContractVersionBackButton(): ReturnValue {
  const [returnValue, setReturnValue] = useState<ReturnValue>({
    poNumber: undefined,
    vendorName: undefined,
  });

  const {
    contractContainer,
    contractVersion,
    periodVersionTraceId,
    contractVersionPeriodMenuItemTraceId,
    isClosedPeriodVersion,
  } = useContractAndPeriodWithVersions();

  const { currentData: periodRecordBlob } = useGetPeriodRecordBlobQuery(
    contractVersionPeriodMenuItemTraceId && periodVersionTraceId
      ? {
          saved_object_type: PeriodRecordBlobType.CONTRACT_VERSION_HEADER,
          period_version: periodVersionTraceId,
          parent_menu_item: contractVersionPeriodMenuItemTraceId,
        }
      : skipToken,
  );

  // there can only ever be one, but our "getByFilters" helper assumes many
  const recordSnapshot = periodRecordBlob?.length
    ? periodRecordBlob[0]
    : undefined;

  useEffect(() => {
    void (async () => {
      if (isClosedPeriodVersion) {
        if (recordSnapshot !== undefined) {
          const json = await getJSONFromFile<ReturnValue>(recordSnapshot.blob);
          if (json !== undefined) {
            setReturnValue(json);
          }
        }
      } else {
        setReturnValue(
          processContractVersionBackButton(contractContainer, contractVersion),
        );
      }
    })();
  }, [
    isClosedPeriodVersion,
    contractContainer,
    contractVersion,
    recordSnapshot,
  ]);

  return returnValue;
}
