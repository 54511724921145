import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { formatFullDate } from 'shared/helpers/helpers';
import type { ContractFileApiResult } from 'shared/lib/types';

type Props = { files: ContractFileApiResult[] | undefined };

function ContractFilesList({ files }: Props) {
  return files?.map((file) => (
    <Box key={file.trace_id} sx={{ mb: 1 }}>
      <Link href={file.file} rel="noreferrer" target="_blank" variant="body1">
        {file.file_name}
      </Link>
      {!!file.created_at && (
        <Typography
          color="text.secondary"
          variant="body1"
        >{`Uploaded ${formatFullDate(file.created_at)}`}</Typography>
      )}
    </Box>
  ));
}

export default ContractFilesList;
