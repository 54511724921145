import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import CondorTextField from 'shared/components/text-field/CondorTextField';

import type { LabRequest } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

type Props = {
  disableNextButton?: boolean;
  labRecord: LabRequest;
  setLabRecord: (lab: LabRequest) => void;
  onSave: (() => void) | undefined;
};

function LabInfoStep(props: Props) {
  const { disableNextButton = false, labRecord, onSave, setLabRecord } = props;

  function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const newRecord = { ...labRecord, [name]: value };

    setLabRecord(newRecord);
  }

  return (
    <WizardStep
      disableNextButton={disableNextButton}
      nextButtonTextOverride="Create Lab"
      onNext={onSave}
    >
      <Box
        sx={{ display: 'flex', flexFlow: 'row', my: 2, alignItems: 'center' }}
      >
        <Typography
          align="right"
          className="required"
          sx={{ mr: 2 }}
          variant="body1"
        >
          Lab name
        </Typography>
        <FormControl sx={{ width: 300 }}>
          <CondorTextField
            label="Lab name"
            name="name"
            placeholder="Enter name"
            size="small"
            value={labRecord.name ?? ''}
            required
            onChange={onFieldChange}
          />
        </FormControl>
      </Box>
    </WizardStep>
  );
}

export default LabInfoStep;
