import { isValid } from 'date-fns/isValid';
import { enUS } from 'date-fns/locale/en-US';
import { parse } from 'date-fns/parse';

import DateFormatCondition from './DateFormatCondition';
import type SnapshotCell from './SnapshotCell';
import type { Validity } from './Validity';

class DateValueCondition implements Validity {
  static parsedStartDate = parse('2022-01-01', 'yyyy-MM-dd', new Date());
  static parsedEndDate = parse('2025-01-01', 'yyyy-MM-dd', new Date());

  isValid(cell: SnapshotCell): boolean {
    const value = cell.getValue();

    if (typeof value !== 'string') {
      return false;
    }

    const dateFormat = DateFormatCondition.getValidFormat(value);
    if (!dateFormat) {
      return false;
    }

    return isValid(parse(value, dateFormat, new Date(), { locale: enUS }));
  }
}

export default DateValueCondition;
