import { useEffect } from 'react';

import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import Button from 'shared/ui/button/Button';

import type {
  RegionGroupListItemType,
  RegionListItemType,
  TraceId,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';

import RegionListItem from './RegionListItem';

type Props = {
  contractVersionTraceId: TraceId;
  defaultRegions: RegionListItemType[];
  regionGroups: RegionGroupListItemType[];
  regions: RegionListItemType[];
  setRegions: (regions: RegionListItemType[]) => void;
};

function RegionList(props: Props) {
  const {
    contractVersionTraceId,
    regions,
    regionGroups,
    setRegions,
    defaultRegions,
  } = props;

  function handleRegionChange(regionListItem: RegionListItemType) {
    const index = regions.findIndex(
      (regionToFind) => regionToFind.trace_id === regionListItem.trace_id,
    );
    regions.splice(index, 1, regionListItem);
    setRegions([...regions]);
  }

  function handleAddRegion() {
    let fakeId = regions.length.toString(); // We need a unique identifier even though we haven't saved to the database yet.
    const existingTraceIds = new Set(regions.map((region) => region.trace_id));
    while (existingTraceIds.has(fakeId)) {
      fakeId = `${fakeId}${fakeId}`; // If we get a duplicate number due to repeated add/delete, double up the string to avoid collisions.
    }
    regions.push({
      trace_id: fakeId,
      name: '',
      contract_version: contractVersionTraceId,
      new: true,
      action: CRUDAction.CREATE,
    });
    setRegions([...regions]);
  }

  // && !regionToFind.global will remove the global region
  const nonDeletedRegions = regions.filter(
    (regionToFind) => regionToFind.action !== CRUDAction.DELETE,
  );

  useEffect(() => {
    if (regions.length === 0 && defaultRegions.length > 0) {
      setRegions([...defaultRegions]);
    }
  }, [defaultRegions, regions, setRegions]);

  return (
    <Box display="flex" justifyContent="center">
      <Box flex={1}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 'normal',
            '&:after': {
              content: '" *"',
              color: (theme) => theme.palette.error.main,
            },
          }}
        >
          Regions
        </Typography>
        <Typography
          color="text.secondary"
          style={{ marginTop: '5px' }}
          variant="body1"
        >
          At least one region is required.
        </Typography>
      </Box>
      <Box flex={1}>
        <List sx={{ mt: 1, ml: 2 }}>
          {nonDeletedRegions.map((regionListItem, index) => (
            <RegionListItem
              key={`${regionListItem.trace_id ?? ''}_${index}`}
              canDelete={nonDeletedRegions.length > 1} // we require a region to always be added
              label={`Region ${index + 1}`}
              region={regionListItem}
              regionGroups={regionGroups}
              regions={regions}
              onRegionChange={handleRegionChange}
            />
          ))}
        </List>
        <Button
          startIcon={<Add />}
          sx={{ ml: 2 }}
          testId="add_region"
          variant="text"
          disableElevation
          onClick={handleAddRegion}
        >
          Add Region
        </Button>
      </Box>
    </Box>
  );
}

export default RegionList;
