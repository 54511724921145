import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { addMonths } from 'date-fns/addMonths';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';

import CostForecast from 'forecasting/components/graphing/cost-forecast/CostForecast';
import TimeForecast from 'forecasting/components/graphing/time-forecast/TimeForecast';

import useFinancialForecastSummaryRows from 'forecasting/pages/forecasting/hooks/useFinancialForecastSummaryRows';
import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import { INVOICED } from 'shared/lib/types';

import useForecastAccruals from './hooks/useForecastAccruals';
import { getSum } from './utils';

function CostAndTimeForecastGraph() {
  const { loading: forecastLoading, generatedForecast, period } = useForecast();
  const { loading: forecastSummaryLoading, rows: summaryRows } =
    useFinancialForecastSummaryRows();
  const { loading: accrualsLoading, rows: accrualRows } = useForecastAccruals();

  const cost = useMemo(
    () => ({
      contracted: { value: getSum(summaryRows, 'default_contract_value') ?? 0 },
      forecasted: { value: getSum(summaryRows, 'grand_total') ?? 0 },
      invoiced: { value: getSum(accrualRows, INVOICED) ?? 0 },
      ltdExpensed: { value: getSum(summaryRows, 'reconciled_expense') ?? 0 },
    }),
    [accrualRows, summaryRows],
  );

  const timeline = useMemo(() => {
    if (
      generatedForecast?.earliest_start_date &&
      generatedForecast.latest_close_date &&
      period?.end_date
    ) {
      const forecastedMonthsCount = generatedForecast.forecasted_months ?? 0;

      return {
        ltdExpense: {
          start: generatedForecast.earliest_start_date,
          end: period.end_date,
        },
        contracted: {
          end: generatedForecast.latest_close_date,
        },
        forecasted: {
          end: format(
            addMonths(
              parse(period.end_date, 'yyyy-MM-dd', new Date()),
              forecastedMonthsCount,
            ),
            'yyyy-MM-dd',
          ),
        },
      };
    }

    return undefined;
  }, [generatedForecast, period]);

  return (
    <ParentSize>
      {(parent) => {
        const graphHeight = (parent.height / 2) * 0.95;
        const graphWidth = Math.max(parent.width, 630);

        return forecastLoading || accrualsLoading || forecastSummaryLoading ? (
          <Skeleton
            height={parent.height}
            variant="rectangular"
            width={graphWidth}
          />
        ) : (
          <>
            <CostForecast data={cost} height={graphHeight} width={graphWidth} />
            {timeline && (
              <TimeForecast
                data={timeline}
                height={graphHeight}
                width={graphWidth}
              />
            )}
          </>
        );
      }}
    </ParentSize>
  );
}

export default CostAndTimeForecastGraph;
