export const parseNullableInt = (num: number | string | null | undefined) => {
  if (num === null || num === undefined) {
    return 0;
  }

  const parsedNumber = Number.parseInt(num.toString(), 10);
  return Number.isNaN(parsedNumber) ? 0 : parsedNumber;
};

export const parseNullableFloat = (num: number | string | null | undefined) => {
  if (num === null || num === undefined) {
    return 0;
  }

  const parsedNumber = Number.parseFloat(num.toString());
  return Number.isNaN(parsedNumber) ? 0 : parsedNumber;
};

export const addNullableFloats = (
  num1: number | string | null | undefined,
  num2: number | string | null | undefined,
) => {
  const parsedNum1 = parseNullableFloat(num1);
  const parsedNum2 = parseNullableFloat(num2);
  return parsedNum1 + parsedNum2;
};

export function filterUndefined<T>(
  value: Array<T | undefined> | T[] | undefined,
): T[] {
  if (value === undefined) {
    return [];
  }

  return value.filter((val) => val !== undefined) as T[];
}
