import type { ElementType } from 'react';
import { memo, useCallback } from 'react';

import type { CustomCellEditorProps } from '@ag-grid-community/react';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import type { InputBaseComponentProps } from '@mui/material/InputBase';

import CondorTextField, {
  textFieldClasses,
} from 'shared/components/text-field/CondorTextField';

import { currencyFormatter } from 'formatters';

type Props = CustomCellEditorProps & {
  inputType?: 'number' | 'text';
  currency?: string;
  inputComponent?: ElementType<InputBaseComponentProps>;
};

function AgGridTextEditor(props: Props) {
  const { value, inputType, onValueChange, currency, inputComponent } = props;
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onValueChange(event.target.value),
    [onValueChange],
  );

  return (
    <CondorTextField
      InputProps={{ inputComponent }}
      inputProps={{ type: inputType ?? 'text' }}
      size="small"
      value={value}
      variant="standard"
      startAdornment={
        currency === undefined ? (
          <ModeEditOutlinedIcon
            sx={{
              height: '12px',
              width: '12px',
              color: (theme) => theme.palette.grey[500],
              ml: '10px',
            }}
          />
        ) : (
          // convert $12.23 to $
          currencyFormatter(0, currency)
            .replace(/[\d.]/g, '')
            .trim()
        )
      }
      sx={{
        height: '100%',
        [`&.${textFieldClasses.root}`]: { height: 'inherit' },
        [`&.${textFieldClasses.root} input`]: {
          textAlign: 'right',
          fontSize: '12px !important',
        },
      }}
      autoFocus
      fullWidth
      onChange={handleOnChange}
    />
  );
}

export default memo(AgGridTextEditor);
