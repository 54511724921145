import { useMemo } from 'react';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import InvoiceListingHeader from './components/InvoiceListingHeader';
import InvoiceListingGrid from './grids/InvoiceListingGrid';

function InvoiceListing() {
  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <InvoiceListingHeader />
        <InvoiceListingGrid
          overlayNoRowsTemplate="No invoices added."
          sx={useMemo(() => ({ height: '100%' }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default InvoiceListing;
