import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import * as routes from 'routes';
import { getJSONFromFile } from 'shared/helpers/helpers';
import type { MenuItemType, PeriodBlob, TraceId } from 'shared/lib/types';
import { PeriodMenuItemType } from 'shared/lib/types';

import { useGetPeriodMenuItemQuery } from 'shared/api/rtkq/periodmenuitems';
import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

export default function useClosedPeriodContractContainerMenuItems(
  periodVersionTraceId: TraceId | undefined,
): {
  menuItems: MenuItemType[] | undefined;
  loading: boolean;
} {
  const [menuItems, setMenuItems] = useState<MenuItemType[] | undefined>();

  const isClosedPeriodVersion = useIsClosedPeriodVersion();
  const {
    currentData: periodMenuItems,
    isLoading,
    isUninitialized,
    isFetching,
  } = useGetPeriodMenuItemQuery(
    periodVersionTraceId
      ? {
          saved_object_type: PeriodMenuItemType.CRO_CONTRACT_CONTAINER,
          period_version: periodVersionTraceId,
        }
      : skipToken,
  );

  useEffect(() => {
    if (!isClosedPeriodVersion) {
      setMenuItems(undefined);
    }

    void (async () => {
      if (periodMenuItems?.length) {
        const promises = periodMenuItems.map(async (periodMenuItem) =>
          periodMenuItem.blob
            ? {
                title:
                  (await getJSONFromFile<PeriodBlob>(periodMenuItem.blob))
                    ?.title ?? '',
                link: routes.getClinicalExpensesByContractContainer(
                  periodMenuItem.trace_id,
                ),
                sort_order: periodMenuItem.sort_order,
              }
            : undefined,
        );
        const items = (await Promise.all(promises)).filter(Boolean);

        items.sort(
          (pmi1, pmi2) => (pmi1.sort_order ?? 0) - (pmi2.sort_order ?? 0),
        );

        setMenuItems(items);
      } else {
        setMenuItems(undefined);
      }
    })();
  }, [periodMenuItems, isClosedPeriodVersion]);

  return {
    menuItems,
    loading:
      isClosedPeriodVersion && (isLoading || isUninitialized || isFetching),
  };
}
