import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import type { LabCostGridResponse, LabGridRow } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetLabCostsQuery } from 'shared/api/rtkq/trials';

export function processLabCostGrid(
  labCostGridResponse: LabCostGridResponse | undefined,
) {
  return cloneDeep(labCostGridResponse?.rows);
}

export default function useLabCostMatrixRows(): LabGridRow[] | undefined {
  const trial = useSelector(selectTrial);
  const { currentData: labs } = useGetLabCostsQuery(trial.trace_id);
  return useMemo(() => processLabCostGrid(labs), [labs]);
}
