import { useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import Chip from 'shared/ui/chip/Chip';

import { humanize } from 'shared/helpers/helpers';
import type { TrialResponse } from 'shared/lib/types';

type TrialsListProps = {
  trials: TrialResponse[];
  onClickTrial: (trial: TrialResponse) => void;
};

function TrialsList(props: TrialsListProps) {
  const { trials, onClickTrial } = props;

  const trialsByProgram = groupBy(trials, 'program.trace_id');
  const allTrialPrograms = trials.map((trial) => trial.program);
  const programs = orderBy(uniqBy(allTrialPrograms, 'trace_id'), [
    (po) => po.name.toLowerCase(),
  ]);
  const [programStates, setProgramStates] = useState<boolean[]>(
    programs.map(() => true),
  );

  const handleProgramClick = (programIndex: number) => {
    const updatedProgram: { [k: string]: boolean } = {};
    updatedProgram[programIndex] = !programStates[programIndex];

    setProgramStates((oldProgramStates) => ({
      ...oldProgramStates,
      ...updatedProgram,
    }));
  };

  const isProgramOpen = (programIndex: number) => programStates[programIndex];
  return (
    <List component="nav" sx={{ width: '100%', maxWidth: '100%' }}>
      {programs.map(({ trace_id, name }, i) => (
        <Box
          key={trace_id}
          sx={{ borderTop: (theme) => `solid 1px ${theme.palette.grey[300]}` }}
        >
          <ListItemButton disableGutters onClick={() => handleProgramClick(i)}>
            <ListItemText
              primary={name}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItemButton>
          <Collapse in={isProgramOpen(i)} timeout="auto" unmountOnExit>
            <List disablePadding>
              {orderBy(trialsByProgram[trace_id], [
                (tr) => tr.study_id.toLowerCase(),
                (tr) => tr.indication.toLowerCase(),
              ]).map((trial) => (
                <ListItemButton
                  key={trial.trace_id}
                  component="li"
                  disableGutters
                  onClick={() => onClickTrial(trial)}
                >
                  <ListItemIcon sx={{ minWidth: 28, ml: 1 }} />
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mr: 2,
                    }}
                  >
                    <Stack sx={{ flexGrow: 1 }}>
                      <Typography color="text.primary" variant="caption">
                        {`${trial.study_id} • ${trial.indication}`}
                      </Typography>
                      <Typography
                        sx={{ color: (theme) => theme.palette.grey[900] }}
                        variant="caption"
                      >
                        {`Phase ${trial.phase}`}
                      </Typography>
                    </Stack>
                    <Typography sx={{ flexGrow: 0 }} variant="caption">
                      {!!trial.status && (
                        <Chip
                          label={humanize(trial.status)}
                          size="small"
                          sx={{ color: (theme) => theme.palette.grey[900] }}
                        />
                      )}
                    </Typography>
                  </Box>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </Box>
      ))}
    </List>
  );
}

export default TrialsList;
