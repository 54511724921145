import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { ForecastedValueResponse } from 'shared/lib/types';

import useForecast from './useForecast';

const useOperationalForecastSitesRows = (): {
  loading: boolean;
  rows: ForecastedValueResponse[] | undefined;
} => {
  const { loading, generatedForecast } = useForecast();

  return useMemo(() => {
    const rows =
      generatedForecast === undefined
        ? undefined
        : cloneDeep(generatedForecast.sites);
    return { loading, rows };
  }, [generatedForecast, loading]);
};

export default useOperationalForecastSitesRows;
