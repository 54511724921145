import { useMemo } from 'react';

import { getRowsForVersionHistory } from 'accruals/pages/clinical-expenses/shared/helpers';
import type {
  ContractContainerResponse,
  ContractVersionResponse,
  VersionHistoryRow,
} from 'shared/lib/types';

export default function useCroVersionHistoryRows(
  contractContainer: ContractContainerResponse | undefined,
  contractVersions: ContractVersionResponse[] | undefined,
): VersionHistoryRow[] | undefined {
  return useMemo(
    () => getRowsForVersionHistory(contractContainer, contractVersions),
    [contractContainer, contractVersions],
  );
}
