import Grid from '@mui/material/Grid';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

function DashboardCardDemo() {
  return (
    <Grid padding={4} spacing={3} container>
      <Grid xs={2} item>
        <DashboardCard title="<title of section>">
          (any ReactNode object can go here)
        </DashboardCard>
      </Grid>
    </Grid>
  );
}

export default DashboardCardDemo;
