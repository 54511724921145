import { useState } from 'react';

import Redo from '@mui/icons-material/Redo';
import Snackbar from '@mui/material/Snackbar';

import type { AlertColor } from 'shared/ui/alert/Alert';
import Alert from 'shared/ui/alert/Alert';
import Button from 'shared/ui/button/Button';
import IconButton from 'shared/ui/icon-button/IconButton';

import styles from './AlertDemo.module.scss';

type AlertSampleProps = {
  dismissable?: boolean;
  severity: AlertColor;
  withButtons?: boolean;
  withContent?: boolean;
};

function AlertDemo() {
  const [content, setContent] = useState<string | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  function AlertSample({
    severity,
    withContent,
    withButtons,
    dismissable,
  }: AlertSampleProps) {
    return (
      <div className={styles.alertDemo}>
        <Alert
          severity={severity}
          title="Alert title"
          onClick={() => null}
          onClose={dismissable ? () => null : undefined}
        >
          {withContent &&
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
          {withButtons && (
            <div className={styles.buttons}>
              <Button testId="one" variant="text">
                Button one
              </Button>
              <Button testId="two" variant="text">
                Button two
              </Button>
            </div>
          )}
        </Alert>
        <IconButton size="small" variant="secondary" onClick={onAddToSnackbar}>
          <Redo />
        </IconButton>
      </div>
    );
  }

  const onAddToSnackbar = (event: React.SyntheticEvent) => {
    setContent(event.currentTarget.previousElementSibling?.outerHTML);
    setOpen(true);
  };

  return (
    <>
      <div className={styles.alerts}>
        <AlertSample severity="success" dismissable withButtons withContent />
        <AlertSample severity="success" withContent />
        <AlertSample severity="success" />
        <AlertSample severity="success" dismissable />

        <AlertSample severity="warning" dismissable withButtons withContent />
        <AlertSample severity="warning" withContent />
        <AlertSample severity="warning" />
        <AlertSample severity="warning" dismissable />

        <AlertSample severity="error" dismissable withButtons withContent />
        <AlertSample severity="error" withContent />
        <AlertSample severity="error" />
        <AlertSample severity="error" dismissable />

        <AlertSample severity="info" dismissable withButtons withContent />
        <AlertSample severity="info" withContent />
        <AlertSample severity="info" />
        <AlertSample severity="info" dismissable />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        open={open}
        onClose={() => setOpen(false)}
      >
        {/* biome-ignore lint/security/noDangerouslySetInnerHtml: idk why this is here, but it's just a demo page */}
        <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
      </Snackbar>
    </>
  );
}

export default AlertDemo;
