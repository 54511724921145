import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { selectIsCohortsValid } from 'shared/state/slices/visitSchedulesSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import CohortsList from './components/CohortsList';
import CurrentCohortBar from './components/CurrentCohortBar';
import VisitsList from './components/VisitsList';

function CohortAndVisitsStep() {
  const isCohortsValid = useSelector(selectIsCohortsValid);

  return (
    <WizardStep
      disableNextButton={!isCohortsValid}
      contentSx={{
        display: 'flex',
        flexDirection: 'column',
        pb: 0,
        mt: -2,
      }}
    >
      <Box display="flex" flex={1} flexDirection="row">
        <Box
          borderColor="grey.300"
          borderRight="1px solid"
          pr={2}
          width="268px"
        >
          <CohortsList />
        </Box>
        <Box display="flex" flex={1} flexDirection="column">
          <CurrentCohortBar />
          <VisitsList />
        </Box>
      </Box>
    </WizardStep>
  );
}

export default CohortAndVisitsStep;
