import { useEffect } from 'react';

import List from '@mui/material/List';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeCohortsOrder,
  selectCohorts,
} from 'shared/state/slices/visitSchedulesSlice';

import AddNewCohort from './AddNewCohort';
import DraggableCohortItem from './DraggableCohortItem';

function CohortsList() {
  const cohorts = useSelector(selectCohorts);
  const dispatch = useDispatch();

  useEffect(
    () =>
      monitorForElements({
        canMonitor({ source }) {
          return source.data.type === 'cohort';
        },
        onDrop({ location }) {
          const target = location.current.dropTargets[0];
          const source = location.initial.dropTargets[0];

          dispatch(
            changeCohortsOrder({
              sourceIndex: source.data.index as number,
              destinationIndex: target.data.index as number,
            }),
          );
        },
      }),
    [cohorts],
  );

  return (
    <List>
      {cohorts.map((cohort, index) => (
        <DraggableCohortItem
          key={cohort.traceId}
          cohort={cohort}
          index={index}
        />
      ))}
      <AddNewCohort />
    </List>
  );
}

export default CohortsList;
