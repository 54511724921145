import type { ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/material/styles';

import TabularDataCell from './TabularDataCell';

type Props = { icon: ReactNode; sx?: SxProps; title: string };

function TabularDataCellIcon(props: Props) {
  const { icon, title, sx } = props;

  return (
    <TabularDataCell sx={sx}>
      {icon}
      <Typography sx={{ ml: 1 }} variant="body2">
        {title}
      </Typography>
    </TabularDataCell>
  );
}

export default TabularDataCellIcon;
