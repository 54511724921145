import { isWithinInterval } from 'date-fns/isWithinInterval';
import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';

import type { GenericData } from 'shared/lib/types';

function contractDateValidator(
  rowData: GenericData[] | undefined,
): GenericData[] | undefined {
  const currentSiteContractTraceId: Record<string, GenericData | undefined> =
    {};

  for (const [siteTraceId, group] of Object.entries(
    groupBy(rowData, 'site_trace_id'),
  )) {
    // assume that the latest start date is the current site contract
    const row = maxBy(group, (row) => new Date(row.start_date ?? 0).valueOf());

    if (row?.start_date) {
      currentSiteContractTraceId[siteTraceId] = row;
    }
  }

  const data = rowData?.map((item, _, array) => {
    const isCurrentSiteContract =
      item.site_contract_trace_id ===
      currentSiteContractTraceId[item.site_trace_id!]?.site_contract_trace_id;

    const hasIncorrectDates = array.find((itemToSearch) => {
      if (itemToSearch.site_trace_id !== item.site_trace_id) {
        // do not validate different sites
        return false;
      }

      if (isCurrentSiteContract) {
        if (!item.end_date) {
          // current site contract can have an empty end date
          return false;
        }

        const startDate = new Date(item.start_date ?? 0);
        const endDate = new Date(item.end_date);

        // current site contract cannot have an end date before the start date
        return startDate.valueOf() >= endDate.valueOf();
      }

      if (item.site_contract_trace_id === itemToSearch.site_contract_trace_id) {
        // do not validate same contract version
        return false;
      }

      const startDate = new Date(item.start_date ?? 0);
      const endDate = new Date(item.end_date ?? 0);
      const startDateToSearch = new Date(itemToSearch.start_date ?? 0);
      const endDateToSearch = new Date(itemToSearch.end_date ?? 0);
      if (startDate.valueOf() > endDate.valueOf()) {
        // start date cannot be after end date
        return true;
      }

      if (
        itemToSearch.start_date === item.start_date ||
        itemToSearch.start_date === item.end_date
      ) {
        // start date cannot be the same as another contract version's start date or end date
        return true;
      }

      try {
        if (
          isWithinInterval(startDateToSearch, {
            start: startDate,
            end: endDate,
          })
        ) {
          // start date cannot be within another contract version's start date and end date
          return true;
        }

        return isWithinInterval(endDateToSearch, {
          start: startDate,
          end: endDate,
        });
      } catch {
        // date-fns throws an error if the date interval is invalid
        return false;
      }
    });

    return {
      ...item,
      contract_dates_invalid: !!hasIncorrectDates,
      current_site_contract: isCurrentSiteContract,
    };
  });

  return data as GenericData[] | undefined;
}

export default contractDateValidator;
