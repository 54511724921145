import { useMemo } from 'react';

import { getNativeCurrencyMoneyCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

export default function useOccContractBudgetColumnDefs(
  _isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      { field: 'traceId', hide: true },
      { field: 'nativeCurrency', hide: true },
      {
        field: 'costCategory',
        headerName: 'Activity Description',
        flex: 1,
        aggFunc: 'totalLabel',
      },
      { field: 'glAccount', headerName: 'G/L account', flex: 1 },
      {
        field: 'netValue',
        headerName: 'Total contract value',
        flex: 1,
        ...getNativeCurrencyMoneyCellConfig(),
        aggFunc: 'sum',
      },
    ],
    [],
  );
}
