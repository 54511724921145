import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import Autocomplete from 'shared/ui/autocomplete/Autocomplete';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import AssumptionGroupSection from 'shared/lib/contract-assumptions/assumption-groups/AssumptionGroupSection';
import { getAssumptionGroupNamesByAssumptionGroupSectionName } from 'shared/lib/contract-assumptions/assumption-groups/helpers';
import type {
  AssumptionGroupRequestDisplay,
  ContractVersionResponse,
  DropdownOption,
  RegionListItemType,
  RegionResponse,
  TraceId,
  VendorType,
} from 'shared/lib/types';
import { AssumptionGroupSectionName } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetContractAssumptionGroupsByContractVersionsQuery } from 'shared/api/rtkq/assumptiongroups';
import { useGetContractWithStatusQuery } from 'shared/api/rtkq/contracts';

type Props = {
  assumptionGroups: AssumptionGroupRequestDisplay[];
  contractVersionTraceId: TraceId;
  regionMap: Record<string, RegionResponse>;
  regions: RegionListItemType[];
  selectedContractVersions: ContractVersionResponse[];
  setAssumptionGroups: (
    assumptionGroups: AssumptionGroupRequestDisplay[],
  ) => void;
  vendorType: VendorType | undefined;
  onSave?: () => void;
};

const convertToDropdownOption = (
  currentContract: ContractVersionResponse,
): { value: TraceId<string>; label: string } => ({
  value: currentContract.trace_id,
  label: `${currentContract.vendor_name ?? ''} #${currentContract.po_number ?? ''}`,
});

function ContractParametersStep(props: Props) {
  const {
    contractVersionTraceId,
    vendorType,
    regions,
    regionMap,
    assumptionGroups,
    setAssumptionGroups,
    onSave,
    selectedContractVersions,
  } = props;
  const period = useSelector(selectPeriod);
  const { currentData: contractVersion } = useGetContractWithStatusQuery({
    trace_id: contractVersionTraceId,
    otherParameter: period.trace_id,
  });
  const { currentData: inheritableAssumptionGroups = [] } =
    useGetContractAssumptionGroupsByContractVersionsQuery(
      selectedContractVersions.map((contract) => contract.trace_id).join(',') ||
        skipToken,
    );

  const inheritanceGroups = [
    'Timeline',
    ...regions.map((region) => region.name),
  ];
  const dropdownOptions = inheritanceGroups.map((inheritanceGroup) => [
    ...selectedContractVersions
      .filter((contract) =>
        isValidInheritedContractForRegion(contract, inheritanceGroup),
      )
      .map(convertToDropdownOption),
    {
      value: 'CUSTOM',
      label: 'Custom',
    },
  ]);
  const [dropdownSelections, setDropdownSelections] = useState<
    Array<{ value: TraceId<string>; label: string } | null>
  >(Array(inheritanceGroups.length).fill(null));
  const requiredAssumptionGroups = assumptionGroups.filter(
    (assumptionGroup) => assumptionGroup.required && assumptionGroup.editable,
  );
  const disableNextButton = requiredAssumptionGroups.some(
    (assumptionGroup) =>
      assumptionGroup.contract_params.length === 0 ||
      assumptionGroup.contract_params.some(
        (param) => param.value === undefined || param.value.length === 0,
      ) ||
      (assumptionGroup.showByRegion &&
        !regions.every((region) =>
          assumptionGroup.contract_params.some(
            (param) =>
              param.region === region.trace_id ||
              (param.region != null &&
                param.region in regionMap &&
                regionMap[param.region].name === region.name),
          ),
        )),
  );

  function isValidInheritedContractForRegion(
    contract: ContractVersionResponse,
    regionName: string,
  ) {
    if (regionName === 'Timeline') {
      return true;
    }
    return contract.regions?.some(
      (regionId) => regionMap[regionId].name === regionName,
    );
  }

  function handleInheritedContractChange(
    selectedContractTraceId: DropdownOption<TraceId | 'CUSTOM'> | null,
    regionName: TraceId | 'Timeline',
  ) {
    if (selectedContractTraceId?.value === 'CUSTOM') {
      return;
    }

    if (regionName === 'Timeline') {
      setAssumptionGroups(
        assumptionGroups.map((assumptionGroup) => {
          if (
            getAssumptionGroupNamesByAssumptionGroupSectionName(
              AssumptionGroupSectionName.TIMELINE,
            ).includes(assumptionGroup.name)
          ) {
            return {
              ...assumptionGroup,
              contract_params:
                inheritableAssumptionGroups.filter(
                  (assumption) =>
                    selectedContractTraceId !== null &&
                    assumption.contract_version ===
                      selectedContractTraceId.value &&
                    assumption.name === assumptionGroup.name,
                )[0]?.contract_params ?? [],
            };
          }
          return assumptionGroup;
        }),
      );
    } else {
      setAssumptionGroups(
        assumptionGroups.map((assumptionGroup) => {
          const existingParams = assumptionGroup.contract_params.filter(
            (contractParam) =>
              !contractParam.region ||
              (contractParam.region in regionMap
                ? regionMap[contractParam.region].name
                : contractParam.region) !== regionName,
          );
          const updatedParams =
            inheritableAssumptionGroups
              .filter(
                (inheritableAssumptionGroup) =>
                  selectedContractTraceId !== null &&
                  inheritableAssumptionGroup.contract_version ===
                    selectedContractTraceId.value &&
                  inheritableAssumptionGroup.name === assumptionGroup.name,
              )[0]
              ?.contract_params.filter(
                (contractParam) =>
                  contractParam.region &&
                  (contractParam.region in regionMap
                    ? regionMap[contractParam.region].name
                    : contractParam.region) === regionName,
              ) ?? [];
          return {
            ...assumptionGroup,
            contract_params: [...existingParams, ...updatedParams],
          };
        }),
      );
    }
  }

  return (
    <WizardStep
      description={`Some values will be auto-calculated for you. ${selectedContractVersions.length !== 0 ? "If you choose to use another contract's assumptions, the values will be pre-filled." : ''}`}
      disableNextButton={disableNextButton}
      header={`Fill in the assumptions as specified in the ${
        vendorType === 'CRO' ? 'CRO contract' : 'Other Clinical Contract'
      }.`}
      onNext={onSave}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          width: '100%',
          alignItems: 'top',
          justifyContent: 'space-evenly',
          gap: 2,
          mt: 2,
        }}
      >
        {selectedContractVersions.length !== 0 ? (
          <Box sx={{ width: '100%' }}>
            {inheritanceGroups.map((inheritanceGroup, i) => (
              <Box key={inheritanceGroup} mb={3}>
                <Typography variant="subtitle2">
                  {inheritanceGroup}
                  {inheritanceGroup === 'Timeline' ? '' : ' sites/patients'}
                </Typography>
                <Autocomplete
                  label=""
                  options={dropdownOptions[i]}
                  sx={{ width: '100%' }}
                  value={dropdownSelections[i]}
                  onChange={(_event, value) => {
                    const newState = [...dropdownSelections];
                    newState[i] = value;
                    setDropdownSelections(newState);
                    handleInheritedContractChange(value, inheritanceGroup);
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : null}
        <AssumptionGroupSection
          assumptionGroups={assumptionGroups}
          regionMap={regionMap}
          regions={regions}
          setAssumptionGroups={setAssumptionGroups}
          type={AssumptionGroupSectionName.TIMELINE}
          versionName={contractVersion?.version_name}
          setCustomInheritedContract={(_value) => {
            const newState = [...dropdownSelections];
            newState[0] = { value: 'CUSTOM', label: 'Custom' };
            setDropdownSelections(newState);
          }}
        />
        <Box sx={{ width: '100%' }}>
          <AssumptionGroupSection
            assumptionGroups={assumptionGroups}
            regionMap={regionMap}
            regions={regions}
            setAssumptionGroups={setAssumptionGroups}
            type={AssumptionGroupSectionName.PATIENT_ENROLLMENT}
            versionName={contractVersion?.version_name}
            setCustomInheritedContract={(region) => {
              setDropdownSelections(
                dropdownSelections.map((selection, i) =>
                  inheritanceGroups[i] !== region
                    ? selection
                    : { value: 'CUSTOM', label: 'Custom' },
                ),
              );
            }}
          />
          <AssumptionGroupSection
            assumptionGroups={assumptionGroups}
            regionMap={regionMap}
            regions={regions}
            setAssumptionGroups={setAssumptionGroups}
            sx={{ mt: 4 }}
            type={AssumptionGroupSectionName.PATIENT_MONTHS}
            versionName={contractVersion?.version_name}
            setCustomInheritedContract={(region) => {
              setDropdownSelections(
                dropdownSelections.map((selection, i) =>
                  inheritanceGroups[i] !== region
                    ? selection
                    : { value: 'CUSTOM', label: 'Custom' },
                ),
              );
            }}
          />
        </Box>
        <AssumptionGroupSection
          assumptionGroups={assumptionGroups}
          regionMap={regionMap}
          regions={regions}
          setAssumptionGroups={setAssumptionGroups}
          type={AssumptionGroupSectionName.SITES}
          versionName={contractVersion?.version_name}
          setCustomInheritedContract={(region) => {
            setDropdownSelections(
              dropdownSelections.map((selection, i) =>
                inheritanceGroups[i] !== region
                  ? selection
                  : { value: 'CUSTOM', label: 'Custom' },
              ),
            );
          }}
        />
      </Box>
    </WizardStep>
  );
}

export default ContractParametersStep;
