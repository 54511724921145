import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import useGridRowsDataLoader from 'shared/lib/periods/hooks/useGridRowsDataLoader';
import { type AccrualsGridRow, PeriodGridBlobType } from 'shared/lib/types';
import { processAccrualsRows } from 'shared/processors/processAccrualsRows';

import { useGetAccrualsQuery } from 'shared/api/rtkq/periods';

export default function useForecastAccruals(): {
  loading: boolean;
  rows: AccrualsGridRow[] | undefined;
} {
  const {
    loading: forecastLoading,
    period,
    latestPeriodVersion,
  } = useForecast();
  const isPeriodClosed = period?.is_closed === true;

  const {
    currentData: openPeriodAccrualsRows,
    isLoading: loadingAccruals,
    isFetching: fetchingAccruals,
    isUninitialized: uninitializedAccruals,
  } = useGetAccrualsQuery(
    period && !isPeriodClosed ? period.trace_id : skipToken,
  );

  const { loading: gridBlobLoading, data: closedPeriodAccrualsRows } =
    useGridRowsDataLoader<AccrualsGridRow[]>(
      PeriodGridBlobType.ACCRUALS,
      isPeriodClosed ? latestPeriodVersion?.trace_id : undefined,
    );

  return useMemo(
    () => ({
      loading:
        forecastLoading ||
        (period?.is_closed === false &&
          (loadingAccruals || fetchingAccruals || uninitializedAccruals)) ||
        (period?.is_closed === true && gridBlobLoading),
      rows: processAccrualsRows(
        openPeriodAccrualsRows ?? { rows: closedPeriodAccrualsRows },
      ),
    }),
    [
      period?.is_closed,
      forecastLoading,
      loadingAccruals,
      fetchingAccruals,
      uninitializedAccruals,
      openPeriodAccrualsRows,
      closedPeriodAccrualsRows,
      gridBlobLoading,
    ],
  );
}
