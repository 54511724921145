import { useMemo } from 'react';

import BookOutlined from '@mui/icons-material/BookOutlined';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import Timeline from '@mui/icons-material/Timeline';
import Vaccines from '@mui/icons-material/Vaccines';
import { useSelector } from 'react-redux';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import * as routes from 'routes';
import SidebarContainer, {
  DIVIDER,
  ICON_SX,
} from 'shared/lib/sidebar/SidebarContainer';
import SidebarMenuItems from 'shared/lib/sidebar/SidebarMenuItems';
import type { SidebarSectionItem } from 'shared/lib/sidebar/SidebarSection';
import SidebarSkeletonContainer from 'shared/lib/sidebar/SidebarSkeletonContainer';
import SidebarTabs from 'shared/lib/sidebar/SidebarTabs';
import TrialNameAndBackButton from 'shared/lib/sidebar/TrialNameAndBackButton';
import { selectSidebarCollapsed } from 'shared/state/slices/sidebarSlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

import PeriodSelector from './PeriodSelector';
import useClosedPeriodContractContainerMenuItems from './hooks/useClosedPeriodContractContainerMenuItems';
import useOpenPeriodContractContainerMenuItems from './hooks/useOpenPeriodContractContainerMenuItems';

function AccrualsSidebar() {
  const trial = useSelector(selectTrial);
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const collapsed = useSelector(selectSidebarCollapsed);
  const isClosed = useIsClosedPeriodVersion();

  const { menuItems: openContractContainerMenuItems, loading: openLoading } =
    useOpenPeriodContractContainerMenuItems(trial.trace_id);
  const {
    menuItems: closedContractContainerMenuItems,
    loading: closedLoading,
  } = useClosedPeriodContractContainerMenuItems(currentPeriodVersion.trace_id);

  // only one of these will be set correctly due to how the hooks work
  const contractContainerMenuItems =
    openContractContainerMenuItems ?? closedContractContainerMenuItems;
  const isLoading = openLoading || closedLoading;

  const navigationItems: Array<SidebarSectionItem | typeof DIVIDER> =
    useMemo(() => {
      const companyMenuItems = [
        {
          title: 'Company',
          icon: <RestoreIcon sx={ICON_SX} />,
          disabled: false,
          subNav: [
            {
              title: 'PO Listing',
              link: routes.getPurchaseOrders(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'Invoice listing',
              link: routes.getInvoices(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'FX Rates',
              link: routes.getFxRates(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'G/L accounts',
              link: routes.getGlAccounts(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'Company Files',
              link: routes.getCompanyFiles(routes.getCompanyPeriodClosed()),
            },
            {
              title: 'Users & Permissions',
              link: routes.getUsersAndPermissions(
                routes.getCompanyPeriodClosed(),
              ),
            },
          ],
        },
      ];

      const trialMenuItems = [
        {
          title: 'Trial Dashboard',
          icon: <DashboardOutlined sx={ICON_SX} />,
          link: routes.getTrialDashboard(),
        },
        {
          title: 'Analytics',
          icon: <Timeline sx={ICON_SX} />,
          link: routes.getAnalytics(),
        },
        {
          title: 'Financial Close',
          icon: <CalendarMonth sx={ICON_SX} />,
          link: routes.getFinancialClose(),
        },
        {
          title: 'Journal Entry',
          icon: <MonetizationOnOutlined sx={ICON_SX} />,
          link: routes.getJournalEntry(),
        },
        DIVIDER,
        {
          title: 'Clinical Expenses',
          icon: <BookOutlined sx={ICON_SX} />,
          disabled: false,
          subNav: [
            { title: 'Summary', link: routes.getClinicalExpenses() },
            ...(contractContainerMenuItems ?? []),
            { title: 'Other Clinical Contracts', link: routes.getOcc() },
          ],
        },
        DIVIDER,
        {
          title: 'Trial Activity',
          icon: <Vaccines sx={ICON_SX} />,
          subNav: [
            {
              title: 'Patient Activity',
              link: routes.getPatientNavigation('patient-activity'),
            },
            {
              title: 'Site & Lab Cost Matrix',
              link: routes.getSiteLabCostMatrix(),
            },
          ],
        },
        DIVIDER,
        {
          title: 'Trial Files',
          icon: <DescriptionIcon sx={ICON_SX} />,
          link: routes.getTrialFiles(),
        },
      ];

      return [
        ...trialMenuItems,
        ...(isClosed ? [DIVIDER, ...companyMenuItems] : []),
      ];
    }, [isClosed, contractContainerMenuItems]);

  return (
    <SidebarContainer>
      <TrialNameAndBackButton collapsed={collapsed} />
      <SidebarTabs activeTab="accruals" collapsed={collapsed} />
      <PeriodSelector collapsed={collapsed} />
      {isLoading ? (
        <SidebarSkeletonContainer collapsed={collapsed} numSkeletons={8} />
      ) : (
        <SidebarMenuItems collapsed={collapsed} items={navigationItems} />
      )}
    </SidebarContainer>
  );
}

export default AccrualsSidebar;
