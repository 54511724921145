import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import {
  unitDetailFromBackendValue,
  unitTypeFromBackendValue,
} from 'shared/lib/driverUnitTypes';
import type { CroReconResponse, CroReconRow } from 'shared/lib/types';

export function processReconRow(
  reconData?: CroReconResponse,
): CroReconRow[] | undefined {
  const rows = reconData?.rows.map((row) => ({
    ...row,
    ...(row.unitType && { unitType: unitTypeFromBackendValue(row.unitType) }),
    ...(row.unitDetail && {
      unitDetail: unitDetailFromBackendValue(row.unitDetail),
    }),
    ...(row.aipUnitType && {
      aipUnitType: unitTypeFromBackendValue(row.aipUnitType),
    }),
    ...(row.aipUnitDetail && {
      aipUnitDetail: unitDetailFromBackendValue(row.aipUnitDetail),
    }),
  }));

  if (rows !== undefined && rows.length > 0) {
    if (reconData?.bottom_line_adjustments) {
      for (const blaRow of reconData.bottom_line_adjustments) {
        rows.push(blaRow);
      }
    }
    if (reconData?.aip_bottom_line_adjustments) {
      for (const aipBlaRow of reconData.aip_bottom_line_adjustments) {
        rows.push(aipBlaRow);
      }
    }
  }

  return cloneDeep(rows);
}

export default function useReconRowData(
  reconData?: CroReconResponse,
): CroReconRow[] | undefined {
  return useMemo(() => processReconRow(reconData), [reconData]);
}
