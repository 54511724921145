import useIsReadOnlyPermission from 'shared/lib/read-only-permission/useIsReadOnlyPermission';

import type { CondorColDef } from '../types';

export default function useGridColDefs<T>(
  colDefsHook: (...rest: any[]) => Array<CondorColDef<T>>,
  params?: unknown[],
): Array<CondorColDef<T>> {
  const isReadOnlyPermission = useIsReadOnlyPermission();
  return colDefsHook.apply(null, [!isReadOnlyPermission, ...(params ?? [])]);
}
