import type { ElementType, Ref } from 'react';
import { forwardRef } from 'react';

import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { InputBaseComponentProps } from '@mui/material/InputBase';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';

import AgGridTextEditor from 'shared/components/ag-grid-cells/editors/ag-grid-text-editor/AgGridTextEditor';

type CustomProps = {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
};

function PercentFormatCustom(props: CustomProps, ref: Ref<NumericFormatProps>) {
  const { onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      decimalScale={2}
      getInputRef={ref}
      suffix="%"
      thousandSeparator
      valueIsNumericString
      onValueChange={(values) => {
        onChange({ target: { name, value: values.value } });
      }}
    />
  );
}

function AgGridPercentEditor(props: CustomCellEditorProps) {
  return (
    <AgGridTextEditor
      {...props}
      inputComponent={
        forwardRef<NumericFormatProps, CustomProps>(
          PercentFormatCustom,
        ) as unknown as ElementType<InputBaseComponentProps>
      }
    />
  );
}

export default AgGridPercentEditor;
