import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import type {
  PatientAssessmentEditor,
  PatientAssessmentError,
  PatientAssessmentRequestParams,
  PatientAssessmentResponse,
  PatientCohortError,
} from 'shared/lib/types';

import {
  useCreatePatientAssessmentMutation,
  useUpdatePatientAssessmentMutation,
} from 'shared/api/rtkq/patientassessments';

const useSavePatientVisit = (): [
  (visit: PatientAssessmentEditor) => Promise<{
    data: PatientAssessmentResponse | undefined;
    error: PatientCohortError | undefined;
  }>,
  { isLoading: boolean },
] => {
  const [createPatientAssessmentRequest, { isLoading: isLoadingCreate }] =
    useCreatePatientAssessmentMutation();
  const [updatePatientAssessmentRequest, { isLoading: isLoadingUpdate }] =
    useUpdatePatientAssessmentMutation();

  const savePatientVisit = async (visit: PatientAssessmentEditor) => {
    let apiError: PatientCohortError | undefined;

    const patientVisitData: PatientAssessmentRequestParams = {
      patient_cohort: visit.patientCohort,
      name: visit.name,
      pizza_name: visit.pizzaName,
      is_screen: visit.isScreen,
      is_enroll: visit.isEnroll,
      is_screenfail: visit.isScreenfail,
      is_dropped_completed: visit.isDroppedCompleted,
      order_index: visit.orderIndex,
      day_of_protocol: visit.dayOfProtocol,
      timeline_portion: visit.timelinePortion,
    };

    let data: PatientAssessmentResponse | undefined;
    try {
      if (visit.traceId && !visit.isNew) {
        data = await updatePatientAssessmentRequest({
          trace_id: visit.traceId,
          ...patientVisitData,
        }).unwrap();
      } else {
        data = await createPatientAssessmentRequest(patientVisitData).unwrap();
      }
    } catch (error: unknown) {
      apiError = (error as FetchBaseQueryError).data as PatientAssessmentError;
    }

    return { data, error: apiError };
  };

  return [savePatientVisit, { isLoading: isLoadingCreate || isLoadingUpdate }];
};

export default useSavePatientVisit;
