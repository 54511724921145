import { useMemo } from 'react';

import maxBy from 'lodash/maxBy';
import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import type {
  ForecastFolderResponse,
  ForecastResponse,
  ForecastedGridsResponse,
  PeriodResponse,
  PeriodVersionResponse,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetForecastFoldersByTrialQuery } from 'shared/api/rtkq/forecastfolders';
import { useGenerateForecastQuery } from 'shared/api/rtkq/forecasts';
import { useGetPeriodsByTrialQuery } from 'shared/api/rtkq/periods';
import { useGetPeriodVersionsByPeriodQuery } from 'shared/api/rtkq/periodversions';

const useForecast = (): {
  loading: boolean;
  forecast: ForecastResponse;
  period: PeriodResponse | undefined;
  folder: ForecastFolderResponse | undefined;
  latestPeriodVersion: PeriodVersionResponse | undefined;
  generatedForecast: ForecastedGridsResponse | undefined;
} => {
  const trial = useSelector(selectTrial);
  const forecast = useSelector(selectForecast);

  const { currentData: periods, isLoading: loadingPeriods } =
    useGetPeriodsByTrialQuery(trial.trace_id);
  const { currentData: folders, isLoading: loadingFolders } =
    useGetForecastFoldersByTrialQuery(trial.trace_id);

  const {
    currentData: generatedForecast,
    isFetching: fetchingForecast,
    isLoading: loadingForecast,
    isUninitialized: forecastUninitialized,
  } = useGenerateForecastQuery(forecast.trace_id);

  const {
    currentData: periodVersions,
    isLoading: loadingPeriodVersions,
    isFetching: fetchingPeriodVersions,
    isUninitialized: uninitializedPeriodVersions,
  } = useGetPeriodVersionsByPeriodQuery(forecast.start_period);

  return useMemo(
    () => ({
      loading:
        loadingPeriods ||
        loadingFolders ||
        fetchingForecast ||
        loadingForecast ||
        forecastUninitialized ||
        loadingPeriodVersions ||
        fetchingPeriodVersions ||
        uninitializedPeriodVersions,
      forecast,
      period: periods?.find(
        (period) => period.trace_id === forecast.start_period,
      ),
      folder: folders?.find((folder) => folder.trace_id === forecast.folder),
      latestPeriodVersion: maxBy(periodVersions, 'version_number'),
      generatedForecast,
    }),
    [
      loadingPeriods,
      loadingFolders,
      fetchingForecast,
      loadingForecast,
      forecastUninitialized,
      loadingPeriodVersions,
      fetchingPeriodVersions,
      uninitializedPeriodVersions,
      forecast,
      periods,
      folders,
      periodVersions,
      generatedForecast,
    ],
  );
};

export default useForecast;
