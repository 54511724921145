import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import NotAuthorized from 'shared/components/not-authorized/NotAuthorized';
import Roller from 'shared/ui/roller/Roller';

import { selectCompany } from 'shared/state/slices/companySlice';
import {
  missingTrial,
  selectTrial,
  selectTrialInitialized,
  useInitializeTrial,
} from 'shared/state/slices/trialSlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

function withTrialRequired<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
) {
  return function TrialRequiredWrapper(props: T): ReactNode {
    const company = useSelector(selectCompany);
    const trial = useSelector(selectTrial);
    const initialized = useSelector(selectTrialInitialized);
    const initTrial = useInitializeTrial();

    const {
      currentData: trials,
      isUninitialized,
      isLoading,
      isFetching,
    } = useGetTrialsByCompanyQuery(company.trace_id);

    const waitForApi = isUninitialized || isLoading || isFetching;
    const numTrials = trials?.length ?? 0;

    // only call initialize trial once
    useEffect(() => {
      if (waitForApi || initialized) {
        return;
      }

      initTrial(trials);
    }, [trials, waitForApi, initTrial, initialized]);

    if (!waitForApi && (trial === missingTrial || numTrials === 0)) {
      return <NotAuthorized />;
    }

    return waitForApi ? <Roller /> : <LiveDataComponent {...props} />;
  };
}

export default withTrialRequired;
