import type {
  SiteContractRequest,
  SiteContractResponse,
} from 'shared/lib/types';

import {
  PATIENT_ACTIVITY_GRID,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
} from './apiTags';
import constructApi from './constructApi';
import {
  CONTRACT_EXPENSES,
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  CONTRACT_RECON,
} from './contracts';
import { SITE_COST_MATRIX } from './periods';

const apiEndpointFactory = constructApi<
  SiteContractResponse,
  SiteContractRequest
>('sitecontracts').withTags('SITE_CONTRACTS', [
  SITE_COST_MATRIX,
  PATIENT_ACTIVITY_GRID,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
]);

const api = apiEndpointFactory.inject(() => ({
  createSiteContract: apiEndpointFactory.create([
    SITE_COST_MATRIX,
    PATIENT_ACTIVITY_GRID,
    CONTRACT_INVESTIGATOR_FEES_RECON,
  ]),
  updateSiteContract: apiEndpointFactory.update([
    SITE_COST_MATRIX,
    PATIENT_ACTIVITY_GRID,
    CONTRACT_INVESTIGATOR_FEES,
    CONTRACT_INVESTIGATOR_FEES_RECON,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
  ]),
}));

export const { useCreateSiteContractMutation, useUpdateSiteContractMutation } =
  api;
