import { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { useAccrualsRegionsAndAssumptionGroups } from 'accruals/components/contract-assumptions/hooks/useAccrualsRegionsAndAssumptionGroups';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import useSaveRegionsAndAssumptionGroups from 'shared/lib/contract-assumptions/hooks/useSaveRegionsAndAssumptionGroups';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type {
  ContractVersionResponse,
  RegionGroupResponse,
  RegionResponse,
  TraceId,
  VendorType,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';

import { useGetCurrentRegionsQuery } from 'shared/api/rtkq/regions';

import ConfigurationStep from './steps/ConfigurationStep';
import ContractParametersStep from './steps/ContractParametersStep';
import RegionStep from './steps/RegionStep';

type Props = {
  contractVersionTraceId?: TraceId;
  vendorType: VendorType | undefined;
  onClose: () => void;
};

function ContractAssumptionsWizard(props: Props) {
  const { vendorType, contractVersionTraceId, onClose } = props;

  const [selectedContractVersions, setSelectedContractVersions] = useState<
    ContractVersionResponse[]
  >([]);
  const [inheritedRegions, setInheritedRegions] = useState<string[]>([]);
  const [inheritedRegionGroups, setInheritedRegionGroups] = useState<
    RegionGroupResponse[]
  >([]);

  const period = useSelector(selectPeriod);
  const { currentData: inheritableRegions = [] } = useGetCurrentRegionsQuery(
    period.trace_id,
  );
  const regionMap = useMemo(
    () =>
      inheritableRegions.reduce<Record<string, RegionResponse>>(
        (acc, region) => {
          acc[region.trace_id] = region;
          return acc;
        },
        {},
      ),
    [inheritableRegions],
  );

  const [
    editableRegions,
    assumptionGroups,
    allRegions,
    defaultRegions,
    setRegions,
    allRegionGroups,
    defaultRegionGroups,
    setAllRegionGroups,
    setAssumptionGroups,
  ] = useAccrualsRegionsAndAssumptionGroups(
    contractVersionTraceId,
    false,
    inheritedRegions,
    inheritedRegionGroups,
  );
  const [saveRegionsAndAssumptionGroups] = useSaveRegionsAndAssumptionGroups(
    contractVersionTraceId,
    regionMap,
  );

  function onSave() {
    // TODO: Error handling
    void (async () => {
      await saveRegionsAndAssumptionGroups(
        allRegions,
        allRegionGroups,
        assumptionGroups,
      );
      onClose();
    })();
  }

  const showConfigurationStep = vendorType === 'OCC';

  return (
    <FullWidthDialog open>
      {contractVersionTraceId == null ? (
        <div />
      ) : (
        <Wizard
          devTitle="GENIE"
          title="Contract Assumptions"
          stepNames={[
            ...(showConfigurationStep ? ['Configuration'] : []),
            'Regions',
            'Assumptions',
          ]}
          onClose={onClose}
        >
          {showConfigurationStep && (
            <ConfigurationStep
              contractVersionTraceId={contractVersionTraceId}
              regionMap={regionMap}
              selectedContractVersions={selectedContractVersions}
              setInheritedRegionGroups={setInheritedRegionGroups}
              setInheritedRegions={setInheritedRegions}
              setSelectedCurrentContracts={setSelectedContractVersions}
            />
          )}
          <RegionStep
            contractVersionTraceId={contractVersionTraceId}
            defaultRegionGroups={defaultRegionGroups}
            defaultRegions={defaultRegions}
            regionGroups={allRegionGroups}
            regions={allRegions}
            setRegionGroups={setAllRegionGroups}
            setRegions={setRegions}
            vendorType={vendorType}
          />
          <ContractParametersStep
            assumptionGroups={assumptionGroups}
            contractVersionTraceId={contractVersionTraceId}
            regionMap={regionMap}
            regions={editableRegions}
            selectedContractVersions={selectedContractVersions}
            setAssumptionGroups={setAssumptionGroups}
            vendorType={vendorType}
            onSave={onSave}
          />
        </Wizard>
      )}
    </FullWidthDialog>
  );
}

export default ContractAssumptionsWizard;
