import type { ReactNode } from 'react';

import type {
  EditableCallbackParams,
  ICellRendererParams,
} from '@ag-grid-community/core';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

import { EM_DASH_PLACEHOLDER } from 'shared/components/ag-grid/helpers/shared';
import type { CondorColDef, IconTypes } from 'shared/components/ag-grid/types';

import { currencyFormatter } from 'formatters';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';

import { getIcon, getTextColor } from './helpers';

function AgGridMoneyCellRenderer(
  params: ICellRendererParams & {
    startIcon?: IconTypes;
    colDef?: CondorColDef;
    tooltipMessage?: string;
    placeholder?: ReactNode;
    useEmDash?: boolean;
    useEmDashInGroup?: boolean;
    useEmDashInTotal?: boolean;
    forecast?: boolean;
    severity?: 'error' | 'success' | 'warning';
    currencyKey?: string;
    startIcons?: IconTypes[]; // Backwards compatibility only
    currencyViewModeOverride?: CurrencyViewMode;
  },
) {
  const {
    currencyKey = 'currency',
    startIcons, // Backwards compatibility only
    startIcon = startIcons?.[0],
    colDef,
    data,
    node,
    tooltipMessage,
    value,
    placeholder,
    severity,
    useEmDash,
    useEmDashInGroup,
    useEmDashInTotal,
    forecast = false,
    context,
    currencyViewModeOverride,
  } = params;

  const theme = useTheme();

  const currencyViewMode: CurrencyViewMode | undefined =
    currencyViewModeOverride ?? context?.currencyViewMode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any  -- It can be any possible value that ag-grid knows how to render
  function getCurrency(nodeData: any): string | undefined {
    return currencyViewMode
      ? nodeData?.[currencyKey]?.[currencyViewMode]
      : nodeData?.[currencyKey];
  }

  const dataCurrency = getCurrency(data);
  let finalCurrency = dataCurrency;
  let finalValue = colDef?.refData?.[value] ?? value;

  if (dataCurrency === undefined) {
    if (node.footer) {
      const children =
        node.level === 2 ? node.parent?.allLeafChildren : node.allLeafChildren;
      const childCurrencies = new Set(
        children
          ?.filter(Boolean)
          ?.map((child) => getCurrency(child.data))
          .filter(Boolean),
      );
      if (childCurrencies.size === 1) {
        [finalCurrency] = childCurrencies;
      } else {
        finalCurrency = undefined;
      }
    } else {
      finalCurrency = 'USD'; // Default value for backwards compatibility only
    }
  }

  const formatOptions: Intl.NumberFormatOptions | null =
    finalCurrency !== undefined
      ? {
          currency: finalCurrency,
          minimumFractionDigits: 2,
        }
      : null;
  finalValue = currencyFormatter(value, formatOptions);

  const isEditable =
    typeof colDef?.editable === 'function'
      ? colDef.editable(params as EditableCallbackParams) // the params are "close enough" to the same thing that we can treat them the same as far as TS is concerned
      : colDef?.editable === true;

  if (
    !isEditable &&
    !placeholder &&
    (useEmDash || useEmDashInGroup || useEmDashInTotal)
  ) {
    const isZeroCurrency = finalValue === currencyFormatter(0, formatOptions);
    const isEmptyValue = !value || isZeroCurrency;
    const isGroup = node.group;
    const isTotal = node.footer;
    let shouldUseEmDash = false;
    if (useEmDashInTotal && isTotal && isEmptyValue) {
      shouldUseEmDash = true;
    } else if (useEmDashInGroup && isGroup && isEmptyValue) {
      shouldUseEmDash = true;
    } else if (useEmDash && isEmptyValue && !isGroup && !isTotal) {
      shouldUseEmDash = true;
    }

    if (shouldUseEmDash) {
      finalValue = EM_DASH_PLACEHOLDER;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
        height: '100%',
      }}
    >
      {getIcon(severity, theme, node, startIcon, finalValue)}
      <Box
        sx={{
          flex: 1,
          ml: 1,
          color: getTextColor(theme, severity, value, forecast, true),
        }}
      >
        {tooltipMessage !== undefined ? (
          <Tooltip
            title={tooltipMessage}
            PopperProps={{
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  bgcolor: 'common.black',
                },
              },
            }}
          >
            <span>{finalValue ?? placeholder}</span>
          </Tooltip>
        ) : (
          (finalValue ?? placeholder)
        )}
      </Box>
    </Box>
  );
}

export default AgGridMoneyCellRenderer;
