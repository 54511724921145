import type {
  ContractStatusRequestParams,
  ContractStatusResponse,
} from 'shared/lib/types';

import {
  ACCRUALS_GRID,
  BS_FLUX_GRID,
  CRO_EXPENSE_SUMMARY,
  JOURNAL_ENTRY_GRID,
  OCC_EXPENSE_SUMMARY,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
} from './apiTags';
import constructApi from './constructApi';
import { TAG as CONTRACT_CONTAINER } from './contractcontainers';
import {
  CONTRACT_EXPENSES,
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  CONTRACT_RECON,
} from './contracts';

const PATH = 'contractstatuses';

const apiEndpointFactory = constructApi<ContractStatusResponse>(PATH).withTags(
  'CONTRACTS_STATUSES',
  [
    CONTRACT_CONTAINER,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    CONTRACT_INVESTIGATOR_FEES,
    CONTRACT_INVESTIGATOR_FEES_RECON,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    CRO_EXPENSE_SUMMARY,
    OCC_EXPENSE_SUMMARY,
    ACCRUALS_GRID,
    JOURNAL_ENTRY_GRID,
    BS_FLUX_GRID,
  ],
);
const api = apiEndpointFactory.inject(() => ({
  updateContractStatus: apiEndpointFactory.update<ContractStatusRequestParams>([
    CONTRACT_CONTAINER,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    CONTRACT_INVESTIGATOR_FEES,
    CONTRACT_INVESTIGATOR_FEES_RECON,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    CRO_EXPENSE_SUMMARY,
    OCC_EXPENSE_SUMMARY,
    ACCRUALS_GRID,
    JOURNAL_ENTRY_GRID,
    BS_FLUX_GRID,
  ]),
}));

export const { useUpdateContractStatusMutation } = api;
