import type { ReactNode } from 'react';

import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';

type FullWidthDialogProps = {
  children?: ReactNode;
  open?: boolean;
  scroll?: DialogProps['scroll'];
  onClose?: () => void;
};

function FullWidthDialog(props: FullWidthDialogProps) {
  const { open, onClose, children, scroll = 'paper' } = props;

  return (
    <Dialog
      open={!!open}
      scroll={scroll}
      sx={{ margin: 5 }}
      PaperProps={{
        sx: {
          minWidth: '100%',
          maxWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%',
          height: 'inherit',
        },
      }}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
}

export default FullWidthDialog;
