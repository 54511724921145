import { useEffect } from 'react';

import List from '@mui/material/List';
import { useDispatch, useSelector } from 'react-redux';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import type { PatientAssessmentEditor } from 'shared/lib/types';
import {
  changeVisitsOrder,
  selectCohort,
} from 'shared/state/slices/visitSchedulesSlice';

import VisitItem from './VisitItem';

function VisitsList() {
  const dispatch = useDispatch();
  const currentCohort = useSelector(selectCohort);

  useEffect(
    () =>
      monitorForElements({
        canMonitor({ source }) {
          return source.data.type === 'visit';
        },
        onDrop({ location }) {
          const target = location.current.dropTargets[0];
          const source = location.initial.dropTargets[0];
          dispatch(
            changeVisitsOrder({
              sourceIndex: source.data.index as number,
              destinationIndex: target.data.index as number,
            }),
          );
        },
      }),
    [currentCohort?.patientAssessments],
  );

  return (
    <List
      sx={{
        ml: 2,
      }}
      dense
      disablePadding
    >
      {currentCohort?.patientAssessments.map(
        (visit: PatientAssessmentEditor, index) => (
          <VisitItem
            key={visit.traceId ?? visit.name}
            currentCohort={currentCohort}
            index={index}
            visit={visit}
          />
        ),
      )}
    </List>
  );
}

export default VisitsList;
