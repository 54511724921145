import { type ReactElement, useMemo } from 'react';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import EditSite from './components/EditSite';
import SiteLabHeader from './components/SiteLabHeader';
import LabGrid from './grids/LabGrid';
import SiteGrid from './grids/SiteGrid';

function SiteLabCostMatrixPage(): ReactElement {
  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <SiteLabHeader />
        <EditSite />
        <SiteGrid
          overlayNoRowsTemplate="No sites added."
          sx={useMemo(() => ({ height: '50%' }), [])}
        />
        <LabGrid
          overlayNoRowsTemplate="No labs added."
          sx={useMemo(() => ({ flex: 1, pt: 2 }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(SiteLabCostMatrixPage);
