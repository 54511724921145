export function isAllocatedPeriod(
  tickDate?: string,
  allocatePeriodDate?: string,
): boolean {
  if (!tickDate || !allocatePeriodDate) {
    return false;
  }

  return new Date(allocatePeriodDate) >= new Date(tickDate);
}

export function getDateMonthName(date?: string): string {
  if (!date) {
    return '';
  }

  const dateObject = new Date(date);
  return dateObject.toLocaleString('en-US', { month: 'short' });
}

export function getYearFromDateString(
  date?: string,
  showAllLabels?: boolean,
): string {
  if (!date) {
    return '';
  }

  const dateObject = new Date(date);

  if (showAllLabels || dateObject.getMonth() === 0) {
    return dateObject.getFullYear().toString();
  }

  return '';
}
