import type {
  CurrentUserResponse,
  EmailVerificationInfo,
  UserAddForm,
} from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'users';

export const USERS_TAG = 'users';

type UserResponse = EitherCompanyOrCompanyGroup &
  UserAddForm & {
    trace_id: string;
    url: string;
    message: string;
    email_send_status: 'success' | 'warning';
  };

const apiEndpointFactory = constructApi<UserResponse>(PATH).withTags(USERS_TAG);

type Company = {
  company_id: string;
  company_group_id?: never;
};

type CompanyGroup = {
  company_id?: never;
  company_group_id: string;
};

// When inviting a user, pass company or companygroup
// along with user data but never both
type EitherCompanyOrCompanyGroup = Company | CompanyGroup;

export const api = apiEndpointFactory.inject(() => ({
  getCurrentUser: apiEndpointFactory.getExtraRoute<CurrentUserResponse>('me'),
  reverifyUser: apiEndpointFactory.create<EmailVerificationInfo>([], 'verify'),
  inviteUser: apiEndpointFactory.create<
    EitherCompanyOrCompanyGroup & UserAddForm
  >(),
  checkEmailExists: apiEndpointFactory.create<{ email: string }>(
    [],
    'check_email_exists',
  ),
  updateUserAccess: apiEndpointFactory.update<
    UserResponse & { status: 'Active' | 'Inactive'; trials?: string[] }
  >([USERS_TAG], 'update_user_access'),
}));

export const {
  useGetCurrentUserQuery,
  useReverifyUserMutation,
  useInviteUserMutation,
  useCheckEmailExistsMutation,
  useUpdateUserAccessMutation,
} = api;
