import { useCallback } from 'react';

import type { LabRequest } from 'shared/lib/types';

import { useCreateLabContractMutation } from 'shared/api/rtkq/labcontracts';
import { useCreateLabMutation } from 'shared/api/rtkq/labs';

export default function useAddLab(): [
  (lab: LabRequest) => Promise<void>,
  { isLoading: boolean },
] {
  const [createLab, { isLoading: createLabLoading }] = useCreateLabMutation();
  const [createLabContract, { isLoading: createLabContractLoading }] =
    useCreateLabContractMutation();

  const callHooks = useCallback(
    async (lab: LabRequest) => {
      const newLab = await createLab(lab).unwrap();
      await createLabContract({ lab: newLab.trace_id }).unwrap();
    },
    [createLab, createLabContract],
  );

  return [
    callHooks,
    { isLoading: createLabLoading || createLabContractLoading },
  ];
}
