import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import BudgetForecast from 'forecasting/components/graphing/budget-forecast/BudgetForecast';

import { budgetForecast1, budgetForecast2, budgetForecast3 } from './data';

function BudgetForecastPerVendor() {
  return (
    <Paper elevation={1} sx={{ p: 4, width: '80%' }}>
      <Typography variant="subtitle1">
        Budget vs. forecast per vendor
      </Typography>
      <Divider sx={{ mt: 1 }} />
      <Box height={400} my={10}>
        <ParentSize>
          {(parent) => (
            <BudgetForecast
              data={budgetForecast1}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Box height={400} my={10}>
        <ParentSize>
          {(parent) => (
            <BudgetForecast
              data={budgetForecast2}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Box height={400} my={10}>
        <ParentSize>
          {(parent) => (
            <BudgetForecast
              data={budgetForecast3}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
    </Paper>
  );
}

export default BudgetForecastPerVendor;
