import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import type { ValidationError } from './ValidationSidebar';

type Props = { validationErrors: ValidationError[] };

type FieldCount = { field: string; count: number };

type ErrorFieldCount = Record<string, FieldCount>;

function ValidateAndCheckErrorsTooltip(props: Props) {
  const { validationErrors } = props;

  const summary = validationErrors.reduce<ErrorFieldCount>((acc, el) => {
    if (typeof acc[el.type] === 'undefined') {
      const [field] = el.fields;
      acc[el.type] = { field, count: 0 };
    }

    acc[el.type].count += 1;
    return acc;
  }, {});

  return (
    <Tooltip
      placement="left-start"
      PopperProps={{
        sx: {
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'common.white',
            border: '1px solid red',
            color: 'common.black',
            maxHeight: '60vh',
            overflow: 'auto',
          },
        },
      }}
      title={
        <List dense>
          <ListItem>
            <ListItemText
              primary="Validation issues summary"
              secondary={
                <List dense>
                  {Object.entries(summary).map(([key, info]) => (
                    <ListItem key={key} disableGutters>
                      <ListItemText
                        primary={`${key}: ${info.field}`}
                        secondary={`Flags: ${info.count}`}
                      />
                    </ListItem>
                  ))}
                </List>
              }
            />
          </ListItem>
        </List>
      }
    >
      <InfoIcon color="error" fontSize="small" />
    </Tooltip>
  );
}

export default ValidateAndCheckErrorsTooltip;
