import Tab from '@mui/material/Tab';
import { useNavigate, useParams } from 'react-router-dom';

import Tabs from 'shared/ui/tabs/Tabs';

import { getDevComponents } from 'routes';

import ComponentsByTitleKey from './ComponentsByTitleKey';

import styles from './ComponentSandbox.module.scss';

function ComponentSandbox() {
  const { componentKey } = useParams();
  const navigate = useNavigate();

  const handleChange = (_event: React.SyntheticEvent, newKey: string) => {
    navigate(`${getDevComponents()}/${newKey}`);
  };

  const activeComponent = componentKey
    ? ComponentsByTitleKey[componentKey]
    : null;

  return (
    <div className={styles.sandbox}>
      <div className={styles.components}>
        <Tabs
          orientation="vertical"
          value={componentKey}
          onChange={handleChange}
        >
          {Object.entries(ComponentsByTitleKey).map(([titleKey, { title }]) => (
            <Tab key={titleKey} label={title} value={titleKey} />
          ))}
        </Tabs>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          {activeComponent?.title ?? 'Component sandbox'}
        </div>
        <div className={styles.component}>{activeComponent?.demo}</div>
      </div>
    </div>
  );
}

export default ComponentSandbox;
