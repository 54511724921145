import color from 'color';

import colors from 'colors.module.scss';

import type { TrialSpendForecastConfig } from './types';

export const TRIAL_SPEND_FORECAST_GRAPH_CONFIG: TrialSpendForecastConfig = {
  margin: { top: 20, right: 80, bottom: 80, left: 150 },
  angleLabels: 'rotate(90,0,0)',
  horizontalLinesColor: colors.n300,
  fontSize: '14px',
  fontSizeYear: '12px',
  fontWeight: '400',
  fontWeightBold: '700',
  columnColor: colors.dividerLightTheme,
  textColor: colors.primaryTextLightTheme,
  yScaleConfig: { type: 'linear' },
  xScaleConfig: { type: 'band', paddingInner: 0.5, paddingOuter: 0.1 },
  enrollmentCurveColor: colors.n900,
  barColors: [colors.chart1, colors.chart2, colors.chart3, colors.chart4],
  directFeesText: 'DIRECT_FEES',
  passThroughText: 'PASS_THROUGH',
  investigatorFeesText: 'INVESTIGATOR_FEES',
  occText: 'OCC',
  orderOfData: ['DIRECT_FEES', 'PASS_THROUGH', 'INVESTIGATOR_FEES', 'OCC'],
  enrollmentScaleObj: { domain: [0, 0], range: [0, 0] },
  forecastedEnrollmentCurveColor: colors.chart1,
  actualEnrollmentCurveColor: colors.chart1,
  forecastedBarColors: [
    color(colors.chart1).alpha(0.25).rgb().string(),
    color(colors.chart2).alpha(0.25).rgb().string(),
    color(colors.chart3).alpha(0.25).rgb().string(),
    color(colors.chart4).alpha(0.25).rgb().string(),
  ],
  forecastedEnrollmentText: 'Forecasted enrollment',
  actualEnrollmentText: 'Actual enrollment',
};
