import { useMemo } from 'react';

import type {
  CellEditingStartedEvent,
  NewValueParams,
} from '@ag-grid-community/core';
import { useTheme } from '@mui/material/styles';

import {
  getDateCellConfig,
  getGenericCellConfig,
  getNullOrDecimalConfig,
  getPercentCellConfig,
  getPercentCompleteCellConfig,
  getSelectCellConfig,
  getToggleableMoneyCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import {
  ActivityUnitDetail,
  ActivityUnitType,
  detailChoicesForUnitType,
} from 'shared/lib/driverUnitTypes';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

const OCC_UNIT_TYPE_CHOICES = Object.values(ActivityUnitType).filter(
  (value) => value !== ActivityUnitType.NONE,
);

export function processOccExpenseGridColumnDefs(
  isOpenPeriod: boolean,
  expenseData: apiJSON | undefined,
  themeMode: 'dark' | 'light' | undefined,
) {
  const columnDefs = [
    {
      headerName: '',
      children: [
        { field: 'id', hide: true },
        { field: 'contractVersionTraceId', hide: true },
        { field: 'vendorName', rowGroup: true, hide: true },
        { field: 'rowGrouping', headerName: 'Cost Category', pinned: 'left' },
        { field: 'glAccount', headerName: 'G/L Account' },
        { field: 'traceId', hide: true },
        { field: 'hasEnrollment', hide: true },
        { field: 'hasTreatment', hide: true },
        { field: 'hasFollowup', hide: true },
        { field: 'serviceCategory', hide: true },
        {
          headerName: 'Total Contract Value',
          ...getToggleableMoneyCellConfig('current_contract_value'),
          aggFunc: 'sum',
        },
      ],
    },
    {
      headerName: 'Activity Driver Assignments',
      children: [
        { field: 'activityDriverTraceId', hide: true },
        {
          field: 'unitType',
          filter: true,
          ...(isOpenPeriod && {
            ...getSelectCellConfig({ useEmDashInTotal: false }),
            cellEditorParams: { values: OCC_UNIT_TYPE_CHOICES },
            onCellValueChanged(event: NewValueParams) {
              event.node?.setDataValue('unitDetail', null);
            },
            ...makeEditableIf(
              ({ data }) =>
                Boolean(data) && data.type !== 'BOTTOM_LINE_ADJUSTMENT',
              themeMode,
            ),
          }),
        },
        {
          field: 'unitDetail',
          filter: true,
          ...(isOpenPeriod && {
            ...getSelectCellConfig({ useEmDashInTotal: false }),
            cellEditorParams(params: CellEditingStartedEvent) {
              return {
                values: detailChoicesForUnitType(
                  params.data.unitType,
                  'OCC',
                ).filter(
                  (unitDetail) =>
                    (unitDetail !== ActivityUnitDetail.ENROLLMENT_PERIOD ||
                      params.data.hasEnrollment === true) &&
                    (unitDetail !== ActivityUnitDetail.TREATMENT_PERIOD ||
                      params.data.hasTreatment === true) &&
                    (unitDetail !== ActivityUnitDetail.FOLLOW_UP_PERIOD ||
                      params.data.hasFollowup === true),
                ),
              };
            },
            ...makeEditableIf(
              ({ data }) =>
                data?.unitType && data.type !== 'BOTTOM_LINE_ADJUSTMENT',
              themeMode,
            ),
          }),
        },
        {
          field: 'startDate',
          ...(isOpenPeriod
            ? {
                ...makeEditableIf(
                  ({ data }) => data?.unitDetail === ActivityUnitDetail.CUSTOM,
                  themeMode,
                ),
                ...getDateCellConfig({ useEmDashInTotal: false }),
              }
            : { ...getGenericCellConfig({ useEmDashInTotal: false }) }),
        },
        {
          field: 'endDate',
          ...(isOpenPeriod
            ? {
                ...makeEditableIf(
                  ({ data }) => data?.unitDetail === ActivityUnitDetail.CUSTOM,
                  themeMode,
                ),
                ...getDateCellConfig({ useEmDashInTotal: false }),
              }
            : { ...getGenericCellConfig({ useEmDashInTotal: false }) }),
        },
        {
          field: 'parameterCalculatedUnitCount',
          headerName: '# of units',
          ...getNullOrDecimalConfig({ emptyValueForNodeLevel: 0 }),
        },
        {
          headerName: 'Unit price',
          ...getToggleableMoneyCellConfig('unit_price'),
        },
      ],
    },
    {
      headerName: 'LTD Expense',
      children: [
        {
          field: 'percentRecognized',
          headerName: '% Recognized',
          aggFunc: 'expensePercentRecognizedTotal',
          ...getPercentCellConfig({ useEmDash: false }),
        },
        {
          field: 'monthlyExpenseUnitsTotal',
          headerName: 'Total LTD units',
          hide: true,
        },
        {
          headerName: 'Total LTD expense',
          ...getToggleableMoneyCellConfig('total_ltd_expense'),
          aggFunc: 'sum',
        },
      ],
    },
  ];

  if (expenseData) {
    columnDefs.push({
      headerName: 'Months',
      children: expenseData.month_list.flatMap((month: string) => {
        if (month.endsWith('_units')) {
          return {
            field: month,
            ...(isOpenPeriod && {
              ...makeEditableIf(
                ({ data }) =>
                  data?.unitType === ActivityUnitType.PERCENT_COMPLETE,
                themeMode,
              ),
            }),
            ...getPercentCompleteCellConfig(),
            ...getNullOrDecimalConfig({ emptyValueForNodeLevel: 0 }),
          };
        }
        return [
          {
            field: month,
            headerName: month,
            ...(isOpenPeriod && {
              ...makeEditableIf(
                ({ data, context }) =>
                  context.currencyViewMode === 'native' &&
                  data?.unitType === ActivityUnitType.AS_INVOICED,
                themeMode,
              ),
            }),
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig(month),
          },
        ];
      }),
    });
  }

  return columnDefs;
}

export default function useOccExpenseGridColumnDefs(
  isOpenPeriod: boolean,
  expenseData: apiJSON | undefined,
): CondorColDef[] {
  const themeMode = useTheme().palette.mode;
  return useMemo(
    () => processOccExpenseGridColumnDefs(isOpenPeriod, expenseData, themeMode),
    [expenseData, isOpenPeriod, themeMode],
  );
}
