import type { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie';

export type DonutArcProps<Datum> = Omit<
  ProvidedProps<Datum>,
  'arcs' | 'pie'
> & {
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  arc: PieArcDatum<Datum>;
};

export default function DonutArc<Datum>(props: DonutArcProps<Datum>) {
  const { arc, path, getKey, getColor } = props;
  const [centroidX, centroidY] = path.centroid(arc);
  const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
  const dPath = path({ ...arc });

  return (
    <g>
      <path d={dPath === null ? undefined : dPath} fill={getColor(arc)} />
      {hasSpaceForLabel && (
        <g>
          <text
            dy=".33em"
            fill="white"
            fontSize={9}
            pointerEvents="none"
            textAnchor="middle"
            x={centroidX}
            y={centroidY}
          >
            {getKey(arc)}
          </text>
        </g>
      )}
    </g>
  );
}
