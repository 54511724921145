import { useLocation } from 'react-router-dom';

import NavigationTabs from 'shared/ui/tabs/NavigationTabs';

import { navigateUrlUp } from 'shared/helpers/helpers';

function CroReconExpenseSubtabs() {
  const location = useLocation();
  const parentUrl = navigateUrlUp(location.pathname);

  const croReconExpenseTabs = [
    { key: `${parentUrl}/expense`, devLabel: 'BRAAVOS-new', label: 'Expenses' },
    {
      key: `${parentUrl}/recon`,
      devLabel: 'BOURNE-new',
      label: 'Recon & Adjustments',
    },
  ];

  return <NavigationTabs tabs={croReconExpenseTabs} />;
}

export default CroReconExpenseSubtabs;
