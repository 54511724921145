import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import SnackbarError from 'shared/components/snackbar-error/SnackbarError';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import Button from 'shared/ui/button/Button';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';
import Select from 'shared/ui/select/Select';

import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import type {
  ControlCodeResponse,
  LabelValueOption,
  TraceId,
} from 'shared/lib/types';
import {
  ControlCodeFrequencyTranslation,
  ControlCodeFrequencyType,
} from 'shared/lib/types';

import {
  useCreateControlCodeMutation,
  useGetControlCodesByCompanyQuery,
  useUpdateControlCodeMutation,
} from 'shared/api/rtkq/controlcodes';

type Props = {
  companyTraceId: TraceId;
  editControlTraceId?: TraceId;
  onClose: () => void;
  onSave?: (controlCode: ControlCodeResponse) => void;
};

const controlFrequencyOptions: Array<
  LabelValueOption<string, ControlCodeFrequencyType>
> = Object.entries(ControlCodeFrequencyTranslation).map((frequency) => {
  const [value, label] = frequency;
  return {
    value:
      ControlCodeFrequencyType[value as keyof typeof ControlCodeFrequencyType],
    label,
  };
});

type Fields = {
  controlCode?: string;
  controlFrequency?: ControlCodeFrequencyType | null;
  description?: string;
};

const defaultValues: Fields = {
  controlCode: '',
  controlFrequency: ControlCodeFrequencyType.MONTHLY,
  description: '',
};

function EditControlModal(props: Props) {
  const { companyTraceId, onClose, editControlTraceId, onSave } = props;
  const [apiError, setApiError] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Fields>({ ...defaultValues });
  const [createControlCode, { isLoading: createIsLoading }] =
    useCreateControlCodeMutation();
  const [updateControlCode, { isLoading: updateIsLoading }] =
    useUpdateControlCodeMutation();
  const {
    currentData: existingControlCodes,
    isLoading,
    error: getControlCodesError,
  } = useGetControlCodesByCompanyQuery(companyTraceId);

  useEffect(() => {
    if (
      !isLoading &&
      existingControlCodes?.length &&
      editControlTraceId !== undefined
    ) {
      const existingAccount = existingControlCodes.find(
        (controlCode) => controlCode.trace_id === editControlTraceId,
      );
      if (existingAccount) {
        setFormValues({
          controlCode: existingAccount.control_code,
          controlFrequency: existingAccount.control_frequency,
          description: existingAccount.description,
        });
      }
    }
  }, [isLoading, existingControlCodes, editControlTraceId]);

  useEffect(() => {
    setApiError(JSON.stringify(getControlCodesError));
  }, [getControlCodesError]);

  async function handleOnSave() {
    const formErrors = getFormErrors();
    if (!submitted && formErrors) {
      setSubmitted(true);
      return;
    }

    let newControlCode: ControlCodeResponse | undefined;
    try {
      if (editControlTraceId !== undefined) {
        newControlCode = await updateControlCode({
          trace_id: editControlTraceId,
          company: companyTraceId,
          control_code: formValues.controlCode,
          control_frequency: formValues.controlFrequency!,
          description: formValues.description,
        }).unwrap();
      } else {
        newControlCode = await createControlCode({
          company: companyTraceId,
          control_code: formValues.controlCode,
          control_frequency: formValues.controlFrequency!,
          description: formValues.description,
        }).unwrap();
      }

      resetValues();
      onClose();
    } catch (error: unknown) {
      const message =
        error instanceof Error ? error.message : 'Save Control failed';
      setApiError(message);
    }

    if (newControlCode?.trace_id) {
      onSave?.(newControlCode);
    }
  }

  function resetValues() {
    setSubmitted(false);
    setFormValues({ ...defaultValues });
  }

  function onChangeControlCode(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setFormValues((values) => ({ ...values, controlCode: value }));
  }

  function menuItem(
    option: LabelValueOption<string, ControlCodeFrequencyType>,
  ) {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  function validateControlCode(): string | undefined {
    const { controlCode } = formValues;
    if (!controlCode?.trim()) {
      return 'Please enter a valid control code name.';
    }

    const alreadyExists = existingControlCodes
      ?.filter((account) => account.trace_id !== editControlTraceId)
      .map((account) => account.control_code)
      .includes(controlCode);
    if (alreadyExists) {
      return 'Control codes must have unique names.';
    }
  }

  function validateControlFrequency(): string | undefined {
    const { controlFrequency } = formValues;
    if (controlFrequency === null) {
      return 'Account type is required';
    }
  }

  function getFormErrors(): Fields | undefined {
    const errors = [
      { field: 'controlCode', message: validateControlCode() },
      { field: 'controlFrequency', message: validateControlFrequency() },
    ].filter((error) => !!error.message);

    if (!errors.length) {
      return;
    }

    return errors.reduce((acc: Record<string, string | undefined>, error) => {
      acc[error.field] = error.message;
      return acc;
    }, {});
  }

  function onChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setFormValues((values) => ({ ...values, description: value }));
  }

  function onChangeCodeFrequency(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setFormValues((values) => ({
      ...values,
      controlFrequency: value as ControlCodeFrequencyType,
    }));
  }

  const formErrors = submitted ? getFormErrors() : undefined;

  return (
    <Dialog maxWidth="xs" fullWidth open>
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="h6">
          {editControlTraceId === undefined ? 'Add control' : 'Edit control'}
        </Typography>
      </DialogTitleWithClose>
      <DialogContent>
        <Stack gap={3} mt={1}>
          <FormControl>
            <CondorTextField
              autoComplete="off"
              errors={formErrors?.controlCode}
              label="Control code"
              placeholder="Enter code"
              value={formValues.controlCode}
              required
              onChange={onChangeControlCode}
            />
          </FormControl>
          <FormControl>
            <Select
              disabled={isLoading}
              errors={formErrors?.controlFrequency !== undefined}
              helperText={formErrors?.controlFrequency}
              label="Freequency"
              value={formValues.controlFrequency}
              required
              onChange={onChangeCodeFrequency}
            >
              {controlFrequencyOptions.map(menuItem)}
            </Select>
          </FormControl>
          <CondorTextField
            errors={formErrors?.description}
            label="Description"
            placeholder="Enter description"
            rows={4}
            type="text"
            value={formValues.description}
            fullWidth
            multiline
            onChange={onChangeDescription}
          />
          {!!apiError && <SnackbarError message={apiError} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button testId="cancel" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={!!formErrors}
          loading={createIsLoading || updateIsLoading}
          testId="save"
          variant="contained"
          onClick={() => void handleOnSave()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default EditControlModal;
