import type { SnapshotColumn, SnapshotRow, SnapshotValue } from './Validity';

class SnapshotCell {
  protected value: SnapshotValue;
  protected column: SnapshotColumn;
  protected row: SnapshotRow;

  constructor(value: SnapshotValue, column: SnapshotColumn, row: SnapshotRow) {
    this.value = value;
    this.column = column;
    this.row = row;
  }

  getColumn() {
    return this.column;
  }

  getRow() {
    return this.row;
  }

  getValue(): SnapshotValue {
    return this.value;
  }
}

export default SnapshotCell;
