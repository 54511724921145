import { useCallback } from 'react';

import { useReinitializePeriod } from 'accruals/state/slices/periodSlice';
import { useReinitializePeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { useReinitializeTrial } from 'shared/state/slices/trialSlice';

export default function useClearAccruals(): () => void {
  const reInitTrial = useReinitializeTrial();
  const reInitPeriod = useReinitializePeriod();
  const reInitPeriodVersion = useReinitializePeriodVersion();

  // have accruals "clean" itself up by clearing the "accruals-only" stuff (trial is a bit special
  // as it's used in other places too, but we will reset it immediately if needed, so it should be fine)
  return useCallback(() => {
    reInitTrial();
    reInitPeriod();
    reInitPeriodVersion();
  }, [reInitPeriod, reInitPeriodVersion, reInitTrial]);
}
