import type { ReactNode } from 'react';

import Box from '@mui/material/Box';

type Props = { children: ReactNode };

function TabularData(props: Props) {
  const { children } = props;

  return (
    <Box sx={{ display: 'flex', width: '100%', flexFlow: 'column' }}>
      {children}
    </Box>
  );
}

export default TabularData;
