import type { FlatFileMappingColumnResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'ffmapcolumns';

const apiEndpointFactory =
  constructApi<FlatFileMappingColumnResponse>(PATH).withTags('FF_MAP_COLUMNS');
const api = apiEndpointFactory.inject(() => ({
  createFlatFileMappingColumn: apiEndpointFactory.create(),
}));

export const { useCreateFlatFileMappingColumnMutation } = api;
