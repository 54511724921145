import { useMemo } from 'react';

import type { FirstDataRenderedEvent, IRowNode } from '@ag-grid-community/core';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { UserDeactivatePopup } from 'shared/lib/types';

import useEditCondorUser from './useEditCondorUser';

export default function useUserAccessGridOptions(
  _isOpenPeriod: boolean,
  setShowPopup: (showPopup: UserDeactivatePopup) => void,
): CondorGridOptions {
  const [onCellValueChanged] = useEditCondorUser(setShowPopup);

  return useMemo(
    () => ({
      suppressAggFuncInHeader: true,
      onCellValueChanged,
      onFirstDataRendered: (params: FirstDataRenderedEvent) => {
        params.api.forEachNode((node: IRowNode) => {
          if (node.key === 'Condor') {
            node.setExpanded(false);
          } else {
            node.setExpanded(true);
          }
        });
      },
    }),
    [onCellValueChanged],
  );
}
