import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FxRate from 'shared/components/fx-rate/FxRate';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { getCompanyPeriodClosed } from 'routes';
import type { CurrencyCode } from 'shared/lib/types';
import { PeriodMenuItemType } from 'shared/lib/types';

import { useGetPeriodMenuItemQuery } from 'shared/api/rtkq/periodmenuitems';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

function FxRatePage() {
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const { activeTab }: { activeTab?: CurrencyCode } = useParams();

  const { currentData: periodMenuItems } = useGetPeriodMenuItemQuery({
    saved_object_type: PeriodMenuItemType.FX_RATE_TABS,
    period_version: currentPeriodVersion.trace_id,
  });

  const parentMenuItem = useMemo(
    () =>
      periodMenuItems?.find((item) => item.live_slug === activeTab)?.trace_id,
    [periodMenuItems, activeTab],
  );

  return (
    <FxRate
      basePath={getCompanyPeriodClosed()}
      parentMenuItem={parentMenuItem}
    />
  );
}

export default withAccrualPageRequiredData(FxRatePage);
