import { useMemo } from 'react';

import CommentsWidget from 'shared/components/comments/CommentsWidget';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { CommentLocationType } from 'shared/lib/types';

import BsFluxGrid from './grids/BsFluxGrid';

function BsFluxTabContent() {
  return (
    <>
      <FlexGridContainer>
        <BsFluxGrid sx={useMemo(() => ({ height: '100%' }), [])} />
      </FlexGridContainer>
      <CommentsWidget location={CommentLocationType.BS_FLUX} commentsForPage />
    </>
  );
}

export default BsFluxTabContent;
