import { useState } from 'react';

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import FullScreenProgress from 'shared/ui/progress/FullScreenProgress';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import type { CurrencyCode } from 'shared/lib/types';
import { PeriodGridBlobType } from 'shared/lib/types';

import useFxRateColumnDefs from '../hooks/useFxRateColumnDefs';
import useFxRateGridOptions from '../hooks/useFxRateGridOptions';
import useFxRateRows from '../hooks/useFxRateRows';

type Props = {
  baseCurrency?: CurrencyCode;
  inputCurrencies: CurrencyCode[];
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function FxRateGrid(props: Props) {
  const { overlayNoRowsTemplate, sx, baseCurrency, inputCurrencies } = props;
  const [upsertFxRateInProgress, setUpsertFxRateInProgress] = useState(false);

  const rowData = useFxRateRows(baseCurrency);
  const columnDefs = useGridColDefs(useFxRateColumnDefs, [inputCurrencies]);
  const gridOptions = useGridOptions(useFxRateGridOptions, [
    setUpsertFxRateInProgress,
  ]);

  // if gridOptions are not yet defined, don't render the grid as they
  // need the company id to go into the onCellValueChanged event handler
  // and ag-grid INTENTIONALLY does not update that after render
  if (gridOptions === undefined) {
    return <div />;
  }

  return (
    <>
      <FullScreenProgress
        inProgress={upsertFxRateInProgress}
        message="Saving FX rate."
      />
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </>
  );
}

export default withPeriodSpecificGridWrapper(
  FxRateGrid,
  PeriodGridBlobType.FX_RATE,
);
