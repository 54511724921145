import type { ICellRendererParams } from '@ag-grid-community/core';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Box from '@mui/material/Box';

function AgGridGroupCellRenderer(params: ICellRendererParams) {
  const { node, value, valueFormatted } = params;

  const finalValue = (valueFormatted ?? '').length > 0 ? valueFormatted : value;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
        height: '100%',
      }}
    >
      {node.aggData === undefined && node.selectable && (
        <ArrowDropDownOutlinedIcon
          sx={{
            width: 16,
            height: 16,
            transform: node.isSelected() ? 'rotate(0deg)' : 'rotate(-90deg)',
          }}
        />
      )}
      <Box sx={{ flex: 1, ml: 1, pl: node.selectable ? 0 : 2 }}>
        {finalValue}
      </Box>
    </Box>
  );
}

export default AgGridGroupCellRenderer;
