import { useEffect, useRef, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';

import Alert from 'shared/ui/alert/Alert';

/**
 * @interface SnackbarErrorProps
 * @message {string} A message to show
 * @autoHideDuration {number} The number of milliseconds to wait before automatically hide.
 *  This behavior is enabled by default with 6000 milliseconds.
 */
type SnackbarErrorProps = {
  autoHideDuration?: number | null;
  message: string;
  onClose?: () => void;
};

function SnackbarError(props: SnackbarErrorProps) {
  const { message, autoHideDuration = 6000, onClose } = props;
  const [open, setOpen] = useState<boolean>(false);
  // The flag is needed to skip reopening the Snackbar after closing.
  const skipOneCycleState = useRef<boolean>(false);

  useEffect(() => {
    if (!open && skipOneCycleState.current) {
      skipOneCycleState.current = false;
      return;
    }

    if (message.length && !skipOneCycleState.current) {
      setOpen(true);
    }
  }, [message, open]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={autoHideDuration}
      open={open}
      sx={{ minWidth: '300px' }}
      onClose={() => {
        skipOneCycleState.current = true;
        setOpen(false);
        onClose?.();
      }}
    >
      <Alert severity="error" sx={{ width: '100%' }} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarError;
