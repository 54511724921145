import type SnapshotCell from './SnapshotCell';
import type { Validity } from './Validity';

class DateFormatCondition implements Validity {
  static getValidFormat(value: string): string | undefined {
    const regExp1 = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
    if (regExp1.test(value)) {
      return 'yyyy-MM-dd';
    }

    const regExp2 = /^\d{2}\/\d{2}\/\d{2}$/; // MM/DD/YY
    if (regExp2.test(value)) {
      return 'mm/dd/yy';
    }

    const regExp3 =
      /^\d{2}-(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{2}$/; // * DD-[Jan-Dec]-YY
    if (regExp3.test(value)) {
      return 'dd-MMM-yy';
    }
  }

  isValid(cell: SnapshotCell): boolean {
    const value = cell.getValue();
    if (typeof value !== 'string') {
      return false;
    }

    return !!DateFormatCondition.getValidFormat(value);
  }
}

export default DateFormatCondition;
