import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query/react';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import OccContractBudgetGrid from 'accruals/pages/clinical-expenses/occ/grids/OccContractBudgetGrid';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import { useGetContractCostCategoryInfoByContractVersionQuery } from 'shared/api/rtkq/contractcostcategoryinfo';

import useContractAndPeriodWithVersions from '../shared/hooks/useContractAndPeriodWithVersions';

const GRID_SX = { height: '100%', width: '100%' };

function OccContractBudgetSection() {
  const { contractVersionTraceId, contractVersionPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();
  const { currentData: contractCostCategoryInfos } =
    useGetContractCostCategoryInfoByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  return (
    <DashboardCard title="Contract Budget">
      <Stack
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="60vh"
        width="100%"
      >
        {contractVersionPeriodMenuItemTraceId ||
        contractCostCategoryInfos?.length ? (
          <FlexGridContainer>
            <OccContractBudgetGrid
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
              sx={GRID_SX}
            />
          </FlexGridContainer>
        ) : (
          <>
            <HistoryOutlinedIcon sx={{ color: 'grey.700' }} />
            <Typography sx={{ mt: 2.5, mb: 5 }} variant="body1">
              Budget is not available for this contract.
            </Typography>
          </>
        )}
      </Stack>
    </DashboardCard>
  );
}

export default OccContractBudgetSection;
