import ExternalLink from '@mui/material/Link';

import config from 'config';

type Props = { sampleFileLink: string | undefined; sampleFileName: string };

function UploadFileSection(props: Props) {
  const { sampleFileLink, sampleFileName } = props;
  return (
    <>
      {config.VITE_APP_IS_DEV_ENVIRONMENT ? (
        <ExternalLink
          href="https://drive.google.com/drive/folders/0AB1fIVCnVTmSUk9PVA"
          rel="noreferrer"
          target="_blank"
        >
          [Dev/Sandbox Only] Condor Example Data Files
        </ExternalLink>
      ) : null}

      {!!sampleFileLink && (
        <>
          <p>Not sure what this file should look like?</p>
          <ExternalLink
            fontSize="14px"
            href={sampleFileLink}
            rel="noreferrer"
            target="_blank"
          >
            View sample {sampleFileName}
          </ExternalLink>
        </>
      )}
    </>
  );
}

export default UploadFileSection;
