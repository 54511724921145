import type { ReactNode } from 'react';

// eslint-disable-next-line import/no-restricted-paths -- this is shared between company and accruals
import PeriodRecordBlobViewer from 'accruals/components/periods/PeriodRecordBlobViewer';

import type { PeriodRecordBlobType, TraceId } from 'shared/lib/types';
import { PERIOD_AWARE_RECORD_BLOB_TYPES } from 'shared/lib/types';
import UnderConstructionClosedRecord from 'shared/under-construction/UnderConstructionClosedRecord';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

type GenericRecordProps = { parentMenuItem?: TraceId };

export default function withPeriodSpecificRecordWrapper<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
  savedObjectType: PeriodRecordBlobType,
) {
  return function PeriodRecordWrapper(props: GenericRecordProps & T) {
    const isClosed = useIsClosedPeriodVersion();
    const { parentMenuItem } = props;

    return isClosed ? (
      PERIOD_AWARE_RECORD_BLOB_TYPES.includes(savedObjectType) ? (
        <PeriodRecordBlobViewer
          parentMenuItem={parentMenuItem}
          savedObjectType={savedObjectType}
        />
      ) : (
        <UnderConstructionClosedRecord />
      )
    ) : (
      <LiveDataComponent {...props} />
    );
  };
}
