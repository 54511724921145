import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import times from 'lodash/times';

import { MENU_WIDTH } from './SidebarContainer';

type Props = { collapsed: boolean; numSkeletons: number };

function SidebarSkeletonContainer({ collapsed, numSkeletons }: Props) {
  return (
    <Box
      component="aside"
      display="flex"
      flexDirection="column"
      sx={{
        borderRight: (theme) => `2px solid ${theme.palette.divider}`,
        flex: 0,
        minWidth: MENU_WIDTH,
        overflowY: 'auto',
        pl: 2,
        pr: 3,
        pt: 1,
        gap: 1.2,
      }}
    >
      {times(numSkeletons, (index) => (
        <Box key={index} display="flex" flexDirection="row" gap={2}>
          <Skeleton height={18} variant="rounded" width={18} />
          {!collapsed && (
            <Skeleton sx={{ fontSize: '1rem', flex: 1 }} variant="text" />
          )}
        </Box>
      ))}
    </Box>
  );
}

export default SidebarSkeletonContainer;
