import type { PeriodGridBlobResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'periodgridblobs';

const apiEndpointFactory =
  constructApi<PeriodGridBlobResponse>(PATH).withTags('PERIOD_GRID_BLOB');
const api = apiEndpointFactory.inject(() => ({
  upsertPeriodGridBlob: apiEndpointFactory.create<FormData>([], 'upsert'),
  getPeriodGridBlob: apiEndpointFactory.getWithFilters(),
}));

export const { useGetPeriodGridBlobQuery, useUpsertPeriodGridBlobMutation } =
  api;
