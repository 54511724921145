import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { getJSONFromFile } from 'shared/helpers/helpers';
import type { PeriodRecordBlobType, TraceId } from 'shared/lib/types';

import { useGetPeriodRecordBlobQuery } from 'shared/api/rtkq/periodrecordblobs';

// eslint-disable-next-line etc/no-misused-generics -- explicitly defining blob data structure
export default function useRecordBlobDataLoader<T>(
  savedObjectType: PeriodRecordBlobType,
  parentMenuItem?: TraceId,
): T | undefined {
  const [blobData, setBlobData] = useState<T>();
  const periodVersion = useSelector(selectPeriodVersion);
  const { currentData: recordSnapshots } = useGetPeriodRecordBlobQuery({
    saved_object_type: savedObjectType,
    period_version: periodVersion.trace_id,
    parent_menu_item: parentMenuItem,
  });

  // there can only ever be one, but our "getByFilters" helper assumes many
  const recordSnapshot = recordSnapshots?.[0];

  useEffect(() => {
    // TODO: Error Handling
    void (async () => {
      if (recordSnapshot === undefined) {
        return;
      }

      setBlobData(await getJSONFromFile<T>(recordSnapshot.blob));
    })();
  }, [recordSnapshot]);

  return blobData;
}
