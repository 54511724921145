import DonutChart from 'forecasting/components/graphing/donut-chart/DonutChart';
import TimelineChart from 'forecasting/components/graphing/timeline-chart/TimelineChart';

import { donut, timeline } from './timelineData';

function ContractParametersTimeline() {
  return (
    <div>
      <div>
        <TimelineChart
          data={timeline}
          height={400}
          width={800}
          xAccessor={(datum) => ({ start: datum.start, end: datum.end })}
          yAccessor={(datum) => datum.parameter}
        />
      </div>
      <div style={{ display: 'flex', gap: '20px' }}>
        <DonutChart data={donut} height={300} width={500} />
      </div>
    </div>
  );
}

export default ContractParametersTimeline;
