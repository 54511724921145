import type { ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';

type Props = { children: ReactNode };

export default function FlexGridContainer(props: Props): ReactElement {
  const { children } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'stretch',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  );
}
