import type { ReactElement } from 'react';

import BudgetForecastPerVendor from './demos/budget-forecast/BudgetForecastPerVendor';
import ContractParametersTimeline from './demos/contract-parameters/ContractParametersTimeline';
import CostForecastDemo from './demos/cost-forecast/CostForecastDemo';
import CostPerPatient from './demos/cost-per-patient/CostPerPatient';
import PercentContracted from './demos/percent-contracted/PercentContracted';
import RunRateVsEnrollment from './demos/run-rate-vs-enrollment/RunRateVsEnrollment';
import TimeForecastDemo from './demos/time-forecast/TimeForecastDemo';
import TrialSpendForecast from './demos/trial-spend-forecast/TrialSpendForecast';

type DemoListType = { [name: string]: ReactElement };

const DemoList: DemoListType = {
  'Contract Parameters Timeline': <ContractParametersTimeline />,
  'Percent Contracted': <PercentContracted />,
  'Budget Forecast Per Vendor': <BudgetForecastPerVendor />,
  'Cost Forecast': <CostForecastDemo />,
  'Time Forecast': <TimeForecastDemo />,
  'Run Rate vs. Enrollment': <RunRateVsEnrollment />,
  'Cost per patient': <CostPerPatient />,
  'Trial Spend Forecast': <TrialSpendForecast />,
};

const DemosByTitleKey = Object.fromEntries(
  Object.entries(DemoList).map(([title, wrapper]) => {
    const titleKey = title.replace(/\s+/g, '').replace(/\//g, '_');
    const demo = { title, wrapper };
    return [titleKey, demo];
  }),
);

export default DemosByTitleKey;
