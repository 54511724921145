import type {
  PatientAssessment,
  PatientAssessmentResponse,
  PatientCohort,
  PatientCohortResponse,
} from 'shared/lib/types';

export function mapPatientCohortFromApiResponse(
  patientCohort: PatientCohortResponse,
): PatientCohort {
  return {
    name: patientCohort.name,
    pizzaName: patientCohort.pizza_name,
    traceId: patientCohort.trace_id,
    trialId: patientCohort.trial,
    orderIndex: patientCohort.order_index,
    patientAssessments: patientCohort.patient_assessments.map(
      mapPatientVisitFromApiResponse,
    ),
  };
}

export function mapPatientVisitFromApiResponse(
  patientAssessment: PatientAssessmentResponse,
): PatientAssessment {
  return {
    name: patientAssessment.name,
    patientCohort: patientAssessment.patient_cohort,
    pizzaName: patientAssessment.pizza_name,
    traceId: patientAssessment.trace_id,
    isEnroll: patientAssessment.is_enroll,
    isScreen: patientAssessment.is_screen,
    isScreenfail: patientAssessment.is_screenfail,
    isDroppedCompleted: patientAssessment.is_dropped_completed,
    timelinePortion: patientAssessment.timeline_portion,
    orderIndex: patientAssessment.order_index,
    dayOfProtocol: patientAssessment.day_of_protocol,
  };
}
