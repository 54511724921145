import { useState } from 'react';

import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import Button from 'shared/ui/button/Button';

import classes from 'shared/lib/column-mapper/ColumnMapperTable.module.scss';

import type { ValidationError } from '../ValidationSidebar';

type Props = {
  handleAccept?: HandleAcceptForRollupRow;
  selectedErrorIndex: number;
  validationErrors: ValidationError[];
};

function RollupRowHandler(props: Props) {
  const { validationErrors, selectedErrorIndex, handleAccept } = props;
  const [isRollupRow, setIsRollupRow] = useState<boolean>(true);
  const [applyToAll, setApplyToAll] = useState<boolean>(false);
  const selectedError = validationErrors[selectedErrorIndex];

  const onClick = () => {
    void handleAccept?.(applyToAll, selectedError, isRollupRow);
  };

  const otherRollupRows = validationErrors
    .filter(
      (error) => error.row !== selectedError.row && error.type === 'rollup',
    )
    .map((error) => error.row);

  return (
    <>
      <div className={classes.validationSidebarContent}>
        <Typography sx={{ marginBottom: '15px' }} variant="body2">
          Please confirm if this is a rollup row:
        </Typography>
        <Typography sx={{ marginBottom: '15px' }} variant="body2">
          Row:<strong> {selectedError.row + 1}</strong>
        </Typography>
        <RadioGroup
          value={isRollupRow ? 'rollup' : 'individual'}
          onChange={(_, value) => {
            setIsRollupRow(value === 'rollup');
          }}
        >
          <FormControlLabel
            control={<Radio size="small" />}
            label={`Yes, Row ${selectedError.row + 1} is a rollup row.`}
            value="rollup"
          />
          <FormControlLabel
            control={<Radio size="small" />}
            label={`No, Row ${selectedError.row + 1} is an individual row.`}
            value="individual"
          />
        </RadioGroup>
        <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
        <Typography sx={{ marginBottom: '15px' }} variant="body2">
          The following rows have the same formatting. Apply changes to all?
        </Typography>
        <Typography sx={{ marginBottom: '15px' }} variant="body2">
          Row:{/*
           */}
          <strong> {otherRollupRows.map((row) => row + 1).join(', ')}</strong>
        </Typography>
        <RadioGroup
          value={applyToAll ? 'applyToAll' : 'applyToThis'}
          onChange={(_, value) => {
            setApplyToAll(value === 'applyToAll');
          }}
        >
          <FormControlLabel
            control={<Radio size="small" />}
            label={`Yes, all rows are ${isRollupRow ? 'rollups' : 'individual'}`}
            value="applyToAll"
          />
          <FormControlLabel
            control={<Radio size="small" />}
            label="No, I'll review each one individually"
            value="applyToThis"
          />
        </RadioGroup>
      </div>
      <div className={classes.validationSidebarFooter}>
        <Button
          className={classes.validationSidebarFooterButton}
          testId="accept"
          variant="contained"
          onClick={onClick}
        >
          Accept
        </Button>
      </div>
    </>
  );
}

export type HandleAcceptForRollupRow = (
  applyToAll: boolean,
  selectedError: ValidationError,
  isRollupRow: boolean,
) => Promise<void>;
export default RollupRowHandler;
