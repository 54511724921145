import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

import {
  getNumberCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColGroupDef } from 'shared/components/ag-grid/types';

export default function useCroReconLineMatchingColumnDefs(): CondorColGroupDef[] {
  const themeMode = useTheme().palette.mode;
  return useMemo(
    () => [
      {
        headerName: 'Current Contract',
        children: [
          { field: 'cur_traceId', hide: true },
          { field: 'cur_region', headerName: 'Region' },
          { field: 'cur_serviceCategory', headerName: 'Service Category' },
          { field: 'cur_activityDescription', headerName: 'Description' },
          { field: 'cur_activityCode', headerName: 'Activity code' },
          {
            field: 'cur_orderIndex',
            headerName: 'Line match',
            ...getNumberCellConfig(),
            ...makeEditableIf(({ data }) => data?.cur_traceId, themeMode),
          },
        ],
      },
      {
        headerName: 'CRO reported activity',
        children: [
          { field: 'rep_traceId', hide: true },
          { field: 'rep_region', headerName: 'Region' },
          { field: 'rep_serviceCategory', headerName: 'Service Category' },
          { field: 'rep_activityDescription', headerName: 'Description' },
          { field: 'rep_activityCode', headerName: 'Activity code' },
          {
            field: 'rep_orderIndex',
            headerName: 'Line match',
            ...getNumberCellConfig(),
            ...makeEditableIf(({ data }) => data?.rep_traceId, themeMode),
          },
        ],
      },
      {
        headerName: 'Amendment-in-progress',
        children: [
          { field: 'aip_traceId', hide: true },
          { field: 'aip_region', headerName: 'Region' },
          { field: 'aip_serviceCategory', headerName: 'Service Category' },
          { field: 'aip_activityDescription', headerName: 'Description' },
          { field: 'aip_activityCode', headerName: 'Activity code' },
          {
            field: 'aip_orderIndex',
            headerName: 'Line match',
            ...getNumberCellConfig(),
            ...makeEditableIf(({ data }) => data?.aip_traceId, themeMode),
          },
        ],
      },
    ],
    [themeMode],
  );
}
