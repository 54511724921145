import type { MouseEvent } from 'react';

import type { PositionScale } from '@visx/shape/lib/types';
import type { BaseBarStackProps } from '@visx/xychart/lib/components/series/private/BaseBarStack';
import type { CombinedStackData } from '@visx/xychart/lib/types/series';

import CondorAnimatedBars from './private/CondorAnimatedBars';
import CondorBaseBarStack from './private/CondorBaseBarStack';

export default function CondorAnimatedBarStack<
  XScale extends PositionScale,
  YScale extends PositionScale,
  Datum extends object,
>(
  props: Omit<BaseBarStackProps<XScale, YScale, Datum>, 'BarsComponent'> & {
    xScale: XScale;
    yScale: YScale;
    onMouseMoveOverBarStack?: (
      event: MouseEvent<SVGElement>,
      datum: CombinedStackData<XScale, YScale>,
      left: number,
      top: number,
      stackIndex?: number,
    ) => void;
    onMouseLeaveBarStack?: (event: MouseEvent<SVGElement>) => void;
  },
) {
  return <CondorBaseBarStack {...props} BarsComponent={CondorAnimatedBars} />;
}
