import type { SxProps } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import useBsFluxColumnDefs from 'accruals/pages/analytics/hooks/useBsFluxColumnDefs';
import useBsFluxGridOptions from 'accruals/pages/analytics/hooks/useBsFluxGridOptions';
import useBsFluxRows from 'accruals/pages/analytics/hooks/useBsFluxRows';
import { selectPeriod } from 'accruals/state/slices/periodSlice';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { getLastQuarterEquivalentPeriod } from 'shared/helpers/helpers';
import type { AccrualsGridRow, BsFluxRow } from 'shared/lib/types';
import { PeriodGridBlobType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetPeriodsByTrialQuery } from 'shared/api/rtkq/periods';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function BsFluxGrid(props: Props) {
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);

  // find the period "from 3 months ago" to compare queries
  const { currentData: periods } = useGetPeriodsByTrialQuery(trial.trace_id);
  const lastQuarterPeriod = getLastQuarterEquivalentPeriod(period, periods);

  const { sx, overlayNoRowsTemplate } = props;

  // When there is no period to compare to, this just calls accruals
  // and shows the current periods accruals numbers.
  //
  // This is why this grid is smart enough to play with either
  const columnDefs = useGridColDefs(useBsFluxColumnDefs, [
    period,
    lastQuarterPeriod,
  ]);
  const gridOptions = useGridOptions(useBsFluxGridOptions);
  const rowData = useBsFluxRows(period, lastQuarterPeriod);

  return (
    <FlexGridContainer>
      <CondorAgGrid<AccrualsGridRow | BsFluxRow>
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  BsFluxGrid,
  PeriodGridBlobType.BS_FLUX,
);
