import { memo } from 'react';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import CostForecast from 'forecasting/components/graphing/cost-forecast/CostForecast';

import { cost1, cost2, cost3, cost4, cost5 } from './data';

function CostForecastDemo() {
  return (
    <Paper elevation={1} sx={{ p: 4, width: '80%' }}>
      <Typography variant="subtitle1">Cost forecast</Typography>
      <Divider sx={{ mt: 1, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <CostForecast
              data={cost1}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Divider sx={{ mt: 1, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <CostForecast
              data={cost2}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Divider sx={{ mt: 1, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <CostForecast
              data={cost3}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Divider sx={{ mt: 1, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <CostForecast
              data={cost4}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
      <Divider sx={{ mt: 1, mb: 3 }} />
      <Box height={150}>
        <ParentSize>
          {(parent) => (
            <CostForecast
              data={cost5}
              height={parent.height}
              width={parent.width}
            />
          )}
        </ParentSize>
      </Box>
    </Paper>
  );
}

export default memo(CostForecastDemo);
