import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';
import UploadCsvReviewGrid from 'shared/wizards/csv-upload-wizards/upload-csv-review-grid/UploadCsvReviewGrid';

import { useGetEdcRecordsBySnapshotQuery } from 'shared/api/rtkq/edcrecords';

import columns from '../columns';

type EdcReviewStepProps = { snapshotId?: string; onComplete: () => void };

function EdcReviewStep(props: EdcReviewStepProps) {
  const { onComplete, snapshotId } = props;

  const { currentData: allRecords } = useGetEdcRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  );

  return (
    <WizardStep
      header="Review your EDC visits to make sure everything looks right."
      onNext={onComplete}
    >
      <UploadCsvReviewGrid
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default EdcReviewStep;
