import { useCallback } from 'react';

import { useReinitializeForecast } from 'forecasting/state/slices/forecastSlice';
import { useReinitializeTrial } from 'shared/state/slices/trialSlice';

export default function useClearForecasting(): () => void {
  const reInitTrial = useReinitializeTrial();
  const reInitForecast = useReinitializeForecast();

  // have forecasting "clean" itself up by clearing the "forecast-only" stuff (trial is a bit special
  // as it's used in other places too, but we will reset it immediately if needed, so it should be fine)
  return useCallback(() => {
    reInitTrial();
    reInitForecast();
  }, [reInitForecast, reInitTrial]);
}
