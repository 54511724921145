import { useState } from 'react';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import type {
  BottomLineAdjustment,
  BottomLineAdjustmentRequestParams,
  ContractInfo,
} from 'shared/lib/types';

import {
  useCreateBottomLineAdjustmentMutation,
  useUpdateBottomLineAdjustmentMutation,
} from 'shared/api/rtkq/bottomlineadjustments';

import type { BottomLineAdjustmentErrors } from './types';

function useUpsertBottomLineAdjustments(): [
  (contractInfo: ContractInfo) => Promise<BottomLineAdjustment[]>,
  { isLoading: boolean; errors: Record<number, BottomLineAdjustmentErrors> },
] {
  const [createBottomLineAdjustment] = useCreateBottomLineAdjustmentMutation();
  const [updateBottomLineAdjustment] = useUpdateBottomLineAdjustmentMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<
    Record<number, BottomLineAdjustmentErrors>
  >({});

  async function syncBottomLineAdjustments(
    contractInfo: ContractInfo,
  ): Promise<BottomLineAdjustment[]> {
    const newAdjustments = [...contractInfo.accountInfo.bottomLineAdjustments];
    const adjustmentErrors: Record<number, BottomLineAdjustmentErrors> = {};

    setIsLoading(true);
    setErrors({});

    const result = await Promise.all(
      contractInfo.accountInfo.bottomLineAdjustments.map(
        async (adjustment, index) => {
          const adjustmentData: BottomLineAdjustmentRequestParams = {
            contract: contractInfo.traceId!,
            adjustment_type: adjustment.adjustmentType!,
            amount: adjustment.amount ?? null,
            amount_percentage: adjustment.amountPercentage ?? null,
            amount_type: adjustment.amountType,
          };

          const newAdjustment = { ...adjustment };
          let result;
          try {
            if (adjustment.traceId) {
              result = await updateBottomLineAdjustment({
                trace_id: adjustment.traceId,
                ...adjustmentData,
              }).unwrap();
            } else {
              result =
                await createBottomLineAdjustment(adjustmentData).unwrap();
            }

            newAdjustments[index].traceId = result.trace_id;
          } catch (error: unknown) {
            adjustmentErrors[index] = (error as FetchBaseQueryError)
              .data as BottomLineAdjustmentErrors;
          }

          return newAdjustment;
        },
      ),
    );

    setErrors(adjustmentErrors);
    setIsLoading(false);
    if (Object.keys(adjustmentErrors).length) {
      throw new Error('Failed to save bottom line adjustments.');
    }

    return result;
  }

  return [syncBottomLineAdjustments, { errors, isLoading }];
}

export default useUpsertBottomLineAdjustments;
