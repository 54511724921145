import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import NotAuthorized from 'shared/components/not-authorized/NotAuthorized';
import Roller from 'shared/ui/roller/Roller';

import {
  selectPeriod,
  selectPeriodForcePeriodVersionReload,
  useForcePeriodVersionReload,
} from 'accruals/state/slices/periodSlice';
import {
  missingPeriodVersion,
  selectPeriodVersion,
  selectPeriodVersionInitialized,
  useChangePeriodVersion,
  useInitializePeriodVersion,
} from 'accruals/state/slices/periodVersionSlice';

import { useGetPeriodVersionsByPeriodQuery } from 'shared/api/rtkq/periodversions';

function withPeriodVersionRequired<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
) {
  return function PeriodVersionRequiredWrapper(props: T): ReactNode {
    const period = useSelector(selectPeriod);
    const periodVersion = useSelector(selectPeriodVersion);
    const initialized = useSelector(selectPeriodVersionInitialized);
    const forcePeriodVersionReload = useSelector(
      selectPeriodForcePeriodVersionReload,
    );
    const initPeriodVersion = useInitializePeriodVersion();
    const changePeriodVersion = useChangePeriodVersion();
    const clearForcePeriodVersionReload = useForcePeriodVersionReload();

    const {
      currentData: periodVersions,
      isUninitialized,
      isLoading,
      isFetching,
    } = useGetPeriodVersionsByPeriodQuery(period.trace_id);
    const waitForApi = isUninitialized || isLoading || isFetching;
    const numPeriodVersions = periodVersions?.length ?? 0;

    // only call initialize period version once
    useEffect(() => {
      if (waitForApi || initialized) {
        return;
      }

      initPeriodVersion(periodVersions);
    }, [periodVersions, waitForApi, initPeriodVersion, initialized]);

    // only call initialize period version once
    useEffect(() => {
      if (waitForApi || !forcePeriodVersionReload) {
        return;
      }

      if (numPeriodVersions > 0) {
        clearForcePeriodVersionReload(false);
        // we know the ?? null is impossible because of the numPeriodVersions > 0 check
        // but for whatever reason, TS does not seem to notice (even if we literally
        // check for the length of period versions in the useEffect)
        changePeriodVersion(periodVersions?.[0] ?? null);
      }
    }, [
      periodVersions,
      waitForApi,
      initPeriodVersion,
      forcePeriodVersionReload,
      changePeriodVersion,
      numPeriodVersions,
      clearForcePeriodVersionReload,
    ]);

    if (
      !waitForApi &&
      (periodVersion === missingPeriodVersion || numPeriodVersions === 0)
    ) {
      return <NotAuthorized />;
    }

    return waitForApi ? <Roller /> : <LiveDataComponent {...props} />;
  };
}

export default withPeriodVersionRequired;
