import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import EdcProceduresList from './EdcProceduresList';
import EdcProceduresTypeSelector from './EdcProceduresTypeSelector';

type Props = {
  children: ReactNode;
  isAllInOneFile: boolean;
  procedureType: string;
  setIsAllInOneFile: (isAllInOneFile: boolean) => void;
  setProcedureType: (procedureType: string) => void;
};

function EdcProceduresExtraFrame(props: Props) {
  const {
    isAllInOneFile,
    setIsAllInOneFile,
    procedureType,
    setProcedureType,
    children,
  } = props;

  return (
    <>
      <Divider orientation="horizontal" sx={{ my: 1 }} flexItem />
      <Box sx={{ flex: 1, display: 'flex', flexFlow: 'row', width: '100%' }}>
        <Box sx={{ width: '25%' }}>
          <Typography sx={{ ml: 2, mt: 2, mb: 1 }} variant="subtitle2">
            Current procedure files
          </Typography>
          <EdcProceduresList
            isAllInOneFile={isAllInOneFile}
            procedureType={procedureType}
          />
        </Box>
        <Divider orientation="vertical" sx={{ mx: 3 }} flexItem />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            position: 'relative',
            ml: 2,
            mt: 2,
            mb: 1,
            width: '60%',
          }}
        >
          <Typography variant="subtitle2">Upload new file</Typography>
          <EdcProceduresTypeSelector
            isAllInOneFile={isAllInOneFile}
            procedureType={procedureType}
            setIsAllInOneFile={setIsAllInOneFile}
            setProcedureType={setProcedureType}
          />
          <Divider flexItem />
          <Typography className="required" sx={{ my: 3 }} variant="h6">
            Procedures report
          </Typography>
          <Typography color="text.secondary" variant="body1">
            Accepted file formats: csv
          </Typography>
          {children}
        </Box>
      </Box>
    </>
  );
}

export default EdcProceduresExtraFrame;
