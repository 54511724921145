import color from 'color';

import colors from 'colors.module.scss';

import type { CostForecastConfig } from './types';

export const COST_FORECAST_CONFIG: CostForecastConfig = {
  margin: { top: 20, right: 25, bottom: 50, left: 20 },
  invoicedText: 'Invoiced',
  accruedText: 'Accrued',
  prepaidText: 'Prepaid',
  forecastedText: 'Forecasted variance',
  contractedText: 'Contracted',
  invoicedColor: colors.chart9,
  accruedColor: colors.chart10,
  contractedColor: color(colors.chart9).alpha(0.1).rgb().string(),
  contractedColorOuter: colors.chart9,
  overContractColor: colors.errorMain,
  underContractColor: colors.successMain,
  columnColor: colors.dividerLightTheme,
  textColor: colors.primaryTextLightTheme,
};
