import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

export default function useOperationalForecastSitesGridOptions(): CondorGridOptions {
  return useMemo(
    () => ({
      autoGroupColumnDef: {
        headerName: 'Sites',
        pinned: 'left',
        cellRendererParams: {
          suppressCount: true, // remove number in group header
        },
      },
      groupDefaultExpanded: 0,
      groupRemoveLowestSingleChildren: true,
      suppressAggFuncInHeader: true,
    }),
    [],
  );
}
