import type {
  GenericData,
  PurchaseOrderSnapshotResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { PURCHASE_ORDER_RECORDS_TAG } from './purchaseorderrecords';

const PATH = 'purchaseordersnapshots';

const apiEndpointFactory = constructApi<
  PurchaseOrderSnapshotResponse,
  GenericData
>(PATH).withTags('PURCHASE_ORDER_SNAPSHOTS', [PURCHASE_ORDER_RECORDS_TAG]);

export const api = apiEndpointFactory.inject(() => ({
  createPurchaseOrdersSnapshotFromCsv: apiEndpointFactory.create(
    [PURCHASE_ORDER_RECORDS_TAG],
    'new_from_csv',
  ),
  deletePurchaseOrdersSnapshot: apiEndpointFactory.delete(),
}));

export const {
  useCreatePurchaseOrdersSnapshotFromCsvMutation,
  useDeletePurchaseOrdersSnapshotMutation,
} = api;
