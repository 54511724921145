import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import Wizard from 'shared/lib/wizard/Wizard';
import {
  resetState,
  selectIsCohortsListInitialized,
  setCohorts,
} from 'shared/state/slices/visitSchedulesSlice';

import usePatientCohorts from './hooks/usePatientCohorts';
import CohortAndVisitsStep from './steps/CohortAndVisitsStep';
import MapToEdcStep from './steps/MapToEdcStep';

type Props = {
  onClose: () => void;
};

function VisitSchedulesWizard(props: Props) {
  const { onClose } = props;
  const dispatch = useDispatch();
  const isCohortsListInitialized = useSelector(selectIsCohortsListInitialized);

  const { patientCohorts } = usePatientCohorts(false);

  useEffect(() => {
    if (!isCohortsListInitialized) {
      // when create a new cohort, the cohorts are re-fetched from the server by RTK Query
      // do not override cohorts when they are already set and have changes
      dispatch(setCohorts({ cohorts: patientCohorts }));
    }
  }, [patientCohorts, isCohortsListInitialized]);

  function onCloseWizard() {
    dispatch(resetState());
    onClose();
  }

  return (
    <FullWidthDialog open>
      <Wizard
        stepNames={['Cohorts & visits', 'Map to EDC']}
        title="Visit schedules"
        onClose={onCloseWizard}
      >
        <CohortAndVisitsStep />
        <MapToEdcStep />
      </Wizard>
    </FullWidthDialog>
  );
}

export default VisitSchedulesWizard;
