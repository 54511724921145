import type { ReactElement } from 'react';

import MuiCard, { cardClasses } from '@mui/material/Card';
import type { CardProps as MuiCardProps } from '@mui/material/Card/Card';
import CardActions, { cardActionsClasses } from '@mui/material/CardActions';
import CardContent, { cardContentClasses } from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import type { SxProps, Theme } from '@mui/material/styles';

import styles from './Card.module.scss';

type Props = MuiCardProps & {
  number?: number;
  header?: string;
  subheader?: string;
  image?: string;
  className?: string;
  headerClassName?: string;
  subheaderClassName?: string;
  imageClassName?: string;
  ActionButtons?: ReactElement;
  actionButtonsPosition?: 'left' | 'right';
  actionsSx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
  horizontal?: boolean;
};

function Card(props: Props) {
  const {
    classes,
    sx,
    imageClassName,
    number,
    header,
    subheader,
    image,
    className,
    headerClassName,
    subheaderClassName,
    ActionButtons,
    actionButtonsPosition,
    actionsSx,
    contentSx,
    horizontal,
    children,
  } = props;
  return (
    <MuiCard
      className={`${styles.card} ${className}`}
      classes={classes}
      sx={{ ...sx, borderRadius: '16px' }}
      variant="outlined"
    >
      <div
        className={`${styles.cardDirection} ${styles[horizontal ? 'horizontal' : 'vertical']}`}
      >
        {!!image && (
          <CardMedia
            className={String(imageClassName)}
            component="img"
            src={image}
            sx={{
              width: horizontal ? '200px' : 'auto',
              height: horizontal ? 'auto' : '180px',
            }}
          />
        )}
        <div className={styles.cardContentJustifier}>
          <CardContent className={styles.cardContent} sx={contentSx}>
            {!!number && <div className={styles.number}>{number}.</div>}
            {!!header && (
              <div className={`${styles.header} ${headerClassName}`}>
                {header}
              </div>
            )}
            {!!subheader && (
              <p className={`${styles.subheader} ${subheaderClassName}`}>
                {subheader}
              </p>
            )}
            {children !== undefined && (
              <div className={styles.cardBody}>{children}</div>
            )}
          </CardContent>
          {ActionButtons !== undefined && (
            <CardActions
              className={`${styles.actions} ${actionButtonsPosition === 'right' ? styles.right : styles.left}`}
              sx={actionsSx}
            >
              {ActionButtons}
            </CardActions>
          )}
        </div>
      </div>
    </MuiCard>
  );
}

export { cardClasses, cardActionsClasses, cardContentClasses };

export default Card;
