import Error from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Chip from 'shared/ui/chip/Chip';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { formatWordMonthDayYear } from 'shared/helpers/helpers';

import { useGetEdcProceduresSnapshotsByPeriodQuery } from 'shared/api/rtkq/proceduresedcsnapshots';

type Props = {
  isAllInOneFile: boolean;
  procedureType: string;
};

function EdcProceduresList(props: Props) {
  const { isAllInOneFile, procedureType } = props;
  const period = useSelector(selectPeriod);
  const { currentData: procedures = [] } =
    useGetEdcProceduresSnapshotsByPeriodQuery(period.trace_id);

  return (
    <List component="nav" sx={{ py: 0, mt: 1, mx: 2 }}>
      {procedures.length !== 0 ? (
        procedures
          .map((item) => (
            <ListItem
              key={item.comment}
              sx={{
                borderRadius: 0.5,
              }}
              disablePadding
            >
              <Box sx={{ flex: 1, display: 'flex', flexFlow: 'column' }}>
                <a
                  href={item.original_upload.file}
                  rel="noreferrer"
                  target="_blank"
                  download
                >
                  {item.original_upload.file
                    .split('\\')
                    .pop()
                    ?.split('/')
                    .pop() ?? 'No file name'}
                </a>
                <Typography variant="body1">
                  Procedure type:{' '}
                  <Box component="span" fontWeight="fontWeightBold">
                    {item.procedure_category?.name ?? 'All procedures'}
                  </Box>
                </Typography>
                <Typography variant="caption">
                  Uploaded on {formatWordMonthDayYear(item.created_at)} by{' '}
                  {item.original_upload.uploaded_by?.first_name}{' '}
                  {item.original_upload.uploaded_by?.last_name}
                </Typography>
              </Box>
              {isAllInOneFile ||
              procedureType === item.procedure_category?.name ||
              item.procedure_category == null ? (
                <Chip
                  color="error"
                  icon={<Error />}
                  label="Replacing"
                  size="small"
                />
              ) : null}
            </ListItem>
          ))
          // biome-ignore lint/suspicious/noArrayIndexKey: Dividers are not unique
          .flatMap((x, i) => [<Divider key={i} flexItem />, x])
          .slice(1)
      ) : (
        <Typography variant="body1">No files uploaded.</Typography>
      )}
    </List>
  );
}

export default EdcProceduresList;
