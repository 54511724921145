import type { ChangeEvent } from 'react';
import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Button from 'shared/ui/button/Button';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

import UserInviteDialog from './UserInviteDialog';

type Props = {
  handleFilterTextboxChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  setShowActiveUsers: (setShowActiveUsers: boolean) => void;
  showActiveUsers: boolean;
};

function UserAccessHeader(props: Props) {
  const { showActiveUsers, setShowActiveUsers, handleFilterTextboxChanged } =
    props;
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const isPeriodClosed = useIsClosedPeriodVersion();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          alignItems: 'center',
          height: '50px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 1,
            alignItems: 'flex-start',
            height: '50px',
          }}
        >
          <Typography component="h1" variant="h4">
            User Access
          </Typography>
          <Typography variant="body1">
            Trial permissions apply to all trials to which the user has access.
          </Typography>
        </Box>
        {!isPeriodClosed && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <FormControlLabel
              label="Only show active users"
              control={
                <Switch
                  checked={showActiveUsers}
                  onChange={(_, checked) => setShowActiveUsers(checked)}
                />
              }
            />
            <CondorTextField
              label="Search"
              size="small"
              sx={{ minWidth: '320px' }}
              variant="outlined"
              onChange={handleFilterTextboxChanged}
            />
            <Button
              startIcon={<AddIcon />}
              testId="add-user-test"
              variant="contained"
              onClick={() => setShowAddUserDialog(true)}
            >
              Add User
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={{ height: '40px' }} /> {/* This acts as a line break*/}
      {showAddUserDialog && (
        <UserInviteDialog onClose={() => setShowAddUserDialog(false)} />
      )}
    </>
  );
}

export default UserAccessHeader;
