import colors from 'colors.module.scss';

export const colorsRange: string[] = [
  colors.chart1,
  colors.chart2,
  colors.chart3,
  colors.chart4,
  colors.chart5,
  colors.chart6,
  colors.chart7,
  colors.chart8,
  colors.chart9,
  colors.chart10,
];
