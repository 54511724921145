import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type {
  TrialActivityEnrollmentGridResponse,
  TrialActivityEnrollmentRow,
} from 'shared/lib/types';

export function processTrialActivityEnrollmentRows(
  enrollmentGrid?: TrialActivityEnrollmentGridResponse | undefined,
) {
  return enrollmentGrid
    ? [
        'enrolled_patients',
        'cumulative_enrollment',
        'patients_dropped_or_completed',
        'active_patients',
      ].map((key) =>
        cloneDeep(
          enrollmentGrid[key as keyof TrialActivityEnrollmentGridResponse],
        ),
      )
    : undefined;
}

export default function useTrialActivityEnrollmentRows(
  trialActivityEnrollment: TrialActivityEnrollmentGridResponse | undefined,
): TrialActivityEnrollmentRow[] | undefined {
  return useMemo(
    () => processTrialActivityEnrollmentRows(trialActivityEnrollment),
    [trialActivityEnrollment],
  );
}
