import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';
import { useGetContractExpenseGridQuery } from 'shared/api/rtkq/contracts';

import { expenseRowSorter } from '../../shared/helpers';
import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';

function mapCostCategory(data: apiJSON | undefined, costCategory: string) {
  if (!data?.rows) {
    return;
  }

  return data.rows.map((row: apiJSON) => ({ ...row, costCategory }));
}

function processContractExpense(
  directFeesData: apiJSON | undefined,
  passThroughData: apiJSON | undefined,
  investigatorFeesData: apiJSON | undefined,
) {
  const directFees = mapCostCategory(directFeesData, 'Direct fees');
  const passThrough = mapCostCategory(passThroughData, 'Pass-throughs');
  const investigatorFees = mapCostCategory(
    investigatorFeesData,
    'Investigator fees',
  );

  const data = [directFees, passThrough, investigatorFees];

  return {
    rows: data.filter(Array.isArray).flat() as Array<{
      displayRowNum: number;
      totalPrice: number;
    }>,
  };
}

export function contractExpenseSortedRows(
  directFeesData: apiJSON | undefined,
  passThroughData: apiJSON | undefined,
  investigatorFeesData: apiJSON | undefined,
): Array<{ totalPrice: number }> | undefined {
  return [
    ...processContractExpense(
      directFeesData,
      passThroughData,
      investigatorFeesData,
    ).rows,
  ].sort(expenseRowSorter);
}

export default function useCroContractBudgetRows() {
  const { contractVersionTraceId, periodTraceId, loadingContractInformation } =
    useContractAndPeriodWithVersions();

  const makeDataRequest =
    contractVersionTraceId !== undefined && periodTraceId !== undefined;
  const { currentData: directFeesData, isLoading: isLoadingDirectFees } =
    useGetContractExpenseGridQuery(
      makeDataRequest
        ? {
            trace_id: contractVersionTraceId,
            secondParameter: periodTraceId,
            thirdParameter: 'DIRECT_FEES',
          }
        : skipToken,
    );

  const { currentData: passThroughData, isLoading: isLoadingPassThrough } =
    useGetContractExpenseGridQuery(
      makeDataRequest
        ? {
            trace_id: contractVersionTraceId,
            secondParameter: periodTraceId,
            thirdParameter: 'PASS_THROUGH',
          }
        : skipToken,
    );

  const {
    currentData: investigatorFeesData,
    isLoading: isLoadingInvestigatorFees,
  } = useGetContractExpenseGridQuery(
    makeDataRequest
      ? {
          trace_id: contractVersionTraceId,
          secondParameter: periodTraceId,
          thirdParameter: 'INVESTIGATOR_FEES',
        }
      : skipToken,
  );

  return useMemo(() => {
    if (
      loadingContractInformation ||
      isLoadingDirectFees ||
      isLoadingPassThrough ||
      isLoadingInvestigatorFees
    ) {
      return;
    }

    return processContractExpense(
      directFeesData,
      passThroughData,
      investigatorFeesData,
    );
  }, [
    directFeesData,
    investigatorFeesData,
    isLoadingDirectFees,
    isLoadingInvestigatorFees,
    isLoadingPassThrough,
    loadingContractInformation,
    passThroughData,
  ]);
}
