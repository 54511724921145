import { useSelector } from 'react-redux';

import {
  missingPeriodVersion,
  selectPeriodVersion,
} from 'accruals/state/slices/periodVersionSlice'; // eslint-disable-line import/no-restricted-paths -- this is shared between company and accruals

export default function useIsClosedPeriodVersion() {
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  // assume if the period version is "missing" that it is not closed
  // (as company pages can still be accessed without a period version but ONLY when they are live)
  return currentPeriodVersion === missingPeriodVersion
    ? false
    : !!currentPeriodVersion.closed_at;
}
