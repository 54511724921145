import type { EmergencyAlertResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'emergencyalerts';

const apiEndpointFactory =
  constructApi<EmergencyAlertResponse>(PATH).withTags('EMERGENCY_ALERT');
const api = apiEndpointFactory.inject(() => ({
  getEmergencyAlerts: apiEndpointFactory.getAll(),
}));

export const { useGetEmergencyAlertsQuery } = api;
