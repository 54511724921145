import { useMemo } from 'react';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';

import TabularData from 'shared/components/tabular-data/TabularData';
import TabularDataCellDonut from 'shared/components/tabular-data/TabularDataCellDonut';
import TabularDataCellIcon from 'shared/components/tabular-data/TabularDataCellIcon';
import TabularDataCellText from 'shared/components/tabular-data/TabularDataCellText';
import TabularDataHeaderCell from 'shared/components/tabular-data/TabularDataHeaderCell';
import TabularDataHeaderRow from 'shared/components/tabular-data/TabularDataHeaderRow';
import TabularDataRow from 'shared/components/tabular-data/TabularDataRow';

import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';

import { getColor, getDifference, getPercent } from './utils';

function OperationalTabularData() {
  const { loading: forecastLoading, generatedForecast } = useForecast();

  const sitePercent = useMemo(
    () =>
      getPercent(
        generatedForecast?.actual_sites_count,
        generatedForecast?.sites_contracted,
      ),
    [
      generatedForecast?.actual_sites_count,
      generatedForecast?.sites_contracted,
    ],
  );
  const siteOverUnder = useMemo(
    () =>
      getDifference(
        generatedForecast?.forecasted_sites_count ?? 0,
        generatedForecast?.sites_contracted,
      ),
    [
      generatedForecast?.forecasted_sites_count,
      generatedForecast?.sites_contracted,
    ],
  );
  const siteColor = useMemo(() => getColor(siteOverUnder), [siteOverUnder]);
  const siteRowLoading = useMemo(() => forecastLoading, [forecastLoading]);

  const patientPercent = useMemo(
    () =>
      getPercent(
        generatedForecast?.actual_patients_count,
        generatedForecast?.patients_contracted,
      ),
    [
      generatedForecast?.actual_patients_count,
      generatedForecast?.patients_contracted,
    ],
  );
  const patientOverUnder = useMemo(
    () =>
      getDifference(
        generatedForecast?.forecasted_patients_count ?? 0,
        generatedForecast?.patients_contracted,
      ),
    [
      generatedForecast?.forecasted_patients_count,
      generatedForecast?.patients_contracted,
    ],
  );
  const patientColor = useMemo(
    () => getColor(patientOverUnder),
    [patientOverUnder],
  );
  const patientRowLoading = useMemo(() => forecastLoading, [forecastLoading]);

  const monthPercent = useMemo(
    () =>
      getPercent(
        generatedForecast?.actual_months.length,
        generatedForecast?.months_contracted,
      ),
    [generatedForecast?.actual_months, generatedForecast?.months_contracted],
  );
  const monthForecasted = useMemo(
    () =>
      (generatedForecast?.forecasted_months ?? 0) +
      (generatedForecast?.actual_months.length ?? 0),
    [generatedForecast?.forecasted_months, generatedForecast?.actual_months],
  );
  const monthOverUnder = useMemo(
    () => getDifference(monthForecasted, generatedForecast?.months_contracted),
    [monthForecasted, generatedForecast?.months_contracted],
  );
  const monthColor = useMemo(() => getColor(monthOverUnder), [monthOverUnder]);
  const monthRowLoading = useMemo(
    () => siteRowLoading || patientRowLoading || forecastLoading,
    [siteRowLoading, patientRowLoading, forecastLoading],
  );

  return (
    <TabularData>
      <TabularDataHeaderRow>
        <TabularDataHeaderCell sx={{ textAlign: 'left' }} title="Operational" />
        <TabularDataHeaderCell title="LTD Actual" />
        <TabularDataHeaderCell title="% of Contracted" />
        <TabularDataHeaderCell title="Contracted Total" />
        <TabularDataHeaderCell title="Forecasted Total" />
        <TabularDataHeaderCell title="Over/Under Contract" />
      </TabularDataHeaderRow>
      <TabularDataRow>
        <TabularDataCellIcon
          icon={<StoreOutlinedIcon sx={{ fontSize: 16 }} />}
          title="Sites"
        />
        <TabularDataCellText
          loading={siteRowLoading}
          value={generatedForecast?.actual_sites_count ?? 0}
        />
        <TabularDataCellDonut
          loading={siteRowLoading}
          percentage={sitePercent}
        />
        <TabularDataCellText
          loading={siteRowLoading}
          value={generatedForecast?.sites_contracted}
        />
        <TabularDataCellText
          color={siteColor}
          loading={siteRowLoading}
          value={generatedForecast?.forecasted_sites_count ?? 0}
        />
        <TabularDataCellText
          color={siteColor}
          loading={siteRowLoading}
          value={siteOverUnder > 0 ? `+${siteOverUnder}` : siteOverUnder}
        />
      </TabularDataRow>
      <TabularDataRow>
        <TabularDataCellIcon
          icon={<GroupOutlinedIcon sx={{ fontSize: 16 }} />}
          title="Patients"
        />
        <TabularDataCellText
          loading={patientRowLoading}
          value={generatedForecast?.actual_patients_count ?? 0}
        />
        <TabularDataCellDonut
          loading={patientRowLoading}
          percentage={patientPercent}
        />
        <TabularDataCellText
          loading={patientRowLoading}
          value={generatedForecast?.patients_contracted}
        />
        <TabularDataCellText
          color={patientColor}
          loading={patientRowLoading}
          value={generatedForecast?.forecasted_patients_count ?? 0}
        />
        <TabularDataCellText
          color={patientColor}
          loading={patientRowLoading}
          value={
            patientOverUnder > 0 ? `+${patientOverUnder}` : patientOverUnder
          }
        />
      </TabularDataRow>
      <TabularDataRow>
        <TabularDataCellIcon
          icon={<CalendarMonthOutlinedIcon sx={{ fontSize: 16 }} />}
          title="Months"
        />
        <TabularDataCellText
          loading={monthRowLoading}
          value={generatedForecast?.actual_months.length ?? 0}
        />
        <TabularDataCellDonut
          loading={monthRowLoading}
          percentage={monthPercent}
        />
        <TabularDataCellText
          loading={monthRowLoading}
          value={generatedForecast?.months_contracted}
        />
        <TabularDataCellText
          color={monthColor}
          loading={monthRowLoading}
          value={monthForecasted}
        />
        <TabularDataCellText
          color={monthColor}
          loading={monthRowLoading}
          value={monthOverUnder > 0 ? `+${monthOverUnder}` : monthOverUnder}
        />
      </TabularDataRow>
    </TabularData>
  );
}

export default OperationalTabularData;
