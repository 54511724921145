import { useMemo } from 'react';

import {
  getPercentCellConfig,
  getTrialMoneyCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import type { TrialExpenseSummaryGridResponse } from 'shared/lib/types';

export const processTrialExpenseSummaryColumnDefs = (
  _isOpenPeriod: boolean,
  expenseSummaryGrid?: TrialExpenseSummaryGridResponse,
) => {
  const columnDefs: Array<CondorColDef | CondorColGroupDef> = [
    {
      valueGetter:
        "node?.footer ? undefined : node?.data?.vendor_type === 'CRO' ? node.data.vendor_name : 'Other Clinical Contracts'",
      rowGroup: true,
      hide: true,
    } as const,
    {
      headerName: 'PO #',
      valueGetter: '!node.footer ? node.data?.po_numbers : undefined',
      pinned: 'left',
    } as const,
    {
      headerName: 'Vendor',
      field: 'vendor_name',
      pinned: 'left',
      onCellClicked: 'handleAnyContractVersionRedirect',
    } as const,
    {
      headerName: 'Description',
      valueGetter: 'node?.footer ? undefined : node?.data?.cost_category',
      valueFormatter: 'humanizeCostCategoryWithMixedVendors',
      pinned: 'left',
    } as const,
    {
      headerName: 'Contract value',
      field: 'default_contract_value',
      aggFunc: 'sum',
      ...getTrialMoneyCellConfig(),
      cellRendererSelector: 'currentContractValueRendererSelector',
    } as const,
    {
      headerName: '% recognized',
      field: 'percent_recognized',
      aggFunc: 'percentRecognized',
      ...getPercentCellConfig({
        useEmDash: false,
        useEmDashInGroup: false,
        useEmDashInTotal: false,
      }),
    } as const,
    {
      headerName: 'LTD reconciled',
      field: 'reconciled_expense',
      aggFunc: 'sum',
      ...getTrialMoneyCellConfig(),
    } as const,
  ];
  const monthColumns =
    expenseSummaryGrid?.month_list.map((month: string) => ({
      field: month,
      ...getTrialMoneyCellConfig(),
      aggFunc: 'sum',
    })) ?? [];
  const grouping = { headerName: 'Months', children: monthColumns };
  columnDefs.push(grouping);
  return columnDefs;
};

export default function useTrialExpenseSummaryColumnDefs(
  isOpenPeriod: boolean,
  expenseSummaryGrid?: TrialExpenseSummaryGridResponse,
): Array<CondorColDef | CondorColGroupDef> {
  return useMemo(
    () =>
      processTrialExpenseSummaryColumnDefs(isOpenPeriod, expenseSummaryGrid),
    [isOpenPeriod, expenseSummaryGrid],
  );
}
