import type { VisitCostRequest, VisitCostResponse } from 'shared/lib/types';

import { PERIOD_OCC_EXPENSES, PERIOD_OCC_RECON } from './apiTags';
import constructApi from './constructApi';
import {
  CONTRACT_EXPENSES,
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  CONTRACT_RECON,
} from './contracts';
import { SITE_COST_MATRIX } from './periods';
import { LAB_COST_MATRIX } from './trials';
const apiEndpointFactory = constructApi<VisitCostResponse>(
  'visitcosts',
).withTags('VISIT_COSTS', [
  SITE_COST_MATRIX,
  LAB_COST_MATRIX,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  CONTRACT_INVESTIGATOR_FEES,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
]);
const api = apiEndpointFactory.inject(() => ({
  deleteSafeVisitCost: apiEndpointFactory.create(
    [
      SITE_COST_MATRIX,
      LAB_COST_MATRIX,
      CONTRACT_INVESTIGATOR_FEES_RECON,
      CONTRACT_INVESTIGATOR_FEES,
      PERIOD_OCC_EXPENSES,
      PERIOD_OCC_RECON,
      CONTRACT_EXPENSES,
      CONTRACT_RECON,
    ],
    'delete_safe',
  ),
  upsertVisitCost: apiEndpointFactory.create<VisitCostRequest>(
    [
      SITE_COST_MATRIX,
      LAB_COST_MATRIX,
      CONTRACT_INVESTIGATOR_FEES_RECON,
      CONTRACT_INVESTIGATOR_FEES,
      PERIOD_OCC_EXPENSES,
      PERIOD_OCC_RECON,
      CONTRACT_EXPENSES,
      CONTRACT_RECON,
    ],
    'upsert',
  ),
}));

export const { useUpsertVisitCostMutation, useDeleteSafeVisitCostMutation } =
  api;
