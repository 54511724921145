import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ListItem, { getListItemUtilityClass } from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';

import { buttonClasses } from 'shared/ui/button/Button';
import IconButton from 'shared/ui/icon-button/IconButton';

import type { PatientCohortEditor } from 'shared/lib/types';
import {
  changeCohort,
  selectCohort,
  selectCohortError,
} from 'shared/state/slices/visitSchedulesSlice';

type Props = {
  cohort: PatientCohortEditor;
};

function CohortItem(props: Props) {
  const { cohort } = props;
  const dispatch = useDispatch();
  const currentCohort = useSelector(selectCohort);
  const cohortError = useSelector(selectCohortError(cohort.traceId!));

  return (
    <ListItem
      key={cohort.traceId}
      secondaryAction={
        <IconButton
          edge="end"
          sx={{
            [`&.${buttonClasses.disabled}`]: {
              color: (theme) => theme.palette.text.primary,
            },
          }}
          disabled
        >
          <NavigateNextIcon />
        </IconButton>
      }
      sx={{
        [`&.${getListItemUtilityClass('root')}`]: {
          cursor: 'pointer',
        },
        ...(cohort.name === currentCohort?.name && {
          [`&.${getListItemUtilityClass('root')}`]: {
            backgroundColor: (theme) => theme.palette.primary.light,
          },
        }),
        ...(cohortError && {
          [`&.${getListItemUtilityClass('root')}`]: {
            backgroundColor: (theme) => theme.palette.error.light,
          },
        }),
      }}
      divider
      onClick={() => dispatch(changeCohort({ cohort }))}
    >
      <ListItemText primary={cohort.name} />
    </ListItem>
  );
}

export default CohortItem;
