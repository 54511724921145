import type SnapshotCell from './SnapshotCell';
import type { Validity } from './Validity';

class ValueCondition implements Validity {
  // maybe we don't need it, just validate type?
  // Potential condition is min/max in number, string length
  isValid(cell: SnapshotCell): boolean {
    const value = cell.getValue();

    return Boolean(value);
  }
}

export default ValueCondition;
