import type { ICellRendererParams } from '@ag-grid-community/core';
import Box from '@mui/material/Box';

function AgGridFileHyperlinkCellRenderer(params: ICellRendererParams) {
  const { data, value } = params;

  // target and rel are for older browsers who don't honor `download`
  return (
    <Box
      component="a"
      href={data?.file_url}
      rel="noreferrer"
      sx={{ ml: 1 }}
      target="_blank"
      download
    >
      {value}
    </Box>
  );
}

export default AgGridFileHyperlinkCellRenderer;
