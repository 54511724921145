import { useMemo } from 'react';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import PatientActivityHeader from './components/PatientActivityHeader';
import PatientActivityGrid from './grids/PatientActivityGrid';

function PatientActivityPage() {
  return (
    <FlexGridContainer>
      <PatientActivityHeader />
      <PatientActivityGrid
        overlayNoRowsTemplate="No sites added."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </FlexGridContainer>
  );
}

export default PatientActivityPage;
