import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type {
  PatientActivityGridResponse,
  PatientActivityRow,
} from 'shared/lib/types';

import { useGetPatientActivityQuery } from 'shared/api/rtkq/periods';

export function processPatientActivityRows(
  patientActivityGrid: PatientActivityGridResponse | undefined,
) {
  return cloneDeep(patientActivityGrid?.rows);
}

export default function usePatientActivityRows():
  | PatientActivityRow[]
  | undefined {
  const period = useSelector(selectPeriod);
  const { currentData: patientActivity } = useGetPatientActivityQuery(
    period.trace_id,
  );

  return useMemo(
    () => processPatientActivityRows(patientActivity),
    [patientActivity],
  );
}
