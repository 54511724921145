import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function EmptyParameterRow() {
  return (
    <Box
      sx={{
        display: 'flex',
        height: 34,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography
        sx={{ flexGrow: 0, flexShrink: 0, width: '60%', pl: 1, py: 0.75 }}
        variant="caption"
      >
        {'  '}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          flexGrow: 1,
          pl: 1,
          py: 0.75,
          borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        {'  '}
      </Typography>
    </Box>
  );
}

export default EmptyParameterRow;
