import type { MouseEventHandler, ReactNode } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

const CLICKABLE_ITEM_HEIGHT = 48;

type Props = {
  collapsed: boolean;
  collapsedContent?: ReactNode | string;
  content: ReactNode | string;
  isActive?: boolean;
  sx?: SxProps;
  testId?: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};

function SidebarClickableItem({
  collapsed,
  content,
  collapsedContent,
  onClick,
  isActive,
  sx,
  testId,
}: Props) {
  return (
    <Box
      data-testid={testId}
      sx={{
        height: CLICKABLE_ITEM_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexFlow: 'row',
        paddingX: 1,
        cursor: 'pointer',
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        '&:hover': { backgroundColor: ({ palette }) => palette.primary.light },
        ...sx,
      }}
      onClick={onClick}
    >
      <>
        <Box sx={{ width: '100%' }}>
          {collapsed ? (
            collapsedContent
          ) : (
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{
                textTransform: 'uppercase',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                letterSpacing: 1.25,
              }}
            >
              {content}
            </Typography>
          )}
        </Box>
        <ArrowDropDownIcon
          sx={{
            minWidth: 24,
            maxWidth: 24,
            transition: (theme) => theme.transitions.create('transform'),
            transform: collapsed ? 'rotate(-90deg)' : 'rotate(0deg)',
            ...(isActive && {
              transform: collapsed ? 'rotate(-270deg)' : 'rotate(180deg)',
            }),
          }}
        />
      </>
    </Box>
  );
}

export default SidebarClickableItem;
