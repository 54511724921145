import type { ProceduresEdcRecordResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'proceduresedcrecords';
export const PROCEDURES_EDC_RECORDS_TAG = 'PROCEDURES_EDC_RECORDS';

const apiEndpointFactory = constructApi<ProceduresEdcRecordResponse>(
  PATH,
).withTags(PROCEDURES_EDC_RECORDS_TAG);
const api = apiEndpointFactory.inject(() => ({
  createProceduresEdcRecord: apiEndpointFactory.create(),
  updateProceduresEdcRecord: apiEndpointFactory.update(),
  getProceduresEdcRecordsByTrial: apiEndpointFactory.getBy('trial'),
  getProceduresEdcRecordsBySnapshot: apiEndpointFactory.getBy('snapshot'),
  deleteProceduresEdcRecord: apiEndpointFactory.delete(),
}));

export const { useGetProceduresEdcRecordsBySnapshotQuery } = api;
