import type { SelectionChangedEvent } from '@ag-grid-community/core';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import useExpectedVisitGridOptions from '../hooks/useExpectedVisitGridOptions';
import useExpectedVisitGridRows from '../hooks/useExpectedVisitGridRows';
import useExpectedVisitsGridColumnDefs from '../hooks/useExpectedVisitsGridColumnDefs';

type Props = {
  handleSelectionChange?: (event: SelectionChangedEvent) => void;
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function ExpectedVisitsGrid(props: Props) {
  const { sx, overlayNoRowsTemplate, handleSelectionChange } = props;
  const columnDefs = useGridColDefs(useExpectedVisitsGridColumnDefs);

  const rowData = useExpectedVisitGridRows();
  const expectedVisitCount = rowData?.length ?? 0;
  const gridOptions = useGridOptions(useExpectedVisitGridOptions, [
    handleSelectionChange,
  ]);

  return (
    <>
      <Typography variant="body1">
        There {expectedVisitCount !== 1 ? 'were' : 'was'}{' '}
        <strong>{expectedVisitCount}</strong>{' '}
        {expectedVisitCount !== 1 ? 'visits' : 'visit'} that{' '}
        {expectedVisitCount !== 1 ? 'were' : 'was'} expected to happen that did
        not appear in the EDC report. Please select which visits you want to
        include in the investigator fee expense calculations.
      </Typography>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </>
  );
}

export default withPeriodSpecificGridWrapper(
  ExpectedVisitsGrid,
  PeriodGridBlobType.EXPECTED_VISITS_GRID,
);
