import type { ReactElement } from 'react';

import AgGridCellEditorsDemo from './demos/agGrid/AgGridCellEditorsDemo';
import AgGridCellValidationDemo from './demos/agGrid/AgGridCellValidationDemo';
import AgGridColumnGroupsDemo from './demos/agGrid/AgGridColumnGroupsDemo';
import AlertDemo from './demos/alert/AlertDemo';
import CheckboxDemo from './demos/checkbox/CheckboxDemo';
import ChipDemo from './demos/chip/ChipDemo';
import ColorsDemo from './demos/colors/ColorsDemo';
import CsvViewerDemo from './demos/csv-viewer/CsvViewerDemo';
import DashboardCardDemo from './demos/dashboard-card/DashboardCardDemo';
import DatePickerDemo from './demos/date-picker/DatePickerDemo';
import EdcVisitsSnapshotValidatorDemo from './demos/edc-validation/EdcVisitsSnapshotValidatorDemo';
import EdcVisitsValidationDemo from './demos/edc-validation/EdcVisitsValidationDemo';
import FileUploaderDemo from './demos/file-uploader/FileUploaderDemo';
import IconButtonDemo from './demos/icon-button/IconButtonDemo';
import SelectDemo from './demos/select/SelectDemo';
import TabsDemo from './demos/tabs/TabsDemo';
import TextFieldDemo from './demos/text-field/TextFieldDemo';

type ComponentListType = { [name: string]: ReactElement };

const ComponentList: ComponentListType = {
  'AgGrid cell editors': <AgGridCellEditorsDemo />,
  'AgGrid cell validation': <AgGridCellValidationDemo />,
  'AgGrid column groups': <AgGridColumnGroupsDemo />,
  Alert: <AlertDemo />,
  Checkbox: <CheckboxDemo />,
  Chip: <ChipDemo />,
  Colors: <ColorsDemo />,
  'CSV Viewer': <CsvViewerDemo />,
  'Dashboard Card': <DashboardCardDemo />,
  DatePicker: <DatePickerDemo />,
  'Edc Visits Snapshot Validator': <EdcVisitsSnapshotValidatorDemo />,
  'Edc Visits Validation': <EdcVisitsValidationDemo />,
  'File Uploader': <FileUploaderDemo />,
  'Icon button': <IconButtonDemo />,
  Select: <SelectDemo />,
  Tabs: <TabsDemo />,
  'Text Field': <TextFieldDemo />,
};

const ComponentsByTitleKey = Object.fromEntries(
  Object.entries(ComponentList).map(([title, componentDemo]) => {
    const titleKey = title
      .replace(/\s+/g, '')
      .replace(/\//g, '_')
      .toLocaleLowerCase();
    const component = { title, demo: componentDemo };
    return [titleKey, component];
  }),
);

export default ComponentsByTitleKey;
