import type { ReactElement } from 'react';

import useRecordBlobDataLoader from 'accruals/components/periods/hooks/useRecordBlobDataLoader';

import type { ContractAssumptions } from 'accruals/pages/clinical-expenses/shared/AssumptionGroupSectionsViewer';
import AssumptionGroupSectionsViewer from 'accruals/pages/clinical-expenses/shared/AssumptionGroupSectionsViewer';
import type { PeriodRecordBlobType, TraceId } from 'shared/lib/types';

type Props = {
  parentMenuItem?: TraceId;
  savedObjectType: PeriodRecordBlobType;
};

type ContractAssumptionsBlobType = ContractAssumptions & {
  /** @deprecated use assumptionGroups instead. this is here only to support older closed periods */
  parameterGroups?: ContractAssumptions['assumptionGroups'];
};

export default function ContractAssumptionsBlobViewer(
  props: Props,
): ReactElement {
  const { savedObjectType, parentMenuItem } = props;
  const blobData = useRecordBlobDataLoader<ContractAssumptionsBlobType>(
    savedObjectType,
    parentMenuItem,
  );

  return (
    <AssumptionGroupSectionsViewer
      assumptionGroups={blobData?.assumptionGroups ?? blobData?.parameterGroups}
      regions={blobData?.regions}
      versionName={blobData?.versionName}
    />
  );
}
