import type {
  ContractBudgetRecordRequest,
  ContractBudgetRecordResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'vendorrepactivityrecords';

// TODO: It feels like this shouldn't be returning `ContractBudgetRecordResponse`, but that's what was in the original `useApi` code.
const apiEndpointFactory = constructApi<
  ContractBudgetRecordResponse,
  ContractBudgetRecordRequest
>(PATH).withTags('VENDOR_REP_ACTIVITY_RECORDS');
const api = apiEndpointFactory.inject(() => ({
  createVendorRepActivityRecord: apiEndpointFactory.create(),
  updateVendorRepActivityRecord: apiEndpointFactory.update(),
  getVendorRepActivityRecordsBySnapshot: apiEndpointFactory.getBy(
    'snapshot',
    'snapshot_row',
  ),
  deleteVendorRepActivityRecord: apiEndpointFactory.delete(),
}));

export const {
  useGetVendorRepActivityRecordsBySnapshotQuery,
  useUpdateVendorRepActivityRecordMutation,
} = api;
