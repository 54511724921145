import type { CommentRequest, CommentResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const apiEndpointFactory = constructApi<CommentResponse, CommentRequest>(
  'comments',
).withTags('COMMENTS');
const api = apiEndpointFactory.inject(() => ({
  getCommentsWithFilters: apiEndpointFactory.getWithFilters(),
  createComment: apiEndpointFactory.create<FormData>(['COMMENTS']),
}));

export const { useGetCommentsWithFiltersQuery, useCreateCommentMutation } = api;
