import { useCallback } from 'react';

import type { CellValueChangedEvent, IRowNode } from '@ag-grid-community/core';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';

import { tryGetNumericStringValue } from 'formatters';
import { capitalize } from 'shared/helpers/helpers';
import {
  ActivityUnitDetail,
  ActivityUnitType,
  backendValueForUnitDetail,
  backendValueForUnitType,
  detailChoicesForUnitType,
  unitDetailFromBackendValue,
  unitTypeFromBackendValue,
} from 'shared/lib/driverUnitTypes';
import type {
  ActivityDriverKeyValuesRequestParams,
  TraceId,
  VendorType,
} from 'shared/lib/types';

import {
  useDeleteActivityDriverMutation,
  useUpsertActivityDriverMutation,
} from 'shared/api/rtkq/activitydrivers';
import type { apiJSON } from 'shared/api/rtkq/apiJSON';
import { useUpdateContractBudgetRecordMutation } from 'shared/api/rtkq/contractbudgetrecords';

type Props = { columnPrefix?: string; vendorType: VendorType };

const isRequestBodyValid = (requestBody: apiJSON, vendorType: VendorType) => {
  const unitType = requestBody.unit_type;
  const unitDetail = requestBody.unit_detail;

  if (unitType !== null && unitDetail !== null) {
    const relevantList = detailChoicesForUnitType(
      unitTypeFromBackendValue(unitType),
      vendorType,
    );
    return relevantList.includes(unitDetailFromBackendValue(unitDetail));
  }

  return false;
};

const tryGetDateValue = (value: string | null | undefined) => {
  // users can copy and paste any value they want, so ignore "bad" values
  try {
    return format(
      parse(value ?? 'NOT_A_DATE', 'yyyy-MM-dd', new Date()),
      'yyyy-MM-dd',
    );
  } catch {
    return undefined;
  }
};

export default function useEditActivityDriver(
  props: Props,
): [(params: CellValueChangedEvent) => void, boolean] {
  const { columnPrefix = '', vendorType } = props;
  const [upsertActivityDriver, { isLoading: upsertActivityDriverLoading }] =
    useUpsertActivityDriverMutation();
  const [deleteActivityDriver, { isLoading: deleteActivityDriverLoading }] =
    useDeleteActivityDriverMutation();
  const [
    updateContractBudgetRecord,
    { isLoading: updateContractBudgetRecordLoading },
  ] = useUpdateContractBudgetRecordMutation();

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      async function doActivityDriverChange(
        activityDriverTraceId: TraceId | undefined,
        contractBudgetRecordTraceId: TraceId | undefined,
        rowNode: IRowNode,
        activityDriverData: apiJSON,
        keyValues?: ActivityDriverKeyValuesRequestParams,
      ) {
        if (activityDriverTraceId) {
          await upsertActivityDriver({
            trace_id: activityDriverTraceId,
            ...activityDriverData,
            key_values: keyValues,
          });
        } else {
          const { trace_id } = await upsertActivityDriver({
            ...activityDriverData,
            key_values: keyValues,
          }).unwrap();

          await updateContractBudgetRecord({
            trace_id: contractBudgetRecordTraceId,
            activity_driver: trace_id,
          });
          rowNode.setDataValue(
            getPrefixedColumnName('activityDriverTraceId'),
            trace_id,
          );
        }
      }

      async function clearActivityDriver() {
        const traceId =
          event.data[getPrefixedColumnName('activityDriverTraceId')];
        if (typeof traceId === 'undefined') {
          return;
        }

        await deleteActivityDriver(traceId);
        event.node.setDataValue(
          getPrefixedColumnName('activityDriverTraceId'),
          undefined,
        );
      }

      async function clearAssignedRegion() {
        // user can delete assigned region from keyboard
        await updateContractBudgetRecord({
          trace_id: event.data[getPrefixedColumnName('traceId')],
          assigned_region: null,
          assigned_region_group: null,
        });
      }

      async function doContractBudgetRecordChange(
        contractBudgetRecordTraceId: TraceId | undefined,
        assignedRegionId: TraceId,
      ) {
        let assignedRegion = null;
        let assignedRegionGroup = null;

        const idPrefix = assignedRegionId.split('_')[0];
        if (idPrefix === 'rg') {
          assignedRegionGroup = assignedRegionId;
        } else if (idPrefix === 'r') {
          assignedRegion = assignedRegionId;
        }

        await updateContractBudgetRecord({
          trace_id: contractBudgetRecordTraceId,
          assigned_region: assignedRegion,
          assigned_region_group: assignedRegionGroup,
        });
      }

      function getPrefixedColumnName(colName: string) {
        return columnPrefix ? `${columnPrefix}${capitalize(colName)}` : colName;
      }

      function getDates(colId: string) {
        if (
          (colId === getPrefixedColumnName('startDate') ||
            colId === getPrefixedColumnName('endDate')) &&
          event.data[getPrefixedColumnName('unitDetail')] ===
            ActivityUnitDetail.CUSTOM
        ) {
          return {
            start_date: tryGetDateValue(
              event.data[getPrefixedColumnName('startDate')],
            ),
            end_date: tryGetDateValue(
              event.data[getPrefixedColumnName('endDate')],
            ),
          };
        }

        return {};
      }

      function getKeyValues(
        colId: string,
      ): ActivityDriverKeyValuesRequestParams | undefined {
        if (
          colId === getPrefixedColumnName('percentRecognized') &&
          event.data[getPrefixedColumnName('unitType')] ===
            ActivityUnitType.PERCENT_COMPLETE
        ) {
          const percentRecognized = tryGetNumericStringValue(
            event.data[getPrefixedColumnName('percentRecognized')],
          );
          return percentRecognized === null ? {} : { percentRecognized };
        }

        if (
          colId === getPrefixedColumnName('ltdExpensed') &&
          event.data[getPrefixedColumnName('unitType')] ===
            ActivityUnitType.AS_INVOICED
        ) {
          const ltdExpensed = tryGetNumericStringValue(
            event.data[getPrefixedColumnName('ltdExpensed')],
          );
          return ltdExpensed === null ? {} : { ltdExpensed };
        }

        if (
          AS_INVOICED_LIST.includes(colId) &&
          event.data[getPrefixedColumnName('unitType')] ===
            ActivityUnitType.AS_INVOICED
        ) {
          return AS_INVOICED_LIST.reduce<ActivityDriverKeyValuesRequestParams>(
            (obj, monthExpenseHeader) => {
              const monthlyUnitPriceData =
                event.data[getPrefixedColumnName(monthExpenseHeader)];
              const monthValue = tryGetNumericStringValue(
                monthlyUnitPriceData?.native ?? monthlyUnitPriceData,
              );
              if (monthValue !== null) {
                obj[monthExpenseHeader] = monthValue;
              }
              return obj;
            },
            {},
          );
        }

        if (
          PERCENT_COMPLETE_LIST.includes(colId) &&
          event.data[getPrefixedColumnName('unitType')] ===
            ActivityUnitType.PERCENT_COMPLETE
        ) {
          return PERCENT_COMPLETE_LIST.reduce<ActivityDriverKeyValuesRequestParams>(
            (obj, monthUnitHeader) => {
              const monthValue = tryGetNumericStringValue(
                event.data[getPrefixedColumnName(monthUnitHeader)],
              );
              if (monthValue !== null) {
                obj[monthUnitHeader] = monthValue;
              }
              return obj;
            },
            {},
          );
        }
      }

      const AS_INVOICED_LIST =
        event.data !== null && event.data !== undefined
          ? Object.keys(event.data)
              .filter((colName: string) => colName.endsWith('_units'))
              .map((colName: string) => colName.split('_units')[0])
          : [];

      const PERCENT_COMPLETE_LIST =
        event.data !== null && event.data !== undefined
          ? Object.keys(event.data).filter((colName: string) =>
              colName.endsWith('_units'),
            )
          : [];

      const EDITABLE_COL_IDS = [
        getPrefixedColumnName('unitType'),
        getPrefixedColumnName('unitDetail'),
        getPrefixedColumnName('startDate'),
        getPrefixedColumnName('endDate'),
        getPrefixedColumnName('assignedRegion'),
        getPrefixedColumnName('ltdExpensed'), // for recon grid
        getPrefixedColumnName('percentRecognized'),
        ...AS_INVOICED_LIST, // for expense grid
        ...PERCENT_COMPLETE_LIST, // for expense grid
      ];

      const colId = event.colDef.field;
      if (!colId || !EDITABLE_COL_IDS.includes(colId)) {
        return;
      }

      if (event.source === 'cellClear') {
        if (colId === getPrefixedColumnName('unitType')) {
          void clearActivityDriver();
          return;
        }

        if (colId === getPrefixedColumnName('assignedRegion')) {
          void clearAssignedRegion();
          return;
        }
      }

      if (colId === getPrefixedColumnName('assignedRegion')) {
        void doContractBudgetRecordChange(
          event.data[getPrefixedColumnName('traceId')],
          event.data[getPrefixedColumnName('assignedRegion')],
        );
        return;
      }

      const activityDriverRequestBody = {
        unit_type: backendValueForUnitType(
          event.data[getPrefixedColumnName('unitType')],
        ),
        unit_detail: backendValueForUnitDetail(
          event.data[getPrefixedColumnName('unitDetail')],
        ),
        ...getDates(colId),
      };

      if (isRequestBodyValid(activityDriverRequestBody, vendorType)) {
        void doActivityDriverChange(
          event.data[getPrefixedColumnName('activityDriverTraceId')],
          event.data[getPrefixedColumnName('traceId')],
          event.node,
          activityDriverRequestBody,
          getKeyValues(colId),
        );
      }
    },
    [
      vendorType,
      upsertActivityDriver,
      updateContractBudgetRecord,
      deleteActivityDriver,
      columnPrefix,
    ],
  );

  return [
    onCellValueChanged,
    upsertActivityDriverLoading ||
      updateContractBudgetRecordLoading ||
      deleteActivityDriverLoading,
  ];
}
