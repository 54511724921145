import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';

import { useGetPurchaseOrderRecordsBySnapshotQuery } from 'shared/api/rtkq/purchaseorderrecords';

import columns from '../columns';

type Props = { snapshotId?: string };

function PurchaseOrdersConfirmationStep(props: Props) {
  const { snapshotId } = props;
  const [selectedErrorIndex, setSelectedErrorIndex] = useState<number>(0);
  const { currentData: allRecords } = useGetPurchaseOrderRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  );

  return (
    <WizardStep header="No issues detected. Click on Save & Continue to proceed">
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
        selectedErrorIndex={selectedErrorIndex}
        setSelectedErrorIndex={setSelectedErrorIndex}
        showValidationSidebar
      />
    </WizardStep>
  );
}

export default PurchaseOrdersConfirmationStep;
