import { useState } from 'react';

import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers/models';
import { format } from 'date-fns/format';
import { lastDayOfMonth } from 'date-fns/lastDayOfMonth';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import { useChangePeriod } from 'accruals/state/slices/periodSlice';
import withReadOnlyPermissionWrapper from 'shared/lib/read-only-permission/withReadOnlyPermissionWrapper';
import type { PeriodResponse } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useCreatePeriodMutation } from 'shared/api/rtkq/periods';

type Props = { periods: PeriodResponse[] | undefined };

function CreatePeriodButton(props: Props) {
  const { periods } = props;
  const trial = useSelector(selectTrial);
  const [showCreatePeriodDialog, setShowCreatePeriodDialog] = useState(false);
  const [errors, setErrors] = useState<string | null>(null);
  const [date, setDate] = useState<Date | null>();
  const [createPeriod, { isLoading }] = useCreatePeriodMutation();
  const changePeriod = useChangePeriod();

  const handleClose = () => setShowCreatePeriodDialog(false);

  const onChange = (
    newValue: Date | null,
    context: PickerChangeHandlerContext<DateValidationError>,
  ) => {
    if (context.validationError || newValue === null) {
      return;
    }

    const periodDate = lastDayOfMonth(newValue);
    const periodDateString = format(periodDate, 'yyyy-MM-dd');

    if (periods?.find((period) => period.end_date === periodDateString)) {
      setErrors('This period already exists');
    } else {
      setErrors('');
      setDate(periodDate);
    }
  };

  const handleCreatePeriod = () => {
    // TODO: Error Handling
    void (async () => {
      const period = await createPeriod({
        trial: trial.trace_id,
        end_date: format(date ?? Date.now(), 'yyyy-MM-dd'), // should be impossible by the time it gets here
      }).unwrap();

      changePeriod(period);
      handleClose();
    })();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        my: 1,
      }}
    >
      <Button
        startIcon={<Add />}
        testId="new_period"
        disableElevation
        onClick={() => setShowCreatePeriodDialog(true)}
      >
        New Period
      </Button>
      <Dialog open={showCreatePeriodDialog}>
        <DialogTitle>Open new period</DialogTitle>
        <DialogContent>
          <DialogContentText
            color="text.primary"
            sx={{ mb: 1 }}
            variant="body1"
          >
            Select which period to open.
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Month / Year"
              openTo="month"
              slotProps={{ textField: { error: !!errors, helperText: errors } }}
              views={['month', 'year']}
              onChange={onChange}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button testId="cancel" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!!errors}
            loading={isLoading}
            testId="open"
            variant="contained"
            onClick={handleCreatePeriod}
          >
            Open
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default withReadOnlyPermissionWrapper(CreatePeriodButton);
