import type { ForwardedRef } from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import type { CondorTextFieldProps } from 'shared/components/text-field/CondorTextField';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import { getButtonUtilityClass } from 'shared/ui/button/Button';
import IconButton from 'shared/ui/icon-button/IconButton';
import LoadingButton, {
  loadingButtonClasses,
} from 'shared/ui/loading-button/LoadingButton';

// eslint-disable-next-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import { missingPeriod, selectPeriod } from 'accruals/state/slices/periodSlice';
import {
  missingPeriodVersion,
  selectPeriodVersion,
} from 'accruals/state/slices/periodVersionSlice'; // eslint-disable-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import type { CommentLocationType } from 'shared/lib/types';
import {
  selectDraftInLocation,
  updateDraftText,
} from 'shared/state/slices/commentSlice';

import NewAttachmentsList from './NewAttachmentsList';

type Props = {
  attachments: File[] | undefined;
  location: CommentLocationType;
  showLoadingButton: boolean;
  onAddAttachments: (attachments: File[]) => void;
  onAddComment: (text: string) => void;
  onDeleteAttachment: (attachments: File) => void;
};

function AddComment(props: Props, ref: ForwardedRef<AddCommentRef>) {
  const {
    attachments,
    location,
    onAddComment,
    onAddAttachments,
    onDeleteAttachment,
    showLoadingButton,
  } = props;
  const dispatch = useDispatch();
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const period = useSelector(selectPeriod);
  const draft = useSelector(selectDraftInLocation(location));
  const [text, setText] = useState<CondorTextFieldProps['value']>(
    () => draft?.text,
  );

  useImperativeHandle(ref, () => ({
    resetComment() {
      setText('');
    },
  }));

  function onUpdateDraft(draftText: string) {
    dispatch(updateDraftText({ text: draftText, location }));
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setText(value);
    onUpdateDraft(value);
  }

  function onChangeFileInput(event: React.ChangeEvent<HTMLInputElement>) {
    const files = [...(event.target.files ?? [])];
    onAddAttachments(files);
  }

  const commentIsEmpty = typeof text !== 'string' || text.trim().length === 0;
  const periodMismatch =
    period !== missingPeriod &&
    currentPeriodVersion !== missingPeriodVersion &&
    period.latest_version !== currentPeriodVersion.trace_id;
  const disabled = commentIsEmpty || periodMismatch;

  return (
    <Box
      sx={{
        p: 3,
        bgcolor: 'grey.100',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
      }}
    >
      <Box alignItems="flex-start" display="flex" flexDirection="row">
        <CondorTextField
          maxRows={5}
          minRows={1}
          placeholder="Add comment"
          size="small"
          value={text}
          endAdornment={
            <IconButton component="label" size="small">
              <AttachFileIcon />
              <input type="file" hidden multiple onChange={onChangeFileInput} />
            </IconButton>
          }
          fullWidth
          multiline
          onChange={onChange}
        />
        <LoadingButton
          disabled={disabled}
          loading={showLoadingButton}
          testId="send"
          variant="contained"
          sx={{
            ml: 1,
            bgcolor: 'primary.main',
            color: 'common.white',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            [`&.${getButtonUtilityClass('disabled')}`]: {
              bgcolor: 'primary.main',
              opacity: 0.4,
            },
            [`&.${loadingButtonClasses.root}`]: { minWidth: 'initial' },
            [`&.${loadingButtonClasses.loading}`]: {
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.4),
              opacity: 1,
            },
            [`& .${loadingButtonClasses.loadingIndicator}`]: {
              color: 'primary.main',
            },
          }}
          onClick={() => onAddComment(text as string)}
        >
          <SendIcon />
        </LoadingButton>
      </Box>
      <Box mt={2}>
        <NewAttachmentsList
          attachments={attachments}
          onDeleteAttachment={onDeleteAttachment}
        />
      </Box>
    </Box>
  );
}

export type AddCommentRef = { resetComment: () => void };

export default forwardRef(AddComment);
