import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import OccContractVersionDetail from './OccContractVersionDetail';

function OccDetailTab() {
  return (
    <PageContentLoadingContainer>
      <OccContractVersionDetail />
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(OccDetailTab);
