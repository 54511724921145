import { useMemo } from 'react';

import type {
  CellClassParams,
  CellClickedEvent,
} from '@ag-grid-community/core';
import cloneDeep from 'lodash/cloneDeep';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { MappedColumn } from 'shared/lib/column-mapper/types';
import type { SnapshotRecordType } from 'shared/lib/types';

import type { ValidationError } from './ValidationSidebar';

type Props = {
  fullWidth?: boolean;
  headers: MappedColumn[];
  rows?: SnapshotRecordType[];
  validationErrors?: ValidationError[];
  onClickErrorRow?: (rowIndex: number) => void;
};

const DEFAULT_COL_DEF: CondorColDef = {
  sortable: true,
  resizable: true,
  filter: false,
  minWidth: 80,
};

function AgGridValidationTable(props: Props) {
  const {
    headers,
    rows,
    onClickErrorRow,
    validationErrors: initialValidationErrors,
    fullWidth,
  } = props;

  const getErrorForCell = (params: CellClassParams): boolean => {
    const {
      rowIndex,
      colDef: { field },
      context: {
        propsProxy: { validationErrors },
      },
    } = params;

    if (!field) {
      return false;
    }

    return !!(validationErrors as Props['validationErrors'])?.find(
      (error) => error.row === rowIndex && error.fields.includes(field),
    );
  };

  const onCellClicked = (event: CellClickedEvent) => {
    const {
      rowIndex,
      colDef: { field },
      context: { propsProxy },
    } = event;

    if (rowIndex === null || typeof field === 'undefined') {
      return;
    }

    const { validationErrors } = propsProxy as Pick<Props, 'validationErrors'>;
    const errorCell = validationErrors?.find(
      (error: ValidationError) =>
        error.row === rowIndex && error.fields.includes(field),
    );
    if (errorCell) {
      const index = (validationErrors ?? []).findIndex(
        (error) => error.row === errorCell.row,
      );
      if (index === -1) {
        return;
      }

      onClickErrorRow?.(index);
    }
  };

  const rowData = useMemo(() => cloneDeep(rows), [rows]);

  const columnDefs = useMemo(() => {
    const nonEmptyHeaders = headers.filter(
      (header) => rows?.[0]?.[header.value],
    ); // ???
    const columnsDefs = nonEmptyHeaders.map((header) => ({
      colId: header.value,
      field: header.value,
      headerName: header.label,
      cellClassRules: {
        'ag-cell-error-default': (params: CellClassParams) =>
          getErrorForCell(params),
      },
    }));

    return [
      {
        headerName: '',
        suppressMovable: true,
        width: 80,
        valueGetter: 'node.rowIndex + 1',
      },
      ...columnsDefs,
    ];
  }, [headers, rows]);

  const gridOptions = useMemo(
    () => ({
      onCellClicked,
      headerHeight: 50,
      context: { propsProxy: { validationErrors: initialValidationErrors } },
    }),
    [headers],
  );

  const sx = useMemo(() => {
    const width = fullWidth ? null : { width: 'calc(100% - 360px)' };
    return { height: '60vh', ...width };
  }, [fullWidth]);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      defaultColDef={DEFAULT_COL_DEF}
      gridOptions={gridOptions}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default AgGridValidationTable;
