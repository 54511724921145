import Typography from '@mui/material/Typography';

type Props = {
  collapsed: boolean;
  testId: string;
  title: string;
};

function SidebarTitle(props: Props) {
  const { collapsed, title, testId } = props;

  return (
    !collapsed && (
      <Typography
        component="div"
        data-testid={testId}
        pl={2}
        py={2}
        variant="overline"
      >
        {title}
      </Typography>
    )
  );
}

export default SidebarTitle;
