import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { PurchaseOrderRecordResponse } from 'shared/lib/types';

export function processPurchaseOrders(
  purchaseOrders: PurchaseOrderRecordResponse[] | undefined,
) {
  return cloneDeep(purchaseOrders);
}

export default function usePurchaseOrdersRows(
  purchaseOrders: PurchaseOrderRecordResponse[] | undefined,
  _isOpenPeriod: boolean,
): PurchaseOrderRecordResponse[] | undefined {
  return useMemo(() => processPurchaseOrders(purchaseOrders), [purchaseOrders]);
}
