import Success from 'shared/assets/icons/Success';
import Chip from 'shared/ui/chip/Chip';

import { capitalize } from 'shared/helpers/helpers';

import styles from './ChipDemo.module.scss';

type ChipsGroupProps = {
  color?: 'default' | 'error' | 'primary' | 'success' | 'warning';
};

function ChipsGroup({ color }: ChipsGroupProps) {
  const renderSize = (size: 'medium' | 'small') => (
    <div className={styles.size}>
      <Chip
        color={color}
        label={`${capitalize(color ?? '')} color`}
        size={size}
      />
      <Chip color={color} icon={<Success />} label="With icon" size={size} />
      <Chip
        color={color}
        label="Deletable"
        size={size}
        onDelete={() => alert('delete button clicked')}
      />
      <Chip
        color={color}
        icon={<Success />}
        label="Deletable with icon"
        size={size}
        onDelete={() => alert('delete button clicked')}
      />
      <Chip color={color} label="Disabled" size={size} disabled />
    </div>
  );
  return (
    <div className={styles.chips}>
      {renderSize('medium')}
      {renderSize('small')}
    </div>
  );
}

function ChipDemo() {
  return (
    <>
      <ChipsGroup color="default" />
      <ChipsGroup color="primary" />
      <ChipsGroup color="success" />
      <ChipsGroup color="warning" />
      <ChipsGroup color="error" />
    </>
  );
}

export default ChipDemo;
