import { useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import SidebarClickableItem from 'shared/components/sidebar/SidebarClickableItem';

import PeriodSelectorSkeleton from 'accruals/components/periods/PeriodSelectorSkeleton';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import {
  formatShortMonth,
  formatShortMonthYear,
  formatShortYear,
} from 'shared/helpers/helpers';
import { MENU_WIDTH } from 'shared/lib/sidebar/SidebarContainer';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetPeriodsByTrialQuery } from 'shared/api/rtkq/periods';

import PeriodList from './PeriodList';
import CreatePeriodButton from './buttons/CreatePeriodButton';

const PERIOD_DROPDOWN_HEIGHT = 48;

type Props = { collapsed?: boolean };

function PeriodSelector(props: Props) {
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const {
    currentData: periods,
    isLoading,
    isUninitialized,
    isFetching,
  } = useGetPeriodsByTrialQuery(trial.trace_id);

  const { collapsed = false } = props;

  const handleOpenSelector = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const periodQueriesLoading = isLoading || isUninitialized || isFetching;

  return (
    <>
      <SidebarClickableItem
        collapsed={collapsed}
        isActive={anchorEl !== null}
        collapsedContent={
          <>
            <Typography
              color="text.secondary"
              sx={{ textTransform: 'uppercase' }}
              variant="body2"
            >
              {formatShortMonth(period.end_date)}
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ textTransform: 'uppercase' }}
              variant="body2"
            >
              {formatShortYear(period.end_date)}
            </Typography>
          </>
        }
        content={
          periodQueriesLoading ? (
            <PeriodSelectorSkeleton />
          ) : (
            `${formatShortMonthYear(period.end_date)} (${period.is_closed ? 'Closed' : 'Open'})`
          )
        }
        onClick={handleOpenSelector}
      />
      <Popover
        anchorEl={anchorEl}
        marginThreshold={0}
        open={anchorEl !== null}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{
          vertical: collapsed ? 0 : PERIOD_DROPDOWN_HEIGHT,
          horizontal: collapsed ? 'right' : 'left',
        }}
        onClose={handleCloseSelector}
      >
        <Box sx={{ display: 'flex', flexFlow: 'column', width: MENU_WIDTH }}>
          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
            <PeriodList
              collapsed={collapsed}
              periods={periods}
              width={anchorEl?.clientWidth}
              onClickPeriod={handleCloseSelector}
            />
          </Box>
          <Box>
            <Divider />
            <CreatePeriodButton periods={periods} />
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default PeriodSelector;
