import type { SxProps } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useInvoiceListingColumnDefs from 'shared/components/invoice/hooks/useInvoiceListingColumnDefs';
import useInvoiceListingGridOptions from 'shared/components/invoice/hooks/useInvoiceListingGridOptions';
import useInvoiceListingRows from 'shared/components/invoice/hooks/useInvoiceListingRows';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetLatestInvoiceSnapshotRecordsByCompanyQuery } from 'shared/api/rtkq/companies';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function InvoiceListingGrid(props: Props) {
  const company = useSelector(selectCompany);
  const { currentData: invoiceSnapshotRecords } =
    useGetLatestInvoiceSnapshotRecordsByCompanyQuery(company.trace_id);

  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useGridColDefs(useInvoiceListingColumnDefs);
  const rowData = useInvoiceListingRows(invoiceSnapshotRecords, true);
  const gridOptions = useGridOptions(useInvoiceListingGridOptions);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  InvoiceListingGrid,
  PeriodGridBlobType.INVOICE_LISTING,
);
