import type {
  ActivityDriverRequestParams,
  ActivityDriverResponse,
} from 'shared/lib/types';

import { PERIOD_OCC_EXPENSES, PERIOD_OCC_RECON } from './apiTags';
import constructApi from './constructApi';
import { CONTRACT_BUDGET_RECORDS } from './contractbudgetrecords';
import {
  CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
} from './contracts';
import { TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT } from './periods';

const apiEndpointFactory = constructApi<
  ActivityDriverResponse,
  ActivityDriverRequestParams
>('activitydriver').withTags('ACTIVITY_DRIVERS', [
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
  CONTRACT_BUDGET_RECORDS,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
  TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
]);
const api = apiEndpointFactory.inject(() => ({
  getActivityDriversByContract: apiEndpointFactory.getBy('contract'),
  deleteActivityDriver: apiEndpointFactory.delete([
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
    TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
  ]),
  upsertActivityDriver: apiEndpointFactory.create(
    [
      CONTRACT_EXPENSES,
      CONTRACT_RECON,
      PERIOD_OCC_EXPENSES,
      PERIOD_OCC_RECON,
      CONTRACT_ACTIVITY_DRIVER_COMPLETED_COUNT,
      TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
    ],
    'upsert_activity_driver',
  ),
}));

export const {
  useUpsertActivityDriverMutation,
  useDeleteActivityDriverMutation,
} = api;
