import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Badge, { badgeClasses } from '@mui/material/Badge';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

type Props = { message?: string; severity: 'error' | 'success' };

function AgGridValidationCellRenderer(props: Props) {
  const { severity, message } = props;

  function getIcon() {
    if (severity === 'success') {
      return (
        <CheckCircleIcon
          fontSize="small"
          sx={{ color: (theme) => theme.palette.success.main }}
        />
      );
    }

    return (
      <ErrorIcon
        fontSize="small"
        sx={{ color: (theme) => theme.palette.error.main }}
      />
    );
  }

  function getContent() {
    if (message) {
      return (
        <Tooltip
          title={message}
          PopperProps={{
            sx: {
              [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: (theme) => theme.palette.common.black,
              },
            },
          }}
        >
          {getIcon()}
        </Tooltip>
      );
    }

    return getIcon();
  }

  return (
    <Badge
      badgeContent={getContent()}
      sx={{
        position: 'fixed',
        top: 'inherit',
        left: 'inherit',
        [`& .${badgeClasses.badge}`]: { padding: 0, backgroundColor: 'white' },
      }}
    />
  );
}

export default AgGridValidationCellRenderer;
