import type { ManyToManyAuditLogEntry } from 'shared/lib/types';

export function getRelevantChanges(
  value: Record<string, ManyToManyAuditLogEntry | string[]>,
) {
  let validChanges: Array<{
    key?: string;
    previousValue?: string;
    newValue?: string;
  }> = [];

  for (const [key, recordValue] of Object.entries(value)) {
    // handle non-m2m changes first
    if (Array.isArray(recordValue)) {
      if (
        recordValue.length !== 2 ||
        (recordValue[0] === 'None' && recordValue[1] === 'None')
      ) {
        continue;
      }

      validChanges.push({
        key,
        previousValue: recordValue[0] === 'None' ? undefined : recordValue[0],
        newValue: recordValue[1] === 'None' ? undefined : recordValue[1],
      });
    } else if (typeof recordValue === 'object' && recordValue.type === 'm2m') {
      const label =
        recordValue.operation === 'add' ? `${key} added` : `${key} removed`;
      validChanges = [
        ...validChanges,
        ...recordValue.objects.map((newValue, index) => ({
          key: index === 0 ? label : undefined,
          newValue,
        })),
      ];
    }
  }

  return validChanges;
}
