import type { ContentTypeResponse, TrialResponse } from 'shared/lib/types';

function _getTrialFilterValue(trial: TrialResponse): string {
  return `${trial.indication} • ${trial.study_id}`;
}

export function mapContentTypesToFilterValues(
  contentTypes: ContentTypeResponse[],
): string[] {
  return contentTypes.map((contentType) => contentType.name);
}

export function mapTrialsToFilterValues(trials: TrialResponse[]): string[] {
  return trials.map(_getTrialFilterValue);
}

export function mapActionsToBackendValues(): Record<string, number> {
  return { Created: 0, Updated: 1, Deleted: 2 };
}

export function mapActionsToFilterValues(): string[] {
  return ['Created', 'Updated', 'Deleted'];
}
