import Box from '@mui/material/Box';

import { useForecastRegionsAndAssumptionGroups } from 'forecasting/components/contract-assumptions/hooks/useForecastingRegionsAndAssumptionGroups';

import AssumptionGroupSection from 'shared/lib/contract-assumptions/assumption-groups/AssumptionGroupSection';
import type { ContractVersionResponse } from 'shared/lib/types';
import { AssumptionGroupSectionName } from 'shared/lib/types';

type Props = {
  contractVersion: ContractVersionResponse;
};

function AssumptionRow({ contractVersion }: Props) {
  const [regions, assumptionGroups] = useForecastRegionsAndAssumptionGroups(
    contractVersion.trace_id,
    true,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
        alignItems: 'top',
        justifyContent: 'space-evenly',
        gap: 2,
      }}
    >
      <AssumptionGroupSection
        assumptionGroups={assumptionGroups}
        regionMap={{}}
        regions={regions}
        setCustomInheritedContract={(_value) => void 0}
        type={AssumptionGroupSectionName.TIMELINE}
        versionName={contractVersion.version_name}
        readOnly
      />
      <AssumptionGroupSection
        assumptionGroups={assumptionGroups}
        regionMap={{}}
        regions={regions}
        setCustomInheritedContract={(_value) => void 0}
        type={AssumptionGroupSectionName.SITES}
        versionName={contractVersion.version_name}
        readOnly
      />
      <Box sx={{ width: '100%' }}>
        <AssumptionGroupSection
          assumptionGroups={assumptionGroups}
          regionMap={{}}
          regions={regions}
          setCustomInheritedContract={(_value) => void 0}
          type={AssumptionGroupSectionName.PATIENT_ENROLLMENT}
          versionName={contractVersion.version_name}
          readOnly
        />
        <AssumptionGroupSection
          assumptionGroups={assumptionGroups}
          regionMap={{}}
          regions={regions}
          setCustomInheritedContract={(_value) => void 0}
          sx={{ mt: 4 }}
          type={AssumptionGroupSectionName.PATIENT_MONTHS}
          versionName={contractVersion.version_name}
          readOnly
        />
      </Box>
    </Box>
  );
}

export default AssumptionRow;
