import type { ReactNode } from 'react';

import type { PeriodRecordBlobType } from 'shared/lib/types';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

import PeriodChecklistBlobViewer from './PeriodChecklistBlobViewer';

export default function withPeriodSpecificChecklistWrapper<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
  savedObjectType: PeriodRecordBlobType,
) {
  return function PeriodChecklistWrapper(props: T) {
    const isClosed = useIsClosedPeriodVersion();

    return isClosed ? (
      <PeriodChecklistBlobViewer savedObjectType={savedObjectType} />
    ) : (
      <LiveDataComponent {...props} />
    );
  };
}
