import type {
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  PopupConfigOptions,
} from '@auth0/auth0-react';

let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined,
) => Promise<string>;
let getAccessTokenWithPopup: (
  options?: GetTokenWithPopupOptions | undefined,
  config?: PopupConfigOptions | undefined,
) => Promise<string | undefined>;

const security = {
  getGetAccessTokenSilently: () => getAccessTokenSilently,
  setGetAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  ) => (getAccessTokenSilently = func),

  getGetAccessTokenWithPopup: () => getAccessTokenWithPopup,
  setGetAccessTokenWithPopup: (
    func: (
      options?: GetTokenWithPopupOptions | undefined,
      config?: PopupConfigOptions | undefined,
    ) => Promise<string | undefined>,
  ) => (getAccessTokenWithPopup = func),
};

export default security;
