import type {
  ContractCostCategoryInfoRequestParams,
  ContractCostCategoryInfoResponse,
} from 'shared/lib/types';

import {
  OCC_EXPENSE_SUMMARY,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
} from './apiTags';
import constructApi from './constructApi';

const apiEndpointFactory = constructApi<
  ContractCostCategoryInfoResponse,
  ContractCostCategoryInfoRequestParams
>('contractcostcategoryinfo').withTags('CONTRACT_COST_CATEGORY_INFO', [
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  OCC_EXPENSE_SUMMARY,
]);
export const api = apiEndpointFactory.inject(() => ({
  getContractCostCategoryInfo: apiEndpointFactory.get(),
  getContractCostCategoryInfoByContractVersion:
    apiEndpointFactory.getBy('contract_version'),
  createContractCostCategoryInfo: apiEndpointFactory.create<
    ContractCostCategoryInfoRequestParams,
    ContractCostCategoryInfoResponse
  >([PERIOD_OCC_EXPENSES, PERIOD_OCC_RECON, OCC_EXPENSE_SUMMARY]),
  updateContractCostCategoryInfo: apiEndpointFactory.update([
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    OCC_EXPENSE_SUMMARY,
  ]),
  deleteContractCostCategoryInfo: apiEndpointFactory.delete([
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    OCC_EXPENSE_SUMMARY,
  ]),
}));

export const {
  useCreateContractCostCategoryInfoMutation,
  useGetContractCostCategoryInfoByContractVersionQuery,
  useLazyGetContractCostCategoryInfoByContractVersionQuery,
  useUpdateContractCostCategoryInfoMutation,
  useDeleteContractCostCategoryInfoMutation,
} = api;
