import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import type { ScaleOrdinal } from 'd3-scale';

import { currencyFormatter, getDateYearAndMonth } from 'formatters';
import { humanizeCostCategory } from 'shared/helpers/helpers';
import GraphTooltipRow from 'shared/lib/graphing/graph-tooltip/GraphTooltipRow';
import LegendLine from 'shared/lib/graphing/shared/LegendLine';
import type { CurrencyCode } from 'shared/lib/types';

import GraphTooltipContainer from '../../../../shared/lib/graphing/graph-tooltip/GraphTooltipContainer';
import type { TrialSpendConfig, TrialSpendDatum } from './types';

type Props = {
  currency: CurrencyCode;
  graphOptions?: TrialSpendConfig;
  innerHeight: number;
  labels: readonly string[];
  legendColorScale: ScaleOrdinal<string, string>;
  marginTop: number;
  tooltip: Partial<UseTooltipParams<TrialSpendDatum>>;
};

function TrialSpendGraphTooltip(props: Props) {
  const {
    labels,
    legendColorScale,
    graphOptions,
    tooltip,
    marginTop,
    innerHeight,
    currency,
  } = props;
  const { tooltipData } = tooltip;

  // Done this way to ensure we have a fallback, bur still prefer the configuration
  const enrollmentText = graphOptions?.enrollmentText ?? 'Actual enrollment';
  const total =
    tooltipData &&
    labels.reduce(
      (total, label) =>
        total + Number.parseFloat(tooltipData[label as keyof TrialSpendDatum]),
      0,
    );

  return (
    <GraphTooltipContainer
      innerHeight={innerHeight}
      marginTop={marginTop}
      tooltip={tooltip}
    >
      {tooltipData && (
        <Box sx={{ m: 0, p: 1, width: 300 }}>
          <Box display="flex">
            <Typography flex={1} variant="body2">
              {getDateYearAndMonth(tooltipData.date)}
            </Typography>
            <Typography variant="body2">Spend</Typography>
          </Box>
          {labels.map((label) => (
            <GraphTooltipRow
              key={label}
              currency={currency}
              label={humanizeCostCategory(label)}
              legendColorScale={legendColorScale}
              value={tooltipData[label as keyof TrialSpendDatum]}
            />
          ))}
          <Box display="flex">
            <Typography flex={1} variant="body2">
              Total
            </Typography>
            <Typography variant="body2">
              {currencyFormatter(total, currency)}
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box display="flex">
            <Box alignItems="center" display="flex" flex={1}>
              <Box
                sx={{
                  width: 18,
                  height: 18,
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                }}
              >
                <svg
                  fill="none"
                  height="18"
                  viewBox="0 0 18 18"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <LegendLine
                    stroke={legendColorScale(enrollmentText)}
                    dashed
                  />
                </svg>
              </Box>
              <Typography ml={1}>{enrollmentText}</Typography>
            </Box>
            <Typography>
              {tooltipData[enrollmentText as keyof TrialSpendDatum]} patients
            </Typography>
          </Box>
        </Box>
      )}
    </GraphTooltipContainer>
  );
}

export default TrialSpendGraphTooltip;
