import type {
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config';
import security from 'security';

import type { TagType } from './rtkq/constructApi';

const prepareHeaders = async (headers: Headers) => {
  let access_token: string | undefined;
  try {
    access_token = await security.getGetAccessTokenSilently()({
      authorizationParams: { audience: config.VITE_APP_AUTH0_AUDIENCE },
    });
  } catch (error) {
    if (config.VITE_APP_IS_DEV_ENVIRONMENT) {
      access_token = await security.getGetAccessTokenWithPopup()({
        authorizationParams: { audience: config.VITE_APP_AUTH0_AUDIENCE },
      });
    } else {
      throw error;
    }
  }

  if (access_token) {
    headers.set('Authorization', `Bearer ${access_token}`);
  }
  return headers;
};

const apiBaseQuery = fetchBaseQuery({
  baseUrl: `${config.VITE_APP_API_HOST}/api`,
  prepareHeaders,
});
const appApiBaseQuery = fetchBaseQuery({
  baseUrl: `${config.VITE_APP_APP_API_HOST}/api`,
  prepareHeaders,
});

const dynamicBaseQuery = (
  args: FetchArgs | string,
  api: BaseQueryApi,
  extraOptions: object,
) => {
  let baseQuery = apiBaseQuery;
  // extraOptions is mistyped in redux-toolkit and we cannot properly type fetchBaseQuery to fix it,
  // but internally they clearly allow it to be undefined (as we have been returning undefined for a long time)
  if ((extraOptions as unknown as ExtraOptionsType)?.api === 'app-api') {
    baseQuery = appApiBaseQuery;
  }
  return baseQuery(args, api, extraOptions);
};

const baseApi = createApi({
  baseQuery: dynamicBaseQuery,
  reducerPath: 'api',
  tagTypes: [] as TagType[],
  endpoints: () => ({}),
});

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error !== null && 'status' in error;
}
export default baseApi;
export type ExtraOptionsType = { api?: 'api' | 'app-api' } | undefined;
