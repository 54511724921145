// eslint-disable-next-line import/no-restricted-paths -- this is shared between company and accruals
import PeriodGraphBlobGraph from 'accruals/components/periods/PeriodGraphBlobGraph';

import type { PeriodGraphBlobType, TraceId } from 'shared/lib/types';
import { PERIOD_AWARE_GRAPH_BLOB_TYPES } from 'shared/lib/types';
import UnderConstructionClosedGrid from 'shared/under-construction/UnderConstructionClosedGrid';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

type GenericClosedGraphProps = GenericGraphProps & { parentMenuItem?: TraceId };
export type GenericGraphProps = { width: number; height: number };

export default function withPeriodSpecificGraphWrapper<T extends object>(
  LiveDataComponent: (props: T) => JSX.Element,
  savedObjectType: PeriodGraphBlobType,
) {
  return function PeriodGraphWrapper(props: GenericClosedGraphProps & T) {
    const { parentMenuItem, width, height } = props;
    const isClosed = useIsClosedPeriodVersion();

    return isClosed ? (
      PERIOD_AWARE_GRAPH_BLOB_TYPES.includes(savedObjectType) ? (
        <PeriodGraphBlobGraph
          height={height}
          parentMenuItem={parentMenuItem}
          savedObjectType={savedObjectType}
          width={width}
        />
      ) : (
        <UnderConstructionClosedGrid />
      )
    ) : (
      <LiveDataComponent {...props} />
    );
  };
}
