/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

The only thing that should go inside this file are things that ONLY need the company, trial, and 
forecast loaded and ready. If you add anything for other parts of the app, like accruals, 
this will likely not work as expected and possibly break our menu structure.

This is a XXXLayout instead of a container as we only want this to render once, and not unmount
when we stay within the forecasting pages so we don't cause the menu to flicker.

If you have any questions, please ask in #engineering
*/

import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import ForecastingPageTitle from 'forecasting/components/page-titles/ForecastingPageTitle';
import ForecastingSidebar from 'forecasting/components/sidebar/ForecastingSidebar';

import EmptyForecasts from 'forecasting/pages/forecasting/components/EmptyForecasts';
import CreateForecastFolderModal from 'forecasting/pages/forecasting/modals/CreateForecastFolderModal';
import CreateForecastModal from 'forecasting/pages/forecasting/modals/CreateForecastModal';
import DeleteForecastFolderModal from 'forecasting/pages/forecasting/modals/DeleteForecastFolderModal';
import DeleteForecastModal from 'forecasting/pages/forecasting/modals/DeleteForecastModal';
import DuplicateForecastModal from 'forecasting/pages/forecasting/modals/DuplicateForecastModal';
import LockForecastModal from 'forecasting/pages/forecasting/modals/LockForecastModal';
import RenameForecastFolderModal from 'forecasting/pages/forecasting/modals/RenameForecastFolderModal';
import RenameForecastModal from 'forecasting/pages/forecasting/modals/RenameForecastModal';
import {
  missingForecast,
  selectForecast,
} from 'forecasting/state/slices/forecastSlice';
import PageContainer from 'shared/containers/page-container/PageContainer';
import PageContentContainer from 'shared/containers/page-content-container/PageContentContainer';
import { closeModal, selectModals } from 'shared/state/slices/modalsSlice';

import withForecastingPageRequiredData from 'forecasting/api/hocs/withForecastingPageRequiredData';

function ForecastingLayout() {
  const dispatch = useDispatch();
  const forecast = useSelector(selectForecast);

  const {
    createForecast,
    createForecastFolder,
    renameForecast,
    lockForecast,
    duplicateForecast,
    deleteForecastFolder,
    deleteForecast,
    renameForecastFolder,
  } = useSelector(selectModals);

  const handleCloseCreateForecast = useCallback(
    () => dispatch(closeModal('createForecast')),
    [dispatch],
  );
  const handleCloseRenameForecast = useCallback(
    () => dispatch(closeModal('renameForecast')),
    [dispatch],
  );
  const handleCloseDuplicateForecast = useCallback(
    () => dispatch(closeModal('duplicateForecast')),
    [dispatch],
  );
  const handleCloseDeleteForecast = useCallback(
    () => dispatch(closeModal('deleteForecast')),
    [dispatch],
  );
  const handleCloseCreateForecastFolder = useCallback(
    () => dispatch(closeModal('createForecastFolder')),
    [dispatch],
  );
  const handleCloseRenameForecastFolder = useCallback(
    () => dispatch(closeModal('renameForecastFolder')),
    [dispatch],
  );
  const handleCloseDeleteForecastFolder = useCallback(
    () => dispatch(closeModal('deleteForecastFolder')),
    [dispatch],
  );

  return (
    <PageContainer>
      <ForecastingSidebar />
      <PageContentContainer>
        {forecast === missingForecast ? (
          <EmptyForecasts />
        ) : (
          <>
            <ForecastingPageTitle />
            <Outlet />
          </>
        )}
        {createForecast && (
          <CreateForecastModal onClose={handleCloseCreateForecast} />
        )}
        {createForecastFolder && (
          <CreateForecastFolderModal
            onClose={handleCloseCreateForecastFolder}
          />
        )}
        {renameForecast && (
          <RenameForecastModal
            forecast={renameForecast}
            handleCloseModal={handleCloseRenameForecast}
          />
        )}
        {lockForecast && (
          <LockForecastModal
            handleCloseModal={() => dispatch(closeModal('lockForecast'))}
          />
        )}
        {duplicateForecast && (
          <DuplicateForecastModal
            forecast={duplicateForecast}
            handleCloseModal={handleCloseDuplicateForecast}
          />
        )}
        {deleteForecast && (
          <DeleteForecastModal
            forecast={deleteForecast}
            handleCloseModal={handleCloseDeleteForecast}
          />
        )}
        {renameForecastFolder && (
          <RenameForecastFolderModal
            folder={renameForecastFolder}
            handleCloseModal={handleCloseRenameForecastFolder}
          />
        )}
        {deleteForecastFolder && (
          <DeleteForecastFolderModal
            folder={deleteForecastFolder}
            handleCloseModal={handleCloseDeleteForecastFolder}
          />
        )}
      </PageContentContainer>
    </PageContainer>
  );
}

export default withForecastingPageRequiredData(ForecastingLayout);
