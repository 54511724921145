import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import type {
  AssumptionGroupRequestDisplay,
  RegionGroupListItemType,
  RegionListItemType,
  TraceId,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';
import type { RegionsAndAssumptionGroups } from 'shared/lib/contract-assumptions/hooks/useRegionsAndAssumptionGroups';
import {
  calculateAssumptionGroups,
  createGroups,
} from 'shared/lib/contract-assumptions/hooks/useRegionsAndAssumptionGroups';

import { useGetContractAssumptionGroupsByContractVersionQuery } from 'shared/api/rtkq/assumptiongroups';
import {
  useGetClosedPeriodAssumptionGroupsByContractVersionQuery,
  useGetClosedPeriodRegionGroupsByContractVersionQuery,
  useGetClosedPeriodRegionsByContractVersionQuery,
} from 'shared/api/rtkq/contracts';
import { useGetRegionGroupsByContractVersionQuery } from 'shared/api/rtkq/regiongroups';
import { useGetRegionsByContractVersionQuery } from 'shared/api/rtkq/regions';

export function useForecastRegionsAndAssumptionGroups(
  contractVersionTraceId?: TraceId,
  readOnly = false,
): RegionsAndAssumptionGroups {
  const { period: currentForecastPeriod } = useForecast();

  const { currentData: databaseRegions } = useGetRegionsByContractVersionQuery(
    contractVersionTraceId &&
      currentForecastPeriod &&
      !currentForecastPeriod.is_closed
      ? contractVersionTraceId
      : skipToken,
  );
  const { currentData: closedPeriodRegions } =
    useGetClosedPeriodRegionsByContractVersionQuery(
      contractVersionTraceId &&
        currentForecastPeriod &&
        currentForecastPeriod.is_closed
        ? contractVersionTraceId
        : skipToken,
    );

  const { currentData: databaseRegionGroups } =
    useGetRegionGroupsByContractVersionQuery(
      contractVersionTraceId &&
        currentForecastPeriod &&
        !currentForecastPeriod.is_closed
        ? contractVersionTraceId
        : skipToken,
    );
  const { currentData: closedPeriodRegionGroups } =
    useGetClosedPeriodRegionGroupsByContractVersionQuery(
      contractVersionTraceId &&
        currentForecastPeriod &&
        currentForecastPeriod.is_closed
        ? contractVersionTraceId
        : skipToken,
    );

  const { currentData: databaseAssumptionGroups } =
    useGetContractAssumptionGroupsByContractVersionQuery(
      contractVersionTraceId &&
        currentForecastPeriod &&
        !currentForecastPeriod.is_closed
        ? contractVersionTraceId
        : skipToken,
    );

  const { currentData: closedPeriodAssumptionGroups } =
    useGetClosedPeriodAssumptionGroupsByContractVersionQuery(
      contractVersionTraceId &&
        currentForecastPeriod &&
        currentForecastPeriod.is_closed
        ? contractVersionTraceId
        : skipToken,
    );

  const [allRegions, setAllRegions] = useState<RegionListItemType[]>([]);
  const [allRegionGroups, setAllRegionGroups] = useState<
    RegionGroupListItemType[]
  >([]);

  const [assumptionGroups, setAssumptionGroups] = useState<
    AssumptionGroupRequestDisplay[]
  >([]);

  const editableRegions = allRegions.filter(
    (region) => region.action !== CRUDAction.DELETE,
  );

  function applyCalculations(
    updatedAssumptionGroups: AssumptionGroupRequestDisplay[],
  ) {
    setAssumptionGroups(calculateAssumptionGroups(updatedAssumptionGroups));
  }

  useEffect(() => {
    const contractVersionRegions = currentForecastPeriod?.is_closed
      ? closedPeriodRegions
      : databaseRegions;
    if (contractVersionRegions !== undefined) {
      setAllRegions(
        contractVersionRegions.map((dbRegion) => ({ ...dbRegion, new: false })),
      );
    }
  }, [databaseRegions, closedPeriodRegions, currentForecastPeriod?.is_closed]);

  useEffect(() => {
    const contractVersionRegionGroups = currentForecastPeriod?.is_closed
      ? closedPeriodRegionGroups
      : databaseRegionGroups;
    // always prepend the global regionGroup
    if (contractVersionRegionGroups !== undefined) {
      const newRegionGroups = [
        { name: 'Global (all added regions)', global: true },
      ] as RegionGroupListItemType[];
      setAllRegionGroups([
        ...newRegionGroups,
        ...contractVersionRegionGroups.map((dbRegionGroup) => ({
          ...dbRegionGroup,
          global: false,
          new: false,
        })),
      ]);
    }
  }, [
    databaseRegionGroups,
    closedPeriodRegionGroups,
    currentForecastPeriod?.is_closed,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: as this examines assumptionGroups, which is what this generates, this will cause an infinite loop if added
  useEffect(() => {
    if (contractVersionTraceId) {
      setAssumptionGroups(
        createGroups(
          contractVersionTraceId,
          readOnly,
          allRegions,
          assumptionGroups,
          currentForecastPeriod?.is_closed
            ? closedPeriodAssumptionGroups
            : databaseAssumptionGroups,
        ),
      );
    }
  }, [
    contractVersionTraceId,
    allRegions,
    databaseAssumptionGroups,
    closedPeriodAssumptionGroups,
  ]);

  return [
    editableRegions,
    assumptionGroups,
    allRegions,
    [],
    setAllRegions,
    allRegionGroups,
    [],
    setAllRegionGroups,
    applyCalculations,
  ];
}
