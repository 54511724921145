import { useState } from 'react';

import EditIcon from '@mui/icons-material/EditOutlined';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import ContractVersionWizard from 'accruals/wizards/contract-version-wizard/ContractVersionWizard';
import withReadOnlyPermissionWrapper from 'shared/lib/read-only-permission/withReadOnlyPermissionWrapper';

import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';

function EditContractVersion() {
  const [editContractVersionWizardOpen, setEditContractVersionWizardOpen] =
    useState(false);
  const { contractContainer, contractVersionTraceId } =
    useContractAndPeriodWithVersions();

  return (
    <>
      {contractContainer && editContractVersionWizardOpen && (
        <ContractVersionWizard
          contractContainerId={contractContainer.trace_id}
          contractVersionTraceId={contractVersionTraceId}
          onClose={() => setEditContractVersionWizardOpen(false)}
        />
      )}
      <Button
        disabled={!contractVersionTraceId}
        size="small"
        startIcon={<EditIcon />}
        testId="edit"
        variant="outlined"
        onClick={() => setEditContractVersionWizardOpen(true)}
      >
        Edit
      </Button>
    </>
  );
}

export default withReadOnlyPermissionWrapper(
  withPeriodSpecificGenericWrapper(EditContractVersion),
);
