import type { ICellRendererParams } from '@ag-grid-community/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles';

import { percentFormatter } from 'formatters';

function AgGridOverUnderPercentageCellRenderer(props: ICellRendererParams) {
  const { value } = props;

  const color = (theme: Theme) =>
    value <= 0 ? theme.palette.success : theme.palette.error;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
        height: '100%',
      }}
    >
      <Typography
        color={(theme) => color(theme).main}
        variant="body1"
        sx={{
          textAlign: 'center',
          width: '100%',
          fontWeight: '700',
          backgroundColor: (theme) => color(theme).light,
        }}
      >
        {`${(value ?? 0) > 0 ? '+' : ''}${percentFormatter(value)}`}
      </Typography>
    </Box>
  );
}

export default AgGridOverUnderPercentageCellRenderer;
