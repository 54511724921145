import type { ReactNode } from 'react';

import type { PeriodRecordBlobType, TraceId } from 'shared/lib/types';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

type Props = { parentMenuItem?: TraceId };

export default function withPeriodSpecificCustomWrapper<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
  ClosedDataComponent: (props: {
    savedObjectType: PeriodRecordBlobType;
    parentMenuItem?: TraceId;
  }) => ReactNode,
  savedObjectType: PeriodRecordBlobType,
) {
  return function PeriodChecklistWrapper(props: Props & T) {
    const { parentMenuItem } = props;
    const isClosed = useIsClosedPeriodVersion();

    return isClosed ? (
      <ClosedDataComponent
        parentMenuItem={parentMenuItem}
        savedObjectType={savedObjectType}
      />
    ) : (
      <LiveDataComponent {...props} />
    );
  };
}
