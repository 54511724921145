import { useMemo } from 'react';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withCompanyPageRequiredData from 'shared/api/hocs/withCompanyPageRequiredData';

import AuditLogGrid from './grids/AuditLogGrid';

function AuditLogPage() {
  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <AuditLogGrid
          overlayNoRowsTemplate="No Data."
          sx={useMemo(() => ({ height: '100%' }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withCompanyPageRequiredData(AuditLogPage);
