import { useRef } from 'react';

import ReactDOM from 'react-dom';

import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ListItem, { getListItemUtilityClass } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { buttonClasses } from 'shared/ui/button/Button';
import IconButton from 'shared/ui/icon-button/IconButton';

import type { PatientCohortEditor } from 'shared/lib/types';
import {
  changeCohort,
  selectCohort,
  selectCohortError,
  selectCohortNameEditor,
  selectCohorts,
} from 'shared/state/slices/visitSchedulesSlice';

import useDnd from './useDnd';

type Props = {
  cohort: PatientCohortEditor;
  index: number;
};

function DraggableCohortItem(props: Props) {
  const { cohort, index } = props;
  const dispatch = useDispatch();
  const cohorts = useSelector(selectCohorts);
  const currentCohort = useSelector(selectCohort);
  const cohortNameEditor = useSelector(selectCohortNameEditor);
  const cohortError = useSelector(selectCohortError(cohort.traceId!));
  const ref = useRef<HTMLLIElement>(null);
  const dragHandleRef = useRef<HTMLButtonElement>(null);
  const { draggableState, closestEdge } = useDnd(
    index,
    'cohort',
    ref,
    dragHandleRef,
  );

  function onSelectCohort(selectedCohort: PatientCohortEditor) {
    dispatch(changeCohort({ cohort: selectedCohort }));
  }

  return (
    <>
      <ListItem
        ref={ref}
        secondaryAction={
          <IconButton
            edge="end"
            sx={{
              [`&.${buttonClasses.disabled}`]: {
                color: (theme) => theme.palette.text.primary,
              },
            }}
            disabled
          >
            <NavigateNextIcon />
          </IconButton>
        }
        sx={{
          [`&.${getListItemUtilityClass('root')}`]: {
            cursor: 'pointer',
          },
          ...(cohort.name === currentCohort?.name && {
            [`&.${getListItemUtilityClass('root')}`]: {
              backgroundColor: (theme) => theme.palette.primary.light,
            },
          }),
          ...(draggableState.type === 'dragging' && {
            [`&.${getListItemUtilityClass('root')}`]: {
              boxShadow: (theme) => `0 0 20px ${theme.palette.grey[600]}`,
              bgcolor: 'common.white',
            },
          }),
          ...(cohortError && {
            [`&.${getListItemUtilityClass('root')}`]: {
              backgroundColor: (theme) => theme.palette.error.light,
            },
          }),
        }}
        divider
        onClick={() => onSelectCohort(cohort)}
      >
        <ListItemIcon
          ref={dragHandleRef}
          color="green"
          sx={{
            ...(!cohortNameEditor && cohorts.length > 1
              ? {
                  cursor: 'grab',
                  color: 'grey.700',
                }
              : {
                  color: 'grey.300',
                }),
          }}
        >
          <DragHandleIcon />
        </ListItemIcon>
        <ListItemText primary={cohort.name} />
        {closestEdge && <DropIndicator edge={closestEdge} gap="1px" />}
      </ListItem>
      {draggableState.type === 'preview' &&
        ReactDOM.createPortal(
          <Box>{cohort.name}</Box>,
          draggableState.container,
        )}
    </>
  );
}

export default DraggableCohortItem;
