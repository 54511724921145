import type { ReactElement } from 'react';

import Button, { type ButtonProps } from 'shared/ui/button/Button';

import withReadOnlyPermissionWrapper from 'shared/lib/read-only-permission/withReadOnlyPermissionWrapper';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';

function PeriodAwareButton(props: ButtonProps): ReactElement {
  return <Button {...props} />;
}

export default withReadOnlyPermissionWrapper(
  withPeriodSpecificGenericWrapper(PeriodAwareButton),
);
