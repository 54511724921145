import type {
  SiteListingApplyRequest,
  SiteListingSnapshotResponse,
} from 'shared/lib/types';

import { PATIENT_ACTIVITY_GRID } from './apiTags';
import constructApi from './constructApi';
import { SITE_COST_MATRIX } from './periods';

const PATH = 'sitelistingsnapshots';

const apiEndpointFactory = constructApi<SiteListingSnapshotResponse>(
  PATH,
).withTags('SITE_LISTING_SNAPSHOTS', [SITE_COST_MATRIX, PATIENT_ACTIVITY_GRID]);
const api = apiEndpointFactory.inject(() => ({
  createSiteListingSnapshotFromCsv: apiEndpointFactory.create(
    [],
    'new_from_csv',
  ),
  updateSiteListingSnapshot: apiEndpointFactory.update(),
  upsertSiteListingApplySiteRecords:
    apiEndpointFactory.create<SiteListingApplyRequest>(
      [SITE_COST_MATRIX, PATIENT_ACTIVITY_GRID],
      'upsert_site_records',
    ),
  getSiteListingSnapshotsByContract: apiEndpointFactory.getBy('contract'),
  deleteSiteListingSnapshot: apiEndpointFactory.delete(),
}));

export const {
  useUpsertSiteListingApplySiteRecordsMutation,
  useDeleteSiteListingSnapshotMutation,
  useCreateSiteListingSnapshotFromCsvMutation,
} = api;
