import type { ReactElement } from 'react';

import useRecordBlobDataLoader from 'accruals/components/periods/hooks/useRecordBlobDataLoader';

import ActivityDriverCounts from 'accruals/pages/clinical-expenses/shared/activity-driver-counts/ActivityDriverCounts';
import type {
  ActivityDriverValidation,
  PeriodRecordBlobType,
  TraceId,
} from 'shared/lib/types';

type Props = {
  parentMenuItem?: TraceId;
  savedObjectType: PeriodRecordBlobType;
};

export default function ActivityDriverCountsBlobViewer(
  props: Props,
): ReactElement {
  const { savedObjectType, parentMenuItem } = props;
  const blobData = useRecordBlobDataLoader<ActivityDriverValidation>(
    savedObjectType,
    parentMenuItem,
  );

  return <ActivityDriverCounts activityDriverValidation={blobData} />;
}
