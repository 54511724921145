import { createContext } from 'react';

const WizardContext = createContext<{
  devTitle: string;
  title?: string;
  stepNames?: string[];
  onClose: (isLastStepSaveClick?: boolean) => void;
  nextStep: () => Promise<void>;
  previousStep: () => void;
  goToStep: (stepIndex: number) => void;
  handleStep?: (handler: Handler) => void;
  isLoading: boolean;
  activeStep: number;
  stepCount: number;
  isFirstStep: boolean;
  isLastStep: boolean;
} | null>(null);

export type Handler = (() => Promise<void>) | (() => void) | null;
export default WizardContext;
