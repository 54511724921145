import type { CSSProperties, ReactNode } from 'react';
import { useCallback } from 'react';

import { useTheme } from '@mui/material/styles';
import { useTooltipInPortal } from '@visx/tooltip';
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';

/** fontSize + lineHeight from default styles break precise location of crosshair, etc. */
/** from Visx lib */
const TOOLTIP_NO_STYLE: CSSProperties = {
  position: 'absolute',
  pointerEvents: 'none',
  fontSize: 0,
  lineHeight: 0,
};

const INVISIBLE_STYLES: CSSProperties = {
  position: 'absolute',
  left: 0,
  top: 0,
  opacity: 0,
  width: 0,
  height: 0,
  pointerEvents: 'none',
};

type Props<TooltipData> = {
  children: ReactNode;
  crossHairStroke?: string;
  innerHeight: number;
  marginTop: number;
  tooltip: Partial<UseTooltipParams<TooltipData>>;
};

function GraphTooltipContainer<TooltipData>(props: Props<TooltipData>) {
  const materialTheme = useTheme();
  const {
    tooltip,
    marginTop,
    innerHeight,
    children,
    crossHairStroke = materialTheme.palette.grey[100],
  } = props;

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const setContainerRef = useCallback(
    (ownRef: HTMLElement | SVGElement | null) => {
      containerRef(ownRef?.parentElement ?? null);
    },
    [containerRef],
  );

  return (
    <>
      <svg ref={setContainerRef} style={INVISIBLE_STYLES} />

      {tooltip.tooltipOpen && tooltip.tooltipData && (
        <>
          <TooltipInPortal
            detectBounds={false}
            left={tooltip.tooltipLeft}
            offsetLeft={0}
            offsetTop={0}
            style={TOOLTIP_NO_STYLE}
            top={marginTop}
          >
            <svg height={innerHeight} overflow="visible" width="1">
              <line
                stroke={crossHairStroke}
                strokeWidth={1.5}
                x1={0}
                x2={0}
                y1={0}
                y2={innerHeight}
              />
            </svg>
          </TooltipInPortal>

          <TooltipInPortal left={tooltip.tooltipLeft} top={tooltip.tooltipTop}>
            {children}
          </TooltipInPortal>
        </>
      )}
    </>
  );
}

export default GraphTooltipContainer;
