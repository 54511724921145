import type { MappedColumn } from 'shared/lib/column-mapper/types';

const columns = (includeProcedureType: boolean): MappedColumn[] => [
  { label: 'Project ID', value: 'project', required: false },
  { label: 'Trial ID', value: 'trial_id', required: false },
  { label: 'Subject', value: 'subject', required: true },
  { label: 'Site Number', value: 'site_number', required: true },
  { label: 'Site Name', value: 'site_name', required: false },
  { label: 'Site Group', value: 'site_group', required: false },
  { label: 'Visit Name', value: 'visit_name', required: false },
  { label: 'Visit Date', value: 'visdat', required: true },
  { label: 'Cohort', value: 'cohort', required: true },
  { label: 'Save TS', value: 'savets', required: false },
  { label: 'Confirmed Event?', value: 'confirmed_event', required: false },
  ...(includeProcedureType
    ? [{ label: 'Procedure type', value: 'invoiceable_type', required: true }]
    : []),
  { label: 'Invoiceable Detail', value: 'invoiceable_detail', required: false },
];

export default columns;
