import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import type { SnapshotRecordType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';

import { useGetSiteListingRecordsBySnapshotQuery } from 'shared/api/rtkq/sitelistingrecords';
import { useUpsertSiteListingApplySiteRecordsMutation } from 'shared/api/rtkq/sitelistingsnapshots';

import columns from '../columns';

type SiteListingReviewStepProps = {
  snapshotId?: string;
  onComplete: () => void;
};

function SiteListingReviewStep(props: SiteListingReviewStepProps) {
  const { snapshotId, onComplete } = props;

  const trialId = useSelector(selectTrial).trace_id;
  const { currentData: allRecords } = useGetSiteListingRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  );
  const [upsertSiteListing] = useUpsertSiteListingApplySiteRecordsMutation();

  const handleOnComplete = () => {
    void (async () => {
      await upsertSiteListing({ snapshot: snapshotId, trial: trialId });
      // TODO: Handle error response
      onComplete();
    })();
  };

  return (
    <WizardStep
      description="Sites will be updated based on their site number. If you manually added a site previously, if there is a matching site number in the listing it will replace the information for this site."
      header="Review your site listing to make sure everything looks right."
      onNext={handleOnComplete}
    >
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default SiteListingReviewStep;
