import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { PeriodGridBlobType } from 'shared/lib/types';

import useCompanyFilesColumnDefs from '../hooks/useCompanyFilesColumnDefs';
import useCompanyFilesGridOptions from '../hooks/useCompanyFilesGridOptions';
import useCompanyFilesRows from '../hooks/useCompanyFilesRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function CompanyFilesGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useCompanyFilesColumnDefs(true);
  const gridOptions = useCompanyFilesGridOptions(true);
  const rowData = useCompanyFilesRows(true);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  CompanyFilesGrid,
  PeriodGridBlobType.COMPANY_FILES,
);
