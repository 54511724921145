import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type { SxProps, Theme } from '@mui/material/styles';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import { TRIAL_SPEND_HEIGHT } from 'forecasting/components/graphing/trial-spend-forecast-graph/TrialSpendForecastGraph';

import BudgetForecastGraph from './summary-tab/BudgetForecastGraph';
import CostAndTimeForecastGraph from './summary-tab/CostAndTimeForecastGraph';
import FinancialTabularData from './summary-tab/FinancialTabularData';
import OperationalTabularData from './summary-tab/OperationalTabularData';
import PatientCostGraph from './summary-tab/PatientCostGraph';
import RunRateGraph from './summary-tab/RunRateGraph';
import TrialSpendGraph from './summary-tab/TrialSpendGraph';

const ROW_HEIGHT = 560;
const ROW_SPACING = 4;
const HEADER_HEIGHT = 90;
const GRAPH_HEIGHT = ROW_HEIGHT - HEADER_HEIGHT; // account for the header / margins / padding / etc

const HALF_WIDTH_GRAPH_ROW_SX: SxProps<Theme> = {
  overflowY: 'auto',
  height: ROW_HEIGHT,
};
const HALF_WIDTH_GRAPH_ROW_INNER_SX: SxProps<Theme> = {
  height: (theme) => `calc(${GRAPH_HEIGHT}px - ${theme.spacing(ROW_SPACING)})`,
};

const FULL_WIDTH_GRAPH_ROW_SX: SxProps<Theme> = {
  height: (theme) =>
    `calc(${TRIAL_SPEND_HEIGHT + HEADER_HEIGHT}px + ${theme.spacing(ROW_SPACING)})`,
};
const FULL_WIDTH_GRAPH_ROW_INNER_SX: SxProps<Theme> = {
  height: (theme) =>
    `calc(${TRIAL_SPEND_HEIGHT}px + ${theme.spacing(ROW_SPACING)})`,
  overflow: 'hidden',
  overflowX: 'auto',
};

function SummaryTab() {
  return (
    <>
      <DashboardCard sx={{ overflowY: 'auto' }}>
        <FinancialTabularData />
      </DashboardCard>
      <DashboardCard sx={{ overflowY: 'auto' }}>
        <OperationalTabularData />
      </DashboardCard>
      <Grid spacing={ROW_SPACING} sx={{ mt: 0 }} container>
        <Grid xs={6} item>
          <DashboardCard
            sx={HALF_WIDTH_GRAPH_ROW_SX}
            title="Cost & time forecast"
          >
            <Box sx={HALF_WIDTH_GRAPH_ROW_INNER_SX}>
              <CostAndTimeForecastGraph />
            </Box>
          </DashboardCard>
        </Grid>
        <Grid xs={6} item>
          <DashboardCard
            sx={HALF_WIDTH_GRAPH_ROW_SX}
            title="Budget vs. forecast per vendor"
          >
            <Box sx={HALF_WIDTH_GRAPH_ROW_INNER_SX}>
              <BudgetForecastGraph />
            </Box>
          </DashboardCard>
        </Grid>
      </Grid>
      <DashboardCard sx={FULL_WIDTH_GRAPH_ROW_SX} title="Trial spend">
        <Box sx={FULL_WIDTH_GRAPH_ROW_INNER_SX}>
          <TrialSpendGraph />
        </Box>
      </DashboardCard>
      <DashboardCard
        sx={FULL_WIDTH_GRAPH_ROW_SX}
        title="Run rate vs. enrollment"
      >
        <Box sx={FULL_WIDTH_GRAPH_ROW_INNER_SX}>
          <RunRateGraph />
        </Box>
      </DashboardCard>
      <DashboardCard
        sx={FULL_WIDTH_GRAPH_ROW_SX}
        title="Cost per patient over time"
      >
        <Box sx={FULL_WIDTH_GRAPH_ROW_INNER_SX}>
          <PatientCostGraph />
        </Box>
      </DashboardCard>
    </>
  );
}

export default SummaryTab;
