import type { SxProps } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import useTrialActivityEnrollmentColumnDefs from 'accruals/pages/trial/hooks/useTrialActivityEnrollmentColumnDefs';
import useTrialActivityEnrollmentGridOptions from 'accruals/pages/trial/hooks/useTrialActivityEnrollmentGridOptions';
import useTrialActivityEnrollmentRows from 'accruals/pages/trial/hooks/useTrialActivityEnrollmentRows';
import { selectPeriod } from 'accruals/state/slices/periodSlice';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { PeriodGridBlobType } from 'shared/lib/types';

import { useGetTrialActivityEnrollmentQuery } from 'shared/api/rtkq/periods';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function EnrollmentTrialActivityGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const period = useSelector(selectPeriod);

  const { currentData: trialActivityEnrollment } =
    useGetTrialActivityEnrollmentQuery(period.trace_id);

  const columnDefs = useTrialActivityEnrollmentColumnDefs(true);
  const rowData = useTrialActivityEnrollmentRows(trialActivityEnrollment);
  const gridOptions = useTrialActivityEnrollmentGridOptions(true);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  EnrollmentTrialActivityGrid,
  PeriodGridBlobType.TRIAL_ACTIVITY_ENROLLMENT,
);
