import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { TrialFilesGridRow } from 'shared/lib/types';

export default function usePeriodFilesGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<TrialFilesGridRow> {
  return useMemo(() => ({ getRowId: 'id' }), []);
}
