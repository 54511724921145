import type { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import logo from 'shared/assets/images/logo.png';

import ErrorContainer from 'shared/containers/error-container/ErrorContainer';

export default function UserBlocked(): ReactElement {
  return (
    <ErrorContainer>
      <Box component="img" height={80} src={logo} sx={{ mb: 2 }} width={80} />
      <Typography color="primary" sx={{ mb: 2 }} variant="h4">
        Welcome to Condor
      </Typography>
      <Typography align="center" data-testid="no_perms" variant="body1">
        It looks like you haven&lsquo;t logged in to your Condor account in over
        30 days. Please contact{' '}
        <a href="mailto:support@condorsoftware.com">
          support@condorsoftware.com
        </a>{' '}
        to reactivate your account.
      </Typography>
    </ErrorContainer>
  );
}
