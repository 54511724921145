import type {
  AccountingInfo,
  BottomLineAdjustment,
  ContractCostCategoryInfoRequestParams,
  ContractFile,
  ContractInfo,
} from 'shared/lib/types';

import type {
  BottomLineAdjustmentErrors,
  ContractCostCategoryInfoErrors,
  ContractVersionErrors,
} from './types';

const validateContractCostCategoryInfo = (
  costCategoryInfo: ContractCostCategoryInfoRequestParams,
  index: number,
  allCategories: string[] | undefined,
) => {
  const errorObj: ContractCostCategoryInfoErrors = {};
  if (!costCategoryInfo.gl_account) {
    errorObj.gl_account = 'GL Account is required';
  }

  const categoryError = getCategoryValidationMessage(
    costCategoryInfo.category.trim(),
    index,
    allCategories,
  );
  if (categoryError) {
    errorObj.category = categoryError;
  }

  if (
    costCategoryInfo.gross_value === undefined ||
    Number.isNaN(costCategoryInfo.gross_value)
  ) {
    errorObj.gross_value = 'Gross Value is required';
  }
  return errorObj;
};

const validateBottomLineAdjustment = (adjustment: BottomLineAdjustment) => {
  const errorObj: BottomLineAdjustmentErrors = {};
  if (adjustment.amountType === 'ABSOLUTE') {
    if (!adjustment.amount) {
      errorObj.amount = 'Adjustment amount is required';
    }
  } else if (
    !adjustment.amountPercentage ||
    adjustment.amountPercentage < 0 ||
    adjustment.amountPercentage > 100
  ) {
    errorObj.amount_percentage =
      'Adjustment amount percentage should be between 0 and 100';
  }
  return errorObj;
};

const getCategoryValidationMessage = (
  category: string,
  index: number,
  allCategories: string[] | undefined,
) => {
  if (!category) {
    return 'Category is required';
  }

  if (
    allCategories?.includes(category) &&
    allCategories.indexOf(category) !== index
  ) {
    return 'There is already a category with this name.';
  }

  return null;
};

export const validateContractInfoForm = (
  contractInfo: ContractInfo,
  sameTrialPoNumbers: string[],
  allEffectiveDatesFromOtherContracts: Array<string | undefined>,
  allAipEffectiveDatesFromOtherContracts: Array<string | undefined>,
) => {
  const errors: ContractVersionErrors = {};
  if (contractInfo.contractVersion.length === 0) {
    errors.version = 'Version is required';
  }

  if (!contractInfo.effectiveDate && !contractInfo.aipEffectiveDate) {
    errors.effective_date = 'Effective date is required';
    errors.aip_effective_date = 'Effective date is required';
  } else if (
    allEffectiveDatesFromOtherContracts.includes(contractInfo.effectiveDate)
  ) {
    errors.effective_date =
      'There is already a current contract version with this effective month';
  } else if (
    allAipEffectiveDatesFromOtherContracts.includes(
      contractInfo.aipEffectiveDate,
    )
  ) {
    errors.aip_effective_date =
      'There is already an AIP contract version with this AIP effective month';
  } else if (contractInfo.effectiveDate === contractInfo.aipEffectiveDate) {
    errors.effective_date =
      'Effective date cannot be the same as AIP Effective date';
    errors.aip_effective_date =
      'AIP Effective date cannot be the same the Effective date';
  }

  if (
    contractInfo.voidedDate &&
    (contractInfo.effectiveDate === contractInfo.voidedDate ||
      contractInfo.aipEffectiveDate === contractInfo.voidedDate)
  ) {
    errors.voided_date =
      'Voided date cannot be the same as Effective date or the AIP Effective date';
  }

  if (
    contractInfo.voidedDate &&
    contractInfo.effectiveDate &&
    contractInfo.voidedDate < contractInfo.effectiveDate
  ) {
    errors.voided_date = 'Void date cannot be before effective date';
  }

  const poNumberErrorMessage = poNumberDuplicatesErrorMessage(
    contractInfo.poNumber,
    sameTrialPoNumbers,
  );
  if (!contractInfo.poNumber) {
    errors.po_number = 'PO Number is required';
  } else if (poNumberErrorMessage) {
    errors.po_number = poNumberErrorMessage;
  }

  return errors;
};

export const poNumberDuplicatesErrorMessage = (
  poNumber: ContractInfo['poNumber'],
  sameTrialPoNumbers: string[],
) => {
  if (poNumber && sameTrialPoNumbers.includes(poNumber)) {
    return 'The PO# already exists for another vendor in this trial. Please enter a different value';
  }

  return '';
};

export const validateAccountingInfoForm = (
  accountInfo: AccountingInfo,
  allCategories: string[] | undefined,
) => {
  const errors: ContractVersionErrors = {};
  if (accountInfo.useCnfThreshold) {
    if (!accountInfo.cnfThresholdPercentage) {
      errors.cnf_threshold_percentage = 'Both thresholds are required';
    } else if (
      accountInfo.cnfThresholdPercentage < 0 ||
      accountInfo.cnfThresholdPercentage > 100
    ) {
      errors.cnf_threshold_percentage =
        'CNF Threshold Percentage should be between 0 and 100';
    }
    if (!accountInfo.cnfThresholdAmount) {
      errors.cnf_threshold_amount = 'Both thresholds are required';
    }
  }

  accountInfo.bottomLineAdjustments.forEach((adjustment, index) => {
    const errorObj = validateBottomLineAdjustment(adjustment);
    if (Object.keys(errorObj).length > 0) {
      errors.adjustmentErrors ||= {};
      errors.adjustmentErrors[index] = errorObj;
    }
  });

  if (
    !accountInfo.costCategoryInfos ||
    accountInfo.costCategoryInfos.length === 0
  ) {
    errors.gl_accounts_per_category = [
      { gl_account: 'At least one GL account is required' },
    ];
  } else {
    accountInfo.costCategoryInfos.forEach((costCategoryInfo, index) => {
      const errorObj = validateContractCostCategoryInfo(
        costCategoryInfo,
        index,
        allCategories,
      );
      if (Object.keys(errorObj).length > 0) {
        errors.gl_accounts_per_category ||= [];
        errors.gl_accounts_per_category[index] = errorObj;
      }
    });
  }

  return errors;
};

export const validateDocuments = (documents: ContractFile[]) => {
  const errors: Record<string, string> = {};
  if (documents.length === 0) {
    errors.documents = 'At least one document is required';
  }
  documents.forEach((document, index) => {
    if (!document.tag) {
      errors[`documents[${index}].tag`] = 'Document tag is required';
    }
  });
  return errors;
};

export const mapBlaValidations = (
  errors: BottomLineAdjustmentErrors | undefined,
) => {
  if (typeof errors === 'undefined' || !Object.keys(errors).length) {
    return '';
  }

  return Object.entries(errors)
    .map(([key, value]) => {
      const message = Array.isArray(value)
        ? value.join(', ')
        : JSON.stringify(value);
      switch (key) {
        case 'amount':
          return `Amount: ${message}`;
        case 'amount_percentage':
          return `Percentage: ${message}`;
        case 'adjustment_type':
          return `Adjustment type: ${message}`;
        default:
          return `${key}: ${message}`;
      }
    })
    .join(' ');
};
