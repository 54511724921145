import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  type Edge,
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';

type DraggableState =
  | { type: 'dragging' }
  | { type: 'idle' }
  | { type: 'preview'; container: HTMLElement };

const idleState: DraggableState = { type: 'idle' };
const draggingState: DraggableState = { type: 'dragging' };

function useDnd(
  index: number,
  type: string,
  element: RefObject<HTMLElement>,
  dragHandle: RefObject<HTMLElement>,
) {
  const [draggableState, setDraggableState] =
    useState<DraggableState>(idleState);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

  useEffect(() => {
    const data = { index, type };

    if (!element.current || !dragHandle.current) {
      return;
    }

    return combine(
      draggable({
        element: dragHandle.current,
        getInitialData: () => data,
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: '16px',
              y: '8px',
            }),
            render({ container }) {
              setDraggableState({ type: 'preview', container });

              return () => setDraggableState(draggingState);
            },
          });
        },
        onDragStart() {
          setDraggableState(draggingState);
        },
        onDrop() {
          setDraggableState(idleState);
        },
      }),
      dropTargetForElements({
        element: element.current,
        canDrop({ source }) {
          return source.data.type === type;
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element: element.current!,
            input,
            allowedEdges: ['top', 'bottom'],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element.current;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdgeFromData = extractClosestEdge(self.data);

          const sourceIndex = source.data.index as number;

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdgeFromData === 'bottom') ||
            (isItemAfterSource && closestEdgeFromData === 'top');

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdgeFromData);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [index, element, dragHandle]);

  return { draggableState, closestEdge };
}

export default useDnd;
