import { useState } from 'react';

import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

type Props = {
  children?: React.ReactNode;
  sx?: SxProps;
  onChangeFiles: (files: File[]) => void;
};

function DragAndDropWrapper(props: Props) {
  const { onChangeFiles, children, sx } = props;
  const [dragEnter, setDragEnter] = useState<boolean>(false);

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    onChangeFiles(files);
    setDragEnter(false);
  }

  function onDragEnter(event: React.DragEvent<HTMLDivElement>) {
    setDragEnter(true);
    event.preventDefault();
    event.stopPropagation();
  }

  function onDragLeave(event: React.DragEvent<HTMLDivElement>) {
    setDragEnter(false);
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        border: 1,
        borderColor: 'grey.300',
        borderStyle: 'dashed',
        p: 2,
        ...(dragEnter && { boxShadow: 3, borderColor: 'success.dark' }),
        ...sx,
      }}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragEnter}
      onDrop={handleDrop}
    >
      {children}
    </Box>
  );
}

export default DragAndDropWrapper;
