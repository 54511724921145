import { type ReactNode, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import type { AuthenticationResult } from '@auth0/auth0-spa-js/src/global';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';

import config from 'config';

import { useGetCurrentUserQuery } from 'shared/api/rtkq/users';

import NoPermissions from '../../components/no-permissions/NoPermissions';
import NotAuthorized from '../../components/not-authorized/NotAuthorized';
import ResetPassword from '../../components/reset-password/ResetPassword';
import UserBlocked from '../../components/user-blocked/UserBlocked';
import VerifyUser from '../../components/verify-user/VerifyUser';

type Props = { children: ReactNode };

const ERROR_ACCESS_DENIED = 'access_denied';
const ERROR_DESCRIPTION_RESET_PASSWORD = 'Your password has expired';

// copied from node_modules/@auth0/auth0-spa-js/src/utils.ts as Auth0 doesn't expose it
const parseAuthenticationResult = (
  queryString: string,
): AuthenticationResult => {
  if (queryString.includes('#')) {
    queryString = queryString.substring(0, queryString.indexOf('#'));
  }

  const searchParams = new URLSearchParams(queryString);

  return {
    state: searchParams.get('state') ?? '',
    code: searchParams.get('code') ?? undefined,
    error: searchParams.get('error') ?? undefined,
    error_description: searchParams.get('error_description') ?? undefined,
  };
};

export default function AuthContainer(props: Props): ReactNode | undefined {
  const { children } = props;

  const { isLoading, isAuthenticated, user, logout, loginWithRedirect } =
    useAuth0();

  const {
    currentData: djangoUser,
    error: djangoError,
    isUninitialized: userUninitialized,
    isLoading: userIsLoading,
  } = useGetCurrentUserQuery('');

  const currentUserIsLoading = userIsLoading || userUninitialized;

  const location = useLocation();

  // this is quite dumb, but this is literally how Auth0 does it too :(
  const authResult = parseAuthenticationResult(window.location.search);
  const isResetPasswordError =
    authResult.error === ERROR_ACCESS_DENIED &&
    authResult.error_description?.startsWith(ERROR_DESCRIPTION_RESET_PASSWORD);

  const userId = user?.sub;
  const isMixpanelEnabled = config.VITE_APP_ENABLE_MIXPANEL === 'true';
  const isFullstoryEnabled = config.VITE_APP_ENABLE_FULLSTORY === 'true';

  // log the user out after 15 minutes
  useIdleTimer({
    timeout: 900_000,
    onIdle: () => {
      void (async () => logout())();
    },
  });

  // copy the logic from /node_modules/@auth0/auth0-react/src/with-authentication-required.tsx
  // but allow us to intercept the error and check what it was so we can handle it better
  useEffect(() => {
    if (isLoading || isAuthenticated || isResetPasswordError) {
      return;
    }

    void (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.hash.substring(1),
        },
      });
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect, isResetPasswordError]);

  useEffect(() => {
    if (isAuthenticated && isFullstoryEnabled && userId) {
      window.FS.identify(userId);
    }
  }, [isAuthenticated, isFullstoryEnabled, userId]);

  useEffect(() => {
    if (isAuthenticated && isMixpanelEnabled && userId) {
      window.mixpanel.identify(userId);
    }
  }, [isAuthenticated, isMixpanelEnabled, userId]);

  useEffect(() => {
    // ensure nothing is tracked until we have called identify
    if (isAuthenticated && isMixpanelEnabled && userId) {
      window.mixpanel.track_pageview();
    }
    // location is here so every time the location changes, we track a new page view
  }, [isAuthenticated, location, isMixpanelEnabled, userId]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (djangoUser !== undefined || djangoError !== undefined)
    ) {
      window.removeRoller();
    }
  }, [djangoUser, djangoError, isAuthenticated]);

  if (isResetPasswordError) {
    return <ResetPassword />;
  }

  if (!currentUserIsLoading && !user?.email_verified) {
    return <VerifyUser userId={userId} />;
  }

  if (!currentUserIsLoading && djangoError !== undefined) {
    if ('status' in djangoError) {
      const data = djangoError.data as
        | { detail: string; code: string }
        | undefined;
      // Refer to CondorJWTUserAuthentication in hanaq to see the possible error codes
      if (data?.code === 'user_has_no_permissions') {
        return <NoPermissions />;
      }
      if (data?.code === 'user_blocked') {
        return <UserBlocked />;
      }
    }
    return <NotAuthorized />;
  }

  if (!currentUserIsLoading && djangoUser !== undefined) {
    return children;
  }

  return undefined;
}
