import type {
  AdministrativeOrProcedureCategoryRequest,
  AdministrativeOrProcedureCategoryResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'administrativeorprocedurecategories';
export const ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG =
  'ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES';

const apiEndpointFactory = constructApi<
  AdministrativeOrProcedureCategoryResponse,
  AdministrativeOrProcedureCategoryRequest
>(PATH).withTags(ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG);
const api = apiEndpointFactory.inject(() => ({
  createAdministrativeOrProcedureCategory: apiEndpointFactory.create(),
  updateAdministrativeOrProcedureCategory: apiEndpointFactory.update(),
  deleteAdministrativeOrProcedureCategory: apiEndpointFactory.delete(),
  getAdministrativeOrProcedureCategoriesByTrial:
    apiEndpointFactory.getBy('trial'),
}));

export const {
  useCreateAdministrativeOrProcedureCategoryMutation,
  useUpdateAdministrativeOrProcedureCategoryMutation,
  useDeleteAdministrativeOrProcedureCategoryMutation,
  useGetAdministrativeOrProcedureCategoriesByTrialQuery,
  useLazyGetAdministrativeOrProcedureCategoriesByTrialQuery,
} = api;
