import type { ReactElement } from 'react';

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import useExpenseRowData from '../../shared/hooks/useExpenseRowData';
import useCroContractBudgetColumnDefs from '../hooks/useCroContractBudgetColumnDefs';
import useCroContractBudgetGridOptions from '../hooks/useCroContractBudgetGridOptions';
import useCroContractBudgetRows from '../hooks/useCroContractBudgetRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function CroCurrentBudgetGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;
  const gridOptions = useGridOptions(useCroContractBudgetGridOptions);
  const columnDefs = useGridColDefs(useCroContractBudgetColumnDefs);
  const rows = useCroContractBudgetRows();
  const rowData = useExpenseRowData(rows);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  CroCurrentBudgetGrid,
  PeriodGridBlobType.CRO_CONTRACT_BUDGET,
);
