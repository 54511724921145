import type {
  BaseResponseFields,
  ClosedPeriodExpensesUpsert,
} from 'shared/lib/types';

import { CLOSED_PERIOD_EXPENSES } from './apiTags';
import constructApi from './constructApi';

const apiEndpointFactory = constructApi<BaseResponseFields>(
  'closedperiodreconciledexpenses',
).withTags(CLOSED_PERIOD_EXPENSES);
const api = apiEndpointFactory.inject(() => ({
  upsertClosedPeriodExpenses:
    apiEndpointFactory.create<ClosedPeriodExpensesUpsert>([], 'upsert'),
}));

export const { useUpsertClosedPeriodExpensesMutation } = api;
