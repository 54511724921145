import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { SiteCostGridResponse, SiteGridRow } from 'shared/lib/types';

import { useGetSiteCostsQuery } from 'shared/api/rtkq/periods';

import contractDateValidator from '../grids/contractDateValidator';

export function processSiteCostGrid(
  siteCostGridResponse: SiteCostGridResponse | undefined,
) {
  return cloneDeep(contractDateValidator(siteCostGridResponse?.rows));
}

export default function useSiteCostMatrixRows(): SiteGridRow[] | undefined {
  const period = useSelector(selectPeriod);
  const { currentData: sites } = useGetSiteCostsQuery(period.trace_id);

  return useMemo(() => processSiteCostGrid(sites), [sites]);
}
