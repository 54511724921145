import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import getSharedExpenseSummaryColumnDefs from 'accruals/pages/clinical-expenses/shared/hooks/getSharedExpenseSummaryColumnDefs';
import type { SharedContractExpenseSummaryRow } from 'shared/lib/types';

function useClinicalExpenseSummaryColumnDefs(
  isOpenPeriod: boolean,
): Array<CondorColDef<SharedContractExpenseSummaryRow>> {
  return useMemo(
    () => [
      // { field: 'contract_container_trace_id', hide: true }, // TODO(tyler): This is UUID, not slug, so don't expose until we fix. We only need this for linking to a contract's details page.
      {
        valueGetter:
          "node.footer ? undefined : node.data?.vendor_type === 'CRO' ? node.data.vendor_name : 'Other Clinical Contracts'",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: 'Contract',
        flex: 1,
        valueGetter:
          "node.footer ? undefined : node.data?.vendor_type === 'CRO' ? node.data.cost_category : node.data?.vendor_name",
        valueFormatter: 'humanizeCostCategoryWithMixedVendors',
      },
      {
        headerName: 'PO #(s)',
        flex: 1,
        valueGetter: `node.group && !node.footer && node.childrenAfterGroup?.[0].data?.vendor_type === 'CRO'
            ? node.childrenAfterGroup[0].data?.po_numbers
            : node.data != null && !node.footer && node.data.vendor_type === 'OCC'
            ? node.data.po_numbers
            : undefined`,
      },
      ...getSharedExpenseSummaryColumnDefs(isOpenPeriod, true),
    ],
    [isOpenPeriod],
  );
}

export default useClinicalExpenseSummaryColumnDefs;
