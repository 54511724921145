/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

This slice should be used in conjunction with the PeriodRequiredLayout component
that knows how to handle when period is null / undefined, so we can assume
that period is always defined inside that element, greatly simplifying the codebase.

If you use this slice outside of the PeriodRequiredLayout context, it will NOT handle 
the "missing period" case correctly and is likely a sign you are doing something wrong 
(or need to move where PeriodRequiredLayout is being used in the routes).

If you have any questions, please ask in #engineering
*/

import { useCallback } from 'react';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import type { RootState } from 'shared/state/store';
import type { PeriodResponse, TraceId } from 'shared/lib/types';
import { getPeriodSafeKeys } from 'shared/state/slices/utils';

import baseApi from 'shared/api/baseApi';
import { getRegisteredTags } from 'shared/api/rtkq/constructApi';

import { useReinitializePeriodVersion } from './periodVersionSlice';

type State = {
  period: PeriodResponse;
  initialized: boolean;
  forcePeriodVersionReload: boolean;
};

export const missingPeriod = null as unknown as PeriodResponse;
const makePeriodKey = (trialTraceId: TraceId) => `trial_${trialTraceId}_period`;

const initialState: State = {
  period: missingPeriod,
  initialized: false,
  forcePeriodVersionReload: false,
};

const periodSlice = createSlice({
  name: 'period',
  initialState,
  reducers: {
    // ideally this would have no side effects, but this is the best place to get access to current state
    changePeriod: (state, action: PayloadAction<PeriodResponse>) => {
      const period = action.payload;

      // only remove the old period if we are already initialized and are trying to clear it intentionally.
      // this will allow us to re-initialize the state (such as when trial changes) without losing the current period
      if (
        state.initialized &&
        period === missingPeriod &&
        state.period !== missingPeriod
      ) {
        const existingKey = makePeriodKey(state.period.trial);
        localStorage.removeItem(existingKey);
      }

      state.period = period;
    },
    changeInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    changeForcePeriodVersionReload: (state, action: PayloadAction<boolean>) => {
      state.forcePeriodVersionReload = action.payload;
    },
  },
});

export const selectPeriod = (state: RootState) => state.period.period;
export const selectPeriodInitialized = (state: RootState) =>
  state.period.initialized;
export const selectPeriodForcePeriodVersionReload = (state: RootState) =>
  state.period.forcePeriodVersionReload;

const { changePeriod, changeInitialized, changeForcePeriodVersionReload } =
  periodSlice.actions;

export const periodReducer = periodSlice.reducer;

export function useChangePeriod() {
  const dispatch = useDispatch();
  const reInitPeriodVersion = useReinitializePeriodVersion();

  return useCallback(
    (period: PeriodResponse | null, isInit = false) => {
      if (period === null) {
        dispatch(changePeriod(missingPeriod));
      } else {
        localStorage.setItem(makePeriodKey(period.trial), period.trace_id);
        dispatch(changePeriod(period));
      }

      // clear all existing RTKQ state to guarantee we're not using stale data, although we
      // don't clear "safe" keys that cannot change, so it reduces loading states
      //
      // additionally, re-initialize the things "inside" period so everything updates correctly
      if (!isInit) {
        reInitPeriodVersion();

        const tagsToClear = getRegisteredTags().filter(
          (tag) => !getPeriodSafeKeys().includes(tag),
        );
        dispatch(baseApi.util.invalidateTags(tagsToClear));
      }
    },
    [dispatch, reInitPeriodVersion],
  );
}

export function useInitializePeriod() {
  const change = useChangePeriod();
  const dispatch = useDispatch();

  return useCallback(
    (periods: PeriodResponse[] | undefined) => {
      dispatch(changeInitialized(true));

      // if we have no returned period, ensure we update state in case what was present was for a company they don't have access to
      let period = missingPeriod;
      if (periods?.length) {
        const previousPeriodId =
          localStorage.getItem(makePeriodKey(periods[0].trial)) ?? null;
        const previousPeriod = periods.find(
          (comp) => comp.trace_id === previousPeriodId,
        );
        const openPeriods = periods.filter((per) => !per.is_closed);

        // If we didn't find a relevant selected trial in local storage, arbitrarily default to the latest open period, followed by any period, if none open
        period =
          previousPeriod ?? (openPeriods.length ? openPeriods[0] : periods[0]);
      }

      change(period, true);
    },
    [change, dispatch],
  );
}

export function useReinitializePeriod() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(changeInitialized(false));
    dispatch(changePeriod(missingPeriod));
  }, [dispatch]);
}

export function useForcePeriodVersionReload() {
  const dispatch = useDispatch();

  return useCallback(
    (force: boolean) => {
      dispatch(changeForcePeriodVersionReload(force));
    },
    [dispatch],
  );
}
