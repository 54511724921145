import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useNavigate } from 'react-router-dom';

import Dropdown from 'shared/ui/dropdown/Dropdown';

import { getCompanyDashboard } from 'routes';
import type { CompanyResponse } from 'shared/lib/types';
import { useChangeCompany } from 'shared/state/slices/companySlice';

type Props = { companies: CompanyResponse[]; company: CompanyResponse };

function CompanySelector(props: Props) {
  const { company, companies } = props;

  const navigate = useNavigate();
  const changeCompany = useChangeCompany();

  return companies.length > 1 ? (
    <Dropdown
      title={company.name}
      buttonProps={{
        testId: 'company_selector',
        endIcon: <KeyboardArrowDownOutlinedIcon />,
        variant: 'text',
      }}
      options={companies.map((com, i) => ({
        value: i.toString(),
        label: com.name,
      }))}
      onSelectOption={(option) => {
        changeCompany(companies[+option.value]);
        navigate(getCompanyDashboard());
      }}
    />
  ) : null;
}

export default CompanySelector;
