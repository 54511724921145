import type { PaletteOptions } from '@mui/material/styles/createPalette';

import colors from 'colors.module.scss';

export const darkPalette = {
  mode: 'dark',
  common: { black: colors.white, white: colors.black },
  primary: { main: colors.medium, light: colors.dark, dark: colors.light },
  secondary: {
    main: colors.secondaryMain,
    light: colors.secondaryDark,
    dark: colors.secondaryLight,
  },
  text: {
    primary: colors.primaryTextDarkTheme,
    secondary: colors.secondaryTextDarkTheme,
    disabled: colors.disabledTextDarkTheme,
  },
  grey: {
    50: colors.n950,
    100: colors.n900,
    300: colors.n700,
    500: colors.n500,
    700: colors.n300,
    900: colors.n100,
  },
  background: { default: colors.n950, paper: colors.black },
  error: {
    main: colors.errorMedium,
    light: colors.errorDark,
    dark: colors.errorLight,
  },
  warning: {
    main: colors.warningMedium,
    light: colors.warningDark,
    dark: colors.warningLight,
  },
  success: {
    main: colors.successMedium,
    light: colors.successDark,
    dark: colors.successLight,
  },
  info: {
    main: colors.infoMedium,
    light: colors.infoDark,
    dark: colors.infoLight,
  },
  divider: colors.dividerDarkTheme,
} satisfies PaletteOptions;

export const lightPalette = {
  mode: 'light',
  common: { black: colors.black, white: colors.white },
  primary: { main: colors.main, light: colors.light, dark: colors.dark },
  secondary: {
    main: colors.secondaryMain,
    light: colors.secondaryLight,
    dark: colors.secondaryDark,
  },
  text: {
    primary: colors.primaryTextLightTheme,
    secondary: colors.secondaryTextLightTheme,
    disabled: colors.disabledTextLightTheme,
  },
  grey: {
    50: colors.n50,
    100: colors.n100,
    300: colors.n300,
    500: colors.n500,
    700: colors.n700,
    900: colors.n900,
  },
  background: { default: colors.n50, paper: colors.white },
  error: {
    main: colors.errorMain,
    light: colors.errorLight,
    dark: colors.errorDark,
  },
  warning: {
    main: colors.warningMain,
    light: colors.warningLight,
    dark: colors.warningDark,
  },
  success: {
    main: colors.successMain,
    light: colors.successLight,
    dark: colors.successDark,
  },
  info: {
    main: colors.infoMain,
    light: colors.infoLight,
    dark: colors.infoDark,
  },
  divider: colors.dividerLightTheme,
} satisfies PaletteOptions;
