import FxRate from 'shared/components/fx-rate/FxRate';

import { getCompany } from 'routes';

import withCompanyPageRequiredData from 'shared/api/hocs/withCompanyPageRequiredData';

function FxRatePage() {
  return <FxRate basePath={getCompany()} />;
}

export default withCompanyPageRequiredData(FxRatePage);
