import type { SxProps } from '@mui/material/styles';

import type { UserDisplayOptions } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { PeriodGridBlobType } from 'shared/lib/types';

import useCroExpenseSummaryColumnDefs from '../hooks/useCroExpenseSummaryColumnDefs';
import useCroExpenseSummaryGridOptions from '../hooks/useCroExpenseSummaryGridOptions';
import useCroExpenseSummaryRows from '../hooks/useCroExpenseSummaryRows';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

function CroExpenseSummaryGrid(props: Props) {
  const { sx, overlayNoRowsTemplate, userDisplayOptions } = props;
  const { contractContainerTraceId } = useContractAndPeriodWithVersions();
  const { rows } = useCroExpenseSummaryRows(contractContainerTraceId);

  const columnDefs = useGridColDefs(useCroExpenseSummaryColumnDefs);
  const gridOptions = useGridOptions(useCroExpenseSummaryGridOptions);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rows}
        sx={sx}
        userDisplayOptions={userDisplayOptions}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  CroExpenseSummaryGrid,
  PeriodGridBlobType.CRO_EXPENSE_SUMMARY,
);
