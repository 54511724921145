import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

const DEFAULT_COL_DEF: CondorColDef = {
  sortable: true,
  resizable: false,
  filter: false,
  flex: 1,
};

const COLUMN_DEFS: Array<CondorColDef | CondorColGroupDef> = [
  {
    headerName: 'Group 1',
    children: [
      { field: 'invoiceNumber' },
      { field: 'vendor' },
      { headerName: 'PO #', field: 'poNumber' },
    ],
  },
  {
    headerName: 'Group 2',
    children: [
      { field: 'invoiceAmount' },
      { field: 'glAccount' },
      { field: 'description' },
      { field: 'invoiceDate' },
    ],
  },
];

const ROW_DATA = [
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '5/17/2023',
    invoiceNumber: '98766.0',
    poNumber: '66735.0',
    vendor: 'DUMMY_VENDOR',
  },
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '5/17/2023',
    invoiceNumber: '98766.0',
    poNumber: '66735.0',
    vendor: 'DUMMY_VENDOR',
  },
  {
    description: 'Test',
    glAccount: ' 234545234-0',
    invoiceAmount: 757435345,
    invoiceDate: '5/17/2023',
    invoiceNumber: '98766.0',
    poNumber: '66735.0',
    vendor: 'DUMMY_VENDOR',
  },
];

function AgGridColumnGroupsDemo() {
  const rowData = useMemo(() => cloneDeep(ROW_DATA), []);

  return (
    <CondorAgGrid
      columnDefs={COLUMN_DEFS} // This is already a constant so does not need to be memoized
      defaultColDef={DEFAULT_COL_DEF} // This is already a constant so does not need to be memoized
      rowData={rowData}
      sx={useMemo(() => ({ height: 450 }), [])}
    />
  );
}

export default AgGridColumnGroupsDemo;
