import { useTheme } from '@mui/material/styles';
import Line from '@visx/shape/lib/shapes/Line';

type Props = {
  from: Partial<SVGPoint>;
  stroke?: string;
  to: Partial<SVGPoint>;
};

function ZeroLine(props: Props): JSX.Element {
  const theme = useTheme();
  const { from, to, stroke = theme.palette.grey['600'] } = props;
  return (
    <Line
      from={from}
      stroke={stroke}
      strokeWidth="1"
      to={to}
      style={{
        mixBlendMode: theme.palette.mode === 'light' ? 'multiply' : 'normal',
      }}
    />
  );
}

export default ZeroLine;
