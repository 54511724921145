import type { AxisScale } from '@visx/axis/lib/types';
import type {
  CombinedStackData,
  SeriesProps,
} from '@visx/xychart/lib/types/series';

/** Returns the value which forms a stack group. */
export const getStackValue = <
  XScale extends AxisScale,
  YScale extends AxisScale,
>(
  datum: Pick<CombinedStackData<XScale, YScale>, 'stack'>,
) => datum.stack;

/**
 * Merges `seriesChildren` `props.data` by their `stack` value which
 * forms the stack grouping (`x` if vertical, `y` if horizontal)
 * and returns `CombinedStackData[]`.
 */
export default function combineBarStackData<
  XScale extends AxisScale,
  YScale extends AxisScale,
  Datum extends object,
>(
  props: Omit<SeriesProps<XScale, YScale, Datum>, 'dataKey'> & {
    dataKeys: readonly string[];
    horizontal?: boolean;
  },
): Array<CombinedStackData<XScale, YScale>> {
  const { dataKeys, data, xAccessor, yAccessor, horizontal = false } = props;
  const dataByStackValue: {
    [stackValue: string]: CombinedStackData<XScale, YScale>;
  } = {};

  const [stackFn, valueFn] = horizontal
    ? [yAccessor, xAccessor]
    : [xAccessor, yAccessor];
  for (const dataKey of dataKeys) {
    for (const datum of data) {
      const stack = stackFn(datum);
      const numericValue = valueFn({
        data: (datum as Record<string, number>)[dataKey],
      } as Datum);
      const stackKey = String(stack);
      if (!(stackKey in dataByStackValue)) {
        dataByStackValue[stackKey] = { stack, positiveSum: 0, negativeSum: 0 };
      }
      dataByStackValue[stackKey][dataKey] = numericValue;
      dataByStackValue[stackKey][
        numericValue >= 0 ? 'positiveSum' : 'negativeSum'
      ] += numericValue;
    }
  }

  return Object.values(dataByStackValue);
}
