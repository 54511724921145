import { useMemo } from 'react';

import { getTrialMoneyCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

export default function usePoCompletenessColumnDefs(
  _isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      { field: 'traceId', hide: true },
      { field: 'vendor', rowGroup: true, flex: 1, hide: true },
      { headerName: 'PO #', field: 'poNumber', flex: 1 },
      {
        headerName: 'PO Amount',
        colId: 'poAmount',
        type: 'rightAligned',
        flex: 1,
        ...getTrialMoneyCellConfig({ useEmDashInGroup: true }),
        aggFunc: 'poCompletenessConfig.poAmount.aggFunc',
        valueGetter: 'node?.level === 0 ? undefined : data?.poAmount',
      },
      {
        headerName: 'Total contract value',
        type: 'rightAligned',
        colId: 'total',
        flex: 1,
        ...getTrialMoneyCellConfig({
          useEmDash: false,
          useEmDashInGroup: true,
        }),
        cellStyle: 'highlightGroup',
        aggFunc: 'poCompletenessConfig.totalContractValue.aggFunc',
        valueGetter:
          "node?.level !== 0 ? '' : node.allLeafChildren[0]?.data?.total",
      },
      {
        headerName: 'PO Check',
        cellRenderer: 'AgGridCustomCellRenderer',
        colId: 'poCheck',
        cellRendererParams: 'poCompletenessPoCheck' as const,
        flex: 5,
        valueGetter:
          "node?.level === 1 ? data?.completenessStatus === 'ADDED' || data?.completenessStatus === 'ADDED_TO_TRIAL' ? 'Found in PO listing' : 'Not found in PO listing' : undefined",
      },
    ],
    [],
  );
}
