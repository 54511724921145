import { type ReactElement, useState } from 'react';

import UploadIcon from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import withReadOnlyPermissionWrapper from 'shared/lib/read-only-permission/withReadOnlyPermissionWrapper';
import { selectCompany } from 'shared/state/slices/companySlice';
import PurchaseOrdersUploadWizard from 'shared/wizards/purchase-order/PurchaseOrdersUploadWizard';

function PurchaseOrdersHeader(): ReactElement {
  const currentCompany = useSelector(selectCompany);
  const [showUploadWizard, setShowUploadWizard] = useState<boolean>(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          startIcon={<UploadIcon />}
          testId="upload_po_listing"
          variant="contained"
          onClick={() => setShowUploadWizard(true)}
        >
          Upload PO Listing
        </Button>
      </Box>
      {showUploadWizard && (
        <PurchaseOrdersUploadWizard
          companyTraceId={currentCompany.trace_id}
          onClose={() => setShowUploadWizard(false)}
        />
      )}
    </>
  );
}

export default withReadOnlyPermissionWrapper(
  withPeriodSpecificGenericWrapper(PurchaseOrdersHeader),
);
