import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import Wizard from 'shared/lib/wizard/Wizard';

import ProcedureCategoriesStep from './steps/ProcedureCategoriesStep';

type Props = { onClose: () => void };

export default function ProceduresWizard(props: Props) {
  const { onClose } = props;

  return (
    <FullWidthDialog open>
      <Wizard devTitle="LOKI" title="Procedure Categories" onClose={onClose}>
        <ProcedureCategoriesStep />
      </Wizard>
    </FullWidthDialog>
  );
}
