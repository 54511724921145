import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { TraceId } from 'shared/lib/types';
import type { RootState } from 'shared/state/store';

type State = { editSiteTraceId: TraceId | null };

const initialState: State = { editSiteTraceId: null };

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    closeEditSite: (state) => {
      state.editSiteTraceId = null;
    },
    openEditSite: (state, action: PayloadAction<{ traceId: TraceId }>) => {
      state.editSiteTraceId = action.payload.traceId;
    },
  },
});

export const selectEditSiteTraceId = (state: RootState) =>
  state.site.editSiteTraceId;

export const { closeEditSite, openEditSite } = siteSlice.actions;

export const siteReducer = siteSlice.reducer;
