import { useCallback, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Modal from 'shared/components/modal/Modal';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import Select from 'shared/ui/select/Select';

import ForecastPeriodSelector from 'forecasting/pages/forecasting/components/ForecastPeriodSelector';
import { useChangeForecast } from 'forecasting/state/slices/forecastSlice';
import * as routes from 'routes';
import useInputErrors from 'shared/lib/sidebar/hooks/useInputErrors';
import type { ForecastRequest } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetForecastFoldersByTrialQuery } from 'shared/api/rtkq/forecastfolders';
import {
  useCreateForecastMutation,
  useGetForecastsByTrialQuery,
} from 'shared/api/rtkq/forecasts';
import { useGetPeriodsByTrialQuery } from 'shared/api/rtkq/periods';

type Props = {
  onClose: () => void;
};

const NO_FOLDER_SELECTED = '-1';

function CreateForecastModal({ onClose }: Props) {
  const trial = useSelector(selectTrial);
  const [selectedPeriodId, setSelectedPeriodId] = useState<string>('');
  const [selectedFolderId, setSelectedFolderId] =
    useState<string>(NO_FOLDER_SELECTED);
  const [nameInput, setNameInput] = useState<string>('');
  const { inputErrors, markInputError } = useInputErrors();
  const [createForecast, { isLoading: isCreatingForecast }] =
    useCreateForecastMutation();
  const navigate = useNavigate();
  const changeForecast = useChangeForecast();

  const { currentData: periods } = useGetPeriodsByTrialQuery(trial.trace_id);
  const { currentData: folders } = useGetForecastFoldersByTrialQuery(
    trial.trace_id,
  );
  const { currentData: existingForecasts } = useGetForecastsByTrialQuery(
    trial.trace_id,
  );

  const handleCreate = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    let inputErrorsFound = false;
    if (!nameInput.trim()) {
      inputErrorsFound = true;
      markInputError('name');
    } else if (
      existingForecasts?.some(
        (existingForecast) => existingForecast.name === nameInput,
      )
    ) {
      inputErrorsFound = true;
      markInputError('name', 'A forecast with this name already exists.');
    }
    if (inputErrorsFound) {
      return;
    }

    void (async () => {
      if (selectedPeriodId) {
        const forecastParams: ForecastRequest = {
          trial: trial.trace_id,
          name: nameInput,
          start_period: selectedPeriodId,
        };
        if (selectedFolderId !== NO_FOLDER_SELECTED) {
          forecastParams.folder = selectedFolderId;
        }

        try {
          const forecast = await createForecast(forecastParams).unwrap();

          changeForecast(forecast);
          navigate(routes.getSpecificForecast(forecast.trace_id));

          onClose();
          setNameInput('');
        } catch (error) {
          // Handle server error for createForecast
          if (typeof error === 'object' && error && 'data' in error) {
            const { data } = error as { data: unknown };
            // This expects the error to be in the format of
            // { errors: { "name of field with error": "error message" } }
            if (
              data &&
              typeof data === 'object' &&
              'errors' in data &&
              typeof error === 'object'
            ) {
              const { errors } = data as { errors: Record<string, string> };
              for (const [field, message] of Object.entries(errors)) {
                markInputError(field, message);
              }
            }
          }
        }
      }
    })();
  }, [
    nameInput,
    existingForecasts,
    markInputError,
    selectedPeriodId,
    trial.trace_id,
    selectedFolderId,
    createForecast,
    changeForecast,
    navigate,
    onClose,
  ]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameInput(event.target.value);
    if (inputErrors.name) {
      markInputError('name', false);
    }
  };

  const handlePeriodChange = (periodId: string) => {
    setSelectedPeriodId(periodId);
    if (inputErrors.period) {
      markInputError('period', false);
    }
  };

  const handleFolderChange = (event: React.ChangeEvent<{ value: string }>) =>
    setSelectedFolderId(event.target.value);

  const handleCloseModal = () => {
    onClose();
    setNameInput('');
    setSelectedPeriodId('');
    setSelectedFolderId('');
  };

  return (
    <Modal
      handleClose={handleCloseModal}
      title="New forecast"
      ButtonProps={{
        label: 'Create',
        testId: 'ForecastModalCreate',
        loading: isCreatingForecast,
        disabled:
          !nameInput ||
          !selectedPeriodId ||
          !!inputErrors.name ||
          !!inputErrors.period,
        onClick: handleCreate,
      }}
      isOpen
    >
      <Stack gap={2} mt={1}>
        <FormControl sx={{ width: '100%' }}>
          <CondorTextField
            autoComplete="off"
            errors={inputErrors.name}
            label="Name"
            name="name"
            type="text"
            value={nameInput}
            required
            onChange={handleNameChange}
          />
        </FormControl>
        {periods && (
          <FormControl>
            <ForecastPeriodSelector
              errors={inputErrors.period}
              handlePeriodChange={handlePeriodChange}
              periods={periods}
              selectedPeriodId={selectedPeriodId}
            />
          </FormControl>
        )}
        <FormControl>
          <Select
            label="Folder"
            value={selectedFolderId}
            onChange={handleFolderChange}
          >
            <MenuItem value={NO_FOLDER_SELECTED}>
              <Typography>None</Typography>
            </MenuItem>
            {folders?.map(({ name, trace_id }) => (
              <MenuItem key={name} value={trace_id}>
                <Typography>{name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Modal>
  );
}

export default CreateForecastModal;
