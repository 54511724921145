import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/ui/tabs/NavigationTabs';

import {
  getClinicalExpensesSubTabByContractContainer,
  getClinicalExpensesTabByContractContainer,
} from 'routes';

function CroContractTabs() {
  const { contractContainerId, activeTab, activeSubTab } = useParams();

  const croContractTabs = [
    { key: 'overview', devLabel: 'FRANCE-CRO', label: 'Overview' },
    { key: 'direct-fees', devLabel: 'ODIN', label: 'Direct Fees' },
    { key: 'pass-throughs', devLabel: 'THOR', label: 'Pass-Throughs' },
    {
      key: 'investigator-fees',
      devLabel: 'SHERLOCK',
      label: 'Investigator Fees',
    },
    {
      key: 'current-contract',
      devLabel: 'PARIS-CRO',
      label: 'Current Contract',
    },
    {
      key: 'amendment-in-progress',
      devLabel: 'HAMILTON-new',
      label: 'Amendment-In-Progress',
    },
  ];

  return (
    <NavigationTabs
      activeTab={activeTab}
      tabs={croContractTabs}
      keyToUrl={(newTab) =>
        ['direct-fees', 'pass-throughs', 'investigator-fees'].includes(newTab)
          ? getClinicalExpensesSubTabByContractContainer(
              contractContainerId,
              newTab,
              activeSubTab ?? 'expense',
            )
          : getClinicalExpensesTabByContractContainer(
              contractContainerId,
              newTab,
            )
      }
    />
  );
}

export default CroContractTabs;
