import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import useJournalEntryColumnDefs from 'accruals/pages/journal-entry/hooks/useJournalEntryColumnDefs';
import useJournalEntryGridOptions from 'accruals/pages/journal-entry/hooks/useJournalEntryGridOptions';
import useJournalEntryRows from 'accruals/pages/journal-entry/hooks/useJournalEntryRows';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { PeriodGridBlobType } from 'shared/lib/types';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function JournalEntryGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;
  const columnDefs = useGridColDefs(useJournalEntryColumnDefs);
  const rowData = useJournalEntryRows();
  const gridOptions = useGridOptions(useJournalEntryGridOptions);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  JournalEntryGrid,
  PeriodGridBlobType.JOURNAL_ENTRY,
);
