import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { TrialSpendGraphGeneric } from 'accruals/components/graphing/trial-spend-graph/TrialSpendGraph';
import { TRIAL_SPEND_GRAPH_CONFIG } from 'accruals/components/graphing/trial-spend-graph/config';
import type {
  TrialSpendConfig,
  TrialSpendDatum,
} from 'accruals/components/graphing/trial-spend-graph/types';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { getJSONFromFile } from 'shared/helpers/helpers';
import type { PeriodGraphBlobResponse, TraceId } from 'shared/lib/types';
import { PeriodGraphBlobType } from 'shared/lib/types';

import { useGetPeriodGraphBlobQuery } from 'shared/api/rtkq/periodgraphblobs';

import type { GenericGraphProps } from '../../../shared/lib/periods/withPeriodSpecificGraphWrapper';

type Props = GenericGraphProps & {
  parentMenuItem?: TraceId;
  savedObjectType: PeriodGraphBlobType;
};

export default function PeriodGraphBlobGraph(props: Props): ReactNode {
  const { parentMenuItem, savedObjectType, height, width } = props;

  const [graphData, setGraphData] = useState<unknown>();
  const [graphOptions, setGraphOptions] = useState<unknown>();

  const periodVersion = useSelector(selectPeriodVersion);
  const { currentData: graphSnapshots } = useGetPeriodGraphBlobQuery({
    saved_object_type: savedObjectType,
    period_version: periodVersion.trace_id,
    parent_menu_item: parentMenuItem,
  });

  let graphSnapshot: PeriodGraphBlobResponse | undefined;
  if (graphSnapshots) {
    graphSnapshot = graphSnapshots[0];

    if (graphSnapshots.length > 1) {
      Sentry.captureMessage(
        "There should only ever be one graph snapshot, but we're getting more than one.",
      );
    }
  }

  useEffect(() => {
    void (async () => {
      if (graphSnapshot === undefined) {
        return;
      }

      if (graphSnapshot.graph_data) {
        setGraphData(await getJSONFromFile<unknown>(graphSnapshot.graph_data));
      }

      if (graphSnapshot.graph_options) {
        setGraphOptions(
          await getJSONFromFile<unknown>(graphSnapshot.graph_options),
        );
      }
    })();
  }, [graphSnapshot]);

  switch (savedObjectType) {
    case PeriodGraphBlobType.TRIAL_EXPENSE_AND_ENROLLMENT:
      if (graphData && graphOptions) {
        // default the closed period graph options to the trial spend graph options, but prefer what they were
        const mergedGraphOptions: TrialSpendConfig = {
          ...TRIAL_SPEND_GRAPH_CONFIG,
          ...graphOptions,
        };

        return (
          <TrialSpendGraphGeneric
            enrollmentScaleObj={mergedGraphOptions.enrollmentScaleObj}
            graphData={graphData as TrialSpendDatum[]}
            graphOptions={mergedGraphOptions}
            height={height}
            width={width}
          />
        );
      }
      break;
    default:
      return undefined;
  }
}
