import { useEffect, useRef, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query/react';
import { isAfter } from 'date-fns/isAfter';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';
import { useSelector } from 'react-redux';

import type {
  SiteFileRequest,
  SiteRecordRequest,
  SiteRecordResponse,
  TraceId,
} from 'shared/lib/types';
import { selectEditSiteTraceId } from 'shared/state/slices/siteSlice';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useCreateSiteContractMutation } from 'shared/api/rtkq/sitecontracts';
import { useGetSiteFilesQuery } from 'shared/api/rtkq/sitefiles';
import {
  useCreateSiteMutation,
  useGetSiteQuery,
  useLazyGetSitesByTrialQuery,
  useUpdateSiteMutation,
} from 'shared/api/rtkq/sites';

import useUploadSiteDocuments from '../hooks/useUploadSiteDocuments';
import AddSiteForm from './AddSiteForm';
import SiteDocuments from './SiteDocuments';

function AddSite() {
  const editSiteIsOpen = useSelector(selectEditSiteTraceId);
  const trial = useSelector(selectTrial);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [siteRecord, setSiteRecord] = useState<Partial<SiteRecordRequest>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [documentsList, setDocumentsList] = useState<SiteFileRequest[]>([]);
  const [uploadErrors, setUploadErrors] = useState<SiteFileRequest[]>([]);
  const { currentData: siteToEdit } = useGetSiteQuery(
    editSiteIsOpen ?? skipToken,
  );
  const { currentData: siteFiles } = useGetSiteFilesQuery(
    editSiteIsOpen ?? skipToken,
  );
  const [getSitesQuery, { isFetching: isFetchingSites }] =
    useLazyGetSitesByTrialQuery();
  const [createSite, { isLoading: isLoadingCreateSite }] =
    useCreateSiteMutation();
  const [updateSite, { isLoading: isLoadingUpdateSite }] =
    useUpdateSiteMutation();
  const [createSiteVersion, { isLoading: isLoadingCreateSiteVersion }] =
    useCreateSiteContractMutation();
  const { uploadSiteDocuments, uploading } = useUploadSiteDocuments();
  const ref = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setSiteRecord({ ...siteToEdit });
  }, [siteToEdit]);

  useEffect(() => {
    if (siteFiles !== undefined) {
      setDocumentsList(siteFiles);
    }
  }, [siteFiles]);

  function uniqueSiteNumber(number: string, sites: SiteRecordResponse[]) {
    const site = sites.find((site) => site.number === number);
    if (!site) {
      return true;
    }

    const message = 'This site number is already assigned to a site.';
    setErrors((state) => ({ ...state, number: message }));

    return false;
  }

  function onChangeField(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;
    setSiteRecord({ ...siteRecord, [name]: value });

    if (submitted) {
      updateValidation();
    }
  }

  function updateValidation() {
    const formErrors = getFormErrors() ?? {};
    setErrors(formErrors);
    return formErrors;
  }

  function getFormErrors() {
    const { current: form } = ref;
    if (!form) {
      return;
    }

    return {
      number: form.number.validationMessage,
      country: form.country.validationMessage,
      investigator_email: form.investigator_email.validationMessage,
      initiated_date: validateInitiatedDate(
        form.initiated_date.value,
        form.closed_date.value,
      )
        ? ''
        : 'Initiation Date cannot be after the Closed Date',
    };
  }

  function validateInitiatedDate(
    initiatedDateString: string,
    closeDateString: string,
  ) {
    if (!initiatedDateString || !closeDateString) {
      return true;
    }
    const initiatedDate = parse(initiatedDateString, 'yyyy-MM-dd', new Date());
    const closeDate = parse(closeDateString, 'yyyy-MM-dd', new Date());

    return (
      isValid(initiatedDate) &&
      isValid(closeDate) &&
      isAfter(closeDate, initiatedDate)
    );
  }

  function getDisableNextButton() {
    const { number, country } = siteRecord;
    return (
      [number, country].some((value) => typeof value === 'undefined') ||
      Object.values(errors).some((value) => !!value)
    );
  }

  async function onNextAsync() {
    const sites = await getSitesQuery(trial.trace_id).unwrap();
    const otherSites = sites.filter(
      (site) => site.trace_id !== siteRecord.trace_id,
    );
    if (!uniqueSiteNumber(siteRecord.number!, otherSites)) {
      throw new Error('Site number is used');
    }

    if (typeof siteRecord.trace_id !== 'undefined') {
      await updateSite({ trace_id: siteRecord.trace_id, ...siteRecord });

      await uploadDocuments(siteRecord.trace_id);
      return;
    }

    const result = await createSite({
      trial: trial.trace_id,
      ...siteRecord,
    }).unwrap();

    setSiteRecord((record) => ({ ...record, trace_id: result.trace_id }));
    await uploadDocuments(result.trace_id);

    await createSiteVersion({ site: result.trace_id });
  }

  async function uploadDocuments(siteTraceId: TraceId) {
    const newDocuments = documentsList.filter(
      (item) => typeof item.file !== 'string',
    );
    const uploadResult = await uploadSiteDocuments(siteTraceId, newDocuments);

    const processedDocuments = uploadResult.map((fileUploadResult, index) => {
      const { status } = fileUploadResult;
      const doc = documentsList[index];

      if (status === 'fulfilled' && !doc.trace_id) {
        const { trace_id } = fileUploadResult.value;
        return { ...doc, trace_id };
      }

      return doc;
    });

    const newUploadErrors = uploadResult.reduce<SiteFileRequest[]>(
      (acc, fileUploadResult, index) => {
        const { status } = fileUploadResult;
        const doc = processedDocuments[index];
        if (status === 'rejected') {
          acc.push(doc);
        }
        return acc;
      },
      [],
    );

    setDocumentsList(processedDocuments);
    setUploadErrors(newUploadErrors);

    if (newUploadErrors.length) {
      throw new Error('Upload file failed');
    }
  }

  function fieldValidator() {
    const updatedErrors = updateValidation();
    setSubmitted(true);
    return Object.fromEntries(
      Object.entries(updatedErrors).filter(([, value]) => Boolean(value)),
    );
  }

  const isLoading =
    isFetchingSites ||
    isLoadingCreateSite ||
    isLoadingUpdateSite ||
    isLoadingCreateSiteVersion ||
    uploading;

  return (
    <WizardStep
      description="Fields with (*) are required."
      disableNextButton={getDisableNextButton()}
      fieldValidator={fieldValidator}
      header="Fill in site info."
      isLoading={isLoading}
      nextButtonTextOverride="Save"
      onNextAsync={onNextAsync}
    >
      <AddSiteForm
        ref={ref}
        errors={errors}
        siteRecord={siteRecord}
        siteDocuments={
          <SiteDocuments
            documentsList={documentsList}
            siteRecord={siteRecord}
            uploadErrors={uploadErrors}
            onChangeDocuments={(documents) => setDocumentsList(documents)}
          />
        }
        onChangeField={onChangeField}
      />
    </WizardStep>
  );
}

export default AddSite;
