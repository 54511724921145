import withPeriodSpecificChecklistWrapper from 'accruals/pages/financial-close/accounting-checklist/withPeriodSpecificChecklistWrapper';
import { PeriodRecordBlobType } from 'shared/lib/types';

import ChecklistSectionsViewer from './ChecklistSectionsViewer';
import useChecklistSectionData from './tasks-status/useChecklistSectionData';

function AccountingChecklist() {
  const sections = useChecklistSectionData(true);

  return <ChecklistSectionsViewer sections={sections} />;
}

export default withPeriodSpecificChecklistWrapper(
  AccountingChecklist,
  PeriodRecordBlobType.FINANCIAL_CLOSE_CHECKLIST,
);
