import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { OccExpenseSummaryRow } from 'shared/lib/types';

import { useGetOccExpenseSummaryQuery } from 'shared/api/rtkq/periods';

export function processOccExpenseSummary(
  occExpenseSummary: { rows: OccExpenseSummaryRow[] } | undefined,
) {
  return cloneDeep(occExpenseSummary?.rows);
}

export default function useOccExpenseSummaryRows(): {
  rows: OccExpenseSummaryRow[] | undefined;
  isLoading: boolean;
} {
  const period = useSelector(selectPeriod);
  const {
    currentData: expenseSummaryGrid,
    isLoading,
    isFetching,
  } = useGetOccExpenseSummaryQuery(period.trace_id);

  return useMemo(
    () => ({
      rows: processOccExpenseSummary(expenseSummaryGrid),
      isLoading: isLoading || isFetching,
    }),
    [expenseSummaryGrid, isFetching, isLoading],
  );
}
