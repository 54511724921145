import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns/format';
import { lastDayOfMonth } from 'date-fns/lastDayOfMonth';

import Button from 'shared/ui/button/Button';

import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import { ContractStatusOptions } from 'shared/lib/types';

import { useUpdateContractMutation } from 'shared/api/rtkq/contracts';

import useAllEffectiveDatesFromContractContainer from './hooks/useAllEffectiveDatesFromContractContainer';

type Props = {
  contractContainerTraceId: string;
  contractVersionTraceId: string;
  currentStatus?: ContractStatusOptions;
  existingAipEffectiveDate?: string;
  existingEffectiveDate?: string;
  existingVoidedDate?: string;
  newStatus?: ContractStatusOptions;
  versionName?: string;
  onClose: () => void;
  onConfirm: () => void;
};

function ConfirmToChangeStatus(props: Props) {
  const {
    newStatus,
    onClose,
    onConfirm,
    currentStatus,
    versionName,
    contractVersionTraceId,
    contractContainerTraceId,
    existingEffectiveDate,
    existingAipEffectiveDate,
    existingVoidedDate,
  } = props;
  let initialEffectiveDate: string | null = null;
  switch (newStatus) {
    case ContractStatusOptions.CURRENT:
      initialEffectiveDate = existingEffectiveDate ?? null;
      break;
    case ContractStatusOptions.AIP:
      initialEffectiveDate = existingAipEffectiveDate ?? null;
      break;
    case ContractStatusOptions.VOIDED:
      initialEffectiveDate = existingVoidedDate ?? null;
      break;
    default:
      initialEffectiveDate = null;
      break;
  }
  const [allEffectiveDates, allAipEffectiveDates] =
    useAllEffectiveDatesFromContractContainer(
      contractContainerTraceId,
      contractVersionTraceId,
    );
  const [effectiveDate, setEffectiveDate] = useState<string | null>(
    initialEffectiveDate,
  );
  const [effectiveDateError, setEffectiveDateError] = useState<string | null>(
    null,
  );
  const [updateContractVersion] = useUpdateContractMutation();

  useEffect(() => {
    if (
      newStatus != null &&
      [ContractStatusOptions.SUPERSEDED, ContractStatusOptions.FUTURE].includes(
        newStatus,
      )
    ) {
      setEffectiveDateError(null);
      return;
    }
    if (!effectiveDate) {
      setEffectiveDateError(
        `${newStatus === ContractStatusOptions.VOIDED ? 'Void' : 'Effective'} Date is required`,
      );
      return;
    }
    if (newStatus === ContractStatusOptions.CURRENT) {
      if (allEffectiveDates.includes(effectiveDate)) {
        setEffectiveDateError(
          'There is already a current contract version with this effective month',
        );
        return;
      }
      if (effectiveDate === existingAipEffectiveDate) {
        setEffectiveDateError(
          'The AIP affective date is already set to same month',
        );
        return;
      }
      if (effectiveDate === existingVoidedDate) {
        setEffectiveDateError(
          'The voided date is already set to the same month',
        );
        return;
      }
    }
    if (newStatus === ContractStatusOptions.AIP) {
      if (allAipEffectiveDates.includes(effectiveDate)) {
        setEffectiveDateError(
          'There is already an AIP contract version with this effective month',
        );
        return;
      }
      if (effectiveDate === existingEffectiveDate) {
        setEffectiveDateError(
          'The affective date is already set to same month',
        );
        return;
      }
      if (effectiveDate === existingVoidedDate) {
        setEffectiveDateError(
          'The voided date is already set to the same month',
        );
        return;
      }
    }
    setEffectiveDateError(null);
  }, [
    effectiveDate,
    newStatus,
    allEffectiveDates,
    allAipEffectiveDates,
    existingEffectiveDate,
    existingAipEffectiveDate,
    existingVoidedDate,
  ]);

  let title = '';
  let description = '';
  switch (newStatus) {
    case ContractStatusOptions.CURRENT:
      title = 'Executing this contract will supersede your current contract.';
      description = `The version will be used for this contract’s calculations, and the existing version ${
        versionName ? `${versionName} ` : ''
      }will be archived and removed from the Current Contract tab.`;
      break;
    case ContractStatusOptions.AIP:
      title =
        'Are you sure you want to change this contract version be the amendment-in-progress?';
      if (currentStatus === ContractStatusOptions.CURRENT) {
        description =
          'You are changing the status of this current contract to "amendment-in-progress." There will no longer be a contract to base expense calculations off of until you add a new current contract.';
      } else {
        description =
          "You are changing the status of this contract to 'amendment-in-progress.' If you have an amendment-in-progress, that contract version will be voided.";
      }
      break;
    case ContractStatusOptions.VOIDED:
      title = 'Are you sure you want to void this contract version?';
      if (currentStatus === ContractStatusOptions.CURRENT) {
        description =
          'You are changing the status of this current contract to "voided." There will no longer be a contract to base expense calculations off of until you add a new current contract.';
      } else {
        description =
          'The data entered for this contract version will be saved and you will be able to use it again in the future if needed.';
      }
      break;
    case ContractStatusOptions.SUPERSEDED:
      title = 'Are you sure you want to supersede this contract version?';
      if (currentStatus === ContractStatusOptions.CURRENT) {
        description =
          "The new version you create will be used for the contract's calculations and this version will be archived. If you need to create an amendment in progress, use the Amendment-In-Progress Tab instead";
      } else {
        description =
          "You are changing the status of this contract to 'Superseded'.";
      }
      break;
    default:
      title = `Are you sure you want to change the contract status to ${newStatus}?`;
      description = '';
      break;
  }

  const onConfirmClick = () => {
    void (async () => {
      if (!effectiveDate) {
        return;
      }
      const request_obj: {
        trace_id: string;
        effective_date?: string;
        aip_effective_date?: string;
        voided_date?: string;
      } = {
        trace_id: contractVersionTraceId,
      };
      switch (newStatus) {
        case ContractStatusOptions.CURRENT:
          request_obj.effective_date = effectiveDate;
          break;
        case ContractStatusOptions.AIP:
          request_obj.aip_effective_date = effectiveDate;
          break;
        case ContractStatusOptions.VOIDED:
          request_obj.voided_date = effectiveDate;
          break;
        default:
          break;
      }
      await updateContractVersion(request_obj);
    })();
    onConfirm();
  };

  const onCloseClick = () => {
    void (async () => {
      // this API call is made to reset the status in AgGrid to what it was before the user tried to change it
      // this is the easiest way to do it than playing with agGrid's API
      await updateContractVersion({ trace_id: contractVersionTraceId });
    })();
    onClose();
  };

  return (
    <Dialog maxWidth="sm" open>
      <DialogTitleWithClose onClose={onCloseClick}>
        {title}
      </DialogTitleWithClose>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      {newStatus != null &&
      ![
        ContractStatusOptions.SUPERSEDED,
        ContractStatusOptions.FUTURE,
      ].includes(newStatus) ? (
        <FormControl sx={{ width: 400, mb: 3, ml: 4 }}>
          <DialogContentText
            color="text.primary"
            sx={{ mb: 2, fontWeight: 'bold' }}
            variant="body1"
          >
            {newStatus === ContractStatusOptions.VOIDED
              ? 'Voided Month'
              : 'Effective Month'}
          </DialogContentText>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Month / Year"
              openTo="month"
              value={effectiveDate ? new Date(effectiveDate) : null}
              views={['month', 'year']}
              slotProps={{
                textField: {
                  error: !!effectiveDateError,
                  helperText: effectiveDateError,
                },
              }}
              onChange={(value) =>
                setEffectiveDate(
                  value ? format(lastDayOfMonth(value), 'yyyy-MM-dd') : null,
                )
              }
            />
          </LocalizationProvider>
        </FormControl>
      ) : null}
      <DialogActions>
        <Button testId="cancel" variant="outlined" onClick={onCloseClick}>
          Cancel
        </Button>
        <Button
          disabled={!!effectiveDateError}
          variant="contained"
          testId={
            newStatus === ContractStatusOptions.VOIDED ? 'void' : 'change'
          }
          onClick={onConfirmClick}
        >
          {newStatus === ContractStatusOptions.VOIDED ? 'Void' : 'Change'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmToChangeStatus;
