import type {
  PatientAssessmentErrors,
  PatientCohort,
  PatientCohortEditor,
  PatientCohortError,
} from 'shared/lib/types';

import useCopyVisitsInCohort from './useCopyVisitsInCohort';
import useSavePatientCohort from './useSavePatientCohort';

function useCopyCohort(): [
  (
    sourceCohort: PatientCohortEditor,
    cohortName: string,
  ) => Promise<{
    data: PatientCohort | undefined;
    errors: PatientAssessmentErrors | PatientCohortError | undefined;
  }>,
  { isLoading: boolean },
] {
  const [savePatientCohort, { isLoading: isLoadingSaveCohort }] =
    useSavePatientCohort();
  const [createVisitsInCohort, { isLoading: isLoadingCreateVisits }] =
    useCopyVisitsInCohort();

  async function saveCohortCopy(
    sourceCohort: PatientCohortEditor,
    cohortName: string,
  ) {
    const { data: cohortData, error: cohortError } = await savePatientCohort({
      name: cohortName,
      patientAssessments: [],
      trialId: sourceCohort.trialId,
      orderIndex: sourceCohort.orderIndex,
      isNew: sourceCohort.isNew,
    });

    if (!cohortData) {
      return { data: undefined, errors: cohortError };
    }

    const sourceVisits = sourceCohort.patientAssessments.map((visit) => {
      const { traceId, ...rest } = visit;
      return rest;
    });

    const { data: newVisits, errors: visitsErrors } =
      await createVisitsInCohort(sourceVisits, cohortData.traceId!);

    if (newVisits) {
      cohortData.patientAssessments = newVisits;
    }

    return { data: cohortData, errors: visitsErrors };
  }

  return [
    saveCohortCopy,
    { isLoading: isLoadingSaveCohort || isLoadingCreateVisits },
  ];
}

export default useCopyCohort;
