import type { TrialRequest } from 'shared/lib/types';
import { useChangeTrial } from 'shared/state/slices/trialSlice';
import type { SummaryItem } from 'shared/wizards/review-step/ReviewStep';
import ReviewStep from 'shared/wizards/review-step/ReviewStep';

import {
  useCreateTrialMutation,
  useUpdateTrialMutation,
} from 'shared/api/rtkq/trials';

type Props = { afterSave: () => void; trialRecord: TrialRequest };

export default function TrialReviewStep(props: Props) {
  const { trialRecord, afterSave } = props;
  const [createTrial] = useCreateTrialMutation();
  const [updateTrial] = useUpdateTrialMutation();
  const changeTrial = useChangeTrial();

  async function onSave() {
    let res;
    if (trialRecord.trace_id) {
      res = await updateTrial({
        trace_id: trialRecord.trace_id,
        program: trialRecord.program,
        ...trialRecord,
      }).unwrap();
    } else {
      res = await createTrial({ ...trialRecord }).unwrap();
    }

    changeTrial(res);
    afterSave();
  }

  const section1: SummaryItem = {
    sectionHeader: 'Trial Information',
    editPageNumber: 1,
    rows: [
      {
        label: 'Already started?',
        values: [trialRecord.is_in_flight ? 'Yes' : 'No'],
      },
      { label: 'Trial ID', values: [trialRecord.study_id] },
      { label: 'Trial name', values: [trialRecord.name] },
      { label: 'Phase', values: [`Phase ${trialRecord.phase}`] },
      { label: 'Indication', values: [trialRecord.indication] },
      { label: 'Trial balance', values: [String(trialRecord.trial_balance)] },
      {
        label: 'ClinicalTrial.gov URL',
        values: [trialRecord.national_clinical_trial_url],
      },
    ],
  };

  const formattedSummaryData = [[section1]];

  return (
    <ReviewStep
      formattedSummaryData={formattedSummaryData}
      header="Review trial information."
      onNextAsync={onSave}
    />
  );
}
