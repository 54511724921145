import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

export default function usePoCompletenessGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions {
  return useMemo(
    () => ({
      getRowStyle: 'highlightRow',
      getRowId: 'traceId',
      autoGroupColumnDef: {
        headerName: 'Group',
        cellRendererParams: { suppressCount: true },
        cellRendererSelector: 'poCompletenessCellRendererSelector',
      },
      groupDisplayType: 'singleColumn',
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
    }),
    [],
  );
}
