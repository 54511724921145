import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';

type StepperWithStepsProps = {
  activeStep: number;
  stepNames: string[];
  width?: string;
  onClick: (clickedStepIndex: number) => void;
};

function StepperWithSteps(props: StepperWithStepsProps) {
  const { stepNames, activeStep, width = '40%', onClick } = props;

  return (
    <Stepper activeStep={activeStep} sx={{ justifyContent: 'center', width }}>
      {stepNames.map((stepName, index) => (
        <Step key={stepName}>
          <StepButton onClick={() => onClick(index)}>{stepName}</StepButton>
        </Step>
      ))}
    </Stepper>
  );
}

export default StepperWithSteps;
