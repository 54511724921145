import type {
  AssumptionGroupRequestDisplay,
  AssumptionGroupResponse,
  RegionGroupListItemType,
  RegionListItemType,
  TraceId,
} from 'shared/lib/types';
import { CRUDAction, DataType, DateAssumptionType } from 'shared/lib/types';

import {
  ALL_DISPLAYED_ASSUMPTION_GROUP_NAMES,
  getCalculation,
  getDataType,
  getMostRecentAssumptionGroupByName,
  getOverall,
  getRequired,
  showByRegion,
  showEmptyRowAfter,
  showOverall,
} from '../assumption-groups/helpers';

export type RegionsAndAssumptionGroups = [
  RegionListItemType[], // regions to play with
  AssumptionGroupRequestDisplay[], // params to play with
  RegionListItemType[], // ALL regions (regardless of status)
  RegionListItemType[], // Default regions (regardless of status)
  (regions: RegionListItemType[]) => void, // updateRegions
  RegionGroupListItemType[], // ALL regionGroups (regardless of status)
  RegionGroupListItemType[], // Default regionGroups (regardless of status)
  (regionGroups: RegionGroupListItemType[]) => void, // updateRegionGroups
  (assumptionGroups: AssumptionGroupRequestDisplay[]) => void, // updateParams
];

export const calculateAssumptionGroups = (
  updatedAssumptionGroups: AssumptionGroupRequestDisplay[],
) => {
  let assumptionGroupsWithCalculations = updatedAssumptionGroups;
  for (const updatedAssumptionGroup of updatedAssumptionGroups) {
    assumptionGroupsWithCalculations = updatedAssumptionGroup.calculation(
      updatedAssumptionGroup,
      assumptionGroupsWithCalculations,
    );
  }

  return assumptionGroupsWithCalculations;
};

export function createGroups(
  contractVersionTraceId: TraceId,
  readOnly: boolean,
  allRegions: RegionListItemType[],
  assumptionGroups: AssumptionGroupRequestDisplay[],
  databaseAssumptionGroups: AssumptionGroupResponse[] | undefined,
) {
  const applicableParameters = getMostRecentAssumptionGroupByName(
    databaseAssumptionGroups ?? [],
  );
  const deletedRegions = allRegions
    .filter((region) => region.action === CRUDAction.DELETE)
    .map((region) => region.trace_id);

  const groups = ALL_DISPLAYED_ASSUMPTION_GROUP_NAMES.map(
    (assumptionGroupName) => {
      const dataType = getDataType(assumptionGroupName);

      const dbAssumptionGroup = applicableParameters.find(
        (param) => param.name === assumptionGroupName,
      );
      const userAssumptionGroup = assumptionGroups.find(
        (param) => param.name === assumptionGroupName,
      );

      const assumptionGroupDisplay: Omit<
        AssumptionGroupRequestDisplay,
        'overallValue'
      > = {
        ...(dbAssumptionGroup !== undefined
          ? {
              ...dbAssumptionGroup,
              contract_params: dbAssumptionGroup.contract_params.filter(
                (param) => !deletedRegions.includes(param.region),
              ),
            }
          : {
              name: assumptionGroupName,
              contract_version: contractVersionTraceId,
              contract_params:
                dataType === DataType.DATE
                  ? [
                      { date_assumption_type: DateAssumptionType.START },
                      { date_assumption_type: DateAssumptionType.END },
                    ]
                  : [],
              data_type: dataType,
            }),
        ...(readOnly ? {} : userAssumptionGroup), // prefer anything that the user has already done, but only if editable
        editable: true, // assume by default everything is editable, and let the calculations control this
        calculation: getCalculation(assumptionGroupName),
        required: getRequired(assumptionGroupName),
        showOverall: showOverall(assumptionGroupName),
        showByRegion: showByRegion(assumptionGroupName),
        showEmptyRowAfter: showEmptyRowAfter(assumptionGroupName),
      };

      return {
        ...assumptionGroupDisplay,
        overallValue: getOverall(assumptionGroupDisplay),
      };
    },
  );

  return calculateAssumptionGroups(groups);
}

export function createRegionsAndParameters(
  contractVersionTraceId: TraceId,
  databaseAssumptionGroups: AssumptionGroupResponse[] | undefined,
) {
  return createGroups(
    contractVersionTraceId,
    true,
    [],
    [],
    databaseAssumptionGroups,
  );
}
