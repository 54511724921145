import { useState } from 'react';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import type {
  PatientAssessment,
  PatientAssessmentError,
  PatientAssessmentErrors,
  TraceId,
} from 'shared/lib/types';

import { useCreatePatientAssessmentMutation } from 'shared/api/rtkq/patientassessments';

import { mapPatientVisitFromApiResponse } from '../mapper';

function useCopyVisitsInCohort(): [
  (
    visits: PatientAssessment[],
    cohortTraceId: TraceId,
  ) => Promise<{
    data: PatientAssessment[] | undefined;
    errors: PatientAssessmentErrors | undefined;
  }>,
  { isLoading: boolean },
] {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createPatientAssessmentRequest] = useCreatePatientAssessmentMutation();

  async function createVisitsInCohort(
    visits: PatientAssessment[],
    cohortTraceId: TraceId,
  ) {
    setIsLoading(true);

    let visitIndex = 0;

    const visitErrorsForCohort: PatientAssessmentErrors = {};
    const savedVisits: PatientAssessment[] = [];

    for (const visit of visits) {
      const patientVisitData = {
        patient_cohort: cohortTraceId,
        name: visit.name,
        pizza_name: visit.pizzaName,
        is_screen: visit.isScreen,
        is_enroll: visit.isEnroll,
        is_screenfail: visit.isScreenfail,
        is_dropped_completed: visit.isDroppedCompleted,
        order_index: visit.orderIndex,
      };
      try {
        const data =
          // eslint-disable-next-line no-await-in-loop -- we don't want to run these in parallel
          await createPatientAssessmentRequest(patientVisitData).unwrap();
        savedVisits.push(mapPatientVisitFromApiResponse(data));
      } catch (error: unknown) {
        visitErrorsForCohort[visitIndex] = (error as FetchBaseQueryError)
          .data as PatientAssessmentError;
      }
      visitIndex++;
    }

    setIsLoading(false);
    return {
      data: savedVisits.length ? savedVisits : undefined,
      errors: Object.keys(visitErrorsForCohort).length
        ? visitErrorsForCohort
        : undefined,
    };
  }

  return [createVisitsInCohort, { isLoading }];
}

export default useCopyVisitsInCohort;
