import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import type { PaletteColor, SxProps, Theme } from '@mui/material/styles';

import { currencyFormatter, percentFormatter } from 'formatters';

import TabularDataCell from './TabularDataCell';

type Props = {
  color?: (theme: Theme) => PaletteColor;
  loading?: boolean;
  percentage: number | undefined;
  sx?: SxProps;
  value: number | undefined;
};

function TabularDataCellCurrencyPercent(props: Props) {
  const { color, loading = false, percentage, value, sx } = props;

  return (
    <TabularDataCell sx={sx}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {loading ? (
          <>
            <Skeleton variant="text" width="100%" />
            <Skeleton sx={{ ml: 1 }} variant="text" width="100%" />
          </>
        ) : (
          <>
            <Typography
              color={(theme) => color?.(theme).main}
              variant="body1"
              sx={{
                textAlign: 'center',
                fontWeight: color === undefined ? undefined : '700',
                mr: 1,
              }}
            >
              {currencyFormatter(value)}
            </Typography>
            <Typography
              color={(theme) => color?.(theme).main}
              variant="body1"
              sx={{
                textAlign: 'center',
                fontWeight: color === undefined ? undefined : '700',
                backgroundColor: (theme) => color?.(theme).light,
                px: 1,
              }}
            >
              {`${(percentage ?? 0) > 0 ? '+' : ''}${percentFormatter(percentage)}`}
            </Typography>
          </>
        )}
      </Box>
    </TabularDataCell>
  );
}

export default TabularDataCellCurrencyPercent;
