import type { HTMLAttributes } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { autocompleteClasses } from 'shared/ui/autocomplete/Autocomplete';
import Chip from 'shared/ui/chip/Chip';

import type { DropdownOption, TraceId } from 'shared/lib/types';

type Props = {
  description?: string;
  mappedCodes: TraceId[];
  option: DropdownOption<string>;
  renderProps: HTMLAttributes<HTMLLIElement>;
};

function ControlCodeMappingOption(props: Props) {
  const { option, renderProps, mappedCodes, description } = props;
  const { className, ...restProps } = renderProps;
  const isMapped = mappedCodes.includes(option.value);

  return (
    <Tooltip
      key={option.value}
      enterDelay={500}
      placement="right"
      title={description}
      arrow
      followCursor
    >
      <span>
        <Box
          component="li"
          display="flex"
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
            pl: (theme) => `${theme.spacing(1)} !important`,
            [`&.${autocompleteClasses.option}`]: {
              justifyContent: 'space-between',
            },
          }}
          {...restProps}
          className={className}
        >
          <Typography>{option.label}</Typography>
          <Chip
            color={isMapped ? 'success' : 'default'}
            icon={isMapped ? <DoneIcon /> : undefined}
            label={isMapped ? 'Mapped' : 'Not mapped'}
            size="small"
          />
        </Box>
      </span>
    </Tooltip>
  );
}

export default ControlCodeMappingOption;
