import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import useFinancialForecastSummaryColDefs from '../hooks/useFinancialForecastSummaryColDefs';
import useFinancialForecastSummaryGridOptions from '../hooks/useFinancialForecastSummaryGridOptions';
import useFinancialForecastSummaryRows from '../hooks/useFinancialForecastSummaryRows';

type Props = {
  overlayNoRowsTemplate?: string;
  showActuals: boolean;
  sx?: SxProps;
};

function FinancialForecastSummaryGrid({
  overlayNoRowsTemplate,
  showActuals,
  sx,
}: Props) {
  const colDefs = useFinancialForecastSummaryColDefs(showActuals);
  const gridOptions = useFinancialForecastSummaryGridOptions();
  const { loading, rows } = useFinancialForecastSummaryRows();

  return (
    <CondorAgGrid
      columnDefs={colDefs}
      gridOptions={gridOptions}
      loading={loading}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rows}
      sx={sx}
    />
  );
}

export default FinancialForecastSummaryGrid;
