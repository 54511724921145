import type { Theme } from '@mui/material/styles';

export const getSum = (
  rows: Array<Record<string, unknown>> | undefined,
  key: string,
) => rows?.reduce<number>((acc: number, row) => Number(row[key] ?? 0) + acc, 0);

export const hasKey = (
  rows: Array<Record<string, unknown>> | undefined,
  key: string,
) => rows?.some((row) => row[key] !== undefined);

export const getPercent = (
  actual: number | string | null | undefined,
  expected: number | undefined,
) =>
  actual !== null && actual !== undefined && expected
    ? (Number(actual) * 100) / expected
    : 0;

export const getDifference = (
  actual: number | string | null | undefined,
  expected: number | undefined,
) => Number(actual ?? 0) - Number(expected ?? 0);

export const getColor = (total: number | null | undefined) => {
  if (total === 0) {
    return undefined;
  }

  return ({ palette }: Theme) =>
    (total ?? 0) < 0 ? palette.success : palette.error;
};
