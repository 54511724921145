import { useEffect, useMemo } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';
import { useSelector } from 'react-redux';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { FxRateResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useUpsertFxRateMutation } from 'shared/api/rtkq/fxrates';

export default function useFxRateGridOptions(
  isOpenPeriod: boolean,
  setUpsertFxRateInProgress?: (inProgress: boolean) => void,
): CondorGridOptions<FxRateResponse> | undefined {
  const currentCompany = useSelector(selectCompany);
  const companyTraceId = currentCompany.trace_id;

  const [upsertFxRate, { isLoading: upsertFxRateLoading }] =
    useUpsertFxRateMutation();

  useEffect(() => {
    setUpsertFxRateInProgress?.(upsertFxRateLoading);
  }, [upsertFxRateLoading, setUpsertFxRateInProgress]);

  return useMemo(() => {
    const onCellValueChanged = (params: CellValueChangedEvent) => {
      const {
        colDef: { field },
        value: rate,
        data: { end_date, currency_to },
      } = params;

      if (typeof field === 'undefined') {
        return;
      }

      void upsertFxRate({
        company: companyTraceId,
        currency_from: field,
        currency_to,
        end_date,
        rate,
      });
    };

    return {
      ...(isOpenPeriod && { onCellValueChanged }),
      getRowId: 'end_date',
    };
  }, [companyTraceId, isOpenPeriod, upsertFxRate]);
}
