import type { ReactNode } from 'react';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

export default function withPeriodSpecificGenericWrapper<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
) {
  return function PeriodGridWrapper(props: T) {
    const isClosed = useIsClosedPeriodVersion();
    return isClosed ? null : <LiveDataComponent {...props} />;
  };
}
