import { COMPANIES_TAG } from 'shared/api/rtkq/companies';
import { FORECASTS_TAG } from 'shared/api/rtkq/forecasts';
import { PERIODS_TAG } from 'shared/api/rtkq/periods';
import { TRIALS_TAG } from 'shared/api/rtkq/trials';
import { USERS_TAG } from 'shared/api/rtkq/users';

export function getCompanySafeKeys() {
  return [USERS_TAG, COMPANIES_TAG];
}

export function getTrialSafeKeys() {
  return [...getCompanySafeKeys(), TRIALS_TAG];
}

export function getForecastSafeKeys() {
  return [...getTrialSafeKeys(), FORECASTS_TAG];
}

export function getPeriodSafeKeys() {
  return [...getTrialSafeKeys(), PERIODS_TAG];
}

// this needs to clear everything, every time
export function getPeriodVersionSafeKeys() {
  return [...getPeriodSafeKeys()];
}
