import ParentSize from '@visx/responsive/lib/components/ParentSize';

import TrialSpendForecastGraph from 'forecasting/components/graphing/trial-spend-forecast-graph/TrialSpendForecastGraph';

import { data } from './data';

function TrialSpendForecast() {
  return (
    <ParentSize>
      {(parent) => (
        <TrialSpendForecastGraph
          graphData={data}
          latestCloseDate="2024-12-01T00:00:00"
          width={parent.width}
        />
      )}
    </ParentSize>
  );
}

export default TrialSpendForecast;
