import { useMemo } from 'react';

import type {
  CellEditingStartedEvent,
  EditableCallbackParams,
  NewValueParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { useTheme } from '@mui/material/styles';
import type {
  BaseQueryFn,
  TypedMutationTrigger,
} from '@reduxjs/toolkit/query/react';

import {
  getDateCellConfig,
  getGenericCellConfig,
  getMoneyCellConfig,
  getNullOrDecimalConfig,
  getPercentCellConfig,
  getRowNumberConfig,
  getSelectCellConfig,
  getTextCellConfig,
  getToggleableMoneyCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColGroupDef } from 'shared/components/ag-grid/types';

import { tryGetNumericValue } from 'formatters';
import { isTopsideAdjustment } from 'shared/helpers/gridData';
import {
  ActivityUnitDetail,
  ActivityUnitType,
  detailChoicesForUnitType,
} from 'shared/lib/driverUnitTypes';
import type {
  ContractVersionResponse,
  CroCostCategory,
  CroReconRow,
  RegionAndRegionGroupResponse,
  TraceId,
  VendorReportedBottomLineAdjustmentResponse,
} from 'shared/lib/types';
import {
  CroAdjustmentType,
  CroAdjustmentTypeReverse,
  CroReconGroupType,
} from 'shared/lib/types';

import { useUpsertVendorRepBlaAmountMutation } from 'shared/api/rtkq/bottomlineadjustments';
import {
  useUpdateContractReconTopsideAdjustmentMutation,
  useUpsertContractReconActivityGroupMutation,
} from 'shared/api/rtkq/contracts';

import {
  createEmptyStateColumnGroupDef,
  getCroUnitTypeChoices,
  hasActivityRow as hasActivityData,
  hasAnyActivityRow,
} from '../helpers';

export function processCroReconGridColumnDefs(
  isOpenPeriod: boolean,
  rowData: CroReconRow[] | undefined,
  costCategory: CroCostCategory,
  currentContractVersionTraceId: TraceId | undefined,
  currentPeriodTraceId: TraceId | undefined,
  themeMode: 'dark' | 'light' | undefined,
  regionsAndRegionGroups?: RegionAndRegionGroupResponse[],
  updateContractReconActivityGroupRequest?: TypedMutationTrigger<
    ContractVersionResponse,
    unknown,
    BaseQueryFn
  >,
  updateContractReconTopsideAdjustmentRequest?: TypedMutationTrigger<
    unknown,
    unknown,
    BaseQueryFn
  >,
  upsertVendorRepBlaAmount?: TypedMutationTrigger<
    VendorReportedBottomLineAdjustmentResponse,
    unknown,
    BaseQueryFn
  >,
): CondorColGroupDef[] {
  const ASSIGNED_REGION_WIDTH = 150;

  // TODO: Instead of looking at each row, just have recon return if it has each type
  const hasAnyAiP = hasAnyActivityRow(CroReconGroupType.AiP, rowData);
  const hasAnyVendorReportedActivity = hasAnyActivityRow(
    CroReconGroupType.Vendor,
    rowData,
  );

  const isEditableAdjustment = (params: EditableCallbackParams) =>
    params.data?.type === 'GROUP' || params.data?.type === 'ROW';

  const onAdjustmentsUpdated = (
    orderIndex: number | undefined,
    adjustmentType: CroAdjustmentType | null,
    adjustmentAmount: number | null,
    currentContractId?: string,
    aipContractId?: string,
    vendorReportedId?: string,
  ) => {
    // this means bad data, but at least don't blow up
    if (orderIndex === undefined) {
      return;
    }

    if (
      adjustmentType !== null &&
      Object.keys(CroAdjustmentType).includes(adjustmentType)
    ) {
      updateContractReconActivityGroupRequest &&
        void updateContractReconActivityGroupRequest({
          object: {
            trace_id: currentContractVersionTraceId ?? '',
            cost_category: costCategory,
            order_index: orderIndex,
            adjustment_type: adjustmentType,
            adjustment_amount: adjustmentAmount,
            current_contract: currentContractId,
            aip_contract: aipContractId,
            vendor_reported: vendorReportedId,
          },
          secondParameter: currentPeriodTraceId ?? '',
        });
    }
  };

  const onAdjustmentTypeChanged = (params: NewValueParams) => {
    // if changing the adjustment type, clear the amount (in case they were on `OTHER`)
    onAdjustmentsUpdated(
      params.data?.orderIndex,
      params.data?.adjustmentType,
      0,
      params.data?.currentTraceId,
      params.data?.aipTraceId,
      params.data?.reportedTraceId,
    );

    // force the row that was changed to redraw so the amount can be colored correctly
    if (params.node) {
      params.api.redrawRows({ rowNodes: [params.node] });
    }
  };

  const onAdjustmentAmountChanged = (params: NewValueParams) => {
    const adjustmentAmount = tryGetNumericValue(
      params.data?.adjustmentAmountUpdated,
    );

    if (isTopsideAdjustment(params)) {
      if (adjustmentAmount !== null) {
        updateContractReconTopsideAdjustmentRequest &&
          void updateContractReconTopsideAdjustmentRequest({
            object: {
              trace_id: currentContractVersionTraceId ?? '',
              topside_adjustment: adjustmentAmount,
            },
            secondParameter: currentPeriodTraceId ?? '',
            thirdParameter: costCategory,
          });
      }
      return;
    }

    onAdjustmentsUpdated(
      params.data?.orderIndex,
      params.data?.adjustmentType,
      adjustmentAmount,
      params.data?.currentTraceId,
      params.data?.aipTraceId,
      params.data?.reportedTraceId,
    );
  };

  const onVendorReportedBottomLineAdjustmentAmountChanged = (
    params: NewValueParams,
  ) => {
    const vendorReportedBla = tryGetNumericValue(
      params.data?.reportedLtdExpensedUpdated,
    );

    if (vendorReportedBla !== null) {
      void upsertVendorRepBlaAmount?.({
        trace_id: params.data.id, // trace_id of the bottom line adjustment
        amount: vendorReportedBla,
        period_trace_id: currentPeriodTraceId,
      });
    }
  };

  const currencyKey = 'currencyToggleable'; // Just to avoid collisions with CustomCellRenderer

  return [
    {
      headerName: '',
      children: [
        {
          field: 'displayRowNum',
          headerName: 'Row',
          pinned: 'left',
          width: 80,
          cellRenderer: 'AgGridGroupCellRenderer',
          ...getRowNumberConfig(),
        },
        {
          field: 'orderIndex',
          headerName: 'Line Match',
          pinned: 'left',
          width: 100,
        },
        {
          field: 'activityDescription',
          headerName: 'Description',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Contract',
      children: [
        { field: 'id', hide: true },
        { field: 'serviceCategory', rowGroup: true, hide: true },
        { field: 'region' },
        { field: 'activityCode', headerName: 'Activity Code' },
      ],
    },
    {
      headerName: 'Contracted Expenses',
      children: [
        {
          field: 'unitType',
          renderIf: {
            condition: 'hasContract',
            colDef: getGenericCellConfig(),
          },
        },
        {
          field: 'unitDetail',
          renderIf: {
            condition: 'hasContract',
            colDef: getGenericCellConfig(),
          },
        },
        {
          field: 'startDate',
          renderIf: {
            condition: 'hasContract',
            colDef: getGenericCellConfig(),
          },
        },
        {
          field: 'endDate',
          renderIf: {
            condition: 'hasContract',
            colDef: getGenericCellConfig(),
          },
        },
        {
          headerName: 'Assigned Region',
          width: ASSIGNED_REGION_WIDTH,
          field: 'assignedRegion',
          renderIf: {
            condition: 'hasContract',
            colDef: getGenericCellConfig(),
          },
        },
        {
          field: 'parameterCalculatedNormalizedUnitCount',
          headerName: '# of normalized units',
          type: 'rightAligned',
          renderIf: {
            condition: 'hasContract',
            colDef: getNullOrDecimalConfig({ emptyValueForTopSideAdj: true }),
          },
        },
        {
          field: 'parameterCalculatedUnitPrice',
          headerName: 'Unit price',
          type: 'rightAligned',
          renderIf: {
            condition: 'hasContract',
            colDef: getToggleableMoneyCellConfig(
              'parameterCalculatedUnitPrice',
              {
                cellRendererParams: {
                  currencyKey,
                  currencyViewModeOverride: 'native',
                  useEmDashInTotal: false,
                },
                valueGetter: undefined,
              },
            ),
          },
        },
        {
          headerName: 'LTD expense',
          type: 'rightAligned',
          aggFunc: 'croReconTotalToggleable',
          cellStyle: 'boldCell',
          renderIf: {
            condition: 'hasContractOrGroupOrBottomLineAdjustment',
            colDef: getToggleableMoneyCellConfig(
              'monthlyExpenseValuesTotalToggleable',
              { cellRendererParams: { currencyKey, useEmDashInGroup: true } },
            ),
          },
        },
      ],
    },
    hasAnyVendorReportedActivity
      ? {
          headerName: 'CRO Reported Expenses',
          children: [
            {
              field: 'reportedUnitType',
              headerName: 'Unit type (reported)',
              renderIf: {
                condition: 'hasVendor',
                colDef: getGenericCellConfig(),
              },
            },
            {
              field: 'reportedNumberOfLTDUnits',
              headerName: '# of LTD units (reported)',
              renderIf: {
                condition: 'hasVendor',
                colDef: getGenericCellConfig(),
              },
            },
            {
              headerName: 'Unit price',
              renderIf: {
                condition: 'hasVendor',
                colDef: getToggleableMoneyCellConfig(
                  'reportedUnitPriceToggleable',
                  {
                    cellRendererParams: {
                      currencyKey,
                      useEmDashInTotal: false,
                    },
                  },
                ),
              },
            },
            {
              field: 'reportedLtdExpensedUpdated',
              headerName: 'LTD expense (reported)',
              type: 'rightAligned',
              aggFunc: 'croReconTotalToggleable',
              renderIf: {
                condition: 'hasVendorOrIsGridGroupOrBla',
                colDef: getToggleableMoneyCellConfig(
                  'reportedLtdExpensedToggleable',
                  {
                    cellRendererParams: { currencyKey },
                  },
                ),
              },
              ...(isOpenPeriod && {
                ...makeEditableIf(
                  ({ data, context }) =>
                    context.currencyViewMode === 'native' &&
                    data?.isAiP === false &&
                    data?.isBottomLineAdjustment,
                  themeMode,
                ),
                onCellValueChanged:
                  onVendorReportedBottomLineAdjustmentAmountChanged,
              }),
            },
            {
              headerName: 'Variance from contracted',
              type: 'rightAligned',
              aggFunc: 'croReconTotalToggleable',
              renderIf: {
                condition: [
                  'hasVendor',
                  'isNotTopsideAdjustment',
                  'isNotChild',
                ],
                colDef: getToggleableMoneyCellConfig(
                  'reportedVarianceToggleable',
                  {
                    cellRendererParams: { currencyKey },
                  },
                ),
              },
            },
          ],
        }
      : createEmptyStateColumnGroupDef(
          'CRO Reported Expenses',
          'To compare expenses, line items need to be matched to the current contract budget.',
        ),
    hasAnyAiP
      ? {
          headerName: 'Amendment-in-progress Expenses',
          children: [
            { field: 'aipActivityDriverTraceId', hide: true },
            { field: 'aipHasEnrollment', hide: true },
            { field: 'aipHasTreatment', hide: true },
            { field: 'aipHasFollowup', hide: true },
            {
              field: 'aipUnitType',
              headerName: 'Unit type',
              ...(isOpenPeriod && {
                ...makeEditableIf(
                  ({ data }) => data?.hasAip && data.type !== 'GROUP',
                  themeMode,
                ),
                renderIf: {
                  condition: ['hasAiP', 'isNotDataGroup'],
                  colDef: getSelectCellConfig({
                    useEmDash: false,
                    useEmDashInGroup: false,
                    useEmDashInTotal: false,
                  }),
                },
                cellEditorParams: {
                  values: getCroUnitTypeChoices(costCategory),
                },
                onCellValueChanged(event: NewValueParams) {
                  event.node?.setDataValue('aipUnitDetail', undefined);
                  if (
                    ['SITE', 'PTNT', 'STMN', 'PTMN', 'ASIN'].includes(
                      event.newValue,
                    )
                  ) {
                    event.node?.setDataValue('aipStartDate', undefined);
                    event.node?.setDataValue('aipEndDate', undefined);
                  }
                },
              }),
            },
            {
              field: 'aipUnitDetail',
              headerName: 'Unit detail',
              ...(isOpenPeriod && {
                renderIf: {
                  condition: ['hasAiP', 'isNotDataGroup'],
                  colDef: getSelectCellConfig({
                    useEmDash: false,
                    useEmDashInGroup: false,
                    useEmDashInTotal: false,
                  }),
                },
                cellEditorParams(event: CellEditingStartedEvent) {
                  return {
                    values: detailChoicesForUnitType(
                      event.data.aipUnitType,
                      'CRO',
                    ).filter(
                      (unitDetail) =>
                        (unitDetail !== ActivityUnitDetail.ENROLLMENT_PERIOD ||
                          event.data.aipHasEnrollment === true) &&
                        (unitDetail !== ActivityUnitDetail.TREATMENT_PERIOD ||
                          event.data.aipHasTreatment === true) &&
                        (unitDetail !== ActivityUnitDetail.FOLLOW_UP_PERIOD ||
                          event.data.aipHasFollowup === true),
                    ),
                  };
                },
                ...makeEditableIf(({ data }) => data?.aipUnitType, themeMode),
              }),
            },
            {
              field: 'aipStartDate',
              headerName: 'Start date',
              ...(isOpenPeriod && {
                ...makeEditableIf(
                  ({ data }) =>
                    data?.aipUnitDetail === ActivityUnitDetail.CUSTOM &&
                    data.type !== 'GROUP',
                  themeMode,
                ),
              }),
              renderIf: {
                condition: [
                  'hasAiP',
                  'isNotDataGroup',
                  'isNeitherFooterNorTopsideAdjustment',
                ],
                colDef: getDateCellConfig({
                  useEmDash: false,
                  useEmDashInGroup: false,
                  useEmDashInTotal: false,
                }),
              },
            },
            {
              field: 'aipEndDate',
              headerName: 'End date',
              ...(isOpenPeriod && {
                ...makeEditableIf(
                  ({ data }) =>
                    data?.aipUnitDetail === ActivityUnitDetail.CUSTOM &&
                    data.type !== 'GROUP',
                  themeMode,
                ),
              }),
              renderIf: {
                condition: [
                  'hasAiP',
                  'isNotDataGroup',
                  'isNeitherFooterNorTopsideAdjustment',
                ],
                colDef: getDateCellConfig({
                  useEmDash: false,
                  useEmDashInGroup: false,
                  useEmDashInTotal: false,
                }),
              },
            },
            {
              headerName: 'Assigned Region',
              field: 'aipAssignedRegion',
              width: ASSIGNED_REGION_WIDTH,
              renderIf: {
                condition: ['hasAiP', 'isNotDataGroup'],
                colDef: isOpenPeriod
                  ? getSelectCellConfig({
                      useEmDash: false,
                      useEmDashInGroup: false,
                      useEmDashInTotal: false,
                    })
                  : getGenericCellConfig({
                      useEmDash: false,
                      useEmDashInGroup: false,
                      useEmDashInTotal: false,
                    }),
              },
              ...(isOpenPeriod && {
                ...makeEditableIf(
                  ({ data }) => data?.hasAip && data.type !== 'GROUP',
                  themeMode,
                ),
                valueFormatter: (params: ValueFormatterParams) =>
                  regionsAndRegionGroups?.find(
                    (regionAndRegionGroup) =>
                      regionAndRegionGroup.trace_id === params.value,
                  )?.name ?? '',
                cellEditorParams: {
                  options: regionsAndRegionGroups?.map(
                    (regionAndRegionGroup) => ({
                      label: regionAndRegionGroup.name,
                      value: regionAndRegionGroup.trace_id,
                    }),
                  ),
                },
              }),
            },
            {
              field: 'aipParameterCalculatedUnitCount',
              headerName: '# of units',
              type: 'rightAligned',
              renderIf: {
                condition: ['hasAiP', 'isNotDataGroup'],
                colDef: getNullOrDecimalConfig({ placeholder: '' }),
              },
            },
            {
              headerName: 'Unit price',
              type: 'rightAligned',
              renderIf: {
                condition: ['hasAiP', 'isNotDataGroup'],
                colDef: getToggleableMoneyCellConfig(
                  'aipParameterCalculatedUnitPriceToggleable',
                  {
                    cellRendererParams: {
                      currencyKey,
                      useEmDashInTotal: false,
                    },
                  },
                ),
              },
            },
            {
              field: 'aipLtdExpensed', // this is needed only for editing
              headerName: 'LTD expense (AIP)',
              aggFunc: 'croReconTotalToggleable',
              renderIf: {
                condition: [
                  'hasAipOrIsGridGroupOrBla',
                  'isNotTopsideAdjustment',
                ],
                colDef: getToggleableMoneyCellConfig(
                  'aipLtdExpensedToggleable',
                  {
                    cellRendererParams: { currencyKey },
                  },
                ),
              },
              ...(isOpenPeriod && {
                ...makeEditableIf(
                  ({ data, context }) =>
                    context.currencyViewMode === 'trial' &&
                    data?.aipUnitType === ActivityUnitType.AS_INVOICED,
                  themeMode,
                ),
              }),
            },
            {
              hide: true,
              field: 'aipPercentRecognizedDenominator',
              headerName: '% recognized denominator',
              type: 'rightAligned',
              width: 200,
              aggFunc: 'croReconTotal',
              renderIf: {
                condition: ['hasAiPOrGroup', 'isNotTopsideAdjustment'],
                colDef: getMoneyCellConfig(),
              },
            },
            {
              field: 'aipPercentRecognized',
              headerName: '% recognized',
              type: 'rightAligned',
              ...(isOpenPeriod && {
                ...makeEditableIf(
                  ({ data }) =>
                    data?.aipUnitType === ActivityUnitType.PERCENT_COMPLETE,
                  themeMode,
                ),
              }),
              ...getPercentCellConfig(),
              aggFunc: 'croReconPercentRecognizedTotal',
              renderIf: {
                condition: ['hasAiPOrGroup', 'isNotTopsideAdjustment'],
                colDef: getPercentCellConfig(),
              },
            },
            {
              headerName: 'Variance from contracted',
              type: 'rightAligned',
              width: 200,
              cellStyle: 'highlightGroup',
              aggFunc: 'croReconTotalToggleable',
              renderIf: {
                condition: [
                  'hasAiPOrGroup',
                  'isNotTopsideAdjustment',
                  'isNotChild',
                ],
                colDef: getToggleableMoneyCellConfig('aipVarianceToggleable', {
                  cellRendererParams: { currencyKey },
                }),
              },
            },
          ],
        }
      : createEmptyStateColumnGroupDef(
          'Amendment-in-progress Expenses',
          'To compare expenses, line items need to be matched to the current contract budget.',
        ),
    {
      headerName: 'Reconciliation',
      children: [
        { field: 'isTopsideAdjustment', hide: true },
        {
          filter: true,
          headerName: 'Adjustment Type',
          width: 200,
          refData: CroAdjustmentType,
          renderIf: {
            condition: 'isGroupOrRow',
            colDef: isOpenPeriod
              ? getSelectCellConfig({
                  useEmDash: false,
                  useEmDashInGroup: false,
                  useEmDashInTotal: false,
                })
              : getTextCellConfig({
                  useEmDash: false,
                  useEmDashInGroup: false,
                  useEmDashInTotal: false,
                }),
          },
          ...(isOpenPeriod && {
            ...makeEditableIf(isEditableAdjustment, themeMode),
            cellEditorParams: {
              values: (data: CroReconRow) =>
                Object.keys(CroAdjustmentType).filter((adjustmentType) => {
                  const hasCurrent = hasActivityData(
                    CroReconGroupType.Current,
                    data,
                  );
                  const hasAiP = hasActivityData(CroReconGroupType.AiP, data);
                  const hasVendor = hasActivityData(
                    CroReconGroupType.Vendor,
                    data,
                  );
                  const hasAny = hasCurrent || hasAiP || hasVendor;

                  if (
                    adjustmentType ===
                    CroAdjustmentTypeReverse[CroAdjustmentType.OTHR]
                  ) {
                    return hasAny;
                  }
                  if (
                    adjustmentType ===
                    CroAdjustmentTypeReverse[CroAdjustmentType.AMIP]
                  ) {
                    return hasAiP;
                  }
                  if (
                    adjustmentType ===
                    CroAdjustmentTypeReverse[CroAdjustmentType.CROR]
                  ) {
                    return hasVendor;
                  }

                  return true;
                }),
            },
            onCellValueChanged: onAdjustmentTypeChanged,
          }),
          valueGetter:
            'node.data?.isTopsideAdjustment === true ? "Top-side adj." : node.data?.adjustmentType',
          valueSetter: `if (node.data.adjustmentType !== newValue) {
              node.data.adjustmentType = newValue;
              return true;
            }
            return false;`,
        },
        {
          field: 'adjustmentAmountUpdated',
          headerName: 'Adjustment Amount',
          type: 'rightAligned',
          width: 200,
          renderIf: {
            condition: 'isGroupOrRowOrTopsideAdjustment',
            colDef: getToggleableMoneyCellConfig('adjustmentAmountToggleable', {
              cellRendererParams: { currencyKey, useEmDashInTotal: false },
            }),
          },
          ...(isOpenPeriod && {
            onCellValueChanged: onAdjustmentAmountChanged,
            ...makeEditableIf(
              (params) =>
                params.context.currencyViewMode === 'native' &&
                (isTopsideAdjustment(params) ||
                  (isEditableAdjustment(params) &&
                    params.data?.adjustmentType ===
                      CroAdjustmentTypeReverse[CroAdjustmentType.OTHR])),
              themeMode,
            ),
          }),
        },
        {
          headerName: 'Final Reconciled Expense',
          type: 'rightAligned',
          width: 200,
          aggFunc: 'croReconTotalToggleable',
          cellStyle: { fontWeight: 'bold' },
          renderIf: {
            condition: 'isNotChild',
            colDef: getToggleableMoneyCellConfig(
              'finalReconciledExpenseToggleable',
              {
                cellRendererParams: {
                  currencyKey,
                  useEmDash: false,
                  useEmDashInTotal: false,
                },
              },
            ),
          },
        },
      ],
    },
  ];
}

export default function useCroReconGridColumnDefs(
  isOpenPeriod: boolean,
  rowData: CroReconRow[] | undefined,
  costCategory: CroCostCategory,
  currentContractVersionTraceId: TraceId | undefined,
  currentPeriodTraceId: TraceId | undefined,
  regionsAndRegionGroups?: RegionAndRegionGroupResponse[],
): CondorColGroupDef[] {
  const [upsertContractReconActivityGroupRequest] =
    useUpsertContractReconActivityGroupMutation();
  const [updateContractReconTopsideAdjustmentRequest] =
    useUpdateContractReconTopsideAdjustmentMutation();
  const [upsertVendorRepBlaAmount] = useUpsertVendorRepBlaAmountMutation();

  const themeMode = useTheme().palette.mode;
  return useMemo(
    () =>
      processCroReconGridColumnDefs(
        isOpenPeriod,
        rowData,
        costCategory,
        currentContractVersionTraceId,
        currentPeriodTraceId,
        themeMode,
        regionsAndRegionGroups,
        // @ts-expect-error: TS2345
        upsertContractReconActivityGroupRequest,
        updateContractReconTopsideAdjustmentRequest,
        upsertVendorRepBlaAmount,
      ),
    [
      costCategory,
      currentContractVersionTraceId,
      currentPeriodTraceId,
      isOpenPeriod,
      rowData,
      regionsAndRegionGroups,
      upsertContractReconActivityGroupRequest,
      updateContractReconTopsideAdjustmentRequest,
      upsertVendorRepBlaAmount,
      themeMode,
    ],
  );
}
