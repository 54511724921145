import Skeleton from '@mui/material/Skeleton';
import type { SxProps } from '@mui/material/styles';

import SmallDonut from 'shared/lib/graphing/small-donut/SmallDonut';

import TabularDataCell from './TabularDataCell';

type Props = {
  loading?: boolean;
  percentage: number | undefined;
  sx?: SxProps;
};

function TabularDataCellDonut(props: Props) {
  const { loading = false, percentage, sx } = props;

  return (
    <TabularDataCell sx={{ justifyContent: 'center', ...sx }}>
      {loading ? (
        <>
          <Skeleton height={32} variant="circular" width={32} />
          <Skeleton sx={{ ml: 1 }} variant="rectangular" width={32} />
        </>
      ) : (
        <SmallDonut height={32} percentage={percentage ?? 0} width={32} />
      )}
    </TabularDataCell>
  );
}

export default TabularDataCellDonut;
