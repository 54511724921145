import NavigationTabs from 'shared/ui/tabs/NavigationTabs';

import { getOcc, getOccExpenses, getOccRecon } from 'routes';

function OccTabs() {
  const occContractTabs = [
    { key: getOcc(), devLabel: 'MESSI-OCC', label: 'Overview' },
    { key: getOccExpenses(), devLabel: 'BRAAVOS-OCC', label: 'Expenses' },
    {
      key: getOccRecon(),
      devLabel: 'BOURNE-OCC',
      label: 'Recon & Adjustments',
    },
  ];

  return <NavigationTabs tabs={occContractTabs} />;
}

export default OccTabs;
