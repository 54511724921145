import { useState } from 'react';

import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Tabs from 'shared/ui/tabs/Tabs';

import {
  selectForecastParameterDrawerOpen,
  useChangeForecastParameterDrawerOpen,
} from 'forecasting/state/slices/forecastSlice';
import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';

function ParameterDrawer() {
  const isOpen = useSelector(selectForecastParameterDrawerOpen);
  const changeDrawerOpen = useChangeForecastParameterDrawerOpen();

  const [activeTab, setActiveTab] = useState<string>('Patients');

  const onClose = () => changeDrawerOpen(false);

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <DialogTitleWithClose
        sx={{ mb: 0, bgcolor: 'grey.100' }}
        onClose={onClose}
      >
        <Typography variant="subtitle1">Forecast parameters</Typography>
      </DialogTitleWithClose>
      <Tabs
        value={activeTab}
        sx={{
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'grey.300',
        }}
        tabs={[
          { key: 'Patients', label: 'Patients' },
          { key: 'Patient Months', label: 'Patient Months' },
          { key: 'Sites', label: 'Sites' },
          { key: 'Timeline', label: 'Timeline' },
          { key: 'Other', label: 'Other' },
        ]}
        onChange={(_event, newValue) => setActiveTab(newValue)}
      />
    </Drawer>
  );
}

export default ParameterDrawer;
