import { useMemo } from 'react';

import {
  getDateMonthName,
  getYearFromDateString,
} from 'shared/lib/graphing/helper';

function useTimeAxisLabels(months: number | undefined) {
  return useMemo(() => {
    const autoLabels = months === undefined || months >= 60; // 5 years * 12 months

    return {
      autoLabels,
      numTicks: autoLabels ? undefined : months,
      yearLabelDy: autoLabels ? 16 : 32,
      formatMonthLabel: (value?: string) => getDateMonthName(value),
      formatYearLabel: (value?: string) =>
        getYearFromDateString(value, autoLabels),
    };
  }, [months]);
}

export default useTimeAxisLabels;
