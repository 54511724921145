import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import type { ErrorsTextFieldProps } from 'shared/components/text-field/CondorTextField';
import Select from 'shared/ui/select/Select';

import type { PeriodResponse } from 'shared/lib/types';

export default function ForecastPeriodSelector({
  periods,
  handlePeriodChange,
  selectedPeriodId,
  errors,
}: ErrorsTextFieldProps & {
  periods: PeriodResponse[];
  handlePeriodChange: (periodId: string) => void;
  selectedPeriodId?: string;
}) {
  const periodGroupings = groupBy(periods, (period) =>
    format(parseISO(period.end_date), 'yyyy'),
  );
  const periodsByYear = Object.entries(periodGroupings).sort(
    ([yearA], [yearB]) => Number(yearB) - Number(yearA),
  );
  const uniquePeriodsByYear = periodsByYear.map(
    ([year, allPeriodsByYear]) =>
      [
        year,
        orderBy(uniqBy(allPeriodsByYear, 'end_date'), ['end_date'], ['desc']),
      ] as const,
  );
  const theme = useTheme();

  return (
    <>
      <Select
        errors={errors}
        label="Forecast Start"
        value={selectedPeriodId}
        required
        onChange={(event) => handlePeriodChange(event.target.value)}
      >
        {uniquePeriodsByYear.map(([_, yearPeriods]) =>
          yearPeriods.map((period: PeriodResponse) => {
            const periodDateString = format(
              parseISO(period.end_date),
              'MMM yyyy',
            );
            const isOpen = !period.is_closed;
            return (
              <MenuItem
                key={period.end_date}
                value={period.trace_id}
                sx={{
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  },
                }}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    component="div"
                    variant="body1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexFlow: 'row',
                    }}
                  >
                    {periodDateString}
                  </Typography>
                  <Typography color="text.secondary">
                    {isOpen ? 'Open' : 'Closed'}
                  </Typography>
                </Stack>
              </MenuItem>
            );
          }),
        )}
      </Select>
      <FormHelperText>
        Using an open period may cause data to fluctuate if changes are made on
        the accruals.
      </FormHelperText>
    </>
  );
}
