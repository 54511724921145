import { useMemo } from 'react';

import { max } from 'date-fns/max';
import { min } from 'date-fns/min';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';

import { getPeriodHeaderNames } from 'shared/helpers/helpers';
import type {
  CurrencyCode,
  FxRateResponse,
  PeriodResponse,
  TrialAssumptionsResponse,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetFxRatesWithFiltersQuery } from 'shared/api/rtkq/fxrates';
import { useGetPeriodsByCompanyQuery } from 'shared/api/rtkq/periods';
import { useGetTrialAssumptionsByCompanyQuery } from 'shared/api/rtkq/trialassumptions';

type FxRateRow = { [currency in CurrencyCode]?: string } & { end_date: string };

export function processFxRates(
  fxRates?: FxRateResponse[],
  baseCurrency?: CurrencyCode,
  allPeriods?: PeriodResponse[],
  allTrialAssumptions?: TrialAssumptionsResponse[],
) {
  const latestDate = max(
    allPeriods?.map(({ end_date }) => new Date(end_date)) ?? [],
  );

  const assumptionStartDates = allTrialAssumptions
    ?.filter(({ start_up_start_date }) => start_up_start_date !== '1970-01-01')
    .map(({ start_up_start_date }) => new Date(start_up_start_date));
  const earliestDate = min(assumptionStartDates ?? []);

  const period_headers = getPeriodHeaderNames(latestDate, earliestDate);
  const defaultEmptyFxRates = period_headers.map(({ endDate }) => ({
    end_date: endDate,
    currency_to: baseCurrency,
    currency_from: undefined,
    rate: undefined,
  }));

  const fxRatesByEndDate = {
    ...groupBy(defaultEmptyFxRates, 'end_date'),
    ...groupBy(fxRates, 'end_date'),
  };
  const fxGroupedByPeriod: FxRateRow[] = Object.entries(fxRatesByEndDate).map(
    ([date, rates]) => ({
      end_date: date,
      currency_to: baseCurrency,
      ...Object.fromEntries(
        rates.map(({ currency_from, rate }) => [currency_from, rate]),
      ),
    }),
  );

  return cloneDeep(fxGroupedByPeriod);
}

export default function useFxRateRows(baseCurrency?: CurrencyCode) {
  const currentCompany = useSelector(selectCompany);

  const { currentData: fxRates } = useGetFxRatesWithFiltersQuery({
    company: currentCompany.trace_id,
    currency_to: baseCurrency,
  });

  const { currentData: allPeriods } = useGetPeriodsByCompanyQuery(
    currentCompany.trace_id,
  );
  const { currentData: allTrialAssumptions } =
    useGetTrialAssumptionsByCompanyQuery(currentCompany.trace_id);

  return useMemo(
    () =>
      processFxRates(fxRates, baseCurrency, allPeriods, allTrialAssumptions),
    [fxRates, baseCurrency, allPeriods, allTrialAssumptions],
  );
}
