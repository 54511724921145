import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { MappedColumn } from 'shared/lib/column-mapper/types';
import type { SnapshotRecordType } from 'shared/lib/types';

type Props = { headers: MappedColumn[]; rows?: SnapshotRecordType[] };

const DEFAULT_COL_DEF: CondorColDef = {
  sortable: false,
  resizable: true,
  filter: false,
  flex: 1,
};

function UploadCsvReviewGrid(props: Props) {
  const { rows, headers } = props;

  const rowData = useMemo(() => cloneDeep(rows), [rows]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        suppressMovable: true,
        width: 80,
        flex: 0,
        valueGetter: 'node.rowIndex + 1',
      },
      ...headers.map((header) => ({
        headerName: header.label,
        field: header.value,
      })),
    ],
    [headers],
  );

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      defaultColDef={DEFAULT_COL_DEF}
      rowData={rowData}
      sx={useMemo(() => ({ height: '100%', mt: 2 }), [])}
    />
  );
}

export default UploadCsvReviewGrid;
