import { type ChangeEvent } from 'react';

import Delete from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import IconButton from 'shared/ui/icon-button/IconButton';

import type {
  RegionGroupListItemType,
  RegionListItemType,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';

import { validateRegion } from '../validators';

type Props = {
  canDelete?: boolean;
  label: string;
  region: RegionListItemType;
  regionGroups: RegionGroupListItemType[];
  regions: RegionListItemType[];
  onRegionChange: (value: RegionListItemType) => void;
};

function RegionListItem(props: Props) {
  const {
    canDelete = false,
    region,
    regions,
    regionGroups,
    onRegionChange,
    label,
  } = props;

  function handleDelete() {
    onRegionChange({ ...region, action: CRUDAction.DELETE });
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onRegionChange({
      ...region,
      action:
        region.action === CRUDAction.CREATE
          ? CRUDAction.CREATE
          : CRUDAction.UPDATE,
      name: event.target.value,
    });
  };

  return (
    <ListItem disableGutters>
      <Box flex={1}>
        <CondorTextField
          errors={validateRegion(region, regions, regionGroups)}
          label={label}
          sx={{ width: ({ spacing }) => `calc(100% + ${spacing(2)})` }}
          value={region.name}
          onChange={onChange}
        />
      </Box>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        sx={{ width: ({ spacing }) => spacing(9) }}
      >
        <IconButton
          data-testid="delete-region"
          disabled={!canDelete}
          edge="end"
          sx={{ ml: 2, height: 42, width: 42 }}
          onClick={handleDelete}
        >
          <Delete />
        </IconButton>
      </Box>
    </ListItem>
  );
}

export default RegionListItem;
