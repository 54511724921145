import Box from '@mui/material/Box';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';
import ContractContainerFileContents from './ContractContainerFileContents';

function ContractFilesSection() {
  const { contractContainerPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();

  return (
    <DashboardCard title="Contract Files">
      <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
        <ContractContainerFileContents
          parentMenuItem={contractContainerPeriodMenuItemTraceId}
        />
      </Box>
    </DashboardCard>
  );
}

export default ContractFilesSection;
