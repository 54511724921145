import Typography from '@mui/material/Typography';

import Modal from 'shared/components/modal/Modal';

import type { ControlCodeResponse } from 'shared/lib/types';

import { useDeleteControlCodeMutation } from 'shared/api/rtkq/controlcodes';

type Props = {
  controlCode: ControlCodeResponse;
  onClose: () => void;
};

function DeleteControlCode(props: Props) {
  const { controlCode, onClose } = props;
  const [deleteControlCode, { isLoading: deleteControlCodeLoading }] =
    useDeleteControlCodeMutation();

  function onClick() {
    void (async () => {
      await deleteControlCode(controlCode.trace_id);
      onClose();
    })();
  }

  return (
    <Modal
      handleClose={onClose}
      title={`Are you sure you want to delete ${controlCode.control_code}?`}
      ButtonProps={{
        label: 'Delete',
        testId: 'confirm_delete_control_code',
        loading: deleteControlCodeLoading,
        onClick,
      }}
      isOpen
    >
      <Typography>This action cannot be undone.</Typography>
    </Modal>
  );
}

export default DeleteControlCode;
