import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import type {
  PatientCohort,
  PatientCohortEditor,
  PatientCohortError,
  PatientCohortResponse,
} from 'shared/lib/types';

import {
  useCreatePatientCohortMutation,
  useUpdatePatientCohortMutation,
} from 'shared/api/rtkq/patientcohorts';

import { mapPatientCohortFromApiResponse } from '../mapper';

const useSavePatientCohort = (): [
  (patientCohort: PatientCohortEditor) => Promise<{
    data: PatientCohort | undefined;
    error: PatientCohortError | undefined;
  }>,
  { isLoading: boolean },
] => {
  const [createPatientCohortRequest, { isLoading: isLoadingCreate }] =
    useCreatePatientCohortMutation();
  const [updatePatientCohortRequest, { isLoading: isLoadingUpdate }] =
    useUpdatePatientCohortMutation();

  const savePatientCohort = async (patientCohort: PatientCohortEditor) => {
    let apiError: PatientCohortError | undefined;
    let data: PatientCohortResponse | undefined;

    const patientCohortData = {
      trial: patientCohort.trialId,
      name: patientCohort.name,
      order_index: patientCohort.orderIndex,
    };

    try {
      if (patientCohort.traceId && !patientCohort.isNew) {
        data = await updatePatientCohortRequest({
          trace_id: patientCohort.traceId,
          ...patientCohortData,
        }).unwrap();
      } else {
        data = await createPatientCohortRequest(patientCohortData).unwrap();
      }
    } catch (error: unknown) {
      apiError = (error as FetchBaseQueryError).data as PatientCohortError;
    }

    return {
      data: data ? mapPatientCohortFromApiResponse(data) : data,
      error: apiError,
    };
  };

  return [savePatientCohort, { isLoading: isLoadingCreate || isLoadingUpdate }];
};

export default useSavePatientCohort;
