import color from 'color';

import colors from 'colors.module.scss';

import type { RateVsEnrollmentConfig } from './types';

export const RATE_VS_ENROLLMENT_GRAPH_CONFIG: RateVsEnrollmentConfig = {
  margin: { top: 20, right: 80, bottom: 80, left: 150 },
  angleLabels: 'rotate(90,0,0)',
  horizontalLinesColor: colors.n300,
  fontSize: '14px',
  fontSizeYear: '12px',
  fontWeight: '400',
  fontWeightBold: '700',
  columnColor: colors.dividerLightTheme,
  textColor: colors.primaryTextLightTheme,
  yScaleConfig: { type: 'linear' },
  xScaleConfig: { type: 'band', paddingInner: 0.5, paddingOuter: 0.1 },
  forecastedEnrollmentCurveColor: colors.chart1,
  actualEnrollmentCurveColor: colors.chart1,
  actualBarColor: colors.chart10,
  forecastedSpendColorOuter: colors.chart9,
  forecastedBarColor: color(colors.chart9).alpha(0.1).rgb().string(),
  actualSpendText: 'Actual spend',
  forecastedEnrollmentText: 'Forecasted enrollment',
  actualEnrollmentText: 'Actual enrollment',
  forecastedSpendText: 'Forecasted spend',
  orderOfData: ['actualSpend', 'forecastedSpend'],
};
