import { useAuth0 } from '@auth0/auth0-react';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from 'AppRoutes';
import security from 'security';
import AppContainer from 'shared/containers/app-container/AppContainer';

function AppWithAuthentication() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  // https://github.com/reduxjs/redux-toolkit/issues/1331#issuecomment-1080901326
  security.setGetAccessTokenSilently(getAccessTokenSilently);
  security.setGetAccessTokenWithPopup(getAccessTokenWithPopup);

  return (
    <AppContainer>
      <AppRoutes />
    </AppContainer>
  );
}

/**
 * This should only ever be used for testing on localhost
 * when you don't have an internet connection.
 */
export function AppWithoutAuthentication() {
  security.setGetAccessTokenSilently(async (_options?) => {
    await Promise.resolve();
    return '';
  });
  security.setGetAccessTokenWithPopup(async (_options?, _config?) => {
    await Promise.resolve();
    return undefined;
  });

  return (
    <AppContainer dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED>
      <AppRoutes />
    </AppContainer>
  );
}

export default AppWithAuthentication;
