import type SnapshotCell from './SnapshotCell';
import type { Validity } from './Validity';
import type ValidityTag from './ValidityTag';

class TagValueRule {
  protected strategy: Validity;
  protected validityTag: ValidityTag;

  constructor(tag: ValidityTag, strategy: Validity) {
    this.validityTag = tag;
    this.strategy = strategy;
  }

  isSuccess(cell: SnapshotCell): boolean {
    return this.strategy.isValid(cell);
  }

  getValidityTag() {
    return this.validityTag;
  }
}

export default TagValueRule;
