import { useEffect, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import useAllEffectiveDatesFromContractContainer from 'accruals/pages/clinical-expenses/shared/hooks/useAllEffectiveDatesFromContractContainer';
import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import {
  type BackendContractStatus,
  type BackendContractVersion,
  type ContractFile,
  type ContractInfo,
  CroCostCategories,
  GlAccountType,
  type TraceId,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { isFetchBaseQueryError } from 'shared/api/baseApi';
import type { apiJSON } from 'shared/api/rtkq/apiJSON';
import { useGetBottomLineAdjustmentsByContractQuery } from 'shared/api/rtkq/bottomlineadjustments';
import {
  useGetContractContainerQuery,
  useGetContractContainersByCompanyQuery,
} from 'shared/api/rtkq/contractcontainers';
import {
  useCreateContractCostCategoryInfoMutation,
  useGetContractCostCategoryInfoByContractVersionQuery,
  useUpdateContractCostCategoryInfoMutation,
} from 'shared/api/rtkq/contractcostcategoryinfo';
import { useLazyGetContractFilesByContractQuery } from 'shared/api/rtkq/contractfiles';
import { useGetContractWithStatusQuery } from 'shared/api/rtkq/contracts';
import { useGetGlAccountsWithFiltersQuery } from 'shared/api/rtkq/glaccounts';

import useAllPoNumbersFromTrial from '../../pages/clinical-expenses/shared/hooks/useAllPoNumbersFromTrial';
import AccountingInfoForm from './AccountingInfoForm';
import ContractInfoForm from './ContractInfoForm';
import DocumentsInfoForm from './DocumentsInfoForm';
import FinalReview from './FinalReview';
import {
  getBottomLineAdjustmentFromApiResponse,
  getContractCostCategoryInfosFromApiResult,
  getContractInfoFromApiResult,
  mapContractInfoToDataRequest,
} from './helpers';
import LoadingIcon from './icons/loading.svg?react';
import type { ContractVersionErrors } from './types';
import useUpsertBottomLineAdjustments from './useUpsertBottomLineAdjustments';
import useUpsertContractFiles from './useUpsertContractFiles';
import useUpsertContractMutation from './useUpsertContractMutation';
import {
  validateAccountingInfoForm,
  validateContractInfoForm,
  validateDocuments,
} from './validators';

import styles from './ContractVersionWizard.module.scss';

type Props = {
  contractContainerId: TraceId;
  contractStatus?: BackendContractStatus;
  contractVersion?: BackendContractVersion;
  contractVersionTraceId?: TraceId; // used when editing an existing contract
  initialContractInfo?: ContractInfo; // used to pre-populate the form just for our tests
  showVoidedDate?: boolean;
  wizardStartIndex?: number;
  onClose: () => void;
  onSave?: () => void;
};

function ContractVersionWizard(props: Props) {
  const {
    onClose,
    onSave,
    initialContractInfo,
    contractVersionTraceId: contractTraceId,
    contractContainerId,
    wizardStartIndex,
    contractStatus,
    contractVersion,
    showVoidedDate = false,
  } = props;
  const { currentData: contractContainer } =
    useGetContractContainerQuery(contractContainerId);
  const currentCompany = useSelector(selectCompany);
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);
  const allPoNumbersFromTrial = useAllPoNumbersFromTrial(
    contractContainer?.trace_id,
  );
  const [allEffectiveDates, allAipEffectiveDates] =
    useAllEffectiveDatesFromContractContainer(
      contractContainer?.trace_id,
      contractTraceId,
    );
  const { currentData: existingGlAccounts } = useGetGlAccountsWithFiltersQuery({
    company: currentCompany.trace_id,
    account_type: GlAccountType.EXPENSE_ACCOUNT,
  });
  const [isAccountingInfoFormValid, setIsAccountingInfoFormValid] =
    useState<boolean>(false);

  const emptyContractInfo: ContractInfo = {
    contractStatus: contractStatus ?? 'CURRENT',
    contractVersion: contractVersion ?? 'LOI',
    versionName: '',
    poNumber: '',
    effectiveDate: '',
    aipEffectiveDate: '',
    voidedDate: '',
    accountInfo: {
      useCnfThreshold: false,
      cnfThresholdAmount: undefined,
      cnfThresholdPercentage: undefined,
      bonusesAndPenalties: '',
      bottomLineAdjustments: [],
      costCategoryInfos: [],
    },
    documents: [],
  };
  const [contractInfo, setContractInfo] = useState<ContractInfo>(
    initialContractInfo ?? { ...emptyContractInfo },
  );
  const [fieldErrors, setFieldErrors] = useState<ContractVersionErrors>({});
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [upsertContractMutation, { isLoading: upsertContractIsLoading }] =
    useUpsertContractMutation();
  const [
    upsertBottomLineAdjustments,
    { isLoading: upsertBlaIsLoading, errors: upsertAdjustmentErrors },
  ] = useUpsertBottomLineAdjustments();
  const [
    upsertContractFiles,
    { isLoading: upsertFilesIsLoading, errors: upsertFilesErrors },
  ] = useUpsertContractFiles();
  const [fetchContractFiles] = useLazyGetContractFilesByContractQuery();
  const { currentData: contractDetails } = useGetContractWithStatusQuery(
    contractTraceId
      ? { trace_id: contractTraceId, otherParameter: period.trace_id }
      : skipToken,
  );
  const { currentData: bottomLineAdjustments } =
    useGetBottomLineAdjustmentsByContractQuery(contractTraceId ?? skipToken);
  const [
    createContractCostCategoryInfo,
    { isLoading: _isContractCostCategoryInfoLoading },
  ] = useCreateContractCostCategoryInfoMutation();
  const [
    updateContractCostCategoryInfo,
    { isLoading: _isUpdateContractCostCategoryInfoLoading },
  ] = useUpdateContractCostCategoryInfoMutation();
  const { currentData: contractCostCategoryInfos } =
    useGetContractCostCategoryInfoByContractVersionQuery(
      contractTraceId ?? skipToken,
    );

  const { currentData: allContractContainersFromCompany } =
    useGetContractContainersByCompanyQuery(currentCompany.trace_id);

  const currentPeriodVersion = useSelector(selectPeriodVersion);

  useEffect(() => {
    if (initialContractInfo) {
      setContractInfo(initialContractInfo);
    }
  }, [initialContractInfo]);

  useEffect(() => {
    if (contractContainer && !initialContractInfo && !contractTraceId) {
      setContractInfo((prev) => ({
        ...prev,
        accountInfo: {
          ...prev.accountInfo,
          costCategoryInfos:
            contractContainer.vendor_type === 'CRO'
              ? CroCostCategories.map((category) => ({
                  category,
                  gl_account: undefined,
                  gross_value: undefined,
                }))
              : [
                  {
                    category: '',
                    gl_account: undefined,
                    gross_value: undefined,
                  },
                ],
        },
      }));
    }
  }, [contractContainer, initialContractInfo, contractTraceId]);

  const allCategories = useMemo(
    () =>
      contractInfo.accountInfo.costCategoryInfos?.map((info) => info.category),
    [contractInfo.accountInfo.costCategoryInfos],
  );

  useEffect(() => {
    const { adjustmentErrors, gl_accounts_per_category, ...errors } =
      validateAccountingInfoForm(contractInfo.accountInfo, allCategories);
    if (
      Object.keys(errors).length > 0 ||
      (adjustmentErrors && Object.keys(adjustmentErrors).length) ||
      gl_accounts_per_category?.length ||
      upsertFilesErrors.length
    ) {
      setIsAccountingInfoFormValid(false);
      setFieldErrors((allErrors) => ({
        ...allErrors,
        gl_accounts_per_category,
      }));
      return;
    }

    setIsAccountingInfoFormValid(true);
    setFieldErrors({});
    setFormErrors([]);
  }, [contractInfo.accountInfo, allCategories]);

  useEffect(() => {
    if (!Object.keys(upsertAdjustmentErrors).length) {
      return;
    }

    setFieldErrors((allErrors) => ({
      ...allErrors,
      adjustmentErrors: upsertAdjustmentErrors,
    }));
  }, [upsertAdjustmentErrors]);

  const allPoNumbersFromCompany = useMemo(
    () =>
      allContractContainersFromCompany
        ?.filter((container) => container.trial !== trial.trace_id)
        .flatMap((container) =>
          container.trace_id !== contractContainer?.trace_id
            ? container.po_numbers
            : [],
        ) ?? [],
    [
      allContractContainersFromCompany,
      contractContainer?.trace_id,
      trial.trace_id,
    ],
  );

  useEffect(() => {
    const errors = validateContractInfoForm(
      contractInfo,
      allPoNumbersFromTrial,
      allEffectiveDates,
      allAipEffectiveDates,
    );
    setFieldErrors((allErrors) => ({
      ...allErrors,
      version: errors.version,
      effective_date: errors.effective_date,
      aip_effective_date: errors.aip_effective_date,
      voided_date: errors.voided_date,
      po_number: errors.po_number,
    }));
  }, [
    allPoNumbersFromTrial,
    contractInfo,
    allEffectiveDates,
    allAipEffectiveDates,
  ]);

  useEffect(() => {
    const fetchContractDetails = async () => {
      if (!contractDetails || !contractContainer) {
        return;
      }
      const newContractCostCategoryInfos =
        getContractCostCategoryInfosFromApiResult(
          contractCostCategoryInfos ?? [],
          contractContainer.vendor_type,
        );

      const contractFiles = await getContractFiles(contractDetails.trace_id);
      setContractInfo(
        getContractInfoFromApiResult(
          contractDetails,
          contractContainer,
          bottomLineAdjustments?.map(getBottomLineAdjustmentFromApiResponse) ??
            [],
          contractFiles,
          newContractCostCategoryInfos,
        ),
      );
    };

    void fetchContractDetails();
  }, [
    contractDetails,
    contractCostCategoryInfos,
    bottomLineAdjustments,
    contractContainer,
  ]);

  async function getContractFiles(contractDetailsTraceId: TraceId) {
    if (!contractInfo.documents.length) {
      const files = await fetchContractFiles(contractDetailsTraceId).unwrap();
      return files.map((file) => ({
        traceId: file.trace_id,
        tag: file.tag,
        file_name: file.file_name,
      }));
    }

    return contractInfo.documents;
  }

  const resetModal = () => {
    setContractInfo(emptyContractInfo);
    clearFieldErrors();
    setFormErrors([]);
  };

  const clearFieldErrors = () => {
    setFieldErrors({});
  };

  const syncAllData = async () => {
    try {
      const data = mapContractInfoToDataRequest(
        contractInfo,
        contractContainerId,
        period.trace_id,
      );

      const result = await upsertContractMutation(data, contractInfo.traceId);
      const newContractInfo = {
        ...contractInfo,
        traceId: result.trace_id,
        periodVersion: currentPeriodVersion,
      };
      setContractInfo(newContractInfo);
      await syncAccountingInfo(newContractInfo);
      await upsertContractFiles(
        newContractInfo.documents,
        newContractInfo.traceId,
        currentPeriodVersion.trace_id,
      );
    } catch (error: unknown) {
      if (isFetchBaseQueryError(error)) {
        setFieldErrors(error.data as apiJSON);
      }

      if (typeof error === 'string') {
        setFormErrors([error]);
      } else if (error instanceof Error) {
        setFormErrors([error.message]);
      }

      throw new Error('Save contract version failed');
    }
  };

  const syncGlAccountGrossValues = async (newContractInfo: ContractInfo) => {
    const newContractCostCategoryInfo =
      contractInfo.accountInfo.costCategoryInfos ?? [];
    for (const glAccountPerCategory of newContractCostCategoryInfo) {
      if (glAccountPerCategory.trace_id) {
        // eslint-disable-next-line no-await-in-loop -- we need to wait for each update to finish since the BE signals logic depends on the snapshotId created in the first one
        await updateContractCostCategoryInfo({
          category: glAccountPerCategory.category,
          gl_account: glAccountPerCategory.gl_account,
          gross_value: glAccountPerCategory.gross_value,
          trace_id: glAccountPerCategory.trace_id,
        });
      } else {
        // eslint-disable-next-line no-await-in-loop -- we need to wait for each update to finish since the BE signals logic depends on the snapshotId created in the first one
        const result = await createContractCostCategoryInfo({
          gl_account: glAccountPerCategory.gl_account,
          gross_value: glAccountPerCategory.gross_value,
          category: glAccountPerCategory.category,
          contract_version: newContractInfo.traceId,
        }).unwrap();
        glAccountPerCategory.trace_id = result.trace_id;
      }
    }
    return newContractCostCategoryInfo;
  };

  const syncAccountingInfo = async (newContractInfo: ContractInfo) => {
    const newAdjustments = await upsertBottomLineAdjustments(newContractInfo);
    const newAccountingInfo = { ...newContractInfo.accountInfo };
    if (newAdjustments.length > 0) {
      newAccountingInfo.bottomLineAdjustments = newAdjustments;
    }

    newAccountingInfo.costCategoryInfos =
      await syncGlAccountGrossValues(newContractInfo);

    setContractInfo({
      ...newContractInfo,
      accountInfo: {
        ...newContractInfo.accountInfo,
        bottomLineAdjustments: newAdjustments,
      },
    });
  };

  const handleCloseBtnClick = () => {
    onClose();
    resetModal();
  };

  const handleSaveBtnClick = async () => {
    await syncAllData();
    onClose();
    onSave?.();
    resetModal();
  };

  function getPoNumberIsUsedInAnotherTrial() {
    if (!contractInfo.poNumber) {
      return false;
    }

    if (allPoNumbersFromTrial.includes(contractInfo.poNumber)) {
      return false;
    }

    // should contain po numbers from current company except the ones for the current trial
    return allPoNumbersFromCompany.includes(contractInfo.poNumber);
  }

  const contractInfoErrors = validateContractInfoForm(
    contractInfo,
    allPoNumbersFromTrial,
    allEffectiveDates,
    allAipEffectiveDates,
  );

  return (
    <Dialog
      className={styles.ContractVersionWizard}
      maxWidth={false}
      scroll="paper"
      fullWidth
      open
    >
      <Wizard
        devTitle="FAUST"
        startIndex={wizardStartIndex ?? 1}
        title={`${contractTraceId ? 'Edit' : 'New'} contract version`}
        stepNames={[
          'Contract info',
          'Accounting info',
          'Document upload',
          'Review',
        ]}
        onClose={handleCloseBtnClick}
      >
        <WizardStep formErrors={formErrors} header="" disableNextButton>
          <div className={styles.processingFiles}>
            <LoadingIcon />
            <br />
            Processing files...
          </div>
        </WizardStep>
        <WizardStep
          description="Fields with (*) are required."
          disableNextButton={Object.keys(contractInfoErrors).length > 0}
          fieldValidator={() => contractInfoErrors}
          formErrors={formErrors}
          header="Fill in the contract information"
          setFieldErrors={setFieldErrors}
          disableBackButton
        >
          <ContractInfoForm
            contractContainer={contractContainer}
            contractInfo={contractInfo}
            fieldErrors={fieldErrors}
            isEdit={!!contractTraceId}
            isForAip={contractStatus === 'AIP'}
            poNumberIsUsedInAnotherTrial={getPoNumberIsUsedInAnotherTrial()}
            setContractInfo={setContractInfo}
            showVoidedDate={showVoidedDate}
          />
        </WizardStep>
        <WizardStep
          description="Fields with (*) are required."
          disableNextButton={!isAccountingInfoFormValid}
          formErrors={formErrors}
          header="Fill in accounting information"
          readyToContinue={formErrors.length === 0}
          setFieldErrors={setFieldErrors}
        >
          {contractContainer && (
            <AccountingInfoForm
              contractContainer={contractContainer}
              contractInfo={contractInfo}
              existingGlAccounts={existingGlAccounts ?? []}
              fieldErrors={fieldErrors}
              setContractInfo={setContractInfo}
            />
          )}
        </WizardStep>
        <ContractVersionDocumentUploadStep
          clearFieldErrors={clearFieldErrors}
          contractInfo={contractInfo}
          errors={upsertFilesErrors}
          formErrors={formErrors}
          setContractInfo={setContractInfo}
        />
        <FinalReview
          contractInfo={contractInfo}
          existingGlAccounts={existingGlAccounts ?? []}
          formErrors={formErrors}
          header="Review the contract record"
          vendorType={contractContainer?.vendor_type ?? 'CRO'}
          isLoading={
            upsertContractIsLoading ||
            upsertBlaIsLoading ||
            upsertFilesIsLoading
          }
          onNextAsync={handleSaveBtnClick}
        />
      </Wizard>
    </Dialog>
  );
}

function ContractVersionDocumentUploadStep(props: {
  clearFieldErrors: () => void;
  contractInfo: ContractInfo;
  errors?: ContractFile[];
  formErrors: string[];
  setContractInfo: React.Dispatch<React.SetStateAction<ContractInfo>>;
}) {
  const {
    formErrors,
    contractInfo,
    setContractInfo,
    clearFieldErrors,
    errors,
  } = props;
  return (
    <WizardStep
      description="Add the contract record document."
      formErrors={formErrors}
      header="Upload contract documentation."
      disableNextButton={
        Object.keys(validateDocuments(contractInfo.documents)).length > 0
      }
    >
      <DocumentsInfoForm
        clearFieldErrors={clearFieldErrors}
        contractInfo={contractInfo}
        errors={errors}
        setContractInfo={setContractInfo}
      />
    </WizardStep>
  );
}

export default ContractVersionWizard;
