import { useEffect, useMemo, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import IconButton from 'shared/ui/icon-button/IconButton';

import {
  selectForecast,
  selectForecastParameterDrawerOpen,
  useChangeForecastParameterDrawerOpen,
} from 'forecasting/state/slices/forecastSlice';
import useIsReadOnlyPermission from 'shared/lib/read-only-permission/useIsReadOnlyPermission';
import {
  COLLAPSED_MENU_WIDTH,
  MENU_WIDTH,
} from 'shared/lib/sidebar/SidebarContainer';
import type { ForecastParameterResponse } from 'shared/lib/types';
import { ForecastParameterType } from 'shared/lib/types';
import { selectSidebarCollapsed } from 'shared/state/slices/sidebarSlice';

import {
  useCreateForecastParameterMutation,
  useGetForecastParametersByForecastQuery,
  useUpdateForecastParameterMutation,
} from 'shared/api/rtkq/forecastparameters';
import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';
import { useGetCurrentRegionsQuery } from 'shared/api/rtkq/regions';

const DRAWER_HEIGHT = 248;
const DRAWER_HEADER_HEIGHT = 40;
const DRAWER_CONTAINER_HEIGHT = DRAWER_HEIGHT - DRAWER_HEADER_HEIGHT;
const PARAMETERS = [
  ForecastParameterType.ENROLLMENT_RATE,
  ForecastParameterType.SITE_ACTIVATION_RATE,
];

const makeKey = (type: ForecastParameterType, regionName: string) =>
  `${type}_${regionName}`;

const formatNumber = (value: number | null | undefined) =>
  Number((value ?? 0).toPrecision(6));

function ParameterPanel() {
  const forecast = useSelector(selectForecast);
  const sidebarCollapsed = useSelector(selectSidebarCollapsed);
  const isOpen = useSelector(selectForecastParameterDrawerOpen);
  const changeDrawerOpen = useChangeForecastParameterDrawerOpen();
  const isReadOnly = useIsReadOnlyPermission();

  const [parameterValues, setParameterValues] = useState<
    Record<string, { value: number | null; dirty: boolean } | undefined>
  >({});

  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  const [createForecastParameter] = useCreateForecastParameterMutation();
  const [updateForecastParameter] = useUpdateForecastParameterMutation();

  const { currentData: regions = [] } = useGetCurrentRegionsQuery(
    forecast.start_period,
  );

  const uniqueRegionNames = useMemo(
    () =>
      regions
        .map((region) => region.name)
        .filter((name, index, self) => self.indexOf(name) === index)
        .sort((param1, param2) => param1.localeCompare(param2)),
    [regions],
  );

  const parameters = useMemo(() => {
    const result: Record<
      ForecastParameterType,
      Record<string, ForecastParameterResponse>
    > = Object.fromEntries(PARAMETERS.map((type) => [type, {}])) as Record<
      ForecastParameterType,
      Record<string, ForecastParameterResponse>
    >;

    if (forecastParameters) {
      for (const param of forecastParameters) {
        if (PARAMETERS.includes(param.type)) {
          result[param.type][param.region_name] = param;
        }
      }
    }
    return result;
  }, [forecastParameters]);

  const handleParameterUpsert = (
    parameter: ForecastParameterResponse | undefined,
    type: ForecastParameterType,
    regionName: string,
    forcedValue?: number,
  ) => {
    void (async () => {
      const key = makeKey(type, regionName);
      const value = parameterValues[key];
      if (forcedValue === undefined && (value === undefined || !value.dirty)) {
        return;
      }

      const newValue = formatNumber(forcedValue ?? value?.value);
      setParameterValues({
        ...parameterValues,
        [key]: { value: newValue, dirty: false },
      });

      if (parameter === undefined) {
        await createForecastParameter({
          forecast: forecast.trace_id,
          type,
          region_name: regionName,
          value: newValue,
        }).unwrap();
      } else {
        await updateForecastParameter({
          ...parameter,
          value: newValue,
        }).unwrap();
      }
    })();
  };

  useEffect(() => {
    for (const type of PARAMETERS) {
      for (const regionName of uniqueRegionNames) {
        const key = makeKey(type, regionName);
        if (type in parameters && regionName in parameters[type]) {
          const parameter = parameters[type][regionName];
          setParameterValues((currentParameterValues) => ({
            ...currentParameterValues,
            [key]: { value: Number(parameter.value), dirty: false },
          }));
        }
      }
    }
  }, [parameters, uniqueRegionNames]);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: isOpen ? 0 : -DRAWER_CONTAINER_HEIGHT,
        overflow: 'hidden',
        transition: ({ transitions }) =>
          transitions.create(['bottom', 'width']),
        zIndex: ({ zIndex }) => zIndex.drawer,
        width: `calc(100% - ${sidebarCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px)`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          height: DRAWER_HEADER_HEIGHT,
          px: 3,
          backgroundColor: ({ palette }) => palette.common.black,
        }}
        onClick={() => changeDrawerOpen(!isOpen)}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
          }}
        >
          <TuneIcon sx={{ color: 'white', width: 16, height: 16 }} />
          <Typography
            color="white"
            sx={{ pl: 1, lineHeight: 'normal' }}
            variant="subtitle2"
          >
            Parameters
          </Typography>
        </Box>
        <ExpandMoreIcon
          sx={{
            color: 'white',
            transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: ({ transitions }) => transitions.create(['transform']),
            width: 16,
            height: 16,
          }}
        />
      </Box>
      <Paper
        sx={{
          height: DRAWER_CONTAINER_HEIGHT,
          overflowY: 'scroll',
          px: 3,
          pt: 2,
          backgroundColor: 'grey.100',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            {PARAMETERS.map((type) => (
              <Box
                key={type}
                sx={{ flex: 1, display: 'flex', flexFlow: 'column', my: 1 }}
              >
                <Box>
                  <Typography
                    component="span"
                    sx={{ pr: 0.5 }}
                    variant="subtitle2"
                  >
                    {type === ForecastParameterType.ENROLLMENT_RATE
                      ? 'Enrollment rate'
                      : 'Site activation rate'}
                  </Typography>{' '}
                  <Typography
                    color="text.secondary"
                    component="span"
                    variant="body1"
                  >
                    (
                    {type === ForecastParameterType.ENROLLMENT_RATE
                      ? 'Patients per site per month'
                      : 'Sites per month'}
                    )
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexFlow: 'row',
                    width: '100%',
                    my: 1,
                  }}
                >
                  {uniqueRegionNames.map((regionName) => {
                    const parametersWithType = parameters[type];
                    const parameter =
                      regionName in parametersWithType
                        ? parametersWithType[regionName]
                        : undefined;
                    const parameterKey = makeKey(type, regionName);

                    return (
                      <Box
                        key={parameterKey}
                        sx={{
                          display: 'flex',
                          flexFlow: 'row',
                          alignItems: 'center',
                          mr: 3,
                        }}
                      >
                        <Typography sx={{ mr: 1 }} variant="subtitle2">
                          {regionName}
                        </Typography>
                        {isReadOnly ? (
                          <Typography variant="body2">
                            {parameterValues[parameterKey] !== undefined
                              ? formatNumber(
                                  parameterValues[parameterKey]?.value,
                                )
                              : ''}
                          </Typography>
                        ) : (
                          <CondorTextField
                            disabled={forecast.locked}
                            size="small"
                            type="number"
                            variant="outlined"
                            endAdornment={
                              defaultParameters ? (
                                <IconButton
                                  disabled={forecast.locked}
                                  sx={{ height: 32, width: 32 }}
                                >
                                  <Tooltip
                                    placement="right"
                                    title="Reset to actual"
                                    arrow
                                  >
                                    <RefreshIcon
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        handleParameterUpsert(
                                          parameter,
                                          type,
                                          regionName,
                                          defaultParameters[type][regionName],
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              ) : undefined
                            }
                            sx={{
                              width: '100%',
                              mr: 2,
                              backgroundColor: ({ palette }) =>
                                palette.background.paper,
                            }}
                            value={
                              parameterValues[parameterKey] !== undefined
                                ? formatNumber(
                                    parameterValues[parameterKey]?.value,
                                  )
                                : ''
                            }
                            onBlur={() =>
                              handleParameterUpsert(parameter, type, regionName)
                            }
                            onChange={(evt) => {
                              setParameterValues((values) => ({
                                ...values,
                                [parameterKey]: {
                                  value: +evt.target.value,
                                  dirty: true,
                                },
                              }));
                            }}
                            onKeyUp={(evt) => {
                              if (evt.key === 'Enter') {
                                handleParameterUpsert(
                                  parameter,
                                  type,
                                  regionName,
                                );
                              }
                            }}
                          />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export { DRAWER_HEADER_HEIGHT, DRAWER_HEIGHT };
export default ParameterPanel;
