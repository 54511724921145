import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import {
  type RegionsAndAssumptionGroups,
  calculateAssumptionGroups,
  createGroups,
} from 'shared/lib/contract-assumptions/hooks/useRegionsAndAssumptionGroups';
import type {
  AssumptionGroupRequestDisplay,
  RegionGroupListItemType,
  RegionGroupResponse,
  RegionListItemType,
  TraceId,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';

import { useGetContractAssumptionGroupsByContractVersionQuery } from 'shared/api/rtkq/assumptiongroups';
import { useGetClosedPeriodAssumptionGroupsByContractVersionQuery } from 'shared/api/rtkq/contracts';
import {
  useGetRegionGroupsByContractContainerQuery,
  useGetRegionGroupsByContractVersionQuery,
} from 'shared/api/rtkq/regiongroups';
import {
  useGetRegionsByContractContainerQuery,
  useGetRegionsByContractVersionQuery,
} from 'shared/api/rtkq/regions';

export function useAccrualsRegionsAndAssumptionGroups(
  contractVersionTraceId?: TraceId,
  readOnly = false,
  inheritedRegions: string[] = [],
  inheritedRegionGroups: RegionGroupResponse[] = [],
): RegionsAndAssumptionGroups {
  const { contractContainerTraceId } = useContractAndPeriodWithVersions();

  const { currentData: databaseRegions } = useGetRegionsByContractVersionQuery(
    contractVersionTraceId ?? skipToken,
  );

  const { currentData: regionsFromContractContainer } =
    useGetRegionsByContractContainerQuery(
      contractContainerTraceId ?? skipToken,
    );
  const { currentData: regionGroupsFromContractContainer } =
    useGetRegionGroupsByContractContainerQuery(
      contractContainerTraceId ?? skipToken,
    );

  const { currentData: databaseRegionGroups } =
    useGetRegionGroupsByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  const { currentData: databaseAssumptionGroups } =
    useGetContractAssumptionGroupsByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  const { currentData: closedPeriodAssumptionGroups } =
    useGetClosedPeriodAssumptionGroupsByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  const [allRegions, setAllRegions] = useState<RegionListItemType[]>([]);
  const [allRegionGroups, setAllRegionGroups] = useState<
    RegionGroupListItemType[]
  >([]);

  const [defaultRegions, setDefaultRegions] = useState<RegionListItemType[]>(
    [],
  );
  const [defaultRegionGroups, setDefaultRegionGroups] = useState<
    RegionGroupListItemType[]
  >([]);

  const [assumptionGroups, setAssumptionGroups] = useState<
    AssumptionGroupRequestDisplay[]
  >([]);

  const editableRegions = allRegions.filter(
    (region) => region.action !== CRUDAction.DELETE,
  );

  function applyCalculations(
    updatedAssumptionGroups: AssumptionGroupRequestDisplay[],
  ) {
    setAssumptionGroups(calculateAssumptionGroups(updatedAssumptionGroups));
  }

  useEffect(() => {
    if (databaseRegions !== undefined) {
      const uniqueDatabaseNames = new Set(
        databaseRegions.map((region) => region.name),
      );
      const inheritedItems = inheritedRegions
        .filter((region) => !uniqueDatabaseNames.has(region))
        .map((region) => ({
          name: region,
          new: true,
          action: CRUDAction.CREATE,
          contract_container: contractContainerTraceId ?? '',
          trace_id: region,
          vendor: '',
          regiongroups: [],
        }));
      setAllRegions([
        ...databaseRegions.map((dbRegion) => ({ ...dbRegion, new: false })),
        ...inheritedItems,
      ]);
    }

    if (
      regionsFromContractContainer !== undefined &&
      databaseRegions?.length === 0
    ) {
      setDefaultRegions(
        regionsFromContractContainer.map((ccRegion) => ({
          ...ccRegion,
          new: true,
          action: CRUDAction.CREATE,
        })),
      );
    }
  }, [
    databaseRegions,
    regionsFromContractContainer,
    inheritedRegions.length, // Infinite loop if we do inheritedRegions
  ]);

  useEffect(() => {
    // always prepend the global regionGroup
    if (databaseRegionGroups !== undefined) {
      const newRegionGroups = [
        { name: 'Global (all added regions)', global: true },
      ] as RegionGroupListItemType[];
      setAllRegionGroups([
        ...newRegionGroups,
        ...databaseRegionGroups.map((dbRegionGroup) => ({
          ...dbRegionGroup,
          global: false,
          new: false,
        })),
        ...inheritedRegionGroups.map((regionGroup) => ({
          ...regionGroup,
          trace_id: `${regionGroup.trace_id}_new`, // Force a new trace_id to avoid collisions with existing groups
          global: false,
          new: true,
          action: CRUDAction.CREATE,
        })),
      ]);
    }

    if (
      regionGroupsFromContractContainer !== undefined &&
      databaseRegionGroups?.length === 0
    ) {
      const newDefaultRegionGroups = [
        { name: 'Global (all added regions)', global: true },
      ] as RegionGroupListItemType[];
      setDefaultRegionGroups([
        ...newDefaultRegionGroups,
        ...regionGroupsFromContractContainer.map((ccRegionGroup) => ({
          ...ccRegionGroup,
          global: false,
          new: true,
          action: CRUDAction.CREATE,
        })),
      ]);
    }
  }, [
    databaseRegionGroups,
    regionGroupsFromContractContainer,
    inheritedRegionGroups.length, // Infinite loop if we do inheritedRegionGroups
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: as this examines assumptionGroups, which is what this generates, this will cause an infinite loop if added
  useEffect(() => {
    if (contractVersionTraceId) {
      setAssumptionGroups(
        createGroups(
          contractVersionTraceId,
          readOnly,
          allRegions,
          assumptionGroups,
          databaseAssumptionGroups,
        ),
      );
    }
  }, [
    contractVersionTraceId,
    allRegions,
    databaseAssumptionGroups,
    closedPeriodAssumptionGroups,
  ]);

  return [
    editableRegions,
    assumptionGroups,
    allRegions,
    defaultRegions,
    setAllRegions,
    allRegionGroups,
    defaultRegionGroups,
    setAllRegionGroups,
    applyCalculations,
  ];
}
