import color from 'color';

import colors from 'colors.module.scss';

import type { CostPerPatientConfig, CostPerPatientDatum } from './types';

export const COST_PER_PATIENT_GRAPH_CONFIG: CostPerPatientConfig = {
  margin: { top: 20, right: 80, bottom: 80, left: 150 },
  angleLabels: 'rotate(90,0,0)',
  horizontalLinesColor: colors.n300,
  fontSize: '14px',
  fontSizeYear: '12px',
  fontWeight: '400',
  fontWeightBold: '700',
  columnColor: colors.dividerLightTheme,
  textColor: colors.primaryTextLightTheme,
  yScaleConfig: { type: 'linear' },
  xScaleConfig: { type: 'band', paddingInner: 0.5, paddingOuter: 0.1 },
  forecastedEnrollmentCurveColor: colors.chart1,
  actualEnrollmentCurveColor: colors.chart1,
  forecastedSpendColorOuter: colors.chart9,
  actualBarColor: colors.chart10,
  forecastedBarColor: color(colors.chart9).alpha(0.1).rgb().string(),
  forecastedSpendText: 'Forecasted cost',
  actualSpendText: 'Actual cost',
  budgetedCostPerPatient: 'Budgeted cost',
  contractedBudget: 'contractedBudget',
  orderOfData: ['actualSpend', 'forecastedSpend'] as Array<
    keyof Pick<CostPerPatientDatum, 'actualSpend' | 'forecastedSpend'>
  >,
};

export function humanizeCostPerPatientSend(dataKey: string) {
  switch (dataKey) {
    case 'actualSpend':
      return COST_PER_PATIENT_GRAPH_CONFIG.actualSpendText;
    case 'forecastedSpend':
      return COST_PER_PATIENT_GRAPH_CONFIG.forecastedSpendText;
    default:
      return dataKey;
  }
}
