import type { ReactElement, ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';

type Props = {
  children: ReactNode;
  sx?: SxProps<Theme>;
  title?: string;
  titleAction?: ReactElement;
};

function DashboardCard({ title, titleAction, children, sx }: Props) {
  return (
    <Paper sx={{ mt: 4, p: 4, '&:first-of-type': { mt: 0 }, ...sx }} square>
      {!!title && (
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          mb={3}
        >
          <Typography color="primary.main" variant="h6">
            {title}
          </Typography>
          {titleAction}
        </Stack>
      )}
      {children}
    </Paper>
  );
}

export default DashboardCard;
