import { memo } from 'react';

import type { CustomTooltipProps } from '@ag-grid-community/react';
import Box from '@mui/material/Box';

function CustomHeaderTooltip(props: CustomTooltipProps) {
  const { value } = props;
  return (
    <Box
      component="div"
      sx={{
        maxWidth: 300,
        backgroundColor: (theme) => theme.palette.grey[800],
        color: 'white',
        padding: 1,
        borderRadius: 1,
      }}
    >
      {value}
    </Box>
  );
}

export default memo(CustomHeaderTooltip);
