import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import CondorTextField from 'shared/components/text-field/CondorTextField';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { selectCompany } from 'shared/state/slices/companySlice';

import withCompanyPageRequiredData from 'shared/api/hocs/withCompanyPageRequiredData';

function CompanySettingsPage() {
  const company = useSelector(selectCompany);

  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <Paper sx={{ width: '480px', p: 3, alignSelf: 'center' }}>
          <Typography mb={3} variant="subtitle1">
            Settings
          </Typography>

          <Typography variant="subtitle2">Company name</Typography>
          <CondorTextField
            size="small"
            sx={{ mt: 1 }}
            type="text"
            value={company.name}
            disabled
            fullWidth
          />
        </Paper>
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withCompanyPageRequiredData(CompanySettingsPage);
