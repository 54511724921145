import type { MappedColumn } from 'shared/lib/column-mapper/types';

const columns: MappedColumn[] = [
  { label: 'PO Number', value: 'po_number', required: true },
  { label: 'PO Amount', value: 'po_amount', required: true },
  { label: 'G/L account', value: 'gl_account', required: true },
  { label: 'Status', value: 'status', required: false },
  { label: 'Date', value: 'date', required: false },
  { label: 'Vendor', value: 'vendor', required: true },
  { label: 'Description', value: 'description', required: false },
  { label: 'Currency', value: 'currency', required: true },
  { label: 'Trial ID', value: 'study_id', required: true },
];

export default columns;
