import { useState } from 'react';

import { useSelector } from 'react-redux';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import ColumnMapperStep from 'shared/lib/column-mapper/ColumnMapperStep';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import {
  UnmappedFileEntityType,
  type UnmappedFileResponse,
  type UploadedFile,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import useOnCloseLogic from 'shared/wizards/hooks/useOnCloseLogic';
import UploadUnmappedCsvStep from 'shared/wizards/upload-unmapped-csv-step/UploadUnmappedCsvStep';

import {
  useCreateSiteListingSnapshotFromCsvMutation,
  useDeleteSiteListingSnapshotMutation,
} from 'shared/api/rtkq/sitelistingsnapshots';

import columns from './columns';
import SiteListingConfirmationStep from './steps/SiteListingConfirmationStep';
import SiteListingReviewStep from './steps/SiteListingReviewStep';

type SiteListingUploadWizardProps = {
  onClose: () => void;
  onComplete?: (message: string) => void;
};

function SiteListingUploadWizard(props: SiteListingUploadWizardProps) {
  const { onClose: onCloseProp, onComplete } = props;
  const [snapshotId, setSnapshotId] = useState<string>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const [deleteSnapshot, { isLoading: _deleteIsLoading }] =
    useDeleteSiteListingSnapshotMutation();
  const [setFfMappingId, handleCloseBtnClick] = useOnCloseLogic();

  const currentPeriodVersion = useSelector(selectPeriodVersion);

  function onClose(isLastStepSaveClick?: boolean) {
    handleCloseBtnClick(
      uploadedFiles,
      deleteSnapshot,
      setUploadedFiles,
      setSnapshotId,
      onCloseProp,
      isLastStepSaveClick,
      snapshotId,
    );
  }

  function saveUrl(unmappedFiles: UnmappedFileResponse[]) {
    setUploadedFiles(
      unmappedFiles.map((file) => ({
        csvBlobUrl: file.file,
        unmappedFileUrl: file.url,
        traceId: file.trace_id,
      })),
    );
  }

  const uploadHeader = 'Upload your site listing file';

  const mapperHeader = 'Assign these headers to your Site Listing report';

  return (
    <FullWidthDialog open>
      <Wizard
        devTitle="BRAN"
        stepNames={['Map Columns', 'Validate', 'Review']}
        title="Site Listing Upload"
        onClose={onClose}
      >
        <UploadUnmappedCsvStep
          entityClass={UnmappedFileEntityType.SiteSnapshot}
          fileTypeWithGrammaticalArticle="a Site Listing report"
          fileUploadType="SS"
          header={uploadHeader}
          periodVersion={currentPeriodVersion}
          setSnapshotId={setSnapshotId}
          onSave={saveUrl}
        />
        <ColumnMapperStep
          columns={columns}
          setFfMappingId={setFfMappingId}
          setSnapshotId={setSnapshotId}
          stepTitleText={mapperHeader}
          uploadedFiles={uploadedFiles}
          useCreateSnapshotEndpoint={
            useCreateSiteListingSnapshotFromCsvMutation
          }
        />
        <SiteListingConfirmationStep snapshotId={snapshotId} />
        <SiteListingReviewStep
          snapshotId={snapshotId}
          onComplete={() =>
            onComplete
              ? onComplete('Site Listing Report uploaded successfully')
              : onCloseProp()
          }
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default SiteListingUploadWizard;
