import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import NavigationTabs from 'shared/ui/tabs/NavigationTabs';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { getFinancialCloseTab } from 'routes';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import { useGetPeriodVersionsByPeriodQuery } from 'shared/api/rtkq/periodversions';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import ChecklistTabContent from './ChecklistTabContent';
import PeriodVersionsTabContent from './PeriodVersionsTabContent';

function FinancialClosePage() {
  const { activeTab } = useParams();
  const navigate = useNavigate();
  const period = useSelector(selectPeriod);
  const { currentData: periodVersions = [] } =
    useGetPeriodVersionsByPeriodQuery(period.trace_id);
  const hasMultiplePeriods = periodVersions.length > 1;

  useEffect(() => {
    if (!hasMultiplePeriods && activeTab !== 'checklist') {
      navigate(getFinancialCloseTab('checklist'));
    }
  }, [activeTab, hasMultiplePeriods, navigate]);

  const tabContent = activeTab
    ? {
        checklist: <ChecklistTabContent />,
        'period-versions': (
          <PeriodVersionsTabContent
            currentPeriod={period}
            periodVersions={periodVersions}
          />
        ),
      }[activeTab]
    : undefined;

  return (
    <PageContentLoadingContainer
      tabs={
        hasMultiplePeriods && (
          <NavigationTabs
            activeTab={activeTab}
            keyToUrl={getFinancialCloseTab}
            tabs={[
              { key: 'checklist', devLabel: '', label: 'Checklist' },
              {
                key: 'period-versions',
                devLabel: 'CHRONOS',
                label: 'Period Versions',
              },
            ]}
          />
        )
      }
    >
      {tabContent}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(FinancialClosePage);
