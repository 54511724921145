import { useMemo } from 'react';

import {
  getDateCellConfig,
  getGenericCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

export default function useCompanyFilesColumnDefs(
  _isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      {
        headerName: 'File name',
        field: 'file_name',
        cellRenderer: 'AgGridFileHyperlinkCellRenderer',
        flex: 1,
      },
      {
        headerName: 'File category',
        field: 'file_category',
        width: 200,
        ...getGenericCellConfig({
          useEmDash: false,
          useEmDashInGroup: false,
          useEmDashInTotal: false,
        }),
      },
      {
        headerName: 'Uploaded by',
        field: 'uploaded_by',
        width: 200,
        ...getGenericCellConfig({
          useEmDash: false,
          useEmDashInGroup: false,
          useEmDashInTotal: false,
        }),
      },
      {
        headerName: 'Upload date',
        field: 'uploaded',
        width: 150,
        ...getDateCellConfig({ endIcon: undefined }),
      },
    ],
    [],
  );
}
