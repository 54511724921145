import { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';

import Modal from 'shared/components/modal/Modal';
import CondorTextField from 'shared/components/text-field/CondorTextField';

import useInputErrors from 'shared/lib/sidebar/hooks/useInputErrors';
import type { ForecastResponse } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import {
  useGetForecastsByTrialQuery,
  useUpdateForecastMutation,
} from 'shared/api/rtkq/forecasts';

type Props = {
  forecast: ForecastResponse;
  handleCloseModal: () => void;
};

function RenameForecastModal({ forecast, handleCloseModal }: Props) {
  const trial = useSelector(selectTrial);
  const { name: existingName, trace_id } = forecast;
  const [nameInput, setNameInput] = useState<string>(existingName);
  const { inputErrors, markInputError } = useInputErrors();
  const [updateForecast, { isLoading: updateForecastLoading }] =
    useUpdateForecastMutation();
  const { currentData: existingForecasts } = useGetForecastsByTrialQuery(
    trial.trace_id,
  );

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameInput(event.target.value);
    if (inputErrors.name) {
      markInputError('name', false);
    }
  };

  const handleSave = () => {
    if (!nameInput.trim()) {
      markInputError('name');
      return;
    }
    if (
      existingForecasts?.some(
        (existingFolder) => existingFolder.name === nameInput,
      )
    ) {
      markInputError('name', 'A forecast with this name already exists.');
      return;
    }

    void (async () => {
      await updateForecast({
        trace_id,
        name: nameInput,
      });

      handleCloseModal();
    })();
  };

  return (
    <Modal
      handleClose={handleCloseModal}
      title="Rename forecast"
      ButtonProps={{
        label: 'Save',
        testId: 'ForecastModalRename',
        loading: updateForecastLoading,
        disabled: !nameInput || nameInput === existingName,
        onClick: handleSave,
      }}
      isOpen
    >
      <FormControl sx={{ width: '100%', mt: 1 }}>
        <CondorTextField
          autoComplete="off"
          errors={inputErrors.name}
          label="Name"
          name="name"
          type="text"
          value={nameInput}
          required
          onChange={handleNameChange}
        />
      </FormControl>
    </Modal>
  );
}

export default RenameForecastModal;
