import { useMemo } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import { currencyToNumberFormatter, formatValue } from 'formatters';
import type { LabGridRow, TraceId } from 'shared/lib/types';

import { useUpdateLabContractMutation } from 'shared/api/rtkq/labcontracts';
import {
  useDeleteSafeVisitCostMutation,
  useUpsertVisitCostMutation,
} from 'shared/api/rtkq/visitcosts';

export default function useLabCostMatrixGridOptions(
  isOpenPeriod: boolean,
): CondorGridOptions<LabGridRow> {
  const [upsertVisitCost] = useUpsertVisitCostMutation();
  const [deleteVisitCost] = useDeleteSafeVisitCostMutation();
  const [updateLabContract] = useUpdateLabContractMutation();

  return useMemo(() => {
    const onCellValueChangedVisitCost = (params: CellValueChangedEvent) => {
      // users can copy and paste any value they want, so ignore "bad" values
      const cost = currencyToNumberFormatter(params.newValue);
      if (!Number.isNaN(cost)) {
        void upsertVisitCost({
          site_or_lab_contract: params.data
            .site_or_lab_contract_trace_id as TraceId,
          patient_assessment: params.colDef.cellEditorParams.trace_id,
          cost,
        });
      } else {
        // because of how data can be added via copy and paste, we have to assume we don't already
        // have the correct cost trace_id and need to check via version and patient assessment
        void deleteVisitCost({
          site_or_lab_contract: params.data
            .site_or_lab_contract_trace_id as TraceId,
          patient_assessment: params.colDef.cellEditorParams.trace_id,
        });
      }
    };

    const onCellValueChangedLabContract = (
      params: CellValueChangedEvent,
      columnName: string,
    ) => {
      const { lab_contract_trace_id } = params.data;
      void updateLabContract({
        trace_id: lab_contract_trace_id,
        [columnName]: formatValue(params.newValue),
      });
    };

    const onCellValueChanged = (params: CellValueChangedEvent) => {
      const columnName = params.colDef.field ?? '';
      if (columnName.startsWith('visit_')) {
        onCellValueChangedVisitCost(params);
      } else {
        onCellValueChangedLabContract(params, columnName);
      }

      // redraw the rows as the individual cellRenderer result might have changed
      // https://www.ag-grid.com/react-data-grid/view-refresh/#redraw-rows
      params.api.redrawRows();
    };

    return {
      getRowId: 'lab_contract_trace_id',
      ...(isOpenPeriod && { onCellValueChanged }),
    };
  }, [isOpenPeriod, deleteVisitCost, updateLabContract, upsertVisitCost]);
}
