import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { SignOffResponse, SignoffBy, TraceId } from 'shared/lib/types';
import { SignoffType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import { selectUser } from 'shared/state/slices/userSlice';

import {
  useCreateSignOffMutation,
  useDeleteSignOffMutation,
} from 'shared/api/rtkq/financialclosesignoffs';

type Props = {
  preparer: SignOffTask;
  reviewer: SignOffTask;
  task: SignOffResponse['task'];
};

function SignOff(props: Props) {
  const { preparer, reviewer, task } = props;

  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);
  const currentUser = useSelector(selectUser);

  const [createSignOff, { isLoading: createIsLoading }] =
    useCreateSignOffMutation();
  const [deleteSignOff, { isLoading: deleteIsLoading }] =
    useDeleteSignOffMutation();

  const isLoading = createIsLoading || deleteIsLoading;
  const preparerEnabled =
    currentUser === trial.preparer?.trace_id &&
    !isLoading &&
    !preparer.disabled;
  const reviewerEnabled =
    currentUser === trial.reviewer?.trace_id &&
    currentUser !== preparer.user_trace_id &&
    !isLoading &&
    !reviewer.disabled;

  async function onChange(
    type: SignoffType,
    checked: boolean,
    traceId?: TraceId,
  ) {
    if (!checked && traceId) {
      try {
        await deleteSignOff(traceId);

        if (type === SignoffType.PREPARER && reviewer.traceId) {
          // remove reviewer
          await deleteSignOff(reviewer.traceId);
        }

        return;
      } catch {
        // user will see error message from the API in the Toast
      }
    }

    if (checked && !traceId) {
      try {
        const result = await createSignOff({
          task,
          period: period.trace_id,
          signoff_type: type,
        }).unwrap();

        if (
          type === SignoffType.REVIEWER &&
          result.signoff_by.trace_id === preparer.user_trace_id
        ) {
          // preparer and reviewer are the same person
          await deleteSignOff(result.trace_id);
        }
      } catch {
        // user will see error message from the API in the Toast
      }
    }
  }

  return (
    <Stack>
      <FormControlLabel
        checked={preparer.checked}
        disabled={!preparerEnabled}
        label={preparer.label}
        control={
          <Switch
            onChange={(_, checked) =>
              void onChange(SignoffType.PREPARER, checked, preparer.traceId)
            }
          />
        }
        sx={{
          [`& .${formControlLabelClasses.label}`]: {
            fontSize: (theme) => theme.typography.body1.fontSize,
          },
        }}
      />
      <FormControlLabel
        checked={reviewer.checked}
        disabled={!reviewerEnabled}
        label={reviewer.label}
        control={
          <Switch
            onChange={(_, checked) =>
              void onChange(SignoffType.REVIEWER, checked, reviewer.traceId)
            }
          />
        }
        sx={{
          [`& .${formControlLabelClasses.label}`]: {
            fontSize: (theme) => theme.typography.body1.fontSize,
          },
        }}
      />
    </Stack>
  );
}

export type SignOffTask = {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  traceId?: TraceId;
  user_trace_id?: SignoffBy['trace_id'];
};

export default SignOff;
