import { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Select from 'shared/ui/select/Select';

import type { SiteRecordResponse } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetSitesByTrialQuery } from 'shared/api/rtkq/sites';

type Props = {
  disableNextButton?: boolean;
  onSave: ((site: SiteRecordResponse | undefined) => void) | undefined;
};

function SiteInfoStep(props: Props) {
  const trial = useSelector(selectTrial);
  const [siteRecord, setSiteRecord] = useState<SiteRecordResponse>();
  const { currentData: sites, isLoading } = useGetSitesByTrialQuery(
    trial.trace_id,
  );
  const { disableNextButton = false, onSave } = props;

  function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: trace_id } = event.target;
    const site = sites?.find((site) => site.trace_id === trace_id);
    setSiteRecord(site);
  }

  useEffect(() => {
    setSiteRecord(sites?.[0]);
  }, [sites]);

  return (
    <WizardStep
      disableNextButton={disableNextButton}
      nextButtonTextOverride="Create Version"
      onNext={() => onSave?.(siteRecord)}
    >
      <Typography sx={{ mb: 2 }} variant="subtitle1">
        Which site do you want to add a version for?
      </Typography>
      {!isLoading && (
        <FormControl sx={{ width: 400 }}>
          <Select
            name="Site"
            placeholder="Select site"
            value={siteRecord?.trace_id ?? ''}
            onChange={onFieldChange}
          >
            {sites?.map((site) => (
              <MenuItem key={site.trace_id} value={site.trace_id}>
                {`${site.number} (${site.name})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </WizardStep>
  );
}

export default SiteInfoStep;
