import type {
  AuditLogGridResponse,
  AuditLogResponse,
  ContentTypeResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';

const CONTENT_TYPE = 'CONTENT_TYPE';

const apiEndpointFactory = constructApi<AuditLogResponse>('auditlogs').withTags(
  'AUDIT_LOG',
  [CONTENT_TYPE],
);
const api = apiEndpointFactory.inject(() => ({
  getAuditLog:
    apiEndpointFactory.getWithFiltersAndPaging<AuditLogGridResponse>(
      'get_audit_log',
    ),
  getContentTypes: apiEndpointFactory.getWithFiltersAndPaging<
    ContentTypeResponse[]
  >('get_content_types', undefined, [CONTENT_TYPE]),
}));

export const { useLazyGetAuditLogQuery, useGetContentTypesQuery } = api;
