import { useState } from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  children: React.ReactNode;
  onAddAttachments: (attachments: File[]) => void;
};

function FilesDropZone(props: Props) {
  const { children, onAddAttachments } = props;
  const [dragEnter, setDragEnter] = useState<boolean>(false);

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    const attachments = [...event.dataTransfer.files];
    setDragEnter(false);
    onAddAttachments(attachments);
  }

  function onDragEnter(event: React.DragEvent<HTMLDivElement>) {
    setDragEnter(true);
    event.preventDefault();
    event.stopPropagation();
  }

  function onDragLeave(event: React.DragEvent<HTMLDivElement>) {
    setDragEnter(false);
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <Box
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragEnter}
      onDrop={handleDrop}
    >
      {children}
      {dragEnter && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'white',
            opacity: 0.9,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FileUploadIcon color="primary" fontSize="large" />
          <Typography mt={2} variant="body1">
            Drag and drop files here.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default FilesDropZone;
