import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import ControlCodesGrid from './ControlCodesGrid';
import ControlCodesHeader from './ControlCodesHeader';

function ControlCodes() {
  return (
    <DashboardCard sx={{ borderRadius: 1 }}>
      <ControlCodesHeader />
      <ControlCodesGrid />
    </DashboardCard>
  );
}

export default ControlCodes;
