import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

function EmptyState() {
  return (
    <Box px={3} py={2}>
      <Typography variant="caption">No comments</Typography>
    </Box>
  );
}

export default EmptyState;
