import { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import SnackbarError from 'shared/components/snackbar-error/SnackbarError';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import Alert from 'shared/ui/alert/Alert';
import Button from 'shared/ui/button/Button';

import withPeriodSpecificRecordWrapper from 'shared/lib/periods/withPeriodSpecificRecordWrapper';
import { PeriodRecordBlobType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useUpdateCompanyMutation } from 'shared/api/rtkq/companies';

type Errors = { amount?: string; percent?: string };

function VarianceThresholdForm() {
  const currentCompany = useSelector(selectCompany);

  const [amount, setAmount] = useState<number | undefined>(
    currentCompany.variance_threshold_amount,
  );
  const [percent, setPercent] = useState<number | undefined>(
    currentCompany.variance_threshold_percent,
  );
  const [updateCompanyRequest] = useUpdateCompanyMutation();
  const [errors, setErrors] = useState<Errors>({});
  const [apiError, setApiError] = useState<string | undefined>();
  const [showSuccess, setShowSuccess] = useState(false);

  const validate = () => {
    const result: Errors = {};
    if (percent && (percent < 0 || percent > 100)) {
      result.percent = 'Percent must be between 0 and 100';
    }
    return result;
  };

  const handleSave = async () => {
    const newErrors = validate();
    setErrors(newErrors);
    if (Object.keys(newErrors).length) {
      return;
    }
    let result;
    try {
      result = await updateCompanyRequest({
        trace_id: currentCompany.trace_id,
        variance_threshold_amount: amount,
        variance_threshold_percent: percent,
      }).unwrap();
      setShowSuccess(true);
    } catch {
      setApiError(`Error saving. ${JSON.stringify(result)}`);
    }
  };

  return (
    <>
      <Typography fontWeight="bold">
        Enter your company’s $ and % variance thresholds:
      </Typography>
      <FormControl
        sx={{
          mt: 4,
          width: 500,
          display: 'flex',
          gap: 5,
          flexDirection: 'row',
        }}
      >
        <CondorTextField
          errors={errors.amount}
          placeholder="Enter amount"
          startAdornment="$"
          type="number"
          value={amount ?? ''}
          onChange={(event) => setAmount(Number(event.target.value))}
        />
        <CondorTextField
          endAdornment="%"
          errors={errors.percent}
          placeholder="Enter percent"
          type="number"
          value={percent ?? ''}
          onChange={(event) => setPercent(Number(event.target.value))}
        />
      </FormControl>
      <Button
        color="primary"
        sx={{ marginTop: 2 }}
        testId="save"
        variant="contained"
        onClick={() => void handleSave()}
      >
        Save
      </Button>
      {!!apiError && <SnackbarError message={apiError} />}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success">Changes saved!</Alert>
      </Snackbar>
    </>
  );
}

export default withPeriodSpecificRecordWrapper(
  VarianceThresholdForm,
  PeriodRecordBlobType.VARIANCE_THRESHOLD,
);
