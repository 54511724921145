export type CsvRow = Record<string, string>;
export function filterTrailingEmptyRows(rows: CsvRow[]): CsvRow[] {
  const result: CsvRow[] = [];
  let testTrailingRow = true;
  for (let i = rows.length - 1; i >= 0; i--) {
    const row = rows[i];

    if (testTrailingRow) {
      const nonEmptyValues = Object.values(row).filter(Boolean);
      if (nonEmptyValues.length) {
        result.push(row);
        testTrailingRow = false;
      }
    } else {
      result.push(row);
    }
  }
  result.reverse();
  return result;
}

export function trimKeysAndValuesInRow(row: CsvRow): CsvRow {
  return Object.keys(row).reduce<CsvRow>((acc, key) => {
    acc[removeExtraQuotesAndSpaces(key)] = removeExtraQuotesAndSpaces(row[key]);
    return acc;
  }, {});
}

export function removeExtraQuotesAndSpaces(
  str: number | string | undefined,
): string {
  return String(str ?? '')
    .trim()
    .replace(/^"|"$/g, '');
}
