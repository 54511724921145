import type { FxRateResponse } from 'shared/lib/types';

import constructApi from './constructApi';

export const FX_RATES = 'FX_RATES';

const apiEndpointFactory =
  constructApi<FxRateResponse>('fxrates').withTags(FX_RATES);
const api = apiEndpointFactory.inject(() => ({
  getFxRatesByCompany: apiEndpointFactory.getBy('company'),
  getFxRatesWithFilters: apiEndpointFactory.getWithFilters('-end_date'),
  upsertFxRate: apiEndpointFactory.create([], 'upsert'),
}));

export const {
  useGetFxRatesByCompanyQuery,
  useLazyGetFxRatesByCompanyQuery,
  useGetFxRatesWithFiltersQuery,
  useLazyGetFxRatesWithFiltersQuery,
  useUpsertFxRateMutation,
} = api;
