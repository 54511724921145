import type { GlAccountResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const apiEndpointFactory =
  constructApi<GlAccountResponse>('glaccounts').withTags('GL_ACCOUNTS');
export const api = apiEndpointFactory.inject(() => ({
  getGlAccountsByCompany: apiEndpointFactory.getBy('company'),
  getGlAccountsWithFilters: apiEndpointFactory.getWithFilters(),
  deleteGlAccount: apiEndpointFactory.delete(),
  createGlAccount: apiEndpointFactory.create(),
  updateGlAccount: apiEndpointFactory.update(),
}));

export const {
  useCreateGlAccountMutation,
  useGetGlAccountsByCompanyQuery,
  useGetGlAccountsWithFiltersQuery,
  useLazyGetGlAccountsByCompanyQuery,
  useUpdateGlAccountMutation,
  useDeleteGlAccountMutation,
} = api;
