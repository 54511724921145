import type { ReactElement } from 'react';

import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import usePatientActivityColumnDefs from '../hooks/usePatientActivityColumnDefs';
import usePatientActivityGridOptions from '../hooks/usePatientActivityGridOptions';
import usePatientActivityRows from '../hooks/usePatientActivityRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function PatientActivityGrid(props: Props): ReactElement {
  const { sx, overlayNoRowsTemplate } = props;

  const columnDefs = useGridColDefs(usePatientActivityColumnDefs);
  const gridOptions = useGridOptions(usePatientActivityGridOptions);
  const rowData = usePatientActivityRows();

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  PatientActivityGrid,
  PeriodGridBlobType.PATIENT_ACTIVITY,
);
