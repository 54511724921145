import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import type { CompanyFilesGridRow } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetCompanyFilesQuery } from 'shared/api/rtkq/companies';

export function processCompanyFiles(
  companyFilesRecords: { rows: CompanyFilesGridRow[] } | undefined,
) {
  return cloneDeep(companyFilesRecords?.rows);
}

export default function useCompanyFilesRows(
  _isOpenPeriod: boolean,
): CompanyFilesGridRow[] | undefined {
  const currentCompany = useSelector(selectCompany);
  const { currentData: companyFilesGrid } = useGetCompanyFilesQuery(
    currentCompany.trace_id,
  );

  return useMemo(
    () => processCompanyFiles(companyFilesGrid),
    [companyFilesGrid],
  );
}
