import type { PieArcDatum } from '@visx/shape/lib/shapes/Pie';

import type { DonutArcProps } from './DonutArc';
import DonutArc from './DonutArc';

type DonutProps<Datum> = Omit<DonutArcProps<Datum>, 'arc'> & {
  arcs: Array<PieArcDatum<Datum>>;
};

export default function Donut<Datum>(props: DonutProps<Datum>) {
  const { arcs, path, getKey, getColor } = props;

  return (
    <>
      {arcs.map((arc, index) => (
        <DonutArc
          // biome-ignore lint/suspicious/noArrayIndexKey: -- there is nothing else to use as a key
          key={index}
          arc={arc}
          getColor={getColor}
          getKey={getKey}
          path={path}
        />
      ))}
    </>
  );
}
