import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

import AipSvg from 'shared/assets/icons/svg/Aip.svg?react';

import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';

type Props = { onAIPCreated: () => void };

function AmendmentInProgressMissing(props: Props) {
  const { onAIPCreated } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box sx={{ margin: 1.25, width: '120px', height: '120px' }}>
        <AipSvg />
      </Box>
      <h2>No amendment in progress</h2>
      If you have agreed with the CRO that an amendment is necessary, start one
      here.
      <PeriodAwareButton
        startIcon={<Add />}
        sx={{ margin: '20px' }}
        testId="add_amendment_in_progress"
        variant="contained"
        disableElevation
        onClick={onAIPCreated}
      >
        Add Amendment In Progress
      </PeriodAwareButton>
    </Box>
  );
}

export default AmendmentInProgressMissing;
