import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SmallDonut from 'shared/lib/graphing/small-donut/SmallDonut';

function PercentContracted() {
  return (
    <Box>
      <Box
        display="flex"
        gap={2}
        justifyContent="space-between"
        justifyItems="center"
        my={2}
        width={150}
      >
        <Typography variant="subtitle2">Budget</Typography>
        <SmallDonut
          height={32}
          percentage={(14_137_105 / 41_998_364) * 100}
          width={32}
        />
      </Box>
      <Box
        display="flex"
        gap={2}
        justifyContent="space-between"
        justifyItems="center"
        my={2}
        width={150}
      >
        <Typography variant="subtitle2">Sites</Typography>
        <SmallDonut height={32} percentage={(63 / 92) * 100} width={32} />
      </Box>
      <Box
        display="flex"
        gap={2}
        justifyContent="space-between"
        justifyItems="center"
        my={2}
        width={150}
      >
        <Typography variant="subtitle2">Patients</Typography>
        <SmallDonut height={32} percentage={(20 / 200) * 100} width={32} />
      </Box>
      <Box
        display="flex"
        gap={2}
        justifyContent="space-between"
        justifyItems="center"
        my={2}
        width={150}
      >
        <Typography variant="subtitle2">Month</Typography>
        <SmallDonut height={32} percentage={(20 / 42) * 100} width={32} />
      </Box>
    </Box>
  );
}

export default PercentContracted;
