import type { ICellRendererParams } from '@ag-grid-community/core';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import Chip, { chipClasses } from 'shared/ui/chip/Chip';

import { humanize } from 'shared/helpers/helpers';

function AgGridUserStatusCellRenderer(
  params: ICellRendererParams<string | undefined>,
) {
  const { value, node } = params;

  if (node.key !== null) {
    // We don't want to show chips for groups
    return value;
  }

  return (
    <Chip
      avatar={<ArrowDropDown />}
      label={humanize(value?.toLowerCase() ?? '')}
      size="small"
      variant="filled"
      color={
        value === 'Active'
          ? 'success'
          : value === 'Inactive'
            ? 'default'
            : 'warning'
      }
      sx={{
        marginTop: '6px',
        flexDirection: 'row-reverse',
        [`&.${chipClasses.root}`]: { px: 0.5 },
        [`& .${chipClasses.label}`]: { pr: 0 },
        [`& .${chipClasses.avatar}`]: { m: 0 },
      }}
    />
  );
}

export default AgGridUserStatusCellRenderer;
