import { useState } from 'react';

import type {
  BaseQueryFn,
  TypedMutationTrigger,
} from '@reduxjs/toolkit/query/react';

import type { TraceId, UploadedFile } from 'shared/lib/types';

import { useDeleteFfMappingMutation } from 'shared/api/rtkq/ffmappings';
import { useDeleteUnmappedFileMutation } from 'shared/api/rtkq/unmappedfiles';

type DeleteMutationTriggerType = TypedMutationTrigger<
  void,
  string,
  BaseQueryFn
>;

export default function useOnCloseLogic(): [
  (id: TraceId) => void,
  (
    uploadedFiles: UploadedFile[],
    deleteSnapshot: DeleteMutationTriggerType,
    setUploadedFiles: (value: React.SetStateAction<UploadedFile[]>) => void,
    setSnapshotId: (value: React.SetStateAction<string | undefined>) => void,
    onClose: () => void,
    isLastStepSaveClick?: boolean,
    snapshotId?: string,
  ) => void,
] {
  const [ffMappingId, setFfMappingId] = useState<TraceId>();
  const [deleteFfMapping, { isLoading: _deleteFfMappingLoading }] =
    useDeleteFfMappingMutation();
  const [deleteUnmappedFile, { isLoading: _deleteUnmappedFileLoading }] =
    useDeleteUnmappedFileMutation();

  function deleteSnapshotAndFiles(
    uploadedFiles: UploadedFile[],
    deleteSnapshotFn: DeleteMutationTriggerType,
    snapshotId?: string,
  ) {
    if (snapshotId) {
      void deleteSnapshotFn(snapshotId);
    }
    for (const file of uploadedFiles) {
      void deleteUnmappedFile(file.traceId);
    }

    if (ffMappingId) {
      void deleteFfMapping(ffMappingId);
    }
  }

  function handleCloseBtnClick(
    uploadedFiles: UploadedFile[],
    deleteSnapshot: DeleteMutationTriggerType,
    setUploadedFiles: (value: React.SetStateAction<UploadedFile[]>) => void,
    setSnapshotId: (value: React.SetStateAction<string | undefined>) => void,
    onClose: () => void,
    isLastStepSaveClick?: boolean,
    snapshotId?: string,
  ) {
    if (!isLastStepSaveClick) {
      deleteSnapshotAndFiles(uploadedFiles, deleteSnapshot, snapshotId);
      setUploadedFiles([]);
      setSnapshotId(undefined);
    }
    onClose();
  }

  return [setFfMappingId, handleCloseBtnClick];
}
