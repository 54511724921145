import { skipToken } from '@reduxjs/toolkit/query';

import {
  useGetAllFeatureFlagsQuery,
  useGetFeatureFlagsByNameQuery,
} from 'shared/api/rtkq/featureFlags';

function useFeatureFlag(name: string) {
  const { data: allFeatureFlags = [] } = useGetAllFeatureFlagsQuery(); // This is loaded once and saved in memory so we can get immediate results
  const matchingFeatureFlag = allFeatureFlags.find(
    (flag) => flag.name === name,
  );
  const {
    currentData: featureFlags = [matchingFeatureFlag ?? { enabled: false }],
  } = useGetFeatureFlagsByNameQuery(matchingFeatureFlag ? skipToken : name); // If the feature flag doesn't exist yet, this will auto-create it

  return featureFlags.length !== 0 && featureFlags[0].enabled;
}

export default useFeatureFlag;
