import { useMemo } from 'react';

import type { TrialExpenseSummaryGridResponse } from 'shared/lib/types';
import { processTrialExpenseSummaryRows } from 'shared/processors/processTrialExpenseSummaryRows';

export default function useTrialExpenseSummaryRows(
  expenseSummaryGrid?: TrialExpenseSummaryGridResponse,
  monthPrefix = '',
) {
  return useMemo(
    () => processTrialExpenseSummaryRows(expenseSummaryGrid, monthPrefix),
    [expenseSummaryGrid, monthPrefix],
  );
}
