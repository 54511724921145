import Stack from '@mui/material/Stack';

import Chip from 'shared/ui/chip/Chip';

type Props = {
  attachments: File[] | undefined;
  onDeleteAttachment: (attachment: File) => void;
};

function NewAttachmentsList(props: Props) {
  const { attachments, onDeleteAttachment } = props;

  return (
    <Stack
      columnGap={2}
      flexDirection="row"
      flexWrap="wrap"
      mt={1}
      rowGap={1}
      sx={{ maxHeight: '112px', overflowY: 'auto' }}
    >
      {attachments?.map((attachment) => (
        <Chip
          key={attachment.name}
          label={attachment.name}
          onDelete={() => onDeleteAttachment(attachment)}
        />
      ))}
    </Stack>
  );
}

export default NewAttachmentsList;
