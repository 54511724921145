import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { JournalEntryRow } from 'shared/lib/types';

import { useGetJournalEntriesQuery } from 'shared/api/rtkq/periods';

export function processJournalEntryRows(
  journalEntryGrid?: { rows: JournalEntryRow[] } | undefined,
) {
  return journalEntryGrid ? cloneDeep(journalEntryGrid.rows) : undefined;
}

export default function useJournalEntryRows(): JournalEntryRow[] | undefined {
  const period = useSelector(selectPeriod);
  const { currentData: journalEntries } = useGetJournalEntriesQuery(
    period.trace_id,
  );

  return useMemo(
    () => processJournalEntryRows(journalEntries),
    [journalEntries],
  );
}
