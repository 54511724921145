import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import useTrialActivitySitesColumnDefs from 'accruals/pages/trial/hooks/useTrialActivitySitesColumnDefs';
import useTrialActivitySitesGridOptions from 'accruals/pages/trial/hooks/useTrialActivitySitesGridOptions';
import useTrialActivitySitesRows from 'accruals/pages/trial/hooks/useTrialActivitySitesRows';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { PeriodGridBlobType } from 'shared/lib/types';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function SitesTrialActivityGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useTrialActivitySitesColumnDefs(true);
  const rowData = useTrialActivitySitesRows();
  const gridOptions = useTrialActivitySitesGridOptions(true);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  SitesTrialActivityGrid,
  PeriodGridBlobType.TRIAL_ACTIVITY_SITES,
);
