import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import uniq from 'lodash/uniq';

import BudgetForecast from 'forecasting/components/graphing/budget-forecast/BudgetForecast';

import useFinancialForecastSummaryRows from 'forecasting/pages/forecasting/hooks/useFinancialForecastSummaryRows';

import { getSum } from './utils';

function BudgetForecastGraph() {
  const { loading, rows: summaryRows } = useFinancialForecastSummaryRows();

  const budgetForecast = useMemo(() => {
    const vendors = uniq(summaryRows?.map((row) => row.vendor));
    return vendors.map((vendor) => {
      const vendorRows = summaryRows?.filter((row) => row.vendor === vendor);
      const contractedValue = getSum(vendorRows, 'default_contract_value') ?? 0;
      const expensedValue = getSum(vendorRows, 'reconciled_expense') ?? 0;
      const forecastedValue = getSum(vendorRows, 'grand_total') ?? 0;

      return {
        vendorName: vendor,
        contracted: { startPercentage: 0, endPercentage: 100 },
        ltdExpense: {
          startPercentage: 0,
          endPercentage: contractedValue
            ? (expensedValue / contractedValue) * 100
            : 0,
        },
        forecasted: {
          startPercentage: 0,
          endPercentage: contractedValue
            ? (forecastedValue / contractedValue) * 100
            : 0,
        },
      };
    });
  }, [summaryRows]);

  return (
    <ParentSize>
      {(parent) => {
        const graphWidth = Math.max(parent.width, 600);

        return loading ? (
          <Skeleton
            height={parent.height}
            variant="rectangular"
            width={graphWidth}
          />
        ) : (
          <BudgetForecast
            data={budgetForecast}
            height={parent.height - 32}
            width={graphWidth}
          />
        );
      }}
    </ParentSize>
  );
}

export default BudgetForecastGraph;
