import { type ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';

import TrialsPopover from 'shared/components/trial-selector/TrialsPopover';

type Props = { children: ReactNode };

function TrialSelector(props: Props) {
  const { children: contents } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpenSelector = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
          '&:hover': { backgroundColor: 'grey.300' },
        }}
        onClick={handleOpenSelector}
      >
        {contents}
        <ArrowDropDownIcon
          sx={{
            color: (theme) => theme.palette.common.black,
            transition: (theme) => theme.transitions.create('transform'),
            ...(open && { transform: 'rotate(180deg)' }),
          }}
        />
      </Box>
      <TrialsPopover
        anchorEl={anchorEl}
        handleCloseSelector={handleCloseSelector}
        open={open}
        onClose={handleCloseSelector}
      />
    </>
  );
}

export default TrialSelector;
