import { type ControlListingTaskType } from 'shared/lib/types';

export function getTaskGroup(taskType: ControlListingTaskType): string {
  switch (taskType) {
    case 'UPLOAD_PO_LISTING':
    case 'UPLOAD_INVOICE_LISTING':
    case 'UPDATE_CURRENT_CONTRACTS_AND_AIPS':
    case 'UPDATE_PATIENT_DATA':
    case 'UPLOAD_SITES_AND_LABS':
    case 'UPLOAD_FX_RATES':
      return 'Data collection';
    case 'CRO_CONTRACTS':
    case 'OTHER_CLINICAL_CONTRACTS':
      return 'Expenses & reconciliation';
    case 'REVIEW_ACCRUAL_CALCULATIONS':
    case 'GENERATE_JOURNAL_ENTRY':
      return 'Accruals & journal entry';
    default:
      return taskType;
  }
}

export function getTaskTitle(taskType: ControlListingTaskType): string {
  switch (taskType) {
    case 'UPLOAD_PO_LISTING':
      return 'Upload PO listing';
    case 'UPLOAD_INVOICE_LISTING':
      return 'Upload invoice listing';
    case 'UPDATE_CURRENT_CONTRACTS_AND_AIPS':
      return 'Update current contracts and amendments';
    case 'UPDATE_PATIENT_DATA':
      return 'Update patient activity';
    case 'UPLOAD_SITES_AND_LABS':
      return 'Update sites & labs';
    case 'OTHER_CLINICAL_CONTRACTS':
      return 'Other clinical contracts';
    case 'REVIEW_ACCRUAL_CALCULATIONS':
      return 'Review accrual calculations';
    case 'GENERATE_JOURNAL_ENTRY':
      return 'Generate journal entry';
    case 'UPLOAD_FX_RATES':
      return 'Update FX rates';
    case 'CRO_CONTRACTS':
      return 'CROs';
    default:
      return taskType;
  }
}

export function getTaskNote(
  taskType: ControlListingTaskType,
): string | undefined {
  switch (taskType) {
    case 'UPDATE_PATIENT_DATA':
      return 'Upload the EDC reports';
    case 'UPLOAD_SITES_AND_LABS':
      return 'Add sites, add labs, and update pricing';
    case 'UPLOAD_FX_RATES':
      return 'Add FX rates for all currencies used in vendor and site contracts for each month the currency is in use';
    case 'CRO_CONTRACTS':
      return 'Assign activity drivers, upload CRO reported activity, explain variances, and make adjustments as needed';
    case 'OTHER_CLINICAL_CONTRACTS':
      return 'Assign activity drivers, upload CRO reported activity, explain variances, and make adjustments as needed';
    default:
      return undefined;
  }
}
