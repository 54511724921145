import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { TrialActivitySitesResponse } from 'shared/lib/types';

import { useGetTrialActivitySitesQuery } from 'shared/api/rtkq/periods';

export function processTrialActivitySitesRows(
  trialActivitySitesGrid?: TrialActivitySitesResponse,
) {
  return trialActivitySitesGrid
    ? ['new_sites', 'cumulative_sites', 'closed_sites', 'active_months'].map(
        (key) =>
          cloneDeep(
            trialActivitySitesGrid[key as keyof TrialActivitySitesResponse],
          ),
      )
    : undefined;
}

export default function useTrialActivitySitesRows() {
  const period = useSelector(selectPeriod);
  const { currentData: trialActivitySites } = useGetTrialActivitySitesQuery(
    period.trace_id,
  );

  return useMemo(
    () => processTrialActivitySitesRows(trialActivitySites),
    [trialActivitySites],
  );
}
