import { useMemo } from 'react';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import AccrualsGrid from './grids/AccrualsGrid';

function AccrualsTabContent() {
  return (
    <FlexGridContainer>
      <AccrualsGrid
        overlayNoRowsTemplate="No accruals."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </FlexGridContainer>
  );
}

export default AccrualsTabContent;
