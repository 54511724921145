import { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Modal from 'shared/components/modal/Modal';
import CondorTextField from 'shared/components/text-field/CondorTextField';
import Select from 'shared/ui/select/Select';

import { useChangeForecast } from 'forecasting/state/slices/forecastSlice';
import * as routes from 'routes';
import useInputErrors from 'shared/lib/sidebar/hooks/useInputErrors';
import type { ForecastResponse } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetForecastFoldersByTrialQuery } from 'shared/api/rtkq/forecastfolders';
import {
  useDuplicateForecastMutation,
  useGetForecastsByTrialQuery,
} from 'shared/api/rtkq/forecasts';

type Props = {
  forecast: ForecastResponse;
  handleCloseModal: () => void;
};

function DuplicateForecastModal({ forecast, handleCloseModal }: Props) {
  const navigate = useNavigate();
  const trial = useSelector(selectTrial);
  const changeForecast = useChangeForecast();
  const [nameInput, setNameInput] = useState<string>(forecast.name);
  const { inputErrors, markInputError } = useInputErrors();
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(
    forecast.folder ?? null,
  );
  const [duplicateForecast, { isLoading: duplicateForecastLoading }] =
    useDuplicateForecastMutation();
  const { currentData: folders } = useGetForecastFoldersByTrialQuery(
    trial.trace_id,
  );
  const { currentData: existingForecasts } = useGetForecastsByTrialQuery(
    trial.trace_id,
  );

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameInput(event.target.value);
    if (inputErrors.name) {
      markInputError('name', false);
    }
  };

  const handleCreate = () => {
    if (!nameInput.trim()) {
      markInputError('name');
      return;
    }
    if (
      existingForecasts?.some(
        (existingForecast) => existingForecast.name === nameInput,
      )
    ) {
      markInputError('name', 'A forecast with this name already exists.');
      return;
    }

    void (async () => {
      const newForecast = await duplicateForecast({
        trace_id: forecast.trace_id,
        name: nameInput,
        folder: selectedFolderId ?? undefined,
      }).unwrap();

      handleCloseModal();
      changeForecast(newForecast);
      navigate(routes.getSpecificForecast(newForecast.trace_id));
    })();
  };

  const handleFolderChange = (event: React.ChangeEvent<{ value: string }>) =>
    setSelectedFolderId(event.target.value);

  return (
    <Modal
      handleClose={handleCloseModal}
      title="Duplicate forecast"
      ButtonProps={{
        label: 'Create',
        testId: 'ForecastModalDuplicate',
        loading: duplicateForecastLoading,
        disabled: !nameInput || nameInput === forecast.name,
        onClick: handleCreate,
      }}
      isOpen
    >
      <Stack gap={2} mt={1}>
        <FormControl>
          <CondorTextField
            autoComplete="off"
            errors={inputErrors.name}
            label="Name"
            name="name"
            type="text"
            value={nameInput}
            required
            onChange={handleNameChange}
          />
        </FormControl>
        <FormControl>
          <Select
            disabled={!folders || folders.length === 0}
            label="Folder"
            onChange={handleFolderChange}
          >
            <MenuItem value="">
              <Typography component="em">None</Typography>
            </MenuItem>
            {folders?.map(({ name, trace_id }) => (
              <MenuItem key={name} value={trace_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Modal>
  );
}

export default DuplicateForecastModal;
