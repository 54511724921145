import { type ReactNode, memo } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import type { LoadingButtonProps } from 'shared/ui/loading-button/LoadingButton';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import Button from '../../ui/button/Button';

type Props = {
  ButtonProps: LoadingButtonProps & { label: string };
  children?: ReactNode;
  handleClose: () => void;
  isOpen: boolean;
  title: string;
};

function Modal({
  title,
  isOpen,
  handleClose,
  ButtonProps: { label, testId, ...ButtonProps },
  children,
}: Props) {
  return (
    <Dialog maxWidth="xs" open={isOpen} fullWidth onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent sx={{ py: 3 }}>{children}</DialogContent>
      <DialogActions>
        <Button testId="cancel" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton testId={testId} variant="contained" {...ButtonProps}>
          {label}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default memo(Modal);
