import FormControl from '@mui/material/FormControl';

import CondorTextField from 'shared/components/text-field/CondorTextField';

import styles from './TextFieldDemo.module.scss';

const SX = { width: 250 };

function TextFieldDemo() {
  return (
    <ul className={styles.list}>
      <li>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="" label="Label" />
        </FormControl>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="" label="Label" size="small" />
        </FormControl>
      </li>
      <li>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="Value" label="Label" />
        </FormControl>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="Value" label="Label" size="small" />
        </FormControl>
      </li>
      <li>
        <FormControl sx={SX}>
          <CondorTextField
            defaultValue="Value"
            helperText="Helper text"
            label="Label"
          />
        </FormControl>
        <FormControl sx={SX}>
          <CondorTextField
            defaultValue="Value"
            helperText="Helper text"
            label="Label"
            size="small"
          />
        </FormControl>
      </li>
      <li>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="" label="Label" errors />
        </FormControl>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="" label="Label" size="small" errors />
        </FormControl>
      </li>
      <li>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="" label="Label" disabled />
        </FormControl>
        <FormControl sx={SX}>
          <CondorTextField
            defaultValue=""
            label="Label"
            size="small"
            disabled
          />
        </FormControl>
      </li>
      <li>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="" label="Label" startAdornment="$" />
        </FormControl>
        <FormControl sx={SX}>
          <CondorTextField
            defaultValue=""
            label="Label"
            size="small"
            startAdornment="$"
          />
        </FormControl>
      </li>
      <li>
        <FormControl sx={SX}>
          <CondorTextField defaultValue="" endAdornment="USD" label="Label" />
        </FormControl>
        <FormControl sx={SX}>
          <CondorTextField
            defaultValue=""
            endAdornment="USD"
            label="Label"
            size="small"
          />
        </FormControl>
      </li>
    </ul>
  );
}

export default TextFieldDemo;
