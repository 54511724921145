import DateFormatCondition from '../snapshot-validator/DateFormatCondition';
import DateValueCondition from '../snapshot-validator/DateValueCondition';
import FormatCondition from '../snapshot-validator/FormatCondition';
import OptionalFieldStrategy from '../snapshot-validator/OptionalFieldStrategy';
import RequiredFieldStrategy from '../snapshot-validator/RequiredFieldStrategy';
import RowValidator from '../snapshot-validator/RowValidator';
import TagValueRule from '../snapshot-validator/TagValueRule';
import type { Validity } from '../snapshot-validator/Validity';
import ValidityTag from '../snapshot-validator/ValidityTag';
import ValueCondition from '../snapshot-validator/ValueCondition';
import ValueTypeCondition from '../snapshot-validator/ValueTypeCondition';
import type { ValidatorFieldsBuilder } from './ValidatorFieldsBuilder';

const dateFields = ['visdat', 'sfdt', 'savets'];

class EdcReportVisitsValidatorBuilder implements ValidatorFieldsBuilder {
  protected _validators: TagValueRule[] = [];

  addRequiredField(field: string) {
    const validityTag = new ValidityTag(field);

    const validationStrategy = new RequiredFieldStrategy([
      this.getValueCondition(field),
      this.getFormatCondition(field),
      new ValueTypeCondition(),
    ]);

    this._validators.push(new TagValueRule(validityTag, validationStrategy));
    return this;
  }

  addOptionalField(field: string) {
    const validityTag = new ValidityTag(field);

    const validationStrategy = new OptionalFieldStrategy([
      this.getFormatCondition(field),
      new ValueTypeCondition(),
    ]);

    this._validators.push(new TagValueRule(validityTag, validationStrategy));
    return this;
  }

  createRowsValidator(): RowValidator {
    return new RowValidator(this._validators);
  }

  protected getFormatCondition(field: string): Validity {
    return dateFields.includes(field)
      ? new DateFormatCondition()
      : new FormatCondition();
  }

  protected getValueCondition(field: string): Validity {
    return dateFields.includes(field)
      ? new DateValueCondition()
      : new ValueCondition();
  }
}

export default EdcReportVisitsValidatorBuilder;
