import withPeriodSpecificRecordWrapper from 'shared/lib/periods/withPeriodSpecificRecordWrapper';
import RecordViewer from 'shared/lib/record-viewer/RecordViewer';
import { PeriodRecordBlobType } from 'shared/lib/types';

import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';
import useContractVersionRecord from './hooks/useContractVersionRecord';

function ContractVersionRecordContents() {
  const { contractContainer, contractVersion } =
    useContractAndPeriodWithVersions();
  const recordData = useContractVersionRecord(
    contractContainer,
    contractVersion,
  );
  return <RecordViewer record={recordData} />;
}

export default withPeriodSpecificRecordWrapper(
  ContractVersionRecordContents,
  PeriodRecordBlobType.CONTRACT_VERSION,
);
