import Close from '@mui/icons-material/Close';
/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
import MuiChip, { chipClasses } from '@mui/material/Chip';
import type { ChipProps as MuiChipProps } from '@mui/material/Chip/Chip';
/* eslint-enable @typescript-eslint/no-restricted-imports */

function Chip(props: ChipProps) {
  return (
    <MuiChip {...props} deleteIcon={<Close data-testid="delete-icon" />} />
  );
}

export type ChipProps = MuiChipProps & {
  color?: 'default' | 'error' | 'primary' | 'success' | 'warning';
  component?: React.ElementType;
};
export { chipClasses };

export default Chip;
