import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import type { ScaleOrdinal } from 'd3-scale';

import { currencyFormatter, getDateYearAndMonth } from 'formatters';
import GraphTooltipContainer from 'shared/lib/graphing/graph-tooltip/GraphTooltipContainer';
import GraphTooltipRow from 'shared/lib/graphing/graph-tooltip/GraphTooltipRow';
import LegendLine from 'shared/lib/graphing/shared/LegendLine';

import { humanizeCostPerPatientSend } from './config';
import type { CostPerPatientDatum } from './types';

type Props = {
  budgetedCost: string | undefined;
  budgetedCostLabel?: string;
  innerHeight: number;
  legendColorScale: ScaleOrdinal<string, string>;
  marginTop: number;
  orderOfData?: string[];
  tooltip: Partial<UseTooltipParams<CostPerPatientDatum>>;
};

function CostPerPatientGraphTooltip(props: Props) {
  const {
    tooltip,
    legendColorScale,
    orderOfData,
    marginTop,
    innerHeight,
    budgetedCost,
    budgetedCostLabel,
  } = props;

  const { tooltipData } = tooltip;

  return (
    <GraphTooltipContainer
      innerHeight={innerHeight}
      marginTop={marginTop}
      tooltip={tooltip}
    >
      {tooltipData && (
        <Box sx={{ m: 0, p: 1, width: 300 }}>
          <Box display="flex">
            <Typography flex={1} variant="body2">
              {getDateYearAndMonth(tooltipData.date)}
            </Typography>
            <Typography variant="body2">Cost</Typography>
          </Box>
          {orderOfData?.map((dataKey) => {
            const label = humanizeCostPerPatientSend(dataKey);
            const value = tooltipData[dataKey as keyof CostPerPatientDatum];
            return typeof value === 'string' && typeof label === 'string' ? (
              <GraphTooltipRow
                key={dataKey}
                label={label}
                legendColorScale={legendColorScale}
                value={value}
              />
            ) : null;
          })}
          {!!budgetedCost && !!budgetedCostLabel && (
            <>
              <Divider sx={{ my: 1 }} />
              <Box display="flex">
                <Box alignItems="center" display="flex" flex={1}>
                  <Box
                    sx={{
                      width: 18,
                      height: 18,
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      fill="none"
                      height="18"
                      viewBox="0 0 18 18"
                      width="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <LegendLine
                        stroke={legendColorScale(budgetedCostLabel)}
                      />
                    </svg>
                  </Box>
                  <Typography ml={1}>{budgetedCostLabel}</Typography>
                </Box>
                <Typography>{currencyFormatter(budgetedCost)}</Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </GraphTooltipContainer>
  );
}

export default CostPerPatientGraphTooltip;
