import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

export default function useHistoricalValuesGridOptions(): CondorGridOptions {
  return useMemo(
    () => ({
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
    }),
    [],
  );
}
