import { Fragment, useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import AxisBottom from '@visx/axis/lib/axis/AxisBottom';
import AxisLeft from '@visx/axis/lib/axis/AxisLeft';
import AxisTop from '@visx/axis/lib/axis/AxisTop';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import Group from '@visx/group/lib/Group';
import band from '@visx/scale/lib/scales/band';
import linear from '@visx/scale/lib/scales/linear';
import ordinal from '@visx/scale/lib/scales/ordinal';
import type { AnyD3Scale } from '@visx/scale/lib/types/Scale';

import { filterUndefined, parseNullableInt } from 'utils';

import { assumeTransparent } from '../../../../shared/lib/graphing/graphUtils';
import AnimatedBar from '../../../../shared/lib/graphing/shared/AnimatedBar';
import ForecastAnimatedLine from '../../../../shared/lib/graphing/shared/ForecastAnimatedLine';
import GraphLegend from '../../../../shared/lib/graphing/shared/GraphLegend';
import { BUDGET_FORECAST_CONFIG } from './config';
import type {
  BudgetForecastConfig,
  BudgetForecastData,
  BudgetForecastParameter,
} from './types';

type Props = {
  data: BudgetForecastData[];
  graphOptions?: BudgetForecastConfig;
  height: number;
  width: number;
};

const EXPENSED_BAR_HEIGHT = 8;
const CONTRACTED_BAR_HEIGHT = 16;
const VERTICAL_OFFSET = 0;

const xAccessor = (
  datum: BudgetForecastData,
  parameter: BudgetForecastParameter,
) => ({
  startPercentage: datum[parameter].startPercentage,
  endPercentage: datum[parameter].endPercentage,
});
const yAccessor = (datum: BudgetForecastData) =>
  datum.vendorName.length > 25
    ? `${datum.vendorName.slice(0, 25)}...`
    : datum.vendorName;

function BudgetForecast(props: Props) {
  const { width, height, data, graphOptions = BUDGET_FORECAST_CONFIG } = props;

  const { margin } = graphOptions;
  const left = parseNullableInt(margin?.left);
  const right = parseNullableInt(margin?.right);
  const top = parseNullableInt(margin?.top);
  const bottom = parseNullableInt(margin?.bottom);

  const innerWidth = width - left - right;
  const innerHeight = height - top - bottom;

  const steps = useMemo(
    () =>
      data.map((datum) => {
        const xValueLtdExpense = xAccessor(datum, 'ltdExpense');
        const xValueContracted = xAccessor(datum, 'contracted');
        const xValueForecasted = xAccessor(datum, 'forecasted');
        const yValue = yAccessor(datum);

        return {
          ltdExpense: {
            x: xValueLtdExpense.endPercentage,
            y: yValue,
            startPercentage: xValueLtdExpense.startPercentage,
            endPercentage: xValueLtdExpense.endPercentage,
            category: yValue,
          },
          contracted: {
            x: xValueContracted.endPercentage,
            y: yValue,
            startPercentage: xValueContracted.startPercentage,
            endPercentage: xValueContracted.endPercentage,
            category: yValue,
          },
          forecasted: {
            x: xValueForecasted.endPercentage,
            y: yValue,
            startPercentage: xValueForecasted.startPercentage,
            endPercentage: xValueForecasted.endPercentage,
            category: yValue,
          },
        };
      }),
    [data],
  );

  const padding = 0.2;
  const yScale = band({
    domain: data.map((step) => yAccessor(step)),
    padding,
    range: [0, innerHeight - VERTICAL_OFFSET],
  });

  const xDomain = useMemo(() => {
    const values = steps.flatMap((step) => [
      step.contracted.startPercentage,
      step.contracted.endPercentage,
      step.ltdExpense.startPercentage,
      step.ltdExpense.endPercentage,
      step.forecasted.startPercentage,
      step.forecasted.endPercentage,
    ]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    return [min, max];
  }, [steps]);

  const xScale = linear({ domain: xDomain, range: [0, innerWidth] });
  const legendColorScale = ordinal<string, string>({
    domain: filterUndefined<string>([
      graphOptions.ltdExpenseText,
      graphOptions.contractedText,
      graphOptions.overContractText,
      graphOptions.underContractText,
    ]),
    range: filterUndefined<string>([
      graphOptions.ltdExpenseColor,
      graphOptions.contractedColor,
      graphOptions.overContractColor,
      graphOptions.underContractColor,
    ]),
  });

  const formatValueToPercent = (value: number) => `${value.toFixed(0)}%`;

  const legendShapes = useCallback(
    (datum: ReturnType<AnyD3Scale>) => {
      if (
        [
          graphOptions.overContractText,
          graphOptions.underContractText,
        ].includes(datum.text)
      ) {
        return { type: 'line' as const };
      }
      return {
        type: 'rect' as const,
        stroke:
          datum.text === graphOptions.contractedText
            ? graphOptions.contractedColorOuter
            : undefined,
      };
    },
    [
      graphOptions.contractedColorOuter,
      graphOptions.contractedText,
      graphOptions.overContractText,
      graphOptions.underContractText,
    ],
  );

  return (
    <Box sx={{ display: 'grid' }}>
      <GraphLegend colorScale={legendColorScale} shapes={legendShapes} />
      <svg height={height} style={{ marginTop: '16px' }} width={width}>
        <Group left={left} top={top}>
          <AxisTop
            numTicks={0}
            scale={xScale}
            stroke={graphOptions.textColor}
            top={1}
            hideTicks
          />
          <GridColumns
            height={innerHeight}
            scale={xScale}
            stroke={graphOptions.columnColor}
          />
          {steps.map((step) => {
            const yLtdExpense = yScale(step.ltdExpense.y);
            const yContracted = yScale(step.contracted.y);
            const yForecasted = yScale(step.forecasted.y);

            const xLtdExpense = xScale(step.ltdExpense.startPercentage);
            const xContracted = xScale(step.contracted.startPercentage);
            const xForecasted = xScale(step.forecasted.endPercentage);

            const barWidthLtdExpense =
              xScale(step.ltdExpense.endPercentage) -
              xScale(step.ltdExpense.startPercentage);
            const barWidthContracted =
              xScale(step.contracted.endPercentage) -
              xScale(step.contracted.startPercentage);

            const barOffset = yScale.bandwidth() / 2; // center in the "middle" of the band

            return (
              <Fragment
                key={`${step.ltdExpense.category}_${step.contracted.category}_${step.forecasted.category}`}
              >
                {yLtdExpense !== undefined && (
                  <AnimatedBar
                    key={`ltdExpensed_${step.ltdExpense.category}`}
                    fill={assumeTransparent(graphOptions.ltdExpenseColor)}
                    height={EXPENSED_BAR_HEIGHT}
                    rx={2}
                    verticalOffset={VERTICAL_OFFSET}
                    width={barWidthLtdExpense}
                    x={xLtdExpense}
                    y={yLtdExpense + barOffset - EXPENSED_BAR_HEIGHT / 2}
                  />
                )}
                {yContracted !== undefined && (
                  <AnimatedBar
                    key={`contracted_${step.contracted.category}`}
                    fill={assumeTransparent(graphOptions.contractedColor)}
                    height={CONTRACTED_BAR_HEIGHT}
                    rx={2}
                    stroke={graphOptions.contractedColorOuter}
                    strokeWidth="0.5px"
                    verticalOffset={VERTICAL_OFFSET}
                    width={barWidthContracted}
                    x={xContracted}
                    y={yContracted + barOffset - CONTRACTED_BAR_HEIGHT / 2}
                  />
                )}
                {yForecasted !== undefined && (
                  <ForecastAnimatedLine
                    isGreater={step.forecasted.endPercentage > 100}
                    verticalOffset={VERTICAL_OFFSET}
                    x={xForecasted}
                    y={yForecasted + barOffset}
                    overContractColor={assumeTransparent(
                      graphOptions.overContractColor,
                    )}
                    underContractColor={assumeTransparent(
                      graphOptions.underContractColor,
                    )}
                  />
                )}
              </Fragment>
            );
          })}
          <AxisLeft
            scale={yScale}
            tickLength={left}
            top={VERTICAL_OFFSET}
            tickLabelProps={() => ({
              fill: graphOptions.textColor,
              fontSize: '12px',
              width: 100,
              verticalAnchor: 'middle',
            })}
            hideAxisLine
            hideTicks
          />
          <AxisBottom
            scale={xScale}
            stroke={graphOptions.textColor}
            // @ts-expect-error Incompatible types
            tickFormat={formatValueToPercent}
            tickStroke={graphOptions.textColor}
            top={innerHeight}
            tickLabelProps={() => ({
              fill: graphOptions.textColor,
              fontSize: 13,
              fontWeight: 600,
              textAnchor: 'middle',
              verticalAnchor: 'middle',
            })}
            hideTicks
          />
        </Group>
      </svg>
    </Box>
  );
}

export default BudgetForecast;
