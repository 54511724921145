import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';

import type {
  AssumptionGroupRequestDisplay,
  AssumptionGroupSectionName,
  RegionListItemType,
  RegionResponse,
} from 'shared/lib/types';

import AssumptionGroup from './AssumptionGroup';
import {
  getAssumptionGroupNamesByAssumptionGroupSectionName,
  getTypeLabel,
} from './helpers';

type Props = {
  assumptionGroups: AssumptionGroupRequestDisplay[];
  readOnly?: boolean;
  regionMap: Record<string, RegionResponse>;
  regions: RegionListItemType[];
  setAssumptionGroups?: (
    assumptionGroups: AssumptionGroupRequestDisplay[],
  ) => void;
  setCustomInheritedContract: (region: string) => void;
  sx?: SxProps<Theme>;
  type: AssumptionGroupSectionName;
  versionName?: string;
};

function AssumptionGroupSection(props: Props) {
  const {
    versionName,
    type,
    assumptionGroups,
    setAssumptionGroups,
    regions,
    regionMap,
    readOnly = false,
    sx,
    setCustomInheritedContract,
  } = props;
  const assumptionGroupNames =
    getAssumptionGroupNamesByAssumptionGroupSectionName(type);

  // ensure the order of the groups is kept visually regardless of what the data looks like
  const renderableAssumptionGroups = assumptionGroupNames.flatMap(
    (assumptionGroupName) => {
      const group = assumptionGroups.find(
        (assumptionGroup) => assumptionGroup.name === assumptionGroupName,
      );
      return group ? [group] : [];
    },
  );

  return (
    <Box
      sx={{
        ...sx,
        width: '100%',
        borderTop: (theme) => `2px solid ${theme.palette.primary.main}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          backgroundColor: (theme) => theme.palette.grey[100],
          borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}`,
        }}
      >
        <Typography
          color="primary"
          variant="caption"
          sx={{
            fontWeight: 'bold',
            flexGrow: 0,
            flexShrink: 0,
            width: '60%',
            pl: 1,
            py: 0.75,
            borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          {getTypeLabel(type)}
        </Typography>
        <Typography
          color="primary"
          variant="caption"
          sx={{
            fontWeight: 'bold',
            flexGrow: 1,
            pl: 1,
            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            py: 0.75,
          }}
        >
          {versionName}
        </Typography>
      </Box>
      {renderableAssumptionGroups.map((assumptionGroup) => (
        <AssumptionGroup
          key={assumptionGroup.name}
          assumptionGroup={assumptionGroup}
          readOnly={readOnly}
          regionMap={regionMap}
          regions={regions}
          setCustomInheritedContract={setCustomInheritedContract}
          onChange={(updatedAssumptionGroup: AssumptionGroupRequestDisplay) => {
            const unchangedAssumptionGroups = assumptionGroups.filter(
              (param) => param.name !== updatedAssumptionGroup.name,
            );

            setAssumptionGroups?.([
              ...unchangedAssumptionGroups,
              updatedAssumptionGroup,
            ]);
          }}
        />
      ))}
    </Box>
  );
}

export default AssumptionGroupSection;
