import ParentSize from '@visx/responsive/lib/components/ParentSize';

import CostPerPatientGraph from 'forecasting/components/graphing/cost-per-patient-graph/CostPerPatientGraph';

import { data } from './data';

function CostPerPatient() {
  return (
    <ParentSize>
      {(parent) => (
        <CostPerPatientGraph
          expectedCostPerPatient="70000"
          graphData={data}
          latestCloseDate="2025-01-01T00:00:00"
          width={parent.width}
        />
      )}
    </ParentSize>
  );
}

export default CostPerPatient;
