import { useDispatch, useSelector } from 'react-redux';

import AddSiteWizard from 'accruals/wizards/site-or-lab-costs/add-site-wizard/AddSiteWizard';
import {
  closeEditSite,
  selectEditSiteTraceId,
} from 'shared/state/slices/siteSlice';

function EditSite() {
  const dispatch = useDispatch();
  const editSiteIsOpen = useSelector(selectEditSiteTraceId);

  function onClose() {
    dispatch(closeEditSite());
  }

  if (!editSiteIsOpen) {
    return null;
  }

  return <AddSiteWizard title="Edit site" onClose={onClose} />;
}

export default EditSite;
