import { useEffect, useMemo, useState } from 'react';

import type { SelectionChangedEvent } from '@ag-grid-community/core';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import CommentsContainer from 'shared/components/comments/CommentsContainer';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type { ManageExpectedVisitRow } from 'shared/lib/types';
import { CommentLocationType } from 'shared/lib/types';
import { updateHeader } from 'shared/state/slices/commentSlice';

import { usePersistExpectedVisitsMutation } from 'shared/api/rtkq/periods';
import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

import ExpectedVisitsGrid from '../grids/ExpectedVisitsGrid';
type Props = { onClose: () => void };

function ManageExpectedVisitsWizard(props: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateHeader({ header: 'Comments' }));
  }, [dispatch]);

  const { onClose } = props;
  const periodIsClosed = useIsClosedPeriodVersion();
  const period = useSelector(selectPeriod);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ManageExpectedVisitRow[]>(
    [],
  );
  const [callPersistExpectedVisits] = usePersistExpectedVisitsMutation();

  function onSelectionChange(event: SelectionChangedEvent) {
    const gridSelectedRows = event.api.getSelectedRows();
    setSelectedRows(gridSelectedRows);
  }

  function onSave() {
    void (async () => {
      setShowLoadingButton(true);
      await callPersistExpectedVisits({
        trace_id: period.trace_id,
        expected_visits: selectedRows,
      });
      onClose();
    })();
  }

  return (
    <FullWidthDialog open>
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="subtitle1">
          Manage expected patient visits
        </Typography>
      </DialogTitleWithClose>
      <DialogContent sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FlexGridContainer>
          <ExpectedVisitsGrid
            handleSelectionChange={onSelectionChange}
            overlayNoRowsTemplate="No data available"
            sx={useMemo(() => ({ mt: 2, height: '100%' }), [])}
          />
        </FlexGridContainer>
        <CommentsContainer
          location={CommentLocationType.MANAGE_EXPECTED_VISITS}
          locationSlug="Expected Visits"
          fullHeight
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          loading={showLoadingButton}
          sx={{ display: periodIsClosed ? 'none' : 'block' }}
          testId="save_expected_visits_btn"
          variant="contained"
          onClick={() => onSave()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </FullWidthDialog>
  );
}

export default ManageExpectedVisitsWizard;
