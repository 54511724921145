import { useState } from 'react';

import type { ParseError, ParseRemoteConfig, ParseResult } from 'papaparse';
import Papa from 'papaparse';

import type { CsvRow } from './csv-row-filters';

function usePapaparse() {
  const [fields, setFields] = useState<string[] | null>(null);
  const [rows, setRows] = useState<CsvRow[] | null>(null);
  const [errors, setErrors] = useState<ParseError[] | null>(null);
  const [loading, setLoading] = useState(false);

  const requestCsvFile = (
    fileUrl: string,
    config?: ParseRemoteConfig<CsvRow>,
  ): void => {
    setLoading(true);
    const overrides = config ?? {};

    Papa.parse(fileUrl, {
      header: true,
      download: true,
      ...overrides,
      complete: (results: ParseResult<CsvRow>) => {
        setFields(results.meta.fields ?? []);
        setRows(results.data.map((row) => ({ ...row })));
        setErrors(results.errors);
        setLoading(false);
      },
    });
  };

  return { fields, data: rows, errors, loading, requestCsvFile };
}

export default usePapaparse;
