import type { RegionResponse } from 'shared/lib/types';

import constructApi from './constructApi';

export const TAG = 'REGIONS';

const apiEndpointFactory =
  constructApi<RegionResponse>('regions').withTags(TAG);
export const api = apiEndpointFactory.inject(() => ({
  getRegionsByTrial: apiEndpointFactory.getBy('trial'),
  getRegionsByContractVersion: apiEndpointFactory.getBy('contract_version'),
  getRegionsByContractContainer: apiEndpointFactory.getBy('contract_container'),
  getCurrentRegions: apiEndpointFactory.getBy('period'),
}));

export const {
  useGetRegionsByTrialQuery,
  useGetRegionsByContractVersionQuery,
  useGetRegionsByContractContainerQuery,
  useLazyGetRegionsByContractVersionQuery,
  useGetCurrentRegionsQuery,
} = api;
