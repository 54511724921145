import { type ReactElement, useMemo, useState } from 'react';

import useCohortAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useCohortAndPeriodWithVersions';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import PatientJourneyHeader from './components/PatientJourneyHeader';
import PatientJourneySubtabs from './components/PatientJourneySubtabs';
import PatientJourneyGrid from './grids/PatientJourneyGrid';

function PatientJourneyPage(): ReactElement {
  const { periodSpecificPatientCohortTraceId: patientCohortId } =
    useCohortAndPeriodWithVersions();
  const [showExpectedVisits, setShowExpectedVisits] = useState<boolean>(false);

  return (
    <FlexGridContainer>
      <PatientJourneyHeader setShowExpectedVisits={setShowExpectedVisits} />
      <PatientJourneySubtabs />
      <PatientJourneyGrid
        overlayNoRowsTemplate="No data available"
        parentMenuItem={patientCohortId}
        showExpectedVisits={showExpectedVisits}
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </FlexGridContainer>
  );
}

export default PatientJourneyPage;
