import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import CloseReopenPeriodButton from 'accruals/components/periods/CloseReopenPeriodButton';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';

import AccountingChecklist from './accounting-checklist/AccountingChecklist';
import PoCompletenessGrid from './po-completeness/PoCompletenessGrid';

function ChecklistTabContent() {
  const period = useSelector(selectPeriod);
  const currentPeriodVersion = useSelector(selectPeriodVersion);

  const isLatest = currentPeriodVersion.trace_id === period.latest_version;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          mb: 2,
        }}
      >
        <Typography color="primary.main" variant="h3">
          Financial Close
        </Typography>
        {isLatest ? <CloseReopenPeriodButton /> : undefined}
      </Box>
      <Paper sx={{ p: 4 }}>
        <Typography color="primary.main" mb={3} variant="h6">
          PO Completeness
        </Typography>
        <PoCompletenessGrid sx={useMemo(() => ({ height: '400px' }), [])} />
      </Paper>
      <AccountingChecklist />
    </>
  );
}

export default ChecklistTabContent;
