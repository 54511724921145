import type { ReactNode } from 'react';

import Modal from '@mui/material/Modal';

import Button from 'shared/ui/button/Button';
import Card, {
  cardActionsClasses,
  cardClasses,
  cardContentClasses,
} from 'shared/ui/card/Card';

type ConfirmReassignmentModalProps = {
  children: ReactNode;
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
};

function ConfirmReassignmentModal(props: ConfirmReassignmentModalProps) {
  const { open, children, onConfirm, onDismiss } = props;

  return (
    <Modal open={open}>
      <div>
        <Card
          actionButtonsPosition="right"
          variant="outlined"
          ActionButtons={
            <>
              <Button testId="cancel" variant="outlined" onClick={onDismiss}>
                Cancel
              </Button>
              <Button
                testId="reassign_column"
                variant="contained"
                onClick={onConfirm}
              >
                Reassign Column
              </Button>
            </>
          }
          actionsSx={{
            [`&.${cardActionsClasses.root}`]: {
              padding: '24px 40px !important',
              borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
            },
          }}
          contentSx={{
            [`&.${cardContentClasses.root}`]: {
              padding: '24px 40px !important',
            },
          }}
          sx={{
            [`&.${cardClasses.root}`]: {
              width: '500px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
        >
          {children}
        </Card>
      </div>
    </Modal>
  );
}

export default ConfirmReassignmentModal;
