import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import { getJSONFromFile } from 'shared/helpers/helpers';
import type { PeriodGridBlobType, TraceId } from 'shared/lib/types';

import { useGetPeriodGridBlobQuery } from 'shared/api/rtkq/periodgridblobs';

// eslint-disable-next-line etc/no-misused-generics -- specifically defining the blob type
export default function useGridRowsDataLoader<T>(
  savedObjectType: PeriodGridBlobType,
  periodVersion?: TraceId,
  parentMenuItem?: TraceId,
): { loading: boolean; data: T | undefined } {
  const [blobData, setBlobData] = useState<T>();
  const {
    currentData: recordSnapshots,
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetPeriodGridBlobQuery(
    periodVersion
      ? {
          saved_object_type: savedObjectType,
          period_version: periodVersion,
          parent_menu_item: parentMenuItem,
        }
      : skipToken,
  );

  // there can only ever be one, but our "getByFilters" helper assumes many
  const gridBlob = recordSnapshots?.[0];

  useEffect(() => {
    // TODO: Error Handling
    void (async () => {
      if (gridBlob === undefined) {
        return;
      }

      setBlobData(await getJSONFromFile<T>(gridBlob.rows));
    })();
  }, [gridBlob]);

  return {
    loading: isLoading || isFetching || isUninitialized,
    data: blobData,
  };
}
