import type SnapshotCell from './SnapshotCell';
import type { Validity } from './Validity';

class OptionalFieldStrategy implements Validity {
  protected conditionOrStrategy: Validity[];

  constructor(conditionOrStrategy: Validity[]) {
    this.conditionOrStrategy = conditionOrStrategy;
  }

  isValid(cell: SnapshotCell) {
    if (!cell.getValue()) {
      // do not need to validate empty value in option columns
      return true;
    }

    for (const conditionOrStrategyItem of this.conditionOrStrategy) {
      if (!conditionOrStrategyItem.isValid(cell)) {
        return false;
      }
    }
    return true;
  }
}

export default OptionalFieldStrategy;
