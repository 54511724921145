import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetAdministrativeOrProcedureCategoriesByTrialQuery } from 'shared/api/rtkq/administrativeorprocedurecategories';
import { useGetContractContainersByTrialQuery } from 'shared/api/rtkq/contractcontainers';
import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';
import { useGetRegionsByTrialQuery } from 'shared/api/rtkq/regions';

import { getLabDefs, getSiteDefs } from './getSiteLabCostMatrixColumnDefs';

export default function useSiteLabCostMatrixColumnDefs(
  isOpenPeriod: boolean,
  type: 'LAB' | 'SITE',
): Array<CondorColDef | CondorColGroupDef> {
  const trial = useSelector(selectTrial);
  const { currentData: contractContainers } =
    useGetContractContainersByTrialQuery(trial.trace_id);
  const { currentData: regions } = useGetRegionsByTrialQuery(trial.trace_id);
  const { currentData: adminOrProcedureCosts } =
    useGetAdministrativeOrProcedureCategoriesByTrialQuery(trial.trace_id);
  const { currentData: patientCohorts } = useGetPatientCohortsByTrialQuery(
    trial.trace_id,
  );
  const themeMode = useTheme().palette.mode;

  return useMemo(() => {
    if (type === 'SITE') {
      const invoiceableProcedureCosts = adminOrProcedureCosts?.filter(
        (category) => category.category_type === 'INV',
      );
      return getSiteDefs(
        isOpenPeriod,
        contractContainers,
        regions,
        invoiceableProcedureCosts,
        patientCohorts,
        themeMode,
      );
    }

    return getLabDefs(isOpenPeriod, contractContainers, patientCohorts);
  }, [
    type,
    isOpenPeriod,
    contractContainers,
    adminOrProcedureCosts,
    patientCohorts,
    regions,
    themeMode,
  ]);
}
