import { useState } from 'react';

import Typography from '@mui/material/Typography';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import Button from 'shared/ui/button/Button';

import type {
  MutationThunkArg,
  QueryThunkArg,
  UnknownAsyncThunkRejectedAction,
} from 'rtkq';

type Props = { action: UnknownAsyncThunkRejectedAction };

const RtkQueryErrorToast = (props: Props) => {
  const { action } = props;
  const [showMore, setShowMore] = useState<boolean>(false);

  function getStatus() {
    const error = action.payload as FetchBaseQueryError;
    if (error.status === 'PARSING_ERROR') {
      return error.originalStatus;
    }

    return error.status;
  }

  function onClickShowMore(event: React.MouseEvent) {
    event.stopPropagation();
    setShowMore(true);
  }

  try {
    const {
      error: { message },
      meta: { arg },
    } = action;
    const { endpointName, originalArgs } = arg as
      | MutationThunkArg
      | QueryThunkArg;

    return (
      <>
        <Typography color="text.primary" variant="body2">
          Error: {`${getStatus()} - ${message}`}
        </Typography>
        <Typography color="text.primary" variant="body2">
          Endpoint: {endpointName}
        </Typography>
        {showMore ? (
          <Typography color="text.secondary" variant="body1">
            Args: {JSON.stringify(originalArgs)}
          </Typography>
        ) : (
          !!originalArgs && (
            <Button
              size="small"
              testId="show_details"
              onClick={onClickShowMore}
            >
              Show details
            </Button>
          )
        )}
      </>
    );
  } catch {
    return (
      <Typography color="text.primary" variant="body2">
        API error.
      </Typography>
    );
  }
};

/**
 * This is because we can't directly use JSX in a .ts file (store.ts)
 */
export default function createToast(action: UnknownAsyncThunkRejectedAction) {
  return <RtkQueryErrorToast action={action} />;
}
