import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import * as routes from 'routes';
import type {
  ContractVersionResponse,
  MenuItemType,
  TraceId,
} from 'shared/lib/types';

import { useGetContractContainersByTrialQuery } from 'shared/api/rtkq/contractcontainers';
import {
  useGetAipContractsByTrialQuery,
  useGetCurrentContractsByTrialQuery,
} from 'shared/api/rtkq/periods';
import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

export default function useOpenPeriodContractContainerMenuItems(
  trialTraceId: TraceId,
): {
  menuItems: MenuItemType[] | undefined;
  loading: boolean;
} {
  const [menuItems, setMenuItems] = useState<MenuItemType[] | undefined>();

  const isClosedPeriodVersion = useIsClosedPeriodVersion();
  const period = useSelector(selectPeriod);
  const {
    currentData: contractContainers,
    isLoading: ccLoading,
    isUninitialized: ccUninitialized,
    isFetching: ccFetching,
  } = useGetContractContainersByTrialQuery(trialTraceId);
  const {
    currentData: currentContracts,
    isLoading: currentLoading,
    isUninitialized: currentUninitialized,
    isFetching: currentFetching,
  } = useGetCurrentContractsByTrialQuery({
    trace_id: period.trace_id,
    otherParameter: trialTraceId,
  });
  const {
    currentData: aipContracts,
    isLoading: aipLoading,
    isUninitialized: aipUninitialized,
    isFetching: aipFetching,
  } = useGetAipContractsByTrialQuery({
    trace_id: period.trace_id,
    otherParameter: trialTraceId,
  });

  useEffect(() => {
    if (isClosedPeriodVersion) {
      setMenuItems(undefined);
      return;
    }

    const currentContractsMap: Record<string, ContractVersionResponse> =
      currentContracts?.reduce(
        (
          acc: Record<string, ContractVersionResponse>,
          currentContract: ContractVersionResponse,
        ) => {
          acc[currentContract.contract_container_id] = currentContract;
          return acc;
        },
        {},
      ) ?? {};

    const aipContractsMap: Record<string, ContractVersionResponse> =
      aipContracts?.reduce(
        (
          acc: Record<string, ContractVersionResponse>,
          currentContract: ContractVersionResponse,
        ) => {
          acc[currentContract.contract_container_id] = currentContract;
          return acc;
        },
        {},
      ) ?? {};

    setMenuItems(
      contractContainers
        ?.filter(
          (contractContainer) =>
            contractContainer.vendor?.vendor_type !== 'OCC',
        )
        .map((contractContainer) => ({
          title: `${contractContainer.vendor?.vendor_type ?? 'CRO'} ${
            contractContainer.vendor_name ?? 'Unknown Vendor'
          }`,
          link: routes.getClinicalExpensesByContractContainer(
            contractContainer.trace_id,
          ),
          contract_container: contractContainer,
          current_contract: currentContractsMap[contractContainer.trace_id],
          aip_contract: aipContractsMap[contractContainer.trace_id],
        })),
    );
  }, [
    aipContracts,
    contractContainers,
    currentContracts,
    isClosedPeriodVersion,
  ]);

  return {
    menuItems,
    loading:
      !isClosedPeriodVersion &&
      (ccLoading ||
        ccFetching ||
        ccUninitialized ||
        currentLoading ||
        currentFetching ||
        currentUninitialized ||
        aipLoading ||
        aipFetching ||
        aipUninitialized),
  };
}
