import type { RegionGroupResponse } from 'shared/lib/types';

import constructApi from './constructApi';

export const TAG = 'REGION_GROUPS';

const apiEndpointFactory =
  constructApi<RegionGroupResponse>('regiongroups').withTags(TAG);
const api = apiEndpointFactory.inject(() => ({
  getRegionGroupsByContractVersion:
    apiEndpointFactory.getBy('contract_version'),
  getRegionGroupsByContractContainer:
    apiEndpointFactory.getBy('contract_container'),
}));

export const {
  useGetRegionGroupsByContractVersionQuery,
  useGetRegionGroupsByContractContainerQuery,
} = api;
