import type { MouseEvent } from 'react';
import { useState } from 'react';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import IconButton from 'shared/ui/icon-button/IconButton';

function HelpDropdown() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '16', mr: 1, pl: 2 }}
      >
        <IconButton data-testid="help-button" onClick={handleOnClick}>
          <QuestionMarkIcon />
        </IconButton>
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={anchorEl !== null}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ p: 3, width: 220 }}>
          <Typography variant="body1">
            Need help? Contact us at{' '}
            <Link href="mailto:support@condorsoftware.com">
              support@condorsoftware.com
            </Link>
            .
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

export default HelpDropdown;
