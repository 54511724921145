import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { AccrualsGridRow } from 'shared/lib/types';
import { processAccrualsRows } from 'shared/processors/processAccrualsRows';

import { useGetAccrualsQuery } from 'shared/api/rtkq/periods';

export default function useAccrualsRows(): AccrualsGridRow[] | undefined {
  const period = useSelector(selectPeriod);
  const { currentData: accrualsGrid } = useGetAccrualsQuery(period.trace_id);

  return useMemo(() => processAccrualsRows(accrualsGrid), [accrualsGrid]);
}
