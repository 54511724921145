import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { PoCompletenessResponse } from 'shared/lib/types';

export function processPoCompleteness(
  responseData: PoCompletenessResponse | undefined,
) {
  if (!responseData) {
    return [];
  }

  const { total_contract_values, trial_currency } = responseData;
  const contractVendorKeys = Object.keys(total_contract_values);

  const rows = responseData.po_numbers.map((row, index) => {
    const total_contract_value = total_contract_values[row.vendor];
    return {
      traceId: `po-completeness-${index}`,
      poNumber: row.po_number,
      poAmount: row.po_amount,
      vendor: row.vendor,
      completenessStatus: row.completeness_status,
      total: total_contract_value,
      trialCurrency: trial_currency,
    };
  });

  const rowsVendorKeys = rows.map((row) => row.vendor);
  const missingPoInVendors = contractVendorKeys.filter(
    (x) => !rowsVendorKeys.includes(x),
  );
  const missingPoListings = missingPoInVendors.map((vendor, index) => {
    const total_contract_value = total_contract_values[vendor];
    return {
      traceId: `missing-po-${index}`,
      total: total_contract_value,
      vendor,
    };
  });

  const finalRows = [...rows, ...missingPoListings];

  return cloneDeep(finalRows);
}

export default function usePoCompletenessRows(
  responseData: PoCompletenessResponse | undefined,
  _isOpenPeriod: boolean,
) {
  return useMemo(() => processPoCompleteness(responseData), [responseData]);
}
