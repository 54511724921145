import type { SignOffResponse } from 'shared/lib/types';

import constructApi from './constructApi';
const PATH = 'financialclosesignoffs';

export const FINANCIAL_CLOSE_SIGN_OFF = 'FINANCIAL_CLOSE_SIGN_OFF';

const apiEndpointFactory = constructApi<SignOffResponse>(PATH).withTags(
  'FINANCIAL_CLOSE_SIGN_OFF',
);
const api = apiEndpointFactory.inject(() => ({
  createSignOff: apiEndpointFactory.create(),
  deleteSignOff: apiEndpointFactory.delete(),
}));

export const { useCreateSignOffMutation, useDeleteSignOffMutation } = api;
