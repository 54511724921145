import type { ReactNode } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import type { Variant } from '@mui/material/styles/createTypography';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  icon?: ReactNode;
  link?: string;
  title: string;
  variant?: Variant;
  onClick?: () => void;
};

function SidebarSubNavigationItem(props: Props) {
  const { link, icon, title, variant = 'body2', onClick } = props;
  const location = useLocation();

  const listItemButtonProps: Record<string, unknown> = {};
  if (link) {
    listItemButtonProps.component = Link;
    listItemButtonProps.to = link;
  }
  if (onClick) {
    listItemButtonProps.onClick = onClick;
  }

  const active = location.pathname === link;

  return (
    <ListItem sx={{ borderRadius: 0.5 }} disablePadding>
      <ListItemButton
        {...listItemButtonProps}
        sx={{
          '&:hover': { backgroundColor: (theme) => theme.palette.info.light },
          ...(link && active && { backgroundColor: '#F0F3FF' }),
          ...(!link &&
            !onClick && {
              cursor: 'auto',
              '&:hover': { backgroundColor: 'transparent' },
            }),
          py: 1,
          justifyContent: 'center',
          height: 32,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 20,
            maxWidth: 20,
            color: (theme) => theme.palette.common.black,
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          sx={{ m: 0, pl: 0.5, minWidth: 0 }}
          primaryTypographyProps={{
            variant,
            sx: {
              minWidth: 0,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default SidebarSubNavigationItem;
