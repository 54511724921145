import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType, TraceId } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';

import { useGetContractBudgetRecordsBySnapshotQuery } from 'shared/api/rtkq/contractbudgetrecords';

import columns from '../columns';

type Props = { snapshotId?: TraceId };

function ContractBudgetReviewStep(props: Props) {
  const { snapshotId } = props;

  const { currentData: allRecords } =
    useGetContractBudgetRecordsBySnapshotQuery(snapshotId ?? skipToken);

  return (
    <WizardStep
      description="Check that all information looks right before saving your budget but, don't worry, you'll still be able to make adjustments in the future."
      header="Last step, make sure everything looks right."
    >
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default ContractBudgetReviewStep;
