import type { ReactElement } from 'react';

import type { SxProps } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { PeriodGridBlobType } from 'shared/lib/types';

import { useGetTrialExpenseSummaryQuery } from 'shared/api/rtkq/periods';

import useTrialExpenseSummaryColumnDefs from '../hooks/useTrialExpenseSummaryColumnDefs';
import useTrialExpenseSummaryGridOptions from '../hooks/useTrialExpenseSummaryGridOptions';
import useTrialExpenseSummaryRows from '../hooks/useTrialExpenseSummaryRows';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function TrialExpenseSummaryGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;
  const period = useSelector(selectPeriod);

  const { currentData: expenseSummaryGrid } = useGetTrialExpenseSummaryQuery(
    period.trace_id,
  );

  const columnDefs = useGridColDefs(useTrialExpenseSummaryColumnDefs, [
    expenseSummaryGrid,
  ]);
  const gridOptions = useGridOptions(useTrialExpenseSummaryGridOptions);
  const rowData = useTrialExpenseSummaryRows(expenseSummaryGrid);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  TrialExpenseSummaryGrid,
  PeriodGridBlobType.TRIAL_EXPENSE_SUMMARY_GRID,
);
