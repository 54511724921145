import { useMemo } from 'react';

import { getTrialMoneyCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

export default function useJournalEntryColumnDefs(
  _isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      { flex: 1, headerName: 'Date', field: 'date' },
      {
        flex: 1,
        headerName: 'G/L account',
        field: 'gl_account',
        cellStyle: 'journalEntryGLAccountColumn',
      },
      { flex: 1, headerName: 'Description', field: 'description' },
      {
        flex: 1,
        headerName: 'Debit',
        field: 'debit',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        flex: 1,
        headerName: 'Credit',
        field: 'credit',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
    ],
    [],
  );
}
