import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';

import Select from 'shared/ui/select/Select';

import styles from '../text-field/TextFieldDemo.module.scss';
const SX = { width: 220 };
function SelectDemo() {
  const menuItems = Array.from({ length: 8 })
    .map((_val, index) => `Menu Item ${index + 1}`)
    .map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));

  return (
    <section>
      <ul className={styles.list}>
        <li>
          <FormControl sx={SX}>
            <Select label="Label">{menuItems}</Select>
          </FormControl>
          <FormControl sx={SX}>
            <Select label="Small" size="small">
              {menuItems}
            </Select>
          </FormControl>
        </li>
        <li>
          <FormControl sx={SX}>
            <Select label="Label" value="Menu Item 1">
              {menuItems}
            </Select>
          </FormControl>
          <FormControl sx={SX}>
            <Select label="Small" size="small" value="Menu Item 1">
              {menuItems}
            </Select>
          </FormControl>
        </li>
        <li>
          <FormControl sx={SX}>
            <Select label="Label">{menuItems}</Select>
            <FormHelperText>Helper text</FormHelperText>
          </FormControl>
          <FormControl sx={SX}>
            <Select label="Small" size="small">
              {menuItems}
            </Select>
            <FormHelperText>Helper text</FormHelperText>
          </FormControl>
        </li>
        <li>
          <FormControl sx={SX}>
            <Select label="Label" errors>
              {menuItems}
            </Select>
          </FormControl>
          <FormControl sx={SX}>
            <Select label="Small" size="small" errors>
              {menuItems}
            </Select>
          </FormControl>
        </li>
      </ul>
      <ul className={styles.list}>
        <li>
          <FormControl sx={SX} variant="standard">
            <Select label="Label">{menuItems}</Select>
          </FormControl>
          <FormControl sx={SX} variant="standard">
            <Select label="Small" size="small">
              {menuItems}
            </Select>
          </FormControl>
        </li>
        <li>
          <FormControl sx={SX} variant="standard">
            <Select label="Label" value="Menu Item 1">
              {menuItems}
            </Select>
          </FormControl>
          <FormControl sx={SX} variant="standard">
            <Select label="Small" size="small" value="Menu Item 1">
              {menuItems}
            </Select>
          </FormControl>
        </li>
        <li>
          <FormControl sx={SX} variant="standard">
            <Select label="Label" value="Menu Item 1">
              {menuItems}
            </Select>
            <FormHelperText>Helper text</FormHelperText>
          </FormControl>
          <FormControl sx={SX} variant="standard">
            <Select label="Small" size="small" value="Menu Item 1">
              {menuItems}
            </Select>
            <FormHelperText>Helper text</FormHelperText>
          </FormControl>
        </li>
      </ul>
    </section>
  );
}

export default SelectDemo;
