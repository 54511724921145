import type {
  ForecastParameterRequest,
  ForecastParameterResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { GENERATE_FORECASTS_TAG } from './forecasts';

const FORCECAST_PARAMETERS_TAG = 'FORCECAST_PARAMETERS';

const apiEndpointFactory = constructApi<ForecastParameterResponse>(
  'forecastparameters',
).withTags(FORCECAST_PARAMETERS_TAG, [GENERATE_FORECASTS_TAG]);
const api = apiEndpointFactory.inject(() => ({
  getForecastParametersByForecast: apiEndpointFactory.getBy('forecast'),
  createForecastParameter: apiEndpointFactory.create<ForecastParameterRequest>([
    GENERATE_FORECASTS_TAG,
  ]),
  updateForecastParameter: apiEndpointFactory.update([GENERATE_FORECASTS_TAG]),
}));

export const {
  useGetForecastParametersByForecastQuery,
  useCreateForecastParameterMutation,
  useUpdateForecastParameterMutation,
} = api;
