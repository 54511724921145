import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { PatientJourneyRowResponse } from 'shared/lib/types';

export default function usePatientJourneyGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<PatientJourneyRowResponse> {
  return useMemo(
    () => ({
      groupDisplayType: 'groupRows',
      getRowId: 'patient_journey_id',
      getRowStyle: 'highlightRow',
      groupTotalRow: 'bottom' as const,
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
      suppressFieldDotNotation: true,
      useCustomGroupFiltering: true,
    }),
    [],
  );
}
