import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import usePeriodFilesColumnDefs from 'accruals/pages/trial-files/hooks/usePeriodFilesColumnDefs';
import usePeriodFilesGridOptions from 'accruals/pages/trial-files/hooks/usePeriodFilesGridOptions';
import usePeriodFilesRows from 'accruals/pages/trial-files/hooks/usePeriodFilesRows';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { PeriodGridBlobType } from 'shared/lib/types';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function PeriodFilesGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = usePeriodFilesColumnDefs(true);
  const gridOptions = usePeriodFilesGridOptions(true);
  const rowData = usePeriodFilesRows(true);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  PeriodFilesGrid,
  PeriodGridBlobType.PERIOD_FILES,
);
