import type { TimeForecastData } from 'forecasting/components/graphing/time-forecast/types';

export const timeline1: TimeForecastData = {
  ltdExpense: {
    start: '2021-08-01',
    end: '2023-02-01',
  },
  contracted: {
    end: '2024-11-01',
  },
  forecasted: {
    end: '2026-10-01',
  },
};

export const timeline2: TimeForecastData = {
  ltdExpense: {
    start: '2021-08-01',
    end: '2023-02-01',
  },
  contracted: {
    end: '2024-11-01',
  },
  forecasted: {
    end: '2023-10-01',
  },
};

export const timeline3: TimeForecastData = {
  ltdExpense: {
    start: '2021-08-01',
    end: '2024-12-01',
  },
  contracted: {
    end: '2024-11-01',
  },
  forecasted: {
    end: '2023-10-01',
  },
};

export const timeline4: TimeForecastData = {
  ltdExpense: {
    start: '2021-08-01',
    end: '2021-09-01',
  },
  contracted: {
    end: '2024-11-01',
  },
  forecasted: {
    end: '2023-10-01',
  },
};

export const timeline5: TimeForecastData = {
  ltdExpense: {
    start: '2021-08-01',
    end: '2021-08-01',
  },
  contracted: {
    end: '2024-11-01',
  },
  forecasted: {
    end: '2023-10-01',
  },
};
