import { useMemo } from 'react';

import GlAccountsHeader from 'shared/components/gl-accounts/GlAccountsHeader';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import GlAccountsGrid from './grids/GlAccountsGrid';

function GlAccounts() {
  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <GlAccountsHeader />
        <GlAccountsGrid
          overlayNoRowsTemplate="No accounts."
          sx={useMemo(() => ({ height: '100%' }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default GlAccounts;
