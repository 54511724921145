import Chip from 'shared/ui/chip/Chip';

export default function PeriodStatusChip(props: {
  isClosed: boolean | undefined;
}) {
  const { isClosed } = props;
  return (
    <Chip
      color={isClosed ? 'primary' : 'success'}
      label={isClosed ? 'Closed' : 'Open'}
    />
  );
}
