import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import useAuditLogColumnDefs from 'company/pages/audit-log/hooks/useAuditLogColumnDefs';
import useAuditLogGridOptions from 'company/pages/audit-log/hooks/useAuditLogGridOptions';
import useAuditLogServerSideDatasource from 'company/pages/audit-log/hooks/useAuditLogServerSideDatasource';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function AuditLogGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useAuditLogColumnDefs();
  const gridOptions = useAuditLogGridOptions();
  const serverSideDatasource = useAuditLogServerSideDatasource();

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        paginationPageSize={100}
        serverSideDatasource={serverSideDatasource}
        sx={sx}
        pagination
      />
    </FlexGridContainer>
  );
}

export default AuditLogGrid;
