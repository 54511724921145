import { type ReactElement, useCallback, useRef } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';
import type { SxProps } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns/format';
import { useSelector } from 'react-redux';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import useClosedPeriodValuesColumnDefs from 'accruals/pages/trial/hooks/useClosedPeriodValuesColumnDefs';
import useClosedPeriodValuesGridOptions from 'accruals/pages/trial/hooks/useClosedPeriodValuesGridOptions';
import useClosedPeriodValuesRows from 'accruals/pages/trial/hooks/useClosedPeriodValuesRows';
import { currencyToNumberFormatter } from 'formatters';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import type {
  ClosedPeriodExpensesUpsertRecord,
  HistoricalValuesExpenseUpsertRecord,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useUpsertClosedPeriodExpensesMutation } from 'shared/api/rtkq/closedperiodexpenses';
import { useGetPeriodsByTrialQuery } from 'shared/api/rtkq/periods';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

const DEFAULT_REF_STATE = {
  hasStartPaste: false,
  cellValues: [] as CellValueChangedEvent[],
};

function ClosedPeriodValuesGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;
  const gridRef = useRef<AgGridRef<HistoricalValuesExpenseUpsertRecord>>(null);

  const trial = useSelector(selectTrial);
  const { currentData: periods } = useGetPeriodsByTrialQuery(trial.trace_id);

  const refState = useRef(DEFAULT_REF_STATE);
  const columnDefs = useGridColDefs(useClosedPeriodValuesColumnDefs);
  const gridOptions = useGridOptions(useClosedPeriodValuesGridOptions);
  const rowData = useClosedPeriodValuesRows();
  const [upsertClosedPeriodExpenses] = useUpsertClosedPeriodExpensesMutation();

  const flushToUpsert = useCallback(() => {
    void (async () => {
      const expenses = refState.current.cellValues.reduce<
        ClosedPeriodExpensesUpsertRecord[]
      >((acc, { colDef, data, newValue }) => {
        let cost = currencyToNumberFormatter(newValue);
        if (Number.isNaN(cost)) {
          cost = 0;
        }
        const date = new Date(colDef.colId ?? '');
        const lastDayOfMonth = format(
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
          'yyyy-LL-dd',
        );

        const period = periods?.find((per) => per.end_date === lastDayOfMonth);
        if (!Number.isNaN(cost) && period?.latest_version) {
          acc.push({
            contract_container: data.contract_container,
            cost_category: data.cost_category,
            period_version: period.latest_version,
            amount: cost,
          });
        }

        return acc;
      }, []);

      try {
        gridRef.current?.gridApi()?.setGridOption('loading', true);
        await upsertClosedPeriodExpenses({ expenses });
        gridRef.current?.gridApi()?.setGridOption('loading', false);
      } catch (error) {
        Sentry.captureException(error);
      }

      refState.current.cellValues = [];
    })();
  }, [periods, upsertClosedPeriodExpenses]);

  const onPasteStart = useCallback(() => {
    refState.current.hasStartPaste = true;
  }, []);

  const onPasteEnd = useCallback(() => {
    flushToUpsert();
    refState.current.hasStartPaste = false;
  }, [flushToUpsert]);

  const onCellValueChanged = useCallback(
    (data: CellValueChangedEvent) => {
      refState.current.cellValues.push(data);
      if (!refState.current.hasStartPaste) {
        flushToUpsert();
      }
    },
    [flushToUpsert],
  );

  return (
    <CondorAgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      onCellValueChanged={onCellValueChanged}
      onPasteEnd={onPasteEnd}
      onPasteStart={onPasteStart}
    />
  );
}

// this grid doesn't get "periodified" so should be just hidden for closed periods
export default withPeriodSpecificGenericWrapper(ClosedPeriodValuesGrid);
