import { useState } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Alert from 'shared/ui/alert/Alert';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import useInputErrors from 'shared/lib/sidebar/hooks/useInputErrors';
import type {
  DropdownOption,
  ThirdPartyCompanyOptions,
  UserAddForm,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import {
  useCheckEmailExistsMutation,
  useInviteUserMutation,
} from 'shared/api/rtkq/users';

import UserInviteForm from './UserInviteForm';

type Props = {
  onClose: () => void;
};

function UserInviteDialog(props: Props) {
  const { onClose } = props;
  const [showLoadingButton, setShowLoadingButton] = useState(false);

  const defaultUser: UserAddForm = {
    first_name: '',
    last_name: '',
    email: '',
    third_party_companies: [] as ThirdPartyCompanyOptions[],
    title: '',
    trial_access: [],
  };
  const [user, setUser] = useState<UserAddForm>(defaultUser);
  const [showMessage, setShowMessage] = useState<{
    email_send_status?: 'success' | 'warning';
    openState: boolean;
    message: string;
  }>({ openState: false, message: '' });
  const [companyOptions, setCompanyOptions] = useState<
    ThirdPartyCompanyOptions[]
  >([]);
  const company = useSelector(selectCompany);
  const [inviteUser] = useInviteUserMutation();
  const [checkEmailExists] = useCheckEmailExistsMutation();
  const { inputErrors, markInputError } = useInputErrors();

  const handleGenericFieldOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const onAddNewThirdPartyCompany = (companyName: string) => {
    setCompanyOptions((prevCompanyOptions) => [
      ...prevCompanyOptions,
      {
        label: companyName,
        value: companyName,
        new: true,
      },
    ]);

    setUser((prevUser) => ({
      ...prevUser,
      third_party_companies: [
        ...prevUser.third_party_companies,
        { label: companyName, value: companyName, new: true },
      ],
    }));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUser((prevUser) => ({ ...prevUser, email: value }));
  };

  const handleThirdPartyCompanyChange = (
    value: Array<DropdownOption<string>>,
    fieldName: string,
  ) => {
    setUser((prevUser) => ({
      ...prevUser,
      [fieldName]: value.map((option) => ({
        value: option.value,
        label: option.label,
      })),
    }));
  };

  const handleTrialAccessChange = (
    value: Array<DropdownOption<string>>,
    fieldName: string,
  ) => {
    setUser((prevUser) => ({
      ...prevUser,
      [fieldName]: value.map((option) => option.value),
    }));
  };

  const handleSaveUser = async () => {
    /* TODO (santosh): 
      1. Show the add user button only if the currentUser has permission to add users
     */
    const { data: userInfo } = await inviteUser({
      ...user,
      // TODO(santosh) - future: Pass company or company group here
      company_id: company.trace_id,
      company_group_id: undefined,
    });

    if (userInfo !== undefined) {
      setShowMessage({
        openState: true,
        message: userInfo.message,
        email_send_status: userInfo.email_send_status,
      });
    }

    setCompanyOptions(
      user.third_party_companies.map((eachThirdPartyCompany) => ({
        label: eachThirdPartyCompany.label,
        value: eachThirdPartyCompany.value,
        new: false,
      })),
    );
  };

  const verifyEmailExists = async () => {
    const { data: emailExists } = await checkEmailExists({ email: user.email });
    if (emailExists) {
      markInputError('email', 'Email already exists');
      setShowLoadingButton(false);
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    void (async () => {
      setShowLoadingButton(true);
      const emailExists = await verifyEmailExists();
      if (emailExists) {
        return;
      }
      await handleSaveUser();
      onClose();
    })();
  };

  const handleSubmitForSaveAndAddAnother = () => {
    void (async () => {
      setShowLoadingButton(true);
      const emailExists = await verifyEmailExists();
      if (emailExists) {
        return;
      }
      await handleSaveUser();
      setUser(defaultUser);
      setShowLoadingButton(false);
    })();
  };

  const buttonDisabled =
    !user.first_name ||
    !user.last_name ||
    !user.email ||
    user.third_party_companies.length === 0;

  return (
    <Dialog open>
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="subtitle1">Add User</Typography>
      </DialogTitleWithClose>
      <DialogContent sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FlexGridContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography color="primary" variant="subtitle1">
                Invite user to {company.name}
              </Typography>
              <Box sx={{ height: '10px' }} />
              <Typography>
                Once added, users will have view access to the trials you add
                them to. You can manage their permissions after you invite them
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: '20px' }} />

          <UserInviteForm
            companyOptions={companyOptions}
            handleEmailChange={handleEmailChange}
            handleGenericFieldOnChange={handleGenericFieldOnChange}
            handleThirdPartyCompanyChange={handleThirdPartyCompanyChange}
            handleTrialAccessChange={handleTrialAccessChange}
            inputErrors={inputErrors}
            setCompanyOptions={setCompanyOptions}
            user={user}
            onAddNewThirdPartyCompany={onAddNewThirdPartyCompany}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={4000}
            open={showMessage.openState}
            onClose={() =>
              setShowMessage({
                email_send_status: undefined,
                message: '',
                openState: false,
              })
            }
          >
            <Alert severity={showMessage.email_send_status}>
              {showMessage.message}
            </Alert>
          </Snackbar>
        </FlexGridContainer>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          disabled={buttonDisabled}
          loading={showLoadingButton}
          testId="save-and-add-another-user-button"
          variant="outlined"
          onClick={handleSubmitForSaveAndAddAnother}
        >
          Save and Add Another
        </LoadingButton>
        <LoadingButton
          disabled={buttonDisabled}
          loading={showLoadingButton}
          testId="invite-user-button"
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default UserInviteDialog;
