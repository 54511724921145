import type { ContractBudgetSnapshotResponse } from 'shared/lib/types';

import constructApi from './constructApi';
import { CONTRACT_EXPENSES } from './contracts';

const CONTRACT_BUDGET_SNAPSHOTS = 'CONTRACT_BUDGET_SNAPSHOTS';
const CONTRACT_BUDGET_RECORDS = 'CONTRACT_BUDGET_RECORDS';

const apiEndpointFactory = constructApi<ContractBudgetSnapshotResponse>(
  'contractbudgetsnapshots',
).withTags(CONTRACT_BUDGET_SNAPSHOTS, [
  CONTRACT_BUDGET_RECORDS,
  CONTRACT_EXPENSES,
]);
const api = apiEndpointFactory.inject(() => ({
  getContractBudgetSnapshotsByContract: apiEndpointFactory.getBy(
    'contract',
    '-created_at',
  ),
  deleteContractBudgetSnapshot: apiEndpointFactory.delete([CONTRACT_EXPENSES]),
  updateContractBudgetSnapshot: apiEndpointFactory.update(),
  createContractBudgetSnapshotFromCsv: apiEndpointFactory.create(
    [CONTRACT_EXPENSES],
    'new_from_csv',
  ),
}));

export const {
  useGetContractBudgetSnapshotsByContractQuery,
  useCreateContractBudgetSnapshotFromCsvMutation,
  useDeleteContractBudgetSnapshotMutation,
} = api;
