import Typography from '@mui/material/Typography';

import Chip from 'shared/ui/chip/Chip';

import Success from '../../assets/icons/Success';
import type { MappedColumn } from './types';

import classes from './ColumnMapperChips.module.scss';

const defaultText = 'Select a header for each column';
const defaultBody =
  'Headers with (*) are required to assign. Unassigned columns will be ignored.';

type Props = { body?: string; columns: MappedColumn[]; text?: string };

function ColumnMapperChips(props: Props) {
  const { columns, body, text = defaultText } = props;

  function getBody(): string {
    return body ?? defaultBody.replace('%d', columns.length.toString());
  }

  return (
    <>
      <Typography color="primary.main" variant="h5">
        {text}
      </Typography>
      <Typography variant="body1">{getBody()}</Typography>
      <ul className={classes.list}>
        {columns.map((column) => (
          <li key={column.label}>
            <Chip
              icon={column.mappedTo ? <Success /> : undefined}
              label={`${column.label} ${column.required ? '*' : ''}`}
              size="small"
              color={
                column.mappedTo
                  ? 'success'
                  : column.required
                    ? 'error'
                    : 'default'
              }
            />
          </li>
        ))}
      </ul>
    </>
  );
}

export default ColumnMapperChips;
