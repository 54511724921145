import type { ReactElement } from 'react';

import useRecordBlobDataLoader from 'accruals/components/periods/hooks/useRecordBlobDataLoader';

import ContractFilesList from 'accruals/pages/clinical-expenses/shared/contract-record/ContractFilesList';
import type {
  ContractFileApiResult,
  PeriodRecordBlobType,
  TraceId,
} from 'shared/lib/types';

type Props = {
  parentMenuItem?: TraceId;
  savedObjectType: PeriodRecordBlobType;
};

export default function ContractFileBlobViewer(props: Props): ReactElement {
  const { savedObjectType, parentMenuItem } = props;
  const blobData = useRecordBlobDataLoader<ContractFileApiResult[] | undefined>(
    savedObjectType,
    parentMenuItem,
  );

  return <ContractFilesList files={blobData} />;
}
