import type SnapshotCell from './SnapshotCell';
import type { Validity } from './Validity';

class FormatCondition implements Validity {
  isValid(_cell: SnapshotCell): boolean {
    // format for boolean yes/no, 1/0 ?
    return true; // any format
  }
}

export default FormatCondition;
