import type { MappedColumn } from 'shared/lib/column-mapper/types';

const columns: MappedColumn[] = [
  { label: 'Invoice number', value: 'invoice_number', required: true },
  { label: 'Vendor', value: 'vendor', required: true },
  { label: 'Po Number', value: 'po_number', required: true },
  { label: 'Invoice amount', value: 'invoice_amount', required: true },
  { label: 'G/L account', value: 'gl_account', required: true },
  { label: 'Invoice paid date', value: 'invoice_paid_date', required: true },
  {
    label: 'Invoice paid amount',
    value: 'invoice_paid_amount',
    required: true,
  },
  { label: 'Description', value: 'description', required: false },
  { label: 'Invoice date', value: 'invoice_date', required: true },
  { label: 'Invoice status', value: 'status', required: false },
  { label: 'Trial ID', value: 'trial_id', required: true },
  { label: 'Currency', value: 'currency', required: true },
];
export default columns;
