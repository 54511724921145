import { useState } from 'react';

import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import Wizard from 'shared/lib/wizard/Wizard';
import type {
  CompanyResponse,
  TrialRequest,
  TrialResponse,
} from 'shared/lib/types';

import LeavePageModal from './LeavePageModal';
import TrialInfoStep from './steps/TrialInfoStep';
import TrialReviewStep from './steps/TrialReviewStep';

type Props = {
  afterSave: () => void;
  company: CompanyResponse;
  trial?: TrialResponse | null;
  onClose: () => void;
};

function TrialSetupWizard(props: Props) {
  const { onClose, company, trial, afterSave } = props;

  const [trialRecord, setTrialRecord] = useState<TrialRequest>(
    trial
      ? { ...trial, program: trial.program.trace_id }
      : { company: company.trace_id, is_in_flight: false },
  );
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);

  const handleCloseBtnClick = () => {
    setShowLeavePageModal(true);
  };

  return (
    <FullWidthDialog open>
      <LeavePageModal
        isOpen={showLeavePageModal}
        setIsOpen={setShowLeavePageModal}
        onLeaveBtnClick={() => {
          onClose();
        }}
        onStayBtnClick={() => {
          setShowLeavePageModal(false);
        }}
      />
      <Wizard
        devTitle="FURY"
        stepNames={['Trial record', 'Review']}
        title={trialRecord.trace_id ? 'Edit trial record' : 'New trial record'}
        onClose={handleCloseBtnClick}
      >
        <TrialInfoStep
          setTrialRecord={setTrialRecord}
          trialRecord={trialRecord}
        />
        <TrialReviewStep afterSave={afterSave} trialRecord={trialRecord} />
      </Wizard>
    </FullWidthDialog>
  );
}

export default TrialSetupWizard;
