import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

export default function useCroContractBudgetGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions {
  return useMemo(
    () => ({
      groupDisplayType: 'groupRows',
      getRowId: 'id',
      groupTotalRow: 'bottom' as const,
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
    }),
    [],
  );
}
