import Group from '@visx/group/lib/Group';
import ordinal from '@visx/scale/lib/scales/ordinal';
import Pie from '@visx/shape/lib/shapes/Pie';

import { colorsRange } from '../../../../shared/lib/graphing/theme';
import type { LabelValueOption } from '../../../../shared/lib/types';
import Donut from './Donut';

type DonutChartData = LabelValueOption<string, number>;

const usage = (data: DonutChartData) => data.value;

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

type DonutChartProps = {
  data: DonutChartData[];
  height: number;
  margin?: typeof defaultMargin;
  width: number;
};

export default function DonutChart(props: DonutChartProps) {
  const { data, width, height, margin = defaultMargin } = props;

  const values = data.map(({ value }) => value);
  const colorScale = ordinal({ domain: values, range: colorsRange });

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 50;

  return (
    <svg height={height} width={width}>
      <rect fill="transparent" height={height} rx={14} width={width} />
      <Group left={centerX + margin.left} top={centerY + margin.top}>
        <Pie
          cornerRadius={3}
          data={data}
          innerRadius={radius - donutThickness}
          outerRadius={radius}
          padAngle={0.005}
          pieValue={usage}
        >
          {(pie) => (
            <Donut<DonutChartData>
              {...pie}
              getColor={(arc) => colorScale(arc.data.value)}
              getKey={(arc) => arc.data.label}
            />
          )}
        </Pie>
      </Group>
    </svg>
  );
}
