import type { HTMLAttributes } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';

import type { UserOption } from 'shared/lib/types';

type Props = {
  option: UserOption;
  renderProps: HTMLAttributes<HTMLLIElement> & { key?: unknown };
};

function TrialTeamMappingOption(props: Props) {
  const { renderProps, option } = props;
  const { name, picture, company_name, title } = option;
  const { className, key: _key, ...restProps } = renderProps;

  return (
    <Box
      key={option.value}
      component="li"
      display="flex"
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 2,
      }}
      {...restProps}
      className={className}
    >
      <Box display="flex" flexDirection="row">
        <ListItemAvatar>
          <Avatar alt={name} src={String(picture)} />
        </ListItemAvatar>
        <Box display="flex" flexDirection="column">
          <Typography variant="h4">{name}</Typography>
          <Typography variant="body1">
            {company_name}
            {title ? ` - ${title}` : undefined}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default TrialTeamMappingOption;
