import { useMemo } from 'react';

import { getMoneyCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

export default function useInvoiceListingColumnDefs(
  _isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      { field: 'trace_id', hide: true },
      {
        headerName: '',
        suppressMovable: true,
        width: 60,
        valueGetter: 'node.rowIndex + 1',
      },
      { headerName: 'Invoice #', field: 'invoice_number' },
      { headerName: 'Vendor', field: 'vendor' },
      { headerName: 'PO #', field: 'po_number' },
      {
        headerName: 'Invoice amount',
        field: 'invoice_amount',
        ...getMoneyCellConfig({ currencyFooterStyle: 'decimal' }),
        valueGetter: 'Number(node.data.invoice_amount)',
        aggFunc: 'sum',
      },
      { headerName: 'G/L account', field: 'gl_account' },
      { headerName: 'Description', field: 'description' },
      { headerName: 'Invoice date', field: 'invoice_date' },
      { headerName: 'Invoice paid date', field: 'invoice_paid_date' },
      {
        headerName: 'Invoice paid amount',
        field: 'invoice_point_amount',
        ...getMoneyCellConfig({ currencyFooterStyle: 'decimal' }),
        valueGetter: 'Number(node.data.invoice_paid_amount)',
        aggFunc: 'sum',
      },
      { headerName: 'Status', field: 'status' },
      { headerName: 'Trial ID', field: 'trial_id' },
      { headerName: 'Currency', field: 'currency' },
    ],
    [],
  );
}
