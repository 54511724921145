import { useMemo } from 'react';

import { AIP_SUFFIX } from 'formatters';
import type { ForecastExpenses } from 'shared/lib/types';
import { processTrialExpenseSummaryRows } from 'shared/processors/processTrialExpenseSummaryRows';

import { useGetTrialExpenseSummaryQuery } from 'shared/api/rtkq/periods';

import useForecast from './useForecast';

const useFinancialForecastSummaryRows = (): {
  loading: boolean;
  rows: ForecastExpenses[] | undefined;
} => {
  const {
    loading: forecastLoading,
    forecast,
    generatedForecast,
  } = useForecast();

  // fetching it in FE instead of in the backend to make use of cached queries since this can be a slow API call
  const {
    currentData: expenseSummaryGrid,
    isLoading: expenseSummaryLoading,
    isFetching: expenseSummaryFetching,
    isUninitialized: expenseSummaryUninitialized,
  } = useGetTrialExpenseSummaryQuery(forecast.start_period);

  const trialExpenseRows = processTrialExpenseSummaryRows(
    expenseSummaryGrid,
    'actual_month_',
  );

  return useMemo(() => {
    const newExpensesGridData: ForecastExpenses[] = [];

    if (generatedForecast) {
      for (const forecastRow of generatedForecast.expenses) {
        const actualRows = trialExpenseRows?.filter(
          (actualsRow) =>
            (actualsRow.raw_cost_category === forecastRow.cost_category ||
              actualsRow.cost_category.replace(AIP_SUFFIX, '') ===
                forecastRow.cost_category) &&
            actualsRow.contract_container_trace_id ===
              forecastRow.contract_container_trace_id,
        );

        if (!actualRows?.length) {
          continue;
        }

        // combine AIP rows into the normal ones (e.g. 'Direct Fees AIP' into 'Direct Fees')
        const newRow: ForecastExpenses = {
          ...forecastRow,
          vendor_type: actualRows[0].vendor_type,
        };
        for (const actualRow of actualRows) {
          for (const key of Object.keys(actualRow)) {
            if (
              key.includes('actual_month_') ||
              ['reconciled_expense', 'percent_recognized'].includes(key)
            ) {
              newRow[key] = actualRows.reduce(
                (acc, row) => acc + Number(row[key] ?? 0),
                0,
              );
            }
            if (key === 'default_contract_value') {
              newRow[key] = actualRow[key];
            }
          }
        }

        newRow.contracted_total = 0;
        newRow.forecasted_total = 0;

        for (const key of Object.keys(newRow)) {
          if (
            key.includes('forecasted_month_') &&
            typeof newRow.forecasted_total === 'number'
          ) {
            newRow.forecasted_total += Number(newRow[key] ?? 0);
          }
          if (
            key.includes('actual_month_') &&
            typeof newRow.contracted_total === 'number'
          ) {
            newRow.contracted_total += Number(newRow[key] ?? 0);
          }
        }

        newRow.grand_total = newRow.contracted_total + newRow.forecasted_total;
        newRow.under_over =
          newRow.grand_total - Number(newRow.default_contract_value ?? 0);
        newRow.under_over_percent =
          newRow.default_contract_value !== undefined
            ? (newRow.under_over / Number(newRow.default_contract_value)) * 100
            : 0;
        newExpensesGridData.push(newRow);
      }
    }

    return {
      loading:
        forecastLoading ||
        expenseSummaryLoading ||
        expenseSummaryFetching ||
        expenseSummaryUninitialized,
      rows: newExpensesGridData.length === 0 ? undefined : newExpensesGridData,
    };
  }, [
    generatedForecast,
    forecastLoading,
    expenseSummaryLoading,
    expenseSummaryFetching,
    expenseSummaryUninitialized,
    trialExpenseRows,
  ]);
};

export default useFinancialForecastSummaryRows;
