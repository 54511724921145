import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Button from 'shared/ui/button/Button';

import type { VendorResponse, VendorType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useCreateVendorMutation } from 'shared/api/rtkq/vendors';

import type { Vendor } from './VendorField';

type Props = {
  isUniqueName: (name: string) => boolean;
  vendor?: Vendor;
  vendorType: VendorType;
  onClose: () => void;
  onSave: (vendor: VendorResponse) => void;
};

type Fields = { name: string[] };

function NewVendorDialog(props: Props) {
  const company = useSelector(selectCompany);
  const { vendor, isUniqueName, onClose, onSave, vendorType } = props;
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState<Fields | null>();
  const nameRef = useRef<HTMLInputElement>(null);
  const subsidiaryRef = useRef<HTMLInputElement>(null);
  const [createVendor] = useCreateVendorMutation();

  function handleSave() {
    void (async () => {
      const name = nameRef.current?.value;
      if (typeof name === 'undefined') {
        return;
      }

      if (!isUniqueName(name)) {
        setFormErrors((prevState) => ({
          ...prevState,
          name: ['Vendor name already exists. Please use different.'],
        }));
        return;
      }

      const newVendor = await createVendor({
        name,
        company: company.trace_id,
        vendor_type: vendorType,
      }).unwrap();
      onSave(newVendor);
    })();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { validity } = event.target;
    setIsFormValid(validity.valid);
  }

  function isNameValid() {
    return (
      typeof nameRef.current?.value !== 'undefined' &&
      !nameRef.current.validity.valid
    );
  }

  return (
    <Dialog maxWidth="xs" fullWidth open>
      <DialogTitle>
        <Typography component="span" variant="subtitle1">
          New vendor
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ borderTop: (theme) => `1px solid ${theme.palette.grey[300]}` }}
      >
        <Stack gap={3} mt={3}>
          <FormControl>
            <CondorTextField
              autoComplete="off"
              defaultValue={vendor?.name}
              errors={isNameValid() || !!formErrors?.name}
              inputRef={nameRef}
              label="Vendor name"
              required
              onChange={onChange}
            />
          </FormControl>
          <FormControl>
            <CondorTextField
              autoComplete="off"
              defaultValue={vendor?.subsidiary}
              inputRef={subsidiaryRef}
              label="Subsidiary"
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button testId="cancel" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!isFormValid}
          testId="save"
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewVendorDialog;
