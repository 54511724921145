import { localPoint } from '@visx/event';
import type { ScaleBand } from 'd3-scale';
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import type { ScaleInput } from '@visx/scale/lib';
import type { AxisScale } from '@visx/axis/lib';

import type { RectDimensionShape } from '../types';
import { scaleInvert } from '../graphUtils';
import EventEmitter from './EventEmitter';

type Props<TooltipData, XScale extends AxisScale> = RectDimensionShape & {
  xScale: ScaleBand<string>;
  tooltip: UseTooltipParams<TooltipData>;
  graphData: TooltipData[];
  xAccessor: (d: TooltipData) => ScaleInput<XScale>;
};

function GraphTooltipDataProvider<TooltipData, XScale extends AxisScale>(
  props: Props<TooltipData, XScale>,
) {
  const { margin, height, width, xScale, graphData, tooltip, xAccessor } =
    props;
  return (
    <EventEmitter
      height={height}
      left={margin.left}
      top={margin.top}
      width={width}
      onPointerLeave={() => {
        tooltip.hideTooltip();
      }}
      onPointerMove={(event) => {
        const point = localPoint(event);
        const datumIndex = scaleInvert(xScale, point?.x ?? 0);
        const tooltipData = graphData[datumIndex];
        const tooltipLeft =
          (xScale(xAccessor(tooltipData)) ?? 0) + xScale.bandwidth() / 2;

        tooltip.showTooltip({
          tooltipData,
          tooltipTop: point?.y,
          tooltipLeft,
        });
      }}
    />
  );
}

export default GraphTooltipDataProvider;
