import Box from '@mui/material/Box';
import Legend from '@visx/legend/lib/legends/Legend';
import type { AnyD3Scale } from '@visx/scale/lib/types/Scale';

import LegendLine from './LegendLine';
import LegendRect from './LegendRect';

type GraphLegendProps = {
  additionalText?: (datum: ReturnType<AnyD3Scale>) => string;
  colorScale: AnyD3Scale;
  shapes: (
    datum: ReturnType<AnyD3Scale>,
  ) =>
    | { type: 'dashed-line' }
    | { type: 'line' }
    | { type: 'rect'; stroke?: string };
};

function GraphLegend(props: GraphLegendProps) {
  const { additionalText, colorScale, shapes } = props;

  return (
    <Legend scale={colorScale}>
      {(labels) => (
        <Box sx={{ display: 'flex', flexFlow: 'row' }}>
          {labels.map((label) => {
            const shape = shapes(label);
            const text = additionalText?.(label);

            return (
              <Box
                key={label.text}
                sx={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <svg height={LEGEND_SIZE} width={LEGEND_SIZE}>
                  {shape.type === 'line' || shape.type === 'dashed-line' ? (
                    <LegendLine
                      dashed={shape.type === 'dashed-line'}
                      stroke={label.value}
                    />
                  ) : (
                    <LegendRect fill={label.value} stroke={shape.stroke} />
                  )}
                </svg>
                <Box style={{ marginLeft: 8 }}>
                  {label.text}
                  {text !== undefined ? (
                    <Box component="span" sx={{ ml: 0.5, fontWeight: 700 }}>
                      {text}
                    </Box>
                  ) : null}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Legend>
  );
}

export const LEGEND_SIZE = 12;

export default GraphLegend;
