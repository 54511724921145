import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { JournalEntryRow } from 'shared/lib/types';

export default function useTrialActivityEnrollmentGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<JournalEntryRow> {
  return useMemo(() => ({}), []);
}
