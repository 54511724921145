import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ExternalLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import config from 'config';
import * as routes from 'routes';

import LastCodeUpdate from './LastCodeUpdate';

function getRequestUrl(relativePath = '') {
  const path = relativePath.startsWith('/') ? relativePath : `/${relativePath}`;
  return `${config.VITE_APP_API_HOST}/api${path}`;
}

function getAdminUrl(relativePath = '') {
  const path = relativePath.startsWith('/') ? relativePath : `/${relativePath}`;
  return `${config.VITE_APP_API_HOST}/admin${path}`;
}

function DevBar() {
  const adminUrl = getAdminUrl();
  const apiUrl = getRequestUrl();
  const menuItems = [
    {
      key: 'env',
      contents: (
        <Typography color="text.primary">{`Environment: ${config.VITE_APP_NAME}`}</Typography>
      ),
    },
    { key: 'version', contents: <LastCodeUpdate /> },
    { key: '/', contents: <Link to="/">App</Link> },
    {
      key: adminUrl,
      contents: (
        <ExternalLink href={adminUrl} target="_blank">
          Admin portal
        </ExternalLink>
      ),
    },
    {
      key: apiUrl,
      contents: (
        <ExternalLink href={apiUrl} target="_blank">
          API browser
        </ExternalLink>
      ),
    },
    {
      key: routes.getDevComponents(),
      contents: <Link to={routes.getDevComponents()}>Component sandbox</Link>,
    },
    {
      key: routes.getDevDemos(),
      contents: <Link to={routes.getDevDemos()}>Tech demos</Link>,
    },
  ];
  return (
    <AppBar
      position="relative"
      sx={{
        flex: '0 0 auto',
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
    >
      <Box
        component="ul"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '30px',
          margin: 0,
          padding: 2,
          listStyle: 'none',
        }}
      >
        {menuItems
          .map((menuItem) => <li key={menuItem.key}>{menuItem.contents}</li>)
          .flatMap((menuItem) => [
            menuItem,
            <Typography key={`${menuItem.key}_spacer`} color="text.primary">
              •
            </Typography>,
          ])
          .slice(0, -1)}
      </Box>
    </AppBar>
  );
}

export default DevBar;
