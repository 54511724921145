import Lock from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'shared/ui/button/Button';

import CloseReopenPeriodButton from 'accruals/components/periods/CloseReopenPeriodButton';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import {
  missingPeriodVersion,
  selectPeriodVersion,
  useChangePeriodVersion,
} from 'accruals/state/slices/periodVersionSlice';
import * as routes from 'routes';
import HiddenForReadOnlyPermission from 'shared/lib/read-only-permission/HiddenForReadOnlyPermission';

import { useGetPeriodVersionsByPeriodQuery } from 'shared/api/rtkq/periodversions';
import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

function ClosedPeriodBanner() {
  const navigate = useNavigate();

  const period = useSelector(selectPeriod);
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const isClosed = useIsClosedPeriodVersion();
  const { currentData: periodVersions = [] } =
    useGetPeriodVersionsByPeriodQuery(period.trace_id);
  const changePeriodVersion = useChangePeriodVersion();

  if (!isClosed) {
    return undefined;
  }

  const isLatest = currentPeriodVersion.trace_id === period.latest_version;
  return (
    <HiddenForReadOnlyPermission>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        sx={{
          display: 'flex',
          padding: '16px 24px',
          gap: '32px',
          borderRadius: '4px',
          borderBottom: '1px solid',
          borderBottomColor: 'primary.main',
          backgroundColor: 'primary.light',
          justifyContent: 'space-between',
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent="space-between"
        >
          <Lock />
          <Typography variant="body1">
            {isLatest
              ? 'You are viewing a closed period. To make changes, reopen the period.'
              : 'You are viewing an archived version of this period. You cannot reopen or make any changes to this view.'}
          </Typography>
        </Box>
        {isLatest ? (
          <CloseReopenPeriodButton bannerVariant />
        ) : (
          <Button
            testId="return_to_current_version"
            variant="outlined"
            onClick={() => {
              // some of the deep linked pages switch urls around in closed periods,
              // so safest to send to a "known-good" page. Do this first to not
              // try to inadvertently load incorrect data
              navigate(routes.getTrialDashboard());

              changePeriodVersion(
                periodVersions.find(
                  (pv) => pv.trace_id === period.latest_version,
                ) ?? missingPeriodVersion,
              );
            }}
          >
            Return to current version
          </Button>
        )}
      </Box>
    </HiddenForReadOnlyPermission>
  );
}

export default ClosedPeriodBanner;
