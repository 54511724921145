import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'shared/state/store';

type WizardName = 'ContractVersion' | 'PatientAssessment' | 'SiteBudget' | null;

type ActiveWizardOnLoadState = { wizard: WizardName };

const initialState: ActiveWizardOnLoadState = { wizard: null };

const activeWizardOnLoadSlice = createSlice({
  name: 'activeWizardOnLoad',
  initialState,
  reducers: {
    changeActiveWizardOnLoad: (state, action: PayloadAction<WizardName>) => {
      state.wizard = action.payload;
    },
  },
});

export const selectActiveWizardOnLoad = (state: RootState) =>
  state.activeWizardOnLoad.wizard;

export const { changeActiveWizardOnLoad } = activeWizardOnLoadSlice.actions;

export const activeWizardOnLoadReducer = activeWizardOnLoadSlice.reducer;
