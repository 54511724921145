import type { ReactElement } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import logo from 'shared/assets/images/logo.png';
import Button from 'shared/ui/button/Button';

import ErrorContainer from 'shared/containers/error-container/ErrorContainer';

export default function ResetPassword(): ReactElement {
  const { logout } = useAuth0();

  return (
    <ErrorContainer>
      <Box component="img" height={80} src={logo} sx={{ mb: 2 }} width={80} />
      <Typography color="primary" sx={{ mb: 2 }} variant="h4">
        Welcome to Condor
      </Typography>
      <Typography
        align="center"
        data-testid="user_reset_password"
        variant="body1"
      >
        Please reset your password, as we require users to do so every 60 days.
        <br />
        Click logout, then &quot;Forgot password?&quot; on the login screen
        <br />
        <br />
        Need help? Contact us at{' '}
        <a href="mailto:support@condorsoftware.com">
          support@condorsoftware.com
        </a>
        {/*
         */}.
      </Typography>
      <br />
      <Button
        testId="reset_password_click"
        variant="contained"
        onClick={() => void logout()}
      >
        Logout
      </Button>
    </ErrorContainer>
  );
}
