export const getHomePage = () => '/';

// COMPANY PAGES
export const getCompany = () => '/company';
export const getCompanyPeriodClosed = () => `${getCompany()}/period-closed`;
export const getAuditLog = () => `${getCompany()}/audit-log`;
export const getCompanyDashboard = () => `${getCompany()}/dashboard`;
export const getCompanyFiles = (path: string = getCompany()) =>
  `${path}/company-files`;
export const getInvoices = (path: string = getCompany()) => `${path}/invoices`;
export const getPurchaseOrders = (path: string = getCompany()) =>
  `${path}/purchase-orders`;
export const getFxRates = (path: string = getCompany()) => `${path}/fx-rates`;
export const getFxRatesTab = (
  path: string = getCompany(),
  activeTab = ':activeTab',
) => `${getFxRates(path)}/${activeTab}`;
export const getGlAccounts = (path: string = getCompany()) =>
  `${path}/gl-accounts`;
export const getVariance = (path: string = getCompany()) => `${path}/variance`;

// TRIAL PAGES
export const getAnalytics = () => '/analytics';
export const getAnalyticsTab = (activeTab = ':activeTab') =>
  `/analytics/${activeTab}`;
export const getClinicalExpenses = () => '/clinical-expenses';
export const getTrialDashboard = () => '/trial-dashboard';

// Please do not change this method signature. We use this for period closed and all the snapshots have is trace_id
export const getClinicalExpensesByContractContainer = (
  contractContainerId = ':contractContainerId',
) => `${getClinicalExpenses()}/${contractContainerId}`;

// Please do not change this method signature. We use this for period closed and all the snapshots have are the trace_id's
export const getClinicalExpensesByContractVersion = (
  contractContainerId = ':contractContainerId',
  contractVersionId = ':contractVersionId',
) =>
  `${getClinicalExpensesByContractContainer(contractContainerId)}/contract-version/${contractVersionId}`;

export const getClinicalExpensesTabByContractContainer = (
  contractContainerId = ':contractContainerId',
  activeTab = ':activeTab',
) =>
  `${getClinicalExpensesByContractContainer(contractContainerId)}/${activeTab}`;
export const getClinicalExpensesSubTabByContractContainer = (
  contractContainerId = ':contractContainerId',
  activeTab = ':activeTab',
  activeSubTab = ':activeSubTab',
) =>
  `${getClinicalExpensesByContractContainer(contractContainerId)}/${activeTab}/${activeSubTab}`;

export const getTrialActivity = () => '/trial-activity';
export const getSiteLabCostMatrix = () => `${getTrialActivity()}/costs`;

export const getPatientContainer = () => `${getTrialActivity()}/patients`;
export const getPatientNavigation = (activeTab = ':activeTab') =>
  `${getPatientContainer()}/${activeTab}`;
export const getPatientJourneyByCohortNavigation = (
  activeTab = ':activeTab',
  patientCohortId = ':patientCohortId',
) => `${getPatientNavigation(activeTab)}/${patientCohortId}`;
export const getFinancialClose = () => '/financial-close';
export const getFinancialCloseTab = (activeTab = ':activeTab') =>
  `${getFinancialClose()}/${activeTab}`;
export const getJournalEntry = () => '/journal-entry';
export const getOcc = () => `${getClinicalExpenses()}/occ`;

// Please do not change this method signature. We use this for period closed and all the snapshots have are the trace_id's
export const getOccByContractVersion = (
  contractContainerId = ':contractContainerId',
  contractVersionId = ':contractVersionId',
) => `${getOcc()}/${contractContainerId}/contract-version/${contractVersionId}`;

export const getOccExpenses = () => `${getOcc()}/expenses`;
export const getOccRecon = () => `${getOcc()}/recon`;
export const getTrialFiles = () => '/trial-files';
export const getTrialFilesNavigation = (activeTab = ':activeTab') =>
  `${getTrialFiles()}/${activeTab}`;
export const getTrialPage = (
  companyTraceId = ':companyTraceId',
  trialTraceId = ':trialTraceId',
) => `/companies/${companyTraceId}/trials/${trialTraceId}`;

// FORECASTING PAGES
export const getForecast = () => '/forecast';
export const getSpecificForecast = (forecastId = ':forecastId') =>
  `${getForecast()}/${forecastId}`;
export const getForecastTab = (
  forecastId = ':forecastId',
  activeTab = ':activeTab',
) => `${getSpecificForecast(forecastId)}/${activeTab}`;
export const getForecastSummaryTab = (forecastId = ':forecastId') =>
  getForecastTab(forecastId, 'summary');
export const getForecastDetailTab = (forecastId = ':forecastId') =>
  getForecastTab(forecastId, 'detail');
export const getForecastAssumptionsTab = (forecastId = ':forecastId') =>
  getForecastTab(forecastId, 'assumptions');

// SETTINGS PAGES
export const getSettings = () => '/settings';
export const getCompanySettings = (path: string = getSettings()) =>
  `${path}/company`;
export const getControlListing = (path: string = getSettings()) =>
  `${path}/control-listing`;
export const getUsersAndPermissions = (path: string = getSettings()) =>
  `${path}/user-access`;

// DEV PAGES
export const getDevComponents = () => '/dev/components';
export const getDevComponent = (componentKey = ':componentKey') =>
  `/dev/components/${componentKey}`;
export const getDevDemos = () => '/dev/demos';
export const getDevDemo = (demoKey = ':demoKey') => `/dev/demos/${demoKey}`;
