import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import RegionGroupList from 'shared/lib/contract-assumptions/region-group/RegionGroupList';
import RegionList from 'shared/lib/contract-assumptions/region-list/RegionList';
import {
  validateRegion,
  validateRegionGroup,
  validateRegions,
} from 'shared/lib/contract-assumptions/validators';
import type {
  RegionGroupListItemType,
  RegionListItemType,
  TraceId,
  VendorType,
} from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

type Props = {
  contractVersionTraceId: TraceId;
  defaultRegionGroups: RegionGroupListItemType[];
  defaultRegions: RegionListItemType[];
  regionGroups: RegionGroupListItemType[];
  regions: RegionListItemType[];
  setRegionGroups: (regionGroups: RegionGroupListItemType[]) => void;
  setRegions: (regions: RegionListItemType[]) => void;
  vendorType: VendorType | undefined;
};

function RegionStep(props: Props) {
  const {
    vendorType,
    contractVersionTraceId,
    regions,
    setRegions,
    regionGroups,
    setRegionGroups,
    defaultRegions,
    defaultRegionGroups,
  } = props;

  const hasInvalidRegion = regions.some((region) =>
    validateRegion(region, regions, regionGroups),
  );
  const hasInvalidRegionGroup = regionGroups.some(
    (regionGroup) =>
      validateRegionGroup(regionGroup, regions, regionGroups) ||
      validateRegions(regionGroup),
  );
  const disableNextButton = hasInvalidRegion || hasInvalidRegionGroup;

  return (
    <WizardStep
      description="You must include at least one region. All added regions will be included in 'Global'."
      disableNextButton={disableNextButton}
      header={`Fill in the regions as specified in the ${
        vendorType === 'CRO' ? 'CRO contract' : 'Other Clinical Contract'
      }.`}
    >
      <Box marginX="auto" mt={4} width="63%">
        <RegionList
          contractVersionTraceId={contractVersionTraceId}
          defaultRegions={defaultRegions}
          regionGroups={regionGroups}
          regions={regions}
          setRegions={setRegions}
        />
        <Divider
          sx={{ my: 4, width: ({ spacing }) => `calc(100% - ${spacing(7)})` }}
        />
        <RegionGroupList
          contractVersionTraceId={contractVersionTraceId}
          defaultRegionGroups={defaultRegionGroups}
          regionGroups={regionGroups}
          regions={regions}
          setRegionGroups={setRegionGroups}
        />
      </Box>
    </WizardStep>
  );
}

export default RegionStep;
