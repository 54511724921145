import type { ReactElement } from 'react';

import Box from '@mui/material/Box';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';

import UploadEdcSnapshots from '../buttons/UploadEdcSnapshots';

function PatientActivityHeader(): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        mb: 2,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <UploadEdcSnapshots />
    </Box>
  );
}

export default withPeriodSpecificGenericWrapper(PatientActivityHeader);
