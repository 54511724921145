import { skipToken } from '@reduxjs/toolkit/query';

import EdcReportVisitsValidatorBuilder from 'shared/lib/report-validator/EdcReportVisitsValidatorBuilder';
import SnapshotCell from 'shared/lib/snapshot-validator/SnapshotCell';
import ValidityTag from 'shared/lib/snapshot-validator/ValidityTag';
import type { TraceId } from 'shared/lib/types';
import type { ValidationError } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/ValidationSidebar';

import { useGetProceduresEdcRecordsBySnapshotQuery } from 'shared/api/rtkq/proceduresedcrecords';

function useValidationQueue(snapshotId: TraceId | undefined) {
  const { currentData: allRecords } = useGetProceduresEdcRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  ); // sort: 'snapshot_row'

  function createValidationErrors(): ValidationError[] {
    const edcReportVisitsValidator = new EdcReportVisitsValidatorBuilder()
      .addRequiredField('subject')
      .addRequiredField('site_number')
      .addRequiredField('cohort')
      .addRequiredField('invoiceable_type')

      .addOptionalField('project')
      .addOptionalField('site_name')
      .addOptionalField('site_group')
      .addOptionalField('visit_name')
      .addOptionalField('savets')
      .addOptionalField('confirmed_event')
      .addOptionalField('invoiceable_detail')

      .createRowsValidator();

    const result: ValidityTag[] = (allRecords ?? []).flatMap((row, index) =>
      Object.entries(row).map(([field, value]) => {
        const cell = new SnapshotCell(value, { field }, { position: index });
        return edcReportVisitsValidator.validate(cell, new ValidityTag(field));
      }),
    );

    return result
      .filter((item) => item.getValidity() && item.getCell())
      .map((item) => {
        const error: ValidationError = {
          fields: [item.getField()],
          row: item.getCell()!.getRow().position,
          type: item.getField() as ValidationError['type'],
        };

        return error;
      });
  }

  return { allRecords, validationErrors: createValidationErrors() };
}

export default useValidationQueue;
