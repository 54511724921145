import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import CurrentContractsGrid from '../grids/CurrentContractsGrid';
import FinancialForecastSummaryGrid from '../grids/FinancialForecastSummaryGrid';
import OperationalForecastPatientsGrid from '../grids/OperationalForecastPatientsGrid';
import OperationalForecastSitesGrid from '../grids/OperationalForecastSitesGrid';

function DetailTab() {
  const [showActuals, setShowActuals] = useState<boolean>(true);

  return (
    <>
      <Tooltip title="Show actuals">
        <FormControlLabel
          label="Show actuals"
          control={
            <Switch
              checked={showActuals}
              onChange={(_, checked) => setShowActuals(checked)}
            />
          }
        />
      </Tooltip>
      <DashboardCard title="Financial Forecast">
        <FinancialForecastSummaryGrid
          overlayNoRowsTemplate="No forecast yet."
          showActuals={showActuals}
          sx={useMemo(() => ({ height: 400 }), [])}
        />
      </DashboardCard>
      <DashboardCard title="Operational Forecast">
        <OperationalForecastPatientsGrid
          overlayNoRowsTemplate="No forecast yet."
          showActuals={showActuals}
          sx={useMemo(() => ({ height: 350, mb: 5 }), [])}
        />
        <OperationalForecastSitesGrid
          overlayNoRowsTemplate="No forecast yet."
          showActuals={showActuals}
          sx={useMemo(() => ({ height: 350 }), [])}
        />
      </DashboardCard>
      <DashboardCard title="Contracts">
        <Box sx={{ height: 400 }}>
          <CurrentContractsGrid
            overlayNoRowsTemplate="No contracts found"
            sx={useMemo(() => ({ height: '100%' }), [])}
          />
        </Box>
      </DashboardCard>
    </>
  );
}

export default DetailTab;
