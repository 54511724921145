import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { CroExpenseSummaryRow } from 'shared/lib/types';

export default function useCroExpenseSummaryGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<CroExpenseSummaryRow> {
  return useMemo(
    () => ({ grandTotalRow: 'bottom', suppressAggFuncInHeader: true }),
    [],
  );
}
