import { LEGEND_SIZE } from './GraphLegend';

type LegendLineProps = {
  fill: string | undefined;
  stroke?: string;
};

function LegendRect(props: LegendLineProps) {
  const { fill, stroke } = props;

  return (
    <rect
      fill={fill}
      height={LEGEND_SIZE}
      stroke={stroke}
      width={LEGEND_SIZE}
    />
  );
}

export default LegendRect;
