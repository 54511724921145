import { useEffect } from 'react';

import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  missingForecast,
  selectForecast,
  selectForecastParameterDrawerOpen,
} from 'forecasting/state/slices/forecastSlice';
import * as routes from 'routes';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import withForecastingPageRequiredData from 'forecasting/api/hocs/withForecastingPageRequiredData';

import AssumptionsTab from './components/AssumptionsTab';
import DetailTab from './components/DetailTab';
import ForecastingTabs from './components/ForecastingTabs';
import ParameterDrawer from './components/ParameterDrawer';
import ParameterPanel, {
  DRAWER_HEADER_HEIGHT,
  DRAWER_HEIGHT,
} from './components/ParameterPanel';
import SummaryTab from './components/SummaryTab';

function ForecastingPage() {
  const { activeTab } = useParams<{
    activeTab: 'assumptions' | 'detail' | 'summary';
  }>();
  const isDrawerOpen = useSelector(selectForecastParameterDrawerOpen);
  const forecast = useSelector(selectForecast);
  const isNewParameterFlow = useFeatureFlag('forecast_parameter_drawer');
  const navigate = useNavigate();

  useEffect(() => {
    // If we dont have forecast, but we are still in a tab, assume we should navigate out of forecast details
    if (activeTab && forecast === missingForecast) {
      navigate(routes.getForecast());
    }
    // if we have a forecast but no active tab, assume we should navigate to the summary tab
    else if (!activeTab && forecast !== missingForecast) {
      navigate(routes.getForecastSummaryTab(forecast.trace_id));
    }
  }, [activeTab, forecast, navigate]);

  // we have to wait for the above `useEffect` to do its thing
  if (!activeTab) {
    return null;
  }

  return (
    <PageContentLoadingContainer
      sx={{ p: 0, display: 'flex', flexFlow: 'column' }}
      tabs={<ForecastingTabs />}
    >
      <Box
        sx={{
          p: 4,
          mb: isNewParameterFlow
            ? undefined
            : `${isDrawerOpen ? DRAWER_HEIGHT : DRAWER_HEADER_HEIGHT}px`,
          transition: ({ transitions }) =>
            transitions.create(['margin-bottom']),
        }}
      >
        {activeTab === 'summary' && <SummaryTab />}
        {activeTab === 'detail' && <DetailTab />}
        {activeTab === 'assumptions' && <AssumptionsTab />}
      </Box>
      {isNewParameterFlow ? <ParameterDrawer /> : <ParameterPanel />}
    </PageContentLoadingContainer>
  );
}

export default withForecastingPageRequiredData(ForecastingPage);
