import { Fragment } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import TrialSelector from 'shared/components/trial-selector/TrialSelector';

import { formatShortMonthYear } from 'shared/helpers/helpers';
import type { PeriodResponse } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

type Props = {
  /** The items to display after the trial information, separated by a divider */
  breadcrumbs: string[];
  breadcrumbsLoading?: boolean;

  /** Optional components to display on the right side of the title */
  rightItems?: React.ReactNode;
};

const DIVIDER = <Typography variant="caption">•</Typography>;

const periodToBreadcrumbString = (period: PeriodResponse) =>
  `${formatShortMonthYear(period.end_date)} (${period.is_closed ? 'Closed' : 'Open'})`;

function Breadcrumb({ children }: { children: React.ReactNode }) {
  return <Typography variant="caption">{children}</Typography>;
}

function PageTitle({
  breadcrumbs,
  rightItems,
  breadcrumbsLoading = false,
}: Props) {
  const trial = useSelector(selectTrial);

  return (
    <Paper
      elevation={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 3,
        gap: 1,
        height: ({ spacing }) => spacing(6),
        minHeight: ({ spacing }) => spacing(6),
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
      square
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <TrialSelector>
          <Typography variant="button">{trial.study_id}</Typography>
          <Breadcrumb>{trial.indication}</Breadcrumb>
        </TrialSelector>
        {breadcrumbsLoading && <Skeleton variant="text" width={300} />}
        {!breadcrumbsLoading &&
          breadcrumbs.map(
            (crumb, i) => (
              <Fragment key={crumb}>
                {i !== 0 && DIVIDER}
                <Breadcrumb>{crumb}</Breadcrumb>
              </Fragment>
            ),
            [],
          )}
      </Box>
      {rightItems !== undefined && <Box>{rightItems}</Box>}
    </Paper>
  );
}

export { periodToBreadcrumbString };
export default PageTitle;
