import type { ChangeEventHandler } from 'react';

import type { SxProps } from '@mui/material/styles';

import type { ErrorsTextFieldProps } from 'shared/components/text-field/CondorTextField';
import CondorTextField from 'shared/components/text-field/CondorTextField';

type Props = ErrorsTextFieldProps & {
  value?: string;
  size?: 'medium' | 'small';
  label?: string;
  required?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  disabled?: boolean;
  helperText?: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  fullWidth?: boolean;
  sx?: SxProps;
};

function DatePicker(props: Props) {
  return (
    <CondorTextField
      {...props}
      InputLabelProps={{ shrink: true }}
      type="date"
    />
  );
}

export default DatePicker;
