import type { SxProps } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetLatestPurchaseOrderSnapshotRecordsByCompanyQuery } from 'shared/api/rtkq/companies';

import usePurchaseOrdersColumnDefs from '../hooks/usePurchaseOrdersColumnDefs';
import usePurchaseOrdersGridOptions from '../hooks/usePurchaseOrdersGridOptions';
import usePurchaseOrdersRows from '../hooks/usePurchaseOrdersRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function PurchaseOrdersGrid(props: Props) {
  const currentCompany = useSelector(selectCompany);
  const { currentData: purchaseOrders } =
    useGetLatestPurchaseOrderSnapshotRecordsByCompanyQuery(
      currentCompany.trace_id,
    );

  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useGridColDefs(usePurchaseOrdersColumnDefs);
  const rowData = usePurchaseOrdersRows(purchaseOrders, true);
  const gridOptions = useGridOptions(usePurchaseOrdersGridOptions);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  PurchaseOrdersGrid,
  PeriodGridBlobType.PO_LISTING,
);
