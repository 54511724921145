import type {
  AdministrativeOrProcedureCostResponse,
  AdministrativeOrProcedureCostUpsertRequest,
} from 'shared/lib/types';

import constructApi from './constructApi';
import {
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_INVESTIGATOR_FEES_RECON,
} from './contracts';
import { SITE_COST_MATRIX } from './periods';
import { LAB_COST_MATRIX } from './trials';

const apiEndpointFactory = constructApi<AdministrativeOrProcedureCostResponse>(
  'administrativeorprocedurecosts',
).withTags('ADMINISTRATIVE_OR_PROCEDURE_COSTS', [
  SITE_COST_MATRIX,
  LAB_COST_MATRIX,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  CONTRACT_INVESTIGATOR_FEES,
]);
const api = apiEndpointFactory.inject(() => ({
  deleteSafeAdministrativeOrProcedureCost:
    apiEndpointFactory.create<AdministrativeOrProcedureCostUpsertRequest>(
      [
        SITE_COST_MATRIX,
        LAB_COST_MATRIX,
        CONTRACT_INVESTIGATOR_FEES_RECON,
        CONTRACT_INVESTIGATOR_FEES,
      ],
      'delete_safe',
    ),
  upsertAdministrativeOrProcedureCost:
    apiEndpointFactory.create<AdministrativeOrProcedureCostUpsertRequest>(
      [
        SITE_COST_MATRIX,
        LAB_COST_MATRIX,
        CONTRACT_INVESTIGATOR_FEES_RECON,
        CONTRACT_INVESTIGATOR_FEES,
      ],
      'upsert',
    ),
}));

export const {
  useUpsertAdministrativeOrProcedureCostMutation,
  useDeleteSafeAdministrativeOrProcedureCostMutation,
} = api;
