import type { PatientCohortResponse } from 'shared/lib/types';

import {
  PATIENT_ACTIVITY_GRID,
  PATIENT_COHORTS,
  PATIENT_JOURNEY_GRID,
} from './apiTags';
import constructApi from './constructApi';

const apiEndpointFactory = constructApi<PatientCohortResponse>(
  'patientcohorts',
).withTags(PATIENT_COHORTS, [PATIENT_ACTIVITY_GRID, PATIENT_JOURNEY_GRID]);
const api = apiEndpointFactory.inject(() => ({
  getPatientCohortsByTrial: apiEndpointFactory.getBy('trial', 'created_at', [
    PATIENT_ACTIVITY_GRID,
    PATIENT_JOURNEY_GRID,
  ]),
  deletePatientCohort: apiEndpointFactory.delete([PATIENT_ACTIVITY_GRID]),
  createPatientCohort: apiEndpointFactory.create([PATIENT_ACTIVITY_GRID]),
  updatePatientCohort: apiEndpointFactory.update([PATIENT_ACTIVITY_GRID]),
}));

export const {
  useGetPatientCohortsByTrialQuery,
  useLazyGetPatientCohortsByTrialQuery,
  useDeletePatientCohortMutation,
  useCreatePatientCohortMutation,
  useUpdatePatientCohortMutation,
} = api;
