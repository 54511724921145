import { useState } from 'react';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import type {
  PatientAssessment,
  PatientAssessmentError,
  PatientAssessmentErrors,
  PatientCohort,
  PatientCohortError,
  PatientCohortErrors,
  PatientCohortRequest,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useUpdatePatientAssessmentMutation } from 'shared/api/rtkq/patientassessments';
import { useUpdatePatientCohortMutation } from 'shared/api/rtkq/patientcohorts';
import { useUpdatePatientJourneysMutation } from 'shared/api/rtkq/trials';

const useSaveEdcMappings = (): [
  (patientCohorts: PatientCohort[]) => Promise<{
    newPatientCohorts: PatientCohort[];
    cohortErrors: PatientCohortErrors;
  }>,
  { isLoading: boolean },
] => {
  const trial = useSelector(selectTrial);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updatePatientCohortRequest] = useUpdatePatientCohortMutation();
  const [updatePatientAssessmentRequest] = useUpdatePatientAssessmentMutation();
  const [updatePatientJourneys] = useUpdatePatientJourneysMutation();

  const savePatientAssessmentEdcMapping = async (
    patientAssessment: PatientAssessment,
  ) => {
    if (!patientAssessment.traceId) {
      return;
    }

    const mappingData = {
      trace_id: patientAssessment.traceId,
      name: patientAssessment.name,
      pizza_name: patientAssessment.mappedName ?? '',
      patient_cohort: patientAssessment.patientCohort,
      is_screen: patientAssessment.isScreen,
      is_enroll: patientAssessment.isEnroll,
      is_screenfail: patientAssessment.isScreenfail,
      is_dropped_completed: patientAssessment.isDroppedCompleted,
      order_index: patientAssessment.orderIndex,
      day_of_protocol: patientAssessment.dayOfProtocol,
      timeline_portion: patientAssessment.timelinePortion,
    };

    let apiError: PatientAssessmentError | undefined;
    let traceId = '';
    try {
      const data = await updatePatientAssessmentRequest(mappingData).unwrap();
      traceId = data.trace_id;
    } catch (error: unknown) {
      apiError = (error as FetchBaseQueryError).data as PatientAssessmentError;
    }

    return { traceId, apiError };
  };

  const saveCohortEdcMapping = async (patientCohort: PatientCohort) => {
    if (!patientCohort.traceId) {
      return;
    }

    const mappingData: PatientCohortRequest = {
      trace_id: patientCohort.traceId,
      trial: patientCohort.trialId,
      name: patientCohort.name,
      pizza_name: patientCohort.mappedName ?? '',
      order_index: patientCohort.orderIndex,
    };

    let apiError: PatientCohortError | undefined;
    let traceId = '';
    try {
      const data = await updatePatientCohortRequest({
        trace_id: patientCohort.traceId,
        ...mappingData,
      }).unwrap();
      traceId = data.trace_id;
    } catch (error: unknown) {
      apiError = (error as FetchBaseQueryError).data as PatientCohortError;
    }

    return { traceId, apiError };
  };

  const saveEdcMappings = async (patientCohorts: PatientCohort[]) => {
    const newPatientCohorts = [...patientCohorts];
    const cohortErrors: PatientCohortErrors = {};

    setIsLoading(true);

    for (const patientCohort of patientCohorts) {
      const cohortRes = await saveCohortEdcMapping(patientCohort); // eslint-disable-line no-await-in-loop -- we don't want to run these in parallel
      if (cohortRes?.apiError) {
        cohortErrors[patientCohort.traceId!] = cohortRes.apiError;
      }

      const visitErrorsForCohort: PatientAssessmentErrors = {};
      for (const visit of patientCohort.patientAssessments) {
        const res = await savePatientAssessmentEdcMapping(visit); // eslint-disable-line no-await-in-loop -- we don't want to run these in parallel
        if (res?.apiError) {
          visitErrorsForCohort[visit.traceId!] = res.apiError;
          cohortErrors[patientCohort.traceId!] = {
            ...cohortErrors[patientCohort.traceId!],
            patientAssessments: visitErrorsForCohort,
          };
        }
      }
    }

    await updatePatientJourneys({ trace_id: trial.trace_id }).unwrap();

    setIsLoading(false);

    return { newPatientCohorts, cohortErrors };
  };

  return [saveEdcMappings, { isLoading }];
};

export default useSaveEdcMappings;
