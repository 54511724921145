import { type ReactElement, useRef } from 'react';

import type { SxProps } from '@mui/material/styles';

import type {
  AgGridRef,
  UserDisplayOptions,
} from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import type { OccExpenseSummaryRow } from 'shared/lib/types';
import { PeriodGridBlobType } from 'shared/lib/types';

import useOccExpenseSummaryColDefs from '../hooks/useOccExpenseSummaryColDefs';
import useOccExpenseSummaryGridOptions from '../hooks/useOccExpenseSummaryGridOptions';
import useOccExpenseSummaryRows from '../hooks/useOccExpenseSummaryRows';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

function OccExpenseSummaryGrid(props: Props): ReactElement {
  const gridRef = useRef<AgGridRef<OccExpenseSummaryRow>>(null);
  const { overlayNoRowsTemplate, sx, userDisplayOptions } = props;

  const columnDefs = useGridColDefs(useOccExpenseSummaryColDefs);
  const { rows } = useOccExpenseSummaryRows();
  const gridOptions = useGridOptions(useOccExpenseSummaryGridOptions);

  return (
    <CondorAgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rows}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  OccExpenseSummaryGrid,
  PeriodGridBlobType.OCC_SUMMARY,
);
