import CroContractVersionDetail from 'accruals/pages/clinical-expenses/cro/CroContractVersionDetail';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

function CroContractVersionPage() {
  return (
    <PageContentLoadingContainer>
      <CroContractVersionDetail showBackButton />
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(CroContractVersionPage);
