import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';

import CondorTextField from 'shared/components/text-field/CondorTextField';

import type { PatientAssessmentEditor } from 'shared/lib/types';
import {
  changeVisitName,
  selectVisitError,
} from 'shared/state/slices/visitSchedulesSlice';

type Props = {
  visit: PatientAssessmentEditor;
};

function VisitInput(props: Props) {
  const { visit } = props;
  const dispatch = useDispatch();
  const visitError = useSelector(
    selectVisitError(visit.traceId!, visit.patientCohort),
  );
  const [value, setValue] = useState<string | undefined>(visit.name);

  useEffect(() => {
    setValue(visit.name);
  }, [visit.name]);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((name: string) => {
        dispatch(changeVisitName({ visit, name }));
      }, 300),
    [],
  );

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    debouncedChangeHandler(event.target.value);
  }

  return (
    <CondorTextField
      errors={visitError?.name}
      placeholder="Visit name"
      size="small"
      sx={{ width: '256px' }}
      value={value}
      onChange={onChange}
    />
  );
}

export default VisitInput;
