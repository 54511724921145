import { useSelector } from 'react-redux';

import { selectTrial } from 'shared/state/slices/trialSlice';

import BackToCompany from './BackToCompany';

type Props = {
  collapsed?: boolean;
};

function TrialNameAndBackButton(props: Props) {
  const { collapsed } = props;
  const trial = useSelector(selectTrial);

  return <BackToCompany collapsed={collapsed} label={trial.study_id} />;
}

export default TrialNameAndBackButton;
