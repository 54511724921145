import type { CostForecastData } from 'forecasting/components/graphing/cost-forecast/types';

export const cost1: CostForecastData = {
  contracted: { value: 27_858_063 },
  forecasted: { value: 12_603_962 },
  invoiced: { value: 13_882_343 },
  ltdExpensed: { value: 12_000_000 },
};

export const cost2: CostForecastData = {
  contracted: { value: 27_858_063 },
  forecasted: { value: 12_603_962 },
  invoiced: { value: 12_882_343 },
  ltdExpensed: { value: 16_000_000 },
};

export const cost3: CostForecastData = {
  contracted: { value: 27_858_063 },
  forecasted: { value: 29_603_962 },
  invoiced: { value: 12_882_343 },
  ltdExpensed: { value: 16_000_000 },
};

export const cost4: CostForecastData = {
  contracted: { value: 27_858_063 },
  forecasted: { value: 29_603_962 },
  invoiced: { value: 27_858_063 },
  ltdExpensed: { value: 27_858_063 },
};

export const cost5: CostForecastData = {
  contracted: { value: 27_858_063 },
  forecasted: { value: 29_603_962 },
  invoiced: { value: 0 },
  ltdExpensed: { value: 0 },
};
