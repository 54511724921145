import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import NotAuthorized from 'shared/components/not-authorized/NotAuthorized';
import Roller from 'shared/ui/roller/Roller';

import {
  missingPeriod,
  selectPeriod,
  selectPeriodInitialized,
  useInitializePeriod,
} from 'accruals/state/slices/periodSlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetPeriodsByTrialQuery } from 'shared/api/rtkq/periods';

function withPeriodRequired<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
) {
  return function PeriodRequiredWrapper(props: T): ReactNode {
    const trial = useSelector(selectTrial);
    const period = useSelector(selectPeriod);
    const initialized = useSelector(selectPeriodInitialized);
    const initPeriod = useInitializePeriod();

    const {
      currentData: periods,
      isUninitialized,
      isLoading,
      isFetching,
    } = useGetPeriodsByTrialQuery(trial.trace_id);
    const waitForApi = isUninitialized || isLoading || isFetching;
    const numPeriods = periods?.length ?? 0;

    // only call initialize period once
    useEffect(() => {
      if (waitForApi || initialized) {
        return;
      }

      initPeriod(periods);
    }, [periods, waitForApi, initPeriod, initialized]);

    if (!waitForApi && (period === missingPeriod || numPeriods === 0)) {
      return <NotAuthorized />;
    }

    return waitForApi ? <Roller /> : <LiveDataComponent {...props} />;
  };
}

export default withPeriodRequired;
