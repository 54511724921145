import { useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectPeriod,
  useChangePeriod,
} from 'accruals/state/slices/periodSlice';
import * as routes from 'routes';
import { formatShortMonthYear } from 'shared/helpers/helpers';
import type { PeriodResponse } from 'shared/lib/types';
import { MENU_WIDTH } from 'shared/lib/sidebar/SidebarContainer';

import PeriodStatusChip from './PeriodStatusChip';

type PeriodListProps = {
  collapsed?: boolean;
  periods?: PeriodResponse[];
  width?: number;
  onClickPeriod?: () => void;
};

function PeriodList(props: PeriodListProps) {
  const { collapsed = false, periods, onClickPeriod, width } = props;
  const period = useSelector(selectPeriod);
  const [openYears, setOpenYears] = useState<string[]>([]);
  const [finalWidth, setFinalWidth] = useState(0);
  const navigate = useNavigate();
  const changePeriod = useChangePeriod();

  // fixes "flashing" of the list during close animation, being this
  // is the size of the anchor and the anchor is gone when closed
  useEffect(() => {
    if (collapsed) {
      setFinalWidth(MENU_WIDTH);
    } else if (width !== undefined && width > 0) {
      setFinalWidth(width);
    }
  }, [width, collapsed]);

  useEffect(() => {
    setOpenYears([format(parseISO(period.end_date), 'yyyy')]);
  }, [period]);

  const periodGroupings = groupBy(periods, (per) =>
    format(parseISO(per.end_date), 'yyyy'),
  );
  const periodsByYear = Object.entries(periodGroupings).sort(
    ([yearA], [yearB]) => Number(yearB) - Number(yearA),
  );
  const uniquePeriodsByYear = periodsByYear.map(
    ([year, allPeriodsByYear]) =>
      [
        year,
        orderBy(uniqBy(allPeriodsByYear, 'end_date'), ['end_date'], ['desc']),
      ] as const,
  );

  const handleChange = (year: string) => () => {
    setOpenYears((currentYears) => {
      if (currentYears.includes(year)) {
        return currentYears.filter((yearToCheck) => yearToCheck !== year);
      }

      return [...currentYears, year];
    });
  };

  const handlePeriodClick = (per: PeriodResponse) => () => {
    changePeriod(per);
    // redirect to home page as there could be deep linking that won't work
    navigate(routes.getHomePage());
    onClickPeriod?.();
  };

  return (
    <>
      {uniquePeriodsByYear.map(([year, yearPeriods]) => (
        <Accordion
          key={year}
          elevation={0}
          expanded={openYears.includes(year)}
          sx={{
            width: finalWidth,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            '&:not(:last-child)': { borderBottom: 0 },
            '&:before': { display: 'none' },
          }}
          disableGutters
          square
          onChange={handleChange(year)}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            sx={{
              height: 40,
              [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
                {
                  transform: 'rotate(90deg)',
                },
            }}
          >
            <Typography variant="body2">{year}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {yearPeriods.map((per) => {
              const activePeriod = period.trace_id === per.trace_id;
              return (
                <Box
                  key={per.end_date}
                  sx={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    px: 2,
                    py: 1,
                    '&:hover': { backgroundColor: 'rgba(75,100,218,0.1)' },
                    ...(activePeriod && {
                      backgroundColor: 'rgba(75,100,218,0.1)',
                      '&:hover': { backgroundColor: 'rgba(75,100,218,0.2)' },
                    }),
                  }}
                  onClick={handlePeriodClick(per)}
                >
                  <Typography
                    component="div"
                    variant="body1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexFlow: 'row',
                    }}
                  >
                    <Box sx={{ height: 24, width: 24, mr: 1 }}>
                      {activePeriod ? <CheckIcon /> : null}
                    </Box>
                    {formatShortMonthYear(per.end_date)}
                  </Typography>
                  <PeriodStatusChip isClosed={per.is_closed} />
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default PeriodList;
