import type { InvoiceRecordResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'invoicerecords';

const apiEndpointFactory =
  constructApi<InvoiceRecordResponse>(PATH).withTags('INVOICE_RECORDS');
export const api = apiEndpointFactory.inject(() => ({
  getInvoiceRecordsBySnapshot: apiEndpointFactory.getBy(
    'snapshot',
    'snapshot_row',
  ),
}));

export const { useGetInvoiceRecordsBySnapshotQuery } = api;
