import { useState } from 'react';

import { useSelector } from 'react-redux';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import ColumnMapperStep from 'shared/lib/column-mapper/ColumnMapperStep';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type {
  FileUploadType,
  TraceId,
  UnmappedFileResponse,
  UploadedFile,
} from 'shared/lib/types';
import { UnmappedFileEntityType } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import UploadUnmappedCsvStep from 'shared/wizards/upload-unmapped-csv-step/UploadUnmappedCsvStep';
import useOnCloseLogic from 'shared/wizards/hooks/useOnCloseLogic';

import {
  useCreateEdcSnapshotFromCsvMutation,
  useDeleteEdcSnapshotMutation,
  usePostVisitsSaveRecordsMutation,
} from 'shared/api/rtkq/edcsnapshots';

import columns from './columns';
import EdcConfirmationStep from './steps/EdcConfirmationStep';
import EdcReviewStep from './steps/EdcReviewStep';

type EdcUploadWizardProps = {
  fileUploadType: FileUploadType;
  onClose: () => void;
  onComplete?: (message: string) => void;
};

function EdcUploadWizard(props: EdcUploadWizardProps) {
  const { onClose: onCloseProp, onComplete, fileUploadType } = props;
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [snapshotId, setSnapshotId] = useState<TraceId>();

  const currentPeriodVersion = useSelector(selectPeriodVersion);

  const [deleteSnapshot, { isLoading: _deleteIsLoading }] =
    useDeleteEdcSnapshotMutation();
  const [setFfMappingId, handleCloseBtnClick] = useOnCloseLogic();

  function onClose(isLastStepSaveClick?: boolean) {
    handleCloseBtnClick(
      uploadedFiles,
      deleteSnapshot,
      setUploadedFiles,
      setSnapshotId,
      onCloseProp,
      isLastStepSaveClick,
      snapshotId,
    );
  }

  function saveUrl(unmappedFiles: UnmappedFileResponse[]) {
    setUploadedFiles(
      unmappedFiles.map((file) => ({
        csvBlobUrl: file.file,
        unmappedFileUrl: file.url,
        traceId: file.trace_id,
      })),
    );
  }

  function getHeader() {
    switch (fileUploadType) {
      case 'UVS':
        return 'Upload your EDC unscheduled visits report.';
      case 'SFS':
        return 'Upload your EDC screen fail report.';
      default:
        return 'Upload your EDC visits report.';
    }
  }

  const mapperHeader = 'Assign these headers to your EDC visits grid.';

  return (
    <FullWidthDialog open>
      <Wizard
        devTitle="BRAN"
        startIndex={0}
        stepNames={['Map Columns', 'Validate', 'Review']}
        title="EDC Upload"
        onClose={onClose}
      >
        <UploadUnmappedCsvStep
          accept=".csv"
          entityClass={UnmappedFileEntityType.EdcSnapshot}
          fileTypeWithGrammaticalArticle="an EDC report"
          fileUploadType={fileUploadType}
          header={getHeader()}
          periodVersion={currentPeriodVersion}
          setSnapshotId={setSnapshotId}
          titleOverride="Upload EDC"
          onSave={saveUrl}
        />
        <ColumnMapperStep
          columns={columns}
          setFfMappingId={setFfMappingId}
          setSnapshotId={setSnapshotId}
          stepTitleText={mapperHeader}
          uploadedFiles={uploadedFiles}
          useCreateSnapshotEndpoint={useCreateEdcSnapshotFromCsvMutation}
          usePostRecordsSaveEndpoint={usePostVisitsSaveRecordsMutation}
          extraCreateSnapshotArgs={{
            snapshot: snapshotId ?? '',
            edc_visit_type: fileUploadType,
          }}
        />
        <EdcConfirmationStep snapshotId={snapshotId} />
        <EdcReviewStep
          snapshotId={snapshotId}
          onComplete={() =>
            onComplete
              ? onComplete('EDC Report uploaded successfully')
              : onCloseProp()
          }
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default EdcUploadWizard;
