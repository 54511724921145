import { useMemo, useRef, useState } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query/react';
import cloneDeep from 'lodash/cloneDeep';
import { useParams } from 'react-router-dom';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import useReconLineMatchingRowData from 'accruals/pages/clinical-expenses/shared/hooks/useReconLineMatchingRowData';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type { CroCostCategory } from 'shared/lib/types';

import { useGetCurrentContractVersionQuery } from 'shared/api/rtkq/contractcontainers';
import {
  useGetContractReconLineMatchingQuery,
  useUpdateContractReconLineMatchingMutation,
} from 'shared/api/rtkq/contracts';

import useCroReconLineMatchingColumnDefs from '../hooks/useCroReconLineMatchingColumnDefs';

type Props = { costCategory: CroCostCategory; onClose: () => void };

function CroReconLineMatchingDialog(props: Props) {
  const { costCategory, onClose } = props;
  const { contractContainerId } = useParams();
  const { period } = useContractAndPeriodWithVersions();
  const { data: currentContract } = useGetCurrentContractVersionQuery(
    contractContainerId && period
      ? { trace_id: contractContainerId, otherParameter: period.trace_id }
      : skipToken,
  );
  const contractId = currentContract?.trace_id;

  const {
    currentData: lineMatchingData,
    isFetching: contractReconLineMatchingFetching,
  } = useGetContractReconLineMatchingQuery(
    contractId !== undefined && period !== undefined
      ? {
          trace_id: contractId,
          secondParameter: period.trace_id,
          thirdParameter: costCategory,
        }
      : skipToken,
  );
  const [
    updateContractReconLineMatchingRequest,
    { isLoading: updateContractReconLineMatchingLoading },
  ] = useUpdateContractReconLineMatchingMutation();
  const lineMatchingChanges = useRef<
    Array<{ type: string; rowIndex: number; newGroupIndex: number }>
  >([]);
  // TODO: This was "temporarily" overridden to "unblock the product team" six months ago. It is currently doing nothing. Convert the existing validation logic to yellow warning highlights on the missing cells instead of blocking the save button.
  const [valid, setValid] = useState(true);

  async function onSave() {
    const request = cloneDeep(lineMatchingData!);
    for (const change of lineMatchingChanges.current) {
      switch (change.type) {
        case 'cur':
          request.currentBudgetRows[change.rowIndex].newGroupIndex =
            change.newGroupIndex;
          break;
        case 'aip':
          request.aipBudgetRows[change.rowIndex].newGroupIndex =
            change.newGroupIndex;
          break;
        case 'rep':
          request.reportedActivityRows[change.rowIndex].newGroupIndex =
            change.newGroupIndex;
          break;
      }
    }
    await updateContractReconLineMatchingRequest({
      object: { trace_id: contractId ?? '', ...request },
      secondParameter: period?.trace_id ?? '',
      thirdParameter: costCategory,
    }).unwrap();
    onClose();
  }

  const rowData = useReconLineMatchingRowData(lineMatchingData);
  const columnDefs = useCroReconLineMatchingColumnDefs();
  const gridOptions = useMemo(() => {
    const onCellValueChanged = (event: CellValueChangedEvent) => {
      const type = event.column.getColId().split('_')[0];

      const index = lineMatchingChanges.current.findIndex(
        (item) => item.rowIndex === event.rowIndex && item.type === type,
      );
      if (index === -1) {
        lineMatchingChanges.current.push({
          rowIndex: event.rowIndex ?? -1,
          type,
          newGroupIndex: +event.newValue,
        });
      } else {
        lineMatchingChanges.current[index].newGroupIndex = +event.newValue;
      }

      // only enable Save button, if all lines are matched:
      if (
        lineMatchingData !== undefined &&
        lineMatchingChanges.current.length ===
          lineMatchingData.currentBudgetRows.length +
            lineMatchingData.aipBudgetRows.length +
            lineMatchingData.reportedActivityRows.length
      ) {
        setValid(true);
      }
    };

    return {
      enableCellTextSelection: true,
      groupDisplayType: 'singleColumn' as const,
      onCellValueChanged,
    };
  }, [lineMatchingData]);

  return (
    <FullWidthDialog open>
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="subtitle1">Line matching</Typography>
      </DialogTitleWithClose>
      <DialogContent>
        <FlexGridContainer>
          <Typography color="primary.main" variant="h6">
            Match the activities to tell us how to compare the new or removed
            line items.
          </Typography>
          <Typography variant="body1">
            The current contract, amendment-in-progress, and CRO-reported line
            items should be matched to get the most accurate expense comparison.
            We&apos;ve highlighted the differences between the activities. Use
            the line matching columns if you need to consolidate line items for
            comparison. For example, if you want to compare 2 line items of the
            amendment-in-progress to 1 line item of the current contract, enter
            the same number for all 3 lines
          </Typography>
          <CondorAgGrid
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            rowData={rowData}
            sx={useMemo(() => ({ mt: 2, height: '100%' }), [])}
          />
        </FlexGridContainer>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          loading={updateContractReconLineMatchingLoading}
          testId="save"
          variant="contained"
          disabled={
            contractReconLineMatchingFetching ||
            updateContractReconLineMatchingLoading ||
            !valid
          }
          onClick={() => void onSave()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </FullWidthDialog>
  );
}

export default CroReconLineMatchingDialog;
