import type { VendorContactResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'vendorcontacts';

const apiEndpointFactory =
  constructApi<VendorContactResponse>(PATH).withTags('VENDOR_CONTACTS');
export const api = apiEndpointFactory.inject(() => ({
  createVendorContact: apiEndpointFactory.create(),
  getVendorContact: apiEndpointFactory.get(),
  getVendorContactsByContractContainer:
    apiEndpointFactory.getBy('contract_container'),
  updateVendorContact: apiEndpointFactory.update(),
  deleteVendorContact: apiEndpointFactory.delete(),
}));

export const {
  useCreateVendorContactMutation,
  useUpdateVendorContactMutation,
  useDeleteVendorContactMutation,
} = api;
