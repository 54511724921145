import { useState } from 'react';

import { useSelector } from 'react-redux';

import type {
  ContractContainerRequest,
  ContractContainerResponse,
  VendorType,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import {
  useCreateContractContainerMutation,
  useUpdateContractContainerMutation,
} from 'shared/api/rtkq/contractcontainers';
import {
  useCreateVendorContactMutation,
  useDeleteVendorContactMutation,
  useUpdateVendorContactMutation,
} from 'shared/api/rtkq/vendorcontacts';

import type { VendorContactParams } from './ContractContainerForm';
import ContractContainerForm from './ContractContainerForm';

type Props = {
  contractContainer?: ContractContainerResponse;
  vendorType: VendorType;
};

type AllFormData = Partial<ContractContainerRequest> & {
  contacts: VendorContactParams[];
};

function ContractContainerDialog(props: Props) {
  const { vendorType, contractContainer } = props;
  const trial = useSelector(selectTrial);
  const [createContractContainer, { isLoading: isCreateLoading }] =
    useCreateContractContainerMutation();
  const [updateContractContainer, { isLoading: isUpdateLoading }] =
    useUpdateContractContainerMutation();
  const [createVendorContact] = useCreateVendorContactMutation();
  const [updateVendorContact] = useUpdateVendorContactMutation();
  const [deleteVendorContact] = useDeleteVendorContactMutation();
  const [valid, setValid] = useState(false);
  const [data, setData] = useState<AllFormData | null>(null);
  const [contactsToDelete, setContactsToDelete] = useState<string[]>([]);

  function onUpdateValidData(newData: AllFormData) {
    setData(newData);
  }

  async function onSave() {
    if (!data) {
      return;
    }

    const { trace_id, vendor, po_numbers, contract_id, contacts, currency } =
      data;

    if (trace_id) {
      await updateContractContainer({
        trace_id,
        vendor,
        po_numbers,
        contract_id,
        currency,
      });
      await createVendorContacts(trace_id, contacts);
    } else {
      const res = await createContractContainer({
        trial: trial.trace_id,
        vendor,
        po_numbers,
        contract_id,
        currency,
      }).unwrap();
      await createVendorContacts(res.trace_id, contacts);
    }
    for (const contactId of contactsToDelete) {
      void deleteVendorContact(contactId);
    }
  }

  async function createVendorContacts(
    contractContainerId: string,
    contacts: VendorContactParams[],
  ) {
    if (contractContainerId) {
      for await (const contact of contacts) {
        if (contact.trace_id) {
          await updateVendorContact({
            trace_id: contact.trace_id,
            name: contact.contact,
            email: contact.email,
          });
        } else {
          await createVendorContact({
            contract_container: contractContainerId,
            name: contact.contact,
            email: contact.email,
          });
        }
      }
    }
  }
  return (
    <WizardStep
      description="Fields with (*) are required."
      disableNextButton={!valid}
      header={`Fill in the ${vendorType} information`}
      isLoading={isCreateLoading || isUpdateLoading}
      onNextAsync={onSave}
    >
      <ContractContainerForm
        contractContainer={contractContainer}
        setContactsToDelete={setContactsToDelete}
        trialId={trial.trace_id}
        vendorType={vendorType}
        onChangeData={onUpdateValidData}
        onChangeValidity={setValid}
      />
    </WizardStep>
  );
}

export default ContractContainerDialog;
