import type { EdcSnapshotResponse } from 'shared/lib/types';

import {
  ACCRUALS_GRID,
  BS_FLUX_GRID,
  CRO_EXPENSE_SUMMARY,
  JOURNAL_ENTRY_GRID,
  OCC_EXPENSE_SUMMARY,
  PATIENT_ACTIVITY_GRID,
  PATIENT_JOURNEY_GRID,
  PERIOD_OCC_EXPENSES,
} from './apiTags';
import constructApi from './constructApi';
import { CONTRACT_INVESTIGATOR_FEES_RECON, CONTRACT_RECON } from './contracts';

const PATH = 'edcsnapshots';
export const EDC_SNAPSHOT_TAG = 'EDC_SNAPSHOTS';

const apiEndpointFactory = constructApi<EdcSnapshotResponse>(PATH).withTags(
  EDC_SNAPSHOT_TAG,
  [
    CONTRACT_RECON,
    CONTRACT_INVESTIGATOR_FEES_RECON,
    PERIOD_OCC_EXPENSES,
    ACCRUALS_GRID,
    CRO_EXPENSE_SUMMARY,
    OCC_EXPENSE_SUMMARY,
    JOURNAL_ENTRY_GRID,
    BS_FLUX_GRID,
  ],
);
const api = apiEndpointFactory.inject(() => ({
  createEdcSnapshotFromCsv: apiEndpointFactory.create([], 'new_from_csv'),
  updateEdcSnapshot: apiEndpointFactory.update(),
  deleteEdcSnapshot: apiEndpointFactory.delete(),
  postVisitsSaveRecords: apiEndpointFactory.create(
    [
      CONTRACT_RECON,
      CONTRACT_INVESTIGATOR_FEES_RECON,
      PERIOD_OCC_EXPENSES,
      ACCRUALS_GRID,
      JOURNAL_ENTRY_GRID,
      BS_FLUX_GRID,
      CRO_EXPENSE_SUMMARY,
      OCC_EXPENSE_SUMMARY,
      PATIENT_ACTIVITY_GRID,
      PATIENT_JOURNEY_GRID,
    ],
    'post_save_records',
  ),
}));

export const {
  useDeleteEdcSnapshotMutation,
  useCreateEdcSnapshotFromCsvMutation,
  usePostVisitsSaveRecordsMutation,
} = api;
