import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

import Icon from './svg/MenuUp.svg?react';

function MenuUp(props: SvgIconProps) {
  return <SvgIcon component={Icon} inheritViewBox {...props} />;
}

export default MenuUp;
