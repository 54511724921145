import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TrialsDropdown from 'shared/components/trial-selector/TrialsDropdown';

// eslint-disable-next-line import/no-restricted-paths -- this is shared beteween accruals and forecasting
import useClearAccruals from 'accruals/state/hooks/useClearAccruals';
import { getTrialDashboard } from 'routes';
import type { TrialResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import { useChangeTrial } from 'shared/state/slices/trialSlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

type Props = {
  anchorEl: HTMLDivElement | null;
  handleCloseSelector: () => void;
  open: boolean;
  onClose: () => void;
};

function TrialsPopover(props: Props) {
  const { open, onClose, anchorEl, handleCloseSelector } = props;
  const navigate = useNavigate();
  const company = useSelector(selectCompany);
  const { currentData: trialsData } = useGetTrialsByCompanyQuery(
    company.trace_id,
  );

  const changeTrial = useChangeTrial();
  const clearAccruals = useClearAccruals();

  function getTrials() {
    return trialsData ?? [];
  }

  const onClickTrial = (trial: TrialResponse) => {
    // usually the Accruals container takes care of this, but we're inside the accruals container, so this has to do it manually
    //
    // clearForecasting is not needed as this currently always redirects to the trial dashboard. if we change this to stay in
    // the forecasting pages, we'll need to add clearForecasting() here like we do with clearAccruals()
    clearAccruals();
    changeTrial(trial);
    navigate(getTrialDashboard());
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      marginThreshold={0}
      open={anchorEl !== null}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      anchorOrigin={{
        vertical: open ? 36 : 0,
        horizontal: open ? 'left' : 'right',
      }}
      onClose={handleCloseSelector}
    >
      <Box sx={{ width: 300 }}>
        {trialsData && (
          <TrialsDropdown trials={getTrials()} onClickTrial={onClickTrial} />
        )}
      </Box>
    </Popover>
  );
}

export default TrialsPopover;
