import { useMemo } from 'react';

import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import type { ContractVersionResponse } from 'shared/lib/types';

import { useGetAllContractsByTrialQuery } from 'shared/api/rtkq/periods';

export default function useContracts(): {
  loading: boolean;
  contracts: ContractVersionResponse[] | undefined;
} {
  const { loading: forecastLoading, forecast } = useForecast();

  const {
    isLoading: loadingContracts,
    isFetching: fetchingContracts,
    isUninitialized: uninitializedContracts,
    currentData: allContractVersions,
  } = useGetAllContractsByTrialQuery({
    trace_id: forecast.start_period,
    otherParameter: forecast.trial,
  });

  return useMemo(
    () => ({
      loading:
        forecastLoading ||
        uninitializedContracts ||
        fetchingContracts ||
        loadingContracts,
      contracts: allContractVersions
        ?.filter((cv) => cv.status_for_period === 'CURRENT')
        .sort((cv1, cv2) => {
          const vendorTypeComparison = (cv1.vendor_type ?? '').localeCompare(
            cv2.vendor_type ?? '',
          );
          if (vendorTypeComparison !== 0) {
            return vendorTypeComparison;
          }
          return (cv1.vendor_name ?? '').localeCompare(cv2.vendor_name ?? '');
        }),
    }),
    [
      forecastLoading,
      fetchingContracts,
      loadingContracts,
      uninitializedContracts,
      allContractVersions,
    ],
  );
}
