/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

This slice should be used in conjunction with the PeriodVersionRequiredLayout component
that knows how to handle when period version is null / undefined, so we can assume
that period version is always defined inside that element, greatly simplifying the codebase.

If you use this slice outside of the PeriodVersionRequiredLayout context, it will NOT handle 
the "missing period version" case correctly and is likely a sign you are doing something wrong 
(or need to move where PeriodVersionRequiredLayout is being used in the routes).

If you have any questions, please ask in #engineering
*/

import { useCallback } from 'react';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import type { RootState } from 'shared/state/store';
import type { PeriodVersionResponse, TraceId } from 'shared/lib/types';
import { getPeriodVersionSafeKeys } from 'shared/state/slices/utils';

import baseApi from 'shared/api/baseApi';
import { getRegisteredTags } from 'shared/api/rtkq/constructApi';

type State = { periodVersion: PeriodVersionResponse; initialized: boolean };

export const missingPeriodVersion = null as unknown as PeriodVersionResponse;

const initialState: State = {
  periodVersion: missingPeriodVersion,
  initialized: false,
};

const makePeriodVersionKey = (periodTraceId: TraceId) =>
  `period_${periodTraceId}_period_version`;

const periodVersionSlice = createSlice({
  name: 'periodVersion',
  initialState,
  reducers: {
    // ideally this would have no side effects, but this is the best place to get access to current state.
    changePeriodVersion: (
      state,
      action: PayloadAction<PeriodVersionResponse>,
    ) => {
      const periodVersion = action.payload;

      // only remove the old period version if we are already initialized and are trying to clear it intentionally.
      // this will allow us to re-initialize the state (such as when period changes) without losing the current period version
      if (
        state.initialized &&
        periodVersion === missingPeriodVersion &&
        state.periodVersion !== missingPeriodVersion
      ) {
        const existingKey = makePeriodVersionKey(state.periodVersion.period);
        localStorage.removeItem(existingKey);
      }

      state.periodVersion = periodVersion;
    },
    changeInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
  },
});

export const selectPeriodVersion = (state: RootState) =>
  state.periodVersion.periodVersion;
export const selectPeriodVersionInitialized = (state: RootState) =>
  state.periodVersion.initialized;

const { changePeriodVersion, changeInitialized } = periodVersionSlice.actions;

export const periodVersionReducer = periodVersionSlice.reducer;

export function useChangePeriodVersion() {
  const dispatch = useDispatch();

  return useCallback(
    (periodVersion: PeriodVersionResponse | null, isInit = false) => {
      if (periodVersion === null) {
        dispatch(changePeriodVersion(missingPeriodVersion));
      } else {
        localStorage.setItem(
          makePeriodVersionKey(periodVersion.period),
          periodVersion.trace_id,
        );
        dispatch(changePeriodVersion(periodVersion));
      }

      // clear all existing RTKQ state to guarantee we're not using stale data, although we
      // don't clear "safe" keys that cannot change, so it reduces loading states
      if (!isInit) {
        const tagsToClear = getRegisteredTags().filter(
          (tag) => !getPeriodVersionSafeKeys().includes(tag),
        );
        dispatch(baseApi.util.invalidateTags(tagsToClear));
      }
    },
    [dispatch],
  );
}

export function useInitializePeriodVersion() {
  const change = useChangePeriodVersion();
  const dispatch = useDispatch();

  return useCallback(
    (periodVersions: PeriodVersionResponse[] | undefined) => {
      dispatch(changeInitialized(true));

      // if we have no returned period, ensure we update state in case what was present was for a company they don't have access to
      let periodVersion = missingPeriodVersion;
      if (periodVersions?.length) {
        const previousPeriodVersionId =
          localStorage.getItem(
            makePeriodVersionKey(periodVersions[0].period),
          ) ?? null;
        const previousPeriodVersion = periodVersions.find(
          (comp) => comp.trace_id === previousPeriodVersionId,
        );

        // If we didn't find a relevant selected trial in local storage, arbitrarily default to the latest open period, followed by any period, if none open
        periodVersion = previousPeriodVersion ?? periodVersions[0];
      }

      change(periodVersion, true);
    },
    [change, dispatch],
  );
}

export function useReinitializePeriodVersion() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(changeInitialized(false));
    dispatch(changePeriodVersion(missingPeriodVersion));
  }, [dispatch]);
}
