import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import Checkbox from 'shared/ui/checkbox/Checkbox';

import WizardStep from 'shared/wizards/steps/WizardStep';

import ColumnMapperChips from './ColumnMapperChips';
import ColumnMapperTable from './ColumnMapperTable';
import type {
  CsvErrors,
  MappedColumn,
  MappedCsvHeader,
  MappedData,
  MappedRow,
} from './types';
import ServerResponseErrorTooltip from './utils/ServerResponseErrorTooltip';
import { IGNORE_COLUMN } from './utils/constants';

function updateCsvHeaders(
  headers: MappedCsvHeader[],
  value: string,
  columnIndex: number,
): MappedCsvHeader[] {
  if (value !== IGNORE_COLUMN) {
    const previousColumnIndex = headers.findIndex(
      ({ destination }) => destination === value,
    );
    if (previousColumnIndex !== -1) {
      const { destination, position, ...column } = headers[previousColumnIndex];
      headers[previousColumnIndex] = column;
    }
  }

  const column = { ...headers[columnIndex] };
  column.destination = value;
  column.ignore = value === IGNORE_COLUMN;
  column.position = columnIndex;
  headers[columnIndex] = column;
  return headers;
}

type ColumnMapperDialogContentProps = {
  csvErrors: CsvErrors;
  initialColumns: MappedColumn[];
  initialCsvHeaders: MappedCsvHeader[];
  isFirstRowHeader: boolean;
  isLoading?: boolean;
  isNextButtonDisabled?: boolean;
  nextButtonText?: string;
  rows: MappedRow[];
  serverResponseError?: FetchBaseQueryError | null;
  setCsvErrors: (csvErrors: CsvErrors) => void;
  setIsFirstRowHeader: (isFirstRowHeader: boolean) => void;
  setIsNextButtonDisabled: (isNextButtonDisabled: boolean) => void;
  stepTitleText: string;
  onSaveMapping: (currentData: MappedData) => Promise<void>;
};

function ColumnMapperDialogContent(props: ColumnMapperDialogContentProps) {
  const {
    stepTitleText,
    rows,
    initialColumns,
    initialCsvHeaders,
    onSaveMapping,
    isFirstRowHeader,
    setIsFirstRowHeader,
    nextButtonText,
    csvErrors,
    isNextButtonDisabled = false,
    setCsvErrors,
    setIsNextButtonDisabled,
    isLoading,
    serverResponseError,
  } = props;
  const [csvHeaders, setCsvHeaders] =
    useState<MappedCsvHeader[]>(initialCsvHeaders);
  const [columns, setColumns] = useState<MappedColumn[]>(initialColumns);

  useEffect(() => {
    setIsFirstRowHeader(true);

    // Initialize the headers to be already mapped if they're perfect matches
    for (const column of columns) {
      const foundIndex = csvHeaders.findIndex((header) =>
        normalizeForMagicMapping(header.name).endsWith(
          normalizeForMagicMapping(column.label),
        ),
      );
      if (foundIndex !== -1) {
        updateCsvHeaders(csvHeaders, column.value, foundIndex);
      }
    }

    function normalizeForMagicMapping(value: string) {
      // docs.google.com/spreadsheets/d/1ccMRfBytpqNgpvdUX8MwUkE8kSOZdV4UK_7TX2mzDf4
      return value
        .toLowerCase()
        .replaceAll(/_raw|_std|_int|_c/gi, '')
        .replaceAll('dsst', 'screenfail')
        .replaceAll('lbcat', 'invoiceabledetail')
        .replaceAll('datapagename', 'invoiceabletype')
        .replaceAll('folder', 'vis')
        .replaceAll('event', 'vis')
        .replaceAll('visit', 'vis')
        .replaceAll('svst', 'vis')
        .replaceAll('date', 'dt')
        .replaceAll('dat', 'dt')
        .replaceAll('no.', '#')
        .replaceAll(/[\s/_.-]|name|id|label|visit actual|page/gi, '')
        .replaceAll('number', '#')
        .replaceAll('studyenvsite', 'site')
        .replaceAll('study', 'trial')
        .replaceAll('patient', 'subject')
        .replaceAll('primaryinvestigator', 'pi')
        .replaceAll('activated', 'activation')
        .replaceAll('deactivation', 'close')
        .replaceAll('termination', 'close')
        .replaceAll('closed', 'close')
        .replaceAll('siteselected', 'recruitment')
        .replaceAll('perunit', 'unit')
        .replaceAll('cost', 'value')
        .replaceAll('price', 'value')
        .replaceAll('totalcontract', 'contract')
        .replaceAll('activity', 'description')
        .replaceAll('department', 'description');
    }
  }, []);

  useEffect(() => {
    const assigned = csvHeaders
      .filter(({ ignore }) => !ignore)
      .map(({ destination }) => destination)
      .filter(Boolean);
    setColumns(
      columns.map((column) => ({
        ...column,
        mappedTo: assigned.includes(column.value) ? column.value : '',
      })),
    );
  }, [csvHeaders]);

  useEffect(() => {
    const requiredColumns = columns.filter(({ required }) => required);
    if (
      columns.filter(({ required, mappedTo }) => !!(required && mappedTo))
        .length === requiredColumns.length
    ) {
      setIsNextButtonDisabled(false);
    }
  }, [columns, isFirstRowHeader, setIsNextButtonDisabled]);

  function onChangeHeaderInFirstRow(
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    setIsFirstRowHeader(checked);
  }

  function onChange(value: string, columnIndex: number) {
    setCsvHeaders((prevHeaders) =>
      updateCsvHeaders([...prevHeaders], value, columnIndex),
    );
    setCsvErrors({});
  }

  async function onSave() {
    await onSaveMapping({ rows, csvHeaders });
  }

  const errorCount = Object.keys(csvErrors).reduce((acc, key) => {
    if (key === 'non_field_errors') {
      return acc;
    }

    return acc + Object.keys(csvErrors[key]).length;
  }, 0);

  return (
    <WizardStep
      disableNextButton={isNextButtonDisabled}
      header=""
      isLoading={isLoading}
      nextButtonTextOverride={nextButtonText ?? 'Save'}
      titleOverride="Column Mapping"
      customHeaderComponent={
        <Box
          sx={{
            overflowY: 'initial',
            paddingBottom: 0,
            paddingTop: '16px !important',
          }}
        >
          <ColumnMapperChips columns={columns} text={stepTitleText} />
          <Checkbox
            label="Row 1 of the original file is header row"
            defaultChecked
            onChange={onChangeHeaderInFirstRow}
          />
        </Box>
      }
      onNextAsync={onSave}
    >
      {!!errorCount && (
        <Box alignItems="center" display="flex" justifyContent="flex-end">
          <Typography align="right" color="error.main">
            <b>{errorCount}</b> flags
          </Typography>
          <ServerResponseErrorTooltip
            csvErrors={csvErrors}
            error={serverResponseError}
          />
        </Box>
      )}
      <ColumnMapperTable
        columns={columns}
        csvErrors={csvErrors}
        csvHeaders={csvHeaders}
        isHeaderInFirstRow={isFirstRowHeader}
        rows={rows}
        onChange={onChange}
      />
    </WizardStep>
  );
}

export default ColumnMapperDialogContent;
