import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import GlAccountCellRenderer from '../grids/GlAccountCellRenderer';

export default function useGlAccountColumnDefs(
  isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      { headerName: 'G/L account number', field: 'accountNumber' },
      { headerName: 'Account type', field: 'accountType' },
      { headerName: 'Description', field: 'description' },
      { headerName: 'Trial', field: 'trial' },
      {
        headerName: '',
        field: 'tools',
        flex: 1,
        ...(isOpenPeriod && { cellRenderer: GlAccountCellRenderer }),
      },
    ],
    [isOpenPeriod],
  );
}
