import type {
  BaseResponseFields,
  HistoricalValuesUpsert,
} from 'shared/lib/types';

import { HISTORICAL_EXPENSES } from './apiTags';
import constructApi from './constructApi';

const apiEndpointFactory = constructApi<BaseResponseFields>(
  'historicalbookedexpenses',
).withTags(HISTORICAL_EXPENSES);
const api = apiEndpointFactory.inject(() => ({
  upsertHistoricalExpenses: apiEndpointFactory.create<HistoricalValuesUpsert>(
    [],
    'upsert',
  ),
}));

export const { useUpsertHistoricalExpensesMutation } = api;
