import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getNumberCellConfig } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import {
  formatLowerShortMonthYear,
  getPeriodHeaderNames,
} from 'shared/helpers/helpers';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetTrialAssumptionsByTrialQuery } from 'shared/api/rtkq/trialassumptions';

export default function useTrialActivitySitesColumnDefs(
  _isOpenPeriod: boolean,
): CondorColDef[] {
  const trial = useSelector(selectTrial);
  const currentPeriod = useSelector(selectPeriod);
  const { currentData: trialAssumptions } = useGetTrialAssumptionsByTrialQuery(
    trial.trace_id,
  );

  return useMemo(() => {
    // there will only ever be 0 or 1, but our api helper doesn't know that
    const trialAssumption = trialAssumptions?.[0];

    const firstColumn = {
      headerName: 'Sites',
      field: 'type',
      width: 300,
      pinned: true,
    };

    if (trialAssumption === undefined) {
      return [firstColumn];
    }

    const periods = getPeriodHeaderNames(
      new Date(trialAssumption.start_up_start_date),
      new Date(currentPeriod.end_date),
      false,
      formatLowerShortMonthYear,
    );

    return [
      firstColumn,
      ...periods.map((period) => ({
        headerName: period.label,
        field: period.field,
        width: 100,
        ...getNumberCellConfig({ useEmDash: true }),
      })),
      {
        flex: 1,
        headerName: 'LTD',
        field: 'total',
        minWidth: 100,
        ...getNumberCellConfig({ useEmDash: true }),
        cellStyle: { fontWeight: 'bold' },
      },
    ];
  }, [trial, trialAssumptions]);
}
