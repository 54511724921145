import { Fragment, useMemo } from 'react';

import AxisBottom from '@visx/axis/lib/axis/AxisBottom';
import AxisLeft from '@visx/axis/lib/axis/AxisLeft';
import Grid from '@visx/grid/lib/grids/Grid';
import Group from '@visx/group/lib/Group';
import band from '@visx/scale/lib/scales/band';
import ordinal from '@visx/scale/lib/scales/ordinal';
import time from '@visx/scale/lib/scales/time';
import Bar from '@visx/shape/lib/shapes/Bar';
import Text from '@visx/text/lib/Text';

import { differenceInMonthsFractional } from 'shared/helpers/helpers';

import { colorsRange } from '../../../../shared/lib/graphing/theme';

const colors = { axes: '#767676', grid: '#E6E6E6' };

type XAccessor<TDatum> = (datum: TDatum) => { start: string; end: string };
type YAccessor<TDatum> = (datum: TDatum) => string;

type TimelineChartProps<TDatum> = {
  data: TDatum[];
  height: number;
  width: number;
  xAccessor: XAccessor<TDatum>;
  yAccessor: YAccessor<TDatum>;
};

function TimelineChart<TDatum>(props: TimelineChartProps<TDatum>) {
  const { width, height, data, xAccessor, yAccessor } = props;

  const colorScale = ordinal({
    domain: data.map(yAccessor),
    range: colorsRange,
  });

  function textLabel({ start, end }: { end: string; start: string }): string {
    const months = differenceInMonthsFractional(new Date(end), new Date(start));
    return `${months} months`;
  }

  const margin = { top: 0, right: 25, bottom: 30, left: 80 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // Calculate waterfall steps from provided data
  const steps = useMemo(
    () =>
      data.map((datum) => {
        const xValue = xAccessor(datum);
        const yValue = yAccessor(datum);

        return {
          x: xValue.end,
          y: yValue,
          start: xValue.start,
          end: xValue.end,
        };
      }),
    [data, xAccessor, yAccessor],
  );

  const verticalOffset = innerHeight / data.length / 2;

  // Build scales
  const padding = 0.2;
  const yScale = band({
    domain: steps.map((step) => step.y),
    padding,
    range: [0, innerHeight - verticalOffset],
  });

  const xDomain = useMemo(() => {
    const values = steps
      .flatMap((step) => [step.start, step.end])
      .map((str) => new Date(str).valueOf());
    const min = Math.min(...values);
    const max = Math.max(...values);
    return [min, max];
  }, [steps]);

  const xScale = time({ domain: xDomain, nice: true, range: [0, innerWidth] });

  const formatDate = (date: Date) =>
    date.toLocaleDateString('en-us', { month: 'short' });

  if (width < 200) {
    // 'Width of Timeline Chart is too small';
    return null;
  }

  return (
    <svg height={height} width={width}>
      <Group left={margin.left} top={margin.top}>
        <Grid
          height={innerHeight}
          stroke={colors.grid}
          width={innerWidth}
          xScale={xScale}
          yScale={yScale}
        />
        {steps.map((step, index) => {
          const y = yScale(step.y);
          const x = xScale(new Date(step.start));

          if (y === undefined) {
            return null;
          }

          const barWidth =
            xScale(new Date(step.end).valueOf()) -
            xScale(new Date(step.start).valueOf());

          const labelOffset = 10;
          const labelX = xScale(new Date(step.end)) + labelOffset;

          return (
            <Fragment key={index}>
              <Bar
                key={index}
                fill={colorScale(step.y)}
                height={yScale.bandwidth() / 4}
                style={{ transform: `translate(0, ${verticalOffset}px)` }}
                width={barWidth}
                x={x}
                y={y + yScale.bandwidth() / 3}
              />

              <Text
                textAnchor="start"
                verticalAnchor="middle"
                x={labelX}
                y={y + yScale.bandwidth() / 2}
                style={{
                  fontSize: '12px',
                  fontWeight: 600,
                  transform: `translate(0, ${verticalOffset - 1}px)`,
                }}
              >
                {textLabel(step)}
              </Text>
            </Fragment>
          );
        })}
        <AxisLeft
          scale={yScale}
          tickLabelProps={() => ({ fill: colors.axes, fontSize: '12px' })}
          tickLength={margin.left}
          top={verticalOffset + 2}
          hideAxisLine
          hideTicks
        />
        <AxisBottom
          scale={xScale}
          stroke={colors.axes}
          // @ts-expect-error Incompatible types
          tickFormat={formatDate}
          tickStroke={colors.axes}
          top={innerHeight}
          tickLabelProps={() => ({
            fill: colors.axes,
            fontSize: 13,
            fontWeight: 600,
            textAnchor: 'middle',
            verticalAnchor: 'middle',
          })}
        />
      </Group>
    </svg>
  );
}

export default TimelineChart;
