import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'shared/state/store';

type Draft = { location: string; text?: string };

type State = {
  drafts: Record<Draft['location'], Pick<Draft, 'text'> | undefined>;
  commentsNumber: Record<Draft['location'], number>;
  activeLocation: string | null;
  header?: string | null;
  title?: string | null;
};

const initialState: State = {
  drafts: {},
  commentsNumber: {},
  activeLocation: null,
  header: undefined,
  title: undefined,
};

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    updateDraftText: (state, action: PayloadAction<Draft>) => {
      const { location, text } = action.payload;
      state.drafts[location] = { ...state.drafts[location], text };
    },
    deleteDraft: (
      state,
      action: PayloadAction<{ location: Draft['location'] }>,
    ) => {
      const { location } = action.payload;
      delete state.drafts[location];
    },
    updateCommentsNumber: (
      state,
      action: PayloadAction<{ location: Draft['location']; comments: number }>,
    ) => {
      const { comments, location } = action.payload;
      state.commentsNumber[location] = comments;
    },
    updateActiveLocation: (
      state,
      action: PayloadAction<{ location: Draft['location'] | null }>,
    ) => {
      const { location } = action.payload;
      state.activeLocation = location;
    },
    updateHeader: (state, action: PayloadAction<{ header: string | null }>) => {
      const { header } = action.payload;
      state.header = header;
    },
    updateTitle: (state, action: PayloadAction<{ title: string | null }>) => {
      const { title } = action.payload;
      state.title = title;
    },
  },
});

export const selectDraftInLocation = (location: string) => (state: RootState) =>
  state.comment.drafts[location];
export const selectActiveCommentsLocation = (state: RootState) =>
  state.comment.activeLocation;
export const selectCommentsHeader = (state: RootState) => state.comment.header;
export const selectCommentsTitle = (state: RootState) => state.comment.title;

export const {
  deleteDraft,
  updateDraftText,
  updateActiveLocation,
  updateHeader,
  updateTitle,
} = commentSlice.actions;

export const commentReducer = commentSlice.reducer;
