import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import NavigationTabs from 'shared/ui/tabs/NavigationTabs';
import type { TabProps } from 'shared/ui/tabs/Tabs';

import useCohortAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useCohortAndPeriodWithVersions';
import { getPatientJourneyByCohortNavigation } from 'routes';
import type { PatientJourneyMenuItemType } from 'shared/lib/types';

function PatientJourneySubtabs() {
  const [activeTab, setActiveTab] = useState<string | undefined>();

  const {
    periodSpecificTabItems: finalCohortTabs,
    periodSpecificPatientCohortTraceId: patientCohortId,
  } = useCohortAndPeriodWithVersions();

  const navigate = useNavigate();

  useEffect(() => {
    const initialTab = patientCohortId ?? finalCohortTabs?.at(0)?.key ?? '';
    setActiveTab(initialTab);
    navigate(
      getPatientJourneyByCohortNavigation('patient-journey', initialTab),
    );
  }, [finalCohortTabs, navigate, patientCohortId, setActiveTab]);

  const tabs = finalCohortTabs?.map(
    (eachCohort: PatientJourneyMenuItemType) => ({
      key: eachCohort.key,
      devLabel: 'BUNSHIN',
      label: eachCohort.title,
      disabled: false,
    }),
  ) as TabProps[];

  return (
    <NavigationTabs
      activeTab={activeTab}
      sx={useMemo(() => ({ pb: '8px' }), [])}
      tabs={tabs}
      variant="small"
      keyToUrl={(cohortId) =>
        getPatientJourneyByCohortNavigation('patient-journey', cohortId)
      }
    />
  );
}

export default PatientJourneySubtabs;
