import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query/react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { CroExpenseSummaryRow, TraceId } from 'shared/lib/types';

import { useGetCroExpenseSummaryQuery } from 'shared/api/rtkq/periods';

export function processCroExpenseSummaryRows(
  expenseSummaryGridRows: CroExpenseSummaryRow[] | undefined,
) {
  return cloneDeep(expenseSummaryGridRows);
}
export default function useCroExpenseSummaryRows(
  contractContainerTraceId: TraceId | 'ALL' | undefined,
): {
  rows: CroExpenseSummaryRow[] | undefined;
  isLoading: boolean;
} {
  const period = useSelector(selectPeriod);
  const {
    currentData: expenseSummaryGrid,
    isLoading,
    isFetching,
  } = useGetCroExpenseSummaryQuery(
    contractContainerTraceId !== undefined
      ? { trace_id: period.trace_id, otherParameter: contractContainerTraceId }
      : skipToken,
  );

  return useMemo(
    () => ({
      rows: processCroExpenseSummaryRows(expenseSummaryGrid?.rows),
      isLoading: isLoading || isFetching,
    }),
    [expenseSummaryGrid, isFetching, isLoading],
  );
}
