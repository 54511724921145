import { type MouseEvent, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';

import IconButton from 'shared/ui/icon-button/IconButton';

import type { ControlCodeResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import DeleteControlCode from './modals/DeleteControlCode';
import EditControlModal from './modals/EditControlModal';

const OPTIONS = ['Edit', 'Delete'] as const;

type Props = {
  controlCode: ControlCodeResponse;
};

function ControlCodeMenu(props: Props) {
  const { controlCode } = props;
  const currentCompany = useSelector(selectCompany);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showModal, setShowModal] = useState<(typeof OPTIONS)[number] | null>(
    null,
  );

  function onClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function onClose() {
    setAnchorEl(null);
    setShowModal(null);
  }

  function onSelect(item: (typeof OPTIONS)[number]) {
    setShowModal(item);
  }

  return (
    <div>
      <IconButton onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onClose}
      >
        {OPTIONS.map((option) => (
          <MenuItem key={option} onClick={() => onSelect(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      {showModal === 'Delete' && (
        <DeleteControlCode controlCode={controlCode} onClose={onClose} />
      )}
      {showModal === 'Edit' && (
        <EditControlModal
          companyTraceId={currentCompany.trace_id}
          editControlTraceId={controlCode.trace_id}
          onClose={onClose}
        />
      )}
    </div>
  );
}

export default ControlCodeMenu;
