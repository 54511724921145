import { skipToken } from '@reduxjs/toolkit/query/react';

import withPeriodSpecificCustomWrapper from 'shared/lib/periods/withPeriodSpecificCustomWrapper';
import ActivityDriverCounts from 'accruals/pages/clinical-expenses/shared/activity-driver-counts/ActivityDriverCounts';
import ActivityDriverCountsBlobViewer from 'accruals/pages/clinical-expenses/shared/activity-driver-counts/ActivityDriverCountsBlobViewer';
import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import { PeriodRecordBlobType } from 'shared/lib/types';

import { useGetCompletedActivityDriverCountQuery } from 'shared/api/rtkq/contracts';

function CroPassThroughActivityDriverCounts() {
  const { contractVersionTraceId } = useContractAndPeriodWithVersions();
  const { currentData } = useGetCompletedActivityDriverCountQuery(
    contractVersionTraceId
      ? { trace_id: contractVersionTraceId, otherParameter: 'PASS_THROUGH' }
      : skipToken,
  );

  return <ActivityDriverCounts activityDriverValidation={currentData} />;
}

export default withPeriodSpecificCustomWrapper(
  CroPassThroughActivityDriverCounts,
  ActivityDriverCountsBlobViewer,
  PeriodRecordBlobType.PASS_THROUGH_ACTIVITY_DRIVER_VALIDATION,
);
