import type {
  ICellRendererParams,
  IGroupCellRendererParams,
} from '@ag-grid-community/core';

import type { ContractContainerResponse } from 'shared/lib/types';

// this appears to be mis-typed in ag-grid. IGroupCellRendererParams has all
// the properties of ICellRendererParams, but the type doesn't think so
function AgGridOccContractContainerGroupCellRenderer(
  params: ICellRendererParams & IGroupCellRendererParams,
) {
  const { innerRendererParams, value } = params;
  const { contractContainers } = innerRendererParams as {
    contractContainers: ContractContainerResponse[];
  };
  const contractContainer = contractContainers.find(
    (contractContainer) => contractContainer.trace_id === value,
  );
  return contractContainer?.vendor_name;
}

export default AgGridOccContractContainerGroupCellRenderer;
