import Dialog from '@mui/material/Dialog';

import type { LabRequest } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';

import { useCreateLabContractMutation } from 'shared/api/rtkq/labcontracts';

import LabInfoStep from './steps/LabInfoStep';

type Props = { onClose: () => void; onSave?: () => void };

function AddLabVersionWizard(props: Props) {
  const { onClose, onSave } = props;

  const [addLabVersion, { isLoading }] = useCreateLabContractMutation();

  async function saveLab(labRecord: LabRequest | undefined) {
    await addLabVersion({ lab: labRecord?.trace_id });
    onSave?.();
  }

  return (
    <Dialog open>
      <Wizard devTitle="NONE" title="New lab version" onClose={onClose}>
        <LabInfoStep
          disableNextButton={isLoading}
          onSave={(lab) => void saveLab(lab)}
        />
      </Wizard>
    </Dialog>
  );
}

export default AddLabVersionWizard;
