import type { PatientAssessmentResponse } from 'shared/lib/types';

import { PATIENT_ASSESSMENTS, PATIENT_COHORTS } from './apiTags';
import constructApi from './constructApi';

const PATH = 'patientassessments';

const apiEndpointFactory = constructApi<PatientAssessmentResponse>(
  PATH,
).withTags(PATIENT_ASSESSMENTS, [PATIENT_COHORTS]);

const api = apiEndpointFactory.inject(() => ({
  createPatientAssessment: apiEndpointFactory.create([PATIENT_COHORTS]),
  updatePatientAssessment: apiEndpointFactory.update([PATIENT_COHORTS]),
  getPatientAssessmentsByTrial: apiEndpointFactory.getBy('trial'),
  deletePatientAssessment: apiEndpointFactory.delete([PATIENT_COHORTS]),
}));

export const {
  useCreatePatientAssessmentMutation,
  useUpdatePatientAssessmentMutation,
  useDeletePatientAssessmentMutation,
} = api;
