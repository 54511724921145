import color from 'color';

import colors from 'colors.module.scss';

import type { BudgetForecastConfig } from './types';

export const BUDGET_FORECAST_CONFIG: BudgetForecastConfig = {
  margin: { top: 0, right: 25, bottom: 30, left: 140 },
  ltdExpenseText: 'LTD Expense',
  contractedText: 'Contracted',
  overContractText: 'Over contract',
  underContractText: 'Under contract',
  ltdExpenseColor: colors.chart9,
  contractedColor: color(colors.chart9).alpha(0.1).rgb().string(),
  contractedColorOuter: colors.chart9,
  overContractColor: colors.errorMain,
  underContractColor: colors.successMain,
  columnColor: colors.dividerLightTheme,
  textColor: colors.primaryTextLightTheme,
};
