import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import type {
  AdministrativeOrProcedureCategoryResponse,
  PatientCohortResponse,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetAdministrativeOrProcedureCategoriesByTrialQuery } from 'shared/api/rtkq/administrativeorprocedurecategories';
import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';

import getPatientJourneyColumnDefs from './getPatientJourneyColumnDefs';

export function processPatientJourneyColDefs(
  cohortTraceId: string,
  adminOrProcedureCategories: AdministrativeOrProcedureCategoryResponse[],
  patientCohorts: PatientCohortResponse[],
) {
  const procedureCategories = adminOrProcedureCategories.filter(
    (category) => category.category_type === 'INV',
  );
  return getPatientJourneyColumnDefs(
    procedureCategories,
    patientCohorts,
    cohortTraceId,
  );
}

// isOpenPeriod is here so this works the same as all the other defs.
// if adding a NON READ ONLY field, please ensure you honor this flag
// else period close might not work correctly
export default function usePatientJourneyColumnDefs(
  _isOpenPeriod: boolean,
  cohortTraceId?: string,
): Array<CondorColDef | CondorColGroupDef> {
  const trial = useSelector(selectTrial);
  const { currentData: adminOrProcedureCategories } =
    useGetAdministrativeOrProcedureCategoriesByTrialQuery(trial.trace_id);

  const { currentData: patientCohorts } = useGetPatientCohortsByTrialQuery(
    trial.trace_id,
  );

  const procedureCategories = adminOrProcedureCategories?.filter(
    (category) => category.category_type === 'INV',
  ); // TODO: Filter in database by passing filter param to UI

  return useMemo(
    () =>
      getPatientJourneyColumnDefs(
        procedureCategories,
        patientCohorts,
        cohortTraceId,
      ),
    [patientCohorts, procedureCategories, cohortTraceId],
  );
}
