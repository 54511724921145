import type { AnyD3Scale } from '@visx/scale/lib/types/Scale';
import { formatPrefix } from 'd3-format';
import type { NumberValue } from 'd3-scale';

function getDivisor(number: number) {
  // Determine the length of the number
  const length = Math.floor(Math.log10(Math.abs(number))) + 1;

  // Determine the divisor based on the length of the number
  return 10 ** (length - 1);
}

function roundToNearest(number: number) {
  if (Number.isNaN(number)) {
    return 0;
  }
  const divisor = getDivisor(number);

  // Round the number to the nearest divisor
  return Math.round(number / divisor) * divisor;
}

export function getMaxTickValueForEnrollment(
  maxEnrollmentValue: number,
): number {
  switch (true) {
    case maxEnrollmentValue === 0:
      // A domain of [0, 0] effectively collapses the domain to a single point.
      // Make value different from 0 to fix the issue when LinePath is positioned not in actual 0 or center.
      return 0.1;
    case maxEnrollmentValue <= 5:
      return 5; // 1-2-3-4-5
    case maxEnrollmentValue <= 10:
      return 10; // 2-4-6-8-10
    case maxEnrollmentValue <= 15:
      return 15; // 5-10-15
    case maxEnrollmentValue <= 20:
      return 20; // 5-10-15-20
    case maxEnrollmentValue <= 25:
      return 25; // 5-10-15-20-25
    case maxEnrollmentValue <= 30:
      return 30; // 5-10-15-20-25-30
    case maxEnrollmentValue <= 40:
      return 40; // 10-20-30-40
    case maxEnrollmentValue <= 50:
      return 50; // 10-20-30-40-50
    case maxEnrollmentValue <= 60:
      return 60; // 15-30-45-60
    case maxEnrollmentValue <= 80:
      return 80; // 20-40-60-80
    case maxEnrollmentValue <= 100:
      return 100; // 20-40-60-80-100
    case maxEnrollmentValue <= 120:
      return 120; // 30-60-90-120
    case maxEnrollmentValue <= 150:
      return 150; // 30-60-90-120-150
    case maxEnrollmentValue <= 200:
      return 200; // 50-100-150-200
    case maxEnrollmentValue <= 250:
      return 250; // 50-100-150-250
    case maxEnrollmentValue <= 300:
      return 300; // 50-100-150-250-300
    case maxEnrollmentValue <= 400:
      return 400; // 100-200-300-400
    case maxEnrollmentValue <= 500:
      return 500; // 100-200-300-400-500
    case maxEnrollmentValue <= 600:
      return 600; // 150-300-450-600
    case maxEnrollmentValue <= 800:
      return 800; // 200-400-600-800
    default:
      return maxEnrollmentValue;
  }
}

export function getIntervalForEnrollment(maxEnrollmentValue: number): number {
  switch (true) {
    case maxEnrollmentValue <= 5:
      return 1; // 1-2-3-4-5
    case maxEnrollmentValue <= 10:
      return 10 / 5; // 2-4-6-8-10
    case maxEnrollmentValue <= 15:
      return 15 / 3; // 5-10-15
    case maxEnrollmentValue <= 20:
      return 20 / 4; // 5-10-15-20
    case maxEnrollmentValue <= 25:
      return 25 / 5; // 5-10-15-20-25
    case maxEnrollmentValue <= 30:
      return 30 / 6; // 5-10-15-20-25-30
    case maxEnrollmentValue <= 40:
      return 40 / 4; // 10-20-30-40
    case maxEnrollmentValue <= 50:
      return 50 / 5; // 10-20-30-40-50
    case maxEnrollmentValue <= 80:
      return 80 / 4; // 20-40-60-80
    case maxEnrollmentValue <= 100:
      return 100 / 5; // 20-40-60-80-100
    case maxEnrollmentValue <= 120:
      return 120 / 4; // 30-60-90-120
    case maxEnrollmentValue <= 150:
      return 150 / 5; // 30-60-90-120-150
    case maxEnrollmentValue <= 200:
      return 200 / 4; // 50-100-150-200
    case maxEnrollmentValue <= 250:
      return 250 / 5; // 50-100-150-200-250
    case maxEnrollmentValue <= 270:
      return 300 / 6; // 50-100-150-250-300
    case maxEnrollmentValue <= 300:
      return 300 / 6; // 50-100-150-250-300
    case maxEnrollmentValue <= 400:
      return 400 / 4; // 100-200-300-400
    case maxEnrollmentValue <= 500:
      return 500 / 5; // 100-200-300-400-500
    case maxEnrollmentValue <= 600:
      return 600 / 4; // 150-300-450-600
    case maxEnrollmentValue <= 800:
      return 800 / 4; // 200-400-600-800
    case maxEnrollmentValue <= 1_000_000: {
      // 2000, 20_000 or 200_000
      // 4000, 40_000 or 400_000
      // 6000, 60_000 or 600_000
      // 8000, 80_000 or 800_000
      // 10_000, 100_000 or 1000_000
      return getIntervalForEnrollment(maxEnrollmentValue / 10) * 10;
    }
    default: {
      return (
        roundToNearest(maxEnrollmentValue) / getDivisor(maxEnrollmentValue)
      );
    }
  }
}

export function getTickValuesForEnrollment(
  scaleMultiplier: number,
  limit: number,
  interval: number,
) {
  const result: number[] = [];

  if (Number.isNaN(scaleMultiplier)) {
    return result;
  }

  for (let i = 1; i <= limit / interval; i++) {
    result.push(interval * i * scaleMultiplier);
  }

  return result;
}

export function getFixedMinimumValues<T extends object>(
  minThreshold: number,
  graphData: T[],
  orderOfData: string[],
) {
  const result: T[] = [];

  for (const data of graphData) {
    const item: { [key: string]: string } = {};

    const map = new Map<string, number>();
    const negativeMap = new Map<string, number>();
    let invisibleBarsSum = 0;
    let invisibleNegativeBarsSum = 0;
    let visibleBarsSum = 0;
    let visibleNegativeBarsSum = 0;

    for (const [key, value] of Object.entries(data)) {
      if (orderOfData.includes(key)) {
        const numValue = Number(value);
        if (numValue > 0) {
          if (numValue < minThreshold) {
            map.set(key, minThreshold);
            invisibleBarsSum += minThreshold;
          } else {
            map.set(key, numValue);
            visibleBarsSum += numValue;
          }
        } else if (numValue < 0) {
          if (Math.abs(numValue) < minThreshold) {
            negativeMap.set(key, minThreshold);
            invisibleNegativeBarsSum += minThreshold;
          } else {
            negativeMap.set(key, Math.abs(numValue));
            visibleNegativeBarsSum += Math.abs(numValue);
          }
        } else {
          item[key] = value;
        }
      } else {
        // `Actual enrollment` and `date`
        item[key] = value;
      }
    }

    const visibleBarsPercentage =
      visibleBarsSum === 0
        ? 0
        : (visibleBarsSum - invisibleBarsSum) / visibleBarsSum;

    for (const [key, value] of map) {
      if (orderOfData.includes(key)) {
        if (value > minThreshold) {
          // There is still a chance to decrease the value of the bar, and it will become less than minThreshold.
          // To make the bar visible, set this value to minimum visible.
          // Tiny changes shouldn't be noticeable on the graph.
          item[key] = Math.max(
            Math.round(value * visibleBarsPercentage),
            minThreshold,
          ).toString();
        } else {
          item[key] = value.toString();
        }
      }
    }

    const visibleNegativeBarsPercentage =
      visibleNegativeBarsSum === 0
        ? 0
        : (visibleNegativeBarsSum - invisibleNegativeBarsSum) /
          visibleNegativeBarsSum;
    for (const [key, value] of negativeMap) {
      if (orderOfData.includes(key)) {
        if (value > minThreshold) {
          // There is still a chance to decrease the value of the bar, and it will become less than minThreshold.
          // To make the bar visible, set this value to minimum visible.
          // Tiny changes shouldn't be noticeable on the graph.
          item[key] = (
            0 -
            Math.max(
              Math.round(value * visibleNegativeBarsPercentage),
              minThreshold,
            )
          ).toString();
        } else {
          item[key] = (0 - value).toString();
        }
      }
    }

    result.push(item as T);
  }

  return result;
}

export function getPrefixedFormattedValue(value: NumberValue, prefix = '') {
  const baseValue = roundToNearest(
    typeof value === 'number' ? value : value.valueOf(),
  );
  const isZeroBaseValue = baseValue === 0 || Number.isNaN(baseValue);
  const format = formatPrefix(
    `${prefix}${isZeroBaseValue ? '.0' : '.1'}`,
    isZeroBaseValue ? 0 : baseValue,
  );
  const formatted = format(Number.isNaN(value) ? 0 : value);

  switch (formatted[formatted.length - 1]) {
    case 'G':
      return `${formatted.slice(0, -1)}B`;
    default:
      return formatted;
  }
}

export function assumeTransparent(color: string | undefined) {
  return color === undefined ? 'transparent' : color;
}

/** from visx-brush/src/utils.ts, not to add the whole visx-brush lib */
export function scaleInvert(scale: AnyD3Scale, value: number) {
  // Test if the scale is an ordinalScale or not,
  // Since an ordinalScale doesn't support invert function.
  if ('invert' in scale && typeof scale.invert !== 'undefined') {
    return scale.invert(value).valueOf();
  }
  const [start, end] = scale.range() as number[];
  let i = 0;
  // ordinal should have step
  const step =
    'step' in scale && typeof scale.step !== 'undefined' ? scale.step() : 1;
  const width = (step * (end - start)) / Math.abs(end - start);
  if (width > 0) {
    while (value > start + width * (i + 1)) {
      i += 1;
    }
  } else {
    while (value < start + width * (i + 1)) {
      i += 1;
    }
  }

  return i;
}
