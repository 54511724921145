import {
  CRUDAction,
  type RegionGroupListItemType,
  type RegionListItemType,
  type TraceId,
} from 'shared/lib/types';

const ERROR_MESSAGE =
  'Name is invalid or duplicate name found in regions or region groups.';
const EMPTY_REGIONS_ERROR_MESSAGE = 'Regions can not be empty.';

const LOCALE_LOWER_CASE_GLOBAL = 'Global'.toLocaleLowerCase();

function isDuplicate(
  name: string,
  traceId: TraceId | undefined,
  allRegions: RegionListItemType[],
  allRegionGroups: RegionGroupListItemType[],
) {
  const isDuplicateRegion = allRegions.some(
    (regionToCheck) =>
      regionToCheck.name.toLocaleLowerCase() === name &&
      regionToCheck.trace_id !== traceId &&
      regionToCheck.action !== CRUDAction.DELETE, // ignore any validation logic tied to deleted regions as they are logically hidden
  );

  return (
    isDuplicateRegion ||
    allRegionGroups.some(
      (regionGroupToCheck) =>
        regionGroupToCheck.name.toLocaleLowerCase() === name &&
        regionGroupToCheck.trace_id !== traceId &&
        regionGroupToCheck.regions?.some((regionName) => regionName !== '') &&
        regionGroupToCheck.action !== CRUDAction.DELETE, // ignore any validation logic tied to deleted regions as they are logically hidden
    )
  );
}

export function validateRegions(regionGroup: RegionGroupListItemType): string {
  return regionGroup.regions?.length === 0 ? EMPTY_REGIONS_ERROR_MESSAGE : '';
}

export function validateRegionGroup(
  regionGroup: RegionGroupListItemType,
  allRegions: RegionListItemType[],
  allRegionGroups: RegionGroupListItemType[],
): string {
  const localeLowerCaseName = regionGroup.name.toLocaleLowerCase();

  const isInvalid =
    (localeLowerCaseName === LOCALE_LOWER_CASE_GLOBAL && !regionGroup.global) ||
    regionGroup.name.trim().length === 0 ||
    isDuplicate(
      localeLowerCaseName,
      regionGroup.trace_id,
      allRegions,
      allRegionGroups,
    );
  return isInvalid ? ERROR_MESSAGE : '';
}

export function validateRegion(
  region: RegionListItemType,
  allRegions: RegionListItemType[],
  allRegionGroups: RegionGroupListItemType[],
): string {
  const localeLowerCaseName = region.name.toLocaleLowerCase();

  const isInvalid =
    localeLowerCaseName === LOCALE_LOWER_CASE_GLOBAL ||
    region.name.trim().length === 0 ||
    isDuplicate(
      localeLowerCaseName,
      region.trace_id,
      allRegions,
      allRegionGroups,
    );

  return isInvalid ? ERROR_MESSAGE : '';
}
