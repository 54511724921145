import type { MappedColumn } from 'shared/lib/column-mapper/types';

const columns: MappedColumn[] = [
  { label: 'Region', value: 'region_name', required: false },
  { label: 'Service Category', value: 'service_category', required: true },
  { label: 'Description', value: 'activity_description', required: true },
  { label: 'No. of LTD Units', value: 'unit_count', required: true },
  { label: 'Unit Price', value: 'pizza_unit_price', required: true },
  { label: 'Total Value', value: 'total_price', required: true },
  { label: 'Unit Type', value: 'pizza_unit_type', required: false },
  { label: 'Activity Code', value: 'activity_code', required: false },
];

export default columns;
