import { useCallback, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';

import Modal from 'shared/components/modal/Modal';
import CondorTextField from 'shared/components/text-field/CondorTextField';

import useInputErrors from 'shared/lib/sidebar/hooks/useInputErrors';
import { selectTrial } from 'shared/state/slices/trialSlice';

import {
  useCreateForecastFolderMutation,
  useGetForecastFoldersByTrialQuery,
} from 'shared/api/rtkq/forecastfolders';

type Props = {
  onClose: () => void;
};

function CreateForecastFolderModal({ onClose }: Props) {
  const trial = useSelector(selectTrial);
  const [nameInput, setNameInput] = useState<string>('');
  const { inputErrors, markInputError } = useInputErrors();
  const [createForecastFolder, { isLoading: isCreatingFolder }] =
    useCreateForecastFolderMutation();
  const { currentData: existingFolders } = useGetForecastFoldersByTrialQuery(
    trial.trace_id,
  );

  const handleCreate = useCallback(() => {
    if (!nameInput.trim()) {
      markInputError('name');
      return;
    }
    if (
      existingFolders?.some(
        (existingFolder) => existingFolder.name === nameInput,
      )
    ) {
      markInputError('name', 'A folder with this name already exists.');
      return;
    }

    void (async () => {
      // create a folder
      await createForecastFolder({
        trial: trial.trace_id,
        name: nameInput,
      });

      onClose();
      setNameInput('');
    })();
  }, [
    nameInput,
    existingFolders,
    markInputError,
    createForecastFolder,
    trial.trace_id,
    onClose,
  ]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameInput(event.target.value);
    if (inputErrors.name) {
      markInputError('name', false);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setNameInput('');
  };

  return (
    <Modal
      handleClose={handleCloseModal}
      title="New forecast folder"
      ButtonProps={{
        label: 'Create',
        testId: 'ForecastFolderModalCreate',
        loading: isCreatingFolder,
        disabled: !nameInput,
        onClick: handleCreate,
      }}
      isOpen
    >
      <Stack gap={2} mt={1}>
        <FormControl sx={{ width: '100%' }}>
          <CondorTextField
            autoComplete="off"
            errors={inputErrors.name}
            label="Name"
            name="name"
            type="text"
            value={nameInput}
            required
            onChange={handleNameChange}
          />
        </FormControl>
      </Stack>
    </Modal>
  );
}

export default CreateForecastFolderModal;
