import { useEffect, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CommentsWidget from 'shared/components/comments/CommentsWidget';
import NavigationTabs from 'shared/ui/tabs/NavigationTabs';

import { getFxRatesTab } from 'routes';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import CurrencyDropdown from 'shared/lib/currency-dropdown/CurrencyDropdown';
import type { CurrencyCode, TraceId } from 'shared/lib/types';
import { CommentLocationType } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetRelevantFxCurrenciesQuery } from 'shared/api/rtkq/companies';

import FxRateGrid from './grids/FxRateGrid';

type Props = {
  basePath: string;
  parentMenuItem?: TraceId;
};

function FxRate(props: Props) {
  const { basePath, parentMenuItem } = props;
  const navigate = useNavigate();
  const company = useSelector(selectCompany);
  const { currentData: currencyPairs } = useGetRelevantFxCurrenciesQuery(
    company.trace_id,
  );
  const { activeTab }: { activeTab?: CurrencyCode } = useParams();

  const [addedCurrencies, setAddedCurrencies] = useState<CurrencyCode[]>([]);

  const baseCurrencyTabs = Object.keys(currencyPairs ?? {}).map(
    (baseCurrency) => ({
      key: baseCurrency,
      label: baseCurrency,
    }),
  );

  useEffect(() => {
    // Set the active tab to the first base currency
    if (!activeTab && baseCurrencyTabs.length) {
      navigate(getFxRatesTab(basePath, baseCurrencyTabs[0].key));
    }
  }, [currencyPairs]);

  useEffect(() => {
    // Reset added currency columns when switching to a new base currency
    if (addedCurrencies.length) {
      setAddedCurrencies([]);
    }
  }, [activeTab, currencyPairs]);

  const currencyColumns = useMemo<CurrencyCode[]>(
    () =>
      activeTab
        ? [...(currencyPairs?.[activeTab] ?? []), ...addedCurrencies]
        : [],
    [currencyPairs, activeTab, addedCurrencies],
  );

  const handleAddCurrency = (currency: CurrencyCode) =>
    setAddedCurrencies((previousCurrencies) => [
      ...previousCurrencies,
      currency,
    ]);

  const tabKeyToUrl = (tabKey: string) => getFxRatesTab(basePath, tabKey);
  const disabledCurrencies = useMemo(
    () => [...(activeTab ? [activeTab] : []), ...currencyColumns],
    [currencyColumns, activeTab],
  );

  const gridSx = useMemo(() => ({ height: '100%', width: 'auto' }), []);

  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <Stack flexDirection="row" justifyContent="space-between" mb={2}>
          <Typography
            alignItems="center"
            color="text.secondary"
            display="flex"
            variant="body1"
          >
            <Typography
              color="primary.main"
              component="span"
              mr={2}
              variant="h4"
            >
              FX Rates
            </Typography>
            All open periods will use the current month’s FX rate. Attach
            screenshots of the sources in the comments.
          </Typography>
          <CurrencyDropdown
            disabledCurrencies={disabledCurrencies}
            handleAddCurrency={handleAddCurrency}
          />
        </Stack>
        <NavigationTabs
          activeTab={activeTab}
          keyToUrl={tabKeyToUrl}
          sx={{ mb: 1 }}
          tabs={baseCurrencyTabs}
        />
        <FxRateGrid
          baseCurrency={activeTab}
          inputCurrencies={currencyColumns}
          parentMenuItem={parentMenuItem}
          sx={gridSx}
        />
        <CommentsWidget
          location={CommentLocationType.FX_RATE}
          commentsForPage
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default FxRate;
