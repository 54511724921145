import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem, { getListItemUtilityClass } from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from 'shared/ui/icon-button/IconButton';

import type { PatientCohortEditor } from 'shared/lib/types';
import {
  closeCohortNameEditor,
  getNewCopyName,
  insertDuplicatedCohort,
  isCohortValid,
  removeCohort,
  selectCohort,
  selectCohortNameEditor,
  selectCohorts,
  showCohortNameEditor,
  updateNameInCohort,
} from 'shared/state/slices/visitSchedulesSlice';

import { useDeletePatientCohortMutation } from 'shared/api/rtkq/patientcohorts';

import useCopyCohort from '../../hooks/useCopyCohort';
import CohortInput from './CohortInput';

function CurrentCohortBar() {
  const [deleteCohort, { isLoading: isLoadingDelete }] =
    useDeletePatientCohortMutation();
  const [copyCohort, { isLoading: isLoadingCopyCohort }] = useCopyCohort();
  const isLoading = isLoadingDelete || isLoadingCopyCohort;

  const dispatch = useDispatch();
  const cohorts = useSelector(selectCohorts);
  const currentCohort = useSelector(selectCohort);
  const cohortNameEditor = useSelector(selectCohortNameEditor);

  async function onRemoveCohort() {
    if (currentCohort) {
      await deleteCohort(currentCohort.traceId!);
    }

    dispatch(removeCohort());
  }

  async function onDuplicateCohort(sourceCohort: PatientCohortEditor) {
    const cohortName = getNewCopyName(
      sourceCohort.name,
      cohorts.map((item) => item.name),
    );

    const { data: newCohort } = await copyCohort(sourceCohort, cohortName);
    if (newCohort) {
      dispatch(insertDuplicatedCohort({ sourceCohort, newCohort }));
    }
  }

  return (
    <List
      sx={{
        ml: 2,
      }}
      dense
      disablePadding
    >
      {currentCohort ? (
        <ListItem
          secondaryAction={
            <>
              <IconButton
                disabled={!isCohortValid(currentCohort) || isLoading}
                size="small"
                onClick={() => void onDuplicateCohort(currentCohort)}
              >
                <ContentCopyIcon />
              </IconButton>
              <IconButton
                disabled={cohorts.length === 1 || isLoading}
                edge="end"
                size="small"
                onClick={() => void onRemoveCohort()}
              >
                <DeleteIcon />
              </IconButton>
            </>
          }
          sx={{
            [`&.${getListItemUtilityClass('root')}`]: {
              my: (theme) => theme.spacing(0.5),
            },
          }}
          disableGutters
          divider
        >
          <ListItemText
            sx={{ display: 'flex', alignItems: 'center' }}
            primary={
              <>
                {cohortNameEditor !== 'edit' && (
                  <>
                    <Typography color="primary.main" variant="h5">
                      {currentCohort.name}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() =>
                        dispatch(showCohortNameEditor({ editor: 'edit' }))
                      }
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </>
                )}
                {cohortNameEditor === 'edit' && (
                  <CohortInput
                    cohorts={cohorts}
                    value={currentCohort.name}
                    onCancel={() => dispatch(closeCohortNameEditor())}
                    onAccept={(value) =>
                      dispatch(updateNameInCohort({ name: value }))
                    }
                  />
                )}
              </>
            }
            disableTypography
          />
        </ListItem>
      ) : (
        <ListItem>Select a cohort first</ListItem>
      )}

      <ListItem disableGutters divider>
        <ListItemText
          primary={
            <Box display="flex" flexDirection="row" ml={7}>
              <Box width={256}>
                <Typography className="required" variant="body2">
                  Visit name
                </Typography>
                <Typography variant="caption">
                  Add the visit name according to the SoA.
                </Typography>
              </Box>
              <Box ml={2} width={256}>
                <Typography className="required" variant="body2">
                  Visit type
                </Typography>
                <Typography variant="caption">
                  Include at least one screening, one screen-fail, and one
                  dropped/completed visit.
                </Typography>
              </Box>
              <Box ml={2} width={240}>
                <Typography variant="body2">
                  Where applicable, select which portion of conduct the visit
                  falls under.
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1">Treatment</Typography>
                  <Typography variant="body1">Follow-up</Typography>
                  <Typography variant="body1">Other</Typography>
                </Box>
              </Box>
              <Box ml={2} width={256}>
                <Typography variant="body2">Include day of protocol</Typography>
                <Typography variant="caption">
                  Where applicable, enter which day of the protocol the visit
                  is.
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItem>
    </List>
  );
}

export default CurrentCohortBar;
