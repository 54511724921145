import Modal from 'shared/components/modal/Modal';

import { useUpdateUserAccessMutation } from 'shared/api/rtkq/users';

type Props = {
  email: string;
  trace_id: string;
  onClose: () => void;
};

function DeactivateUserPopup(props: Props) {
  const { email, trace_id, onClose } = props;
  const [updateUserPermissions, { isLoading: updateUserPermissionsLoading }] =
    useUpdateUserAccessMutation();

  function onClick() {
    void (async () => {
      await updateUserPermissions({ trace_id, status: 'Inactive' });
      onClose();
    })();
  }

  return (
    <Modal
      handleClose={onClose}
      title={`Are you sure you want to deactivate the user ${email}?`}
      ButtonProps={{
        label: 'Deactivate',
        testId: 'confirm_deactivate_user',
        loading: updateUserPermissionsLoading,
        onClick,
      }}
      isOpen
    >
      The user will be removed from all trials. If the user is a preparer or
      reviewer on a trial, you will have to reassign the role on that trial.
    </Modal>
  );
}

export default DeactivateUserPopup;
