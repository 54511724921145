import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import useOperationalForecastPatientsColDefs from '../hooks/useOperationalForecastPatientsColDefs';
import useOperationalForecastPatientsGridOptions from '../hooks/useOperationalForecastPatientsGridOptions';
import useOperationalForecastPatientsRows from '../hooks/useOperationalForecastPatientsRows';

type Props = {
  overlayNoRowsTemplate?: string;
  showActuals: boolean;
  sx?: SxProps;
};

function OperationalForecastPatientsGrid({
  overlayNoRowsTemplate,
  showActuals,
  sx,
}: Props) {
  const colDefs = useOperationalForecastPatientsColDefs(showActuals);
  const gridOptions = useOperationalForecastPatientsGridOptions();
  const { loading, rows } = useOperationalForecastPatientsRows();

  return (
    <CondorAgGrid
      columnDefs={colDefs}
      gridOptions={gridOptions}
      loading={loading}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rows}
      sx={sx}
    />
  );
}

export default OperationalForecastPatientsGrid;
