import type { MouseEvent } from 'react';
import { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import type { ButtonProps } from 'shared/ui/button/Button';
import Button from 'shared/ui/button/Button';

import type { LabelValueOption } from 'shared/lib/types';

type Props<T> = {
  buttonProps: ButtonProps;
  options: Array<LabelValueOption<T>>;
  title: string;
  onClose?: () => void;
  onSelectOption: (option: LabelValueOption<T>) => void;
};

function Dropdown<T extends string>(props: Props<T>) {
  const { title, options, onSelectOption, buttonProps, onClose } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
    onClose?.();
  }

  function onClick(option: LabelValueOption<T>) {
    return () => {
      onSelectOption(option);
      handleClose();
    };
  }

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        {...buttonProps}
      >
        {title}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            disabled={option.disabled}
            disableRipple
            onClick={onClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default Dropdown;
