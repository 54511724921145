import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

export default function useTrialExpenseSummaryGridOptions(
  _isOpenPeriod = true,
): CondorGridOptions {
  return useMemo(
    () =>
      ({
        groupDisplayType: 'groupRows',
        getRowStyle: 'highlightRow',
        groupTotalRow: 'bottom',
        grandTotalRow: 'bottom',
        rowSelection: 'single',
        rowStyle: { cursor: 'pointer' },
        suppressAggFuncInHeader: true,
      }) as const,
    [],
  );
}
