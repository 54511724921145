import Box from '@mui/material/Box';

function Roller() {
  return (
    <Box
      className="lds-roller"
      data-testid="loading"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </Box>
  );
}

export default Roller;
