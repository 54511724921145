import { useMemo, useState } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import { useDispatch } from 'react-redux';

import PageTitle, {
  periodToBreadcrumbString,
} from 'shared/components/page-titles/PageTitle';
import Button from 'shared/ui/button/Button';
import IconButton from 'shared/ui/icon-button/IconButton';

import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import HiddenForReadOnlyPermission from 'shared/lib/read-only-permission/HiddenForReadOnlyPermission';
import {
  openDeleteForecastModal,
  openDuplicateForecastModal,
  openLockForecastModal,
  openRenameForecastModal,
} from 'shared/state/slices/modalsSlice';

const ICON_SX = { fontSize: 16 };

function ForecastingPageTitle() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    loading,
    forecast: currentForecast,
    period: currentForecastPeriod,
    folder: currentForecastFolder,
  } = useForecast();

  const breadcrumbs = useMemo(() => {
    const crumbs: string[] = [];

    if (!loading) {
      if (currentForecastFolder) {
        crumbs.push(currentForecastFolder.name);
      }
      crumbs.push(currentForecast.name);
      if (currentForecastPeriod) {
        crumbs.push(`as of ${periodToBreadcrumbString(currentForecastPeriod)}`);
      }
    }
    return crumbs;
  }, [currentForecast, currentForecastFolder, currentForecastPeriod, loading]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => setAnchorEl(null);

  const handleRename = () => {
    dispatch(openRenameForecastModal(currentForecast));
    handleCloseMenu();
  };
  const handleDuplicate = () => {
    dispatch(openDuplicateForecastModal(currentForecast));
    handleCloseMenu();
  };
  const handleDelete = () => {
    dispatch(openDeleteForecastModal(currentForecast));
    handleCloseMenu();
  };
  const handleLock = () => {
    dispatch(openLockForecastModal());
    handleCloseMenu();
  };

  return (
    <PageTitle
      breadcrumbs={breadcrumbs}
      breadcrumbsLoading={loading}
      rightItems={
        <HiddenForReadOnlyPermission>
          <Button
            color="primary"
            size="small"
            testId="ForecastLock"
            variant={currentForecast.locked ? 'outlined' : 'contained'}
            startIcon={
              currentForecast.locked ? (
                <LockIcon sx={ICON_SX} />
              ) : (
                <LockOpenIcon sx={ICON_SX} />
              )
            }
            onClick={handleLock}
          >
            {currentForecast.locked ? 'Unlock' : 'Lock'}
          </Button>
          <IconButton onClick={handleOpenMenu}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            marginThreshold={0}
            open={anchorEl !== null}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={handleCloseMenu}
          >
            <Box sx={{ display: 'flex' }}>
              <List disablePadding>
                <ListItemButton
                  data-testid="ForecastKabobRename"
                  onClick={handleRename}
                >
                  Rename
                </ListItemButton>
                <ListItemButton
                  data-testid="ForecastKabobDuplicate"
                  onClick={handleDuplicate}
                >
                  Duplicate
                </ListItemButton>
                <ListItemButton
                  data-testid="ForecastKabobDelete"
                  onClick={handleDelete}
                >
                  Delete
                </ListItemButton>
              </List>
            </Box>
          </Popover>
        </HiddenForReadOnlyPermission>
      }
    />
  );
}

export default ForecastingPageTitle;
