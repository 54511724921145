import type { TrialAssumptionsResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const apiEndpointFactory =
  constructApi<TrialAssumptionsResponse>('trialassumptions').withTags(
    'TRIAL_ASSUMPTIONS',
  );
export const api = apiEndpointFactory.inject(() => ({
  getTrialAssumptionsByCompany: apiEndpointFactory.getBy(
    'company',
    'start_up_start_date',
  ),
  getTrialAssumptionsByTrial: apiEndpointFactory.getBy('trial'),
}));

export const {
  useGetTrialAssumptionsByCompanyQuery,
  useGetTrialAssumptionsByTrialQuery,
} = api;
