import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import type { TrialClosedPeriodValuesRow } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetClosedPeriodExpensesQuery } from 'shared/api/rtkq/trials';

export default function useClosedPeriodValuesRows():
  | TrialClosedPeriodValuesRow[]
  | undefined {
  const trial = useSelector(selectTrial);
  const { currentData: closedPeriodValues } = useGetClosedPeriodExpensesQuery(
    trial.trace_id,
  );

  return useMemo(
    () => cloneDeep(closedPeriodValues?.rows),
    [closedPeriodValues],
  );
}
