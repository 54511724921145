import Box from '@mui/material/Box';
import ExternalLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import FileDisplay from 'shared/components/file-uploader/FileDisplay';
import type { FileUploaderRef } from 'shared/components/file-uploader/FileUploader';
import Button from 'shared/ui/button/Button';

import errorIcon from './icons/error.svg';

type Props = {
  columnCount: number;
  fileUploadData: { message: string; rows_count: number } | null;
  fileUploaderRef: React.MutableRefObject<FileUploaderRef | null>;
  files: File[];
  sampleFileLink: string | undefined;
  sampleFileName: string;
};

function UploadErrorSection(props: Props) {
  const {
    columnCount,
    fileUploadData,
    fileUploaderRef,
    files,
    sampleFileLink,
    sampleFileName,
  } = props;
  return (
    <>
      <img alt="Error" height={120} src={errorIcon} />
      <b>
        <Box color="error.main" component="p" fontSize="18px" lineHeight="1.5">
          This file {columnCount > 0 ? 'only' : ''} has {columnCount} column
          {columnCount !== 1 ? 's' : ''}.
        </Box>
      </b>
      <Box color="error.main" fontSize="18px" lineHeight="1.5">
        {fileUploadData?.message}
      </Box>
      <Box color="grey.900" display="flex" gap={4}>
        <span>
          Column count: <b>{columnCount}</b>
        </span>
        <span>
          Row count: <b>{fileUploadData?.rows_count ?? 0}</b>
        </span>
      </Box>
      <Button component="label" testId="change_file_error" variant="outlined">
        Change File
        {fileUploaderRef.current?.getFileInput()}
      </Button>
      <FileDisplay files={files} />
      <Typography color="info.main" sx={{ marginTop: '24px' }} variant="body1">
        <ExternalLink
          fontSize="14px"
          href={sampleFileLink}
          rel="noreferrer"
          target="_blank"
        >
          View sample {sampleFileName}
        </ExternalLink>
      </Typography>
    </>
  );
}

export default UploadErrorSection;
