import { useMemo } from 'react';

import {
  getNativeCurrencyMoneyCellConfig,
  getNullOrDecimalConfig,
  getRowNumberConfig,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

export default function useCroContractBudgetColumnDefs(
  _isOpenPeriod: boolean,
): Array<CondorColDef | CondorColGroupDef> {
  return useMemo(
    () => [
      { field: 'id', hide: true },
      { field: 'contractVersionTraceId', hide: true },
      { field: 'vendorName', hide: true },
      { field: 'traceId', hide: true },
      { field: 'costCategory', rowGroup: true, hide: true },
      { field: 'serviceCategory', rowGroup: true, hide: true },
      {
        field: 'displayRowNum',
        pinned: true,
        flex: 1,
        headerName: 'Row',
        ...getRowNumberConfig(),
      },
      { pinned: true, field: 'region', flex: 1 },
      { field: 'activityDescription', headerName: 'Description', flex: 1 },
      { field: 'pizzaUnitType', headerName: 'Unit Type', flex: 1 },
      {
        field: 'pizzaUnitCount',
        headerName: '# of Units',
        flex: 1,
        ...getNullOrDecimalConfig(),
      },
      {
        field: 'pizzaUnitPrice',
        headerName: 'Unit Price',
        flex: 1,
        ...getNativeCurrencyMoneyCellConfig(),
      },
      {
        field: 'totalPrice',
        headerName: 'Total Contract Value',
        flex: 1,
        ...getNativeCurrencyMoneyCellConfig(),
        aggFunc: 'sum',
      },
    ],
    [],
  );
}
