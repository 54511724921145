import orderBy from 'lodash/orderBy';

import { differenceInMonthsFractional } from 'shared/helpers/helpers';
import type {
  AssumptionGroupRequestDisplay,
  AssumptionGroupResponse,
  TraceId,
} from 'shared/lib/types';
import {
  AssumptionGroupName,
  AssumptionGroupSectionName,
  DataType,
  DateAssumptionType,
} from 'shared/lib/types';

function parseNumber(value?: string) {
  const number = Number(value);
  return Number.isNaN(number) || !Number.isFinite(number) ? 0 : number;
}

function getContractParamByRegion(
  assumptionGroup?: AssumptionGroupRequestDisplay,
  region?: TraceId | null,
) {
  return assumptionGroup?.contract_params.find(
    (param) => param.region === region,
  );
}

function getNumberValueFromAssumptionGroup(
  allAssumptionGroups: AssumptionGroupRequestDisplay[],
  name: AssumptionGroupName,
  region?: TraceId | null,
) {
  const patientThroughput = allAssumptionGroups.find(
    (param) => param.name === name,
  );
  const patientThroughputParam = getContractParamByRegion(
    patientThroughput,
    region,
  );
  return parseNumber(patientThroughputParam?.value);
}

function insertUpdatedDateAssumption(
  assumptionGroup: AssumptionGroupRequestDisplay,
  dateAssumptionType: DateAssumptionType,
  assumptionGroupToCheck?: AssumptionGroupRequestDisplay,
) {
  const checkedValue = assumptionGroupToCheck?.contract_params.find(
    (param) => param.date_assumption_type === dateAssumptionType,
  );

  if (checkedValue) {
    const currentValueIndex = assumptionGroup.contract_params.findIndex(
      (param) => param.date_assumption_type === dateAssumptionType,
    );
    if (currentValueIndex < 0) {
      assumptionGroup.contract_params.push({
        date_assumption_type: dateAssumptionType,
        value: checkedValue.value,
      });
    } else {
      assumptionGroup.contract_params.splice(currentValueIndex, 1, {
        ...assumptionGroup.contract_params[currentValueIndex],
        date_assumption_type: dateAssumptionType,
        value: checkedValue.value,
      });
    }
  }

  return assumptionGroup;
}

function insertUpdatedAssumptionGroup(
  assumptionGroup: AssumptionGroupRequestDisplay,
  allAssumptionGroups: AssumptionGroupRequestDisplay[],
) {
  const index = allAssumptionGroups.findIndex(
    (group) => group.name === assumptionGroup.name,
  );
  allAssumptionGroups.splice(index, 1, {
    ...assumptionGroup,
    overallValue: getOverall(assumptionGroup),
  });
  return [...allAssumptionGroups];
}

function overallCalculation(
  assumptionGroup: AssumptionGroupRequestDisplay,
  allAssumptionGroups: AssumptionGroupRequestDisplay[],
) {
  let finalAssumptionGroup = { ...assumptionGroup, editable: false };
  finalAssumptionGroup = insertUpdatedDateAssumption(
    finalAssumptionGroup,
    DateAssumptionType.START,
    allAssumptionGroups.find(
      (param) => param.name === AssumptionGroupName.START_UP,
    ),
  );
  finalAssumptionGroup = insertUpdatedDateAssumption(
    finalAssumptionGroup,
    DateAssumptionType.END,
    allAssumptionGroups.find(
      (param) => param.name === AssumptionGroupName.CLOSE,
    ),
  );
  return insertUpdatedAssumptionGroup(
    finalAssumptionGroup,
    allAssumptionGroups,
  );
}

function patientMonthsCalculation(
  assumptionGroup: AssumptionGroupRequestDisplay,
  allAssumptionGroups: AssumptionGroupRequestDisplay[],
) {
  const patientThroughputValue = getNumberValueFromAssumptionGroup(
    allAssumptionGroups,
    AssumptionGroupName.PATIENT_THROUGHPUT,
    null,
  );
  const patientEnrolled = allAssumptionGroups.find(
    (param) => param.name === AssumptionGroupName.PATIENTS_ENROLLED,
  );

  const finalAssumptionGroup = { ...assumptionGroup, editable: false };

  if (patientEnrolled) {
    // loop through the patient enrolled params to ensure that the params are added
    // as the system has to add them, being they are automatically generated
    finalAssumptionGroup.contract_params = patientEnrolled.contract_params
      .filter((param) => param.region !== null && param.region !== undefined)
      .map((param) => ({
        ...getContractParamByRegion(finalAssumptionGroup, param.region),
        region: param.region,
        value: (parseNumber(param.value) * patientThroughputValue).toString(),
      }));
  }

  return insertUpdatedAssumptionGroup(
    finalAssumptionGroup,
    allAssumptionGroups,
  );
}

function siteMonthsCalculation(
  assumptionGroup: AssumptionGroupRequestDisplay,
  allAssumptionGroups: AssumptionGroupRequestDisplay[],
) {
  const sites = allAssumptionGroups.find(
    (param) => param.name === AssumptionGroupName.SITES,
  );

  const finalAssumptionGroup = { ...assumptionGroup, editable: false };

  if (sites) {
    // loop through the sites params to ensure that the params are added
    // as the system has to add them, being they are automatically generated
    finalAssumptionGroup.contract_params = sites.contract_params
      .filter((param) => param.region !== null && param.region !== undefined)
      .map((param) => {
        const clinicalDurationValue = getNumberValueFromAssumptionGroup(
          allAssumptionGroups,
          AssumptionGroupName.CLINICAL_DURATION,
          param.region,
        );

        return {
          ...getContractParamByRegion(finalAssumptionGroup, param.region),
          region: param.region,
          value: (parseNumber(param.value) * clinicalDurationValue).toString(),
        };
      });
  }

  return insertUpdatedAssumptionGroup(
    finalAssumptionGroup,
    allAssumptionGroups,
  );
}

export function getAssumptionGroupNamesByAssumptionGroupSectionName(
  name: AssumptionGroupSectionName,
) {
  switch (name) {
    case AssumptionGroupSectionName.TIMELINE:
      return [
        AssumptionGroupName.START_UP,
        AssumptionGroupName.CONDUCT,
        AssumptionGroupName.ENROLLMENT,
        AssumptionGroupName.TREATMENT,
        AssumptionGroupName.FOLLOWUP,
        AssumptionGroupName.CLOSE,
        AssumptionGroupName.OVERALL,
      ];
    case AssumptionGroupSectionName.SITES:
      return [
        AssumptionGroupName.SITES,
        AssumptionGroupName.CLINICAL_DURATION,
        AssumptionGroupName.SITE_MONTHS,
      ];
    case AssumptionGroupSectionName.PATIENT_ENROLLMENT:
      return [
        AssumptionGroupName.PATIENTS_SCREENED,
        AssumptionGroupName.PATIENTS_ENROLLED,
        AssumptionGroupName.PATIENTS_DROPPED_COMPLETED,
      ];
    case AssumptionGroupSectionName.PATIENT_MONTHS:
      return [
        AssumptionGroupName.PATIENT_THROUGHPUT,
        AssumptionGroupName.PATIENT_MONTHS,
      ];
  }
}

export function getAssumptionGroupLabel(name: AssumptionGroupName) {
  switch (
    name // Timeline
  ) {
    case AssumptionGroupName.START_UP:
      return 'Start up';
    case AssumptionGroupName.CONDUCT:
      return 'Conduct';
    case AssumptionGroupName.ENROLLMENT:
      return 'Enrollment';
    case AssumptionGroupName.TREATMENT:
      return 'Treatment';
    case AssumptionGroupName.FOLLOWUP:
      return 'Followup';
    case AssumptionGroupName.CLOSE:
      return 'Close';
    case AssumptionGroupName.OVERALL:
      return 'Overall';
    // Patient Enrollment
    case AssumptionGroupName.PATIENTS_SCREENED:
      return 'Patients screened';
    case AssumptionGroupName.PATIENTS_ENROLLED:
      return 'Patients enrolled';
    case AssumptionGroupName.PATIENTS_DROPPED_COMPLETED:
      return 'Patients completed';
    // Patient Months
    case AssumptionGroupName.PATIENT_THROUGHPUT:
      return 'Expected patient throughput (months)';
    case AssumptionGroupName.PATIENT_MONTHS:
      return 'Patient months';

    // TEMPORARILY REMOVED
    // // case AssumptionGroupName.PATIENT_ONSITE_VISITS:
    // //  return 'On-site interim visits';
    // // case AssumptionGroupName.IMV_PER_PATIENT_MONTH:
    // //   return 'IMV per patient month';

    // Sites
    case AssumptionGroupName.SITES:
      return 'Sites';
    case AssumptionGroupName.CLINICAL_DURATION:
      return 'Start up + clinical duration';
    case AssumptionGroupName.SITE_MONTHS:
      return 'Site months';
  }
}

export function getCalculation(name: AssumptionGroupName) {
  switch (name) {
    case AssumptionGroupName.SITE_MONTHS:
      return siteMonthsCalculation;
    case AssumptionGroupName.PATIENT_MONTHS:
      return patientMonthsCalculation;
    case AssumptionGroupName.OVERALL:
      return overallCalculation;
    default:
      return (
        _assumptionGroup: AssumptionGroupRequestDisplay,
        allAssumptionGroups: AssumptionGroupRequestDisplay[],
      ) => allAssumptionGroups;
  }
}

export function getOverall(
  assumptionGroup:
    | AssumptionGroupResponse
    | Partial<AssumptionGroupRequestDisplay>
    | undefined,
): number | undefined {
  if (assumptionGroup === undefined) {
    return undefined;
  }
  const { contract_params, data_type } = assumptionGroup;

  if (contract_params === undefined || data_type === undefined) {
    return undefined;
  }

  switch (data_type) {
    case DataType.DATE: {
      const start = contract_params.find(
        (param) =>
          param.date_assumption_type === DateAssumptionType.START ||
          param.date_param_type === DateAssumptionType.START,
      );
      const end = contract_params.find(
        (param) =>
          param.date_assumption_type === DateAssumptionType.END ||
          param.date_param_type === DateAssumptionType.END,
      );

      if (start?.value && end?.value) {
        const difference = differenceInMonthsFractional(
          new Date(start.value),
          new Date(end.value),
        );
        return Number.isNaN(difference) ? undefined : difference;
      }
      return undefined;
    }
    case DataType.INTEGER:
    case DataType.DECIMAL: {
      if (contract_params.length === 0) {
        return undefined;
      }

      const values = contract_params.map((param) => param.value);
      return values.reduce((acc, value) => {
        const num = Number(value ?? 0);
        return acc + (Number.isNaN(num) ? 0 : num);
      }, 0);
    }
  }
}

export function getTypeLabel(type: AssumptionGroupSectionName) {
  switch (type) {
    case AssumptionGroupSectionName.TIMELINE:
      return 'Timeline';
    case AssumptionGroupSectionName.PATIENT_ENROLLMENT:
      return 'Patient Enrollment';
    case AssumptionGroupSectionName.PATIENT_MONTHS:
      return 'Patient Months (Enrollment * throughput)';
    case AssumptionGroupSectionName.SITES:
      return 'Sites';
  }
}

export function showOverall(assumptionGroupName: AssumptionGroupName) {
  return ![
    AssumptionGroupName.PATIENT_THROUGHPUT,
    AssumptionGroupName.CLINICAL_DURATION,
  ].includes(assumptionGroupName);
}

export function getRequired(assumptionGroupName: AssumptionGroupName) {
  return [
    AssumptionGroupName.START_UP,
    AssumptionGroupName.CONDUCT,
    AssumptionGroupName.CLOSE,
    // Sites
    AssumptionGroupName.SITES,
    AssumptionGroupName.CLINICAL_DURATION,
    AssumptionGroupName.SITE_MONTHS,
    // Patient Enrollment
    AssumptionGroupName.PATIENTS_SCREENED,
    AssumptionGroupName.PATIENTS_ENROLLED,
    AssumptionGroupName.PATIENTS_DROPPED_COMPLETED,
    // Patient Months
    AssumptionGroupName.PATIENT_THROUGHPUT,
    AssumptionGroupName.PATIENT_MONTHS,
  ].includes(assumptionGroupName);
}

export function showEmptyRowAfter(assumptionGroupName: AssumptionGroupName) {
  return [
    AssumptionGroupName.PATIENT_THROUGHPUT,
    AssumptionGroupName.SITES,
    AssumptionGroupName.CLINICAL_DURATION,
  ].includes(assumptionGroupName);
}

export function showByRegion(assumptionGroupName: AssumptionGroupName) {
  return [
    AssumptionGroupName.PATIENTS_SCREENED,
    AssumptionGroupName.PATIENTS_ENROLLED,
    AssumptionGroupName.PATIENTS_DROPPED_COMPLETED,
    AssumptionGroupName.PATIENT_MONTHS,
    AssumptionGroupName.SITES,
    AssumptionGroupName.SITE_MONTHS,
    AssumptionGroupName.CLINICAL_DURATION,
  ].includes(assumptionGroupName);
}

export function getDataType(assumptionGroupName: AssumptionGroupName) {
  switch (assumptionGroupName) {
    case AssumptionGroupName.START_UP:
    case AssumptionGroupName.CONDUCT:
    case AssumptionGroupName.ENROLLMENT:
    case AssumptionGroupName.TREATMENT:
    case AssumptionGroupName.FOLLOWUP:
    case AssumptionGroupName.CLOSE:
    case AssumptionGroupName.OVERALL:
      return DataType.DATE;
    case AssumptionGroupName.CLINICAL_DURATION:
    case AssumptionGroupName.PATIENT_MONTHS:
    case AssumptionGroupName.PATIENT_THROUGHPUT:
    case AssumptionGroupName.SITE_MONTHS:
      return DataType.DECIMAL;
    case AssumptionGroupName.PATIENTS_SCREENED:
    case AssumptionGroupName.PATIENTS_ENROLLED:
    case AssumptionGroupName.PATIENTS_DROPPED_COMPLETED:
    case AssumptionGroupName.SITES:
      return DataType.INTEGER;
  }
}

export const ALL_DISPLAYED_ASSUMPTION_GROUP_NAMES = [
  AssumptionGroupName.START_UP,
  AssumptionGroupName.CONDUCT,
  AssumptionGroupName.ENROLLMENT,
  AssumptionGroupName.TREATMENT,
  AssumptionGroupName.FOLLOWUP,
  AssumptionGroupName.CLOSE,
  AssumptionGroupName.OVERALL,
  // Sites
  AssumptionGroupName.SITES,
  AssumptionGroupName.CLINICAL_DURATION,
  AssumptionGroupName.SITE_MONTHS,
  // Patient Enrollment
  AssumptionGroupName.PATIENTS_SCREENED,
  AssumptionGroupName.PATIENTS_ENROLLED,
  AssumptionGroupName.PATIENTS_DROPPED_COMPLETED,
  // Patient Months
  AssumptionGroupName.PATIENT_THROUGHPUT,
  AssumptionGroupName.PATIENT_MONTHS,
];

export function getMostRecentAssumptionGroupByName(
  databaseAssumptionGroups: AssumptionGroupResponse[],
) {
  const returnValue: AssumptionGroupResponse[] = [];

  // add any params that were in the database
  const sortedAssumptionGroupsByCreated = orderBy(
    databaseAssumptionGroups,
    'created_at',
    'desc',
  );
  for (const assumptionGroup of sortedAssumptionGroupsByCreated) {
    if (returnValue.every((param) => param.name !== assumptionGroup.name)) {
      returnValue.push(assumptionGroup);
    }
  }

  return returnValue;
}
