import type { EdcRecordResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'edcrecords';
export const EDC_RECORD_TAG = 'EDC_RECORDS';

const apiEndpointFactory =
  constructApi<EdcRecordResponse>(PATH).withTags(EDC_RECORD_TAG);
const api = apiEndpointFactory.inject(() => ({
  createEdcRecord: apiEndpointFactory.create(),
  updateEdcRecord: apiEndpointFactory.update(),
  getEdcRecordsBySnapshot: apiEndpointFactory.getBy('snapshot', 'snapshot_row'),
  deleteEdcRecord: apiEndpointFactory.delete(),
}));

export const { useUpdateEdcRecordMutation, useGetEdcRecordsBySnapshotQuery } =
  api;
