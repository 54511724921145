import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  ForecastFolderResponse,
  ForecastResponse,
} from 'shared/lib/types';
import type { RootState } from 'shared/state/store';

type State = {
  createForecast?: boolean;
  renameForecast?: ForecastResponse;
  lockForecast?: boolean;
  duplicateForecast?: ForecastResponse;
  deleteForecast?: ForecastResponse;
  createForecastFolder?: boolean;
  renameForecastFolder?: ForecastFolderResponse;
  deleteForecastFolder?: ForecastFolderResponse;
};
type ModalKey = keyof State;

const initialState: () => State = () => ({
  createForecast: false,
  renameForecast: undefined,
  lockForecast: false,
  duplicateForecast: undefined,
  deleteForecast: undefined,
  createForecastFolder: false,
  renameForecastFolder: undefined,
  deleteForecastFolder: undefined,
});

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openCreateForecastModal: (state) => {
      state.createForecast = true;
    },
    openRenameForecastModal: (
      state,
      action: PayloadAction<ForecastResponse>,
    ) => {
      state.renameForecast = action.payload;
    },
    openLockForecastModal: (state) => {
      // Lock forecast just needs the current forecast
      state.lockForecast = true;
    },
    openDuplicateForecastModal: (
      state,
      action: PayloadAction<ForecastResponse>,
    ) => {
      state.duplicateForecast = action.payload;
    },
    openDeleteForecastModal: (
      state,
      action: PayloadAction<ForecastResponse>,
    ) => {
      state.deleteForecast = action.payload;
    },
    openCreateForecastFolderModal: (state) => {
      state.createForecastFolder = true;
    },
    openRenameForecastFolderModal: (
      state,
      action: PayloadAction<ForecastFolderResponse>,
    ) => {
      state.renameForecastFolder = action.payload;
    },
    openDeleteForecastFolderModal: (
      state,
      action: PayloadAction<ForecastFolderResponse>,
    ) => {
      state.deleteForecastFolder = action.payload;
    },
    closeModal: (state, action: PayloadAction<ModalKey>) => {
      state[action.payload] = undefined;
    },
  },
});

export const selectModals = (state: RootState) => state.modals;

export const {
  openCreateForecastModal,
  openRenameForecastModal,
  openLockForecastModal,
  openDuplicateForecastModal,
  openDeleteForecastModal,
  openCreateForecastFolderModal,
  openRenameForecastFolderModal,
  openDeleteForecastFolderModal,
  closeModal,
} = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
