import { useState } from 'react';

import type { ContractFile, TraceId } from 'shared/lib/types';

import {
  useCreateContractFileMutation,
  useUpdateContractFileMutation,
} from 'shared/api/rtkq/contractfiles';

function useUpsertContractFiles(): [
  (
    files: ContractFile[],
    contractTraceId: TraceId,
    periodVersionTraceId: TraceId,
  ) => Promise<ContractFile[]>,
  { isLoading: boolean; errors: ContractFile[] },
] {
  const [createFileMutation] = useCreateContractFileMutation();
  const [updateFileMutation] = useUpdateContractFileMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ContractFile[]>([]);

  async function uploadFiles(
    files: ContractFile[],
    contractTraceId: TraceId,
    periodVersionTraceId: TraceId,
  ): Promise<ContractFile[]> {
    setIsLoading(true);
    setErrors([]);

    const uploadErrors: ContractFile[] = [];

    const result = await Promise.all(
      files.map(async (document) => {
        const documentData = new FormData();
        documentData.append('file', document.file!);
        documentData.append('contract', contractTraceId);
        documentData.append('file_name', document.file_name!);
        documentData.append('tag', document.tag);
        documentData.append('period_version', periodVersionTraceId);

        const newDocument = { ...document };
        try {
          let result;
          if (document.traceId) {
            result = await updateFileMutation({
              trace_id: document.traceId,
              ...documentData,
            }).unwrap();
          } else {
            result = await createFileMutation(documentData).unwrap();
          }
          newDocument.traceId = result.trace_id;
          return { ...document, traceId: result.trace_id };
        } catch {
          uploadErrors.push(newDocument);
        }

        return newDocument;
      }),
    );

    setErrors(uploadErrors);
    setIsLoading(false);

    if (uploadErrors.length) {
      throw new Error('Failed to save documents.');
    }

    return result;
  }

  return [uploadFiles, { isLoading, errors }];
}

export default useUpsertContractFiles;
