import type { CostPerPatientDatum } from 'forecasting/components/graphing/cost-per-patient-graph/types';
export const data: CostPerPatientDatum[] = [
  {
    actualSpend: '210000',
    forecastedSpend: null,
    actual: true,
    date: '2021-08-01T00:00:00',
  },
  {
    actualSpend: '200000',
    forecastedSpend: null,
    actual: true,
    date: '2021-09-01T00:00:00',
  },
  {
    actualSpend: '190000',
    forecastedSpend: null,
    actual: true,
    date: '2021-10-01T00:00:00',
  },
  {
    actualSpend: '180000',
    forecastedSpend: null,
    actual: true,
    date: '2021-11-01T00:00:00',
  },
  {
    actualSpend: '170000',
    forecastedSpend: null,
    actual: true,
    date: '2021-12-01T00:00:00',
  },
  {
    actualSpend: '160000',
    forecastedSpend: null,
    actual: true,
    date: '2022-01-01T00:00:00',
  },
  {
    actualSpend: '150000',
    forecastedSpend: null,
    actual: true,
    date: '2022-02-01T00:00:00',
  },
  {
    actualSpend: '140000',
    forecastedSpend: null,
    actual: true,
    date: '2022-03-01T00:00:00',
  },
  {
    actualSpend: '130000',
    forecastedSpend: null,
    actual: true,
    date: '2022-04-01T00:00:00',
  },
  {
    actualSpend: '120000',
    forecastedSpend: null,
    actual: true,
    date: '2022-05-01T00:00:00',
  },
  {
    actualSpend: '110000',
    forecastedSpend: null,
    actual: true,
    date: '2022-06-01T00:00:00',
  },
  {
    actualSpend: '100000',
    forecastedSpend: null,
    actual: true,
    date: '2022-07-01T00:00:00',
  },
  {
    actualSpend: '100000',
    forecastedSpend: null,
    actual: true,
    date: '2022-08-01T00:00:00',
  },
  {
    actualSpend: '100000',
    forecastedSpend: null,
    actual: true,
    date: '2022-09-01T00:00:00',
  },
  {
    actualSpend: '100000',
    forecastedSpend: null,
    actual: true,
    date: '2022-10-01T00:00:00',
  },
  {
    actualSpend: '90000',
    forecastedSpend: null,
    actual: true,
    date: '2022-11-01T00:00:00',
  },
  {
    actualSpend: '90000',
    forecastedSpend: null,
    actual: true,
    date: '2022-12-01T00:00:00',
  },
  {
    actualSpend: '90000',
    forecastedSpend: null,
    actual: true,
    date: '2023-01-01T00:00:00',
  },
  {
    actualSpend: '90000',
    forecastedSpend: null,
    actual: true,
    date: '2023-02-01T00:00:00',
  },
  {
    actualSpend: '90000',
    forecastedSpend: null,
    actual: true,
    date: '2023-03-01T00:00:00',
  },
  {
    actualSpend: '90000',
    forecastedSpend: null,
    actual: true,
    date: '2023-04-01T00:00:00',
  },
  {
    actualSpend: '80000',
    forecastedSpend: null,
    actual: true,
    date: '2023-05-01T00:00:00',
  },
  {
    actualSpend: '80000',
    forecastedSpend: null,
    actual: true,
    date: '2023-06-01T00:00:00',
  },
  {
    actualSpend: '80000',
    forecastedSpend: null,
    actual: true,
    date: '2023-07-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2023-08-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2023-09-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2023-10-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2023-11-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2023-12-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-01-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-02-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-03-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-04-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-05-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-06-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-07-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-08-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '70000',
    actual: false,
    date: '2024-09-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '71000',
    actual: false,
    date: '2024-10-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '71000',
    actual: false,
    date: '2024-11-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '71000',
    actual: false,
    date: '2024-12-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '71000',
    actual: false,
    date: '2025-01-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '71000',
    actual: false,
    date: '2025-02-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '71000',
    actual: false,
    date: '2025-02-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '71000',
    actual: false,
    date: '2025-03-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '72000',
    actual: false,
    date: '2025-04-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '72000',
    actual: false,
    date: '2025-05-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '72000',
    actual: false,
    date: '2025-06-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '72000',
    actual: false,
    date: '2025-07-01T00:00:00',
  },
  {
    actualSpend: '0',
    forecastedSpend: '72000',
    actual: false,
    date: '2025-08-01T00:00:00',
  },
];
