import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import type { SxProps } from '@mui/material/styles';

type Props = { children: ReactNode; sx?: SxProps };

function TabularDataCell(props: Props) {
  const { children, sx } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        px: 1.5,
        minWidth: 220,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default TabularDataCell;
