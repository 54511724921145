import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { DataType } from 'shared/lib/types';

import ParameterInput from './ParameterInput';

type Props = {
  dataType: DataType;
  editable?: boolean;
  isOverall?: boolean;
  label: string;
  readOnly?: boolean;
  required?: boolean;
  value: number | string | undefined;
  onChange?: (newValue: string | undefined) => void;
};

function ParameterRow(props: Props) {
  const {
    editable,
    label,
    isOverall = false,
    value,
    dataType,
    readOnly = false,
    required = false,
    onChange,
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isOverall
          ? (theme) => theme.palette.grey[100]
          : undefined,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontWeight: isOverall ? 'bold' : undefined,
          flexGrow: 0,
          flexShrink: 0,
          width: '60%',
          pl: 1,
          py: 0.75,
        }}
      >
        {label}
      </Typography>
      <ParameterInput
        dataType={dataType}
        editable={editable}
        isOverall={isOverall}
        readOnly={readOnly}
        required={required}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
}

export default ParameterRow;
