import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { ScaleOrdinal } from 'd3-scale';

import { currencyFormatter } from 'formatters';
import type { CurrencyCode } from 'shared/lib/types';

type Props = {
  currency?: CurrencyCode;
  label: string;
  legendColorScale: ScaleOrdinal<string, string>;
  value: string;
};

function GraphTooltipRow(props: Props) {
  const { label, value, legendColorScale, currency } = props;
  return (
    <Box sx={{ display: 'flex', my: 1 }}>
      <Box alignItems="center" display="flex" flex={1}>
        <Box sx={{ width: 8, height: 8, bgcolor: legendColorScale(label) }} />
        <Typography ml={1} variant="caption">
          {label}
        </Typography>
      </Box>
      <Typography variant="caption">
        {currencyFormatter(Number.parseFloat(value), currency)}
      </Typography>
    </Box>
  );
}

export default GraphTooltipRow;
