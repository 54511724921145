import { skipToken } from '@reduxjs/toolkit/query';

import withPeriodSpecificCustomWrapper from 'shared/lib/periods/withPeriodSpecificCustomWrapper';
import ContractFileBlobViewer from 'accruals/pages/clinical-expenses/shared/contract-record/ContractFileBlobViewer';
import ContractFilesList from 'accruals/pages/clinical-expenses/shared/contract-record/ContractFilesList';
import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import { PeriodRecordBlobType } from 'shared/lib/types';

import { useGetContractFilesByContractQuery } from 'shared/api/rtkq/contractfiles';

function OccContractVersionFileContents() {
  const { contractVersion } = useContractAndPeriodWithVersions();
  const { currentData: files } = useGetContractFilesByContractQuery(
    contractVersion?.trace_id ?? skipToken,
  );

  return <ContractFilesList files={files} />;
}

export default withPeriodSpecificCustomWrapper(
  OccContractVersionFileContents,
  ContractFileBlobViewer,
  PeriodRecordBlobType.OCC_CONTRACT_VERSION_FILES,
);
