import type { ReactElement } from 'react';

import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import IconButton from 'shared/ui/icon-button/IconButton';

import useWizard from 'shared/lib/wizard/useWizard';
import WizardStep from 'shared/wizards/steps/WizardStep';

type SummaryRow = { label: string; values: Array<string | undefined> };

type Props = {
  description?: string;
  formErrors?: string[];
  formattedSummaryData: SummaryItem[][];
  header: string;
  isLoading?: boolean;
  onNext?: () => void;
  onNextAsync?: () => Promise<void>;
};

function ReviewStep(props: Props): ReactElement {
  const {
    header,
    description,
    formErrors,
    onNext,
    onNextAsync,
    formattedSummaryData,
    isLoading,
  } = props;
  const { goToStep } = useWizard();
  return (
    <WizardStep
      description={description}
      formErrors={formErrors}
      header={header}
      isLoading={isLoading}
      onNext={onNext}
      onNextAsync={onNextAsync}
    >
      <Box columnGap={4} display="grid" gridTemplateColumns="40% 40%" my={3}>
        {formattedSummaryData.map((column, index) => (
          <Stack
            // biome-ignore lint/suspicious/noArrayIndexKey: This is unavoidable because all we have is an array of arrays.
            key={index}
            divider={<Divider sx={{ my: 2 }} />}
            sx={{ backgroundColor: 'grey.100', p: 5 }}
          >
            {column.map((item, itemIndex) => (
              <Box key={item.sectionHeader}>
                <Box display="flex" flexDirection="row">
                  <Typography flex={1} variant="body1">
                    {item.sectionHeader}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      goToStep(item.editPageNumber);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Box>
                {itemIndex > 0 && (
                  <Box display="flex" flexDirection="row">
                    <Typography flex={1} variant="subtitle2">
                      Visit name
                    </Typography>
                    <Typography flex={1} variant="subtitle2">
                      EDC mapping
                    </Typography>
                    <Typography flex={1} variant="subtitle2">
                      Visit type
                    </Typography>
                  </Box>
                )}
                {item.rows.map((row) => (
                  <Box
                    key={row.label}
                    display="flex"
                    flexDirection="row"
                    my={1}
                  >
                    <Typography
                      flex={1}
                      variant={itemIndex === 0 ? 'subtitle2' : 'body1'}
                    >
                      {row.label}
                    </Typography>
                    {row.values.map((value, valueIndex) => (
                      // biome-ignore lint/suspicious/noArrayIndexKey: This is unavoidable because all we have is an array of arrays.
                      <Typography key={valueIndex} flex={1}>
                        {value}
                      </Typography>
                    ))}
                  </Box>
                ))}
              </Box>
            ))}
          </Stack>
        ))}
      </Box>
    </WizardStep>
  );
}

export type SummaryItem = {
  sectionHeader: string;
  editPageNumber: number;
  rows: SummaryRow[];
};
export default ReviewStep;
