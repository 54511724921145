import { useState } from 'react';

type InputErrorsHookReturn = {
  /**
   * Each key represents an input field, and each value is either
   * a boolean (whether there is an error)
   * or a string (the specific error message).
   */
  inputErrors: Record<string, boolean | string>;

  /**
   * Sets the error value for the given key.
   * If no value is provided, it defaults to true (indicating an error).
   * If false is provided, it indicates that there is no error.
   */
  markInputError: (key: string, value?: string | false) => void;
};

function useInputErrors(): InputErrorsHookReturn {
  const [inputErrors, setInputErrors] = useState<
    Record<string, boolean | string>
  >({});

  const markInputError = (key: string, value?: string | false) =>
    setInputErrors((previousErrors) => ({
      ...previousErrors,
      [key]: value ?? true,
    }));

  return { inputErrors, markInputError };
}

export default useInputErrors;
