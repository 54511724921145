import type SnapshotCell from './SnapshotCell';
import type TagValueRule from './TagValueRule';
import type ValidityTag from './ValidityTag';

/**
 * Collection is expected to be extended to have navigation through rows, invalid cells
 */
class RowValidator {
  protected tagValueRules: TagValueRule[];

  constructor(tagValueRules: TagValueRule[]) {
    this.tagValueRules = tagValueRules;
  }

  validate(cell: SnapshotCell, defaultTag: ValidityTag): ValidityTag {
    for (const tagValueRule of this.tagValueRules) {
      if (tagValueRule.getValidityTag().getField() === cell.getColumn().field) {
        if (tagValueRule.isSuccess(cell)) {
          return defaultTag.setCellValidity(cell, false);
        }
        return defaultTag.setCellValidity(cell, true);
      }
    }

    return defaultTag;
  }
}

export default RowValidator;
