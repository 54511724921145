import type { UnmappedFileResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'unmappedfiles';

const apiEndpointFactory =
  constructApi<UnmappedFileResponse>(PATH).withTags('UNMAPPED_FILES');
const api = apiEndpointFactory.inject(() => ({
  deleteUnmappedFile: apiEndpointFactory.delete(),
  createUnmappedFile: apiEndpointFactory.create<FormData>(),
}));

export const { useDeleteUnmappedFileMutation, useCreateUnmappedFileMutation } =
  api;
