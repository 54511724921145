import { skipToken } from '@reduxjs/toolkit/query';

import WizardStep from 'shared/wizards/steps/WizardStep';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';

import { useGetInvoiceRecordsBySnapshotQuery } from 'shared/api/rtkq/invoicerecords';

import columns from '../columns';

type InvoiceReviewStepProps = { snapshotId?: string; onComplete: () => void };

function InvoiceReviewStep(props: InvoiceReviewStepProps) {
  const { onComplete, snapshotId } = props;
  const { currentData: allRecords } = useGetInvoiceRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  );

  return (
    <WizardStep
      description="Check that all information looks right before saving your invoice"
      header="Last step, make sure everything looks right."
      onNext={onComplete}
    >
      <SnapshotRecordsTable headers={columns} rows={allRecords} />
    </WizardStep>
  );
}

export default InvoiceReviewStep;
