function UnderConstructionClosedRecord() {
  return (
    <img
      height={300}
      src="https://www.lawrenceprospera.org/images/quintana/Page_Under_Construction.jpg"
    />
  );
}

export default UnderConstructionClosedRecord;
