import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { addDays } from 'date-fns/addDays';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';

import TrialSpendForecastGraph, {
  TRIAL_SPEND_HEIGHT,
} from 'forecasting/components/graphing/trial-spend-forecast-graph/TrialSpendForecastGraph';
import type { TrialSpendForecastDatum } from 'forecasting/components/graphing/trial-spend-forecast-graph/types';

import useFinancialForecastSummaryRows from 'forecasting/pages/forecasting/hooks/useFinancialForecastSummaryRows';
import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import { parseNullableInt } from 'utils';

import { getSum, hasKey } from './utils';

function TrialSpendGraph() {
  const { loading: forecastLoading, generatedForecast } = useForecast();
  const { loading: financialForecastLoading, rows } =
    useFinancialForecastSummaryRows();

  const graphData = useMemo(() => {
    const forecastedMonths = Object.keys(rows?.[0] ?? {}).filter((key) =>
      key.startsWith('forecasted_month_'),
    );
    const actualMonths = Object.keys(rows?.[0] ?? {}).filter((key) =>
      key.startsWith('actual_month_'),
    );

    const months = [...actualMonths, ...forecastedMonths].map((month) =>
      // add 5 days so we don't have to worry about timezones
      addDays(
        parse(
          month.replace('forecasted_month_', '').replace('actual_month_', ''),
          'LLL-yyyy',
          new Date(),
        ),
        5,
      ),
    );
    months.sort((monthA, monthB) => monthA.getTime() - monthB.getTime());

    const directFeesRows = rows?.filter(
      (row) => row.cost_category === 'DIRECT_FEES' && row.vendor_type === 'CRO',
    );
    const passthroughsRows = rows?.filter(
      (row) =>
        row.cost_category === 'PASS_THROUGH' && row.vendor_type === 'CRO',
    );
    const investigatorFeesRows = rows?.filter(
      (row) =>
        row.cost_category === 'INVESTIGATOR_FEES' && row.vendor_type === 'CRO',
    );
    const occRows = rows?.filter((row) => row.vendor_type === 'OCC');
    const enrollmentRowsByRegion =
      generatedForecast?.patients.filter(
        (row) => row.type === 'Cumulative enrollment',
      ) ?? [];

    const monthlySpend = months.map((month) => {
      const monthString = format(month, 'LLL-yyyy').toLowerCase();
      const actualMonthString = `actual_month_${monthString}`;
      const forecastedMonthString = `forecasted_month_${monthString}`;

      const forecastedEnrollment = getSum(
        enrollmentRowsByRegion,
        forecastedMonthString,
      );
      const actualEnrollment = getSum(
        enrollmentRowsByRegion,
        actualMonthString,
      );

      const hasActual = hasKey(rows, actualMonthString) ?? false;

      return {
        actual: hasActual,
        forecastedEnrollment,
        actualEnrollment,
        DIRECT_FEES: (
          (getSum(directFeesRows, forecastedMonthString) ?? 0) +
          (getSum(directFeesRows, actualMonthString) ?? 0)
        ).toString(),
        OCC: (
          (getSum(occRows, forecastedMonthString) ?? 0) +
          (getSum(occRows, actualMonthString) ?? 0)
        ).toString(),
        INVESTIGATOR_FEES: (
          (getSum(investigatorFeesRows, forecastedMonthString) ?? 0) +
          (getSum(investigatorFeesRows, actualMonthString) ?? 0)
        ).toString(),
        PASS_THROUGH: (
          (getSum(passthroughsRows, forecastedMonthString) ?? 0) +
          (getSum(passthroughsRows, actualMonthString) ?? 0)
        ).toString(),
        date: month.toISOString(),
      };
    });

    const returnValue: TrialSpendForecastDatum[] = [];
    for (let i = 0; i < monthlySpend.length; i++) {
      const previousMonth = i === 0 ? null : returnValue[i - 1];
      const currentMonth = monthlySpend[i];

      const actual = parseNullableInt(currentMonth.actualEnrollment);
      const forecasted = parseNullableInt(currentMonth.forecastedEnrollment);

      returnValue.push({
        ...currentMonth,
        forecastedEnrollment: currentMonth.actual
          ? null
          : forecasted.toString(),
        actualEnrollment:
          // if current month is forecasted, but last month was actuals, then we should show the forecasted value
          // for the current month so the lines "meet" instead of potentially having a gap
          currentMonth.actual
            ? actual.toString()
            : previousMonth?.actual
              ? forecasted.toString()
              : null,
      });
    }
    return returnValue;
  }, [generatedForecast?.patients, rows]);

  return (
    <ParentSize>
      {(parent) => {
        const graphWidth = Math.max(parent.width, 900);

        return forecastLoading || financialForecastLoading ? (
          <Skeleton
            height={TRIAL_SPEND_HEIGHT}
            variant="rectangular"
            width="100%"
          />
        ) : (
          <TrialSpendForecastGraph
            graphData={graphData}
            latestCloseDate={generatedForecast?.latest_close_date}
            width={graphWidth}
          />
        );
      }}
    </ParentSize>
  );
}

export default TrialSpendGraph;
