import { useSelector } from 'react-redux';

import withPeriodSpecificCustomWrapper from 'shared/lib/periods/withPeriodSpecificCustomWrapper';
import ActivityDriverCounts from 'accruals/pages/clinical-expenses/shared/activity-driver-counts/ActivityDriverCounts';
import ActivityDriverCountsBlobViewer from 'accruals/pages/clinical-expenses/shared/activity-driver-counts/ActivityDriverCountsBlobViewer';
import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { PeriodRecordBlobType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetOccCompletedActivityDriverCountQuery } from 'shared/api/rtkq/periods';

function OccActivityDriverCounts() {
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);

  const { currentData } = useGetOccCompletedActivityDriverCountQuery({
    trace_id: period.trace_id,
    otherParameter: trial.trace_id,
  });

  return <ActivityDriverCounts activityDriverValidation={currentData} />;
}

export default withPeriodSpecificCustomWrapper(
  OccActivityDriverCounts,
  ActivityDriverCountsBlobViewer,
  PeriodRecordBlobType.OCC_ACTIVITY_DRIVER_VALIDATION,
);
