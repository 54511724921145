import { useMemo } from 'react';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import CompanyFilesGrid from './grids/CompanyFilesGrid';

function CompanyFiles() {
  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <CompanyFilesGrid
          overlayNoRowsTemplate="No files."
          sx={useMemo(() => ({ height: '100%' }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default CompanyFiles;
