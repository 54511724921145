import { currencyFormatter } from 'formatters';

import type { TabularDataCellTextProps } from './TabularDataCellText';
import TabularDataCellText from './TabularDataCellText';

function TabularDataCellCurrency(props: TabularDataCellTextProps) {
  const { value, ...other } = props;
  return <TabularDataCellText {...other} value={currencyFormatter(value)} />;
}

export default TabularDataCellCurrency;
