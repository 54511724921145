import { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import EnrollmentTrialActivityGrid from 'accruals/pages/trial/grids/EnrollmentTrialActivityGrid';
import SitesTrialActivityGrid from 'accruals/pages/trial/grids/SitesTrialActivityGrid';

export default function TrialActivityCard() {
  // This should be 200px but the grid shows a scrollbar if the height is 200px.
  // However, this is ~16px bigger than it should be when it doesn't have a horizontal scroll to accommodate the scrollbar.
  const gridSx = useMemo(() => ({ height: '201px', mb: 5 }), []);
  const lastGridSx = useMemo(() => ({ height: '201px' }), []);

  return (
    <Grid xs={12} item>
      <DashboardCard title="Trial activity">
        <EnrollmentTrialActivityGrid sx={gridSx} />
        <SitesTrialActivityGrid sx={lastGridSx} />
      </DashboardCard>
    </Grid>
  );
}
