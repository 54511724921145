import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

import Chip from 'shared/ui/chip/Chip';

type Props = {
  contractVersionCount: number;
  currency_from: string;
  currency_to: string;
  expectedFxRateCount: number;
  fxRateCount: number;
  invoiceCount: number;
  labCount: number;
  poCount: number;
  siteCount: number;
};

function FxRateTaskStatus(props: Props) {
  const {
    currency_from,
    currency_to,
    contractVersionCount,
    expectedFxRateCount,
    fxRateCount,
    siteCount,
    labCount,
    poCount,
    invoiceCount,
  } = props;

  const countsMatch = expectedFxRateCount === fxRateCount;
  const color = countsMatch ? 'success' : 'error';
  const icon = countsMatch ? <CheckCircleIcon /> : <ErrorIcon />;
  const label = `${fxRateCount} / ${expectedFxRateCount} month(s)`;

  const labels = [];
  if (contractVersionCount > 0) {
    labels.push(`${contractVersionCount} contract(s)`);
  }
  if (siteCount > 0) {
    labels.push(`${siteCount} site(s)`);
  }
  if (labCount > 0) {
    labels.push(`${labCount} lab(s)`);
  }
  if (poCount > 0) {
    labels.push('PO listing');
  }
  if (invoiceCount > 0) {
    labels.push('Invoice listing');
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      sx={{ mb: 0.5 }}
    >
      <Box>
        <Typography component="span" variant="body2">
          {currency_from} → {currency_to}:
        </Typography>
        <Typography component="span" sx={{ pl: 0.5 }} variant="caption">
          {labels.join(', ')}
        </Typography>
      </Box>
      <Chip color={color} icon={icon} label={label} size="small" />
    </Box>
  );
}

export default FxRateTaskStatus;
