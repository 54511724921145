/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

This slice should be used in conjunction with the TrialRequiredLayout component
that knows how to handle when trial is null / undefined, so we can assume
that trial is always defined inside that element, greatly simplifying the codebase.

If you use this slice outside of the TrialRequiredLayout context, it will NOT handle 
the "missing trial" case correctly and is likely a sign you are doing something wrong 
(or need to move where TrialRequiredLayout is being used in the routes).

If you have any questions, please ask in #engineering
*/

import { useCallback } from 'react';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

/* eslint-disable import/no-restricted-paths -- trial is special as both forecasting and accruals use it */
import { useReinitializePeriod } from 'accruals/state/slices/periodSlice';
import { useReinitializePeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { useReinitializeForecast } from 'forecasting/state/slices/forecastSlice';
/* eslint-enable import/no-restricted-paths */
import type { TrialResponse } from 'shared/lib/types';
import type { RootState } from 'shared/state/store';

import baseApi from 'shared/api/baseApi';
import { getRegisteredTags } from 'shared/api/rtkq/constructApi';

import { getTrialSafeKeys } from './utils';

type State = { trial: TrialResponse; initialized: boolean };

const TRIAL_KEY = 'trial_trace_id';

export const missingTrial = null as unknown as TrialResponse;

const initialState: State = { trial: missingTrial, initialized: false };

const trialSlice = createSlice({
  name: 'trial',
  initialState,
  reducers: {
    changeTrial: (state, action: PayloadAction<TrialResponse>) => {
      state.trial = action.payload;
    },
    changeInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
  },
});

const { changeTrial, changeInitialized } = trialSlice.actions;

export const selectTrial = (state: RootState) => state.trial.trial;
export const selectTrialInitialized = (state: RootState) =>
  state.trial.initialized;

export const trialReducer = trialSlice.reducer;

export function useChangeTrial() {
  const dispatch = useDispatch();
  const reInitForecast = useReinitializeForecast();
  const reInitPeriod = useReinitializePeriod();
  const reInitPeriodVersion = useReinitializePeriodVersion();

  return useCallback(
    (trial: TrialResponse | null, isInit = false) => {
      if (trial === null) {
        localStorage.removeItem(TRIAL_KEY);
        dispatch(changeTrial(missingTrial));
      } else {
        localStorage.setItem(TRIAL_KEY, trial.trace_id);
        dispatch(changeTrial(trial));
      }

      // clear all existing RTKQ state to guarantee we're not using stale data, although we
      // don't clear "safe" keys that cannot change, so it reduces loading states
      //
      // additionally, re-initialize the things "inside" trial so everything updates correctly
      if (!isInit) {
        reInitForecast();
        reInitPeriod();
        reInitPeriodVersion();

        const tagsToClear = getRegisteredTags().filter(
          (tag) => !getTrialSafeKeys().includes(tag),
        );
        dispatch(baseApi.util.invalidateTags(tagsToClear));
      }
    },
    [dispatch, reInitForecast, reInitPeriod, reInitPeriodVersion],
  );
}

export function useInitializeTrial() {
  const change = useChangeTrial();
  const dispatch = useDispatch();

  return useCallback(
    (trials: TrialResponse[] | undefined) => {
      dispatch(changeInitialized(true));

      // if we have no returned trials, ensure we update state in case what was present was for a company they don't have access to
      let trial = missingTrial;
      if (trials?.length) {
        const previousTrialId = localStorage.getItem(TRIAL_KEY) ?? null;
        const previousTrial = trials.find(
          (comp) => comp.trace_id === previousTrialId,
        );
        // If we didn't find a relevant selected trial in local storage, arbitrarily default to the first one in the list.
        trial = previousTrial ?? trials[0];
      }

      change(trial, true);
    },
    [change, dispatch],
  );
}

export function useReinitializeTrial() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(changeInitialized(false));
    dispatch(changeTrial(missingTrial));
  }, [dispatch]);
}
