import type { MappedColumn } from 'shared/lib/column-mapper/types';

const columns: MappedColumn[] = [
  { label: 'Cost Category', value: 'cost_category', required: true },
  { label: 'Region', value: 'region_name', required: false },
  { label: 'Service Category', value: 'service_category', required: true },
  { label: 'Activity Code', value: 'activity_code', required: true },
  {
    label: 'Activity Description',
    value: 'activity_description',
    required: true,
  },
  { label: 'Unit Type', value: 'pizza_unit_type', required: true },
  { label: 'No. of Units', value: 'unit_count', required: true },
  { label: 'Unit Price', value: 'pizza_unit_price', required: true },
  { label: 'Contract Value', value: 'total_price', required: true },
];
export default columns;
