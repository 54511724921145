import Add from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

import IconButton from 'shared/ui/icon-button/IconButton';

import styles from './IconButtonDemo.module.scss';

function IconButtonDemo() {
  return (
    <>
      <div className={styles.type}>
        <Typography variant="h6">Primary</Typography>
        <div className={styles.buttons}>
          <IconButton size="large" variant="primary">
            <Add />
          </IconButton>
          <IconButton size="medium" variant="primary">
            <Add />
          </IconButton>
          <IconButton size="small" variant="primary">
            <Add />
          </IconButton>
        </div>
      </div>

      <div className={styles.type}>
        <Typography variant="h6">Secondary</Typography>
        <div className={styles.buttons}>
          <IconButton size="large" variant="secondary">
            <Add />
          </IconButton>
          <IconButton size="medium" variant="secondary">
            <Add />
          </IconButton>
          <IconButton size="small" variant="secondary">
            <Add />
          </IconButton>
        </div>
      </div>

      <div className={styles.type}>
        <Typography variant="h6">Tertiary</Typography>
        <div className={styles.buttons}>
          <IconButton size="large" variant="tertiary">
            <Add />
          </IconButton>
          <IconButton size="medium" variant="tertiary">
            <Add />
          </IconButton>
          <IconButton size="small" variant="tertiary">
            <Add />
          </IconButton>
        </div>
      </div>

      <div className={styles.type}>
        <Typography variant="h6">Disabled</Typography>
        <div className={styles.buttons}>
          <IconButton size="large" variant="primary" disabled>
            <Add />
          </IconButton>
          <IconButton size="medium" variant="secondary" disabled>
            <Add />
          </IconButton>
          <IconButton size="small" variant="tertiary" disabled>
            <Add />
          </IconButton>
        </div>
      </div>
    </>
  );
}

export default IconButtonDemo;
