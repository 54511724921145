import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { addDays } from 'date-fns/addDays';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';

import RateVsEnrollmentGraph from 'forecasting/components/graphing/rate-vs-enrollment-graph/RateVsEnrollmentGraph';
import type { RunRateVsEnrollmentDatum } from 'forecasting/components/graphing/rate-vs-enrollment-graph/types';
import { TRIAL_SPEND_HEIGHT } from 'forecasting/components/graphing/trial-spend-forecast-graph/TrialSpendForecastGraph';

import useFinancialForecastSummaryRows from 'forecasting/pages/forecasting/hooks/useFinancialForecastSummaryRows';
import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import { addNullableFloats, parseNullableInt } from 'utils';

import { getSum, hasKey } from './utils';

function RunRateGraph() {
  const { loading: forecastLoading, generatedForecast } = useForecast();
  const { loading: financialForecastLoading, rows } =
    useFinancialForecastSummaryRows();

  const graphData = useMemo(() => {
    const forecastedMonths = Object.keys(rows?.[0] ?? {}).filter((key) =>
      key.startsWith('forecasted_month_'),
    );
    const actualMonths = Object.keys(rows?.[0] ?? {}).filter((key) =>
      key.startsWith('actual_month_'),
    );

    const months = [...actualMonths, ...forecastedMonths].map((month) =>
      // add 5 days so we don't have to worry about timezones
      addDays(
        parse(
          month.replace('forecasted_month_', '').replace('actual_month_', ''),
          'LLL-yyyy',
          new Date(),
        ),
        5,
      ),
    );
    months.sort((monthA, monthB) => monthA.getTime() - monthB.getTime());

    const directFeesRows = rows?.filter(
      (row) => row.cost_category === 'DIRECT_FEES' && row.vendor_type === 'CRO',
    );
    const passthroughsRows = rows?.filter(
      (row) =>
        row.cost_category === 'PASS_THROUGH' && row.vendor_type === 'CRO',
    );
    const investigatorFeesRows = rows?.filter(
      (row) =>
        row.cost_category === 'INVESTIGATOR_FEES' && row.vendor_type === 'CRO',
    );
    const occRows = rows?.filter((row) => row.vendor_type === 'OCC');
    const enrollmentRowsByRegion =
      generatedForecast?.patients.filter(
        (row) => row.type === 'Cumulative enrollment',
      ) ?? [];

    const monthlySpend = months.map((month) => {
      const monthString = format(month, 'LLL-yyyy').toLowerCase();
      const actualMonthString = `actual_month_${monthString}`;
      const forecastedMonthString = `forecasted_month_${monthString}`;

      const forecastedEnrollment = getSum(
        enrollmentRowsByRegion,
        forecastedMonthString,
      );
      const actualEnrollment = getSum(
        enrollmentRowsByRegion,
        actualMonthString,
      );
      const actualDirectFeesSpend = getSum(directFeesRows, actualMonthString);
      const actualInvestigatorFeesSpend = getSum(
        investigatorFeesRows,
        actualMonthString,
      );
      const actualPassthroughsSpend = getSum(
        passthroughsRows,
        actualMonthString,
      );
      const actualOccSpend = getSum(occRows, actualMonthString);

      const forecastedDirectFeesSpend = getSum(
        directFeesRows,
        forecastedMonthString,
      );
      const forecastedInvestigatorFeesSpend = getSum(
        investigatorFeesRows,
        forecastedMonthString,
      );
      const forecastedPassthroughsSpend = getSum(
        passthroughsRows,
        forecastedMonthString,
      );
      const forecastedOccSpend = getSum(occRows, forecastedMonthString);

      const hasActual = hasKey(rows, actualMonthString) ?? false;

      return {
        forecastedEnrollment:
          forecastedEnrollment == null ? null : forecastedEnrollment.toString(),
        actualEnrollment:
          actualEnrollment == null ? null : actualEnrollment.toString(),
        actual: hasActual,
        actualSpend: hasActual
          ? (
              (actualDirectFeesSpend ?? 0) +
              (actualInvestigatorFeesSpend ?? 0) +
              (actualPassthroughsSpend ?? 0) +
              (actualOccSpend ?? 0)
            ).toString()
          : null,
        forecastedSpend: hasActual
          ? null
          : (
              (forecastedDirectFeesSpend ?? 0) +
              (forecastedInvestigatorFeesSpend ?? 0) +
              (forecastedPassthroughsSpend ?? 0) +
              (forecastedOccSpend ?? 0)
            ).toString(),
        date: month.toISOString(),
      };
    });

    const returnValue: RunRateVsEnrollmentDatum[] = [];
    for (let i = 0; i < monthlySpend.length; i++) {
      if (i === 0) {
        returnValue.push(monthlySpend[0]);
      } else {
        const previousMonth = returnValue[i - 1];
        const currentMonth = monthlySpend[i];

        const actualEnrollment = parseNullableInt(
          currentMonth.actualEnrollment,
        );
        const actualSpend = addNullableFloats(
          currentMonth.actualSpend,
          previousMonth.actual
            ? previousMonth.actualSpend
            : previousMonth.forecastedSpend,
        );
        const forecastedEnrollment = parseNullableInt(
          currentMonth.forecastedEnrollment,
        );
        const forecastedSpend = addNullableFloats(
          currentMonth.forecastedSpend,
          previousMonth.actual
            ? previousMonth.actualSpend
            : previousMonth.forecastedSpend,
        );

        returnValue.push({
          ...currentMonth,
          forecastedSpend: currentMonth.actual
            ? null
            : forecastedSpend.toString(),
          forecastedEnrollment: currentMonth.actual
            ? null
            : forecastedEnrollment.toString(),
          actualSpend: currentMonth.actual ? actualSpend.toString() : null,
          // if current month is forecasted, but last month was actuals, then we should show the forecasted value
          // for the current month so the lines "meet" instead of potentially having a gap
          actualEnrollment: currentMonth.actual
            ? actualEnrollment.toString()
            : previousMonth.actual
              ? forecastedEnrollment.toString()
              : null,
        });
      }
    }

    return returnValue;
  }, [generatedForecast?.patients, rows]);

  return (
    <ParentSize>
      {(parent) => {
        const graphWidth = Math.max(parent.width, 900);

        return forecastLoading || financialForecastLoading ? (
          <Skeleton
            height={TRIAL_SPEND_HEIGHT}
            variant="rectangular"
            width="100%"
          />
        ) : (
          <RateVsEnrollmentGraph
            graphData={graphData}
            latestCloseDate={generatedForecast?.latest_close_date}
            width={graphWidth}
          />
        );
      }}
    </ParentSize>
  );
}

export default RunRateGraph;
