import type { EditableCallbackParams } from '@ag-grid-community/core';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

import {
  EM_DASH_PLACEHOLDER,
  valueIsMultipleStringArray,
} from 'shared/components/ag-grid/helpers/shared';
import type { CustomCellRendererParams } from 'shared/components/ag-grid/types';

import { currencyFormatter, percentFormatter } from 'formatters';
import { formatCustomDate } from 'shared/helpers/helpers';

import { getIcon, getTextColor } from './helpers';

function AgGridCustomCellRenderer(params: CustomCellRendererParams) {
  const {
    startIcons,

    endIcons,
    startIcon = startIcons?.[0],
    colDef,
    data,
    node,
    endIcon = endIcons?.[0],
    newRowText,
    tooltipMessage,
    formatter,
    format,
    value,
    valueFormatted,
    currencyKey,
    fxRateKey,
    currencyFooterStyle,
    placeholder,
    severity,
    useEmDash,
    useEmDashInGroup,
    useEmDashInTotal,
    forecast = false,
  } = params;

  const materialTheme = useTheme();

  const isDate = formatter === 'date';
  const isPercent = formatter === 'percent';
  const isPercentComplete = formatter === 'percent_complete';
  const isCurrency = formatter === 'currency';
  const isNativeCurrency = formatter === 'native_currency';
  const isMultipleVisits = formatter === 'multiple_visits';
  const currencyFormatOptions: Intl.NumberFormatOptions = {
    style: node.footer ? currencyFooterStyle : 'currency',
    minimumFractionDigits: 2,
  };

  const dataCurrency = currencyKey ? data?.[currencyKey] : undefined;
  let finalCurrency = dataCurrency ?? 'USD';
  const valueIsString =
    value !== undefined && value !== null && typeof value === 'string';
  const valueIsNumber =
    value !== undefined &&
    value !== null &&
    typeof value !== 'string' &&
    !Number.isNaN(value);
  let finalValue =
    colDef?.refData?.[value] ??
    ((valueFormatted ?? '').length > 0 ? valueFormatted : value);

  if (isPercent || (isPercentComplete && data?.unitType === '% Complete')) {
    finalValue = percentFormatter(value);
  } else if (isDate && valueIsString && format !== undefined) {
    // users can copy and paste any value they want, so ignore "bad" values
    try {
      finalValue = formatCustomDate(value, format);
    } catch {
      finalValue = value;
    }
  } else if (isCurrency && valueIsNumber) {
    if (node.group && currencyKey && node.allLeafChildren) {
      // For aggregates, get the currency code from the first row in the group
      finalCurrency = node.allLeafChildren[0].data?.[currencyKey] ?? 'USD';
    }
    const fxRate = fxRateKey ? (data?.[fxRateKey] ?? 1) : 1;
    finalValue = currencyFormatter(value * fxRate, {
      ...currencyFormatOptions,
      currency: finalCurrency,
    });
  } else if (isNativeCurrency && valueIsNumber) {
    if (dataCurrency === undefined && node.footer) {
      if (node.level === 2) {
        finalCurrency =
          currencyKey && node.parent?.allLeafChildren
            ? (node.parent.allLeafChildren[0]?.data?.[currencyKey] ?? 'USD')
            : 'USD';
      } else if (node.level <= 1) {
        finalCurrency =
          currencyKey && node.allLeafChildren
            ? (node.allLeafChildren[0]?.data?.[currencyKey] ?? 'USD')
            : 'USD';
      }
    }
    finalValue = currencyFormatter(value, {
      ...currencyFormatOptions,
      currency: finalCurrency,
    });
  } else if (isMultipleVisits && valueIsMultipleStringArray(value)) {
    finalValue = `${value.length} ${colDef?.field?.startsWith('procedure_') ? ' procedures' : ' visits'} `;
  } else if (Array.isArray(value)) {
    finalValue = value.join(', ');
  }

  const isEditable =
    typeof colDef?.editable === 'function'
      ? colDef.editable(params as EditableCallbackParams) // the params are "close enough" to the same thing that we can treat them the same as far as TS is concerned
      : colDef?.editable === true;

  if (
    !isEditable &&
    !placeholder &&
    (useEmDash || useEmDashInGroup || useEmDashInTotal)
  ) {
    const isZeroCurrency =
      finalValue ===
      currencyFormatter(0, {
        ...currencyFormatOptions,
        currency: finalCurrency,
      });
    const isEmptyValue = !value || isZeroCurrency;
    const isGroup = node.group;
    const isTotal = node.footer;

    let shouldUseEmDash = false;
    if (useEmDashInTotal && isTotal && isEmptyValue) {
      shouldUseEmDash = true;
    } else if (useEmDashInGroup && isGroup && isEmptyValue) {
      shouldUseEmDash = true;
    } else if (useEmDash && isEmptyValue && !isGroup && !isTotal) {
      shouldUseEmDash = true;
    }

    if (shouldUseEmDash) {
      finalValue = EM_DASH_PLACEHOLDER;
    }
  }

  const tooltipTitle =
    isMultipleVisits && valueIsMultipleStringArray(value)
      ? value.map((item) => <div key={item}>{item}</div>)
      : tooltipMessage;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row',
        height: '100%',
      }}
    >
      {getIcon(
        severity,
        materialTheme,
        node,
        startIcon,
        finalValue,
        newRowText,
      )}
      <Box
        sx={{
          flex: 1,
          ml: 1,
          color: getTextColor(materialTheme, severity, value, forecast, false),
        }}
      >
        {tooltipTitle !== undefined ? (
          <Tooltip
            title={tooltipTitle}
            PopperProps={{
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => theme.palette.common.black,
                },
              },
            }}
          >
            <span>{finalValue ?? placeholder}</span>
          </Tooltip>
        ) : (
          (finalValue ?? placeholder)
        )}
      </Box>
      {getIcon(severity, materialTheme, node, endIcon, finalValue, newRowText)}
    </Box>
  );
}

export default AgGridCustomCellRenderer;
