import { useMemo } from 'react';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import PurchaseOrdersHeader from './PurchaseOrdersHeader';
import PurchaseOrdersGrid from './grids/PurchaseOrdersGrid';

function PurchaseOrders() {
  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <PurchaseOrdersHeader />
        <PurchaseOrdersGrid
          overlayNoRowsTemplate="No purchase orders."
          sx={useMemo(() => ({ height: '100%' }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default PurchaseOrders;
