import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import type { CsvErrors } from '../types';

type Props = { csvErrors?: CsvErrors; error?: FetchBaseQueryError | null };

type LabelCount = { label: string; count: number };
type ErrorLabelCount = Record<string, LabelCount>;
type ErrorsList = Record<string, ErrorLabelCount>;

function ServerResponseErrorTooltip(props: Props) {
  const { error, csvErrors } = props;

  const errorString = typeof error?.status === 'number' ? '' : error?.error;

  const csvErrorsSummary = Object.values(csvErrors ?? {}).reduce<ErrorsList>(
    (acc, el) => {
      const keys = Object.keys(el);

      for (const key of keys) {
        if (key === '0') {
          continue;
        }

        const [label] = el[key];

        if (typeof acc[key] === 'undefined') {
          acc[key] = {};
        }

        if (typeof acc[key][label] === 'undefined') {
          acc[key][label] = { label, count: 0 };
        }

        acc[key][label].count += 1;
      }

      return acc;
    },
    {},
  );

  return (
    <Tooltip
      placement="left-start"
      PopperProps={{
        sx: {
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'common.white',
            border: '1px solid red',
            color: 'common.black',
            maxHeight: '60vh',
            overflow: 'auto',
          },
        },
      }}
      title={
        <List dense>
          <ListItem>
            <ListItemText primary="Error" secondary={error?.status} />
          </ListItem>
          {!!errorString && (
            <ListItem>
              <ListItemText primary="Text" secondary={errorString} />
            </ListItem>
          )}
          <ListItem>
            <ListItemText
              primary="CSV Errors summary"
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <List dense>
                  {Object.entries(csvErrorsSummary).map(([key, info]) => (
                    <ListItem key={key} disableGutters>
                      <ListItemText
                        primary={`${key}: ${Object.values(info)[0].label}`}
                        secondary={`Flags: ${Object.values(info)[0].count}`}
                      />
                    </ListItem>
                  ))}
                </List>
              }
            />
          </ListItem>
        </List>
      }
    >
      <InfoIcon color="error" fontSize="small" />
    </Tooltip>
  );
}

export default ServerResponseErrorTooltip;
