import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { AccrualsGridRow, BsFluxRow } from 'shared/lib/types';

export default function useBsFluxGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<AccrualsGridRow | BsFluxRow> {
  return useMemo(
    () => ({ grandTotalRow: 'bottom', suppressAggFuncInHeader: true }),
    [],
  );
}
