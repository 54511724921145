import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

import Icon from './svg/MenuDown.svg?react';

function MenuDown(props: SvgIconProps) {
  return <SvgIcon component={Icon} inheritViewBox {...props} />;
}

export default MenuDown;
