import Box from '@mui/material/Box';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';

import OccContractVersionFileContents from './OccContractVersionFileContents';

function OccContractFilesSection() {
  const { contractVersionPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();

  return (
    <DashboardCard title="Contract Files">
      <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
        <OccContractVersionFileContents
          parentMenuItem={contractVersionPeriodMenuItemTraceId}
        />
      </Box>
    </DashboardCard>
  );
}

export default OccContractFilesSection;
