import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import Wizard from 'shared/lib/wizard/Wizard';

import AddSite from './steps/AddSite';

type Props = { title?: string; onClose: () => void };

function AddSiteWizard(props: Props) {
  const { onClose, title } = props;
  return (
    <FullWidthDialog open>
      <Wizard title={title ?? 'New site'} onClose={onClose}>
        <AddSite />
      </Wizard>
    </FullWidthDialog>
  );
}

export default AddSiteWizard;
