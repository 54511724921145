import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import ControlListingTasksGrid from './ControlListingTasksGrid';

function ControlListingTasks() {
  return (
    <DashboardCard sx={{ borderRadius: 1 }}>
      <Box mb={3}>
        <Typography variant="subtitle1">Financial close checklist</Typography>
      </Box>
      <ControlListingTasksGrid />
    </DashboardCard>
  );
}

export default ControlListingTasks;
