import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetContractContainersByTrialQuery } from 'shared/api/rtkq/contractcontainers';

function useAllPoNumbersFromTrial(
  contractContainerTraceId: string | undefined,
) {
  const trial = useSelector(selectTrial);
  const { currentData: allContractContainersFromTrial } =
    useGetContractContainersByTrialQuery(trial.trace_id);

  return useMemo(
    () =>
      allContractContainersFromTrial
        ?.filter((container) => container.trace_id !== contractContainerTraceId)
        ?.flatMap((container) => container.po_numbers) ?? [],
    [allContractContainersFromTrial, contractContainerTraceId],
  );
}

export default useAllPoNumbersFromTrial;
