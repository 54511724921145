import { useState } from 'react';

import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import SidebarClickableItem from 'shared/components/sidebar/SidebarClickableItem';

import {
  openCreateForecastFolderModal,
  openCreateForecastModal,
} from 'shared/state/slices/modalsSlice';
import { MENU_WIDTH } from 'shared/lib/sidebar/SidebarContainer';

const PERIOD_DROPDOWN_HEIGHT = 48;

type Props = { collapsed: boolean };

function ForecastCreateButton(props: Props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { collapsed } = props;

  const handleOpenSelector = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const handleOpenForecastModal = () => {
    handleCloseSelector();
    dispatch(openCreateForecastModal());
  };
  const handleOpenFolderModal = () => {
    handleCloseSelector();
    dispatch(openCreateForecastFolderModal());
  };

  return (
    <>
      <SidebarClickableItem
        collapsed={collapsed}
        isActive={anchorEl !== null}
        testId="ForecastNew"
        collapsedContent={
          <Typography color="text.secondary" variant="body2">
            NEW
          </Typography>
        }
        content={
          <>
            <Add sx={{ fontSize: '1rem' }} /> New
          </>
        }
        onClick={handleOpenSelector}
      />
      <Popover
        anchorEl={anchorEl}
        marginThreshold={0}
        open={anchorEl !== null}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{
          vertical: collapsed ? 0 : PERIOD_DROPDOWN_HEIGHT,
          horizontal: collapsed ? 'right' : 'left',
        }}
        onClose={handleCloseSelector}
      >
        <Box sx={{ display: 'flex', flexFlow: 'column', width: MENU_WIDTH }}>
          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
            <ButtonOptions
              testId="ForecastNewFolder"
              title="New folder"
              onClick={handleOpenFolderModal}
            />
            <ButtonOptions
              testId="ForecastNewForecast"
              title="New forecast"
              onClick={handleOpenForecastModal}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
}

function ButtonOptions({
  title,
  onClick,
  testId,
}: { testId?: string; title: string; onClick?: () => void }) {
  return (
    <Box
      key={`forecast-${title}`}
      data-testid={testId}
      sx={{
        height: 40,
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        px: 2,
        py: 1,
        '&:hover': { backgroundColor: 'rgba(75,100,218,0.1)' },
      }}
      onClick={onClick}
    >
      <Typography component="div" variant="body1">
        {title}
      </Typography>
    </Box>
  );
}

export default ForecastCreateButton;
