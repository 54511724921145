import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import type { ScaleOrdinal } from 'd3-scale';

import { currencyFormatter, getDateYearAndMonth } from 'formatters';
import { humanizeCostCategory } from 'shared/helpers/helpers';
import GraphTooltipContainer from 'shared/lib/graphing/graph-tooltip/GraphTooltipContainer';
import GraphTooltipRow from 'shared/lib/graphing/graph-tooltip/GraphTooltipRow';
import LegendLine from 'shared/lib/graphing/shared/LegendLine';

import type { TrialSpendForecastDatum } from './types';
type BarKey = Pick<
  TrialSpendForecastDatum,
  'DIRECT_FEES' | 'INVESTIGATOR_FEES' | 'OCC' | 'PASS_THROUGH'
>;

type Props = {
  actualEnrollment: string | undefined;
  actualEnrollmentText: string | undefined;
  forecastedEnrollment: string | undefined;
  forecastedEnrollmentText: string | undefined;
  innerHeight: number;
  legendColorScale: ScaleOrdinal<string, string>;
  marginTop: number;
  orderOfData?: string[];
  tooltip: Partial<UseTooltipParams<TrialSpendForecastDatum>>;
};

function TrialSpendForecastGraphTooltip(props: Props) {
  const {
    tooltip,
    legendColorScale,
    orderOfData,
    marginTop,
    innerHeight,
    actualEnrollment,
    actualEnrollmentText,
    forecastedEnrollment,
    forecastedEnrollmentText,
  } = props;

  const { tooltipData } = tooltip;
  const actualEnrollmentValue =
    tooltipData?.[actualEnrollment as keyof TrialSpendForecastDatum];
  const forecastedEnrollmentValue =
    tooltipData?.[forecastedEnrollment as keyof TrialSpendForecastDatum];

  const total =
    tooltipData &&
    orderOfData?.reduce(
      (total, label) =>
        total + Number.parseFloat(tooltipData[label as keyof BarKey]),
      0,
    );

  return (
    <GraphTooltipContainer
      innerHeight={innerHeight}
      marginTop={marginTop}
      tooltip={tooltip}
    >
      {tooltipData && (
        <Box sx={{ m: 0, p: 1, width: 300 }}>
          <Box display="flex">
            <Typography flex={1} variant="body2">
              {getDateYearAndMonth(tooltipData.date)}
            </Typography>
            <Typography variant="body2">Cost</Typography>
          </Box>
          {orderOfData?.map((dataKey) => {
            const label = humanizeCostCategory(dataKey);
            const value = tooltipData[dataKey as keyof TrialSpendForecastDatum];
            return typeof value === 'string' ? (
              <GraphTooltipRow
                key={dataKey}
                label={label}
                legendColorScale={legendColorScale}
                value={value}
              />
            ) : null;
          })}
          <Box display="flex">
            <Typography flex={1} variant="body2">
              Total
            </Typography>
            <Typography variant="body2">{currencyFormatter(total)}</Typography>
          </Box>
          {!!actualEnrollmentValue && !!actualEnrollmentText && (
            <>
              <Divider sx={{ my: 1 }} />
              <Box display="flex">
                <Box alignItems="center" display="flex" flex={1}>
                  <Box
                    sx={{
                      width: 18,
                      height: 18,
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      fill="none"
                      height="18"
                      viewBox="0 0 18 18"
                      width="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <LegendLine
                        stroke={legendColorScale(actualEnrollmentText)}
                      />
                    </svg>
                  </Box>
                  <Typography ml={1}>{actualEnrollmentText}</Typography>
                </Box>
                <Typography>{actualEnrollmentValue}</Typography>
              </Box>
            </>
          )}
          {!!forecastedEnrollmentValue && !!forecastedEnrollmentText && (
            <>
              <Divider sx={{ my: 1 }} />
              <Box display="flex">
                <Box alignItems="center" display="flex" flex={1}>
                  <Box
                    sx={{
                      width: 18,
                      height: 18,
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      fill="none"
                      height="18"
                      viewBox="0 0 18 18"
                      width="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <LegendLine
                        stroke={legendColorScale(forecastedEnrollmentText)}
                        dashed
                      />
                    </svg>
                  </Box>
                  <Typography ml={1}>{forecastedEnrollmentText}</Typography>
                </Box>
                <Typography>{forecastedEnrollmentValue}</Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </GraphTooltipContainer>
  );
}

export default TrialSpendForecastGraphTooltip;
