import type { ReactElement } from 'react';

import * as Sentry from '@sentry/react';

import useRecordBlobDataLoader from 'accruals/components/periods/hooks/useRecordBlobDataLoader';

import type { ChecklistSection } from 'accruals/pages/financial-close/accounting-checklist/AccountingChecklistGrid';
import ChecklistSectionsViewer from 'accruals/pages/financial-close/accounting-checklist/ChecklistSectionsViewer';
import { getTaskCommentLocation } from 'accruals/pages/financial-close/accounting-checklist/tasks-status/useChecklistSectionData';
import type { PeriodRecordBlobType, TraceId } from 'shared/lib/types';

type Props = {
  parentMenuItem?: TraceId;
  savedObjectType: PeriodRecordBlobType;
};

export default function PeriodChecklistBlobViewer(props: Props): ReactElement {
  const { savedObjectType, parentMenuItem } = props;
  const blobData = useRecordBlobDataLoader<ChecklistSection[]>(
    savedObjectType,
    parentMenuItem,
  );

  const sections = blobData?.map((group) => {
    // fix tasks which were added in closed period before comments
    const tasks = group.tasks.map((task) => {
      let { comments } = task;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- // TODO: The type says not possible. If it is, we'll get a sentry alert. Delete after a week if no alerts.
      if (comments === undefined) {
        Sentry.captureMessage(
          "This shouldn't be possible. The type definition is not correct.",
        );
        comments = {
          location: getTaskCommentLocation(group.group),
          locationSlug: task.task.name,
        };
      }

      return { ...task, comments };
    });

    return { ...group, tasks };
  });

  return <ChecklistSectionsViewer sections={sections} />;
}
