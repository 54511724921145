import type { HTMLAttributes } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import { autocompleteClasses } from 'shared/ui/autocomplete/Autocomplete';
import Chip from 'shared/ui/chip/Chip';

import type { DropdownOption } from 'shared/lib/types';
import { selectCohort } from 'shared/state/slices/visitSchedulesSlice';

type Props = {
  option: DropdownOption<string | undefined>;
  renderProps: HTMLAttributes<HTMLLIElement>;
};

function EdcMappingOption(props: Props) {
  const { option, renderProps } = props;
  const { className, ...restProps } = renderProps;
  const currentCohort = useSelector(selectCohort);
  const isMapped = currentCohort?.patientAssessments.some(
    (item) => item.mappedName === option.value,
  );

  return (
    <Box
      component="li"
      display="flex"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
        pl: (theme) => `${theme.spacing(1)} !important`,
        [`&.${autocompleteClasses.option}`]: {
          justifyContent: 'space-between',
        },
      }}
      {...restProps}
      className={className}
    >
      <Typography>{option.label}</Typography>
      <Chip
        color={isMapped ? 'success' : 'default'}
        icon={isMapped ? <DoneIcon /> : undefined}
        label={isMapped ? 'Mapped' : 'Not mapped'}
        size="small"
      />
    </Box>
  );
}

export default EdcMappingOption;
