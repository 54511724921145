import { useMemo } from 'react';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import OccVersionHistoryGrid from './grids/OccVersionHistoryGrid';

function OccContractVersionHistorySection() {
  return (
    <DashboardCard title="Version History">
      <OccVersionHistoryGrid
        overlayNoRowsTemplate="No versions available."
        sx={useMemo(() => ({ height: 400 }), [])}
      />
    </DashboardCard>
  );
}

export default OccContractVersionHistorySection;
