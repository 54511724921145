function UnderConstructionClosedGrid() {
  return (
    <img
      height={400}
      src="http://www.flashbackmartialarts.com/wp-content/uploads/2016/11/underconstruction.jpg"
    />
  );
}

export default UnderConstructionClosedGrid;
