import classes from 'shared/lib/column-mapper/ColumnMapperTable.module.scss';
import type { MappedColumn } from 'shared/lib/column-mapper/types';
import type { SnapshotRecordType } from 'shared/lib/types';

import AgGridValidationTable from './AgGridValidationTable';
import type { ValidationError } from './ValidationSidebar';
import ValidationSidebar from './ValidationSidebar';
import type { HandleAcceptForFieldValueMapper } from './validation-handlers/FieldValueMapper';
import type { HandleAcceptForRollupRow } from './validation-handlers/RollupRowHandler';

type Props = {
  allSiteRecordOptions?: string[];
  handleAcceptForFieldValueMapper?: HandleAcceptForFieldValueMapper;
  handleAcceptForRollupRows?: HandleAcceptForRollupRow;
  headers: MappedColumn[];
  rows?: SnapshotRecordType[];
  selectedErrorIndex?: number;
  setSelectedErrorIndex?: (index: number) => void;
  showValidationSidebar?: boolean;
  validationErrors?: ValidationError[];
};

function SnapshotRecordsTable(props: Props) {
  const {
    rows,
    validationErrors,
    selectedErrorIndex,
    setSelectedErrorIndex,
    headers,
    allSiteRecordOptions,
    handleAcceptForFieldValueMapper,
    handleAcceptForRollupRows,
    showValidationSidebar = false,
  } = props;

  const rowsToFix: Record<string, number> = {};
  validationErrors?.forEach((error, errorIndex) => {
    if (errorIndex === selectedErrorIndex) {
      return;
    }
    rowsToFix[String(error.row)] = errorIndex;
  });

  function onClickErrorRow(rowIndex: number) {
    setSelectedErrorIndex?.(rowIndex);
  }

  return (
    <div className={classes.ValidateAndCheck}>
      <AgGridValidationTable
        fullWidth={!showValidationSidebar}
        headers={headers}
        rows={rows}
        validationErrors={validationErrors}
        onClickErrorRow={onClickErrorRow}
      />
      {showValidationSidebar && (
        <ValidationSidebar
          allSiteRecordOptions={allSiteRecordOptions}
          handleAcceptForFieldValueMapper={handleAcceptForFieldValueMapper}
          handleAcceptForRollupRow={handleAcceptForRollupRows}
          rows={rows}
          selectedErrorIndex={selectedErrorIndex}
          setSelectedErrorIndex={setSelectedErrorIndex}
          validationErrors={validationErrors ?? []}
        />
      )}
    </div>
  );
}

export default SnapshotRecordsTable;
