import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import type { ContractVersionResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

export default function DeleteGlAccountDialog(props: {
  accountNumber: null;
  contracts: ContractVersionResponse[] | undefined;
  isDeleteConfirmationModalOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}) {
  const {
    onClose,
    isDeleteConfirmationModalOpen,
    contracts,
    accountNumber,
    onDelete,
  } = props;

  const currentCompany = useSelector(selectCompany);
  const { currentData: trials } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );

  return (
    <Dialog open={isDeleteConfirmationModalOpen}>
      {contracts?.length ? (
        <>
          <DialogTitle>
            You can’t delete this G/L account because it is being used for one
            or more contracts.
          </DialogTitle>
          <DialogContent>
            These contracts are using this G/L account:
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              {contracts.map((contract) => (
                <ListItem key={contract.trace_id} sx={{ display: 'list-item' }}>
                  Trial{' '}
                  {trials?.find(
                    (trial) => trial.trace_id === contract.trial_trace_id,
                  )?.study_id ?? contract.trial_trace_id}
                  , {contract.po_number} {contract.vendor_name}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button testId="close" variant="contained" onClick={onClose}>
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            Are you sure you want to delete the account {accountNumber}?
          </DialogTitle>
          <DialogContent>This action can&apos;t be undone.</DialogContent>
          <DialogActions>
            <Button testId="cancel" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button testId="delete" variant="contained" onClick={onDelete}>
              Delete
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
