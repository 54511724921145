import type { MouseEvent } from 'react';
import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import IconButton from 'shared/ui/icon-button/IconButton';

function UserDropdown() {
  const { user, logout } = useAuth0();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16', mr: 4 }}>
        <IconButton data-testid="person-button" onClick={handleOnClick}>
          <PersonOutlineOutlinedIcon />
        </IconButton>
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={anchorEl !== null}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ p: 3, width: 320 }}>
          <Typography sx={{ mb: 3 }} variant="h6">
            {user?.given_name && user.family_name
              ? `${user.given_name} ${user.family_name}`
              : (user?.name ?? '')}
          </Typography>
          <Divider />
          <List sx={{ p: 0, mt: 2 }}>
            <ListItem
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                void (async () => {
                  await logout();
                })();
              }}
            >
              <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                <LogoutIcon />
              </ListItemIcon>
              Log out
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default UserDropdown;
