import type { ReactElement } from 'react';

/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
import InputAdornment from '@mui/material/InputAdornment';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import TextField, { textFieldClasses } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
/* eslint-enable @typescript-eslint/no-restricted-imports */

function CondorTextField(props: CondorTextFieldProps) {
  const {
    startAdornment,
    endAdornment,
    InputProps,
    errors,
    ...textFieldProps
  } = props;

  return (
    <TextField
      error={Boolean(errors)}
      InputProps={{
        startAdornment:
          startAdornment !== undefined ? (
            <Adornment adornment={startAdornment} position="start" />
          ) : undefined,
        endAdornment:
          endAdornment !== undefined ? (
            <Adornment adornment={endAdornment} position="end" />
          ) : undefined,
        ...InputProps,
      }}
      helperText={
        typeof errors === 'boolean'
          ? undefined
          : typeof errors === 'string'
            ? errors
            : errors?.map((error) => (
                <Typography key={error} component="span">
                  {error}
                </Typography>
              ))
      }
      {...textFieldProps}
    />
  );
}

function Adornment(props: {
  adornment: ReactElement | string | undefined;
  position: 'end' | 'start';
}) {
  const { adornment, position } = props;
  return adornment === undefined ? null : (
    <InputAdornment position={position}>
      <Typography
        variant="button"
        sx={{
          lineHeight: 1,
          fontSize: typeof adornment === 'string' ? 'inherit' : 0,
          ml: typeof adornment === 'string' ? 1 : 0,
        }}
      >
        {adornment}
      </Typography>
    </InputAdornment>
  );
}

type AdornmentTextFieldProps = {
  startAdornment?: ReactElement | string;
  endAdornment?: ReactElement | string;
};
export type ErrorsTextFieldProps = Omit<MuiTextFieldProps, 'error'> & {
  errors?: string[] | boolean | string | null;
};

export type CondorTextFieldProps = AdornmentTextFieldProps &
  ErrorsTextFieldProps;

export { textFieldClasses };

export default CondorTextField;
