import { LEGEND_SIZE } from './GraphLegend';

type LegendLineProps = {
  dashed?: boolean;
  stroke: string | undefined;
  x?: number;
};

function LegendLine(props: LegendLineProps) {
  const { dashed, stroke, x = 0 } = props;

  const dashedProps = dashed
    ? {
        strokeDasharray: '1,4',
        strokeLinecap: 'round' as const,
        strokeLinejoin: 'round' as const,
      }
    : undefined;

  return (
    <line
      stroke={stroke}
      strokeWidth={4}
      x1={x}
      x2={LEGEND_SIZE}
      y1={LEGEND_SIZE / 2}
      y2={LEGEND_SIZE / 2}
      {...dashedProps}
    />
  );
}

export default LegendLine;
