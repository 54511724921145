import { useState } from 'react';

import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { selectClasses } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import Chip, { chipClasses } from 'shared/ui/chip/Chip';
import Select from 'shared/ui/select/Select';

import ConfirmReassignmentModal from './ConfirmReassignmentModal';
import type { MappedColumn, MappedCsvHeader } from './types';
import { IGNORE_COLUMN } from './utils/constants';

type ColumnMapperTableHeaderCellProps = {
  columns: MappedColumn[];
  csvHeader: MappedCsvHeader;
  onChange: (value: string) => void;
};

function ColumnMapperTableHeaderCell(props: ColumnMapperTableHeaderCellProps) {
  const { columns, csvHeader, onChange } = props;
  const [reassignment, setReassignment] = useState<string | null>(null);

  function onSelect(value: string): void {
    const column = columns.find((column) => column.value === value);

    if (column?.mappedTo) {
      setReassignment(value);
    } else {
      onChange(value);
    }
  }

  function onDismissReassignment(): void {
    setReassignment(null);
  }

  function onConfirmReassignment(): void {
    setReassignment(null);
    onChange(reassignment!);
  }

  return (
    <>
      <Select
        SelectProps={{ displayEmpty: true }}
        size="small"
        value={csvHeader.destination ?? ''}
        sx={{
          [`.${selectClasses.select} > .${chipClasses.root}`]: {
            display: 'none', // hide Chip when the input is closed
          },
        }}
        fullWidth
        onChange={({ target: { value } }) => onSelect(value)}
      >
        <MenuItem
          value=""
          sx={{
            [`&.${menuItemClasses.disabled}`]: {
              display: 'none', // do not show in when the input is open
            },
          }}
          disabled
        >
          <span>Select</span>
        </MenuItem>
        <MenuItem value={IGNORE_COLUMN}>
          <span>{IGNORE_COLUMN}</span>
        </MenuItem>
        {columns.map((column) => (
          <MenuItem
            key={column.value}
            value={column.value}
            sx={{
              justifyContent: 'space-between',
              [`.${chipClasses.root}`]: { marginLeft: '30px' },
            }}
          >
            {column.label}
            {!!column.mappedTo && (
              <Chip color="default" label="Assigned" size="small" />
            )}
            {!column.mappedTo && column.required && (
              <Chip
                color="error"
                label="Required"
                size="small"
                variant="outlined"
              />
            )}
          </MenuItem>
        ))}
      </Select>
      {!!reassignment && (
        <ConfirmReassignmentModal
          open
          onConfirm={onConfirmReassignment}
          onDismiss={onDismissReassignment}
        >
          <Typography color="text.primary" variant="h6">
            The header &quot;{csvHeader.name}&quot; is already assigned to
            another column. Assign to this column?
          </Typography>
        </ConfirmReassignmentModal>
      )}
    </>
  );
}

export default ColumnMapperTableHeaderCell;
