import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import type { ChecklistSection } from './AccountingChecklistGrid';
import AccountingChecklistGrid from './AccountingChecklistGrid';

type Props = { sections?: ChecklistSection[] };

function ChecklistSectionsViewer(props: Props) {
  const { sections } = props;

  if (sections === undefined) {
    return null;
  }

  return (
    <>
      {sections.map((section) => (
        <Box key={section.group} mt={2}>
          <Paper sx={{ p: 4 }}>
            <Typography color="primary.main" mb={3} variant="h6">
              {section.title}
            </Typography>
            <AccountingChecklistGrid tasks={section.tasks} />
          </Paper>
        </Box>
      ))}
    </>
  );
}

export default ChecklistSectionsViewer;
