import color from 'color';

import colors from 'colors.module.scss';

import type { TimeForecastConfig } from './types';

export const TIME_FORECAST_CONFIG: TimeForecastConfig = {
  margin: { top: 20, right: 25, bottom: 50, left: 20 },
  angleLabels: 'rotate(90,0,0)',
  horizontalLinesColor: colors.n300,
  fontSize: '14px',
  fontSizeYear: '12px',
  fontWeight: '400',
  fontWeightBold: '700',
  columnColor: colors.dividerLightTheme,
  textColor: colors.primaryTextLightTheme,
  yScaleConfig: { type: 'linear' } as const,
  xScaleConfig: { type: 'band', paddingInner: 0.5, paddingOuter: 0.1 } as const,
  ltdExpenseText: 'LTD months',
  contractedText: 'Contracted',
  forecastedText: 'Forecasted variance',
  ltdExpenseColor: colors.chart9,
  overContractColor: colors.errorMain,
  underContractColor: colors.successMain,
  contractedColor: color(colors.chart9).alpha(0.1).rgb().string(),
  contractedColorOuter: colors.chart9,
};
