import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';

import { useGetVendorRepActivityRecordsBySnapshotQuery } from 'shared/api/rtkq/vendorrepactivityrecords';

import columns from '../columns';

type Props = { snapshotId?: string; onNext?: () => void };

function DirectFeeReviewStep(props: Props) {
  const { snapshotId, onNext } = props;
  const { currentData: reportRecords } =
    useGetVendorRepActivityRecordsBySnapshotQuery(snapshotId ?? skipToken);

  return (
    <WizardStep
      description="Check that all information looks right before saving your report but, don't worry, you'll still be able to make adjustments in the future."
      header="Last step, make sure everything looks right."
      onNext={onNext}
    >
      <SnapshotRecordsTable
        headers={columns}
        rows={reportRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default DirectFeeReviewStep;
