import type { ChangeEvent, KeyboardEvent } from 'react';
import { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import IconButton from 'shared/ui/icon-button/IconButton';

import type { PatientCohort } from 'shared/lib/types';
import { validatePatientCohortName } from 'shared/helpers/validators';

type Props = {
  cohorts: PatientCohort[];
  value: string | undefined;
  onAccept: (value: string) => void;
  onCancel: () => void;
};

function CohortInput(props: Props) {
  const { value: initialValue, cohorts, onAccept, onCancel } = props;

  const [cohortName, setCohortName] = useState<string | undefined>(
    initialValue,
  );
  const [error, setError] = useState<string | undefined>(undefined);

  const isNameValid = !!cohortName?.trim().length && !error;

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const errorMsg = validatePatientCohortName(cohorts, value, cohorts.length);
    setError(errorMsg);
    setCohortName(value);
  }

  function onSave() {
    onAccept(cohortName ?? '');
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Escape') {
      onCancel();
    }

    if (isNameValid && event.key === 'Enter') {
      onSave();
    }
  }

  return (
    <>
      <CondorTextField
        errors={error}
        placeholder="Cohort name"
        size="small"
        sx={{ width: '256px' }}
        value={cohortName}
        autoFocus
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <IconButton color="error" edge="end" size="small" onClick={onCancel}>
        <ClearIcon />
      </IconButton>
      <IconButton
        disabled={!isNameValid}
        size="small"
        sx={{ color: (theme) => theme.palette.success.dark }}
        onClick={onSave}
      >
        <DoneIcon />
      </IconButton>
    </>
  );
}

export default CohortInput;
