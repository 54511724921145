import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from 'shared/ui/autocomplete/Autocomplete';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { getDropdownOptionsFromArray } from 'shared/helpers/helpers';
import type { DropdownOption, PatientAssessmentEditor } from 'shared/lib/types';
import {
  changeEdcCohortMapping,
  magicMapping,
  resetState,
  selectCohort,
  selectCohorts,
  selectCohortsToSave,
  setCohortErrors,
} from 'shared/state/slices/visitSchedulesSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetUniqueEdcNamesQuery } from 'shared/api/rtkq/periods';

import useSaveEdcMappings from '../hooks/useSaveEdcMappings';
import CohortItem from './components/CohortItem';
import EdcMappingSelect from './components/EdcMappingSelect';

function MapToEdcStep() {
  const dispatch = useDispatch();
  const cohorts = useSelector(selectCohorts);
  const currentCohort = useSelector(selectCohort);
  const period = useSelector(selectPeriod);
  const editedCohorts = useSelector(selectCohortsToSave);

  const { currentData: uniqueEdcNames } = useGetUniqueEdcNamesQuery(
    period.trace_id,
  );
  const edcCohortNameOptions = getDropdownOptionsFromArray(
    uniqueEdcNames?.cohorts ?? [],
  );
  const edcVisitNameOptions = getDropdownOptionsFromArray(
    uniqueEdcNames?.visits ?? [],
  );

  const [saveEdcMappings, { isLoading }] = useSaveEdcMappings();

  useEffect(() => {
    dispatch(magicMapping({ uniqueEdcNames }));
  }, [uniqueEdcNames]);

  const onNextAsync = async () => {
    const { cohortErrors } = await saveEdcMappings(editedCohorts);
    if (Object.keys(cohortErrors).length > 0) {
      dispatch(setCohortErrors({ errors: cohortErrors }));
      throw new Error('Error saving patient assessments');
    }

    dispatch(resetState());
  };

  const duplicateMapping = cohorts.find(
    (cohort) =>
      cohort.traceId !== currentCohort?.traceId &&
      cohort.mappedName &&
      cohort.mappedName === currentCohort?.mappedName,
  );

  return (
    <WizardStep
      contentSx={{ display: 'flex', flexDirection: 'column', pb: 0, mt: -2 }}
      disableNextButton={duplicateMapping !== undefined}
      isLoading={isLoading}
      onNextAsync={onNextAsync}
    >
      <Box display="flex" flex={1} flexDirection="row">
        <Box
          borderColor="grey.300"
          borderRight="1px solid"
          pr={2}
          width="268px"
        >
          <List>
            {cohorts.map((cohort) => (
              <CohortItem key={cohort.traceId} cohort={cohort} />
            ))}
          </List>
        </Box>
        <Box display="flex" flex={1} flexDirection="column" p={2}>
          <Typography color="primary.main" variant="h5">
            Map to EDC report
          </Typography>
          <Typography width="60%">
            Assign a value from the EDC dataset to each cohort and visit. Every
            EDC name up to the current period must be mapped in order to
            calculate the trial’s expenses. If you don’t have the EDC name yet,
            you can add them later.
          </Typography>

          <Divider sx={{ mt: 1, borderWidth: 1 }} />

          <Box mt={2} width="60%">
            <Typography variant="body2">Patient Cohort</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                my: 2,
              }}
            >
              <Box
                bgcolor="grey.100"
                borderRadius={1}
                flex={1}
                mr={2}
                px={2}
                py={1}
              >
                <Typography color="text.disabled">
                  {currentCohort?.name}
                </Typography>
              </Box>
              <FormControl sx={{ flex: 1 }}>
                <Autocomplete
                  label="EDC cohort name"
                  options={edcCohortNameOptions}
                  errorMsg={
                    duplicateMapping ? 'This cohort is already mapped' : ''
                  }
                  isOptionEqualToValue={(
                    option: DropdownOption<string> | null,
                    value: DropdownOption<string> | null,
                  ) => option?.label === value?.label}
                  sx={{
                    backgroundColor: (theme) =>
                      currentCohort?.isMagicMapped
                        ? theme.palette.success.light
                        : undefined,
                  }}
                  value={
                    edcCohortNameOptions.find(
                      (option) => option.value === currentCohort?.mappedName,
                    ) ?? null
                  }
                  onChange={(_, option: DropdownOption<string> | null) =>
                    dispatch(
                      changeEdcCohortMapping({
                        cohort: currentCohort!,
                        value: option?.value,
                      }),
                    )
                  }
                />
              </FormControl>
            </Box>

            <Divider sx={{ mt: 3 }} />

            <Box mb={3} mt={2}>
              <Typography variant="body2">Patient Visit</Typography>
            </Box>
            {currentCohort?.patientAssessments.map(
              (visit: PatientAssessmentEditor) => (
                <Box
                  key={visit.traceId}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    alignItems: 'flex-start',
                    marginBottom: '15px',
                  }}
                >
                  <Box
                    bgcolor="grey.100"
                    borderRadius={1}
                    flex={1}
                    mr={2}
                    px={2}
                    py={1}
                  >
                    <Typography color="text.disabled">{visit.name}</Typography>
                  </Box>
                  <FormControl sx={{ flex: 1 }}>
                    <EdcMappingSelect
                      options={edcVisitNameOptions}
                      visit={visit}
                    />
                  </FormControl>
                </Box>
              ),
            )}
          </Box>
        </Box>
      </Box>
    </WizardStep>
  );
}

export default MapToEdcStep;
