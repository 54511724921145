import type { AxisScale } from '@visx/axis/lib/types';
import type { BaseBarSeriesProps } from '@visx/xychart/lib/components/series/private/BaseBarSeries';
import BaseBarSeries from '@visx/xychart/lib/components/series/private/BaseBarSeries';

import CondorAnimatedBars from './private/CondorAnimatedBars';

export default function CondorAnimatedBarSeries<
  XScale extends AxisScale,
  YScale extends AxisScale,
  Datum extends object,
>({
  colorAccessor,
  ...props
}: Omit<BaseBarSeriesProps<XScale, YScale, Datum>, 'BarsComponent'> & {
  strokeAccessor?: (datum: Datum, index: number) => string | undefined;
  strokeWidthAccessor?: (
    datum: Datum,
    index: number,
  ) => number | string | undefined;
}) {
  return (
    <BaseBarSeries<XScale, YScale, Datum>
      {...props}
      BarsComponent={CondorAnimatedBars}
      colorAccessor={
        colorAccessor as BaseBarSeriesProps<
          XScale,
          YScale,
          object
        >['colorAccessor']
      }
    />
  );
}
