import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import EdcReportVisitsValidatorBuilder from 'shared/lib/report-validator/EdcReportVisitsValidatorBuilder';
import SnapshotCell from 'shared/lib/snapshot-validator/SnapshotCell';
import ValidityTag from 'shared/lib/snapshot-validator/ValidityTag';
import type { TraceId } from 'shared/lib/types';
import { getStringFromSiteRecord } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import type { ValidationError } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/ValidationSidebar';

import {
  useGetEdcRecordsBySnapshotQuery,
  useUpdateEdcRecordMutation,
} from 'shared/api/rtkq/edcrecords';
import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';
import { useGetSitesByTrialQuery } from 'shared/api/rtkq/sites';

function useValidationQueue(snapshotId: TraceId | undefined) {
  const { currentData: edcRecords } = useGetEdcRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  ); // EdcRecordResponse // sort: 'snapshot_row'
  const trial = useSelector(selectTrial);
  const { currentData: siteRecords } = useGetSitesByTrialQuery(trial.trace_id);
  const { currentData: existingCohorts } = useGetPatientCohortsByTrialQuery(
    trial.trace_id,
  );
  const [updateEdcRecord] = useUpdateEdcRecordMutation();

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    [],
  );

  useEffect(() => {
    createValidationErrors();
  }, [edcRecords]);

  function createValidationErrors() {
    const edcReportVisitsValidator = new EdcReportVisitsValidatorBuilder()
      .addRequiredField('subject')
      .addRequiredField('site_number')
      .addRequiredField('visit_name')
      .addRequiredField('cohort')

      .addOptionalField('project')
      .addOptionalField('site_name')
      .addOptionalField('site_group')
      .addOptionalField('savets')

      .createRowsValidator();

    const result: ValidityTag[] = (edcRecords ?? []).flatMap((row, index) =>
      Object.entries(row).map(([field, value]) => {
        const cell = new SnapshotCell(value, { field }, { position: index });
        return edcReportVisitsValidator.validate(cell, new ValidityTag(field));
      }),
    );

    const errors = result
      .filter((item) => item.getValidity() && item.getCell())
      .map((item) => {
        const error: ValidationError = {
          fields: [item.getField()],
          row: item.getCell()!.getRow().position,
          type: item.getField() as ValidationError['type'],
        };

        return error;
      });

    setValidationErrors(errors);
  }

  async function handleAcceptForFieldValueMapper(
    selectedErrorIndex: number,
    applyToAllRows: boolean,
    fieldValue: string,
  ) {
    const selectedSite = getSelectedSite(fieldValue);
    if (applyToAllRows) {
      const selectedError = validationErrors[selectedErrorIndex];
      const recordId = selectedError.row
        ? edcRecords?.[selectedError.row]?.trace_id
        : undefined;
      if (recordId && selectedSite) {
        await updateEdcRecord({
          trace_id: recordId,
          site_record: selectedSite.trace_id,
        });
      }
      setValidationErrors(
        validationErrors.filter((_err, index) => index !== selectedErrorIndex),
      );
    }
  }

  function getSelectedSite(fieldValue: string) {
    return siteRecords?.find(
      (site) => getStringFromSiteRecord(site) === fieldValue,
    );
  }

  return {
    allSiteRecordOptions: siteRecords?.map(getStringFromSiteRecord) ?? [],
    allRecords: edcRecords,
    allCohortOptions: existingCohorts?.map(({ name }) => name),
    validationErrors,
    handleAcceptForFieldValueMapper,
  };
}

export default useValidationQueue;
