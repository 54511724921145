import { useState } from 'react';

import CommentsWidget from 'shared/components/comments/CommentsWidget';

import OccReconGrid from 'accruals/pages/clinical-expenses/occ/grids/OccReconGrid';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import { CommentLocationType } from 'shared/lib/types';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import OccTabs from './OccTabs';

function OccReconTab() {
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  return (
    <PageContentLoadingContainer tabs={<OccTabs />}>
      <FlexGridContainer>
        <CurrencyToggleGroup
          setValue={setCurrencyViewMode}
          sx={{ mb: 2 }}
          testId="OCCReconToggle"
          value={currencyViewMode}
        />
        <OccReconGrid
          sx={{ height: '100%' }}
          userDisplayOptions={{ currencyViewMode }}
        />
      </FlexGridContainer>
      <CommentsWidget
        location={CommentLocationType.OCC_RECON}
        commentsForPage
      />
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(OccReconTab);
