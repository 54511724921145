import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

type Props = { inProgress: boolean; message?: string };

function FullScreenProgress({ inProgress, message }: Props) {
  return (
    <Backdrop
      open={inProgress}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
      <Typography
        variant="body1"
        sx={{
          ml: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {message}
      </Typography>
    </Backdrop>
  );
}

export default FullScreenProgress;
