import Box from '@mui/material/Box';

import { DIVIDER } from './SidebarContainer';
import SidebarItem from './SidebarItem';
import type { SidebarSectionItem } from './SidebarSection';
import SidebarSection from './SidebarSection';

type Props = {
  collapsed: boolean;
  items: Array<SidebarSectionItem | typeof DIVIDER>;
};

function SidebarMenuItems(props: Props) {
  const { collapsed, items } = props;

  return items.map((item, i) =>
    item === DIVIDER ? (
      <Box
        // biome-ignore lint/suspicious/noArrayIndexKey: there's nothing else to use as the key
        key={i}
        sx={{
          my: 1,
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />
    ) : item.link ? (
      // biome-ignore lint/suspicious/noArrayIndexKey: nothing else is guaranteed unique
      <SidebarItem key={i} collapsed={collapsed} {...item} />
    ) : (
      // biome-ignore lint/suspicious/noArrayIndexKey: nothing else is guaranteed unique
      <SidebarSection key={i} collapsed={collapsed} {...item} />
    ),
  );
}

export default SidebarMenuItems;
