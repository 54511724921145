import useIsReadOnlyPermission from './useIsReadOnlyPermission';

export default function withReadOnlyPermissionWrapper<T extends object>(
  WrappedComponent: (props: T) => React.JSX.Element | null,
) {
  return function ReadOnlyViewWrapper(props: T) {
    const isReadonly = useIsReadOnlyPermission();

    if (isReadonly) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}
