import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Badge, { badgeClasses } from '@mui/material/Badge';

import IconButton from 'shared/ui/icon-button/IconButton';

import type { CommentLocationType } from 'shared/lib/types';

import useCommentsLoader from './hooks/useCommentsLoader';

type Props = {
  location: CommentLocationType;
  locationSlug: string;
  onClick: () => void;
};

function CommentsWidgetIcon(props: Props) {
  const { location, locationSlug, onClick } = props;
  const comments = useCommentsLoader(location, locationSlug);

  return (
    <Badge
      badgeContent={comments?.length ?? 0}
      color="primary"
      sx={{ [`& .${badgeClasses.badge}`]: { right: 8, top: 8 } }}
      showZero
      onClick={onClick}
    >
      <IconButton>
        <CommentOutlinedIcon />
      </IconButton>
    </Badge>
  );
}

export default CommentsWidgetIcon;
