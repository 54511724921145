import { animated, useSpring } from '@react-spring/web';

type AnimatedBarProps = {
  fill: string;
  height: number;
  rx?: number;
  ry?: number;
  stroke?: string;
  strokeWidth?: string;
  verticalOffset: number;
  width: number;
  x: number;
  y: number;
};

function AnimatedBar(props: AnimatedBarProps) {
  const {
    stroke,
    strokeWidth,
    width,
    rx,
    ry,
    x,
    y,
    height,
    verticalOffset,
    fill,
  } = props;

  const { width: animatedWidth, x: animatedX } = useSpring({
    from: { width: 0, x: 0 },
    to: { width: width < 0 ? 0 : width, x },
    config: { tension: 150 },
  });

  return (
    <animated.rect
      fill={fill}
      height={height}
      rx={rx}
      ry={ry}
      stroke={stroke}
      strokeWidth={strokeWidth}
      style={{ transform: `translate(0, ${verticalOffset}px)` }}
      width={animatedWidth}
      x={animatedX}
      y={y}
    />
  );
}

export default AnimatedBar;
