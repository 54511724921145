import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

export default function useExpectedVisitsGridColumnDefs(
  isOpenPeriod: boolean,
): CondorColDef[] {
  return useMemo(
    () => [
      { flex: 1, headerName: 'Patient ID', field: 'patient_id' },
      {
        flex: 1,
        headerName: 'Patient Journey ID',
        field: 'journey_id',
        hide: true,
      },
      {
        flex: 1,
        headerName: 'Expected Visit Trace ID',
        field: 'trace_id',
        hide: true,
      },
      { flex: 1, headerName: 'Cohort', field: 'cohort_name', filter: true },
      {
        flex: 1,
        headerName: 'Expected Visit Name',
        field: 'visit_name',
        filter: true,
      },
      { flex: 1, headerName: 'Expected Visit Date', field: 'visit_date' },
      {
        flex: 1,
        headerName: 'Use in calculations',
        field: 'use_in_calculations',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        hide: !isOpenPeriod,
      },
    ],
    [isOpenPeriod],
  );
}
