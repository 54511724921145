import type { ReactNode } from 'react';

import Box from '@mui/material/Box';

type Props = { children: ReactNode };

function TabularDataHeaderRow(props: Props) {
  const { children } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexFlow: 'row',
        justifyContent: 'space-between',
        px: 1.5,
        mb: 1,
      }}
    >
      {children}
    </Box>
  );
}

export default TabularDataHeaderRow;
