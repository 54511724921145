import Typography from '@mui/material/Typography';

import colors from 'colors.module.scss';

import styles from './ColorsDemo.module.scss';

function ColorsDemo() {
  return (
    <>
      {samples.map((group) => (
        <div key={group.type} className={styles.group}>
          <Typography variant="h4">{group.type}</Typography>
          <div className={styles.samples}>
            {group.colors.map((sample) => (
              <div key={sample.name} className={styles.sample}>
                <div
                  className={styles.color}
                  style={{ backgroundColor: sample.color }}
                />
                <div>{sample.name}</div>
                <span>{sample.color}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

const samples = [
  {
    type: 'Primary',
    colors: [
      { name: 'Main', color: colors.main },
      { name: 'Light', color: colors.light },
      { name: 'Dark', color: colors.dark },
    ],
  },
  {
    type: 'Secondary',
    colors: [
      { name: 'Main', color: colors.secondaryMain },
      { name: 'Light', color: colors.secondaryLight },
      { name: 'Dark', color: colors.secondaryDark },
    ],
  },
  {
    type: 'Neutral',
    colors: [
      { name: 'White', color: colors.white },
      { name: 'Black', color: colors.black },
    ],
  },
  {
    type: 'Grayscale',
    colors: [
      { name: '50', color: colors.n50 },
      { name: '100', color: colors.n100 },
      { name: '300', color: colors.n300 },
      { name: '500', color: colors.n500 },
      { name: '700', color: colors.n700 },
      { name: '900', color: colors.n900 },
    ],
  },
  {
    type: 'Error',
    colors: [
      { name: 'Main', color: colors.errorMain },
      { name: 'Light', color: colors.errorLight },
      { name: 'Dark', color: colors.errorDark },
    ],
  },
  {
    type: 'Warning',
    colors: [
      { name: 'Main', color: colors.warningMain },
      { name: 'Light', color: colors.warningLight },
      { name: 'Dark', color: colors.warningDark },
    ],
  },
  {
    type: 'Success',
    colors: [
      { name: 'Main', color: colors.successMain },
      { name: 'Light', color: colors.successLight },
      { name: 'Dark', color: colors.successDark },
    ],
  },
  {
    type: 'Info',
    colors: [
      { name: 'Main', color: colors.infoMain },
      { name: 'Light', color: colors.infoLight },
      { name: 'Dark', color: colors.infoDark },
    ],
  },
  {
    type: 'Chart color order',
    colors: [
      { name: 'Chart 01', color: colors.chart1 },
      { name: 'Chart 02', color: colors.chart2 },
      { name: 'Chart 03', color: colors.chart3 },
      { name: 'Chart 04', color: colors.chart4 },
      { name: 'Chart 05', color: colors.chart5 },
      { name: 'Chart 06', color: colors.chart6 },
      { name: 'Chart 07', color: colors.chart7 },
      { name: 'Chart 08', color: colors.chart8 },
      { name: 'Chart 09', color: colors.chart9 },
      { name: 'Chart 10', color: colors.chart10 },
    ],
  },
];

export default ColorsDemo;
