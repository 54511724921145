import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CommentsWidget from 'shared/components/comments/CommentsWidget';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { CommentLocationType } from 'shared/lib/types';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import JournalEntryGrid from './grids/JournalEntryGrid';

function JournalEntryPage() {
  return (
    <PageContentLoadingContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          mb: 2,
        }}
      >
        <Typography color="primary.main" variant="h3">
          Journal Entry
        </Typography>
      </Box>
      <FlexGridContainer>
        <JournalEntryGrid sx={useMemo(() => ({ height: '90%' }), [])} />
      </FlexGridContainer>
      <CommentsWidget
        location={CommentLocationType.ACCRUALS_AND_JOURNAL_ENTRY}
        commentsForPage
      />
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(JournalEntryPage);
