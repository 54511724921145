import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import DatePicker from 'shared/ui/date-picker/DatePicker';

import type { SiteRecordRequest } from 'shared/lib/types';

import styles from '../../../contract-version-wizard/ContractVersionWizard.module.scss';

type Props = {
  errors: Record<string, string>;
  siteDocuments: React.ReactNode;
  siteRecord: Partial<SiteRecordRequest>;
  onBlurField?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function AddSiteForm(props: Props, ref: ForwardedRef<HTMLFormElement>) {
  const { siteDocuments, siteRecord, errors, onChangeField, onBlurField } =
    props;

  return (
    <Box
      ref={ref}
      className={styles.ContractVersionWizard}
      component="form"
      noValidate
    >
      <Box className={styles.formRow}>
        <div className={`${styles.formLabel} ${styles.required}`}>
          Site number
        </div>
        <div className={styles.rightPanel}>
          <FormControl
            className={[styles.formInput, styles.fullWidth].join(' ')}
          >
            <CondorTextField
              autoComplete="off"
              errors={errors.number}
              label="Site number"
              name="number"
              size="small"
              value={siteRecord.number ?? ''}
              required
              onBlur={onBlurField}
              onChange={onChangeField}
            />
          </FormControl>
        </div>
      </Box>
      <Box className={styles.formRow}>
        <div className={styles.formLabel}>Site name</div>
        <div className={styles.rightPanel}>
          <FormControl
            className={[styles.formInput, styles.fullWidth].join(' ')}
          >
            <CondorTextField
              autoComplete="off"
              errors={errors.name}
              label="Site name"
              name="name"
              size="small"
              value={siteRecord.name ?? ''}
              onBlur={onBlurField}
              onChange={onChangeField}
            />
          </FormControl>
        </div>
      </Box>
      <Box className={styles.formRow}>
        <div className={`${styles.formLabel} ${styles.required}`}>Country</div>
        <div className={styles.rightPanel}>
          <FormControl
            className={[styles.formInput, styles.fullWidth].join(' ')}
          >
            <CondorTextField
              autoComplete="off"
              errors={errors.country}
              label="Country"
              name="country"
              size="small"
              value={siteRecord.country ?? ''}
              required
              onBlur={onBlurField}
              onChange={onChangeField}
            />
          </FormControl>
        </div>
      </Box>
      <Box className={styles.formRow}>
        <Box width="100%">
          <Box className={styles.formLabel}>Dates</Box>
          <Stack direction="row" gap={2} justifyContent="space-between" mt={3}>
            <FormControl
              className={[styles.formInput, styles.fullWidth].join(' ')}
            >
              <DatePicker
                errors={errors.recruited_date}
                label="Recruitment date"
                name="recruited_date"
                size="small"
                value={siteRecord.recruited_date ?? ''}
                onChange={onChangeField}
              />
            </FormControl>
            <FormControl
              className={[styles.formInput, styles.fullWidth].join(' ')}
            >
              <DatePicker
                errors={errors.initiated_date}
                label="Initiation date"
                name="initiated_date"
                size="small"
                value={siteRecord.initiated_date ?? ''}
                onChange={onChangeField}
              />
            </FormControl>
            <FormControl
              className={[styles.formInput, styles.fullWidth].join(' ')}
            >
              <DatePicker
                errors={errors.closed_date}
                label="Close date"
                name="closed_date"
                size="small"
                value={siteRecord.closed_date ?? ''}
                fullWidth
                onChange={onChangeField}
              />
            </FormControl>
          </Stack>
        </Box>
      </Box>
      <Box className={styles.formRow}>
        <Box className={styles.formLabel}>Principal investigator</Box>
        <Stack
          className={styles.rightPanel}
          direction="row"
          gap={2}
          justifyContent="space-between"
        >
          <FormControl
            className={[styles.formInput, styles.fullWidth].join(' ')}
          >
            <CondorTextField
              autoComplete="off"
              errors={errors.investigator_name}
              label="Investigator name"
              name="investigator_name"
              size="small"
              value={siteRecord.investigator_name ?? ''}
              onChange={onChangeField}
            />
          </FormControl>
          <FormControl
            className={[styles.formInput, styles.fullWidth].join(' ')}
          >
            <CondorTextField
              autoComplete="off"
              errors={errors.investigator_email}
              label="Investigator email"
              name="investigator_email"
              size="small"
              type="email"
              value={siteRecord.investigator_email ?? ''}
              onChange={onChangeField}
            />
          </FormControl>
        </Stack>
      </Box>
      <Box className={styles.formRow} flexDirection="column">
        <Typography variant="h6">Site contract(s)</Typography>
        <Typography variant="caption">
          Upload the site contract documents. Accepted file formats: pdf, docx,
          csv, xlsx
        </Typography>
      </Box>
      <Box
        borderBottom="none !important"
        className={styles.formRow}
        flexDirection="column"
      >
        {siteDocuments}
      </Box>
    </Box>
  );
}

export default forwardRef(AddSiteForm);
