import { useMemo } from 'react';

import type {
  ICellRendererParams,
  RowClickedEvent,
} from '@ag-grid-community/core';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import PeriodStatusChip from 'accruals/components/sidebar/PeriodStatusChip';

import {
  selectPeriodVersion,
  useChangePeriodVersion,
} from 'accruals/state/slices/periodVersionSlice';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { formatFullDate, formatFullMonthYear } from 'shared/helpers/helpers';
import type { PeriodResponse, PeriodVersionResponse } from 'shared/lib/types';

type Props = {
  currentPeriod: PeriodResponse;
  periodVersions: PeriodVersionResponse[];
};

const COLUMN_DEFS = [
  { field: 'row_number', hide: true },
  { headerName: 'Version', field: 'version', flex: 1 },
  {
    headerName: 'Status',
    field: 'status',
    flex: 1,
    cellRenderer: (params: ICellRendererParams) => params.value,
  },
  { headerName: 'Open date', field: 'created_at', flex: 1 },
  { headerName: 'Close date', field: 'closed_at', flex: 1 },
];

function PeriodVersionsTabContent(props: Props) {
  const { currentPeriod, periodVersions } = props;
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const changePeriodVersion = useChangePeriodVersion();

  const periodName = formatFullMonthYear(currentPeriod.end_date);

  const rowData = useMemo(
    () =>
      periodVersions.map((periodVersion) => ({
        version: `${periodName} Version ${periodVersion.version_number}`,
        status: <PeriodStatusChip isClosed={!!periodVersion.closed_at} />,
        created_at:
          periodVersion.created_at !== undefined
            ? formatFullDate(periodVersion.created_at)
            : '',
        closed_at:
          periodVersion.closed_at !== null
            ? formatFullDate(periodVersion.closed_at)
            : '',
      })),
    [periodName, periodVersions],
  );

  const gridOptions = useMemo(
    () => ({
      onRowClicked: (event: RowClickedEvent) =>
        changePeriodVersion(periodVersions[event.node.rowIndex ?? 0]),
    }),
    [changePeriodVersion, periodVersions],
  );

  return (
    <FlexGridContainer>
      <Typography color="primary.main" variant="h3">
        {periodName}
      </Typography>
      <Typography variant="body1">
        Current version: #{currentPeriodVersion.version_number}
      </Typography>
      <CondorAgGrid
        columnDefs={COLUMN_DEFS}
        gridOptions={gridOptions}
        rowData={rowData}
        rowStyle={useMemo(() => ({ cursor: 'pointer' }), [])}
        sx={useMemo(() => ({ mt: 2, height: '100%' }), [])}
      />
    </FlexGridContainer>
  );
}

export default PeriodVersionsTabContent;
