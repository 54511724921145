import type { SetStateAction } from 'react';
import { useState } from 'react';

import Add from '@mui/icons-material/Add';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import Button from 'shared/ui/button/Button';

import SiteListingUploadWizard from 'accruals/wizards/csv-upload-wizards/site-listing-upload-wizard/SiteListingUploadWizard';
import AddLabVersionWizard from 'accruals/wizards/site-or-lab-costs/add-lab-version-wizard/AddLabVersionWizard';
import AddLabWizard from 'accruals/wizards/site-or-lab-costs/add-lab-wizard/AddLabWizard';
import AddSiteVersionWizard from 'accruals/wizards/site-or-lab-costs/add-site-version-wizard/AddSiteVersionWizard';
import AddSiteWizard from 'accruals/wizards/site-or-lab-costs/add-site-wizard/AddSiteWizard';
import withReadOnlyPermissionWrapper from 'shared/lib/read-only-permission/withReadOnlyPermissionWrapper';

function AddSiteLabDropdown() {
  const [showSiteListing, setShowSiteListing] = useState<boolean>(false);
  const [showAddSite, setShowAddSite] = useState<boolean>(false);
  const [showAddSiteVersion, setShowAddSiteVersion] = useState<boolean>(false);
  const [showAddLab, setShowAddLab] = useState<boolean>(false);
  const [showAddLabVersion, setShowAddLabVersion] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function DropdownMenuItem(props: {
    label: string;
    setShowWizard: (value: SetStateAction<boolean>) => void;
  }) {
    const { label, setShowWizard } = props;
    return (
      <MenuItem
        disableGutters
        disableRipple
        onClick={() => {
          setShowWizard(true);
          setAnchorEl(null);
        }}
      >
        {label}
      </MenuItem>
    );
  }

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDownOutlinedIcon />}
        startIcon={<Add />}
        testId="add_site_lab"
        variant="outlined"
        disableElevation
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Add Site/Lab
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ mx: 2, my: 1 }}>
          <Typography variant="body2">SITES</Typography>
          <DropdownMenuItem
            label="Upload site listing"
            setShowWizard={setShowSiteListing}
          />
          <DropdownMenuItem
            label="Add new site"
            setShowWizard={setShowAddSite}
          />
          <DropdownMenuItem
            label="Add new site version"
            setShowWizard={setShowAddSiteVersion}
          />
          <Divider />
          <Typography variant="body2">LABS</Typography>
          <DropdownMenuItem label="Add new lab" setShowWizard={setShowAddLab} />
          <DropdownMenuItem
            label="Add new lab version"
            setShowWizard={setShowAddLabVersion}
          />
        </Box>
      </Menu>
      {showSiteListing && (
        <SiteListingUploadWizard
          onClose={() => setShowSiteListing(false)}
          onComplete={() => setShowSiteListing(false)}
        />
      )}
      {showAddSite && <AddSiteWizard onClose={() => setShowAddSite(false)} />}
      {showAddSiteVersion && (
        <AddSiteVersionWizard
          onClose={() => setShowAddSiteVersion(false)}
          onSave={() => setShowAddSiteVersion(false)}
        />
      )}
      {showAddLab && (
        <AddLabWizard
          onClose={() => setShowAddLab(false)}
          onSave={() => setShowAddLab(false)}
        />
      )}
      {showAddLabVersion && (
        <AddLabVersionWizard
          onClose={() => setShowAddLabVersion(false)}
          onSave={() => setShowAddLabVersion(false)}
        />
      )}
    </>
  );
}

export default withReadOnlyPermissionWrapper(AddSiteLabDropdown);
