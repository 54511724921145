import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import type { SxProps, Theme } from '@mui/material/styles';

import Roller from 'shared/ui/roller/Roller';

type Props = {
  children: ReactNode;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  tabs?: ReactNode;
};

function PageContentLoadingContainer(props: Props) {
  const { children, isLoading = false, tabs, sx } = props;

  return (
    <>
      {tabs}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          p: 4,
          ...(isLoading && {
            position: 'relative',
          }),
          ...sx,
        }}
      >
        {isLoading ? <Roller /> : children}
      </Box>
    </>
  );
}

export default PageContentLoadingContainer;
