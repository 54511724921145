import type SnapshotCell from './SnapshotCell';

class ValidityTag {
  protected field: string;
  protected invalid?: boolean;
  protected cell?: SnapshotCell;

  constructor(field: string) {
    this.field = field;
  }

  setCellValidity(cell: SnapshotCell, invalid: boolean): this {
    this.cell = cell;
    this.invalid = invalid;
    return this;
  }

  getCell(): SnapshotCell | undefined {
    return this.cell;
  }

  getValidity(): boolean | undefined {
    return this.invalid;
  }

  getField() {
    return this.field;
  }
}

export default ValidityTag;
