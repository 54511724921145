import useIsReadOnlyPermission from './useIsReadOnlyPermission';

type Props = { children: React.ReactNode };

function HiddenForReadOnlyPermission(props: Props) {
  const { children } = props;
  const isReadOnly = useIsReadOnlyPermission();
  return isReadOnly ? null : children;
}

export default HiddenForReadOnlyPermission;
