import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { CroReconLineMatchings } from 'shared/lib/types';

// `unknown` matches ag-grid
export default function useReconLineMatchingRowData(
  lineMatchingData?: CroReconLineMatchings,
): unknown[] | undefined {
  return useMemo(() => {
    const copyProperties = (
      arr: Array<Record<string, unknown>>,
      index: number,
      target: Record<string, unknown>,
      prefix: string,
    ) => {
      if (index < arr.length) {
        for (const [key, value] of Object.entries(arr[index])) {
          target[prefix + key] = value ?? '';
        }
      }
    };

    if (lineMatchingData !== undefined) {
      const rows = [];
      const { currentBudgetRows, aipBudgetRows, reportedActivityRows } =
        lineMatchingData;
      const lineCount = Math.max(
        currentBudgetRows.length,
        aipBudgetRows.length,
        reportedActivityRows.length,
      );
      for (let i = 0; i < lineCount; i++) {
        const row = {};
        copyProperties(currentBudgetRows, i, row, 'cur_');
        copyProperties(aipBudgetRows, i, row, 'aip_');
        copyProperties(reportedActivityRows, i, row, 'rep_');
        rows.push(row);
      }
      return cloneDeep(rows);
    }
  }, [lineMatchingData]);
}
