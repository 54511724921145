export const ALL_CONTRACTS = 'ALL_CONTRACTS';
export const PATIENT_ACTIVITY_GRID = 'PATIENT_ACTIVITY_GRID';
export const PATIENT_JOURNEY_GRID = 'PATIENT_JOURNEY_GRID';
export const EXPECTED_VISITS_GRID = 'EXPECTED_VISITS_GRID';
export const PERIOD_OCC_EXPENSES = 'PERIOD_OCC_EXPENSES';
export const PERIOD_OCC_RECON = 'PERIOD_OCC_RECON';
export const ACCRUALS_GRID = 'ACCRUALS_GRID';
export const BS_FLUX_GRID = 'BS_FLUX_GRID';
export const JOURNAL_ENTRY_GRID = 'JOURNAL_ENTRY_GRID';
export const TRIAL_ACTIVITY_SITES_GRID = 'TRIAL_ACTIVITY_SITES_GRID';
export const TRIAL_ACTIVITY_ENROLLMENT_GRID = 'TRIAL_ACTIVITY_ENROLLMENT_GRID';
export const CRO_EXPENSE_SUMMARY = 'CRO_EXPENSE_SUMMARY';
export const OCC_EXPENSE_SUMMARY = 'OCC_EXPENSE_SUMMARY';
export const TRIAL_EXPENSE_SUMMARY = 'TRIAL_EXPENSE_SUMMARY';
export const PATIENT_COHORTS = 'PATIENT_COHORTS';
export const PATIENT_ASSESSMENTS = 'PATIENT_ASSESSMENTS';
export const HISTORICAL_EXPENSES = 'HISTORICAL_EXPENSES';
export const CLOSED_PERIOD_EXPENSES = 'CLOSED_PERIOD_EXPENSES';
