import { type ReactNode, useEffect } from 'react';

import { useSelector } from 'react-redux';

import NoPermissions from 'shared/components/no-permissions/NoPermissions';
import Roller from 'shared/ui/roller/Roller';

import {
  missingCompany,
  selectCompany,
  selectCompanyInitialized,
  useInitializeCompany,
} from 'shared/state/slices/companySlice';

import { useGetCompaniesQuery } from 'shared/api/rtkq/companies';

export default function withCompanyRequired<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
) {
  return function CompanyRequiredWrapper(props: T) {
    const initialized = useSelector(selectCompanyInitialized);
    const company = useSelector(selectCompany);
    const initCompany = useInitializeCompany();

    const {
      currentData: companies,
      isUninitialized,
      isLoading,
      isFetching,
    } = useGetCompaniesQuery();
    const waitForApi = isUninitialized || isLoading || isFetching;
    const numCompanies = companies?.length ?? 0;

    // only call initialize company once
    useEffect(() => {
      if (waitForApi || initialized) {
        return;
      }

      initCompany(companies);
    }, [companies, waitForApi, initCompany, initialized]);

    if (!waitForApi && (company === missingCompany || numCompanies === 0)) {
      return <NoPermissions />;
    }

    return waitForApi ? <Roller /> : <LiveDataComponent {...props} />;
  };
}
