import type { Ref } from 'react';
import { forwardRef } from 'react';

/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename -- intentional
import MuiLoadingButton from '@mui/lab/LoadingButton';
import type { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';

function LoadingButton(props: LoadingButtonProps, ref: Ref<HTMLButtonElement>) {
  const { testId, ...otherProps } = props;

  return <MuiLoadingButton {...otherProps} ref={ref} data-testid={testId} />;
}

export type LoadingButtonProps = MuiLoadingButtonProps & { testId: string };
export { loadingButtonClasses } from '@mui/lab/LoadingButton';
/* eslint-enable @typescript-eslint/no-restricted-imports */

export default forwardRef(LoadingButton);
