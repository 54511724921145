import { useCallback } from 'react';

import Typography from '@mui/material/Typography';

import Modal from 'shared/components/modal/Modal';
import Alert from 'shared/ui/alert/Alert';

import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import { useChangeForecast } from 'forecasting/state/slices/forecastSlice';

import { useUpdateForecastMutation } from 'shared/api/rtkq/forecasts';

type Props = {
  handleCloseModal: () => void;
};

function LockForecastModal({ handleCloseModal }: Props) {
  const [updateForecast, { isLoading: updateForecastLoading }] =
    useUpdateForecastMutation();
  const changeForecast = useChangeForecast();
  const { forecast, period } = useForecast();
  const { name, locked } = forecast;

  const toggleLock = useCallback(() => {
    void (async () => {
      const newForecast = { ...forecast, locked: !forecast.locked };
      await updateForecast({
        trace_id: forecast.trace_id,
        locked: !forecast.locked,
      });
      changeForecast(newForecast);

      handleCloseModal();
    })();
  }, [forecast, updateForecast, changeForecast, handleCloseModal]);

  return (
    <Modal
      handleClose={handleCloseModal}
      title={`${locked ? 'Unlock' : 'Lock'} "${name}"`}
      ButtonProps={{
        label: locked ? 'Unlock' : 'Lock',
        testId: 'ForecastModalLock',
        loading: updateForecastLoading,
        onClick: toggleLock,
      }}
      isOpen
    >
      {forecast.locked ? (
        <Typography>
          Unlocking a forecast will allow changes to the forecast parameters.
        </Typography>
      ) : (
        <Typography>
          Locking a forecast will prevent any changes to the forecast
          parameters. You may unlock it at any time.
        </Typography>
      )}
      {!locked && period && !period.is_closed && (
        <Alert
          severity="info"
          sx={{ mt: 2 }}
          title="This forecast is using an open period."
        >
          <Typography variant="caption">
            The forecast numbers may change if the period&apos;s accrual data is
            updated.
          </Typography>
        </Alert>
      )}
    </Modal>
  );
}

export default LockForecastModal;
