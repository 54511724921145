/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

The only thing that should can go inside this file is the useEffect that cleans up accruals.
If anything else goes in here, especially things that depend on trial, period, etc. data, 
it will cause an infinite loop.

If you have any questions, please ask in #engineering
*/

import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import useClearAccruals from 'accruals/state/hooks/useClearAccruals';

function AccrualsCleanLayout() {
  const clearAccruals = useClearAccruals();

  // when this is unmounted, clear the current accruals data
  useEffect(() => clearAccruals);

  return <Outlet />;
}

export default AccrualsCleanLayout;
