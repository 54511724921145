import { useMemo, useState } from 'react';

import type {
  GridApi,
  GridReadyEvent,
  ModelUpdatedEvent,
} from '@ag-grid-community/core';
import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import CustomHeaderTooltip from 'shared/components/ag-grid/headers/CustomHeaderTooltip';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import type { UserDeactivatePopup } from 'shared/lib/types';
import { PeriodGridBlobType } from 'shared/lib/types';

import DeactivateUserPopup from '../components/DeactivateUserPopup';
import TrialAccessDialog from '../components/TrialAccessDialog';
import useUserAccessColumnDefs from '../hooks/useUserAccessColumnDefs';
import useUserAccessGridOptions from '../hooks/useUserAccessGridOptions';
import useUserDataRows, { processUserRows } from '../hooks/useUserDataRows';

type Props = {
  overlayNoRowsTemplate?: string;
  setUserAccessGridApi: (gridApi: GridApi) => void;
  showActiveUsers: boolean;
  sx?: SxProps;
};

function UserAccessGrid(props: Props) {
  const { overlayNoRowsTemplate, sx, showActiveUsers, setUserAccessGridApi } =
    props;

  const defaultUserDeactivatedState: UserDeactivatePopup = {
    email: '',
    state: false,
    trace_id: '',
  };
  const [showDeactivateUserPopupData, setShowDeactivateUserPopupData] =
    useState<UserDeactivatePopup>(defaultUserDeactivatedState);

  const [showTrialAccessData, setShowTrialAccessData] = useState({
    trace_id: '',
    name: '',
    state: false,
    trials: [],
  });
  const columnDefs = useGridColDefs(useUserAccessColumnDefs, [
    setShowTrialAccessData,
  ]);
  const gridOptions = useGridOptions(useUserAccessGridOptions, [
    setShowDeactivateUserPopupData,
  ]);

  const result = useUserDataRows(showActiveUsers);

  const onCloseUserPopup = async () => {
    const { data } = await result.usersRefetch();
    result.rowData = processUserRows({ rows: data ?? [] }, showActiveUsers);
    setShowDeactivateUserPopupData(defaultUserDeactivatedState);
  };

  return (
    <>
      {showDeactivateUserPopupData.state && (
        <DeactivateUserPopup
          email={showDeactivateUserPopupData.email}
          trace_id={showDeactivateUserPopupData.trace_id}
          onClose={() => {
            void onCloseUserPopup();
          }}
        />
      )}
      {showTrialAccessData.state && (
        <TrialAccessDialog
          isOpen={showTrialAccessData.state}
          name={showTrialAccessData.name}
          title="Trial Access"
          userId={showTrialAccessData.trace_id}
          userTrials={showTrialAccessData.trials}
          handleClose={() =>
            setShowTrialAccessData({
              state: false,
              trace_id: '',
              name: '',
              trials: [],
            })
          }
        />
      )}

      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        sx={sx}
        tooltipShowDelay={0}
        defaultColDef={useMemo<CondorColDef>(
          () => ({
            tooltipComponent: CustomHeaderTooltip,
          }),
          [],
        )}
        rowData={
          showActiveUsers
            ? result.rowData?.filter((row) => row.status === 'Active')
            : result.rowData
        }
        tooltipInteraction
        onGridReady={(event: GridReadyEvent) => {
          setUserAccessGridApi(event.api);
        }}
        onModelUpdated={(event: ModelUpdatedEvent) => {
          event.api.getDisplayedRowCount() === 0
            ? event.api.showNoRowsOverlay()
            : event.api.hideOverlay();
        }}
      />
    </>
  );
}

export default withPeriodSpecificGridWrapper(
  UserAccessGrid,
  PeriodGridBlobType.USER_ACCESS_GRID,
);
