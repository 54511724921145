import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { PurchaseOrderRecordResponse } from 'shared/lib/types';

export default function usePurchaseOrdersGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<PurchaseOrderRecordResponse> {
  return useMemo(
    () => ({
      getRowId: 'trace_id',
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
    }),
    [],
  );
}
