import columns from 'accruals/wizards/edc-upload-wizard/columns';
import AgGridValidationTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/AgGridValidationTable';
import type { ValidationError } from 'shared/wizards/csv-upload-wizards/validate-and-check-table/ValidationSidebar';

import rows from './rows';

const edcVisitsErrors: ValidationError[] = [
  { type: 'visit_name', row: 1, fields: ['visit_name'] },
];

function EdcVisitsValidationDemo() {
  return (
    <AgGridValidationTable
      headers={columns}
      rows={rows}
      validationErrors={edcVisitsErrors}
    />
  );
}

export default EdcVisitsValidationDemo;
