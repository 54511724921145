import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import {
  selectUserCanEdit,
  selectUserCanSignoffAsReviewer,
} from 'shared/state/slices/userSlice';

function UserPermissionBanner() {
  const userCanSignoffAsReviewer = useSelector(selectUserCanSignoffAsReviewer);
  const userEditPermission = useSelector(selectUserCanEdit);

  function getBannerText(): string | undefined {
    // super users can do everything, whereas reviewers cannot edit, so show if in that state
    if (!userEditPermission && userCanSignoffAsReviewer) {
      return 'You are a reviewer, so you can only comment, sign-off as a reviewer, and close the period.';
    }

    if (!userEditPermission) {
      return 'You are in read-only view. You cannot make any changes.';
    }
  }

  if (!getBannerText()) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        borderBottom: '1px solid',
        borderBottomColor: 'primary.main',
        backgroundColor: 'primary.light',
      }}
    >
      <Typography mx={4} variant="body1">
        {getBannerText()}
      </Typography>
    </Box>
  );
}

export default UserPermissionBanner;
