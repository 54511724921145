import { useState } from 'react';

import Stack from '@mui/material/Stack';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import ContractAssumptionsSectionContents from './ContractAssumptionsSectionContents';
import EditContractAssumptionsButton from './EditContractAssumptionsButton';
import useContractAndPeriodWithVersions from './hooks/useContractAndPeriodWithVersions';

function ContractAssumptionsSection() {
  const { contractVersionPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();
  const [openContractAssumptionsWizard, setOpenContractAssumptionsWizard] =
    useState(false);

  return (
    <DashboardCard
      title="Contract Assumptions"
      titleAction={
        <EditContractAssumptionsButton
          setOpenContractAssumptionsWizard={setOpenContractAssumptionsWizard}
        />
      }
    >
      <Stack alignItems="center" display="flex" flexDirection="column">
        <ContractAssumptionsSectionContents
          openContractAssumptionsWizard={openContractAssumptionsWizard}
          parentMenuItem={contractVersionPeriodMenuItemTraceId}
          setOpenContractAssumptionsWizard={setOpenContractAssumptionsWizard}
        />
      </Stack>
    </DashboardCard>
  );
}

export default ContractAssumptionsSection;
