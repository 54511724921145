import { Fragment, type ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import AppToolbar from 'shared/components/app-toolbar/AppToolbar';
import Alert from 'shared/ui/alert/Alert';

import theme from 'theme';

import { useGetEmergencyAlertsQuery } from 'shared/api/rtkq/emergencyalerts';

import AuthContainer from '../auth-container/AuthContainer';

type Props = {
  children: ReactNode;
  dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED?: boolean;
};

function AppContainer(props: Props) {
  const { children, dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } =
    props;
  const { data: emergencyAlerts } = useGetEmergencyAlertsQuery();
  const numAlerts = emergencyAlerts?.length ?? 0;
  const [themeMode, setThemeMode] = useState<'dark' | 'light'>(
    (localStorage.getItem('theme') as 'dark' | 'light' | null) ?? 'light',
  );

  const ConditionalAuthContainerWrapper =
    dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
      ? Fragment
      : AuthContainer;

  return (
    <ThemeProvider theme={theme(themeMode)}>
      <CssBaseline />
      <ConditionalAuthContainerWrapper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            position: 'relative',
          }}
        >
          <AppToolbar setThemeMode={setThemeMode} themeMode={themeMode} />
          {numAlerts > 0 && (
            <Paper elevation={0} sx={{ p: 1 }}>
              {emergencyAlerts?.map((emergencyAlert) => (
                <Alert
                  key={emergencyAlert.trace_id}
                  dismissable={emergencyAlert.dismissable}
                  severity={emergencyAlert.severity}
                  title={emergencyAlert.title}
                >
                  {emergencyAlert.body}
                </Alert>
              ))}
            </Paper>
          )}
          {children}
        </Box>
        {/* ToastContainer is INTENTIONALLY inside AuthContainer as AuthContainer relies on
            error statuses to know what content should be shown, and we don't want toasts if things fail */}
        <ToastContainer theme={themeMode} closeOnClick />
      </ConditionalAuthContainerWrapper>
    </ThemeProvider>
  );
}

export default AppContainer;
