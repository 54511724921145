import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import type { TrialFilesGridRow } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetTrialFilesQuery } from 'shared/api/rtkq/trials';

export function processTrialFiles(
  trialFilesRecords: { rows: TrialFilesGridRow[] } | undefined,
) {
  return cloneDeep(trialFilesRecords?.rows);
}

export default function useTrialFilesRows(
  _isOpenPeriod: boolean,
): TrialFilesGridRow[] | undefined {
  const trial = useSelector(selectTrial);
  const { currentData: trialFilesGrid } = useGetTrialFilesQuery(trial.trace_id);

  return useMemo(() => processTrialFiles(trialFilesGrid), [trialFilesGrid]);
}
