import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import { useGetContractsByContractContainerQuery } from 'shared/api/rtkq/contracts';

function useAllEffectiveDatesFromContractContainer(
  contractContainerTraceId: string | undefined,
  contractVersionTraceId: string | undefined,
) {
  const { currentData: allContractVersions } =
    useGetContractsByContractContainerQuery(
      contractContainerTraceId ?? skipToken,
    );

  return useMemo(() => {
    const effectiveDates: string[] = [];
    const aipEffectiveDates: string[] = [];
    if (!allContractVersions) {
      return [effectiveDates, aipEffectiveDates];
    }
    for (const contract of allContractVersions) {
      if (contract.trace_id === contractVersionTraceId) {
        continue;
      }
      if (contract.effective_date) {
        effectiveDates.push(contract.effective_date);
      }
      if (contract.aip_effective_date) {
        aipEffectiveDates.push(contract.aip_effective_date);
      }
    }
    return [effectiveDates, aipEffectiveDates];
  }, [allContractVersions, contractVersionTraceId]);
}

export default useAllEffectiveDatesFromContractContainer;
