import type { ReactElement } from 'react';

import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Select from 'shared/ui/select/Select';

import type { TrialRequest } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

type Props = {
  setTrialRecord: (site: TrialRequest) => void;
  trialRecord: TrialRequest;
};
export default function TrialInfoStep(props: Props): ReactElement {
  const { trialRecord, setTrialRecord } = props;
  const isValid = !!(
    trialRecord.study_id &&
    trialRecord.phase &&
    trialRecord.indication &&
    (trialRecord.is_in_flight
      ? !!(trialRecord.trial_balance && trialRecord.national_clinical_trial_url)
      : true)
  );

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRecord = {
      ...trialRecord,
      [event.target.name]: event.target.value,
    };
    setTrialRecord(newRecord);
  };

  const dataInputFields = [
    <Select
      key="isInFlight"
      label="Has this trial already started?"
      name="is_in_flight"
      size="small"
      value={trialRecord.is_in_flight ? 'YES' : 'NO'}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setTrialRecord({
          ...trialRecord,
          is_in_flight: event.target.value === 'YES',
        })
      }
    >
      <MenuItem value="YES">Yes</MenuItem>
      <MenuItem value="NO">No</MenuItem>
    </Select>,
    <CondorTextField
      key="trialId"
      label="Trial ID"
      name="study_id"
      placeholder="Enter trial ID"
      size="small"
      value={trialRecord.study_id ?? ''}
      required
      onChange={onFieldChange}
    />,
    <CondorTextField
      key="trialName"
      label="Trial Name"
      name="name"
      placeholder="Enter trial name"
      size="small"
      value={trialRecord.name ?? ''}
      onChange={onFieldChange}
    />,
    <Select
      key="phase"
      label="Select phase"
      name="phase"
      size="small"
      value={trialRecord.phase?.toString() ?? ''}
      required
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setTrialRecord({ ...trialRecord, phase: event.target.value })
      }
    >
      <MenuItem value="1">Phase 1</MenuItem>
      <MenuItem value="1/2">Phase 1/2</MenuItem>
      <MenuItem value="2">Phase 2</MenuItem>
      <MenuItem value="2/3">Phase 2/3</MenuItem>
      <MenuItem value="3">Phase 3</MenuItem>
      <MenuItem value="4">Phase 4</MenuItem>
    </Select>,
    <CondorTextField
      key="indication"
      label="Indication"
      name="indication"
      placeholder="Add indication"
      size="small"
      value={trialRecord.indication ?? ''}
      required
      onChange={onFieldChange}
    />,
    <CondorTextField
      key="trial_balance"
      label="Trial Balance"
      name="trial_balance"
      placeholder="Add trial balance"
      required={trialRecord.is_in_flight}
      size="small"
      type="number"
      value={trialRecord.trial_balance ?? ''}
      onChange={onFieldChange}
    />,
    <CondorTextField
      key="nct"
      label="ClinicalTrial.gov URL"
      name="national_clinical_trial_url"
      placeholder="Copy clinicaltrial.gov link here"
      required={trialRecord.is_in_flight}
      size="small"
      value={trialRecord.national_clinical_trial_url ?? ''}
      onChange={onFieldChange}
    />,
  ];
  return (
    <WizardStep
      description="Fields with (*) are required."
      disableNextButton={!isValid}
      header="Enter the trial record."
      nextButtonTextOverride="Continue"
    >
      <form>
        {dataInputFields.map((inputField, i) => (
          <div key={i}>
            <br />
            <FormControl key={`form-${i}`} sx={{ width: 300 }}>
              {inputField}
            </FormControl>
            <br />
            <br />
            <Divider />
          </div>
        ))}
      </form>
    </WizardStep>
  );
}
