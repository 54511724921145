import type { LabContractRequest, LabContractResponse } from 'shared/lib/types';

import { PERIOD_OCC_EXPENSES, PERIOD_OCC_RECON } from './apiTags';
import constructApi from './constructApi';
import { CONTRACT_EXPENSES, CONTRACT_RECON } from './contracts';
import { LAB_COST_MATRIX } from './trials';

const apiEndpointFactory = constructApi<
  LabContractResponse,
  LabContractRequest
>('labcontracts').withTags('LAB_CONTRACTS', [
  LAB_COST_MATRIX,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  CONTRACT_EXPENSES,
  CONTRACT_RECON,
]);

const api = apiEndpointFactory.inject(() => ({
  createLabContract: apiEndpointFactory.create([LAB_COST_MATRIX]),
  updateLabContract: apiEndpointFactory.update([
    LAB_COST_MATRIX,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
  ]),
}));

export const { useCreateLabContractMutation, useUpdateLabContractMutation } =
  api;
