import type { MappedColumn } from 'shared/lib/column-mapper/types';

const columns: MappedColumn[] = [
  { label: 'Site #', value: 'site_number', required: true },
  { label: 'Site Name', value: 'site_name' },
  { label: 'PI Name', value: 'pi_name', required: true },
  { label: 'Recruitment Date', value: 'site_recruited_date', required: true },
  { label: 'Initiation Date', value: 'site_initiated_date', required: true },
  { label: 'Closed Date', value: 'site_closed_date', required: true },
  { label: 'Country', value: 'site_country', required: false },
  {
    label: 'Trial ID', // a trial has a study id that this points to that is unique per customer
    value: 'site_study_id',
    required: false,
  },
];
export default columns;
