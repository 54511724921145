import { useContext } from 'react';

import WizardContext from './WizardContext';

const useWizard = () => {
  const context = useContext(WizardContext);

  if (!context) {
    throw Error('Wrap your step with `Wizard`');
  }
  return context;
};

export default useWizard;
