import type { ReactElement } from 'react';

import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

type Props = {
  descriptorText?: string;
  files: Array<{ name: string; url?: string }>;
};

function FileDisplay(props: Props): ReactElement {
  const { files, descriptorText } = props;

  return (
    <Box component="ul" sx={{ listStyle: 'none' }}>
      {files.map((file) => (
        <Box key={file.name} component="li" display="flex">
          <TextSnippetOutlinedIcon sx={{ mr: 1 }} />
          <Link href={file.url ?? '#'} underline="hover">
            {descriptorText}
            {file.name}
          </Link>
        </Box>
      ))}
    </Box>
  );
}

export default FileDisplay;
