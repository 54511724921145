import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetInvoiceRecordsBySnapshotQuery } from 'shared/api/rtkq/invoicerecords';

import columns from '../columns';

type Props = { snapshotId?: string };

function InvoiceValidationStep(props: Props) {
  const { snapshotId } = props;
  const { currentData: allRecords } = useGetInvoiceRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  );

  return (
    <WizardStep header="No issues detected. Click on Save & Continue to proceed">
      <SnapshotRecordsTable
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default InvoiceValidationStep;
