import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { InvestigatorFeesRow } from 'shared/lib/types';

export default function useCroInvestigatorFeesGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<InvestigatorFeesRow> {
  return useMemo(
    () => ({
      getRowId: 'site_trace_id',
      groupDisplayType: 'groupRows',
      groupTotalRow: 'bottom' as const,
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
      getRowStyle: 'highlightRow',
      autoGroupColumnDef: { pinned: 'left' },
    }),
    [],
  );
}
