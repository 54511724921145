import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import IconButton from 'shared/ui/icon-button/IconButton';

import * as routes from 'routes';

import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';
import useContractVersionBackButton from './hooks/useContractVersionBackButton';

type Props = { isOcc?: boolean };

function ContractVersionBackButton(props: Props) {
  const { isOcc = false } = props;

  const navigate = useNavigate();
  const { contractContainerTraceId, contractContainerPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();
  const { poNumber, vendorName } = useContractVersionBackButton();

  return (
    <Box sx={{ display: 'flex', flexFlow: 'row' }}>
      <IconButton
        onClick={() =>
          navigate(
            isOcc
              ? routes.getOcc()
              : routes.getClinicalExpensesByContractContainer(
                  contractContainerTraceId ??
                    contractContainerPeriodMenuItemTraceId,
                ),
          )
        }
      >
        <ArrowBackIcon sx={{ width: 36 }} />
      </IconButton>
      <Box sx={{ ml: 1 }}>
        <Typography variant="overline">Historical Version</Typography>
        <Box>
          <Typography component="span" variant="subtitle1">
            {vendorName}
          </Typography>
          <Typography component="span" sx={{ ml: 1 }} variant="h6">
            {poNumber}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ContractVersionBackButton;
