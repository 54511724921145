import { useCallback, useMemo } from 'react';

import Box from '@mui/system/Box';
import AxisBottom from '@visx/axis/lib/axis/AxisBottom';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import Group from '@visx/group/lib/Group';
import ordinal from '@visx/scale/lib/scales/ordinal';
import time from '@visx/scale/lib/scales/time';
import type { AnyD3Scale } from '@visx/scale/lib/types/Scale';
import type { NumberValue } from 'd3-scale';

import { differenceInMonthsFractional } from 'shared/helpers/helpers';
import { filterUndefined, parseNullableInt } from 'utils';

import { assumeTransparent } from '../../../../shared/lib/graphing/graphUtils';
import {
  getDateMonthName,
  getYearFromDateString,
} from '../../../../shared/lib/graphing/helper';
import AnimatedBar from '../../../../shared/lib/graphing/shared/AnimatedBar';
import ForecastAnimatedLine from '../../../../shared/lib/graphing/shared/ForecastAnimatedLine';
import GraphLegend from '../../../../shared/lib/graphing/shared/GraphLegend';
import { TIME_FORECAST_CONFIG } from './config';
import type { TimeForecastConfig, TimeForecastData } from './types';

type Props = {
  data: TimeForecastData;
  graphOptions?: TimeForecastConfig;
  height: number;
  width: number;
};

const VERTICAL_OFFSET = 0;
const BAR_HEIGHT = 8;
const CONTRACTED_BAR_HEIGHT = 16;

function TimeForecast(props: Props) {
  const { width, height, data, graphOptions = TIME_FORECAST_CONFIG } = props;

  const steps = useMemo(() => {
    const ltdExpenseStart = new Date(data.ltdExpense.start).valueOf();

    return {
      contracted: {
        start: ltdExpenseStart,
        end: new Date(data.contracted.end).valueOf(),
      },
      ltdExpense: {
        start: ltdExpenseStart,
        end: new Date(data.ltdExpense.end).valueOf(),
      },
      forecasted: {
        end: new Date(data.forecasted.end).valueOf(),
      },
    };
  }, [data]);

  const isGreater = useMemo(
    () => steps.contracted.end < steps.forecasted.end,
    [steps],
  );

  const colorScale = ordinal({
    domain: ['ltdExpense', 'contracted', 'forecasted'],
    range: [
      graphOptions.ltdExpenseColor,
      graphOptions.contractedColor,
      isGreater
        ? graphOptions.overContractColor
        : graphOptions.underContractColor,
    ],
  });

  const { margin } = graphOptions;
  const left = parseNullableInt(margin?.left);
  const right = parseNullableInt(margin?.right);
  const top = parseNullableInt(margin?.top);
  const bottom = parseNullableInt(margin?.bottom);

  const innerWidth = width - left - right;
  const innerHeight = height - top - bottom;

  const xDomain = useMemo(() => {
    const values = [
      steps.contracted.start,
      steps.contracted.end,
      steps.ltdExpense.start,
      steps.ltdExpense.end,
      steps.forecasted.end,
    ];
    const min = Math.min(...values);
    const max = Math.max(...values);
    return [min, max];
  }, [steps]);

  const xScale = time({ nice: true, domain: xDomain, range: [0, innerWidth] });

  const formatDate = (date: Date | NumberValue) => {
    if (date instanceof Date) {
      return date.toLocaleDateString('en-us');
    }
    return date.valueOf().toString();
  };

  const legendShapes = useCallback(
    (datum: ReturnType<AnyD3Scale>) => {
      if ([graphOptions.forecastedText].includes(datum.text)) {
        return { type: 'line' as const };
      }
      return {
        type: 'rect' as const,
        stroke:
          datum.text === graphOptions.contractedText
            ? graphOptions.contractedColorOuter
            : undefined,
      };
    },
    [
      graphOptions.contractedColorOuter,
      graphOptions.contractedText,
      graphOptions.forecastedText,
    ],
  );

  const additionalText = useCallback(
    ({ datum }: ReturnType<AnyD3Scale>) => {
      if (datum === graphOptions.ltdExpenseText) {
        return `${differenceInMonthsFractional(new Date(steps.ltdExpense.start), new Date(steps.ltdExpense.end)).toFixed(0)}mo`;
      }
      if (datum === graphOptions.contractedText) {
        return `${differenceInMonthsFractional(new Date(steps.contracted.start), new Date(steps.contracted.end)).toFixed(0)}mo`;
      }
      if (datum === graphOptions.forecastedText) {
        return `${differenceInMonthsFractional(new Date(steps.contracted.end), new Date(steps.forecasted.end)).toFixed(0)}mo`;
      }
      return '';
    },
    [
      graphOptions.contractedText,
      graphOptions.forecastedText,
      graphOptions.ltdExpenseText,
      steps,
    ],
  );

  if (width < 200) {
    // 'Width of Timeline Chart is too small';
    return null;
  }

  const xScaledLtdExpenseStart = xScale(steps.ltdExpense.start);
  const xScaledLtdExpenseEnd = xScale(steps.ltdExpense.end);
  const xScaledContractedStart = xScale(steps.contracted.start);
  const xScaledContractedEnd = xScale(steps.contracted.end);
  const xScaledForecastedEnd = xScale(steps.forecasted.end);

  const legendColorScale = ordinal<string, string>({
    domain: filterUndefined<string>([
      graphOptions.ltdExpenseText,
      graphOptions.contractedText,
      graphOptions.forecastedText,
    ]),
    range: filterUndefined<string>([
      graphOptions.ltdExpenseColor,
      graphOptions.contractedColor,
      isGreater
        ? graphOptions.overContractColor
        : graphOptions.underContractColor,
    ]),
  });

  // center in the "middle" of the chart (there is only one bar "visually", so we can just compute it, instead of letting it scale)
  const barY = VERTICAL_OFFSET + innerHeight / 2 - BAR_HEIGHT / 2;
  const contractedY =
    VERTICAL_OFFSET + innerHeight / 2 - CONTRACTED_BAR_HEIGHT / 2;

  return (
    <Box sx={{ display: 'grid' }}>
      <GraphLegend
        additionalText={additionalText}
        colorScale={legendColorScale}
        shapes={legendShapes}
      />
      <svg height={height} width={width}>
        <Group left={left} top={top}>
          <GridColumns
            height={innerHeight}
            scale={xScale}
            stroke={graphOptions.columnColor}
          />
          <AnimatedBar
            fill={assumeTransparent(colorScale('contracted'))}
            height={CONTRACTED_BAR_HEIGHT}
            rx={2}
            ry={2}
            stroke={graphOptions.contractedColorOuter}
            strokeWidth="0.5px"
            verticalOffset={VERTICAL_OFFSET}
            width={xScaledContractedEnd - xScaledContractedStart}
            x={xScaledContractedStart}
            y={contractedY}
          />
          <AnimatedBar
            fill={assumeTransparent(colorScale('ltdExpense'))}
            height={BAR_HEIGHT}
            rx={2}
            ry={2}
            verticalOffset={VERTICAL_OFFSET}
            width={xScaledLtdExpenseEnd - xScaledLtdExpenseStart}
            x={xScaledLtdExpenseStart}
            y={barY}
          />
          <ForecastAnimatedLine
            isGreater={isGreater}
            verticalOffset={VERTICAL_OFFSET}
            x={xScaledForecastedEnd}
            y={barY + BAR_HEIGHT / 2}
            overContractColor={assumeTransparent(
              graphOptions.overContractColor,
            )}
            underContractColor={assumeTransparent(
              graphOptions.underContractColor,
            )}
          />
          <AxisBottom
            scale={xScale}
            tickFormat={formatDate}
            top={innerHeight}
            tickComponent={({ formattedValue, x, y: yTick }) => (
              <g transform={`translate(${x}, ${yTick})`}>
                <text
                  fill={graphOptions.textColor}
                  fontSize={graphOptions.fontSize}
                  textAnchor="middle"
                >
                  {getDateMonthName(formattedValue)}
                </text>
                <text
                  dy={16}
                  fill={graphOptions.textColor}
                  fontSize={graphOptions.fontSizeYear}
                  fontWeight={graphOptions.fontWeightBold}
                  textAnchor="middle"
                >
                  {getYearFromDateString(formattedValue)}
                </text>
              </g>
            )}
            tickLabelProps={() => ({
              fill: graphOptions.textColor,
              fontSize: 13,
              fontWeight: 600,
              textAnchor: 'middle',
              verticalAnchor: 'middle',
            })}
            hideAxisLine
            hideTicks
          />
        </Group>
      </svg>
    </Box>
  );
}

export default TimeForecast;
