import Typography from '@mui/material/Typography';

import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withCompanyPageRequiredData from 'shared/api/hocs/withCompanyPageRequiredData';

import ControlCodes from './ControlCodes';
import ControlListingTasks from './ControlListingTasks';

function ControlListingPage() {
  return (
    <PageContentLoadingContainer>
      <Typography mb={3} variant="h4">
        Control listing
      </Typography>
      <ControlCodes />
      <ControlListingTasks />
    </PageContentLoadingContainer>
  );
}

export default withCompanyPageRequiredData(ControlListingPage);
