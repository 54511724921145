import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { CompanyFilesGridRow } from 'shared/lib/types';

export default function useCompanyFilesGridOptions(
  _isOpenPeriod: boolean,
): CondorGridOptions<CompanyFilesGridRow> {
  return useMemo(() => ({ getRowId: 'id' }), []);
}
