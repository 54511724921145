import Box from '@mui/material/Box';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';

import CroContractVersionFileContents from './CroContractVersionFileContents';

function CroContractFilesSection() {
  const { contractVersionPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();

  return (
    <DashboardCard title="Contract Files">
      <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
        <CroContractVersionFileContents
          parentMenuItem={contractVersionPeriodMenuItemTraceId}
        />
      </Box>
    </DashboardCard>
  );
}

export default CroContractFilesSection;
