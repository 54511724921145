import Close from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useSelector } from 'react-redux';

import IconButton from 'shared/ui/icon-button/IconButton';

import {
  selectCommentsHeader,
  selectCommentsTitle,
} from 'shared/state/slices/commentSlice';

type Props = { onClose?: () => void };

function CommentsHeader(props: Props) {
  const { onClose } = props;
  const header = useSelector(selectCommentsHeader);
  const title = useSelector(selectCommentsTitle);

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        bgcolor: 'grey.100',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
    >
      <Box flex={1}>
        {!!title && (
          <Typography
            color="text.secondary"
            sx={{ textTransform: 'uppercase' }}
            variant="subtitle2"
          >
            {title}
          </Typography>
        )}
        {!!header && <Typography variant="subtitle1">{header}</Typography>}
      </Box>
      {onClose && (
        <IconButton variant="tertiary" onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
}

export default CommentsHeader;
