import type { SyntheticEvent } from 'react';

import Tab from '@mui/material/Tab';
import { useNavigate, useParams } from 'react-router-dom';

import Tabs from 'shared/ui/tabs/Tabs';

import { getDevDemos } from 'routes';

import DemosByTitleKey from './DemosByTitleKey';

import styles from './DemoViewer.module.scss';

function DemoViewer() {
  const { demoKey } = useParams();
  const navigate = useNavigate();

  const handleChange = (__event: SyntheticEvent, newKey: string) => {
    navigate(`${getDevDemos()}/${newKey}`);
  };

  const activeDemo = demoKey ? DemosByTitleKey[demoKey] : null;

  return (
    <div className={styles.DemoViewer}>
      <h1>Tech Demos</h1>
      <div className={styles.InnerWrapper}>
        <div className={styles.DemoSelector}>
          <h2>Demos</h2>
          <Tabs
            orientation="vertical"
            value={demoKey ?? false}
            variant="scrollable"
            onChange={handleChange}
          >
            {Object.entries(DemosByTitleKey).map(([titleKey, { title }]) => (
              <Tab key={titleKey} label={title} value={titleKey} />
            ))}
          </Tabs>
        </div>
        <div className={styles.Demo}>{activeDemo?.wrapper}</div>
      </div>
    </div>
  );
}

export default DemoViewer;
