import type { InvoiceSnapshotsResponse } from 'shared/lib/types';

import { LATEST_INVOICE_SNAPSHOT_RECORD } from './companies';
import constructApi from './constructApi';

const PATH = 'invoicesnapshots';

const apiEndpointFactory = constructApi<InvoiceSnapshotsResponse>(
  PATH,
).withTags('INVOICE_SNAPSHOTS', [LATEST_INVOICE_SNAPSHOT_RECORD]);
const api = apiEndpointFactory.inject(() => ({
  createInvoiceSnapshotFromCsv: apiEndpointFactory.create(
    [LATEST_INVOICE_SNAPSHOT_RECORD],
    'new_from_csv',
  ),
  deleteInvoiceSnapshot: apiEndpointFactory.delete(),
}));

export const {
  useDeleteInvoiceSnapshotMutation,
  useCreateInvoiceSnapshotFromCsvMutation,
} = api;
