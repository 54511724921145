import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Badge, { badgeClasses } from '@mui/material/Badge';
import Stack from '@mui/material/Stack';

import CommentsWidget from 'shared/components/comments/CommentsWidget';

import type { CommentLocationType } from 'shared/lib/types';

type Props = { comments: ChecklistComment; issues?: number };

function TaskIssues(props: Props) {
  const { comments, issues } = props;
  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      gap={3}
      justifyContent="center"
    >
      {!!issues && (
        <Badge
          badgeContent={issues}
          sx={{
            [`& .${badgeClasses.badge}`]: {
              backgroundColor: (theme) => theme.palette.common.black,
              color: (theme) => theme.palette.warning.main,
            },
          }}
        >
          <ReportProblemIcon color="warning" />
        </Badge>
      )}
      <CommentsWidget
        location={comments.location}
        locationSlug={comments.locationSlug}
      />
    </Stack>
  );
}

export type ChecklistComment = {
  location: CommentLocationType;
  locationSlug: string;
};

export default TaskIssues;
