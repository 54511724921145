import { useMemo } from 'react';

import type {
  CellClickedEvent,
  ValueGetterParams,
} from '@ag-grid-community/core';

import {
  getSelectCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { UserStatusOptions } from 'shared/lib/types';

export default function useUserAccessColumnDefs(
  _isOpenPeriod: boolean,
  setShowTrialAccessDialog: (showTrialAccessDialog: {
    state: boolean;
    trace_id: string;
    name: string;
    trials: string[];
  }) => void,
): CondorColDef[] {
  return useMemo(
    () => [
      {
        headerName: 'User',
        flex: 1,
        children: [
          { headerName: 'Name', field: 'name' },
          { headerName: '', field: 'company_name', rowGroup: true, hide: true },
          {
            field: 'status',
            headerName: 'Status',
            ...getSelectCellConfig(),
            ...makeEditableIf(
              (params) =>
                params.data !== undefined && params.data.is_superuser === false,
            ),
            cellRenderer: 'AgGridUserStatusCellRenderer',
            cellEditorParams: (_params: ValueGetterParams) => ({
              options: Object.entries(UserStatusOptions).map(
                ([value, label]) => ({ value, label }),
              ),
            }),
          },
          { headerName: 'Company', field: 'third_party_company_list' },
          {
            headerName: 'Trial Access',
            field: 'trial_access',
            cellRenderer: 'AgGridTrialAccessCellRenderer' as const,
            cellRendererParams: {
              formatter: 'multiple_trials' as const,
              useEmDash: true,
            },
            onCellClicked: (event: CellClickedEvent) => {
              // if key exists, its a group so we don't want to
              // handle on click event for that
              if (event.node.key === null) {
                setShowTrialAccessDialog({
                  state: true,
                  trace_id: event.data.trace_id,
                  name: event.data.name,
                  trials: event.data.trial_access,
                });
              }
            },
          },
        ],
      },
      {
        headerName: 'Profile',
        children: [
          { headerName: 'Email', field: 'email' },
          { headerName: 'Title', field: 'title' },
        ],
      },
      {
        headerName: 'Company Permissions',
        children: [
          {
            headerName: 'Can manage users and permissions',
            field: 'can_edit_company_users_and_permissions',
            headerTooltip:
              'User can add, edit, and remove company users, manage user permissions, and assign preparers and reviewers to trials. User will be able access all trials.',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
          },
          {
            headerName: 'Can edit company settings',
            field: 'can_edit_company_settings',
            headerTooltip:
              'User can edit the company settings, like the control listing',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
          },
          {
            headerName: 'Can edit company level-trial info',
            field: 'can_edit_company_level_trial_info',
            headerTooltip:
              'User can upload PO and invoice listings, edit FX rates and edit G/L accounts',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
          },
          {
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerName: 'Can delete company-level trial info',
            field: 'can_delete_company_level_trial_info',
            headerTooltip:
              'User can delete G/L accounts and company files, including PO and invoice listings',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
          },
        ],
      },
      {
        headerName: 'Trial Permissions',
        children: [
          {
            headerName: 'Can manage trial roles',
            field: 'can_manage_trial_roles',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User is allowed to change who the preparer and reviewer are on any trial they have access to',
          },
          {
            headerName: 'Can be preparer',
            field: 'can_signoff_as_preparer',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User is allowed to be Preparer for any trial they have access to',
          },
          {
            headerName: 'Can be reviewer',
            field: 'can_signoff_as_reviewer',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User is allowed to be Reviewer for any trial they have access to',
          },
          {
            headerName: 'Can edit trials',
            field: 'can_edit_trials',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User is allowed edit contracts, update the site & lab cost matrix, manage visits & procedures, upload EDCs, change activity drivers, and make reconciliation adjustments',
          },
          {
            headerName: 'Can delete trial info',
            field: 'can_delete_trial_info',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User can delete CRO and OCC contracts, sites, labs, and trial files',
          },
          {
            headerName: 'Can open new periods',
            field: 'can_open_period',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User can open new periods for trials they have access to',
          },
          {
            headerName: 'Can close periods',
            field: 'can_close_period',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User can close periods for trials they have access to',
          },
          {
            headerName: 'Can re-open periods',
            field: 'can_reopen_period',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User can re-open periods for trials they have access to',
          },
          {
            headerName: 'Can comment',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            field: 'can_add_comments',
            headerTooltip:
              'User can comment and add attachments anywhere commenting is available at both the company and trial level',
          },
          {
            headerName: 'Can edit historical values',
            field: 'can_edit_historic_values',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User can add historical expense data on the trial dashboard',
          },
          {
            headerName: 'Can access forecasts',
            field: 'can_access_forecasting',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User can view forecasts for trials they have access to',
          },
          {
            headerName: 'Can create and edit forecasts',
            field: 'can_create_and_edit_forecasting',
            ...makeEditableIf(
              (node) =>
                node.data !== undefined && node.data.is_superuser === false,
            ),
            headerTooltip:
              'User can create, edit, lock, and unlock forecasts for trials they have access to',
          },
        ],
      },
    ],
    [setShowTrialAccessDialog],
  );
}
