import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import type { TrialHistoricalValuesRow } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetHistoricalExpensesQuery } from 'shared/api/rtkq/trials';

export default function useHistoricalValuesRows():
  | TrialHistoricalValuesRow[]
  | undefined {
  const trial = useSelector(selectTrial);
  const { currentData: historicalValues } = useGetHistoricalExpensesQuery(
    trial.trace_id,
  );

  return useMemo(() => cloneDeep(historicalValues?.rows), [historicalValues]);
}
