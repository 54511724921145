import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type {
  ManageExpectedVisitGridResponse,
  ManageExpectedVisitRow,
} from 'shared/lib/types';

import { useGetManageExpectedVisitsGridQuery } from 'shared/api/rtkq/periods';

export function processExpectedVisitRows(
  expectedVisitRows?: ManageExpectedVisitGridResponse,
  showSelected?: boolean,
) {
  const expectedVisitRowsCloned = cloneDeep(expectedVisitRows?.rows);
  return expectedVisitRows
    ? showSelected
      ? expectedVisitRowsCloned?.filter((row) => row.trace_id !== undefined)
      : expectedVisitRowsCloned
    : undefined;
}

export default function useExpectedVisitGridRows():
  | ManageExpectedVisitRow[]
  | undefined {
  const period = useSelector(selectPeriod);
  const { currentData: expectedVisitRows } =
    useGetManageExpectedVisitsGridQuery(period.trace_id);
  return useMemo(
    () => processExpectedVisitRows(expectedVisitRows, false),
    [expectedVisitRows],
  );
}
