import type { VendorResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'vendors';

export const VENDOR_TAG = 'VENDORS';

const apiEndpointFactory =
  constructApi<VendorResponse>(PATH).withTags(VENDOR_TAG);
export const api = apiEndpointFactory.inject(() => ({
  createVendor: apiEndpointFactory.create(),
  getVendorsByVendorType: apiEndpointFactory.getBy('vendor_type'),
  getVendorsByTrial: apiEndpointFactory.getBy('trial'),
  getVendorsWithFilters: apiEndpointFactory.getWithFilters('name'),
  updateVendor: apiEndpointFactory.update(),
}));

export const { useCreateVendorMutation, useGetVendorsWithFiltersQuery } = api;
