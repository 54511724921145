import type { BudgetForecastData } from 'forecasting/components/graphing/budget-forecast/types';

export const budgetForecast1: BudgetForecastData[] = [
  {
    vendorName: 'CTI Clinical Trial Services',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: {
      startPercentage: 0,
      endPercentage: (300_000 / 1_000_000) * 100,
    },
    forecasted: {
      startPercentage: 0,
      endPercentage: (1_600_000 / 1_000_000) * 100,
    },
  },
  {
    vendorName: 'Clintrack/Thermo Fisher',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: {
      startPercentage: 0,
      endPercentage: (900_000 / 1_000_000) * 100,
    },
    forecasted: { startPercentage: 0, endPercentage: 100 },
  },
  {
    vendorName: 'Medidata, Inc.',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: {
      startPercentage: 0,
      endPercentage: (400_000 / 1_000_000) * 100,
    },
    forecasted: {
      startPercentage: 0,
      endPercentage: (1_800_100 / 1_000_000) * 100,
    },
  },
  {
    vendorName: 'Bioinnovat',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: {
      startPercentage: 0,
      endPercentage: (200_000 / 1_000_000) * 100,
    },
    forecasted: {
      startPercentage: 0,
      endPercentage: (1_400_100 / 1_000_000) * 100,
    },
  },
  {
    vendorName: 'Eurofins',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: {
      startPercentage: 0,
      endPercentage: (200_000 / 1_000_000) * 100,
    },
    forecasted: {
      startPercentage: 0,
      endPercentage: (400_500 / 1_000_000) * 100,
    },
  },
  {
    vendorName: 'Other Company',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: { startPercentage: 0, endPercentage: 0 },
    forecasted: {
      startPercentage: 0,
      endPercentage: (400_500 / 1_000_000) * 100,
    },
  },
];

export const budgetForecast2: BudgetForecastData[] = [
  {
    vendorName: 'Bioinnovat',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: {
      startPercentage: 0,
      endPercentage: (603_000 / 1_000_000) * 100,
    },
    forecasted: {
      startPercentage: 0,
      endPercentage: (600_000 / 1_000_000) * 100,
    },
  },
  {
    vendorName: 'Eurofins',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: {
      startPercentage: 0,
      endPercentage: (450_000 / 1_000_000) * 100,
    },
    forecasted: {
      startPercentage: 0,
      endPercentage: (400_500 / 1_000_000) * 100,
    },
  },
];

export const budgetForecast3: BudgetForecastData[] = [
  {
    vendorName: 'Bioinnovat',
    contracted: { startPercentage: 0, endPercentage: 100 },
    ltdExpense: {
      startPercentage: 0,
      endPercentage: (200_000 / 1_000_000) * 100,
    },
    forecasted: {
      startPercentage: 0,
      endPercentage: (600_000 / 1_000_000) * 100,
    },
  },
];
