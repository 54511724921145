import { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Select from 'shared/ui/select/Select';

import type { LabRequest } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetLabsByTrialQuery } from 'shared/api/rtkq/labs';

type Props = {
  disableNextButton?: boolean;
  onSave: ((lab: LabRequest | undefined) => void) | undefined;
};

function LabInfoStep(props: Props) {
  const trial = useSelector(selectTrial);
  const [labRecord, setLabRecord] = useState<LabRequest>();
  const { currentData: labs, isLoading } = useGetLabsByTrialQuery(
    trial.trace_id,
  );
  const { disableNextButton = false, onSave } = props;

  function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: trace_id } = event.target;
    const lab = labs?.find((lab) => lab.trace_id === trace_id);
    setLabRecord(lab);
  }

  useEffect(() => {
    setLabRecord(labs?.[0]);
  }, [labs]);

  return (
    <WizardStep
      disableNextButton={disableNextButton}
      nextButtonTextOverride="Create Version"
      onNext={() => onSave?.(labRecord)}
    >
      <Typography sx={{ mb: 2 }} variant="subtitle1">
        Which lab do you want to add a version for?
      </Typography>
      {!isLoading && (
        <FormControl sx={{ width: 400 }}>
          <Select
            name="Lab"
            placeholder="Select lab"
            value={labRecord?.trace_id ?? ''}
            onChange={onFieldChange}
          >
            {labs?.map((lab) => (
              <MenuItem key={lab.trace_id} value={lab.trace_id}>
                {lab.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </WizardStep>
  );
}

export default LabInfoStep;
