import { skipToken } from '@reduxjs/toolkit/query';

import type { SnapshotRecordType } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';
import UploadCsvReviewGrid from 'shared/wizards/csv-upload-wizards/upload-csv-review-grid/UploadCsvReviewGrid';

import { useGetProceduresEdcRecordsBySnapshotQuery } from 'shared/api/rtkq/proceduresedcrecords';

import columns from '../columns';

type Props = {
  isAllInOneFile: boolean;
  snapshotId?: string;
  onComplete: () => void;
};

function EdcProceduresReviewStep(props: Props) {
  const { onComplete, snapshotId, isAllInOneFile } = props;

  const { currentData: allRecords } = useGetProceduresEdcRecordsBySnapshotQuery(
    snapshotId ?? skipToken,
  );

  return (
    <WizardStep
      header="Review your EDC procedures to make sure everything looks right."
      onNext={onComplete}
    >
      <UploadCsvReviewGrid
        headers={columns(isAllInOneFile)}
        rows={allRecords as SnapshotRecordType[]}
      />
    </WizardStep>
  );
}

export default EdcProceduresReviewStep;
