import { useEffect, useRef, useState } from 'react';

import type { ICellEditorParams } from '@ag-grid-community/core';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Box from '@mui/material/Box';
import { inputClasses } from '@mui/material/Input';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { textFieldClasses } from 'shared/components/text-field/CondorTextField';
import Select from 'shared/ui/select/Select';

import type { LabelValueOption } from 'shared/lib/types';

type Props = CustomCellEditorProps &
  Pick<ICellEditorParams, 'api' | 'colDef' | 'data'> & {
    options?: LabelValueOption[];
    values?: string[] | ((data: unknown) => string[]);
  };

function AgGridSelectEditor(props: Props) {
  const { options, data, onValueChange, value, values, colDef, api } = props;
  const [closed, setClosed] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!closed) {
      return;
    }

    api.stopEditing();
  }, [api, closed]);

  function getOptions() {
    if (typeof options !== 'undefined') {
      return options;
    }

    let _values: string[] | undefined;
    if (typeof values === 'function') {
      _values = values(data);
    } else {
      _values = values;
    }

    if (colDef.refData) {
      const refDataOptions = Object.entries(colDef.refData).map(
        ([val, label]) => ({
          value: val,
          label,
        }),
      ) as LabelValueOption[];

      return _values === undefined
        ? refDataOptions
        : refDataOptions.filter((option) =>
            (_values ?? []).includes(option.value),
          );
    }

    if (_values) {
      return _values.map((val) => ({
        label: val,
        value: val,
      })) as LabelValueOption[];
    }

    return [];
  }

  return (
    <Select
      SelectProps={{ defaultOpen: true, onClose: () => setClosed(true) }}
      size="small"
      sx={{ [`&.${textFieldClasses.root}`]: { height: '100%' } }}
      value={value}
      variant="standard"
      InputProps={{
        ref: refInput,
        sx: {
          [`& .${inputClasses.input}`]: {
            paddingLeft: 0,
            textAlign: 'right',
            fontSize: '12px',
          },
          [`&.${inputClasses.root}`]: { height: 'inherit' },
        },
      }}
      autoFocus
      fullWidth
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onValueChange(event.target.value)
      }
    >
      <MenuItem
        value=""
        sx={{
          [`&.${menuItemClasses.disabled}`]: {
            display: 'none', // do not show when the input is open
          },
        }}
        disabled
      >
        <span>Select</span>
      </MenuItem>
      {getOptions().map((option) => (
        <MenuItem
          key={option.value}
          disabled={option.disabled}
          value={option.value}
          disableGutters
        >
          <Stack display="flex" flexDirection="row">
            <Box alignItems="center" display="flex" mx={1} width={24}>
              {typeof value === 'string' && option.value === value && (
                <CheckOutlinedIcon fontSize="small" />
              )}
            </Box>
            <Box flex={1} pr={1}>
              {option.label}
            </Box>
          </Stack>
        </MenuItem>
      ))}
    </Select>
  );
}

export default AgGridSelectEditor;
