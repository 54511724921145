import { type FocusEvent, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query/react';
import uniqBy from 'lodash/uniqBy';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import Button from 'shared/ui/button/Button';
import IconButton from 'shared/ui/icon-button/IconButton';

import styles from 'accruals/wizards/contract-version-wizard/ContractVersionWizard.module.scss';
import { poNumberDuplicatesErrorMessage } from 'accruals/wizards/contract-version-wizard/validators';
import currencies from 'currencies';
import type {
  ContractContainerRequest,
  ContractContainerResponse,
  CurrencyCode,
  DropdownOption,
  TraceId,
  VendorType,
} from 'shared/lib/types';

import { useGetVendorsWithFiltersQuery } from 'shared/api/rtkq/vendors';

import NewVendorDialog from './NewVendorDialog';
import type { Vendor } from './VendorField';
import VendorField, { ADD_NEW_NAME } from './VendorField';
import useAllPoNumbersFromTrial from './hooks/useAllPoNumbersFromTrial';

type CurrencyCodeOption = CurrencyCode | '';

type Fields = {
  contractId?: string;
  poNumbers?: Record<string, string[] | undefined>;
  contact?: Record<string, string[] | undefined>;
  contactEmail?: Record<string, string[] | undefined>;
  currency?: string;
};

type Props = {
  contractContainer?: ContractContainerResponse;
  setContactsToDelete: (contactsToDelete: string[]) => void;
  trialId?: TraceId;
  vendorType: VendorType;
  onChangeData: (
    currentData: Partial<ContractContainerRequest> & {
      contacts: VendorContactParams[];
    },
  ) => void;
  onChangeValidity: (isValid: boolean) => void;
};

const CURRENCIES: Array<{ value: CurrencyCodeOption; label: string }> = [
  { value: '', label: '' },
  ...currencies.map((currency) => ({ value: currency, label: currency })),
];

function ContractContainerForm(props: Props) {
  const {
    trialId,
    onChangeValidity,
    onChangeData,
    vendorType,
    contractContainer,
    setContactsToDelete,
  } = props;

  const [showNewVendorDialog, setShowNewVendorDialog] =
    useState<boolean>(false);
  const [contacts, setContacts] = useState<VendorContactParams[]>(
    contractContainer?.vendor_contacts?.map((contact) => ({
      contact: contact.name,
      email: contact.email,
      trace_id: contact.trace_id,
    })) ?? [],
  );
  const [poNumbers, setPoNumbers] = useState<string[]>(
    contractContainer?.po_numbers ?? [''],
  );
  const [selectedVendor, setSelectedVendor] = useState<Vendor | undefined>(
    contractContainer?.vendor,
  );
  const [contractId, setContractId] = useState<string>(
    contractContainer?.contract_id ?? '',
  ); // Note: this is not a foreign key to any model, it's just a user-provided string on contract container
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode | ''>(
    contractContainer?.currency ?? '',
  );
  const [fieldErrors, setFieldErrors] = useState<Fields>({});
  const allPoNumbersFromTrial = useAllPoNumbersFromTrial(
    contractContainer?.trace_id,
  );

  const { currentData: vendorList } = useGetVendorsWithFiltersQuery(
    trialId ? { trial: trialId, vendor_type: vendorType } : skipToken,
  );

  useEffect(() => {
    validateData();
    reportChanges();
  }, [contractId, selectedVendor, poNumbers, contacts, currencyCode]);

  function getVendorsToField() {
    if (!vendorList) {
      return [{ name: ADD_NEW_NAME }];
    }
    return [...uniqBy(vendorList, 'trace_id'), { name: ADD_NEW_NAME }];
  }

  function reportChanges() {
    onChangeData({
      trace_id: contractContainer?.trace_id,
      contract_id: contractId,
      vendor: selectedVendor?.trace_id ?? undefined,
      po_numbers: poNumbers,
      contacts,
      currency: currencyCode,
    });
  }

  const poNumbersErrors =
    fieldErrors.poNumbers && Object.keys(fieldErrors.poNumbers).length > 0;
  const poNumbersAreValid =
    poNumbers.length > 0 && poNumbers.every(poNumberIsValid);
  const contactsAreValid =
    selectedVendor != null &&
    contacts.every((contact) => !!contact.contact.length);

  function validateData() {
    const isDataValid =
      poNumbersAreValid &&
      contactsAreValid &&
      !poNumbersErrors &&
      !!currencyCode.length;
    onChangeValidity(isDataValid);
  }

  function poNumberIsValid(poNumber: string) {
    return (
      poNumber.length > 0 &&
      poNumbers.filter((number) => number === poNumber).length === 1
    );
  }

  function onDeletePoNumber(index: number) {
    setPoNumbers((prevState) => {
      // don't allow deleting the "last" PO number
      if (prevState.length === 1) {
        return prevState;
      }

      return prevState.filter((_, i) => i !== index);
    });
  }

  function onDeleteVendorContact(index: number) {
    if (index < 0 || index >= contacts.length) {
      return;
    }

    const _deletedContacts = contacts.splice(index, 1);
    setContactsToDelete(
      _deletedContacts.map((contact) => contact.trace_id).filter(Boolean),
    );
    setContacts([...contacts]);
    setFieldErrors((prevState) => {
      const { contact, contactEmail, ...rest } = prevState;
      return { ...rest };
    });

    validateData();
  }

  function onAddVendorContact() {
    if (!selectedVendor) {
      return;
    }

    setContacts((prevState) => [...prevState, { contact: '', email: '' }]);
  }

  function onAddPoNumber() {
    setPoNumbers((prevState) => [...prevState, '']);
  }

  function onAddVendor() {
    setShowNewVendorDialog(true);
  }

  function onChangeContractId(event: FocusEvent<HTMLInputElement>) {
    const { value } = event.target;
    setContractId(value);
  }

  function onSaveVendor(vendor: Vendor) {
    setSelectedVendor(vendor);
    setShowNewVendorDialog(false);
  }

  function isUniqueVendorName(name: string): boolean {
    if (!vendorList) {
      return true;
    }

    return !vendorList.find((vendor) => vendor.name === name);
  }

  const onChangeCurrency = (
    _event: React.SyntheticEvent,
    value: DropdownOption<CurrencyCodeOption>,
  ) => setCurrencyCode(value.value);

  const selectedCurrency: DropdownOption<CurrencyCodeOption> | undefined =
    useMemo(
      () =>
        CURRENCIES.find(
          (currencyOption) => currencyOption.value === currencyCode,
        ),
      [currencyCode],
    );

  const onChangePoNumber = (index: number, value: string) => {
    const newPoNumbers = [...poNumbers];
    newPoNumbers[index] = value;
    setPoNumbers([...newPoNumbers]);

    if (value.trim()) {
      if (poNumbers.filter((number) => number === value).length === 1) {
        setFieldErrors((prevState) => {
          const previousPoNumbers = prevState.poNumbers ?? {};
          previousPoNumbers[index] = [
            'PO Number already exists. Please change it or delete the duplicate.',
          ];
          return { ...prevState, poNumbers: previousPoNumbers };
        });

        return;
      }

      const poNumberErrorMessage = poNumberDuplicatesErrorMessage(
        value,
        allPoNumbersFromTrial,
      );
      if (poNumberErrorMessage) {
        setFieldErrors((prevState) => {
          const previousPoNumbers = prevState.poNumbers ?? {};
          previousPoNumbers[index] = [poNumberErrorMessage];
          return { ...prevState, poNumbers: previousPoNumbers };
        });
      }
    } else {
      setFieldErrors((prevState) => {
        const previousPoNumbers = prevState.poNumbers ?? {};
        previousPoNumbers[index] = [
          'PO Number cannot be blank. Please enter a value or delete it.',
        ];
        return { ...prevState, poNumbers: previousPoNumbers };
      });
    }

    if (fieldErrors.poNumbers?.[index]) {
      delete fieldErrors.poNumbers[index];
      setFieldErrors({ ...fieldErrors });
    }
  };

  const onChangeContact =
    (index: number) => (event: FocusEvent<HTMLInputElement>) => {
      const { value, validity } = event.target;
      const newContacts = [...contacts];
      newContacts[index].contact = value;
      setContacts(newContacts);

      if (!validity.valid) {
        setFieldErrors((prevState) => {
          const contact = prevState.contact ?? {};
          contact[index] = ['Values is too short.'];
          return { ...prevState, contact };
        });
        return;
      }

      if (fieldErrors.contact?.[index]) {
        delete fieldErrors.contact[index];
        setFieldErrors({ ...fieldErrors });
      }
    };

  const onChangeContactEmail =
    (index: number) => (event: FocusEvent<HTMLInputElement>) => {
      const { value, validity } = event.target;
      const newContacts = [...contacts];
      newContacts[index].email = value;
      setContacts(newContacts);

      if (!validity.valid) {
        setFieldErrors((prevState) => {
          const contactEmail = prevState.contactEmail ?? {};
          contactEmail[index] = ['Email is invalid.'];
          return { ...prevState, contactEmail };
        });
        return;
      }

      if (fieldErrors.contactEmail?.[index]) {
        delete fieldErrors.contactEmail[index];
        setFieldErrors({ ...fieldErrors });
      }
    };

  return (
    <div className={styles.ContractVersionWizard}>
      <div className={styles.formRow}>
        <div className={`${styles.formLabel} ${styles.required}`}>Currency</div>
        <div className={styles.rightPanel}>
          <FormControl className={styles.formInput}>
            <Autocomplete
              defaultValue={{ value: 'USD', label: 'USD' } as const}
              errorMsg={fieldErrors.currency}
              label="Select Currency"
              options={CURRENCIES}
              value={selectedCurrency}
              disableClearable
              onChange={onChangeCurrency}
            />
          </FormControl>
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.formLabel}>Contract ID</div>
        <div className={styles.rightPanel}>
          <FormControl
            className={[styles.formInput, styles.fullWidth].join(' ')}
          >
            <CondorTextField
              autoComplete="off"
              errors={fieldErrors.contractId}
              label="Enter value"
              size="small"
              value={contractId}
              onChange={onChangeContractId}
            />
          </FormControl>
        </div>
      </div>
      <div className={styles.formRow}>
        <Box className={styles.formLabel}>
          <div className={styles.required}>PO number(s)</div>
          <Typography color="text.secondary" variant="body1">
            This should match the PO # in your accounting system. If you don’t
            have a PO number, enter the Contract ID
          </Typography>
        </Box>
        <div className={styles.rightPanel}>
          {poNumbers.map((poNumber, index) => (
            <Stack
              key={index}
              alignItems="flex-start"
              flexDirection="row"
              gap={2}
              mb={2}
            >
              <FormControl
                className={[styles.formInput, styles.fullWidth].join(' ')}
              >
                <CondorTextField
                  autoComplete="off"
                  errors={fieldErrors.poNumbers?.[index.toString()]}
                  label="Enter value"
                  required={poNumbers.length === 1}
                  size="small"
                  value={poNumber}
                  onChange={(event) =>
                    onChangePoNumber(index, event.target.value)
                  }
                />
              </FormControl>
              <IconButton
                disabled={poNumbers.length === 1}
                size="small"
                sx={{ mt: 1 }}
                onClick={() => onDeletePoNumber(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}
          <Button
            size="small"
            startIcon={<AddIcon />}
            testId="add_po_number"
            variant="text"
            onClick={onAddPoNumber}
          >
            Add PO number
          </Button>
        </div>
      </div>
      <Box borderBottom="none !important" className={styles.formRow}>
        <div className={`${styles.formLabel} ${styles.required}`}>Vendor</div>
        <div className={styles.rightPanel}>
          <FormControl
            className={[styles.formInput, styles.fullWidth].join(' ')}
          >
            <VendorField
              vendor={selectedVendor}
              vendors={getVendorsToField()}
              onAddVendor={onAddVendor}
              onChange={setSelectedVendor}
            />
          </FormControl>
          {contacts.map((contact, contactIndex) => {
            const contactError = fieldErrors.contact?.[contactIndex];
            const contactEmailError = fieldErrors.contactEmail?.[contactIndex];
            return (
              <Stack
                key={contactIndex}
                alignItems="flex-start"
                flexDirection="row"
                justifyContent="space-between"
                mt={2}
              >
                <FormControl>
                  <CondorTextField
                    autoComplete="off"
                    errors={contactError}
                    inputProps={{ minLength: 3 }}
                    label="Vendor contact"
                    placeholder="First, last name"
                    size="small"
                    value={contact.contact}
                    onChange={onChangeContact(contactIndex)}
                  />
                </FormControl>
                <FormControl>
                  <CondorTextField
                    autoComplete="off"
                    errors={contactEmailError}
                    label="Vendor contact email"
                    placeholder="Enter email"
                    size="small"
                    type="email"
                    value={contact.email}
                    onChange={onChangeContactEmail(contactIndex)}
                  />
                </FormControl>
                <IconButton
                  size="small"
                  sx={{ mt: 1 }}
                  onClick={() => onDeleteVendorContact(contactIndex)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          })}
          <Button
            disabled={!contactsAreValid}
            size="small"
            startIcon={<AddIcon />}
            sx={{ mt: 2 }}
            testId="add_vendor_contact"
            variant="text"
            onClick={onAddVendorContact}
          >
            Add vendor contact
          </Button>
        </div>
      </Box>
      {showNewVendorDialog && (
        <NewVendorDialog
          isUniqueName={isUniqueVendorName}
          vendorType={vendorType}
          onClose={() => setShowNewVendorDialog(false)}
          onSave={onSaveVendor}
        />
      )}
    </div>
  );
}

export type VendorContactParams = {
  contact: string;
  email: string;
  trace_id?: string;
};
export default ContractContainerForm;
