import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import type { SxProps, TypographyVariant } from '@mui/material/styles';
import Box from '@mui/system/Box';
import { Fragment } from 'react/jsx-runtime';

import Chip from 'shared/ui/chip/Chip';

import { formatMonthDayYear } from 'shared/helpers/helpers';
import type { UploadStatusFile } from 'shared/lib/types';

type Props = {
  files?: UploadStatusFile[];
  labelVariant?: TypographyVariant;
  status: 'notUploaded' | 'uploaded';
  sx?: SxProps;
  title: string;
};

function UploadTaskStatus(props: Props) {
  const { title, status, sx, labelVariant = 'body2', files } = props;
  const uploaded = status === 'uploaded';
  const color = uploaded ? 'success' : 'warning';
  const icon = uploaded ? <CheckCircleIcon /> : <ErrorIcon />;
  const label = uploaded ? 'Uploaded' : 'Not uploaded';

  return (
    <Box sx={{ mb: 2 }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={sx}
      >
        <Typography variant={labelVariant}>{title}</Typography>
        <Chip color={color} icon={icon} label={label} size="small" />
      </Box>
      {!!files &&
        files.map(
          ({ procedure_name, file_name, file_url, rows_count, created_at }) => {
            const procedureText = procedure_name ?? '';
            const rowText =
              rows_count !== undefined && rows_count !== null
                ? `${rows_count} row${rows_count > 1 ? 's' : ''}`
                : '';
            const createdText =
              created_at !== undefined
                ? `Uploaded on ${formatMonthDayYear(created_at)}`
                : '';

            return (
              <Fragment key={file_url}>
                {!!file_name && !!file_url && (
                  <Box
                    component="a"
                    href={file_url}
                    rel="noreferrer"
                    sx={{ display: 'block' }}
                    target="_blank"
                    download
                  >
                    {file_name}
                  </Box>
                )}
                <Typography
                  color="text.secondary"
                  variant="caption"
                >{`${procedureText.length > 0 ? `${procedureText} • ` : ''}${rowText.length > 0 ? `${rowText} • ` : ''}${createdText}`}</Typography>
              </Fragment>
            );
          },
        )}
    </Box>
  );
}

export default UploadTaskStatus;
