import { useState } from 'react';

import PeopleOutline from '@mui/icons-material/PeopleOutline';
import { useSelector } from 'react-redux';

import PageTitle, {
  periodToBreadcrumbString,
} from 'shared/components/page-titles/PageTitle';
import TrialTeamModal from 'shared/components/page-titles/components/TrialTeamModal';
import IconButton from 'shared/ui/icon-button/IconButton';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

function AccrualsPageTitle() {
  const period = useSelector(selectPeriod);
  const [showTrialTeam, setShowTrialTeam] = useState(false);
  const trialTeamModalEnabled = useFeatureFlag('trial_team');

  return (
    <>
      <PageTitle
        breadcrumbs={[periodToBreadcrumbString(period)]}
        rightItems={
          trialTeamModalEnabled ? (
            <IconButton onClick={() => setShowTrialTeam(true)}>
              <PeopleOutline />
            </IconButton>
          ) : undefined
        }
      />
      {showTrialTeam && trialTeamModalEnabled && (
        <TrialTeamModal
          setShowTrialTeam={setShowTrialTeam}
          showTrialTeam={showTrialTeam}
        />
      )}
    </>
  );
}

export default AccrualsPageTitle;
