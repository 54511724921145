import type { ReactNode } from 'react';

import withCompanyRequired from 'shared/api/hocs/withCompanyRequired';
import withTrialRequired from 'shared/api/hocs/withTrialRequired';

import withForecastRequired from './withForecastRequired';

export default <T extends object>(LiveDataComponent: (props: T) => ReactNode) =>
  withCompanyRequired(
    withTrialRequired(withForecastRequired(LiveDataComponent)),
  );
