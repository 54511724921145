import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import type { CommentLocationType } from 'shared/lib/types';
import {
  selectActiveCommentsLocation,
  updateActiveLocation,
} from 'shared/state/slices/commentSlice';

export default function useCommentsVisibility(
  locationType: CommentLocationType,
  locationSlug: string,
) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const activeCommentsLocation = useSelector(selectActiveCommentsLocation);
  const location = locationSlug
    ? `${locationType}/${locationSlug}`
    : locationType;
  const open = activeCommentsLocation === location;

  useEffect(() => {
    if (!open) {
      return;
    }

    dispatch(updateActiveLocation({ location: null }));
  }, [pathname]);

  function onClickComments() {
    dispatch(updateActiveLocation({ location: open ? null : location }));
  }

  return { open, onClickComments };
}
