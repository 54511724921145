import { useMemo } from 'react';

import type {
  CellClassParams,
  ICellRendererParams,
} from '@ag-grid-community/core';
import cloneDeep from 'lodash/cloneDeep';

import AgGridValidationCellRenderer from 'shared/components/ag-grid-cells/renderers/ag-grid-validation-cell-renderer/AgGridValidationCellRenderer';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import type { GenericData } from 'shared/lib/types';

import ColumnMapperTableHeaderCell from './ColumnMapperTableHeaderCell';
import type {
  CsvErrors,
  MappedColumn,
  MappedCsvHeader,
  MappedRow,
} from './types';
import { IGNORE_COLUMN } from './utils/constants';

import classes from './ColumnMapperTable.module.scss';
const propsProxy: Pick<
  Props,
  'columns' | 'csvErrors' | 'csvHeaders' | 'isHeaderInFirstRow'
> = {
  isHeaderInFirstRow: false,
  csvHeaders: [],
  columns: [],
  csvErrors: {},
};

type Props = {
  columns: MappedColumn[];
  csvErrors: CsvErrors;
  csvHeaders: MappedCsvHeader[];
  isHeaderInFirstRow: boolean;
  rows: MappedRow[];
  onChange: (value: string, column: number) => void;
};

function ColumnMapperTable(props: Props) {
  const { columns, csvErrors, csvHeaders, onChange, isHeaderInFirstRow, rows } =
    props;

  const columnDefs = useMemo(() => {
    function getErrorForCell(row: number, column: number) {
      if (propsProxy.csvHeaders.length < column + 1) {
        return undefined;
      }

      const { destination } = propsProxy.csvHeaders[column];
      if (!destination) {
        return undefined;
      }

      const key = `Row ${row + (propsProxy.isHeaderInFirstRow ? 0 : 1)}`;
      if (typeof propsProxy.csvErrors !== 'object') {
        return undefined;
      }

      return key in propsProxy.csvErrors
        ? propsProxy.csvErrors[key][destination]
        : undefined;
    }

    const onChangeHeader = (index: number) => (value: string) => {
      onChange(value, index);
    };

    const columnsDefs = rows[0].map((_text, column) => ({
      colId: column.toString(),
      field: `csv-col-${column}`,
      width: 150,
      resizable: true,
      cellClassRules: {
        [`${classes.cell} ${classes.cell__highlighted}`]: (
          params: CellClassParams,
        ) => {
          const mappedColumns = propsProxy.csvHeaders
            .filter(
              (header) =>
                !!header.destination && header.destination !== IGNORE_COLUMN,
            )
            .map((header) => `csv-col-${header.position}`);
          return (
            !!params.colDef.field && mappedColumns.includes(params.colDef.field)
          );
        },
        [`${classes.cell} ${classes.cell__mutedText}`]: () => {
          const { ignore } = propsProxy.csvHeaders[column];
          return ignore;
        },
        [`${classes.cell} ${classes.cell__agGridInfo}`]: (
          params: CellClassParams,
        ) =>
          params.rowIndex === 0 && params.context.propsProxy.isHeaderInFirstRow,
        'ag-cell-error-default': (params: CellClassParams) => {
          const error = getErrorForCell(
            params.rowIndex,
            Number.parseInt(params.colDef.colId ?? ''),
          );
          return !!error;
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.node.rowIndex === null) {
          return params.value;
        }

        const errors = getErrorForCell(
          params.node.rowIndex,
          Number.parseInt(params.colDef?.colId ?? ''),
        );
        if (!errors) {
          return params.value;
        }

        return (
          <>
            <AgGridValidationCellRenderer message={errors} severity="error" />
            {params.value}
          </>
        );
      },
      headerComponent: () => (
        <ColumnMapperTableHeaderCell
          columns={propsProxy.columns}
          csvHeader={propsProxy.csvHeaders[column]}
          onChange={onChangeHeader(column)}
        />
      ),
    }));

    return [
      {
        headerName: '',
        suppressMovable: true,
        width: 60,
        valueGetter: 'node.rowIndex + 1',
      },
      ...columnsDefs,
    ];
  }, [onChange, rows]);

  const rowData = useMemo(
    () =>
      cloneDeep(
        rows.map((columnsInRow) =>
          columnsInRow.reduce<GenericData>((acc, text, column) => {
            acc[`csv-col-${column}`] = text;
            return acc;
          }, {}),
        ),
      ),
    [rows],
  );

  const gridOptions = useMemo(() => {
    // TODO: Why is this shared in this manner? If we are passing to context, it should then be used from context instead of the "static" variable it is right now
    propsProxy.isHeaderInFirstRow = isHeaderInFirstRow;
    propsProxy.csvHeaders = csvHeaders;
    propsProxy.columns = columns;
    propsProxy.csvErrors = csvErrors;

    return { context: { propsProxy }, headerHeight: 50 };
  }, [columns, csvErrors, csvHeaders, isHeaderInFirstRow]);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      rowData={rowData}
      sx={useMemo(() => ({ height: 400 }), [])}
    />
  );
}

export default ColumnMapperTable;
