import { useState } from 'react';

import type { SnapshotRecordType } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';
import SnapshotRecordsTable from 'shared/wizards/csv-upload-wizards/validate-and-check-table/SnapshotRecordsTable';

import columns from '../columns';
import useValidationQueue from '../useValidationQueue';

type EdcConfirmationStepProps = { snapshotId?: string };

function EdcConfirmationStep(props: EdcConfirmationStepProps) {
  const { snapshotId } = props;
  const [selectedErrorIndex, setSelectedErrorIndex] = useState<number>(0);
  const {
    validationErrors,
    allRecords,
    allSiteRecordOptions,
    handleAcceptForFieldValueMapper,
  } = useValidationQueue(snapshotId);

  const handleValidationAccept = async (
    applyToAllRows: boolean,
    fieldValue: string,
  ) => {
    await handleAcceptForFieldValueMapper(
      selectedErrorIndex,
      applyToAllRows,
      fieldValue,
    );
  };

  return (
    <WizardStep
      disableNextButton={validationErrors.length > 0}
      description={
        validationErrors.length > 0
          ? 'We will step you through each one. Accept or adjust the following'
          : ''
      }
      header={
        validationErrors.length > 0
          ? `We have detected ${validationErrors.length} issue${
              validationErrors.length > 1 ? 's' : ''
            } that require your validation.`
          : 'No issues detected. Click on Save & Continue to proceed'
      }
    >
      <SnapshotRecordsTable
        allSiteRecordOptions={allSiteRecordOptions}
        handleAcceptForFieldValueMapper={handleValidationAccept}
        headers={columns}
        rows={allRecords as SnapshotRecordType[]}
        selectedErrorIndex={selectedErrorIndex}
        setSelectedErrorIndex={setSelectedErrorIndex}
        validationErrors={validationErrors}
        showValidationSidebar
      />
    </WizardStep>
  );
}

export default EdcConfirmationStep;
