import type { ReactNode } from 'react';

import withCompanyRequired from 'shared/api/hocs/withCompanyRequired';
import withTrialRequired from 'shared/api/hocs/withTrialRequired';

import withPeriodRequired from './withPeriodRequired';
import withPeriodVersionRequired from './withPeriodVersionRequired';

export default <T extends object>(LiveDataComponent: (props: T) => ReactNode) =>
  withCompanyRequired(
    withTrialRequired(
      withPeriodRequired(withPeriodVersionRequired(LiveDataComponent)),
    ),
  );
