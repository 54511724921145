import type { LabRequest, LabResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'labs';

const apiEndpointFactory = constructApi<LabResponse, LabRequest>(PATH).withTags(
  'LABS',
);
export const api = apiEndpointFactory.inject(() => ({
  createLab: apiEndpointFactory.create(),
  getLab: apiEndpointFactory.get(),
  getLabsByTrial: apiEndpointFactory.getBy('trial'),
  updateLab: apiEndpointFactory.update(),
}));

export const { useCreateLabMutation, useGetLabsByTrialQuery } = api;
